import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";

export const importLicenseRequest = async (id: string, file: File, setIsSubmitting: (value: boolean) => void) => {

        const jwtToken = localStorage.getItem("TokenData") as string;
        const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
        const formData = new FormData;
        const header = {
            headers: {
                Authorization: token,
            },
        }

        formData.append('Content', file);

        const generateRequestUrl = (prefix: string, endPoint?: string) => {
            if (endPoint) {
                return `${envF.REACT_APP_OAUTH_API_PATH}/${prefix}/${endPoint}`
            } else {
                return `${envF.REACT_APP_OAUTH_API_PATH}/${prefix}`
            }
        }

        await axios.post(
            generateRequestUrl(`Companies/${id}`, 'ImportLicense'),
            formData,
            header
        ).then((e) => {
            if (e.status === 200) {
                setIsSubmitting(false)
                renderGlobalAlert({ variant: "success", statusCode: 200, title: 'Лицензия загружена успешно' })
            }
        }).catch((err: AxiosError) => {
            setIsSubmitting(false)
            renderGlobalAlert({ variant: 'error', statusCode: 500, title: err.response?.statusText ?? 'Ошибка' })
        })
}
