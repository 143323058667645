import { DateTime } from "luxon";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { Button } from "../../../../components/buttons/button";
import { SettingsButtonModal } from "../../../../components/buttons/iconButtons/settingsButton/SettingsButtonModal";
import { Accordion } from "../../../../components/controls/accordion";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IOption, Select } from "../../../../components/selects/select";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { IGoodsDTO } from "../../../../libs/coreapi-dto/dirs/goods";
import { IInvoiceItem } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";
import { GoodsDataProvider } from "../../../../Services/DataProviders/GoodsDataProvider";
import { PricingInvoiceDataProvider } from "../../../../Services/DataProviders/PricingInvoiceDataProvider";
import { PricingModelDataProvider } from "../../../../Services/DataProviders/PricingModelDataProvider";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { useForm } from "../../../../system/hooks/useForm";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { GoodsSelectorModal } from "../../../Dictionaries/Goods";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { PricingModelSelectorModal } from "../../../Dictionaries/PricingModel";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import styles from "../styles/ImportRemainsCreatorView.module.scss";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { IGoodsByIdDTO } from "../../../../libs/core-api-requests/dirs/goodsRequest";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { StockBalanceButtonCompress, StockBalanceButtonExpand } from "../../../../components/buttons/iconButtons/special/QuickAccessBarCollection";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { autocomplete } from "../../../../system/functions/autocomplete";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { IImportRemainsItemCreateDTO, IImportRemainsItemEditDTO, IImportRemainsItemGetDTO } from "../../../../libs/coreapi-dto/documents/importRemainsItem";
import { IImportRemainsCreateDTO, IImportRemainsGetDTO, IImportRemainsUpdateDTO } from "../../../../libs/coreapi-dto/documents/importRemains";
import { CollectPricingInvoiceDTO, CollectPricingInvoiceItem, eventPricingInvoiceTypes } from "../../Invoice/CollectPricingInvoiceDTO";
import InvoiceGoodsSelectModal from "../../Invoice/InvoiceCreator/InvoiceGoodsSelectModal";
import { ImportRemainsCreatorItemGridPluginSettings } from "./ImportRemainsItemCreator/ImportRemainsItemCreatorGridPluginSettings";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import FlexRow from "../../../../components/controls/FlexRow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { addValueToArray } from "../../../../system/functions/addValToArray";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";

interface IImportRemainsItemDTOForRecalculate extends IImportRemainsItemCreateDTO {
    forRecalculateGlobal: string;
}

interface ISum {
    amountRetailSum: number;
    amountRetailVatSum: number;
    amountRetailSumIncVat: number;
    amountSupplierVatSum: number;
    amountSupplierSum: number;
    amountSupplierSumIncVat: number;
}

interface IBaseFields {
    store: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    pharmacy: IEntitySimpleDTO;
}

interface ISelectedItemGoods extends IGoodsDTO {
    idGlobal: string;
}

interface IGoodsViewItem {
    item: IGoodsByIdDTO[] | null;
    show: boolean;
}

interface IStoreModel {
    store?: IEntitySimpleDTO;
}

interface IValidator {
    supplier: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    documentDate: DateTime;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

export type BtSaveOrOk = "ok" | "save";

interface ISum {
    amountRetailSum: number;
    amountRetailVatSum: number;
    amountRetailSumIncVat: number;
    amountSupplierVatSum: number;
    amountSupplierSum: number;
    amountSupplierSumIncVat: number;
}

interface IBaseFields {
    store: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    pharmacy: IEntitySimpleDTO;
}
export interface IDisplayItem {
    idGlobal: string;
    goodsName: string;
    scalingRatioName: string;
    quantity: number;
    producerPrice: number;
    productMargin: number;
    retailPrice: number;
    isKiz: boolean;
    isGnvls: boolean;
    countKizScan: number | undefined;
    supplier: string | undefined;
    numerator: number;
    denominator: number;
    dateCreated: DateTime;
    isOsu?: boolean
}

interface ISelectedItemGoods extends IGoodsDTO {
    idGlobal: string;
}

interface IGoodsViewItem {
    item: IGoodsByIdDTO[] | null;
    show: boolean;
}

const ImportRemainsUpdateForm: FC<IDocumentUpdateFormProps<IImportRemainsGetDTO, IImportRemainsItemGetDTO[], IImportRemainsUpdateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const pluginCtx = usePluginContext();

    const sdp = new StoreDataProvider(appContext.coreApiService);
    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const cdp = new ContractorDataProvider(appContext.coreApiService);
    const pdp = new PricingModelDataProvider(appContext.coreApiService);
    const pricingInvoiceDataProvider = new PricingInvoiceDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);

    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [data, setData] = useState<IImportRemainsUpdateDTO>(
        props.documentUpdate ??({ idGlobal: uuidv4(), documentDate: props.isTest ? DateTime.now().plus({ days: -7 }) : DateTime.now() } as IImportRemainsCreateDTO)
    );

    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemGoods, setSelectedItemGoods] = useState<ISelectedItemGoods>();
    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IImportRemainsItemCreateDTO>();
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const [averageMarkup, setAverageMarkup] = useState<string>(recalculateAverageMarkup(data.items)); // средняя наценка

    const [mnemocode, setMnemocode] = useState<string>(props.document?.mnemocode ?? "");
    const [supplier, setSupplier] = useState<IEntitySimpleDTO[]>(props.documentItems?.map((item) => item.supplier) ?? ([{ displayName: "", idGlobal: "" } as IEntitySimpleDTO] as IEntitySimpleDTO[]));

    const [sum, setSum] = useState<ISum>({ amountRetailSum: 0, amountRetailVatSum: 0, amountRetailSumIncVat: 0, amountSupplierSum: 0, amountSupplierSumIncVat: 0, amountSupplierVatSum: 0 });
    const [showKizScan, setShowKizScan] = useState<boolean>(false);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const defaultSearchOption = { displayName: t("documents.invoice.name"), value: "name" } as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);

    const [selectedScalingRation, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);
    const [goodsViewItem, setGoodsViewItem] = useState<IGoodsViewItem>({ item: null, show: false });

    const [isCompact, setIsCompact] = useState<boolean>(false);
    const [isCompactStore, setIsCompactStore] = useState<boolean>(false);
    //Validations
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);

    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO | null>(props.document?.pricingModel ?? null);
    const [store, setStore] = useState<IEntitySimpleDTO | null>(props.document?.store ?? null);
    const [pharmacy, setPharmacy] = useState<IEntitySimpleDTO | null>(null);

    const currentIdTab = useRef<string>('');

    useEffect(() => setData({ ...data, idStoreGlobal: store?.idGlobal }), [store]);
    useEffect(() => setData({ ...data, idPricingModelGlobal: pricingModel?.idGlobal }), [pricingModel]);

    useEffect(() => setStore(props.document?.store ? { ...props.document?.store } : null), [props.document?.store]);
    useEffect(() => setPricingModel(props.document?.pricingModel ? { ...props.document?.pricingModel } : null), [props.document?.pricingModel]);

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });
    const [setTimer, clearTimer]  = useTimeout()

    const { isValid, errors, setErrors } = useForm<IStoreModel & IValidator>({
        validations: {
            store: {
                required: {
                    value: data?.idStoreGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            pricingModel: {
                required: {
                    value: data?.idPricingModelGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });

    useEffect(() => {
        if (selectedItem) {
            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IImportRemainsItemCreateDTO;
            setSelectedItemCreateDto(selectedItemDto);
            scalingRatioDP.overrideGetById(selectedItemDto.idGoodsGlobal, selectedItemDto.idScalingRatioGlobal as string, (e) => {
                setSelectedScalingRation(e);
            });
            goodsDP.getById(data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idGoodsGlobal as string, (e) => {
                setSelectedItemGoods({ ...e, idGlobal: data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idGoodsGlobal as string });
            });
        } else {
            setSelectedItemCreateDto(undefined);
            setSelectedItemGoods(undefined);
            setSelectedScalingRation(null);
        }
    }, [selectedItem]);

    useEffect(() => {
        calculateSum()
    }, [data?.items,displayItems]);

    const calculateSum = () => {
        let amountRetailSumTemp = 0; //Сумма роз. б/НДС
        let amountRetailVatSumTemp = 0; //Сумма НДС розн.
        let amountRetailSumIncVatTemp = 0; //Сумма розничная с НДС //Сумма розн.

        let amountSupplierVatSumTemp = 0; //Суммы НДС пост.
        let amountSupplierSumTemp = 0; //Сумма пост. б/НДС
        let amountSupplierSumIncVatTemp = 0; //Сумма пост.
        if (data?.items) {
            data?.items.map((ii) => {
                amountRetailSumTemp += ii.retailCostInfo.sum;
                amountRetailVatSumTemp += ii.retailCostInfo.vatSum;
                amountRetailSumIncVatTemp += ii.retailCostInfo.sumIncVat;
                amountSupplierVatSumTemp += ii.supplierCostInfo.vatSum;
                amountSupplierSumTemp += ii.supplierCostInfo.sum;
                amountSupplierSumIncVatTemp += ii.supplierCostInfo.sumIncVat;
            });
            setSum({
                ...sum,
                amountRetailSum: amountRetailSumTemp,
                amountRetailVatSum: amountRetailVatSumTemp,
                amountRetailSumIncVat: amountRetailSumIncVatTemp,
                amountSupplierVatSum: amountSupplierVatSumTemp,
                amountSupplierSum: amountSupplierSumTemp,
                amountSupplierSumIncVat: amountSupplierSumIncVatTemp,
            });
        }
    }

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        if (props.document && props.documentItems) {
            sdp.getById(props.document.store.idGlobal, (e) => {
                if (props.document) {
                    setPharmacy(e.contractor);
                }
            });
            setDisplayItems(
                props.documentItems.map((x) => {
                    return {
                        idGlobal: x.idGlobal,
                        goodsName: x.goods.displayName,
                        scalingRatioName: x.scalingRatio.displayName,
                        quantity: x.quantity,
                        producerPrice: x.producerPrice,
                        productMargin: x.productMargin,
                        retailPrice: x.retailCostInfo.priceIncVat,
                        isKiz: x.isKiz || x.isKizBox,
                        countKizScan: (x.isKiz || x.isKizBox) === true ? x.kizBoxCount + x.kizCount : undefined,
                        isGnvls: x.isGnvls,
                        denominator: x.denominator,
                        numerator: x.numerator,
                    } as IDisplayItem;
                })
            );
        }
        if ((props.variant === "edit" || props.variant === "copy") && props.document) {
            let arr: IItemFilter[] = [];
            props.document.items?.forEach((x) => {
                arr.push({
                    idItem: x.idGlobal as string,
                    quantityMax: 9999999,
                    quantityMin: 0,
                });
            });
            setItemsFilter(arr);
            const propsValidateItems = props.document.items.map((el) => ({
                err: false,
                message: "",
                idGlobal: el.idGlobal,
            })) as IValidationItem[];
            setItemsValidation(propsValidateItems);
        }
    }, []);

    useEffect(() => {
        if (props.variant === "create") {
            if (props.isTest) {
                autocomplete(props.isTest).then((e) => {
                    sdp.getById(e.contractorMe.store.idGlobal, (store) => {
                        props.ok({
                            ...data,
                            idPricingModelGlobal: store.pricingPricingModel?.idGlobal ?? e.contractorMe.pricingPricingModel?.idGlobal,
                            idStoreGlobal: e.contractorMe.store.idGlobal,
                            idSupplierGlobal: e.defaultSupplier?.idGlobal,
                            items: [e.invoiceItem as IImportRemainsItemCreateDTO],
                        });
                    });
                });
            } else {

            }
        }
    }, []);

    useEffect(() => {
        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: IdTableVariant.ImportRemains,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idPricingModelGlobal, data.idStoreGlobal, data.datePay, data.items]);

    useEffect(() => {
        if (props.variant === "edit"&& props.documentUpdate&& props.documentItems && props.document) {

            lockingDocuments.check(data.idGlobal as string, (e)=>{
                if (e) setOnlyRead(true)
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.ImportRemains,
                        idDocument: data.idGlobal as string,
                    }, (e)=> {
                        setIdLockingDocument(e)
                    });
            })

            setData(props.documentUpdate);
            setMnemocode(props.document.mnemocode);
            setDisplayItems(
                props.documentItems.map((x) => {
                    return {
                        idGlobal: x.idGlobal,
                        goodsName: x.goods.displayName,
                        scalingRatioName: x.scalingRatio.displayName,
                        quantity: x.quantity,
                        producerPrice: x.producerPrice,
                        productMargin: x.productMargin,
                        retailPrice: x.retailCostInfo.priceIncVat,
                        isKiz: x.isKiz || x.isKizBox,
                        countKizScan: x.isKiz || x.isKizBox ? x.kizBoxCount + x.kizCount : undefined,
                        isGnvls: x.isGnvls,
                        idSupplierGlobal: x.supplier.idGlobal,
                        supplierDocDate: x.supplierDocDate,
                        supplierDocNumber: x.supplierDocNumber,
                        supplier: x.supplier.displayName,
                        denominator: x.denominator,
                        numerator: x.numerator,
                        dateCreated: x.dateCreated,
                        isOsu: x.isOsu,
                    } as IDisplayItem;
                })
            );
        }
    }, [props.variant]);

    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
          }
        if (!data.idPricingModelGlobal) return;
        recalculatePricingItems("QuantityСhanged");
    },[data.idPricingModelGlobal]);

    const recalculatePricingItems = (eventTypes: eventPricingInvoiceTypes) => {
        if (data?.idGlobal !== undefined && data?.idStoreGlobal !== undefined && data.items) {
            let invoiceItemsWithTempGlobal: IImportRemainsItemDTOForRecalculate[] = [];
            let invoiceItemsForRequest: IInvoiceItem[] = [];
            data.items.map((ii) => {
                let uuid = ii.idGlobal ?? uuidv4();
                invoiceItemsWithTempGlobal.push({ ...ii, forRecalculateGlobal: uuid });
                let invoiceItem = CollectPricingInvoiceItem(uuid, ii.idGoodsGlobal, ii.idScalingRatioGlobal, ii.quantity, ii.producerPrice, {...ii.retailCostInfo, adprice: 0}, ii.supplierCostInfo, ii.registerPrice);
                invoiceItemsForRequest.push(invoiceItem);
            });
            let pricingInvoiceDTO = CollectPricingInvoiceDTO(eventTypes, data.idGlobal, data.items[0]?.idSupplierGlobal, data.idStoreGlobal, invoiceItemsForRequest);

            pricingInvoiceDataProvider.getCalculatedInvoiceViewAsync(data?.idPricingModelGlobal as string, pricingInvoiceDTO, (e) => {
                
                e.items.forEach(x => {
                    let indexItem = data.items.findIndex((item) => item.idGlobal === x.idGlobal);
                    let takeDataObj = data.items.find((item) => item.idGlobal === x.idGlobal) as IImportRemainsItemCreateDTO;
        
                    let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === x.idGlobal);
                    let takeDisplayObj = displayItems.find((item) => item.idGlobal === x.idGlobal) as IDisplayItem; 

                    if (x.retailPriceChangeReason) {
                        setDisplayItems([ ...addValueToArray(displayItems, indexDisplayItem, { ...takeDisplayObj, retailPriceIncVat: 0, }),]);
                        renderGlobalAlert({ variant: "warning", statusCode: 500, title: x.retailPriceChangeReason, instance: "Сервис расчета цен" });
                    }

                    let newItem: IImportRemainsItemCreateDTO = {
                        ...takeDataObj,
                        retailCostInfo: {
                            vat: x.percentageOfVatRateRetail,
                            price: x.retailPrice,
                            vatPrice: x.retailVatPerUnit,
                            priceIncVat: x.retailPriceWithVat,
                            sum: x.retailAmount,
                            vatSum: x.vatAmountRetail,
                            sumIncVat: x.retailAmountWithVat,
                            adprice: x.percentageOfMarkupRetail,
                        },
                        supplierCostInfo: {
                            vat: x.supplierVatRatePercentage,
                            price: x.supplierPriceExcludingVat,
                            vatPrice: x.supplierVatPerUnit,
                            priceIncVat: x.supplierPriceWithVat,
                            sum: x.supplierAmountExcludingVat,
                            vatSum: x.supplierVatAmount,
                            sumIncVat: x.supplierAmountWithVat,
                            adprice: x.supplierMarkupPercentage,
                        },
                        quantity: x.quantity,
                        registerPrice: x.registeredPrice,
                        producerPrice: x.manufacturerPrice,
                        productMargin: x.actualMarkupPercentage,
                    };
                    
                    setData({
                        ...data,
                        items: addValueToArray(data.items, indexItem, newItem),
                    });

                    displayItems.length > 0 && setDisplayItems([
                        ...addValueToArray(displayItems, indexDisplayItem, {
                            ...takeDisplayObj,
                            producerPrice: x.manufacturerPrice,
                            retailPrice: x.retailPriceWithVat,
                            quantity: x.quantity,
                            productMargin: x.percentageOfMarkupRetail
                        } as IDisplayItem),
                    ]);
                })
            });
        }
    };

    function recalculateAverageMarkup(items: IImportRemainsItemCreateDTO[] | undefined): string {
        let result: number = 0;
        if (items && items.length) {
            for (let i = 0; i < items.length; i++) {
                let supplierSumIncVat: number = items[i].supplierCostInfo.price;
                supplierSumIncVat += (supplierSumIncVat * items[i].supplierCostInfo.vat) / 100;
                let retailSumIncVat: number = items[i].retailCostInfo.price;
                retailSumIncVat += (retailSumIncVat * items[i].retailCostInfo.vat) / 100;
                result += (supplierSumIncVat / retailSumIncVat) * 100;
            }
            result = result / items.length;
        }
        return result > 0 ? result.toFixed(2).toString() : "0";
    }

    function callOpenImportRemainsItemCreatorPlugin(entity: IEntitySimpleDTO) {
        tabsContext.openChild(
            "import_remains_item_creator_plugin",
            "create",
            undefined,
            (value: IImportRemainsItemCreateDTO, displayItem: IDisplayItem) => {
                setData({ ...data, items: data.items ? [...data.items?.filter((x) => x.idGlobal !== value.idGlobal), value] : [value] });
                setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem.idGlobal), displayItem]);
                let _supplier = { displayName: displayItem.supplier, idGlobal: value.idSupplierGlobal } as IEntitySimpleDTO;
                setSupplier([...supplier.filter((x) => x.idGlobal !== displayItem.idGlobal), _supplier]);

                setItemsFilter([...itemsFilter, { idItem: value.idGlobal, quantityMax: 9999999, quantityMin: 0 }]);
                setItemsValidation([...itemsValidation, { err: false, message: "", idGlobal: value.idGlobal }]);
            },
            {
                goods: entity,
                idPricingModel: data.idPricingModelGlobal,
                idStore: data.idStoreGlobal,
                documentDate: data.documentDate
            }
        );
    }

    const isValidDocument = (items:IImportRemainsItemEditDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const calcRow = (value: IChangeDataGrid) => {
        let dataRowItems = data.items.find((x) => x.idGlobal === value.idGlobal) as IImportRemainsItemCreateDTO;
        let filteredItems = data.items.filter((x) => x.idGlobal !== value.idGlobal);
    
        let displayRowItems = displayItems.find((x) => x.idGlobal === value.idGlobal) as IDisplayItem;
        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== value.idGlobal);
    
        setData({ ...data, items: [...filteredItems, { ...dataRowItems, [value.propertyName]: value.value }] });
        setDisplayItems([...filteredDisplayItems, { ...displayRowItems, [value.propertyName]: value.value }]);
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ImportRemains,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onCancel = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ImportRemains,
            idDocument: data.idGlobal as string,
        });

        props.cancel();
    };

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (isValid() && isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true) {
                            if (selectedScalingRation?.denominator !== 1) {
                                for (const x of data.items) {
                                    if (x.kizs?.length !== 1) {
                                        setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" });
                                    } else {
                                        onOkAction();
                                    }
                                }
                            } else if (
                                kizCounter(data.items, () => {	
                                    setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" });	
                                }) === true
                            ) {
                                onOkAction();
                            }
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: () => {
                        if (isValid() && isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true) {
                            if (selectedScalingRation?.denominator !== 1) {
                                for (const x of data.items) {
                                    if (x.kizs?.length !== 1) {
                                        setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" });
                                    } else {
                                        props.save(data);
                                    }
                                }
                            } else if (
                                kizCounter(data.items, () => {	
                                    setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" });	
                                }) === true
                            ) {
                                props.save(data);
                            }
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                cancel={{ onClick: onCancel }}
                print={{
                    onClick: () => {
                        return "import_remains_plugin";
                    },
                    onSubItemClick: () => {
                        let arr = new Array<IKeyValuePair>();
                        if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined) arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                        return arr;
                    },
                }}
            >
                <div className={styles.glwrapper}>
                    <div className={styles.wrapper}>
                        <div>
                            {isCompact ? (
                                <>
                                    <GridWrapper cols={35}>
                                        <FlexRow className={styles.compactFieldFields} wrap>
                                            <div>
                                                <Button
                                                    disabled={data.items && data?.items?.length > 0 && !onlyRead ? false : true}
                                                    className={styles.compactFieldCompactRecalculate}
                                                    variant="primary"
                                                    onClick={() => {
                                                        recalculatePricingItems('QuantityСhanged')
                                                    }}
                                                >
                                                    Пересчитать (F5)
                                                </Button>
                                            </div>
                                            <TextInput
                                                label={t("documents.invoice.number")}
                                                inputId={"inputInvoiceNumber"}
                                                value={mnemocode}
                                                disabled
                                            />
                                            <DateInput
                                                required
                                                disabled={onlyRead ? true : false}
                                                labelVariant="required"
                                                inputId={"inputInvoiceDate"}
                                                label={t("documents.invoice.date")}
                                                value={data.documentDate}
                                                error={errors.documentDate}
                                                onBlur={(value) => {
                                                    setData({ ...data, documentDate: value as DateTime });
                                                }}
                                            />
                                            <StoreSelectorPanel
                                                we
                                                required
                                                compactMode
                                                contractor={{
                                                    label: t("documents.invoice.pharmacy"),
                                                    disabled: onlyRead ? true : false,
                                                    treeViewCheckDirectoryType:'pharmacy'
                                                }}
                                                store={{
                                                    label: t("documents.invoice.store"),
                                                    value: store, 
                                                    onSelect: (value) => {
                                                        setStore(value);
                                                    },
                                                    onClear: () => setStore(null),
                                                    error: errors.store,
                                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                                    disabled: onlyRead ? true : false
                                                }}
                                                pricingModel={{
                                                    label: t("documents.invoice.pricingModel"),
                                                    onSelect: (value) => setPricingModel(value),
                                                }}
                                                notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                documentStore={store ?? undefined}
                                                documentPricingModel={pricingModel ?? undefined}
                                            />
                                            <GridSelectorInput
                                                required
                                                disabled={onlyRead ? true : false}
                                                variant="required"
                                                selectorModalJsx={PricingModelSelectorModal}
                                                selectedEntity={pricingModel}
                                                label={t("documents.invoice.pricingModel")}
                                                id={"dictionaryInputPricingModel"}
                                                onSelect={(entity) => {
                                                    setPricingModel({ ...entity })
                                                }}
                                                error={errors.pricingModel}
                                                onClear={() => setPricingModel(null) }
                                                onFocus={() => {
                                                    setErrors({ ...errors, pricingModel: undefined });
                                                }}
                                            />
                                        </FlexRow>
                                        <div className={styles.compactFieldCompactMode}>
                                            <Tooltip title="Обычный режим">
                                                <StockBalanceButtonExpand
                                                    onClick={() => {
                                                        setIsCompact(!isCompact);
                                                    }}
                                                    sizeVariant="normal"
                                                    colorVariant="default"
                                                />
                                            </Tooltip>
                                        </div>
                                    </GridWrapper>
                                </>
                            ) : (
                                <>
                                    <GridWrapper cols={35}>
                                        <TextInput
                                            className={styles.field_Number}
                                            disabled={true}
                                            label={t("documents.invoice.number")}
                                            inputId={"inputInvoiceNumber"}
                                            inline={true}
                                            value={mnemocode}
                                        />
                                        <DateInput
                                            required
                                            disabled={onlyRead ? true : false}
                                            labelVariant="required"
                                            inputId={"inputInvoiceDate"}
                                            className={styles.field_Date}
                                            label={t("documents.invoice.date")}
                                            inline={true}
                                            value={data.documentDate}
                                            error={errors.documentDate}
                                            onBlur={(value) => {
                                                setData({ ...data, documentDate: value as DateTime });
                                            }}
                                        />
                                        <div className={styles.field_CompactMode}>
                                            <Tooltip title="Компактный режим">
                                                <StockBalanceButtonCompress
                                                    onClick={() => {
                                                        setIsCompact(!isCompact);
                                                        setIsCompactStore(true);
                                                    }}
                                                    sizeVariant="normal"
                                                    colorVariant="default"
                                                />
                                            </Tooltip>
                                        </div>
                                    </GridWrapper>
                                    <div>
                                        <GridWrapper cols={2}>
                                            <StoreSelectorPanel
                                                we
                                                required
                                                contractor={{ 
                                                    label: t("documents.invoice.pharmacy"),
                                                    disabled: onlyRead ? true : false,
                                                    treeViewCheckDirectoryType:'pharmacy'
                                                }}
                                                store={{
                                                    label: t("documents.invoice.store"),
                                                    value: store,
                                                    onSelect: (value) => {
                                                        setStore(value);
                                                    },
                                                    onClear: () => setStore(null),
                                                    error: errors.store,
                                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                                    disabled: onlyRead ? true : false
                                                }}
                                                pricingModel={{
                                                    label: t("documents.invoice.pricingModel"),
                                                    onSelect: (value) => setPricingModel(value),
                                                }}
                                                notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                documentStore= {store ?? undefined}
                                                documentPricingModel={pricingModel ?? undefined}
                                            />
                                        </GridWrapper>
                                    </div>
                                    <GridWrapper cols={6}>
                                        <GridSelectorInput
                                            required
                                            disabled={onlyRead ? true : false}
                                            variant="required"
                                            className={styles.field_PriceModel}
                                            selectorModalJsx={PricingModelSelectorModal}
                                            selectedEntity={pricingModel}
                                            label={t("documents.invoice.pricingModel")}
                                            id={"dictionaryInputPricingModel"}
                                            inline={true}
                                            onSelect={(entity) => {
                                                setPricingModel({ ...entity })
                                            }}
                                            error={errors.pricingModel}
                                            onClear={() => setPricingModel(null)
                                                    }
                                            onFocus={() => {
                                                setErrors({ ...errors, pricingModel: undefined });
                                            }}
                                        />
                                        <Button
                                            disabled={data.items && data?.items?.length > 0 && !onlyRead ? false : true}
                                            className={styles.field_Recalculate}
                                            variant="primary"
                                            onClick={() => {
                                                recalculatePricingItems('QuantityСhanged')
                                            }}
                                        >
                                            Пересчитать (F5)
                                        </Button>
                                    </GridWrapper>
                                </>
                            )}
                            <FlexRow className={styles.field_flexbox}>
                                <PositionEditPanel
                                    kiz={{
                                        onClick: () => setShowKizScan(true),
                                        disabled: (selectedItemCreateDto?.isKiz || selectedItemCreateDto?.isKizBox) && !onlyRead ? false : true,
                                    }}
                                    edit={{
                                        onClick: () => {
                                            tabsContext.openChild(
                                                "import_remains_item_creator_plugin",
                                                "edit",
                                                undefined,
                                                (value: IImportRemainsItemCreateDTO, displayItem: IDisplayItem) => {
                                                    setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== value.idGlobal), value] });
                                                    setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem.idGlobal), displayItem]);

                                                    let _supplier = { displayName: displayItem.supplier, idGlobal: value.idSupplierGlobal } as IEntitySimpleDTO;
                                                    setSupplier([...supplier.filter((x) => x.idGlobal !== _supplier.idGlobal), _supplier]);
                                                },
                                                {
                                                    item: data.items.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                    supplier: {
                                                        displayName: supplier.find((c) => c.idGlobal === data.items.find((x) => x.idGlobal === selectedItem?.idGlobal)?.idSupplierGlobal)
                                                            ?.displayName as string,
                                                        idGlobal: data.items.find((x) => x.idGlobal === selectedItem?.idGlobal)?.idSupplierGlobal as string,
                                                    } as IEntitySimpleDTO,
                                                    goods: { displayName: selectedItemGoods?.goodsName.name as string, idGlobal: selectedItemGoods?.idGlobal as string } as IEntitySimpleDTO,
                                                    idPricingModel: data.idPricingModelGlobal,
                                                    idStore: data.idStoreGlobal,
                                                    displayItem: displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                    onlyRead: onlyRead,
                                                    documentDate: data.documentDate,
                                                    scalingRatio: { displayName: selectedScalingRation?.displayName, idGlobal: selectedScalingRation?.idGlobal} as IEntitySimpleDTO,
                                                }
                                            );
                                        },
                                        disabled: selectedItem ? false : true
                                    }}
                                    delete={{
                                        onClick: () => {
                                            setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal)] });
                                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal)]);

                                            const itemsValid = itemsValidation.filter((item) => item.idGlobal !== selectedItem?.idGlobal);
                                            const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== selectedItem?.idGlobal);
                                            setItemsValidation(itemsValid);
                                            setItemsFilter(itemsFilterValid);
                                            setSelectedItem(undefined);
                                        },
                                        disabled: selectedItem && !onlyRead ? false : true
                                    }}
                                />
                                <GridSelectorInput
                                    disabled={onlyRead ? true : false}
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    selectorModalJsx={GoodsSelectorModal}
                                    searchOption={searchOption.value}
                                    className={styles.field_SelectionGoods}
                                    id={"inputAccompanyingSelectionGoods"}
                                    inline={true}
                                    label={t("documents.invoice.selectionOfGoods")}
                                    //selectedEntity={goods}
                                    valid={() => isValid()}
                                    onSelect={(entity) => {
                                        if (searchOption.value === SearchOption.barcode || searchOption.value === SearchOption.goodsCode) {
                                            if (entity.length > 1) setGoodsViewItem({ item: entity, show: true });
                                            else
                                                callOpenImportRemainsItemCreatorPlugin(entity);
                                        } else {
                                            callOpenImportRemainsItemCreatorPlugin(entity);
                                        }
                                    }}
                                />
                                <Select
                                    disabled={onlyRead ? true : false}
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    onSelect={(option) => {
                                        setSearchOption(option);
                                    }}
                                    value={searchOption}
                                    className={styles.field_SelectBlock}
                                    options={[
                                        { displayName: t("documents.invoice.barcode"), value: "barcode" },
                                        { displayName: t("documents.invoice.name"), value: "name" },
                                        { displayName: t("documents.invoice.goodsCode"), value: "goodsCode" },
                                    ]}
                                    defaultOption={defaultSearchOption}
                                />
                                <TextInput
                                    className={styles.field_Empty}
                                    disabled
                                    label=""
                                    inputId={"inputAccompanyingEmpty"}
                                    inline={true} /* value={accompanyingInvoiceInfo?.empty} onChange={(value) => setAccompanyingInvoiceInfo({ ...accompanyingInvoiceInfo, empty: value })}*/
                                />

                                <div className={styles.SettingsButton}>
                                    <SettingsButtonModal 
                                        disabled={onlyRead ? true : false}
                                    />
                                </div>
                            </FlexRow>
                            <ChangeableGrid
                                documentStateProc={onlyRead}
                                itemsFilter={itemsFilter}
                                itemsValidation={itemsValidation}
                                setItemsValidation={(value) => {
                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item));
                                    setItemsValidation(newItemsValidation);
                                }}
                                autoSelect
                                gridId={uuidv4()}
                                data={displayItems}
                                hiddenPagination={undefined}
                                filter={gridFilter}
                                totalCount={0}
                                isSortByCreatedDate
                                getStyleCell={(cell, cells) => getStyleCell(cell, cells, DocumentType.importRemains)}
                                plugin={ImportRemainsCreatorItemGridPluginSettings}
                                selectedItem={selectedItem}
                                onSelect={(row) => {
                                    setSelectedItem(row);
                                }}
                                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                                onSort={(i) => {
                                    dispatchGridFilter({ type: "sort", payload: i.propertyName });
                                }}
                                onFilterDelete={(i) => {
                                    dispatchGridFilter({
                                        type: "deleteColumnFilter",
                                        payload: i.propertyName,
                                    });
                                }}
                                onPageNumberChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changePageNumber",
                                        payload: { pageNumber: n },
                                    });
                                }}
                                onNumberPerPageChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changeNumberPerPage",
                                        payload: { numberPerPage: n },
                                    });
                                }}
                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                                onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                            />
                        </div>
                        <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent
                                    className={styles.field_BillNumber}
                                    labelClassName="label__width-111"
                                    disabled
                                    label={t("documents.invoice.averageMarkup")}
                                    inputId={"inputAccompanyingAverageMarkup"}
                                    value={+averageMarkup}
                                />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent
                                    disabled
                                    labelClassName="label__width-111"
                                    label={t("documents.invoice.supplierVatAmounts")}
                                    inputId={"inputAccompanyingNdsSum"}
                                    value={Number(sum.amountSupplierVatSum)}
                                />
                                <MoneyFormatComponent
                                    disabled
                                    labelClassName="label__width-111"
                                    label={t("documents.invoice.supplierAmountsWithoutVat")}
                                    inputId={"inputAccompanyingNdsProviderSum"}
                                    value={Number(sum.amountSupplierSum)}
                                />
                                <MoneyFormatComponent
                                    disabled
                                    label={t("documents.invoice.supplierAmounts")}
                                    inputId={"inputAccompanyingProviderSum"}
                                    value={Number(sum.amountSupplierSumIncVat)}
                                />
                            </GridWrapper>

                            <GridWrapper cols={3}>
                                <MoneyFormatComponent
                                    disabled
                                    labelClassName="label__width-111"
                                    label={t("documents.invoice.retailAmountWithoutVat")}
                                    inputId={"inputAccompanyingRetailSumBNds"}
                                    value={Number(sum.amountRetailSum)}
                                />
                                <MoneyFormatComponent
                                    disabled
                                    labelClassName="label__width-111"
                                    label={t("documents.invoice.retailVatAmount")}
                                    inputId={"inputAccompanyingNdsRetailSumNds"}
                                    value={Number(sum.amountRetailVatSum)}
                                />
                                <MoneyFormatComponent 
                                    disabled 
                                    label={t("documents.invoice.retailAmount")} 
                                    inputId={"inputAccompanyingRetailSum"} 
                                    value={Number(sum.amountRetailSumIncVat)} 
                                />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>

                {showKizScan && selectedScalingRation && (
                    <KizScanModal
                        ok={(value: IImportRemainsItemCreateDTO, scalingRatio: IScalingRatioDTO) => {
                            setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== value.idGlobal), {...value, dateCreated: DateTime.now()}] });
                            setDisplayItems(
                                displayItems.map((x): IDisplayItem => {
                                    return {
                                        countKizScan: x.idGlobal === value.idGlobal ? sumKizsCount(value, DocumentType.importRemains,scalingRatio) : x.countKizScan,
                                        goodsName: x.goodsName,
                                        idGlobal: x.idGlobal,
                                        isKiz: x.isKiz,
                                        producerPrice: x.producerPrice,
                                        productMargin: x.productMargin,
                                        quantity: x.quantity,
                                        retailPrice: x.retailPrice,
                                        scalingRatioName: x.scalingRatioName,
                                        isGnvls: x.isGnvls,
                                        supplier: x.supplier,
                                        denominator: scalingRatio.denominator,
                                        numerator: scalingRatio.numerator,
                                        dateCreated:  x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                        isOsu: value?.isOsu,
                                    };
                                })
                            );
                            setShowKizScan(false);
                        }}
                        cancel={() => setShowKizScan(false)}
                        selectedItem={selectedItemCreateDto as IImportRemainsItemCreateDTO}
                        documentType={DocumentType.importRemains}
                        document={{ idTable: IdTableVariant.ImportRemains, idDocument: data.idGlobal as string }}
                        idLotFrom={null}
                        scalingRatio={selectedScalingRation}
                        numerator={selectedScalingRation.numerator}
                        onValidateDuplicate={(barcode) => validateDuplicateKizs(data.items, barcode)}
                    />
                )}

                {showMessageModal.show && (	
                    <MessageModalWindow	
                        message={showMessageModal.message}	
                        ok={{	
                            onClick: () => {	
                                setShowMessageModal({ show: false, message: "" });	
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);	
                            },	
                        }}	
                        cancel={{	
                            onClick: () => {	
                                setShowMessageModal({ show: false, message: "" });	
                            },	
                        }}	
                        primary	
                    />	
                )}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {goodsViewItem.item && goodsViewItem.show && (
                    <InvoiceGoodsSelectModal
                        ok={(value: IEntitySimpleDTO) => {
                            callOpenImportRemainsItemCreatorPlugin(value);
                        }}
                        cancel={() => setGoodsViewItem({ item: goodsViewItem.item, show: false })}
                        data={goodsViewItem.item}
                    />
                )}
            </BaseDocumentCreator>
        </>
    );
};

export default ImportRemainsUpdateForm;
