import { IBaseDocumentItemKizDTO } from "../../libs/coreapi-dto/documents/base";
import { DocumentType } from '../../@types/enumsGlobal'
import { IKizDTO } from "../../libs/coreapi-dto/dirs/kiz";
import { IScalingRatioDTO } from "../../libs/coreapi-dto/dirs/scalingRatio";

export const sumKizsCount = (param: any, documentType: DocumentType,scalingRatio?: IScalingRatioDTO): number => {
    let number = 0;
    switch (documentType)
    {
        case DocumentType.cheque:
        case DocumentType.disassembling:
            number += param.kizs?.length ?? 0;
            break;

        case DocumentType.invoice:
        case DocumentType.actReturnToContractor:
        case DocumentType.actDeduction:
        case DocumentType.importRemains:
        case DocumentType.movement:
        case DocumentType.revaluation:
        case DocumentType.inventoryVed:
            if (param.kizs.length > 0 && param.kizBoxes.length > 0) {
                param.kizBoxes.map(i => number += i.quantity)
            } else {
                number += param.kizBoxes.length
            }
            if (scalingRatio) {
                let isDivided = scalingRatio.numerator < scalingRatio.denominator
                if (isDivided) {
                    number += param.kizs?.length > 0 ? scalingRatio.denominator : 0
                    break;
                }
            }

            number += param.kizs?.length ?? 0
            break;

        case DocumentType.disaggregation:
            let kizs = param.kizs as IKizDTO[] | undefined
            number = kizs?.filter(c => c.isTrusted).length ?? 0
            break;
    }

    return number
}

export const kizCounter = (items: IBaseDocumentItemKizDTO[], showMessage: () => void, documentType?: DocumentType): boolean => {
    if (items && items.length <= 0) {
        return true
    }

    let kiz: boolean = true;

    for (const x of items) {
        const isKiz: boolean = x.isKiz === true;
        const isKizBox: boolean = x.isKizBox === true;
        const isNotKizBox: boolean = x.isKizBox === false;
        const kizsLength = x.kizs?.length;
        const kizBoxesLength = x.kizBoxes?.length;

        if (isKiz && !x.hasOwnProperty('isKizBox') && !x.isOsu) {
            kiz = false;
            break;
        }
        if (isKiz && isKizBox && !x.isOsu) {
            if ((kizsLength === 0) && (kizBoxesLength === 0)) {
                kiz = false;
                break;
            }
        }
        if (isKiz && isNotKizBox && !x.isOsu) {
            if (kizsLength === 0) {
                kiz = false;
                break;
            }
        }

        if (x.kizBoxes?.length !== undefined && x.kizBoxes?.length > 0 && x.kizs?.length !== undefined && x.kizs?.length > 0) {
            if (documentType && documentType === DocumentType.disaggregation) {
                let kizCount = x.kizs.filter(c => c.isTrusted).length
                if (x.quantity != kizCount) {
                    kiz = false;
                    break;
                }
            }
            else {
                const kizInBox: number = x.kizBoxes.map(x => x.quantity).reduce((prev, current) => prev + current, 0)
                if (x.quantity !== kizInBox + x.kizs.length) {
                    kiz = false
                    break;
                }
            }
        } else if (x.numerator && x.kizBoxes?.length !== undefined && x.kizBoxes?.length > 0) {
            const kizInBox: number = x.kizBoxes.map(x => x.quantity).reduce((prev, current) => prev + current, 0)
            if (x.quantity !== (kizInBox / x.numerator)) {
                kiz = false
                break;
            }
        } else if (x.kizs?.length !== undefined && x.kizs?.length > 0) {
            if (documentType && documentType === DocumentType.disassembling)
            {
                if (x.quantityFrom !== x.kizs.length){
                    kiz = false
                    break;
                }
            }
            else if (x.quantity !== x.kizs.length) {
                kiz = false
                break;
            }
        }
    }

    if (kiz !== true) {
        showMessage()
    }

    return kiz
}
