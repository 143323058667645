import classNames from "classnames"
import { FC,  useEffect,  useState } from "react"
import { LotsByGoodsCreatorModal } from "."
import { DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { ILotByGoodsViewDTO } from "../../../libs/coreapi-dto/accounting/lot"
import { LotDataProvider } from "../../../Services/DataProviders/LotDataProvider"
import useGridFilter, { DefaultLotGridFilter } from "../../../system/hooks/useGridFilter"
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { usePluginContext } from "../../../system/providers/plugin"
import styles from '../../styles/index.module.scss'
import tabsStyles from '../../../pages/styles/homePage.module.scss'
import { TabsPanel } from "../../../components/tabs"
import DefaultLotsByCommandsPanel from "../../../components/commandsPanels/DefaultLotsByCommandsPanel"
import { GoodsDataProvider } from "../../../Services/DataProviders/GoodsDataProvider"
import { GoodsCreatorModal } from "../../Dictionaries/Goods"
import { useTabsContext } from "../../../system/providers/tabsProvider"
import { Spinner } from "../../../components/spiner/Spinner"
import { getFiltersForPrint } from "../../../system/functions/getFiltersForPrint"


const LotsByGoodsPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const tabCtx = useTabsContext();
    const pluginCtx = usePluginContext();

    const lotsByGoodsDataProvider = new LotDataProvider(appContext.coreApiService);
    const goodsDataProvider = new GoodsDataProvider(appContext.coreApiService);

    const [data, setData] = useState<ILotByGoodsViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultLotGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);   
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(props.plugin);

    useEffect(() => {
        if (viewState == 'refresh') {
            setSelectedItem(undefined)
            setViewState('view') 
            return
        }
        setIsSubmitting(true)        
        pluginCtx.lots.dispatchGridFilter({type: 'sortByFilters', payload: {gridColumnFilter: gridFilter.columnFilters}})

        lotsByGoodsDataProvider.getViewByGoods(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }
            setIsSubmitting(false)
        })

    }, [viewState, gridFilter])

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent()
    }, [tabCtx.currentTab])

    return <>
        <PluginWrapper>
            <ToolbarWrapper withoutMarginBottom={true}>
                <DefaultLotsByCommandsPanel
                    rootTranslation='accounting.lotsByGoods.'
                    selectedIdGoods={selectedItem?.idGoodsGlobal}
                    selectedGoodsName={selectedItem?.goodsName}
                    selectedIdLot={selectedItem?.idGlobal}
                    selectedItem={[selectedItem, setSelectedItem]}
                    dispatchGridFilter={dispatchGridFilter}
                    dataProvider={lotsByGoodsDataProvider}
                    goodsDataProvider={goodsDataProvider}
                    creatorModalJsx={LotsByGoodsCreatorModal}
                    createGoodsModalJsx={GoodsCreatorModal}
                    pluginSettings={props.plugin}
                    setViewState={(vs: GridStateType) => setViewState(vs)} 
                    idVariantTable={IdTableVariant.LotsByGoods}
                    filters={getFiltersForPrint(pluginSettings,gridFilter)}
                />
            </ToolbarWrapper>
            <div className={styles.pluginWorkspace}>
                <div
                    className={styles.gridWrapper}
                >
                    <div className={styles.masterGrid}>
                        {
                            isSubmitting ? <Spinner /> : <DefaultGrid
                                gridId={props.gridId}
                                data={data}
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                totalCount={totalCount}
                                separator
                                dataProvider={lotsByGoodsDataProvider}
                                getView={(gridFilter,callback) => { 
                                    lotsByGoodsDataProvider.getViewByGoods(gridFilter, (data) => {
                                        setSelectedItem(undefined)
                                        callback(data)
                                    })
                                }}
                                filter={gridFilter}
                                plugin={props.plugin}
                                setPluginSettings={(plugin) => setPluginSettings(plugin)}
                                selectedItem={selectedItem}
                                onSelect={(row) => {
                                    setSelectedItem(row)
                                    row
                                        ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.lotsByGoods)
                                        : pluginCtx.masterGrid.onUnselectEvent()
                                }}
                                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                            >
                            </DefaultGrid>
                        }
                    </div>
                    <div className={styles.detailsTabView}>
                        {
                            selectedItem && detailsTabsPanel.tabs.length > 0 &&
                            <div>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: 'hidden', width: '100%' }}>
                                        <TabsPanel id="detailsTabsPanel" activeId={detailsTabsPanel.currentTab?.id} tabs={detailsTabsPanel.tabs}
                                            onActive={(tab) => { dispatchDetailsTabsPanel({ type: "activate", payload: tab }) }}>
                                        </TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map(item => {
                                        return <div
                                            key={item.id}
                                            className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}
                                        >
                                            {item.view.content}
                                        </div>
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </PluginWrapper>
    </>
}

export default LotsByGoodsPluginView