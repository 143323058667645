import { FC, useEffect, useState } from "react";
import { MultiplyIcon as CloseSvg } from "../../libs/corporate/icons/outlined/suggested/SymbolCollection";
import styles from "./alert.module.scss";
import classNames from "classnames";
import AlertIcon from "./alertIcon";
import ReactDOM from "react-dom";
import { MessageModalWindow } from "../modalWindows/MessageModalWindow";
import { useTranslation } from "react-i18next";
import { ApiErrorModalWindow } from "../modalWindows/ApiErrorModalWindow";
import { responseErrorType } from "../../@types/enumsGlobal";
import { ReactPortal } from "../modalWindows/ReactPortal";

interface IAlertProps {
    variant: AlertVariant,
    closeOnClick?: () => void,
    closable?: boolean,
    iconable?: boolean,
    global?: boolean
}

const Alert: FC<IAlertProps> = (props) => {

    function getAlertClasses() {
        switch (props.variant) {
            case "default": {
                return classNames(styles.alertInfo)
            }
            case "info": {
                return classNames(styles.alertInfo)
            }
            case "success": {
                return classNames(styles.alertSuccess)
            }
            case "danger": {
                return classNames(styles.alertDanger)
            }
            case "warning": {
                return classNames(styles.alertDanger)
            }
            case "error": {
                return classNames(styles.alertDanger)
            }
            case "exception": {
                return classNames(styles.alertDanger)
            }
        }
    }
    function getAlertIcon() {
        switch (props.variant) {
            case "default": {
                return (
                    <span>
                        <AlertIcon type="screamer" className={styles.alertIconInfo}></AlertIcon>
                    </span>
                )
            }
            case "info": {
                return (
                    <span>
                        <AlertIcon type="screamer" className={styles.alertIconInfo}></AlertIcon>
                    </span>
                )
            }
            case "success": {
                return (
                    <span>
                        <AlertIcon type="success" className={styles.alertIconSuccess}></AlertIcon>
                    </span>
                )
            }
            case "danger": {
                return (
                    <span>
                        <AlertIcon type="danger" className={styles.alertIconDanger}></AlertIcon>
                    </span>
                )
            }
            case "warning": {
                return (
                    <span>
                        <AlertIcon type="warning" className={styles.alertIconDanger}></AlertIcon>
                    </span>
                )
            }
            case "error": {
                return (
                    <span>
                        <AlertIcon type="warning" className={styles.alertIconDanger}></AlertIcon>
                    </span>
                )
            }
        }
    }
    function getCloseIcon() {
        switch (props.variant) {
            case "default": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconInfo}></CloseSvg>
                    </span>
                )
            }
            case "info": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconInfo}></CloseSvg>
                    </span>
                )
            }
            case "success": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconInfo}></CloseSvg>
                    </span>
                )
            }
            case "danger": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconDanger}></CloseSvg>
                    </span>
                )
            }
            case "warning": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconDanger}></CloseSvg>
                    </span>
                )
            }
            case "error": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconDanger}></CloseSvg>
                    </span>
                )
            }
            case "exception": {
                return (
                    <span>
                        <CloseSvg className={styles.closeIconDanger}></CloseSvg>
                    </span>
                )
            }
        }
    }
    return (
        <div className={classNames(getAlertClasses(), props.global ? styles.globalBottomRight : null)}>
            {props.iconable ? getAlertIcon() : null}
            <span className={styles.message}>{props.children}</span>
            {props.closable ? <span className={styles.closebtn} onClick={() => {
                if (props.closeOnClick) {
                    props.closeOnClick();
                }

            }}>{getCloseIcon()}</span> : null}
        </div>
    )
}

interface IGlobalAlertProps {
    variant: AlertVariant,
    title: string
    instance?: string
    detail?: string
    statusCode?: number
    alertElementId: string,
    onClose: () => void,
    autoCloseTimout?: number;
}


const GlobalAlert: FC<IGlobalAlertProps> = (props) => {
    const [timerId, setTimerId] = useState<number>()
    const [openMoreInfo, setOpenMoreInfo] = useState<boolean>(false)
    const { t } = useTranslation();
    const baseT = (value: string) => t('errors.' + value)


    useEffect(() => {
        if (openMoreInfo) {
            let alertElement = document.getElementById(props.alertElementId) as Element;
            let tId = window.setTimeout(function () {
                ReactDOM.unmountComponentAtNode(alertElement)
            }, 100000)
            setTimerId(tId)
            return clearTimeout(timerId as number)
        } else {
            let alertElement = document.getElementById(props.alertElementId) as Element;
            let tId = window.setTimeout(function () {
                ReactDOM.unmountComponentAtNode(alertElement)
            }, 3000)
            setTimerId(tId)
            return clearTimeout(timerId as number)
        }
    }, [openMoreInfo])
    const returnMessage = () => {
        switch (props.variant) {
            case 'exception': {
                return <span onClick={() => setOpenMoreInfo(true)} className={styles.title}>
                    {props.title?.substring(0, 35) + '...'}
                </span>
            }
            case "error": {
                return <span onClick={() => setOpenMoreInfo(true)} className={styles.title}>{baseT('requestExecutionError')}</span>
            }
            case "warning": {
                return <span onClick={() => setOpenMoreInfo(true)} className={styles.title}>{baseT('warning')}</span>
            }
            default: {
                return props.title
            }
        }

    }
    return (
        <>
            <Alert variant={props.variant} global={true} closable={true} iconable={true} closeOnClick={() => { props.onClose() }}>
                {
                    returnMessage()
                }
            </Alert>
            {openMoreInfo &&
            <ReactPortal wrapperId="react-portal-modal-container-alert">
                <ApiErrorModalWindow
                    header={responseErrorType[props.variant]}
                    instance={props.instance ?? ''}
                    title={props.title}
                    detail={props.detail ?? ''}
                    status={props.statusCode}
                    ok={() => { setOpenMoreInfo(false) }}
                    cancel={() => { setOpenMoreInfo(false) }}
                />
            </ReactPortal>
            }
        </>
    )
}

export { GlobalAlert }