import { Dropdown, Menu } from "antd";
import { FC } from "react";
import { IIconButtonProps } from "../buttons/iconButtons/BaseIconButton";

export interface IItem {
    label: string;
    onClick: () => void;
    disabled?: boolean;
}

interface IButtonDropDownProps {
    items: IItem[];
    button?: FC<IIconButtonProps>;
}

export const ButtonDropDown: FC<IButtonDropDownProps> = (props) => {
    const itemsMenu = props.items.map((x, i) => {
        return {
            label: <p onClick={() => x.onClick()}>{x.label}</p>,
            disabled: x.disabled,
            key: i,
        };
    });

    const menu = <Menu items={itemsMenu} />;

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            {props.button ? <props.button sizeVariant="normal" /> : props.children}
        </Dropdown>
    );
};
