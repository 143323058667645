import classNames from "classnames";
import { FC, useState } from "react";
import styles from "./checkbox.module.scss"

type LabelSize = 'small' | 'normal'

export interface ICheckboxProps {
    defaultChecked?: boolean
    disabled?: boolean
    label?: string
    id?: string
    onChanged?: (checked: boolean) => void
    className?: string
    labelSize?: LabelSize
}

const CheckBox: FC<ICheckboxProps> = (props) => {
    //const [value] = useState<boolean>(props.defaultChecked ?? false)



    return (
        <div className={classNames(props.labelSize === 'small' ? styles.checkBox : styles.checkBoxNormal, props.className)}>
            <input
                type='checkbox'
                id={props.id}
                checked={props.defaultChecked}
                disabled={props.disabled}
                onChange={() => props.onChanged?.(!props.defaultChecked)} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}
export { CheckBox }
