import { times } from "lodash";
import { IGoodsDTO, IGoodsViewDTO } from "../../libs/coreapi-dto/dirs/goods";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";
import { IGoodsByIdDTO } from "../../libs/core-api-requests/dirs/goodsRequest";

export class GoodsDataProvider extends DictionaryDataProvider<IGoodsViewDTO, IGoodsDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IGoodsViewDTO, IGoodsDTO>> {
        return this._coreApiService.goodsRequest
    }

    attachToGroup(idGoodsGlobal: string, idGoodsGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.goodsRequest
            .then(r => r
                .attachToGroup(idGoodsGlobal, idGoodsGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    moveFromGroup(idGoodsGlobal: string, fromIdGoodsGroupGlobal: string, toIdGoodsGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.goodsRequest
            .then(r => r
                .moveFromGroup(idGoodsGlobal, fromIdGoodsGroupGlobal, toIdGoodsGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    detachFromGroup(idGoodsGlobal: string, idGoodsGroupGlobal: string, callback: (e) => void) {
        this._coreApiService.goodsRequest
            .then(r => r
                .detachFromGroup(idGoodsGlobal, idGoodsGroupGlobal, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e)
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }
    
    getGoodsByBarcode(externalBarcode: string, callback: (data: IGoodsByIdDTO[]) => void) {
        this._coreApiService.goodsRequest
          .then(r => r.getGoodsByBarcode(externalBarcode, (e) => {
            if (e.respType === 'isCompleted') {
              callback(e.data)
            } else {
              throw new Error(e.message.text);
            }
          }))
      }

    getGoodsByCode(code: string, callback: (data: IGoodsByIdDTO[]) => void) {
        this._coreApiService.goodsRequest
          .then(r => r.getGoodsByCode(code, (e) => {
            if (e.respType === 'isCompleted') {
              callback(e.data)
            } else {
              callback(e.data)
              throw new Error(e.message.text);
            }
          }))
      }

    getViewByName(filter: IViewBody, callback?: (data: IGoodsViewDTO[], totalCount: number,) => void, signal?: AbortSignal | null) {
        this._coreApiService.goodsRequest
            .then(r => r
                .getViewByName(filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        try {
                            callback?.(e.data.entities, e.data.totalCount);
                        } catch (e) {
                            console.error(e)
                        }
                    }
                    else
                        throw new Error(e?.message.title ?? 'DictionaryDataProvider getViewByName !isCompleted');
                },signal)
            )
            .catch((e) => {
                throw new Error(e?.message.text ?? 'getViewByName DictionaryDataProvider')
            });
    }

}