const ActRevaluationItemGridPluginSettings: IPluginSettings = {
    name: "Акты переоценки",
    mnemocode: "act_revaluation_item_plugin",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "supplier",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 2,
            propertyName: "lotName",
            propertyType: "string",
            displayName: "Партия",
            visibility: true,
            width: 300,
        },
        {
            order: 3,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 200,
        },
        {
            order: 4,
            propertyName: "scalingRatioDisplayName",
            propertyType: "string",
            displayName: "Ед.измерения",
            visibility: true,
            width: 200,
        },
        {
            order: 5,
            propertyName: "quantity",
            propertyType: "number",
            displayName: "Кол-во",
            visibility: true,
            width: 100,
        },
        {
            order: 6,
            propertyName: "goodsName",
            propertyType: "string",
            displayName: "Товар",
            visibility: true,
            width: 200,
        },
        {
            order: 7,
            propertyName: "goodsCode",
            propertyType: "string",
            displayName: "Код товара",
            visibility: true,
            width: 100,
        },
        {
            order: 8,
            propertyName: "producerName",
            propertyType: "string",
            displayName: "Производитель",
            visibility: true,
            width: 200,
        },
        {
            order: 9,
            propertyName: "countryName",
            propertyType: "string",
            displayName: "Страна",
            visibility: true,
            width: 200,
        },
        {
            order: 10,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, пост.",
            visibility: true,
            width: 150,
        },
        {
            order: 13,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "Значение НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 22,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 23,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 24,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 25,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 26,
            propertyName: "producerPrice",
            propertyType: "number",
            displayName: "Цена производителя",
            visibility: true,
            width: 100,
        },
        {
            order: 27,
            propertyName: "comment",
            propertyType: "number",
            displayName: "Комментарий",
            visibility: false,
            width: 300,
        },{
            order: 28,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 150
        },{
            order: 29,
            propertyName: 'isGnvls',
            propertyType: "boolean",
            displayName: "ЖНВЛС",
            visibility: true,
            width: 100,
        },{
            order: 30,
            propertyName: 'isOsu',
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 100,
        }
    ],
};

export default ActRevaluationItemGridPluginSettings;
