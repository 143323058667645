import React, { FC, useEffect, useState } from 'react'
import { Accordion } from '../../../../../../components/controls/accordion'
import { DictionaryInput } from '../../../../../../components/controls/dictionaryInput'
import GridSelectorInput from '../../../../../../components/controls/GridSelectorInput'
import GridWrapper from '../../../../../../components/controls/GridWrapper'
import { IEntitySimpleDTO } from '../../../../../../libs/coreapi-dto/@types/common'
import { ContractorSelectorModal } from '../../../../../Dictionaries/Contractor'
import styles from './styles/BunchModalContent.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { DefaultGrid } from '../../../../../../components/grids/default/defaultGrid'
import useGridFilter, { CreateSelectorGridFilter, GridFilterAction } from '../../../../../../system/hooks/useGridFilter'
import BunchDbPluginSettings from './BuchDbPluginSettings'
import { usePluginContext } from '../../../../../../system/providers/plugin'
import { ICashRegisterCreate } from '../../../../../../libs/coreapi-dto/service/cashRegisters'
import { ICashRegister2StoreCreate, ICashRegister2StoreUpdate, ICashRegister2StoreViewDTO } from '../../../../../../libs/coreapi-dto/service/cashRegister2Store'
import { StoreSelectorModal } from '../../../../../Dictionaries/Store'
import { useAppContext } from '../../../../../../system/providers/appContextProvider'
import { ContractorDataProvider } from '../../../../../../Services/DataProviders/ContractorDataProvider'
import { TypeOpen } from '../../../CashRegistersPluginView'
import { CashRegister2StoreCommandPanel } from './CashRegister2StoreCommandPanel'
import { DocumentType, LoadingStatus } from '../../../../../../@types/enumsGlobal'
import { CheckBox } from '../../../../../../components/controls/checkbox'
import { useTranslation } from 'react-i18next'
import { IStoreDTO, IStoreViewDTO } from '../../../../../../libs/coreapi-dto/dirs/store'
import { StoreDataProvider } from '../../../../../../Services/DataProviders/StoreDataProvider'
import renderGlobalAlert from '../../../../../../system/hooks/useGlobalAlert'
import { IContractorDTO } from '../../../../../../libs/coreapi-dto/dirs/contractor'
import { ChangeableGrid, IChangeDataGrid } from '../../../../../../components/grids/changeableGrid/ChangeableGrid'
import { getStyleCell } from '../../../../../../system/functions/getStyleCell'
import { CashRegister2StoreDataProvider } from '../../../../../../Services/DataProviders/CashRegister2StoreDataProvider'


interface IBunchModalContentProps {
    selectedCashRegisterItem: IGridRow | undefined
    createBody: ICashRegisterCreate
    setCreateBody: (value: ICashRegisterCreate) => void
    cashRegister2Store: ICashRegister2StoreViewDTO[]
    setCashRegister2Store: (value: ICashRegister2StoreViewDTO[]) => void
    typeOpen: TypeOpen
    loadingStatus: LoadingStatus
    refresh: () => void
    gridFilter: IGridFilter
    dispatchGridFilter: (value: GridFilterAction) => void
    deletedStores: { idCashRegister2StoreGlobal: string, idStoreGlobal: string }[]
    setDeletedStores: (value: { idCashRegister2StoreGlobal: string, idStoreGlobal: string}[]) => void
    errors: Partial<Record<keyof ICashRegisterCreate, string>>
    setErrors: React.Dispatch<React.SetStateAction<Partial<Record<keyof ICashRegisterCreate, string>>>>
    lockFromPermission?: boolean
    validateDifferentContractors?: boolean
}


export const BunchModalContent: FC<IBunchModalContentProps> = (props) => {
    const appContext = useAppContext()
    const pluginCtx = usePluginContext();

    const storeDataProvider = new StoreDataProvider(appContext.coreApiService)
    const contractorDataProvider = new ContractorDataProvider(appContext.coreApiService);
    const cashRegister2StoreDataProvider = new CashRegister2StoreDataProvider(appContext.coreApiService);

    const [storeGridFilter, dispatchStoreGridFilter] = useGridFilter(CreateSelectorGridFilter);
    const [contractorGridFilter, dispatchContractorGridFilter] = useGridFilter(CreateSelectorGridFilter);

    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [openCashRegister2Store, setOpenCashRegister2Store] = useState<boolean>(false);
    const [contractor, setContractor] = useState<IEntitySimpleDTO>();

    const { t } = useTranslation();
    const bunchT = (value: string) => t("modals.settingKkm.bunchContent." + value);

    const addEntities = (newEntities: ICashRegister2StoreViewDTO[]) => {
        let newEntitiesArr = newEntities
        if (props.cashRegister2Store.length > 0) {
            for (let i = 0; i < newEntitiesArr.length; ++i) {
                for (var j = i + 1; j < newEntitiesArr.length; ++j) {
                    if (newEntitiesArr[i].idStoreGlobal === newEntitiesArr[j].idStoreGlobal)
                    newEntitiesArr.splice(j--, 1);
                }
            }
        }

        props.setCashRegister2Store(newEntitiesArr);
    };

    useEffect(() => {

        // Показывать в модальном окне подбора складов, исключив выбранные ранее
        updateStoreGridFilterWithExcludes(props.cashRegister2Store);

        // Настройка фильтров складов и КА
        if (props.cashRegister2Store.length > 0) {
            dispatchContractorGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "IdGlobal",
                    value: props.createBody.idContractorGlobal,
                    operator: "Eq"
                }
            });
        } else {
            dispatchContractorGridFilter({
                type: "deleteColumnFilter",
                payload: "IdGlobal"
            });
        }
    }, [props.cashRegister2Store])


    useEffect(() => {
        if (props.createBody.idContractorGlobal) {
            contractorDataProvider.getById(props.createBody.idContractorGlobal, e => {
                setContractor({idGlobal: e.idGlobal, displayName: e.name});
                dispatchStoreGridFilter({
                    type: "addColumnFilter",
                    payload: {
                        name: "IdContractorGlobal",
                        value: e.idGlobal,
                        operator: "Eq"
                    }
                });

                if (props.cashRegister2Store.length > 0) {
                    dispatchContractorGridFilter({
                        type: "addColumnFilter",
                        payload: {
                            name: "IdGlobal",
                            value: e.idGlobal,
                            operator: "Eq"
                        }
                    });
                } else {
                    dispatchContractorGridFilter({
                        type: "deleteColumnFilter",
                        payload: "IdGlobal"
                    });
                }
            })
        }
    }, [props.createBody.idContractorGlobal])

    const updateStoreGridFilterWithExcludes = (excludes: ICashRegister2StoreViewDTO[]) => {

        if (excludes.length > 0) {
            let expression = "";
            excludes.forEach((x, i) => {
                expression += `"IdGlobal" <> '${x.idStoreGlobal}'${i < excludes.length - 1 ? " and " : ''}`
            })

            dispatchStoreGridFilter( { type: "addCustomWhereExpressionFilter", payload: expression })
        } else {
            dispatchStoreGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    };

    const makeCustomExpressionForStoresView = (stores: IEntitySimpleDTO[]) => {

        let expression = "";
        stores.forEach((item, i) => {
            expression += `"IdGlobal" = '${item.idGlobal}'${i < stores.length - 1 ? " or " : ''}`
        })

        return expression;
    };

    return (
        <>
            <Accordion insertedAccordion opened={true} caption={bunchT("accordeon.caption")}/*'Реквизиты'*/ id="additionalInvoiceInfo">
                <GridWrapper cols={3}>
                    <DictionaryInput
                        required
                        label={bunchT("accordeon.kkmName")}//'Наименование ККМ'
                        className={styles.field_NameKkm}
                        value={props.createBody.nameCashRegister}
                        onChange={(e) => {
                            e ? props.setCreateBody({ ...props.createBody, nameCashRegister: e }) :
                                props.setCreateBody({ ...props.createBody, nameCashRegister: '' })
                        }}
                        hiddenMoreButton
                        hiddenClearButton

                        error={props.errors.nameCashRegister} onFocus={() => props.setErrors({ ...props.errors, nameCashRegister: undefined })}
                        disabled={props.lockFromPermission}
                    />
                    <GridSelectorInput
                        required
                        selectorModalJsx={ContractorSelectorModal}
                        className={styles.field_Contractor}
                        //id={"dictionaryInputInvoiceContractor"}
                        label={bunchT("accordeon.contractor")}//'Контрагент'
                        selectedEntity={contractor}
                        gridFilter={contractorGridFilter}
                        onSelect={(entity) => {
                            props.setCreateBody({ ...props.createBody, idContractorGlobal: entity.idGlobal });
                            setContractor(entity);

                            dispatchStoreGridFilter({
                                type: "addColumnFilter",
                                payload: {
                                    name: "IdContractorGlobal",
                                    value: entity.idGlobal,
                                    operator: "Eq"
                                }
                            });
                        }}
                        onClear={() => {
                            props.setCreateBody({ ...props.createBody, idContractorGlobal: "" });
                            setContractor(undefined);

                            // Если очищен КА, но выбраны склады ранее
                            // ориентироваться на фильтр от КА первого склада из списка
                            if (props.cashRegister2Store?.length === 0) {
                                dispatchStoreGridFilter({
                                    type: "deleteColumnFilter",
                                    payload: "IdContractorGlobal"
                                });

                                dispatchContractorGridFilter({
                                    type: "deleteColumnFilter",
                                    payload: "IdGlobal"
                                })
                            } else {
                                storeDataProvider.getById(props.cashRegister2Store[0].idStoreGlobal, (storeDto: IStoreDTO) => {
                                    const idContractorGlobal = storeDto.contractor?.idGlobal;

                                    // Фильтр складов по КА
                                    dispatchStoreGridFilter({
                                        type: "addColumnFilter",
                                        payload: {
                                            name: "IdContractorGlobal",
                                            value: idContractorGlobal,
                                            operator: "Eq"
                                        }
                                    });

                                    // Выбираем только КА от склада
                                    dispatchContractorGridFilter({
                                        type: "addColumnFilter",
                                        payload: {
                                            name: "IdGlobal",
                                            value: idContractorGlobal,
                                            operator: "Eq"
                                        }
                                    });
                                });
                            }                            
                        }}
                        error={props.errors.idContractorGlobal} onFocus={() => props.setErrors({ ...props.errors, idContractorGlobal: undefined })}
                        disabled={props.lockFromPermission}
                        treeViewCheckDirectoryType='pharmacy'
                    />
                </GridWrapper>
                <GridWrapper cols={3}>
                    <DictionaryInput
                        label={bunchT("accordeon.kkmProducerNo")}//'Номер производителя ККМ'
                        value={props.createBody.producerNumber}
                        onChange={(e) => {
                            e ? props.setCreateBody({ ...props.createBody, producerNumber: e }) :
                                props.setCreateBody({ ...props.createBody, producerNumber: '' })
                        }}
                        hiddenMoreButton
                        hiddenClearButton
                        disabled={props.lockFromPermission}
                    />
                    <DictionaryInput
                        label={bunchT("accordeon.kkmNo")}//'Номер ККМ'
                        value={props.createBody.numberCashRegister}
                        onChange={(e) => {
                            e ? props.setCreateBody({ ...props.createBody, numberCashRegister: e }) :
                                props.setCreateBody({ ...props.createBody, numberCashRegister: '' })
                        }}
                        hiddenMoreButton
                        hiddenClearButton
                        disabled={props.lockFromPermission}
                    />
                    <DictionaryInput
                        label={bunchT("accordeon.additionalKkmInfo")}//'Дополнительная информация о ККМ'
                        value={props.createBody.additionalInformation}
                        onChange={(e) => {
                            e ? props.setCreateBody({ ...props.createBody, additionalInformation: e }) :
                                props.setCreateBody({ ...props.createBody, additionalInformation: '' })
                        }}
                        hiddenMoreButton
                        hiddenClearButton
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <GridWrapper cols={2}>
                    <CheckBox
                        id={"kkmDisabled"}
                        label={bunchT("accordeon.schemeApiCheckbox")}//"ККТ отключена, отправка схемы чека через API"
                        defaultChecked={props.createBody.kkmDisabled}
                        onChanged={(e) => props.setCreateBody({ ...props.createBody, kkmDisabled: e })}
                        disabled={props.lockFromPermission}
                    />
                    <CheckBox
                        id={"useRawKiz"}
                        label={bunchT("accordeon.kizFromScannerCheckbox")}//"Получать КИЗ из сканера"
                        defaultChecked={props.createBody.useRawKiz}
                        onChanged={(e) => props.setCreateBody({ ...props.createBody, useRawKiz: e })}
                        disabled={props.lockFromPermission}
                    />
                </GridWrapper>
                <div className={styles.wrap}>
                    <div className={styles.wrap_label}><br></br>{bunchT("accordeon.storePanel")}</div>
                    <CashRegister2StoreCommandPanel
                        add={{ onClick: () => { setOpenCashRegister2Store(true) }, disabled: props.lockFromPermission }}
                        delete={{
                            onClick: () => {

                                const idStoreGlobal = selectedItem?.cells
                                    .find(x => x.propertyName.toLowerCase() === "idStoreGlobal".toLowerCase())?.value as string;

                                if (idStoreGlobal.length > 0) {
                                    props.setCashRegister2Store([...props.cashRegister2Store.filter(x => x.idGlobal !== selectedItem?.idGlobal as string)])
                                    if (!props.deletedStores.some(x => x.idStoreGlobal === idStoreGlobal))
                                        props.setDeletedStores([...props.deletedStores,
                                        {
                                            idCashRegister2StoreGlobal: selectedItem?.idGlobal as string,
                                            idStoreGlobal: idStoreGlobal
                                        }]);

                                    setSelectedItem(undefined)
                                }

                            }, disabled: (selectedItem ? false : true) || props.lockFromPermission
                        }}
                        refresh={{
                            onClick: () => {
                                {
                                    props.refresh()
                                    setSelectedItem(undefined)
                                }
                            }
                        }}
                    />
                </div>
                <ChangeableGrid
                    gridId={uuidv4()}
                    data={props.cashRegister2Store}
                    filter={props.gridFilter}
                    hiddenPagination={undefined}
                    totalCount={totalCount}
                    plugin={BunchDbPluginSettings}
                    selectedItem={selectedItem}
                    getStyleCell={(cell, cells) => getStyleCell(cell, cells, DocumentType.importInvoice)}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        row
                            ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.importInvoice)
                            : pluginCtx.masterGrid.onUnselectEvent()
                    }}
                    onSort={(i) =>
                        { props.dispatchGridFilter({ type: "sort", payload: i.propertyName }) }
                    }
                    onFilterDelete={(i) => { props.dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { props.dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => {
                        props.dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                    onChangeData={(value) => {
                        props.setCashRegister2Store(props.cashRegister2Store.map(store => {
                            if (store.idGlobal === value.idGlobal) {
                                console.log(store)
                                return {
                                    idGlobal: store.idGlobal,
                                    idStoreGlobal: store.idStoreGlobal,
                                    onlyView: value.value as boolean,
                                    name: store.name
                                };
                            }
                            return store;
                        }))

                        let oldStore = props.cashRegister2Store.find(x => x.idGlobal === value.idGlobal);
                        let updatedStore = { ...oldStore,
                            onlyView: value.value,
                            idCashRegisterGlobal: props.createBody.idGlobal
                        } as ICashRegister2StoreUpdate

                        cashRegister2StoreDataProvider.updateCashRegister2Store(value.idGlobal, updatedStore, () => {
                            return
                        })
                    }}
                />

            </Accordion>
            {
                openCashRegister2Store &&
                <StoreSelectorModal
                    multipleSelect={true}
                    gridFilter={storeGridFilter}
                    ok={(stores: IEntitySimpleDTO[]) => {

                        // TODO: рассмотреть вариант валидации выбранных складов
                        // many stores -> one contractor
                        // на уровне компонента StoreSelectorModal

                        const updateStores = (storeEntities: IEntitySimpleDTO[]) => {
                            addEntities([
                                ...props.cashRegister2Store, 
                                ...storeEntities.map((x: IEntitySimpleDTO) => ({ 
                                    idGlobal: uuidv4(), 
                                    idStoreGlobal: x.idGlobal, 
                                    onlyView: false, 
                                    name: x.displayName 
                                } as ICashRegister2StoreViewDTO))]);
                            setOpenCashRegister2Store(false);
                        }

                        // (Валидация) Если контрагент пустой, назначить контрагента по складу

                        const hasContractor: boolean = "idContractorGlobal" in props.createBody &&
                            props.createBody.idContractorGlobal.length > 0;

                        if (props.validateDifferentContractors && !hasContractor) {

                            // Валидация складов
                            const storeGridFilter: IGridCustomWhereExpressionFilter = {
                                expression: makeCustomExpressionForStoresView(stores),
                                pageNumber: 1,
                                numberPerPage: stores.length,
                                columnFilters: []
                            };

                            // Получить информацию по выбранным складам
                            // при невыбранном КА
                            storeDataProvider.getView(storeGridFilter, (storesView: IStoreViewDTO[]) => {

                                const contratorSet = new Set(storesView.map(x => x.idContractorGlobal));
                                const isValid: boolean = contratorSet.size === 1;

                                if (isValid) {
                                    contractorDataProvider.getById([...contratorSet][0], (c: IContractorDTO) => {
                                        props.setCreateBody({ ...props.createBody, idContractorGlobal: c.idGlobal });
                                        setContractor({ idGlobal: c.idGlobal, displayName: c.name });

                                        dispatchStoreGridFilter({
                                            type: "addColumnFilter",
                                            payload: {
                                                name: "IdContractorGlobal",
                                                value: c.idGlobal,
                                                operator: "Eq"
                                            }
                                        });

                                        updateStores(stores);
                                    })
                                } else {
                                    renderGlobalAlert({
                                        variant: "warning",
                                        title: bunchT("accordeon.diffContractorsWarning"),
                                        statusCode: 0,
                                    })
                                }
                            });
                        } else {
                            updateStores(stores);
                        }
                    }}
                    cancel={() => setOpenCashRegister2Store(false)}
                />
            }
        </>
    )
}