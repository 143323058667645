import { useState } from "react";
import { IKizOsuEditDTO } from "../../../../../libs/coreapi-dto/dirs/KizOsu";
import useGridFilter from "../../../../../system/hooks/useGridFilter";
import { ChangeableGrid, IChangeDataGrid } from "../../../../../components/grids/changeableGrid/ChangeableGrid";
import { v4 as uuidv4 } from "uuid";
import Styles from "../Styles/ScanModalStyle.module.scss";
import { KizOsuModalViewPluginSettings } from "./KizOsuModalViewPluginSettings";
import { useTranslation } from "react-i18next";

interface IKizOsuModalContentProps extends ISelectorGridProps {
    kizOsuDto: IKizOsuEditDTO[];
    selectedItem: IGridRow | undefined;
    onBlur?: (value: number) => void;
    setSelectedItem: (data: IGridRow | undefined) => void;
    setKizOsuData: (data: IKizOsuEditDTO) => void;
    isEditKizOsu?: boolean;
    numerator?: number;
    documentType?: DocumentType;
}

//filterCount
export const KisOsuModalContent: React.FC<IKizOsuModalContentProps> = (props) => {
    const [totalCount, ] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const { t } = useTranslation();
    const baseT = (value: string) => t("kizOsu." + value);
    const isEditKizs: boolean = props.isEditKizOsu ?? false;

    return (
        <>
            <div className={Styles.InputPanel}>{baseT("warningMessageOne")}</div>  
            <div className={Styles.InputPanel}>{baseT("warningMessageTwo")}</div>          

            {
                <ChangeableGrid
                    autoSelect
                    gridId={uuidv4()}
                    data={props.kizOsuDto}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={KizOsuModalViewPluginSettings}
                    hiddenPagination={undefined}
                    numbering={true}
                    selectedItem={props.selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    numerator={props.numerator}
                    onSelect={(row) => {
                        props.setSelectedItem(row);
                        props.onSelect?.(row);
                    }}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onChangeData={(value: IChangeDataGrid) => {
                       
                    }}
                    isEditKizs={isEditKizs}
                />
            }
        </>
    );
};
