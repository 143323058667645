import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { IdTableVariant } from "../../../../@types/enumsGlobal";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { IInvoiceOutGetDTO, IInvoiceOutUpdateDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { IInvoiceOutItemEditDTO, IInvoiceOutItemGetDTO, IInvoiceOutItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceOutItem";

export const useInvoiceOutState = (idGlobal: string, defaultEntity: IInvoiceOutGetDTO | null | undefined, callback: (entity: IInvoiceOutUpdateDTO) => void) => {
    const [store, setStore] = useState<IEntitySimpleDTO | null>(defaultEntity?.store ?? null);
    const [contractorTo, setContractorTo] = useState<IEntitySimpleDTO | null>(defaultEntity?.contractorTo ?? null);
    const [payer, setPayer] = useState<IEntitySimpleDTO | null>(defaultEntity?.payer ?? null);
    const [pharmacy, setPharmacy] = useState<IEntitySimpleDTO | null>();
    const [storeFromLot, setStoreFromLot] = useState<IEntitySimpleDTO | null>();

    const [entity, setEntity] = useState<IInvoiceOutUpdateDTO>({
        idGlobal: idGlobal,
        documentDate: defaultEntity?.documentDate ?? DateTime.now(),
        datePayment: defaultEntity?.datePayment ?? null,
        comment: defaultEntity?.comment ?? null,
        retailCostInfo: defaultEntity?.retailCostInfo,
        supplierCostInfo: defaultEntity?.shipmentType,
        discountCostInfo: defaultEntity?.discountCostInfo,
        shipmentType: defaultEntity?.shipmentType ?? 2,
        dateShipment: defaultEntity?.dateShipment ?? null,
        isAgentContract: defaultEntity?.isAgentContract ?? null,
        items:
            defaultEntity?.items?.map((item) => {
                return {
                    idGlobal: item.idGlobal,
                    idLotGlobal: item.lot.idGlobal,
                    quantity: item.quantity,
                    retailCostInfo: item.retailCostInfo,
                    supplierCostInfo: item.supplierCostInfo,
                    discountCostInfo: item.discountCostInfo,
                    isKiz: item.isKiz,
                    isKizBox: item.isKizBox,
                    suppler: item.supplier,
                    dateCreated: item.dateCreated,
                    kizs: item.kizs,
                    kizBoxes: item.kizBoxes,
                    scalingRatioName: item.scalingRatioName,
                    isOsu: item.isOsu,
                    kizOsuDto: item.kizOsuDto
                } as IInvoiceOutItemEditDTO;
            }) ?? [],
    } as IInvoiceOutUpdateDTO);

    useEffect(() => {
        callback({
            ...entity,
            idStoreGlobal: store?.idGlobal,
            idContractorToGlobal: contractorTo?.idGlobal,
            idPayerGlobal: payer?.idGlobal,
            retailCostInfo: {
                sum: 0,
                vatSum: entity.items.reduce((sum, item) => sum + (item.retailCostInfo?.vatSum ?? 0), 0),
                sumIncVat: entity.items.reduce((sum, item) => sum + (item.retailCostInfo?.sumIncVat ?? 0), 0),
            },
            supplierCostInfo: {
                sum: 0,
                vatSum: entity.items.reduce((sum, item) => sum + (item.supplierCostInfo?.vatSum ?? 0), 0),
                sumIncVat: entity.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sumIncVat ?? 0), 0),
            },
            discountCostInfo: {
                sum: 0,
                vatSum: entity.items.reduce((sum, item) => sum + (item.discountCostInfo?.vatSum ?? 0), 0),
                sumIncVat: entity.items.reduce((sum, item) => sum + (item.discountCostInfo?.sumIncVat ?? 0), 0),
            },
        });
    }, [defaultEntity, entity, store, contractorTo, payer, entity.items]);

    const operativeReserve = useOperativeReserve();

    return {
        documentDate: {
            value: entity.documentDate,
            setValue: (value: DateTime | null) => setEntity({ ...entity, documentDate: value }),
        },
        storeFromLot: {
            value: storeFromLot,
            setValue: (value: IEntitySimpleDTO | null) => setStoreFromLot(value),
        },
        pharmacy: {
            value: pharmacy,
            setValue: (value: IEntitySimpleDTO | null) => setPharmacy(value),
        },
        store: {
            value: store,
            setValue: (value: IEntitySimpleDTO | null) => setStore(value),
        },
        isAgentContract: {
            value: entity.isAgentContract,
            setValue: (value: boolean) => setEntity({ ...entity, isAgentContract: value }),
        },
        contractorTo: {
            value: contractorTo,
            setValue: (value: IEntitySimpleDTO | null) => setContractorTo(value),
        },
        payer: {
            value: payer,
            setValue: (value: IEntitySimpleDTO | null) => setPayer(value),
        },
        datePayment: {
            value: entity.datePayment,
            setValue: (value: DateTime | null) => setEntity({ ...entity, datePayment: value }),
        },
        comment: {
            value: entity.comment,
            setValue: (value: string | null) => setEntity({ ...entity, comment: value }),
        },
        shipmentType: {
            value: entity.shipmentType,
            setValue: (value: number | null) => setEntity({ ...entity, shipmentType: value }),
        },
        dateShipment: {
            value: entity.dateShipment,
            setValue: (value: DateTime | null) => setEntity({ ...entity, dateShipment: value }),
        },

        findItem: (idGlobal: string) => {
            return entity.items.find((item) => item.idGlobal === idGlobal);
        },

        findItemByLot: (idLotGlobal: string) => {
            return entity.items.find((item) => item.idLotGlobal === idLotGlobal);
        },

        addItem: (item: IInvoiceOutItemEditDTO) => {
            operativeReserve.send(
                {
                    idLot: item.idLotGlobal as string,
                    idTable: IdTableVariant.InvoiceOut,
                    idDocument: idGlobal,
                    idDocumentItem: item.idGlobal,
                    quantity: item.quantity,
                },
                () => setEntity({ ...entity, items: [...entity.items, item] })
            );
        },

        editItem: (editedItem: IInvoiceOutItemEditDTO) => {
            const currentItem = entity.items.find((i) => i.idGlobal === editedItem.idGlobal);
            if (currentItem?.quantity !== editedItem.quantity) {
                operativeReserve.send({
                    idLot: editedItem.idLotGlobal as string,
                    idTable: IdTableVariant.InvoiceOut,
                    idDocument: idGlobal,
                    idDocumentItem: editedItem.idGlobal,
                    quantity: editedItem.quantity,
                });
            }

            setEntity({ ...entity, items: [...entity.items.filter((i) => i.idGlobal !== editedItem.idGlobal), editedItem] });
        },

        deleteItem: (idGlobal: string, successCallback: (idGlobal: string) => void) => {
            const itemForDelete = entity.items.find((i) => i.idGlobal === idGlobal);

            if (!itemForDelete) {
                successCallback(idGlobal);
                return;
            }
            operativeReserve.delete(
                {
                    idLot: itemForDelete.idLotGlobal as string,
                    idTable: IdTableVariant.InvoiceOut,
                    idDocument: entity.idGlobal,
                    idDocumentItem: itemForDelete.idGlobal,
                },
                () => {
                    setEntity({ ...entity, items: entity.items.filter((i) => i.idGlobal !== idGlobal) });
                    successCallback(idGlobal);
                }
            );
        },

        viewItems: (items: IInvoiceOutItemGetDTO[]) => {
            return items.map((item) => {
                return {
                    idGlobal: item.idGlobal,
                    lotName: item.lot.displayName,
                    quantity: item.quantity,
                    isKiz: item.isKiz,
                    isKizBox: item.isKizBox,
                    retailPrice: item.retailCostInfo.price,
                    retailPriceIncVat: item.retailCostInfo.priceIncVat,
                    retailVat: item.retailCostInfo.vat,
                    retailVatSum: item.retailCostInfo.vatSum,
                    retailSum: item.retailCostInfo.sum,
                    retailSumIncVat: item.retailCostInfo.sumIncVat,
                    scalingRatioName: item.scalingRatioName,
                    supplierPrice: item.supplierCostInfo.price,
                    supplierPriceIncVat: item.supplierCostInfo.priceIncVat,
                    supplierVat: item.supplierCostInfo.vat,
                    supplierVatSum: item.supplierCostInfo.vatSum,
                    supplierSum: item.supplierCostInfo.sum,
                    supplierSumIncVat: item.supplierCostInfo.sumIncVat,
                    discountPrice: item.discountCostInfo.price,
                    discountPriceIncVat: item.discountCostInfo.priceIncVat,
                    discountVat: item.discountCostInfo.vat,
                    discountVatSum: item.discountCostInfo.vatSum,
                    discountSum: item.discountCostInfo.sum,
                    discountSumIncVat: item.discountCostInfo.sumIncVat,
                    supplier: item.supplier,
                    dateCreated: item.dateCreated,
                    isOsu: item.isOsu
                } as IInvoiceOutItemViewDTO;
            });
        },
    };
};

export default useInvoiceOutState;
