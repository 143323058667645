import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {GridFilterAction} from '../../system/hooks/useGridFilter';
import FlexColumn from '../controls/FlexColumn';
import LotsCommandsPanel from './LotsCommandsPanel';
import {useTabsContext} from '../../system/providers/tabsProvider';
import {ILotViewDTO} from '../../libs/coreapi-dto/accounting/lot';
import {SearchModalWindow} from '../modalWindows/SearchModalWindow';
import {SearchModalPanel} from './SearchModalPanel';
import { IOption } from '../selects/select';

interface IDefaultLotPanelProps<TEntityViewDTO, TEntityDTO> {
  openGtd: () => void;
  openSeries: () => void;
  selectedItemLot: IGridRow | undefined;
  data: ILotViewDTO[];
  isModal?: boolean;
  dispatchGridFilter: (value: GridFilterAction) => void;
  docId: string | null;
  pluginSettings: IPluginSettings;
  gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>];
  disableSeries?: boolean;
  disableKizExchange?: boolean;
  searchQuery?: string;
}

const DefaultLotCommandsPanel = <TEntityViewDTO, TEntityDTO>(
  props: PropsWithChildren<IDefaultLotPanelProps<TEntityViewDTO, TEntityDTO>>
) => {
  const tabsContext = useTabsContext();
  const defaultOptionFilters = [{displayName: 'Наименование товара', value: 'goodsName'},
  {displayName: 'Код товара', value: 'goodsCode'}] as IOption[];

  const isGoodsCode = () => {
    if (props.gridFilter?.[0].columnFilters) {
      let filter = props.gridFilter?.[0].columnFilters.find((x) => x.name === 'goodsCode')
      if (filter) {     
        return filter
      }
    }
    return undefined
  }

  const getOption = () => {
    if (isGoodsCode())
      return defaultOptionFilters[1]
    return defaultOptionFilters[0]
  }
  
  const [optionFilter, setOptionFilter] = useState<IOption>(getOption());
  const [modalWindow, setModalWindow] = useState(<></>);
  const [viewState, setViewState] = useState<GridStateType>('view'); 

  const getSearchQuery = () => {
    let result = isGoodsCode()
    if (result)
      return result.value as string
    return ''   
  }

  const [searchQuery, setSearchQuery] = useState<string>(getSearchQuery());
  const timeout = useRef<NodeJS.Timeout>();

  const search = (callback: () => void) => {
    clearTimeout(timeout.current as NodeJS.Timeout);
    timeout.current = setTimeout(() => {
      callback();
    }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
  };


  useEffect(() => {
    renderModalWindow();
  }, [viewState]);

  function renderModalWindow() {
    switch (viewState) {
      case 'search':
        renderSearchWindow();
        break;
      default:
        setModalWindow(<></>);
    }
  }

  function renderSearchWindow() {
    const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? [];
    setModalWindow(
      <SearchModalWindow
        defaultFilters={props.gridFilter?.[0].columnFilters}
        columns={visibleColumns}
        cancel={() => setViewState('return')}
        search={(columnFilters) => {
          props.gridFilter?.[1]({type: 'search', payload: columnFilters});
          setViewState('refresh');
        }}
      />
    );
  }

  const onSearch = (value: string) => {
    if (optionFilter.value === defaultOptionFilters[0].value)
    {
      props.dispatchGridFilter?.({
        type: "paramFilter",
        payload: {
            gridParamFilter: {
              goodsName: value
            },
            sortDesc: "Perc"
        },
      });
    }
    else
    {
      props.dispatchGridFilter?.({
        type: 'addColumnFilter',
        payload: {
          name: optionFilter.value,
          operator: 'Like',
          value: `%${value}%`,
        },
      });
    }
  }

  return (
    <FlexColumn>
      {!props.isModal && (
        <LotsCommandsPanel
          print={{onClick: () => window.alert('print')}}
          gtd={{onClick: () => props.openGtd(), disabled: props.selectedItemLot ? false : true}}
          series={{onClick: () => props.openSeries(), disabled: props.disableSeries}}
          movementOfGoods={{
            onClick: () =>
              tabsContext.openChild(
                'lot_movement_plugin', 
                'view', 
                props.selectedItemLot?.idGlobal,
                () => setViewState("refresh"), 
                {
                  idGoods: props.selectedItemLot?.idGoodsGlobal,
                  goodsName: props.selectedItemLot?.goodsName
                }
              ),
              disabled: !props.selectedItemLot
          }}
          invoice={{
            onClick: () => tabsContext.openChild('invoice_creator_plugin', 'edit', props.docId as string),
            disabled: props.docId ? false : true,
          }}
          kizExchange={{
            onClick: () => {
              let idGlobal = props.selectedItemLot ? props.selectedItemLot.idGlobal : props.data[0].idGlobal;
              tabsContext.openChild(
                'kiz_exchange_modal_plugin',
                'view',
                idGlobal,
                undefined,
                props.data.find((item) => item.idGlobal === idGlobal)?.goodsName
              )
            },
            disabled: (() => {
              const itemLotIsKiz = props.selectedItemLot?.cells.find(el => el.propertyName === 'isKiz' && el.value === false) ? true : false;
              const isDisabled = props.selectedItemLot ? itemLotIsKiz : true;
              return isDisabled;
            })(),
          }}
        />
      )}
      {props.isModal && (
        <SearchModalPanel
          searchQuery={searchQuery}
          options={defaultOptionFilters}
          defaultOptionFilter={optionFilter}
          onClickOnSearchButton={() => setViewState('search')}
          onSelect={(option) => {
            props.dispatchGridFilter({
              type: 'deleteColumnFilter',
              payload: optionFilter.value,
            });
            setOptionFilter(option);
          }}
          onChange={(value) => {
            if (value.length > 0) {
              setSearchQuery(value);
              onSearch(value)
            } else {
              setSearchQuery('');
              search(() => {
                props.dispatchGridFilter?.({
                  type: 'paramSimpleFilter',
                  payload: {
                    gridColumnFilter: props.gridFilter?.[0].columnFilters.filter((item) => item.name !== optionFilter.value) ?? [],
                    gridParamFilter: []
                  },
              });
              });
            }
          }}
        />
      )}
      {modalWindow}
    </FlexColumn>
  );
};

export default DefaultLotCommandsPanel;
