const ActDisassemblingItemGridPluginSettings: IPluginSettings = {
    name: 'Акты разукомплектации',
    mnemocode: 'act_disassembling_item_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'idDocumentGlobal',
            propertyType: 'string',
            displayName: 'idDocumentGlobal',
            visibility: false,
            width: 100
        }, {
            order: 2,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 150
        }, {
            order: 3,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество',
            visibility: true,
            width: 150
        }, {
            order: 4,
            propertyName: 'lotFromName',
            propertyType: 'string',
            displayName: 'Исходная партия',
            visibility: true,
            width: 150
        }, {
            order: 5,
            propertyName: 'scalingRatioFromName',
            propertyType: 'string',
            displayName: 'Исходная упаковка',
            visibility: true,
            width: 150
        }, {
        //     order: 6,
        //     propertyName: 'lotToName',
        //     propertyType: 'string',
        //     displayName: 'Партия',
        //     visibility: true,
        //     width: 150
        // }, {
            order: 7,
            propertyName: 'scalingRatioToName',
            propertyType: 'string',
            displayName: 'Упаковка',
            visibility: true,
            width: 150
        }, {
            order: 8,
            propertyName: 'retailPrice',
            propertyType: 'number',
            displayName: 'Розничная цена',
            visibility: true,
            width: 150
        }, {
            order: 9,
            propertyName: 'retailVatPrice',
            propertyType: 'number',
            displayName: 'Розничная цена с НДС',
            visibility: true,
            width: 150
        }, {
            order: 10,
            propertyName: 'quantityFrom',
            propertyType: 'number',
            displayName: 'Исходное количество',
            visibility: true,
            width: 150
        }, {
        //     order: 11,
        //     propertyName: 'retailPriceFrom',
        //     propertyType: 'number',
        //     displayName: 'Исходная розничная цена',
        //     visibility: false,
        //     width: 150
        // }, {
        //     order: 12,
        //     propertyName: 'retailPriceVatFrom',
        //     propertyType: 'number',
        //     displayName: 'Исходная розничная цена с НДС',
        //     visibility: false,
        //     width: 150
        // },
        // {
            order: 13,
            propertyName: 'supplier',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 150
        },
        {
            order: 14,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 150,
        },
        {
            order: 15,
            propertyName: 'isGnvls',
            propertyType: "boolean",
            displayName: "ЖНВЛС",
            visibility: true,
            width: 100,
        },
    ]
}

export default ActDisassemblingItemGridPluginSettings