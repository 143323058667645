import { FC, useRef } from "react";
import { DoubleDownIcon, LeftIcon, RightIcon } from "../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { useTabsContext } from "../../system/providers/tabsProvider";
import styles from './styles/TabHead.module.scss'

interface ITabHeadProps {}

export const TabHead: FC<ITabHeadProps> = (props) => {
    const areaRef = useRef<HTMLDivElement>(null)
    const tabsContext = useTabsContext()
    const tabsId = 'headTabsId'
    const headId = 'headId'
    const head = document.getElementById(headId)
    const element = document.getElementById(tabsId)

    const rightAction = () => {
        if (element && head && tabsContext.tabs.length * 250 > head?.clientWidth) {
            if (Math.abs(parseInt(element.style.left, 10) || 0) < tabsContext.tabs.length * 100) {
                const val = (parseInt(element.style.left, 10) || 0) - 250;
                element.style.left = val + 'px';
            }
        }
    }

    const leftAction = () => {
        let tabIndex = tabsContext.tabs.findIndex(x => x.id === tabsContext.currentTab?.id)
        if (element && parseInt(element.style.left, 10) <= 0) {
            if (Math.abs(parseInt(element.style.left, 10)) <= 250) {
                element.style.left = 0 + 'px';
            } else {
                const val = (parseInt(element.style.left, 10) || 0) + 250;
                element.style.left = val + 'px';
            }
        } else if (tabIndex !== 0) {}
    }

    return (
        <div className={styles.root}>
            <div className={styles.container} id={headId}>
                <div className={styles.area} >
                    <div className={styles.items} ref={areaRef} id={tabsId}>
                        {props.children}
                    </div>
                    {
                        <ul className={styles.buttonBar}>
                            <li className={styles.buttonBarItem} onClick={() => leftAction()} > <LeftIcon /> </li>
                            <li className={!tabsContext.showTopMenu ? styles.buttonBarItem : styles.buttonBarDisabledItem} onClick={() => !tabsContext.showTopMenu && tabsContext.setShowTopMenu(true)} > {!tabsContext.showTopMenu && <DoubleDownIcon />} </li>
                            <li className={styles.buttonBarItem} onClick={() => rightAction()} > <RightIcon /> </li>
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}