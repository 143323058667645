import { FC, useEffect, useState } from "react";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { IMovementItemCreateDTO } from "../../../../../libs/coreapi-dto/documents/movementItem";
import { v4 as uuidv4 } from "uuid";
import { ICreateItem } from "../MovementUpdateForm";
import { KizBoxEntity } from "../../../../Dictionaries/Kiz/KizBoxEntity";
import { IKizBoxDTO, IKizDTO } from "../../../../../libs/coreapi-dto/dirs/kiz";
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { KizDataProvider } from "../../../../../Services/DataProviders/KizDataProvider";
import buffer from "buffer";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import useOperativeReserve from "../../../../../components/lotSelector/useOperativeReserve";
import { IdTableVariant } from "../../../../../@types/enumsGlobal";
import { IMovementCreateDTO } from "../../../../../libs/coreapi-dto/documents/movement";
import { useForm } from "../../../../../system/hooks/useForm";
import { DateTime } from "luxon";

interface IMovementCreateItemModal {
    idDocumentGlobal: string;
    idDocumentItemGlobal: string;
    lot: ILotDTO;
    ok: (createItem: ICreateItem) => void;
    scanData?: string;
    //typeSearch?: SearchOption
    cancel: () => void;
    items: IMovementItemCreateDTO[];
}

interface IValidator {
    quantity: number;
}

export const MovementCreatorItemModal: FC<IMovementCreateItemModal> = (props) => {
    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);

    const [createItem, setCreateItem] = useState<IMovementItemCreateDTO>({} as IMovementItemCreateDTO);
    const [idGlobal, setIdGlobal] = useState<string>(props.idDocumentItemGlobal ?? uuidv4());

    useEffect(() => {
        if (props.scanData) {
            const scanDataBase64: string = buffer.Buffer.from(props.scanData, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(props.scanData)) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idGlobal: idGlobal,
                        idGoodsGlobal: props.lot.goods.idGlobal,
                        idScalingRatioGlobal: props.lot.scalingRatio.idGlobal,
                        idLotFromGlobal: props.lot.idGlobal,
                        supplierPrice: props.lot.supplierCostInfo?.price ?? 0,
                        quantity: 0,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        retailCostInfo: props.lot.retailCostInfo,
                        producerPrice: 0,
                        denominator: 0,
                        numerator: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [] as IKizDTO[],
                        kizBoxes: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.lot.numerator ?? 1 }],
                        supplierFrom: props.lot.supplier,
                        supplierTo: "",
                        supplier: props.lot.supplier,
                        dateCreated: DateTime.now(),
                        isOsu: props.lot?.kizOsuDto?.barcode !== undefined,
                        kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...createItem?.kizOsuDto, idDocumentItem: idGlobal } : undefined,
                    } as IMovementItemCreateDTO);
                });
            } else {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idGlobal: idGlobal,
                        idGoodsGlobal: props.lot.goods.idGlobal,
                        idScalingRatioGlobal: props.lot.scalingRatio.idGlobal,
                        idLotFromGlobal: props.lot.idGlobal,
                        supplierPrice: props.lot.supplierCostInfo?.price ?? 0,
                        quantity: 0,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        retailCostInfo: props.lot.retailCostInfo,
                        denominator: 0,
                        numerator: 0,
                        producerPrice: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [{ ...parsedBarcode.kizData, idGlobal: uuidv4() }] as IKizDTO[],
                        kizBoxes: [] as IKizBoxDTO[],
                        supplierFrom: props.lot.supplier,
                        supplier: props.lot.supplier,
                        dateCreated: DateTime.now(),
                        isOsu: props.lot?.kizOsuDto?.barcode !== undefined,
                        kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...createItem?.kizOsuDto, idDocumentItem: idGlobal } : undefined,
                    } as IMovementItemCreateDTO);
                });
            }
        } else {
            setCreateItem({
                idGlobal: idGlobal,
                idGoodsGlobal: props.lot.goods.idGlobal,
                idScalingRatioGlobal: props.lot.scalingRatio.idGlobal,
                idLotFromGlobal: props.lot.idGlobal,
                supplierCostInfo: props.lot.supplierCostInfo,
                retailCostInfo: props.lot.retailCostInfo,
                quantity: 1,
                supplierVatPrice: props.lot.supplierCostInfo?.vat ?? 0,
                producerPrice: 0,
                salePrice: props.lot.retailCostInfo?.price ?? 0,
                isKiz: props.lot.isKiz,
                denominator: 0,
                numerator: 0,
                kizs: [] as IKizDTO[],
                kizBoxes: [] as IKizBoxDTO[],
                supplierFrom: props.lot.supplier,
                supplierTo: "",
                supplier: props.lot.supplier,
                dateCreated: DateTime.now(),
                isOsu: props.lot?.kizOsuDto?.barcode !== undefined,
                kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...props.lot?.kizOsuDto, idDocumentItem: idGlobal } : undefined,
            } as IMovementItemCreateDTO);
        }
    }, []);

    const buildDocumentItem = (item: IMovementItemCreateDTO | undefined): ICreateItem => {
        let result = item ?? createItem;
        return {
            item: {
                ...result,
                supplierCostInfo: {
                    ...props.lot.supplierCostInfo,
                    vat: props.lot.supplierCostInfo.vat,
                    sum: round(props.lot.supplierCostInfo.price * result.quantity),
                    vatSum: round(props.lot.supplierCostInfo.vatPrice * result.quantity),
                    sumIncVat: round(props.lot.supplierCostInfo.priceIncVat * result.quantity),
                },
                retailCostInfo: {
                    ...props.lot.retailCostInfo,
                    vat: props.lot.retailCostInfo.vat,
                    sum: round(props.lot.retailCostInfo.price * result.quantity),
                    vatSum: round(props.lot.retailCostInfo.vatPrice * result.quantity),
                    sumIncVat: round(props.lot.retailCostInfo.priceIncVat * result.quantity),
                },
                dateCreated: DateTime.now()
            },
            displayItem: {
                idGlobal: result.idGlobal,
                idLotFromGlobal: result.idLotFromGlobal,
                goodsName: props.lot.goods.displayName,
                scalingRatioName: props.lot.scalingRatio.displayName,
                quantity: result.quantity,

                supplierVat: props.lot.supplierCostInfo.vat,
                supplierPrice: props.lot.supplierCostInfo.price,
                supplierVatPrice: props.lot.supplierCostInfo.vatPrice,
                supplierPriceIncVat: props.lot.supplierCostInfo.priceIncVat,
                supplierSum: round(props.lot.supplierCostInfo.price * result.quantity),
                supplierVatSum: round(props.lot.supplierCostInfo.vatPrice * result.quantity),
                supplierSumIncVat: round(props.lot.supplierCostInfo.priceIncVat * result.quantity),
                supplierAdprice: props.lot.supplierCostInfo.adprice,

                retailVat: props.lot.retailCostInfo.vat,
                retailPrice: props.lot.retailCostInfo.price,
                retailVatPrice: props.lot.retailCostInfo.vatPrice,
                retailPriceIncVat: props.lot.retailCostInfo.priceIncVat,
                retailSum: round(props.lot.retailCostInfo.price * result.quantity),
                retailVatSum: round(props.lot.retailCostInfo.vatPrice * result.quantity),
                retailSumIncVat: round(props.lot.retailCostInfo.priceIncVat * result.quantity),
                retailAdprice: props.lot.retailCostInfo.adprice,

                isKiz: props.lot.isKizFlag,
                countKizScan: props.lot.isKiz === false ? undefined : 0,
                denominator: props.lot.denominator,
                numerator: props.lot.numerator,
                supplier: props.lot.supplier,
                dateCreated: DateTime.now(),
                isOsu: result.isOsu
            },
            filter: {
                idItem: result.idGlobal,
                quantityMax: props.lot.quantityRem + props.lot.quantityRes,
                quantityMin: 0,
            },
        };
    };
    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const remain = useLotRemain(props.lot.idGlobal, props.idDocumentGlobal);
    const operativeReserve = useOperativeReserve();

    const onOk = () => {
        let id = idGlobal;
        let quantity = createItem.quantity;
        let newCreateItem;

        let item = props.items.find((c) => c.idLotFromGlobal === createItem.idLotFromGlobal);
        if (item) {
            id = item.idGlobal;
            quantity = item.quantity + quantity;

            newCreateItem = {
                ...item,
                quantity: quantity,
            } as IMovementItemCreateDTO;
        }

        operativeReserve.send(
            {
                idLot: props.lot.idGlobal,
                idTable: IdTableVariant.Movement,
                idDocument: props.idDocumentGlobal,
                idDocumentItem: id,
                quantity: quantity,
            },
            () => props.ok(buildDocumentItem(newCreateItem))
        );
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: createItem.quantity && createItem.quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    return (
        <BaseModalWindow
            header="Введите количество"
            ok={{
                onClick: () => {
                    if (isValid()) {
                        onOk();
                    }
                },
            }}
            cancel={{ onClick: props.cancel }}
            modalWindowClassName={modalStyles.modalWindowMovement}
            footerStyle="confirm"
        >
            <TextAreaInput value={props.lot.displayName} disabled />
            <GridWrapper cols={3}>
                <TextInput label="Единица измерения" value={props.lot.scalingRatio.displayName} disabled />
                <NumberInput label="Остаток" value={remain} disabled />
                <NumberInput
                    required
                    onFocus={() => setErrors({ ...errors, quantity: undefined })}
                    error={errors.quantity}
                    keyBan={true}
                    label="Количество"
                    value={createItem.quantity}
                    min={1}
                    max={remain}
                    onChange={(value) => setCreateItem({ ...createItem, quantity: value })}
                />
            </GridWrapper>
        </BaseModalWindow>
    );
};
