import { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, NumberInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import styles from "../styles/ActDisassembling.module.scss";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { CheckBox } from "../../../../components/controls/checkbox";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { StoreSelectorModal } from "../../../Dictionaries/Store";
import { LotsSelectorModal } from "../../../Accounting/Lots";
import { IOption, Select } from "../../../../components/selects/select";
import useGridFilter, { CreateFilterNumerator, CreateFilterNumeratorDenominator } from "../../../../system/hooks/useGridFilter";
import { ActDisassemblingCreatorItemModal } from "./ActDisassemblingCreatorItem/ActDisassemblingCreatorItemModal";
import { useForm } from "../../../../system/hooks/useForm";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import ActDisassemblingItemCreatorGridPluginSettings from "./ActDisassemblingItemCreatorGridPluginSettings";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { IActDisassemblingCreateDTO, IActDisassemblingGetDTO } from "../../../../libs/coreapi-dto/documents/actDisassembling";
import { IActDisassemblingItemCreateDTO, IActDisassemblingItemEditDTO, IActDisassemblingItemGetDTO } from "../../../../libs/coreapi-dto/documents/actDisassemblingItem";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { ActDisassemblingUpdateItemModal } from "./ActDisassemblingCreatorItem/ActDisassemblingUpdateItemModal";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";
import LotsSimpleSelectorModal from "../../../../Business/Accounting/Lots/LotsSimpleSelectorModal";
import { ILotsByIdDTO } from "../../../../libs/core-api-requests/accounting/lotRequest";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { autocomplete } from "../../../../system/functions/autocomplete";
import { BtSaveOrOk } from "../../Invoice/InvoiceCreator/InvoiceUpdateForm";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { addKizOrKizBox } from "../../../../system/functions/addKizOrKizBox";
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { IPricingDocumentItemDTO } from "../../../../libs/coreapi-dto/dirs/pricingInvoice";
import { PricingDisassemblingDataProvider } from "../../../../Services/DataProviders/PricingDisassemblingDataProvider";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import FlexRow from "../../../../components/controls/FlexRow";

interface IValidator {
    store: IEntitySimpleDTO;
    documentDate: DateTime;
}

interface ICreatorItem {
    show: boolean;
    item: ILotDTO | null;
    editItem?: IActDisassemblingItemEditDTO | null;
}
export interface IDisplayItem {
    idGlobal: string;
    idLotFromGlobal: string;
    goodsName: string;
    quantityFrom: number;
    quantity: number;
    scalingRatioFrom: string;
    scalingRatioTo: string;
    retailPrice: number;
    retailPriceIncVat: number;
    supplierPrice: number;
    supplierSum: number;
    retailSumWithVat: number;
    isKiz: boolean;
    isGnvls: boolean;
    countKizScan: number | undefined;
    supplier?: string;
    dateCreated: DateTime
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

export interface ICreateItem {
    item: IActDisassemblingItemCreateDTO;
    displayItem: IDisplayItem;
    filter: IItemFilter;
}
interface IBaseFields {
    store: IEntitySimpleDTO;
    pharmacy: IEntitySimpleDTO;
    storeFromLot: IEntitySimpleDTO;
}
interface ILotsViewItem {
    item: ILotsByIdDTO[] | null;
    show: boolean;
}

interface IReturnCreatedItem {
    createdItem: IActDisassemblingItemCreateDTO;
    displayItem: IDisplayItem;
}

export const ActDisassemblingUpdateForm: FC<IDocumentUpdateFormProps<IActDisassemblingGetDTO, IActDisassemblingItemGetDTO[], IActDisassemblingCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const operativeReserve = useOperativeReserve();
    const documentType = DocumentType.disassembling

    const lotDP = new LotDataProvider(appContext.coreApiService);
    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const pricingDP = new PricingDisassemblingDataProvider(appContext.coreApiService);
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);
    const [setTimer, clearTimer]  = useTimeout()

    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [displayNameBaseDoc, setDisplayNameBaseDoc] = useState<string>("");
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });

    const [data, setData] = useState<IActDisassemblingCreateDTO>(
        props.documentUpdate ??
            ({
                idGlobal: props.idGlobal,
                documentDate: DateTime.now(),
                isNewBarcode: false,
                items: [] as IActDisassemblingItemCreateDTO[],
            } as IActDisassemblingCreateDTO)
    );
    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>(
        props.documentItems
            ? [
                  ...props.documentItems.map((x: IActDisassemblingItemGetDTO): IDisplayItem => {
                      return {
                          idGlobal: x.idGlobal,
                          idLotFromGlobal: x.lotFrom.idGlobal,
                          goodsName: x.goods.displayName,
                          quantity: x.quantity,
                          quantityFrom: x.quantityFrom,
                          scalingRatioFrom: x.scalingRatioFrom.displayName,
                          scalingRatioTo: x.scalingRatioTo.displayName,
                          supplierPrice: x.supplierCostInfo.price,
                          retailPrice: x.retailCostInfo.price,
                          retailPriceIncVat: x.retailCostInfo.priceIncVat ?? 0,
                          supplierSum: x.supplierCostInfo.sum,
                          retailSumWithVat: x.retailCostInfo.sumIncVat,
                          isKiz: x.isKiz,
                          isGnvls: x?.isGnvls ?? false,
                          countKizScan: x.isKiz === false ? undefined : x.kizCount + x.kizBoxCount,
                          supplier: x.supplier,
                          dateCreated: x.dateCreated
                      };
                  }),
              ]
            : []
    );
    const [creatorItem, setCreatorItem] = useState<ICreatorItem>({ show: false, item: null });
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const [baseFields, setBaseFields] = useState<IBaseFields>({
        store: {} as IEntitySimpleDTO,
        pharmacy: {} as IEntitySimpleDTO,
        storeFromLot: {} as IEntitySimpleDTO,
    });
    const [showKizScan, setShowKizScan] = useState<boolean>(false);

    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter(CreateFilterNumeratorDenominator);

    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO>({idGlobal: uuidv4(), displayName: '--'} as IEntitySimpleDTO);

    const [selectedScalingRatio, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);
    const [lotsViewItem, setLotsViewItem] = useState<ILotsViewItem>({ item: null, show: false });
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });

    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>(props.documentUpdate ? props.documentUpdate.items.map((x) => ({ err: false, message: "", idGlobal: x.idGlobal })) : []);
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.actDisassembling." + value);
    const errorsT = (value: string) => t("errors." + value);

    const defaultSearchOption = {displayName: t("documents.movement.goodsName"), value: SearchOption.goodsName} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);

    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IActDisassemblingItemCreateDTO>();
    const [scanFocus, setScanFocus] = useState<boolean>(false);
    const [scanValue, setScanValue] = useState<string>("");
    const timeout = useRef<NodeJS.Timeout>();

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const scanner = useComPortScanner((data) => {
            // сообщение от сканера
            setScanValue(data);
            addKiz(data, true);
    })

    const addKiz = (scanValue: string, portScanner?: boolean) => {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = setTimeout(() => {
            let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActDisassemblingItemCreateDTO;
            if (item.quantity > (item.kizBoxes?.length || 0 + (item.kizs?.length || 0))) {
                addKizOrKizBox(
                    scanValue,
                    kizDP,
                    documentType,
                    (value) => {
                        let filteredItems = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IActDisassemblingItemCreateDTO;
                        let displayItem = displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal) as IDisplayItem;
                        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        setData({
                            ...data,
                            items: [
                                ...filteredItems,
                                {
                                    ...item,
                                    kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                    kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                },
                            ],
                        });
                        setDisplayItems([
                            ...filteredDisplayItems,
                            {
                                ...displayItem,
                                countKizScan: sumKizsCount(
                                    {
                                        ...item,
                                        kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                        kizBoxes:
                                            value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                    },
                                    documentType
                                ),
                            },
                        ]);
                        setSelectedItemCreateDto({
                            ...item,
                            kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                            kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                        });
                        setScanValue("");
                    },
                    portScanner
                );
            } else {
                setScanValue("");
                renderGlobalAlert({
                    variant: "exception",
                    title: "Превышено максимальное количество",
                    statusCode: 400,
                });
            }
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            store: {
                required: {
                    value: data?.idStoreGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });
    const kizDisabled = (): boolean => {
        if (selectedItem) {
            if (data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.isKiz === true && !onlyRead) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    const addItemsFilter = () => {
        let arr: IItemFilter[] = [];
        props.document?.items.forEach((x) => {
            lotDP.getById(x.lotFrom.idGlobal, (e) => {
                arr.push({ idItem: x.idGlobal, quantityMax: e.quantityRem + e.quantityRes, quantityMin: 0 });
            });
        });
        setItemsFilter(arr);
    };

    useEffect(() => dispatchGridFilterLot(onPharmacyChanged(baseFields.pharmacy.idGlobal)), [baseFields.pharmacy]);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idUserGlobal, data.idGosContractGlobal, data.idStoreGlobal, data.items]);


    useEffect(() => {
        if (props.variant === "create") {
            autocomplete().then((e) => {
                setData({ ...data, idStoreGlobal: e.contractorMe.store.idGlobal });
            });
        } else if (props.variant === "edit") {

            lockingDocuments.check(data.idGlobal as string, (e)=>{
                if (e) setOnlyRead(true)
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.ActDisassembling,
                        idDocument: data.idGlobal as string,
                    }, (e)=> {
                        setIdLockingDocument(e)
                    });
            })

            addItemsFilter();
        }
    }, [props.variant]);

    useEffect(() => {
        if (selectedItem) {
            setScanFocus(true);
            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IActDisassemblingItemCreateDTO;
            if (selectedItemDto) {
                setSelectedItemCreateDto(selectedItemDto);
                if (selectedItemDto.idScalingRatioToGlobal.length > 0) {
                    scalingRatioDP.overrideGetById(selectedItemDto.idGoodsGlobal, selectedItemDto.idScalingRatioToGlobal as string, (e) => {
                        setSelectedScalingRation(e);
                    });
                }
            } else {
                setSelectedItemCreateDto(undefined);
            }
        } else {
            setScanFocus(false);
            setSelectedItemCreateDto(undefined);
            setSelectedScalingRation(null);
        }
        setScanValue("");
    }, [selectedItem]);

    useEffect(() => {
        if (data?.idStoreGlobal) {
            storeDP.getById(data.idStoreGlobal, (e) => {
                setBaseFields({
                    ...baseFields,
                    store: { displayName: e.name, idGlobal: data.idStoreGlobal as string },
                    pharmacy: e.contractor,
                });
                dispatchGridFilterLot({
                    type: "addColumnFilter",
                    payload: {
                        name: "idStoreGlobal",
                        operator: "Eq",
                        value: data.idStoreGlobal,
                        invisible: true,
                    },
                });
            });
        } else {
            setBaseFields({
                ...baseFields,
                store: {} as IEntitySimpleDTO,
            });

            dispatchGridFilterLot({
                type: "deleteColumnFilter",
                payload: "idStoreGlobal",
            });
        }
    }, [data?.idStoreGlobal]);

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
        const handleTabClose = (event) => {
            operativeReserve.deleteAll({
                idTable: IdTableVariant.ActDisassembling,
                idDocument: data.idGlobal as string,
            });
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDisassembling,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => window.removeEventListener("beforeunload", handleTabClose);
    }, []);

    const addItem = (value: IChangeDataGrid) => {
        let dataRowItem = data.items.find((x) => x.idGlobal === value.idGlobal) as IActDisassemblingItemCreateDTO;
        let filteredItems = data.items.filter((x) => x.idGlobal !== value.idGlobal);

        let displayRowItem = displayItems.find((x) => x.idGlobal === value.idGlobal) as IDisplayItem;
        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== value.idGlobal);

        if (selectedScalingRatio?.idGlobal)
            calculated(
                {
                    quantity: value.value,
                    supplierPriceExcludingVat: dataRowItem.supplierCostInfo?.price ?? 0,
                    supplierVatRatePercentage: dataRowItem.supplierCostInfo.vat ?? 0,
                    supplierPriceWithVat: dataRowItem.supplierCostInfo.priceIncVat ?? 0,
                    supplierAmountExcludingVat: dataRowItem.supplierCostInfo.sum ?? 0,
                    supplierVatAmount: dataRowItem.supplierCostInfo.vatSum ?? 0,
                    supplierAmountWithVat: dataRowItem.supplierCostInfo.sumIncVat ?? 0,
                    retailUnitMargin: dataRowItem.productMargin ?? 0,
                    retailPrice: dataRowItem.retailCostInfo?.price ?? 0,
                    retailPriceWithVat: dataRowItem.retailCostInfo.priceIncVat ?? 0,
                    percentageOfVatRateRetail: dataRowItem.retailCostInfo.vat ?? 0,
                    retailAmount: dataRowItem.retailCostInfo.sum ?? 0,
                    retailAmountWithVat: dataRowItem.retailCostInfo.sumIncVat ?? 0,
                    numerator: selectedScalingRatio?.numerator ?? 1,
                    denominator: selectedScalingRatio?.denominator ?? 1,
                    eventType: "QuantityСhanged",
                } as IPricingDocumentItemDTO,
                (e) => {
                    setData({
                        ...data,
                        items: [
                            ...filteredItems,
                            {
                                ...dataRowItem,
                                retailCostInfo: {
                                    adprice: 0,
                                    vatPrice: 0,
                                    vat: e.percentageOfVatRateRetail,
                                    vatSum: e.vatAmountRetail as number,
                                    sumIncVat: e.retailAmountWithVat,
                                    sum: e.retailAmount as number,
                                    price: e.retailPrice,
                                    priceIncVat: e.retailPriceWithVat,
                                },
                                supplierCostInfo: {
                                    adprice: 0,
                                    vatPrice: 0,
                                    vat: e.supplierVatRatePercentage,
                                    vatSum: e.supplierVatAmount,
                                    sumIncVat: e.supplierAmountWithVat,
                                    sum: e.supplierAmountExcludingVat,
                                    price: e.supplierPriceExcludingVat,
                                    priceIncVat: e.supplierPriceWithVat,
                                },
                                productMargin: e.retailUnitMargin,
                                quantityFrom: e.quantity,
                                quantity: e.quantity * (selectedScalingRatio?.denominator ?? 1),
                                idScalingRatioToGlobal: selectedScalingRatio?.idGlobal ?? "",
                            },
                        ],
                    });
                    setDisplayItems([
                        ...filteredDisplayItems,
                        {
                            ...displayRowItem,
                            quantityFrom: e.quantity,
                            quantity: e.quantity * (selectedScalingRatio?.denominator ?? 1),
                            supplierPrice: e.supplierPriceExcludingVat,
                            retailPrice: e.retailPrice,
                            supplierSum: e.supplierAmountExcludingVat,
                            retailPriceIncVat: e.retailPriceWithVat,
                        },
                    ]);
                }
            );
    };

    const calculated = (calcItem: IPricingDocumentItemDTO, callback: (e: IPricingDocumentItemDTO) => void) => {
        pricingDP.CalculateItem(pricingModel.idGlobal, calcItem, (e: IPricingDocumentItemDTO) => {
            callback(e);
        });
    };

    const isValidDocument = (items:IActDisassemblingItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActDisassembling,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onOk = () => {
        if (isValid()) {
            if (isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" }),documentType) === true
                ) {
                onOkAction();
                scanner.disconnect();
            }
        }
    };

    const onSave = () => {
        if (isValid()) {
            if (isValidDocument(data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true &&
                kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" }),documentType) === true
                ) {
                props.save(data);
                scanner.disconnect();
            }
        }
    };

    const onCancel = () => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.ActDisassembling,
                idDocument: data.idGlobal as string,
            },
            () => {
                props.cancel();
                scanner.disconnect();
            }
        );
        lockingDocuments.delete({
            idTable: IdTableVariant.ActDisassembling,
            idDocument: data.idGlobal as string,
        });
    };

    const getItem = (idGlobal: string) : IActDisassemblingItemCreateDTO | undefined => {
        let item = data.items.find(c => c.idLotFromGlobal === idGlobal)
        if (!item) return item
        return {
            ...item,
            quantityFrom: item.quantityFrom + 1
        } as IActDisassemblingItemCreateDTO
    }

    const calcRow = (value : IChangeDataGrid) => {
        if (value.propertyName === "quantityFrom") {
            const idLot: string = data.items.find((x) => x.idGlobal === value.idGlobal)?.idLotFromGlobal as string;
            operativeReserve.send(
                {
                    idLot: idLot,
                    idDocument: data.idGlobal as string,
                    idTable: IdTableVariant.ActDisassembling,
                    idDocumentItem: value.idGlobal,
                    quantity: value.value as number,
                },
                () => addItem(value)
            );
        } else {
            addItem(value);
        }
    }

    return (
        <BaseDocumentCreator
            valid={true}
            ok={{
                onClick: onOk,
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            save={{
                onClick: onSave,
                disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                sendRequestGlobal: props.isSubmitting
            }}
            cancel={{
                onClick: onCancel,
            }}
            print={{
                onClick: () => {
                    return IdTableVariant.ActDisassembling;
                },
                onSubItemClick: () => {
                    let arr = new Array<IKeyValuePair>();
                    if (props.document !== undefined && props.document !== null && props.document.mnemocode !== undefined && props.idGlobal !== undefined)
                        arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                    return arr;
                },
            }}
        >
            <div className={styles.glwrapper}>
                <div className={styles.wrapper}>
                    <div>
                        <GridWrapper cols={2}>
                            <TextInput
                                labelClassName={styles.numberLabel}
                                disabled
                                label={baseT("number")}
                                inputId={"inputInvoiceNumber"}
                                inline
                                value={props.variant === "edit" && props.document ? props.document.mnemocode : undefined}
                            />
                            <DateInput
                                required
                                disabled={onlyRead}
                                labelVariant="required"
                                inputId={"inputInvoiceDate"}
                                label={baseT("date")}
                                value={data.documentDate}
                                onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                inline
                                error={errors.documentDate}
                            />
                        </GridWrapper>
                        <GridWrapper cols={3}>
                            <StoreSelectorPanel
                                compactMode
                                we
                                required
                                change={onCanChangeData(data.items.length)}
                                storeFromLot={baseFields.storeFromLot}
                                contractor={{
                                    label: baseT("pharmacy"),
                                    onSelect: (value) => value && setBaseFields({ ...baseFields, pharmacy: value }),
                                    onClear: () => setBaseFields({ ...baseFields, pharmacy: {} as IEntitySimpleDTO }),
                                    disabled: onlyRead ? true : false,
                                    treeViewCheckDirectoryType:'pharmacy'
                                }}
                                store={{
                                    label: baseT("store"),
                                    value: baseFields.store,
                                    onSelect: (value) => value && setData({ ...data, idStoreGlobal: value.idGlobal }),
                                    onClear: () => setData({ ...data, idStoreGlobal: undefined }),
                                    error: errors.store,
                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                    disabled: onlyRead ? true : false
                                }}
                                notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                documentStore={props.document?.store}
                            />
                            <DictionaryInput
                                placeholder={baseT("checkBoxNotSelected")}
                                label={baseT("baseDocument")}
                                value={displayNameBaseDoc}
                                onMoreClick={() => {
                                    !baseFields.store?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                }}
                                disabled={onlyRead}
                            />
                            <CheckBox
                                defaultChecked={data.isNewBarcode}
                                onChanged={() => setData({ ...data, isNewBarcode: !data.isNewBarcode })}
                                id={uuidv4()}
                                className="createMovementsStyles.field_GenerateNewBarcode"
                                label={baseT("isNewBarcode")}
                                labelSize="normal"
                                disabled={onlyRead ? true : false}
                            />
                        </GridWrapper>
                        <Accordion opened={false} caption={baseT("gosContract")} id={`gos-${uuidv4()}`}>
                            <GridWrapper cols={2}>
                                <GridSelectorInput
                                    selectorModalJsx={StoreSelectorModal}
                                    id={"dictionaryInputRevaluationStore"}
                                    inline={true}
                                    selectedEntity={{}}
                                    label={baseT("gosContract")}
                                    onSelect={(entity) => {}}
                                    onClear={() => {}}
                                    disabled
                                />
                                <GridSelectorInput
                                    selectorModalJsx={StoreSelectorModal}
                                    id={"dictionaryInputRevaluationStore"}
                                    inline={true}
                                    selectedEntity={{}}
                                    label={baseT("targetProgram")}
                                    onSelect={(entity) => {}}
                                    onClear={() => {}}
                                    disabled
                                />
                            </GridWrapper>
                        </Accordion>
                        <FlexRow className={styles.field_flexblock}>
                            <PositionEditPanel
                                kiz={{
                                    onClick: () => setShowKizScan(true),
                                    disabled: kizDisabled(),
                                }}
                                edit={{
                                    onClick: () => {
                                        if (selectedItem) {
                                            const editItem = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IActDisassemblingItemEditDTO;
                                            lotDP.getById(editItem.idLotFromGlobal, (item: ILotDTO) => {
                                                setCreatorItem({ show: true, item: item, editItem: editItem });
                                            });
                                        }
                                    },
                                    disabled: selectedItem && !onlyRead ? false : true,
                                }}
                                delete={{
                                    onClick: () => {
                                        if (selectedItem) {
                                            let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
                                            if (item) {
                                                operativeReserve.delete(
                                                    {
                                                        idLot: item.idLotFromGlobal,
                                                        idTable: IdTableVariant.ActDisassembling,
                                                        idDocument: data.idGlobal as string,
                                                        idDocumentItem: item.idGlobal as string,
                                                    },
                                                    () => {
                                                        setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== selectedItem.idGlobal)] });
                                                        setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem.idGlobal)]);
                                                        setSelectedItem(undefined);
                                                    }
                                                );
                                            }
                                        }
                                    },
                                    disabled: selectedItem && !onlyRead ? false : true,
                                }}
                            />
                            <GridSelectorInput
                                disabled={onlyRead ? true : false}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                selectorModalJsx={LotsSelectorModal}
                                className={styles.field_SelectionGoods}
                                id={"inputAccompanyingSelectionGoods"}
                                gridFilter={gridFilterLot}
                                dispatchGridFilter={dispatchGridFilterLot}
                                inline={true}
                                label={baseT("selectionOfGoods")}
                                onClear={() => {}}
                                onSelect={(value) => {
                                    lotDP.getById(value.idGlobal, (lot) => {
                                        if (!checkStorePharmacy(lot, baseFields.store, baseFields.pharmacy)) return;

                                        let item = getItem(value.idGlobal)

                                        if (searchOption.value === SearchOption.goodsName ||
                                            searchOption.value === SearchOption.goodsCodeBylot) 
                                        {
                                            lotDP.remain(value.idGlobal, data.idGlobal as string, (remain) => {
                                                setCreatorItem({ show: true, item: { ...lot, quantityRem: remain }, editItem: item });
                                            });
                                        } else if (searchOption.value === SearchOption.kizOrKizBox) {
                                            if (value.isKizBox)
                                            {
                                                renderGlobalAlert({
                                                    variant: "warning",
                                                    title: `Невозможно добавить партию с SSCC`,
                                                    statusCode: 500,
                                                });
                                            }
                                            else
                                                setCreatorItem({ show: true, item: value, editItem: item  });
                                        } else if (searchOption.value === SearchOption.internalBarcode) {
                                            if (value.isKizBox)
                                            {
                                                renderGlobalAlert({
                                                    variant: "warning",
                                                    title: `Невозможно добавить партию с SSCC`,
                                                    statusCode: 500,
                                                });
                                            } else
                                            if (value.length !== undefined && value.length > 1)
                                            {
                                                setLotsViewItem({ item: value, show: true });
                                            }
                                            else {
                                                if (!(searchOption.value === SearchOption.internalBarcode)) value = value[0];
                                                setCreatorItem({ show: true, item: value, editItem: item  });
                                            }
                                        }
                                    });
                                }}
                                searchOption={searchOption.value}
                                idTable={IdTableVariant.ActDisassembling}
                            />
                            <Select
                                disabled={onlyRead ? true : false}
                                barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                onSelect={(option) => {
                                    setSearchOption(option);
                                }}
                                value={searchOption}
                                defaultOption={defaultSearchOption}
                                className={styles.field_SelectBlock}
                                options={[
                                    { displayName: t("documents.movement.goodsName"), value: SearchOption.goodsName },
                                    { displayName: t("documents.movement.kizOrKizBox"), value: SearchOption.kizOrKizBox },
                                    { displayName: t("documents.movement.goodsCodeBylot"), value: SearchOption.goodsCodeBylot },
                                    { displayName: t("documents.movement.internalBarcode"), value: SearchOption.internalBarcode },
                                ]}
                            />
                        </FlexRow>
                        <TextInput
                            disabled={onlyRead ? true : false}
                            className={styles.scanField}
                            autoFocus={scanFocus}
                            value={scanValue}
                            onChange={(e) => {
                                addKiz(e);
                                setScanValue(e);
                            }}
                        />
                        <ChangeableGrid
                            documentStateProc={onlyRead}
                            gridId={uuidv4()}
                            data={displayItems}
                            hiddenPagination={undefined}
                            filter={gridFilter}
                            dataProvider={lotDP}
                            autoSelect
                            totalCount={0}
                            itemsFilter={itemsFilter}
                            isSortByCreatedDate
                            itemsValidation={itemsValidation}
                            setItemsValidation={(value) => {
                                const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item))
                                setItemsValidation(newItemsValidation)
                            }}
                            plugin={ActDisassemblingItemCreatorGridPluginSettings}
                            selectedItem={selectedItem}
                            documentType={documentType}
                            getStyleCell={(cell, cells) => getStyleCell(cell, cells, documentType)}
                            onRowValidate={(row) => {
                                const none = 'none';
                                const redBorder = '1px solid red';
                                const cellScalingRatioTo = row?.cells?.find(x => x.propertyName === "scalingRatioTo");
                                const cellQuantityFrom = row?.cells?.find(x => x.propertyName === "quantityFrom");

                                return (cellScalingRatioTo && cellScalingRatioTo.value.length === 0) || (cellQuantityFrom && cellQuantityFrom.value === 0) ?
                                    redBorder : none;
                            }}
                            onSelect={(row) => {
                                setSelectedItem(row);
                            }}
                            onDoubleClick={(row) => props.onDoubleClick?.(row)}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                            onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                        />
                    </div>
                    <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierAmounts")} inputId={"inputAccompanyingNdsSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("supplierVatAmounts")} inputId={"inputAccompanyingNdsProviderSum"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("supplierAmountsWithVat")} inputId={"inputAccompanyingProviderSum"} value={undefined} />
                        </GridWrapper>

                        <GridWrapper cols={3}>
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailAmount")} inputId={"inputAccompanyingRetailSumBNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled labelClassName="label__width-111" label={baseT("retailVatAmount")} inputId={"inputAccompanyingNdsRetailSumNds"} value={undefined} />
                            <MoneyFormatComponent inline disabled label={baseT("retailAmountWithVat")} inputId={"inputAccompanyingRetailSum"} value={undefined} />
                        </GridWrapper>
                    </Accordion>
                </div>
            </div>

            {showKizScan && selectedScalingRatio && (
                <KizScanModal
                    ok={(value, scalingRatio: IScalingRatioDTO) => {
                        let items = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        setData({ ...data, items: items ? [...items, {...value,dateCreated:DateTime.now()}] : [value] });
                        setDisplayItems(
                            displayItems.map((x): IDisplayItem => {
                                return {
                                    countKizScan: x.idGlobal === value.idGlobal ? sumKizsCount(value, documentType,scalingRatio) : x.countKizScan,
                                    goodsName: x.goodsName,
                                    idGlobal: x.idGlobal,
                                    idLotFromGlobal: x.idLotFromGlobal,
                                    isKiz: x.isKiz,
                                    quantity: x.quantity,
                                    quantityFrom: x.quantityFrom,
                                    isGnvls: x.isGnvls,
                                    retailSumWithVat: x.retailSumWithVat,
                                    scalingRatioFrom: x.scalingRatioFrom,
                                    scalingRatioTo: x.scalingRatioTo,
                                    supplierPrice: x.supplierPrice,
                                    retailPriceIncVat: x.retailPriceIncVat,
                                    retailPrice: x.retailPrice,
                                    supplierSum: x.supplierSum,
                                    dateCreated: x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                };
                            })
                        );
                        setShowKizScan(false);
                    }}
                    cancel={() => {
                        setShowKizScan(false);
                    }}
                    selectedItem={selectedItemCreateDto as IActDisassemblingItemCreateDTO}
                    document={{ idTable: IdTableVariant.ActDisassembling, idDocument: data.idGlobal as string }}
                    idLotFrom={selectedItemCreateDto?.idLotFromGlobal ?? null}
                    numerator={selectedScalingRatio.numerator}
                    documentType={documentType}
                    //scalingRatio={selectedScalingRatio}
                />
            )}

            {creatorItem.show && creatorItem.item && creatorItem?.editItem && (
                <ActDisassemblingUpdateItemModal
                    idDocument={data.idGlobal as string}
                    ok={(createItem: ICreateItem) => {
                        operativeReserve.send(
                            {
                                idLot: createItem.item.idLotFromGlobal,
                                idTable: IdTableVariant.ActDisassembling,
                                idDocument: data.idGlobal as string,
                                idDocumentItem: createItem.item.idGlobal,
                                quantity: createItem.item.quantityFrom,
                            },
                            () => {
                                setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== createItem.item.idGlobal), createItem.item] });
                                setDisplayItems([...displayItems.filter((x) => x.idGlobal !== createItem.displayItem.idGlobal), createItem.displayItem]);
                                setCreatorItem({ show: false, item: null, editItem: null });
                                setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                            }
                        );
                    }}
                    cancel={() => {
                        if (creatorItem.editItem?.quantityFrom as number > 0) {
                            operativeReserve.send(
                                {
                                    idLot: creatorItem.editItem?.idLotFromGlobal as string,
                                    idTable: IdTableVariant.ActDisassembling,
                                    idDocument: data.idGlobal as string,
                                    idDocumentItem: creatorItem.editItem?.idGlobal as string,
                                    quantity: creatorItem.editItem?.quantityFrom as number,
                                },
                                () => setCreatorItem({ show: false, item: null, editItem: null })
                            );
                        } else {
                            setCreatorItem({ show: false, item: null, editItem: null })
                        }
                    }}
                    kizCount={displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal)?.countKizScan as number}
                    itemLot={creatorItem.item}
                    ItemEdit={creatorItem.editItem}
                    pricingModel={pricingModel}
                />
            )}

            {creatorItem.show && creatorItem.item && !creatorItem?.editItem && (
                <ActDisassemblingCreatorItemModal
                    idDocument={data.idGlobal as string}
                    searchOption={searchOption.value}
                    //scanData={searchOption === SearchOption.kizOrKizBox ? scanData : undefined}
                    ok={(createItem: ICreateItem) => {
                        operativeReserve.send(
                            {
                                idLot: createItem.item.idLotFromGlobal,
                                idTable: IdTableVariant.ActDisassembling,
                                idDocument: data.idGlobal as string,
                                idDocumentItem: createItem.item.idGlobal,
                                quantity: createItem.item.quantityFrom,
                            },
                            () => {
                                let filteredItems: IActDisassemblingItemCreateDTO[] = data.items.filter((x) => x.idLotFromGlobal !== createItem.item.idLotFromGlobal);
                                let filteredDisplayItems: IDisplayItem[] = displayItems.filter((x) => x.idLotFromGlobal !== createItem.displayItem.idLotFromGlobal);
                                setData({ ...data, items: [...filteredItems, createItem.item] });
                                setDisplayItems([...filteredDisplayItems, createItem.displayItem]);
                                setCreatorItem({ show: false, item: null });
                                setItemsFilter([...itemsFilter, createItem.filter]);
                                setItemsValidation([...itemsValidation, { idGlobal: createItem.item.idGlobal, message: "", err: false }]);
                                setBaseFields({ ...baseFields, storeFromLot: creatorItem.item?.store as IEntitySimpleDTO });
                            }
                        );
                    }}
                    cancel={(idDocumentItem) => {
                        operativeReserve.delete(
                            {
                                idLot: creatorItem.item?.idGlobal as string,
                                idTable: IdTableVariant.ActDisassembling,
                                idDocument: data.idGlobal as string,
                                idDocumentItem: idDocumentItem,
                            },
                            () => setCreatorItem({ show: false, item: null })
                        );
                    }}
                    item={creatorItem.item}
                    pricingModel={pricingModel}
                />
            )}

            {lotsViewItem.item && lotsViewItem.show && (
                <LotsSimpleSelectorModal
                    ok={(value_: IEntitySimpleDTO) => {
                        lotDP.getById(value_.idGlobal, (value) => setCreatorItem({ show: true, item: value }));
                    }}
                    cancel={() => setLotsViewItem({ item: lotsViewItem.item, show: false })}
                    data={lotsViewItem.item}
                />
            )}

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            setShowMessageModal({ show: false, message: "" });
                            showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                        },
                    }}
                    cancel={{
                        onClick: () => setShowMessageModal({ show: false, message: "" }),
                    }}
                    primary
                />
            )}

            {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}
            
            {showMessageModalValidBaseDocument && 
                <MessageModalWindow
                    message={t("errors.noStoreForBaseDocument")}
                    ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                />
            }

            {selectBaseDocument && (
                <SelectDocumentModalWindow
                    idStoreGlobal={data.idStoreGlobal ?? uuidNull}
                    dateDocument={data.documentDate}
                    ok={(lots, document) => {
                        const values: IReturnCreatedItem[] = lots.filter(l => !l.isKizBox).map((x) => {
                            const idGlobal: string = uuidv4();
                            return {
                                displayItem: {
                                    idGlobal: idGlobal,
                                    idLotFromGlobal: x.idGlobal,
                                    goodsName: x.goods.displayName,
                                    quantityFrom: 0,
                                    scalingRatioTo: "",
                                    scalingRatioFrom: x.scalingRatio.displayName,
                                    retailPrice: x.retailCostInfo?.price ?? 0,
                                    supplierPrice: x.supplierCostInfo?.price ?? 0,
                                    supplierSum: x.supplierCostInfo?.sum ?? 0,
                                    retailSumWithVat: x.retailCostInfo?.sumIncVat ?? 0,
                                    retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                    quantity: 0,
                                    isKiz: x.isKizFlag,
                                    isGnvls: false,
                                    countKizScan: x.isKiz === false ? undefined : 0,
                                    dateCreated: DateTime.now()
                                } as IDisplayItem,
                                createdItem: {
                                    idGlobal: idGlobal,
                                    idLotGlobal: "",
                                    idGoodsGlobal: x.goods.idGlobal,
                                    idScalingRatioToGlobal: "",
                                    idScalingRatioFromGlobal: x.scalingRatio.idGlobal,
                                    retailCostInfo: x.retailCostInfo,
                                    supplierCostInfo: x.supplierCostInfo,
                                    idLotFromGlobal: x.idGlobal,
                                    quantityFrom: 0,
                                    quantity: 0,
                                    productMargin: x.productMargin ?? 0,
                                    kizCount: 0,
                                    kizBoxCount: 0,
                                    supplier: x.supplier,
                                    dateCreated: DateTime.now()
                                } as IActDisassemblingItemCreateDTO,
                            };
                        });
                        setDisplayNameBaseDoc(document.displayName);
                        setDisplayItems(values.map((x) => x.displayItem));
                        setData({ ...data, items: values.map((x) => x.createdItem) });
                        setSelectBaseDocument(false);
                    }}
                    cancel={{ onClick: () => setSelectBaseDocument(false) }}
                />
            )}

        </BaseDocumentCreator>
    );
};
