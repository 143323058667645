import {FC, useState} from 'react';
import {SearchButton} from '../buttons/iconButtons/action/MainCollection';
import {TextInput} from '../controls/inputs';
import {Select, IOption} from '../selects/select';
import styles from './styles/BaseCommandsPanel.module.scss';

interface ISearchModalPanel {
  searchQuery: string;
  onChange: (value: string) => void;
  onClickOnSearchButton: () => void;
  onSelect: (option: IOption) => void;
  defaultOptionFilter: IOption;
  options?: IOption[]
}

export const SearchModalPanel: FC<ISearchModalPanel> = (props) => {
  const [searchValue, setSearchValue] = useState<string>(props.searchQuery);

  return (
    <div className={styles.searchField}>
      <div className={styles.searchField_SearchIcon}>
        <SearchButton sizeVariant="mini" onClick={() => props.onClickOnSearchButton()} />
      </div>
      <TextInput
        inline
        className={styles.searchField_SearchInput}
        label='Поиск'
        value={searchValue}
        searchButtonShow={true}
        onChange={(value: string) => {
          setSearchValue(value);
        }}
        searchButtonOnClick={() => {
          props.onChange(searchValue);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') { 
            props.onChange(searchValue);
          }
        }}
      />
      <Select
        onSelect={(option) => props.onSelect(option)}
        defaultOption={props.defaultOptionFilter}
        className={styles.searchField_TypeSearch}
        options={props.options ??
          [
          {displayName: 'Наименование товара', value: 'name'},
          {displayName: 'Код товара', value: 'goodsCode'},
        ]}
      />
    </div>
  );
};
