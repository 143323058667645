import { FC, useEffect, useState } from "react"
import styles from "./datePeriodPicker.module.scss"
import { DatePicker } from "../../components/datePicker/datePicker";
import { DateTime } from "luxon";

export interface IDatePeriod {
    startDate: DateTime | null,
    endDate: DateTime | null
}

interface IDatePeriodPickerProps{
    period?: IDatePeriod,
    onPeriodChange?: (value: IDatePeriod) => void
    disabled?: boolean
}

const DatePeriodPicker:FC<IDatePeriodPickerProps> = (props) => {

    const [incorrectPeriod, setIncorrectPeriod] = useState<boolean>(false);

    const minDate = DateTime.fromJSDate(new Date('1900-01-01' as string));
    const maxDate = DateTime.fromJSDate(new Date('2999-12-31' as string));

    return (
        <div className={styles.datePeriodPicker}>
            <DatePicker 
                date={props.period?.startDate} 
                onBlur={value => {
                    if ((value as DateTime) > (props.period?.endDate as DateTime)) {
                        setIncorrectPeriod(true);
                        props.onPeriodChange?.({startDate: value?.day ? value : minDate, endDate: value} as IDatePeriod);
                    } else {
                        setIncorrectPeriod(false);
                        props.onPeriodChange?.({startDate: value?.day ? value : minDate, endDate: props.period?.endDate} as IDatePeriod);
                    }
                }}
                incorrectPeriod={incorrectPeriod}
                disabled={props.disabled}
                minDate={'1900-01-01'}
                maxDate={'2999-12-31'}
            />
            <div className={styles.separator}>一</div>
            <DatePicker 
                date={props.period?.endDate} 
                onBlur={value => {
                    if ((value as DateTime) < (props.period?.startDate as DateTime)) {
                        setIncorrectPeriod(true);
                        props.onPeriodChange?.({startDate: value, endDate: value?.day ? value : maxDate} as IDatePeriod)
                    } else {
                        setIncorrectPeriod(false);
                        props.onPeriodChange?.({startDate: props.period?.startDate, endDate: value?.day ? value : maxDate} as IDatePeriod)
                    }
                }}
                incorrectPeriod={incorrectPeriod}
                disabled={props.disabled}
                minDate={'1900-01-01'}
                maxDate={'2999-12-31'}
            />
        </div>
    )
}
export default DatePeriodPicker