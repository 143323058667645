import { ITimeZoneDTO, ITimeZoneViewDTO } from "../../coreapi-dto/dirs/timeZone";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { ContentType } from "../../fetchApiDistributor/contentTypes";

class TimeZoneRequest extends BaseDirRequest<ITimeZoneViewDTO, ITimeZoneDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "timeZone", token, eventHandler)
    }

    getTimeZones(callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetTimeZone`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        })
    }

    GetTimeZoneOffset(idTimeZone: string, callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetTimeZoneOffset/${idTimeZone}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        })
    }



}
export default TimeZoneRequest