import { FC, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import GridMultipleSelectorInput from "../../../../components/controls/GridMultipleSelectorInput";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { v4 as uuidv4 } from 'uuid';
import useGridFilter, { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { useTranslation } from 'react-i18next';
import KizInventoryItemSelectorModal from "../forms/KizInventoryItemSelectorModal";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { KizInventoryDataProvider } from "../../../../Services/DataProviders/KizInventoryDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { SelectedEntityKizInventoryItemCorrectPluginSettings } from "..";
import { IKizInventoryItemCorrectViewDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";
import styles from '../styles/KizInventoryCreator.module.scss'
import stylesBase from '../../../../components/modalWindows/styles/BaseModalWindow.module.scss'
import { PluginWrapper } from "../../../../components/plugins";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";

interface ICorrectModalWindowProps {
    masterIdGlobal: string;
    ok?: () => void;
    cancel?: () => void;
    plugin: IPluginSettings;
}

const DefaultDetailGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: "SendMdlp",
            operator: 'Eq',
            value: true
        }
    ],
};

export const CorrectModalWindow: FC<ICorrectModalWindowProps> = (props) => {
    const appCtx = useAppContext();
    const [selectedKizInventoryItems, setSelectedKizInventoryItems] = useState<IEntitySimpleDTO[]>([]);
    const [kizInventoryItems, setKizInventoryItems] = useState<IKizInventoryItemCorrectViewDTO[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter);
    const [gridFilterItems, dispatchGridFilterItems] = useGridFilter(DefaultDetailGridFilter);
    const kizInventoryDP = new KizInventoryDataProvider(appCtx.coreApiService);
    const [totalCount, setTotalCount] = useState(0);
    const userContext = useUserContext();
    const [isSubmittingCreate, setIsSubmittingCreate] = useState<boolean>(false);
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.kizInventory." + value);

    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        getKizInventoryItems()
    }, [gridFilterItems]);

    function getKizInventoryItems() {
        if (props.masterIdGlobal) {
            kizInventoryDP.getItemsCorrectView(props.masterIdGlobal, gridFilterItems, (values, count) => {
                setKizInventoryItems(values)
                setTotalCount(count);
                setSelectedKizInventoryItems(values.map((x) => {
                    return {
                        displayName: x.sgtin,
                        idGlobal: x.idGlobal
                    } as IEntitySimpleDTO 
                }))
            })
        }
    }

    function updateKizInventoryItemsSendMdlp(entity: IEntitySimpleDTO[]) {
        let body = {
            idsKizInventoryItemGlobal: entity.map(x => {return  x.idGlobal})
        }
        kizInventoryDP.updateItemsSendMdlp(props.masterIdGlobal, body, (data) => {
            getKizInventoryItems();
        });
    }

    function createKizDocItems() {
        setIsSubmittingCreate(true);
        kizInventoryDP.createKizDocItems(props.masterIdGlobal, (flag) => {
            if (flag)
                renderGlobalAlert({
                    variant: "success",
                    title: baseT("success"),
                    statusCode: 200,
                });
            else
                renderGlobalAlert({
                    variant: "info",
                    title: baseT("partSuccess"),
                    statusCode: 200,
                });
            setIsSubmittingCreate(false);
        }, (message) => {
            renderGlobalAlert({
                variant: "exception",
                title: message.title,
                detail: message.detail,
                statusCode: message.statusCode,
            });
            setIsSubmittingCreate(false);
        });
    }

    return (
        <BaseModalWindow
            header= {baseT("itemsCorrect")} 
            ok={{
                disabled: !props.masterIdGlobal || isSubmittingCreate || lockFromPermission,
                sendRequestSpinner: isSubmittingCreate,
                title: baseT("createKizDocItems"),
                onClick: () => {
                    createKizDocItems();
                }
            }}
            cancel={{ onClick: () => props.cancel?.(), title: "Закрыть" }}

        >
            <GridMultipleSelectorInput
                masterIdGlobal={props.masterIdGlobal}
                selectedEntity={selectedKizInventoryItems?.[0]}
                selectedEntities={selectedKizInventoryItems}
                id={`idKizInventoryItem-${uuidv4()}`}
                switchOnchange
                gridFilter={gridFilter}
                selectorModalJsx={KizInventoryItemSelectorModal}
                label={baseT("sgtins")}
                onSelect={(entities: IEntitySimpleDTO[]) => {
                    setSelectedKizInventoryItems(entities);
                    updateKizInventoryItemsSendMdlp(entities);
                }}
                disabled={lockFromPermission}
            />
            {
                props.masterIdGlobal &&
                <DefaultGrid
                    gridId={uuidv4()}
                    data={kizInventoryItems}
                    filter={gridFilterItems}
                    totalCount={totalCount}
                    plugin={SelectedEntityKizInventoryItemCorrectPluginSettings}
                    selectedItem={undefined}
                    onSelect={(row) => { }}
                    onSort={(i) => { }}
                    onFilterDelete={(i) => { dispatchGridFilterItems({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilterItems({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => { dispatchGridFilterItems({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                    hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                />
            }
            
        </BaseModalWindow>
    );
};
