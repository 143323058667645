import { FC, CSSProperties } from "react";
import styles from './styles/BaseModalWindow.module.scss';
import classNames from "classnames";
import { BaseModalWindow, IButton } from "./BaseModalWindow";

interface ITreeViewSelectorModalProps {
    gridDisplayName: string;
    ok: IButton;
    cancel: IButton;
    modalWindowClassName?: string;
    style?: CSSProperties;
}
export const TreeViewSelectorModal: FC<ITreeViewSelectorModalProps> = (props) => {
    const title: string = 'Выбор строк из справочника: ' + props.gridDisplayName;

    return (
        <BaseModalWindow header={title}
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={classNames(props.modalWindowClassName, styles.modalEditValues_TreeView)}
            style={props.style}>
            {props.children}
        </BaseModalWindow>
    );
};
