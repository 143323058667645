
function LogoF3TailSvg(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 123 52"
            {...props}
        >
              <path fill="#039BB3" d="M16.494 21.203v3.172h7.659v4.705h-7.659v6.801h-6.19V16.5h14.924v4.704h-8.734zM37.454 23.997c1.432.361 2.528 1.02 3.288 1.977.778.938 1.166 2.075 1.166 3.41 0 1.137-.309 2.202-.928 3.195-.6.974-1.537 1.768-2.81 2.382-1.273.613-2.855.92-4.746.92-1.308 0-2.616-.144-3.924-.433-1.29-.289-2.422-.713-3.394-1.272l2.121-4.71c.76.45 1.573.794 2.44 1.028a9.914 9.914 0 002.545.352c.725 0 1.308-.126 1.75-.379.442-.252.663-.613.663-1.083 0-.866-.734-1.299-2.201-1.299h-2.466V24.16l2.731-2.843h-6.628V16.5H40.9v3.925l-3.447 3.573zM63.587 22.38h-5.602v-4.975h17.651v4.975h-5.6v14.055h-6.45V22.38zM89.384 33.118h-7.268l-1.284 3.317h-6.558l8.361-19.03h6.34l8.36 19.03H90.67l-1.285-3.317zm-1.776-4.621l-1.858-4.785-1.858 4.785h3.716zM98.365 17.405h6.448v19.03h-6.448v-19.03zM107.917 17.405h6.449V31.46H123v4.975h-15.083v-19.03z"/>
              <path stroke="#039BB3" strokeMiterlimit="10" strokeWidth="2.712" d="M2 49.619h47.451V2H2v47.619z"/>
        </svg>
    )
}
const LogoF3Tail2Svg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 44" fill="none" width="102px" height="42px">
            <path d="M14.2181 18.1309V20.7951H20.6743V24.747H14.2181V30.4602H9V14.1791H21.5808V18.1309H14.2181Z" fill="#CED4DA" />
            <path d="M31.8869 20.4778C33.0939 20.781 34.0177 21.3343 34.6584 22.1377C35.314 22.926 35.6418 23.881 35.6418 25.0028C35.6418 25.9579 35.3811 26.8523 34.8595 27.686C34.3529 28.5046 33.5632 29.1716 32.4904 29.687C31.4176 30.2025 30.084 30.4602 28.4897 30.4602C27.387 30.4602 26.2844 30.3389 25.1818 30.0963C24.0941 29.8538 23.1404 29.4976 22.3209 29.0276L24.109 25.071C24.7497 25.45 25.4351 25.738 26.1652 25.9351C26.9102 26.1322 27.6254 26.2307 28.3109 26.2307C28.9218 26.2307 29.4135 26.1246 29.786 25.9124C30.1585 25.7001 30.3448 25.397 30.3448 25.0028C30.3448 24.2752 29.7264 23.9114 28.4897 23.9114H26.4111V20.6142L28.7132 18.2266H23.1255V14.1791H34.7925V17.4762L31.8869 20.4778Z" fill="#CED4DA" />
            <path d="M53.9159 19.1193H49.1941V14.9403H64.0737V19.1193H59.3518V30.9254H53.9159V19.1193Z" fill="#039BB3" />
            <path d="M75.6627 28.1394H69.5358L68.4532 30.9254H62.9252L69.9734 14.9403H75.3172L82.3654 30.9254H76.7453L75.6627 28.1394ZM74.1655 24.2573L72.5993 20.2382L71.033 24.2573H74.1655Z" fill="#039BB3" />
            <path d="M83.2329 14.9403H88.6688V30.9254H83.2329V14.9403Z" fill="#039BB3" />
            <path d="M91.2856 14.9403H96.7215V26.7464H104V30.9254H91.2856V14.9403Z" fill="#039BB3" />
            <path d="M2 42H42V22V12V2H32H22H2V42Z" stroke="#039BB3" strokeWidth="2.71165" strokeMiterlimit="10" />
        </svg>
    )
}

const LogoF3Tail3Svg = () => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" width="101" height="42" viewBox="0 0 101 42" fill="none">
                <g clipPath="url(#clip0)">
                    <rect width="42" height="42" fill="#324155"/>
                    <path d="M1 41H41V21V11V1H31H21H1V41Z" stroke="#039BB3" strokeWidth="2.71165" strokeMiterlimit="10"/>
                    <path d="M7 30.9988V10.7C7 10.3134 7.3134 10 7.7 10H24.547C25.1333 10 25.4598 10.6777 25.0944 11.1363L22.2003 14.7675C22.0675 14.9342 21.866 15.0312 21.6529 15.0312H12.6032C12.2166 15.0312 11.9032 15.3446 11.9032 15.7312V17.6636C11.9032 18.0502 12.2166 18.3636 12.6032 18.3636H17.5829C18.1629 18.3636 18.4913 19.0285 18.1388 19.489L15.2101 23.3163C15.0777 23.4894 14.8722 23.5909 14.6542 23.5909H12.7C12.3134 23.5909 12 23.9043 12 24.2909V26.4824C12 26.6409 11.9462 26.7948 11.8474 26.9187L8.24738 31.4351C7.83455 31.953 7 31.6611 7 30.9988Z" fill="#CED4DA"/>
                    <path d="M34.3 10H30.8569C30.6327 10 30.4221 10.1074 30.2904 10.2888L27.5043 14.1269C27.1684 14.5896 27.499 15.2381 28.0708 15.2381H29.4163C29.8029 15.2381 30.1163 15.5515 30.1163 15.9381V17.681C30.1163 18.0676 29.8029 18.381 29.4163 18.381H24.1186C23.8976 18.381 23.6896 18.4853 23.5575 18.6624L20.6947 22.5005C20.3504 22.9622 20.6799 23.619 21.2559 23.619H26.6977H29.4163C29.8029 23.619 30.1163 23.9324 30.1163 24.319V26.1438C30.1163 26.5303 29.8029 26.8438 29.4163 26.8438H17.3429C17.1266 26.8438 16.9224 26.9438 16.7898 27.1147L13.8759 30.8709C13.5191 31.3309 13.8469 32 14.429 32H34.3C34.6866 32 35 31.6866 35 31.3V10.7C35 10.3134 34.6866 10 34.3 10Z" fill="#CED4DA"/>
                </g>
                <path d="M50.7218 17.179H46V13H60.8796V17.179H56.1577V28.9851H50.7218V17.179Z" fill="#039BB3"/>
                <path d="M72.4686 26.1991H66.3417L65.2592 28.9851H59.7311L66.7794 13H72.1231L79.1713 28.9851H73.5512L72.4686 26.1991ZM70.9714 22.317L69.4052 18.2979L67.8389 22.317H70.9714Z" fill="#039BB3"/>
                <path d="M80.0389 13H85.4747V28.9851H80.0389V13Z" fill="#039BB3"/>
                <path d="M88.0915 13H93.5274V24.8061H100.806V28.9851H88.0915V13Z" fill="#039BB3"/>
                <defs>
                    <clipPath id="clip0">
                        <rect width="42" height="42" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
    )
}

export { LogoF3TailSvg, LogoF3Tail2Svg, LogoF3Tail3Svg }