import { FC, useContext, useEffect, useState } from "react"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import { Spinner } from "../../../components/spiner/Spinner"
import { IPricingFormulaViewDTO } from "../../../libs/coreapi-dto/dirs/pricingFormula"
import { PricingFormulaDataProvider } from "../../../Services/DataProviders/PricingFormulaDataProvider"
import useGridFilter from "../../../system/hooks/useGridFilter"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { CalculationFormulaModal } from "./Components/CalculationFormulaModal"
import { PricingFormulaPanel } from "./Components/PricingFormulaPanel"
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common"
import { checkLockStatus } from "../../CommonHelperFunctions"
import { useUserContext } from "../../../system/providers/userContextProvider"

interface ICustomGridProps  extends IGridProps{
    disabledPricingFormulaPanelInput?: boolean
}

export const PricingFormulaPluginView: FC<ICustomGridProps> = (props) => {
    const appCtx = useAppContext();
    const pricingFormulaDP = new PricingFormulaDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<IPricingFormulaViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [openFormula, setOpenFormula] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO | null>(null);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        setIsSubmitting(true)
        pricingFormulaDP.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (viewState == 'refresh') setViewState('view')
            setIsSubmitting(false)
        })
    }, [gridFilter, viewState])

    useEffect(() => {
        if (pricingModel?.idGlobal) {
            dispatchGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "idPricingPriceModelGlobal",
                    operator: "Eq",
                    value: pricingModel?.idGlobal,
                    invisible: true,
                },
            });
        } else {
            dispatchGridFilter({
                type: "deleteColumnFilter",
                payload: "idPricingPriceModelGlobal",
            });
        }
    }, [pricingModel]);


    return (

        <PluginWrapper>
            <PricingFormulaPanel
                refresh={{ onClick: () => setViewState('refresh') }}
                edit={{
                    onClick: () => setOpenFormula(true),
                    disabled: data.find(x => x.idGlobal === selectedItem?.idGlobal)?.isConst ? true : false || data.find(x => x.idGlobal === selectedItem?.idGlobal)?.visible ? false : true
                }}
                pricingModelObject={{ setPricingModel, pricingModel }}
                permission={props.plugin.permission}
                disabledPricingFormulaPanelInput={props.disabledPricingFormulaPanelInput}
            />
            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={pricingFormulaDP}
                    hiddenPagination={undefined}
                    separator
                    singleDirectory
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    contextMenuItems={
                        [{
                            name: "Изменить формулу модели",
                            onClick: () => { setOpenFormula(true) },
                            disabled: data.find(x => x.idGlobal === selectedItem?.idGlobal)?.isConst ? true : false || data.find(x => x.idGlobal === selectedItem?.idGlobal)?.visible ? false : true
                        }
                        ]}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
            {
                openFormula && selectedItem &&
                <CalculationFormulaModal
                    cancel={() => setOpenFormula(false)}
                    isSubmitting={isSubmitting}
                    data={data.find(x => x.idGlobal === selectedItem.idGlobal) as IPricingFormulaViewDTO}
                    ok={(value: IPricingFormulaViewDTO) => {
                        setIsSubmitting(true)
                        pricingFormulaDP.update(data.find(x => x.idGlobal === selectedItem.idGlobal)?.idGlobal as string, value, () => {
                            setViewState('refresh')
                            setOpenFormula(false)
                        })
                    }}
                    lockFromPermission={lockFromPermission}
                />
            }
        </PluginWrapper>
    )
}