import React, {FC, PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import {checkLockStatus} from '../../Business/CommonHelperFunctions';
import {IContractorParamFilters} from '../../libs/coreapi-dto/dirs/contractor';
import {GridFilterAction} from '../../system/hooks/useGridFilter';
import {usePluginContext} from '../../system/providers/plugin';
import {useUserContext} from '../../system/providers/userContextProvider';
import {DeleteModalWindow} from '../modalWindows/DeleteModalWindow';
import {RestoreModalWindow} from '../modalWindows/RestoreModalWindow';
import {SearchModalWindow} from '../modalWindows/SearchModalWindow';
import TreeViewCommandsPanel from './TreeViewCommandsPanel';
import styles from './styles/BaseCommandsPanel.module.scss';
import {SearchModalPanel} from './SearchModalPanel';
import { IOption } from '../selects/select';
import { DocumentStatusType } from '../../@types/enumsGlobal';
type GridRowViewState = 'view' | 'return' | 'attachToGroup' | 'moveToGroup' | 'detachFromGroup';

interface IGroupModel {
  name: string;
  parentName: string | undefined;
  idParentGroup: string | undefined;
}
enum MultipleChoice {
  Restore = 'restore',
  Deleted = 'deleted',
}
function checkDocumentStateForMultipleChoice(arr: IGridRow[] | [], checkType: MultipleChoice): boolean {
  if (arr.length === 0) {
      return true;
  }

  switch (checkType) {
      case MultipleChoice.Deleted:
          return !arr.every(object => object.isDeleted === false);
      case MultipleChoice.Restore:
          return !arr.every(object => object.isDeleted);
      default:
          throw new Error('Ошибка');
  }
}
interface IDefaultTreeViewPanelProps<TEntityViewDTO, TEntityGroupViewDTO, TEntityDTO, TEntityGroupDTO> {
  multipleSelect?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  selectedItems?: [IGridRow[] | [], (value: IGridRow[] | []) => void];
  selectedDocumentStatusType?: DocumentStatusType | undefined;
  selectedItem?: [IGridRow | undefined, React.Dispatch<React.SetStateAction<IGridRow | undefined>>];
  gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>];
  dataProvider: ITreeViewDictionaryDataProvider<TEntityViewDTO, TEntityDTO>;
  groupDataProvider: IDictionaryDataProvider<TEntityGroupViewDTO, TEntityGroupDTO>;
  creatorModalJsx: FC<ICreatorModalProps<TEntityDTO>>;
  creatorGroupModalJsx: FC<ICreatorModalProps<TEntityGroupDTO>>;
  selectorGroupModalJsx: FC<ITreeViewSelectorModalProps>;
  pluginSettings: IPluginSettings;
  setViewState?: (viewState: GridStateType) => void;
  setGroupViewState?: (viewState: TreeViewStateType) => void;
  contextMenuViewState?: [GridRowViewState, React.Dispatch<React.SetStateAction<GridRowViewState>>]
  isModal?: boolean;
  dispatchGridFilter?: (value: GridFilterAction) => void;
  searchQuery?: string;
}

const DefaultTreeViewCommandsPanel = <TEntityViewDTO, TEntityGroupViewDTO, TEntityDTO, TEntityGroupDTO>(
  props: PropsWithChildren<
    IDefaultTreeViewPanelProps<
      TEntityViewDTO,
      TEntityGroupViewDTO,
      TEntityDTO,
      TEntityGroupDTO extends IGroupModel ? any : any
    >
  >
) => {
  const [viewState, setViewState] = useState<GridStateType>('view');
  const [modalWindow, setModalWindow] = useState(<></>);
  const [groupViewState, setGroupViewState] = useState<TreeViewStateType>('view');
  const [groupModalWindow, setGroupModalWindow] = useState(<></>);
  const [contextMenuModalWindow, setContextMenuModalWindow] = useState(<></>);

  const defaultOptionFilters = [{displayName: 'Наименование товара', value: 'name'},
  {displayName: 'Код товара', value: 'code'}] as IOption[];

  const userContext = useUserContext();
  const lockFromPermission = checkLockStatus(props.pluginSettings.permission as IPermission, userContext.userPermission);

  const isGoodsCode = () => {
    if (props.gridFilter?.[0].columnFilters) {
      let filter = props.gridFilter?.[0].columnFilters.find((x) => x.name === 'code')
      if (filter) {     
        return filter
      }
    }
    return undefined
  }

  const getOption = () => {
    if (isGoodsCode())
      return defaultOptionFilters[1]
    return defaultOptionFilters[0]
  }

  const [optionFilter, setOptionFilter] = useState<IOption>(getOption());
  const pluginContext = usePluginContext();

  const getSearchQuery = () => {
    let result = isGoodsCode()
    if (result)
      return result.value as string
    return ''   
  }

  const [searchQuery, setSearchQuery] = useState<string>(getSearchQuery());

  useEffect(() => {
    props.setViewState?.(viewState);
  }, [viewState]);

  useEffect(() => {
    renderModalWindow();
  }, [viewState]);

  useEffect(() => {
    renderGroupModalWindow();
  }, [groupViewState]);

  useEffect(() => {
    renderContextMenuModalWindow();
  }, [props.contextMenuViewState]);

  useEffect(() => {
    refreshGridWithSelectedFolder();
  }, [pluginContext.treeView.selectedFolder]);

  function refreshGridWithSelectedFolder() {
    if (
      pluginContext.treeView.selectedFolder?.folderId !== undefined &&
      pluginContext.treeView.selectedFolder?.folderId !== ''
    ) {
      const paramFilter: IContractorParamFilters = {
        idGroupGlobal: pluginContext.treeView.selectedFolder?.folderId,
      };

      props.gridFilter?.[1]({type: 'paramFilter', payload: {gridParamFilter: paramFilter}});
      setViewState('refresh');
    }
  }

  // ModalWindows
  function renderModalWindow() {
    switch (viewState) {
      case 'create':
        renderCreator();
        break;
      case 'edit':
        renderEditor();
        break;
      case 'copy':
        renderCopyEditor();
        break;
      case 'delete':
        renderDeleteWindow();
        break;
      case 'restore':
        renderRestoreWindow();
        break;
      case 'search':
        renderSearchWindow();
        break;
      default:
        setModalWindow(<></>);
    }
  }

  function renderCreator() {
    setModalWindow(
      <props.creatorModalJsx
        variant="create"
        data={undefined}
        save={(model) => {
            props.dataProvider?.create(model, (idGlobal) => {
              if (pluginContext.treeView?.selectedFolder) {
                props.dataProvider?.attachToGroup(idGlobal, pluginContext.treeView?.selectedFolder?.folderId as string, () => setViewState('refresh'));
              } else {
                setViewState('refresh');
              }
            });
        }}
        cancel={() => setViewState('return')}
        lockFromPermission={lockFromPermission}
      />
    );
  }

  function renderEditor() {
    props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, (entity) => {
      setModalWindow(
        <props.creatorModalJsx
          variant="edit"
          data={entity}
          save={(model) =>
            props.dataProvider?.update(props.selectedItem?.[0]?.idGlobal as string, model, () =>
              setViewState('refresh')
            )
          }
          cancel={() => setViewState('return')}
          lockFromPermission={lockFromPermission}
        />
      );
    });
  }

  function renderCopyEditor() {
    props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, (entity) => {
      setModalWindow(
        <props.creatorModalJsx
          variant="copy"
          data={entity}
          save={(model) => props.dataProvider?.create(model, () => setViewState('refresh'))}
          cancel={() => setViewState('return')}
          lockFromPermission={lockFromPermission}
        />
      );
    });
  }

  function renderDeleteWindow() {
    setModalWindow(
      <DeleteModalWindow
        name={props.multipleSelect?.[0] === true ? '' : props.selectedItem?.[0]?.cells['name'] as string}
        cancel={{onClick: () => setViewState('return')}}
        delete={{
          onClick: 
          async () => {
            if (props.multipleSelect?.[0] === true && props.selectedItems) {

                const promises = props.selectedItems[0].map((element) => {
                    return new Promise((resolve) => {
                        props.dataProvider?.markDelete(element.idGlobal as string, (e) => {

                            if (e.respType === 'isCompleted') {
                                resolve(e.respType);
                            }
                        });
                    });
                });
                const results = await Promise.all(promises);

                if (results.length === props.selectedItems[0].length) {
                    setViewState('refresh');
                } else {
                    setViewState('refresh');
                }

            } else {
                 props.dataProvider?.markDelete(props.selectedItem?.[0]?.idGlobal as string, () => setViewState('refresh'))
                 props.selectedItem?.[1](undefined)
             }
         },
          title: 'Удалить',
        }}
      />
    );
  }

  function renderRestoreWindow() {
    setModalWindow(
      <RestoreModalWindow
        name={props.selectedItem?.[0]?.cells['name'] as string}
        cancel={{onClick: () => setViewState('return')}}
        restore={{
          onClick: async () => {
            if (props.multipleSelect?.[0] === true && props.selectedItems) {

                const promises = props.selectedItems[0].map((element) => {
                    return new Promise((resolve) => {
                        props.dataProvider?.restore(element.idGlobal as string, (e) => {

                            if (e.respType === 'isCompleted') {
                                resolve(e.respType);
                            }
                        });
                    });
                });
                const results = await Promise.all(promises);

                if (results.length === props.selectedItems[0].length) {
                    setViewState('refresh');
                } else {
                    setViewState('refresh');
                }

            } else {
                 props.dataProvider?.restore(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("refresh"))
                 props.selectedItem?.[1](undefined)
             }
         },
          title: 'Восстановить',
        }}
      />
    );
  }

  function renderSearchWindow() {
    const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? [];
    setModalWindow(
      <SearchModalWindow
        defaultFilters={props.gridFilter?.[0].columnFilters}
        columns={visibleColumns}
        cancel={() => setViewState('return')}
        search={(columnFilters) => {
          props.gridFilter?.[1]({type: 'search', payload: columnFilters});
          setViewState('refresh');
        }}
      />
    );
  }

  // GroupModalWindows
  function renderGroupModalWindow() {
    switch (groupViewState) {
      case 'create':
        renderGroupCreator();
        break;
      case 'edit':
        renderGroupEditor();
        break;
      case 'delete':
        renderGroupDeleteWindow();
        break;
      case 'return':
        pluginContext.treeView.onUnselectEvent();
        setGroupModalWindow(<></>);
        break;

      default:
        setGroupModalWindow(<></>);
        break;
    }
  }

  function renderGroupCreator() {
    setGroupModalWindow(
      <props.creatorGroupModalJsx
        variant="create"
        cancel={() => setGroupViewState('return')}
        data={{
          name: '',
          parentName: pluginContext.treeView.selectedFolder?.displayName,
          idParentGroup: pluginContext.treeView.selectedFolder?.folderId,
        }}
        save={(model) => {
          props.groupDataProvider?.create(model, () => setGroupViewState('return'));
        }}
        lockFromPermission={lockFromPermission}
      />
    );
  }

  function renderGroupEditor() {
    if (pluginContext.treeView.selectedFolder?.folderId) {
      props.groupDataProvider?.getById(pluginContext.treeView.selectedFolder?.folderId as string, (entity) =>
        setGroupModalWindow(
          <props.creatorGroupModalJsx
            variant="edit"
            data={entity}
            cancel={() => setGroupViewState('return')}
            save={(model) => {
              props.groupDataProvider?.update(
                pluginContext.treeView.selectedFolder?.folderId as string,
                model,
                () => setGroupViewState('return')
              );
            }}
            lockFromPermission={lockFromPermission}
          />
        )
      );
    }
  }

  function renderGroupDeleteWindow() {
    if (pluginContext.treeView.selectedFolder) {
      setGroupModalWindow(
        <DeleteModalWindow
          name={pluginContext.treeView.selectedFolder?.displayName}
          cancel={{
            onClick: () => {
              setGroupViewState('return');
            },
          }}
          delete={{
            onClick: () => {
              props.groupDataProvider?.markDelete(
                pluginContext.treeView.selectedFolder?.folderId as string,
                () => setGroupViewState('return')
              );
            },
            title: 'Удалить',
          }}
        />
      );
    }
  }

  // ContextMenuModalWindows
  function renderContextMenuModalWindow() {
    switch (props.contextMenuViewState?.[0]) {
      case 'attachToGroup':
        renderAttachModal();
        break;
      case 'moveToGroup':
        renderMoveModal();
        break;
      case 'detachFromGroup':
        renderDetachModal();
        break;

      default:
        setContextMenuModalWindow(<></>);
        break;
    }
  }

  function renderAttachModal() {
    setContextMenuModalWindow(
      <props.selectorGroupModalJsx
        cancel={() => props.contextMenuViewState?.[1]('view')}
        ok={async(folder) => {
          if (props.multipleSelect?.[0] && folder && props.selectedItems) {

            const promises = props.selectedItems[0].map((element) => {
              return new Promise((resolve) => {
                props.dataProvider?.attachToGroup(element.idGlobal as string, folder?.folderId, (e) => {

                  if (e.respType === 'isCompleted') {
                    resolve(e.respType);
                  }
                });
              });
            });
            const results = await Promise.all(promises);

            if (results.length === props.selectedItems[0].length) {
              props.contextMenuViewState?.[1]("return");

            } else {
              props.contextMenuViewState?.[1]("return");
            }
          } 

          if (props.selectedItem?.[0] && folder) {
            props.dataProvider?.attachToGroup(props.selectedItem?.[0]?.idGlobal, folder?.folderId, () =>
            props.contextMenuViewState?.[1]('return')
            );
          }
        }}
        pluginSettings={props.pluginSettings}
      />
    );
  }

  function renderMoveModal() { 
      if ((props.multipleSelect?.[0] && pluginContext.treeView.selectedFolder) || (props.selectedItem?.[0] && pluginContext.treeView.selectedFolder)) {
      let fromFolderId: string = pluginContext.treeView.selectedFolder?.folderId;

      setContextMenuModalWindow(
        <props.selectorGroupModalJsx
          cancel={() => props.contextMenuViewState?.[1]('view')}
          ok={async(toFolder) => {
            if (props.multipleSelect?.[0] && fromFolderId && toFolder && props.selectedItems) {

              const promises = props.selectedItems[0].map((element) => {
                return new Promise((resolve) => {
                  props.dataProvider?.moveFromGroup(element.idGlobal, fromFolderId, toFolder.folderId, (e) => {

                    if (e.respType === 'isCompleted') {
                      resolve(e.respType);
                    }
                  });
                });
              });
              const results = await Promise.all(promises);

              if (results.length === props.selectedItems[0].length) {
                props.contextMenuViewState?.[1]('return');
                refreshGridWithSelectedFolder();

              } else {
                props.contextMenuViewState?.[1]('return');
              }
            } 

            if (props.selectedItem?.[0] && fromFolderId && toFolder) {
              props.dataProvider?.moveFromGroup(
                props.selectedItem?.[0]?.idGlobal,
                fromFolderId,
                toFolder.folderId,
                () => {
                  props.contextMenuViewState?.[1]('return');
                  refreshGridWithSelectedFolder();
                }
              );
            }
          }}
          pluginSettings={props.pluginSettings}
        />
      );
    }
  }

  function renderDetachModal() {
      if ((props.multipleSelect?.[0] && pluginContext.treeView.selectedFolder) || (props.selectedItem?.[0] && pluginContext.treeView.selectedFolder)) {
      let folderId: string = pluginContext.treeView.selectedFolder?.folderId;
      let folderName: string = pluginContext.treeView.selectedFolder?.displayName;

      setContextMenuModalWindow(
        <DeleteModalWindow
          name={props.multipleSelect?.[0] === true ? '' : 'из ' + folderName}
          cancel={{
            onClick: () => {
              props.contextMenuViewState?.[1]('view');
            },
          }}
          delete={{
            onClick: async() => { 
              if (props.multipleSelect?.[0] && props.selectedItems) {

                const promises = props.selectedItems[0].map((element) => {
                  return new Promise((resolve) => {
                    props.dataProvider?.detachFromGroup(element.idGlobal as string, folderId, (e) => {
                      if (e.respType === 'isCompleted') {
                        resolve(e.respType);
                      }
                    });
                  });
                });

                const results = await Promise.all(promises);

                if (results.length === props.selectedItems[0].length) {
                  props.contextMenuViewState?.[1]('return');
                  refreshGridWithSelectedFolder();
                } else {
                  props.contextMenuViewState?.[1]('return');

                }
              }

              if (props.selectedItem?.[0]) {
                props.dataProvider?.detachFromGroup(props.selectedItem?.[0]?.idGlobal, folderId, () => {
                  props.contextMenuViewState?.[1]('return');
                  refreshGridWithSelectedFolder();
                });
              }
            },
            title: 'Удалить',
          }}
        />
      );
    }
  }

  const onSearch = (value: string) => {
    if (optionFilter.value === 'name')
    {
      props.dispatchGridFilter?.({
        type: "paramFilter",        
        payload: {            
            gridParamFilter: {
                goodsName: value
            },
            sortDesc: "Perc"
        },
      });
    }
    else
    {
      props.dispatchGridFilter?.({
        type: 'addColumnFilter',
        payload: {
          name: optionFilter.value,
          operator: 'Like',
          value: `%${value}%`,
        },
      });
    }
  }

  return (
    <div className={styles.wrapper}>
      {!props.isModal && (
        <TreeViewCommandsPanel
          fileAndFolder={{
            visible: !pluginContext.treeView.visiblePanel,
            onClick: () => pluginContext.treeView.onVisiblePanelEvent(!pluginContext.treeView.visiblePanel),
          }}
          add={{
            onClick: () => setViewState('create'),
            disabled: props.multipleSelect?.[0] === true ? props.selectedItems?.[0].length !== 0 : false
          }}
          edit={{
            disabled: props.multipleSelect?.[0] === true ? true :  (props.selectedItem?.[0] === undefined || props.selectedItem?.[0]?.isDeleted),
            onClick: () => setViewState('edit'),
          }}
          copy={{
            disabled: props.selectedItem?.[0] === undefined,
            onClick: () => setViewState('copy'),
          }}
          print={{
            onClick: () => window.alert('print'),
            disabled: props.multipleSelect?.[0] === true ? true : false
          }}
          delete={{
            disabled: props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]!, MultipleChoice.Deleted) : (props.selectedItem?.[0] === undefined  || props.selectedItem?.[0]?.isDeleted),
            onClick: () => setViewState('delete'),
          }}
          restore={{
            disabled:  props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]!, MultipleChoice.Restore) :  (props.selectedItem?.[0] === undefined || !props.selectedItem?.[0]?.isDeleted),
            onClick: () => setViewState('restore'),
          }}
          search={{onClick: () => setViewState('search')}}
          refresh={{onClick: () => props.gridFilter?.[1]({type: 'refresh'})}}
          showDeleted={{
            disabled: false,
            onChecked: (e) => props.gridFilter?.[1]({type: 'showDeleted', payload: e}),
          }}
          permission={props.pluginSettings.permission}
          groupProcessing={{
            onClick: () => {
              props?.multipleSelect?.[1](!props?.multipleSelect?.[0]);
            },
            value: props?.multipleSelect?.[0],
          }}
        />
      )}
      {props.isModal && (
        <SearchModalPanel
          searchQuery={searchQuery}
          options={defaultOptionFilters}
          defaultOptionFilter={optionFilter}
          onClickOnSearchButton={() => setViewState('search')}
          onSelect={(option) => {
            props.dispatchGridFilter?.({
              type: 'deleteColumnFilter',
              payload: optionFilter.value,
            });
            setOptionFilter(option);
          }}
          onChange={(value) => {
            if (value.length > 0) {
              setSearchQuery(value);
              onSearch(value)
            } else {
              setSearchQuery('');            
              props.dispatchGridFilter?.({
                  type: 'paramSimpleFilter',
                  payload: {
                    gridColumnFilter: props.gridFilter?.[0].columnFilters.filter((item) => item.name !== optionFilter.value) ?? [],
                    gridParamFilter: []
                  },
              });
             
            }
          }}
        />
      )}

      {modalWindow}
      {groupModalWindow}
      {contextMenuModalWindow}
    </div>
  );
};

export default DefaultTreeViewCommandsPanel;
