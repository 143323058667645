import { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "../../../../components/controls/accordion";
import { SelectControl } from "../../../../components/controls/SelectControl";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextAreaInput, TextInput } from "../../../../components/controls/inputs";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import styles from "../../../styles/index.module.scss";
import ContractorSelectorInput from "../../../../components/controls/inputs/ContractorSelectorInput";
import createInternetOrdersStyles from "./styles/CreateInternetOrders.module.scss";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { v4 as uuidv4 } from "uuid";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { ICostInfoDTO, IEntitySimpleDTO, ISummaryCostInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { DateTime } from "luxon";
import { IInternetOrderItemCreateDTO, IInternetOrderItemEditDTO, IInternetOrderItemGetDTO } from "../../../../libs/coreapi-dto/accounting/internetOrderItem";
import { useTranslation } from "react-i18next";
import { useForm } from "../../../../system/hooks/useForm";
import { LotsSelectorModal } from "../../../Accounting/Lots";
import { IInternetOrderCreateDTO, IInternetOrderGetDTO, IInternetOrderEditDTO } from "../../../../libs/coreapi-dto/accounting/internetOrder";
import { InternetOrderCreatorItemModal } from "./InternetOrderItemCreator/InternetOrderItemCreatorModal";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { InternetOrderUpdateItemModal } from "./InternetOrderItemCreator/InternetOrderItemUpdateModal";
import { IOption, Select } from "../../../../components/selects/select";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { BtSaveOrOk } from "../../../Documents/Invoice/InvoiceCreator/InvoiceUpdateForm";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { addKizOrKizBox } from "../../../../system/functions/addKizOrKizBox";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { BarcodeType, IKizBoxDTO, IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import { addValueToArray } from "../../../../system/functions/addValToArray";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import InternetOrderItemsGridPluginSettings from "../InternetOrderItems/InternetOrderItemsGridPluginSettings";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import FlexRow from "../../../../components/controls/FlexRow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { GoodsDataProvider } from "../../../../Services/DataProviders/GoodsDataProvider";

interface IValidator {
    contractorOwner: IEntitySimpleDTO;
    contractor: IEntitySimpleDTO;
    documentDate: DateTime;
    deliveryDate: DateTime;
}
interface IBaseFields {
    contractorOwner: IEntitySimpleDTO;
    contractor: IEntitySimpleDTO;
}
interface ICreatorItem {
    show: boolean;
    item: ILotDTO | null;
    editItem?: IInternetOrderItemEditDTO | null;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

interface IDisplayItem extends IDocumentItemCostInfoViewDTO {
    idGlobal: string;
    idLotExternalGlobal?: string;
    goodsName: string;
    lotName?: string;
    scalingRatioDisplayName?: string;
    quantity: number;
    price: number;
    discount: number;
    producer?: string;
    isGnvls: boolean;
    kizCount: number;
    isKiz: boolean;
    dateCreated: DateTime
}
export interface ICreateItem {
    item: IInternetOrderItemEditDTO;
    displayItem: IDisplayItem;
    filter: IItemFilter;
}

interface IReturnCreatedItem {
    displayItem: any;
    createdItem: any;
}

export const InternetOrderUpdateForm: FC<IDocumentUpdateFormProps<IInternetOrderGetDTO, IInternetOrderItemGetDTO[], IInternetOrderEditDTO>> = (props) => {
    const appContext = useAppContext();
    const operativeReserve = useOperativeReserve();
    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string | undefined>();
    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const documentStateProc = (props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false;

    //dataProvider
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IInternetOrderCreateDTO>(
        props.documentUpdate ??
        ({
            idGlobal: props.idGlobal,
            documentDate: DateTime.now(),
            items: [] as IInternetOrderItemCreateDTO[],
        } as IInternetOrderCreateDTO)
    );

    const [supplierCostInfo, setSupplierCostInfo] = useState<ISummaryCostInfoDTO>();
    const [retailCostInfo, setRetailCostInfo] = useState<ISummaryCostInfoDTO>();
    
    const [isSelectedLot, setIsSelectedLot] = useState<boolean>(false);

    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>([]);
    const [totalCount] = useState<number>(0);
    const [baseFields, setBaseFields] = useState<IBaseFields>({
        contractorOwner: {} as IEntitySimpleDTO,
        contractor: {} as IEntitySimpleDTO
    });
    const [mnemocode, setMnemocode] = useState<string>(props.document?.mnemocode ?? "");

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [kizScanModal, setKizScanModal] = useState<boolean>(false);
    const [creatorItem, setCreatorItem] = useState<ICreatorItem>({
        show: false,
        item: null,
    });
    const [searchOption, setSearchOption] = useState<SearchOption>(SearchOption.goodsName);
    const [scanData, setScanData] = useState<string>("");
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({
        show: false,
        message: "",
    });
    //filter
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterLot, dispatchGridFilterLot] = useGridFilter();

    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const [selectedScalingRatio, setSelectedScalingRatio] = useState<IScalingRatioDTO | null>(null);

    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IInternetOrderItemCreateDTO>();
    const [scanFocus, setScanFocus] = useState<boolean>(false);
    const [scanValue, setScanValue] = useState<string>("");
    const timeout = useRef<NodeJS.Timeout>();
    const [setTimer, clearTimer] = useTimeout()

    const scanner = useComPortScanner((data) => {
        //сообщение от сканнера
        setScanValue(data);
        addKiz(data, true);
    })

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });

    const addKiz = (scanValue: string, portScanner?: boolean) => {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = setTimeout(() => {
            let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInternetOrderItemCreateDTO;
            if (item.quantity > (item.kizs?.length || 0)) {
                addKizOrKizBox(
                    scanValue,
                    kizDP,
                    DocumentType.internetOrder,
                    (value) => {
                        let filteredItems = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInternetOrderItemCreateDTO;
                        let displayItem = displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal) as IDisplayItem;
                        let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                        setData({
                            ...data,
                            items: [
                                ...filteredItems,
                                {
                                    ...item,
                                    kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs
                                },
                            ],
                        });
                        setDisplayItems([
                            ...filteredDisplayItems,
                            {
                                ...displayItem
                            },
                        ]);
                        setSelectedItemCreateDto({
                            ...item,
                            kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs
                        });
                        setScanValue("");
                    },
                    portScanner
                );
            } else {
                setScanValue("");
                renderGlobalAlert({
                    variant: "exception",
                    title: "Превышено максимальное количество",
                    statusCode: 400,
                });
            }
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required")
                }
            },
            deliveryDate: {
                required: {
                    value: data?.deliveryDate?.day ? false : true,
                    message: errorsT("required")
                },
                custom: {
                    isValid: () => {
                        if (!data?.documentDate)
                            return false;

                        var date = data?.documentDate.toFormat('dd.MM.yyyy')

                        if (!data?.deliveryDate)
                            return false;

                        var deliveryDate = data?.deliveryDate.toFormat('dd.MM.yyyy')
                        if (deliveryDate >= date)
                            return true
                        return false
                    },
                    message: '"Дата интернет заказа" не может быть пустой или меньше "Дата"'
                }
            },
            contractor: {
                required: {
                    value: data?.idContractorExternalGlobal ? false : true,
                    message: errorsT("required")
                },
            }
        },
    });

    const addDisplayItems = (items: IInternetOrderItemGetDTO[]) => {
        setDisplayItems([
            ...items.map((x: IInternetOrderItemGetDTO): IDisplayItem => {
                return {
                    idGlobal: x.idGlobal,
                    idLotExternalGlobal: x.lot?.idGlobal ?? undefined,
                    goodsName: x.goods?.displayName ?? undefined,
                    lotName: x.lot?.displayName ?? undefined,
                    scalingRatioDisplayName: x.scalingRatio?.displayName ?? undefined,
                    quantity: x.quantity,
                    price: x.price,
                    discount: x.discount,

                    supplierVat: x.supplierCostInfo.vat,
                    supplierPrice: x.supplierCostInfo.price,
                    supplierVatPrice: x.supplierCostInfo.vatPrice,
                    supplierPriceIncVat: x.supplierCostInfo.priceIncVat,
                    supplierSum: x.supplierCostInfo.sum,
                    supplierVatSum: x.supplierCostInfo.vatSum,
                    supplierSumIncVat: x.supplierCostInfo.sumIncVat,
                    supplierAdprice: x.supplierCostInfo.adprice,

                    retailVat: x.retailCostInfo.vat,
                    retailPrice: x.retailCostInfo.price,
                    retailVatPrice: x.retailCostInfo.vatPrice,
                    retailPriceIncVat: x.retailCostInfo.priceIncVat,
                    retailSum: x.retailCostInfo.sum,
                    retailVatSum: x.retailCostInfo.vatSum,
                    retailSumIncVat: x.retailCostInfo.sumIncVat,
                    retailAdprice: x.retailCostInfo.adprice,

                    producer: x.producerName,
                    isGnvls: x.isGnvls,
                    kizCount: x.kizCount,
                    isKiz: x.isKiz,
                    dateCreated: x.dateCreated
                };
            }),
        ]);
    };

    useEffect(() => dispatchGridFilterLot(onPharmacyChanged(data?.idContractorExternalGlobal)), [data?.idContractorExternalGlobal]);

    useEffect(() => {
        if (props.documentItems) {
            addDisplayItems(props.documentItems);
        }
    }, []);

    useEffect(() => {
        const handleTabClose = (event) => {
            operativeReserve.deleteAll({
                idTable: IdTableVariant.InternetOrder,
                idDocument: data.idGlobal as string,
            });
            lockingDocuments.delete({
                idTable: IdTableVariant.InternetOrder,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const addItemsFilter = () => {
        let arr: IItemFilter[] = [];
        props.document?.items.forEach((x) => {
            if(x?.lot?.idGlobal)
            lotDP.getById(x?.lot?.idGlobal, (e) => {
                arr.push({
                    idItem: x.idGlobal,
                    quantityMax: e.quantityRem + e.quantityRes,
                    quantityMin: 0,
                });
            });
        });
        setItemsFilter(arr);
    };

    useEffect(() => {
        if (props.variant === "edit" && props.documentUpdate && props.documentItems && props.document) {

            lockingDocuments.check(data.idGlobal as string, (e) => {
                if (e)
                    props.cancel();
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.InternetOrder,
                        idDocument: data.idGlobal as string,
                    }, (e) => {
                        setIdLockingDocument(e)
                    });
            })

            setData(props.documentUpdate);
            setItemsValidation(
                props.documentUpdate.items.map((x) => ({
                    err: false,
                    message: "",
                    idGlobal: x.idGlobal,
                }))
            );
            setMnemocode(props.document.mnemocode);
            addDisplayItems(props.documentItems);
            addItemsFilter();
        }
    }, [props.variant]);

    useEffect(() => {
        if (selectedItem) {
            setScanFocus(true);
            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IInternetOrderItemCreateDTO;
            if (selectedItemDto) {
                setSelectedItemCreateDto(selectedItemDto);
                if(selectedItemDto.idLotExternalGlobal)
                    lotDP.getById(selectedItemDto.idLotExternalGlobal, (lot: ILotDTO) => {
                        scalingRatioDP.overrideGetById(lot.goods.idGlobal, lot.scalingRatio.idGlobal as string, (e) => {
                        setSelectedScalingRatio(e);
                        });
                    });
            } else {
                setSelectedItemCreateDto(undefined);
                setSelectedScalingRatio(null);
            }
        } else {
            setScanFocus(false);
            setSelectedItemCreateDto(undefined);
            setSelectedScalingRatio(null);
        }
        setScanValue("");
    }, [selectedItem]);

    const kizDisabled = (): boolean => {
        if (selectedItemCreateDto) {
            if (selectedItemCreateDto.isKiz && !documentStateProc) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const addItem = (value: IChangeDataGrid) => {

        let quantity = (value.value as number)
        let indexItem = data.items.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDataObj = data.items.find((item) => item.idGlobal === value.idGlobal) as IInternetOrderItemCreateDTO;

        let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === value.idGlobal);
        let takeDisplayObj = displayItems.find((item) => item.idGlobal === value.idGlobal) as IDisplayItem;

        let newItem: IInternetOrderItemCreateDTO = {
            ...takeDataObj,
            retailCostInfo: {
                ...takeDataObj.retailCostInfo,
                vat: takeDataObj.retailCostInfo.vat,
                sum: round(takeDataObj.retailCostInfo.price * quantity),
                vatSum: round(takeDataObj.retailCostInfo.vatPrice * quantity),
                sumIncVat: round(takeDataObj.retailCostInfo.priceIncVat * quantity),
            } as ICostInfoDTO,
            supplierCostInfo: {
                ...takeDataObj.supplierCostInfo,
                vat: takeDataObj.supplierCostInfo.vat,
                sum: round(takeDataObj.supplierCostInfo.price * quantity),
                vatSum: round(takeDataObj.supplierCostInfo.vatPrice * quantity),
                sumIncVat: round(takeDataObj.supplierCostInfo.priceIncVat * quantity),
            } as ICostInfoDTO,
            quantity: quantity,
        };

        let newDisplayItem: IDisplayItem = {
            ...takeDisplayObj,
            supplierVat: newItem.supplierCostInfo.vat,
            supplierPrice: newItem.supplierCostInfo.price,
            supplierVatPrice: newItem.supplierCostInfo.vatPrice,
            supplierPriceIncVat: newItem.supplierCostInfo.priceIncVat,
            supplierSum: newItem.supplierCostInfo.sum,
            supplierVatSum: newItem.supplierCostInfo.vatSum,
            supplierSumIncVat: newItem.supplierCostInfo.sumIncVat,
            supplierAdprice: newItem.supplierCostInfo.adprice,

            retailVat: newItem.retailCostInfo.vat,
            retailPrice: newItem.retailCostInfo.price,
            retailVatPrice: newItem.retailCostInfo.vatPrice,
            retailPriceIncVat: newItem.retailCostInfo.priceIncVat,
            retailSum: newItem.retailCostInfo.sum,
            retailVatSum: newItem.retailCostInfo.vatSum,
            retailSumIncVat: newItem.retailCostInfo.sumIncVat,
            retailAdprice: newItem.retailCostInfo.adprice,
            quantity: quantity,
        };

        setData({
            ...data,
            items: addValueToArray(data.items, indexItem, newItem),
        });
        setDisplayItems([...addValueToArray(displayItems, indexDisplayItem, newDisplayItem)]);
    };

    useEffect(() => {
        setSupplierCostInfo({
            sum: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sum ?? 0), 0),
            vatSum: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.vatSum ?? 0), 0),
            sumIncVat: data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sumIncVat ?? 0), 0),
        });
        setRetailCostInfo({
            sum: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sum ?? 0), 0),
            vatSum: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.vatSum ?? 0), 0),
            sumIncVat: data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sumIncVat ?? 0), 0),
        });
    }, [data.items]);

    const isValidDocument = (items: IInternetOrderItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0) {
            flag = false;
            showMessage();
        }
        return flag;
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.InternetOrder,
            idDocument: data.idGlobal as string,
        });

        props.ok(data);
    };

    const onOk = () => {
        if (isValid()) {
            if (isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true
                && kizCounter(data.items, () =>
                    setShowMessageModal({
                        message: errorsT("kizCountNotMatch"),
                        show: true,
                        btSaveOrOk: "ok",
                    })
                ) === true
            ) {
                onOkAction();
                scanner.disconnect();
            }
        }
    };

    const onSave = () => {
        if (isValid()) {
            setErrors({ ...errors, documentDate: undefined, deliveryDate: undefined, contractor: undefined })
            if (isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true
                && kizCounter(data.items, () =>
                    setShowMessageModal({
                        message: errorsT("kizCountNotMatch"),
                        show: true,
                        btSaveOrOk: "save",
                    })
                ) === true
            ) {
                props.save(data);
                scanner.disconnect();
            }
        }
    };

    const onCancel = () => {
        operativeReserve.deleteAll(
            {
                idTable: IdTableVariant.InternetOrder,
                idDocument: data.idGlobal as string,
            },
            () => {
                props.cancel();
                scanner.disconnect();
            }
        );
        lockingDocuments.delete({
            idTable: IdTableVariant.InternetOrder,
            idDocument: data.idGlobal as string,
        });
    };

    const calcRow = (value: IChangeDataGrid) => {
        if (value.propertyName === "quantity") {
            let idLot: string = data.items.find((x) => x.idGlobal === value.idGlobal)?.idLotExternalGlobal as string;
            operativeReserve.send(
                {
                    idLot: idLot,
                    idDocument: data.idGlobal as string,
                    idTable: IdTableVariant.InternetOrder,
                    idDocumentItem: value.idGlobal,
                    quantity: value.value as number,
                },
                () => addItem(value)
            );
        } else {
            addItem(value);
        }
    }

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: onOk,
                    disabled: documentStateProc ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: onSave,
                    disabled: documentStateProc ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                print={{
                    onClick: () => {
                        return IdTableVariant.InternetOrder;
                    },
                    onSubItemClick: () => {
                        let arr = new Array<IKeyValuePair>();
                        if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined) arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                        return arr;
                    },
                }}
                cancel={{ onClick: onCancel }}
            >
                <div className={createInternetOrdersStyles.glwrapper}>
                    <div className={createInternetOrdersStyles.wrapper}>
                        <div>
                            {
                                <>
                                    <>
                                        <GridWrapper cols={35}>
                                            <FlexRow className={createInternetOrdersStyles.compactFieldFields}>
                                                <TextInput
                                                    disabled
                                                    label="Номер"
                                                    value={mnemocode}
                                                />
                                                <DateInput
                                                    required
                                                    disabled={documentStateProc ? true : false}
                                                    labelVariant="required"
                                                    value={data.documentDate}
                                                    onBlur={(value) => setData({ ...data, documentDate: value as DateTime })}
                                                    label="Дата"
                                                    error={errors.documentDate}
                                                />
                                                <DateInput
                                                    required
                                                    disabled={documentStateProc ? true : false}
                                                    labelVariant="required"
                                                    value={data.deliveryDate}
                                                    onBlur={(value) => setData({ ...data, deliveryDate: value as DateTime })}
                                                    label="Дата интернет заказа"
                                                    error={errors.deliveryDate}
                                                />
                                                <TextInput 
                                                    label="Имя" 
                                                    value={data.customerName} 
                                                    onChange={(value) => setData({...data, customerName: value})}
                                                    disabled={documentStateProc ? true : false}
                                                />
                                                <TextInput 
                                                    label="Email" 
                                                    value={data.customerEmail} 
                                                    onChange={(value) => setData({...data, customerEmail: value})}
                                                    disabled={documentStateProc ? true : false}
                                                />
                                            </FlexRow>                                            
                                            <FlexRow className={createInternetOrdersStyles.compactFieldFields} wrap>
                                                <TextInput 
                                                    label="Телефон" 
                                                    value={data.customerPhone} 
                                                    onChange={(value) => setData({...data, customerPhone: value})}
                                                    disabled={documentStateProc ? true : false}
                                                />
                                                <ContractorSelectorInput
                                                    required
                                                    disabled={documentStateProc ? true : false}
                                                    label="Аптека получатель"
                                                    selectedEntity={data.contractor}
                                                    onSelect={(value) => {
                                                        setData({...data, contractor: value, idContractorExternalGlobal: value.idGlobal});
                                                    }}
                                                    onClear={() =>{
                                                        setData({...data, contractor: {} as IEntitySimpleDTO, idContractorExternalGlobal: undefined});
                                                    }}
                                                    error={errors.contractor}
                                                    treeViewCheckDirectoryType="pharmacy"
                                                />
                                                <TextInput
                                                    disabled
                                                    label="Источник заказа"
                                                    value={data.orderSource}
                                                />
                                                <SelectControl
                                                    disabled={documentStateProc ? true : false}
                                                    label="Способ доставки"
                                                    value={{ value: data.deliveryType, displayName: data.deliveryType } as IOption}
                                                    options={[
                                                        {
                                                            value: "",
                                                            displayName: ""
                                                        },
                                                        {
                                                            value: "pickup",
                                                            displayName: "Самовывоз",
                                                        },
                                                        {
                                                            value: "delivery",
                                                            displayName: "Доставка",
                                                        },
                                                        {
                                                            value: "express",
                                                            displayName: "Срочная",
                                                        }
                                                    ]}
                                                    onSelect={(option) => setData({...data, deliveryType: option.value})}
                                                />
                                            </FlexRow>
                                        </GridWrapper>                                        
                                        <Accordion opened={false} caption={"Комментарий"} id={`accordionComments-${uuidv4()}`}>
                                            <GridWrapper cols={1}>
                                                <TextAreaInput 
                                                    label="Комментарий" 
                                                    value={data.comments} 
                                                    onChange={(value) => setData({...data, comments: value})}
                                                    disabled={documentStateProc ? true : false}
                                                />
                                            </GridWrapper>
                                        </Accordion>
                                    </>
                                    <div className={createInternetOrdersStyles.field_flexblock}>
                                        <PositionEditPanel
                                            kiz={{
                                                onClick: () => setKizScanModal(true),
                                                disabled: kizDisabled(),
                                            }}
                                            edit={{
                                                onClick: () => {
                                                    if (selectedItem) {
                                                        const editItem = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IInternetOrderItemCreateDTO;
                                                        if(editItem.idLotExternalGlobal)
                                                        {
                                                            lotDP.getById(editItem.idLotExternalGlobal!, (item: ILotDTO) => {
                                                                setCreatorItem({
                                                                    show: true,
                                                                    item: item,
                                                                    editItem: editItem,
                                                                });
                                                            });
                                                        }
                                                        else
                                                        {
                                                            setIsSelectedLot(true)
                                                        }
                                                    }
                                                },
                                                disabled: selectedItem && !documentStateProc ? false : true,
                                            }}
                                            delete={{
                                                onClick: () => {
                                                    if (selectedItem) {
                                                        let item = data.items.find((x) => x.idGlobal === selectedItem.idGlobal);
                                                        if (item) {
                                                            operativeReserve.delete(
                                                                {
                                                                    idLot: item.idLotExternalGlobal!,
                                                                    idTable: IdTableVariant.InternetOrder,
                                                                    idDocument: data.idGlobal as string,
                                                                    idDocumentItem: item.idGlobal,
                                                                },
                                                                () => {
                                                                    let displayItem = displayItems.find((x) => x.idGlobal === selectedItem.idGlobal);

                                                                    var newItem = { ...item,
                                                                        idLotExternalGlobal: undefined,
                                                                        producerName: undefined,                                                                        
                                                                        isGnvls: false,
                                                                    } as IInternetOrderItemCreateDTO;
                                                                    var newDisplayItem = { ...displayItem,
                                                                        idLotExternalGlobal: undefined,
                                                                        lotName: undefined,
                                                                        producer: undefined,
                                                                        isGnvls: false
                                                                    } as IDisplayItem;

                                                                    setData({...data,items: [...data.items.filter((x) => x.idGlobal !== newItem.idGlobal), newItem]});
                                                                    setDisplayItems([...displayItems.filter((x) => x.idGlobal !== newDisplayItem.idGlobal), {
                                                                        ...newDisplayItem,
                                                                    }]);

                                                                    setSelectedItem(undefined);
                                                                }
                                                            );
                                                        }
                                                    }
                                                },
                                                disabled: selectedItem && data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idLotExternalGlobal && !documentStateProc ? false : true,
                                            }}
                                        />
                                        <GridSelectorInput
                                            disabled={documentStateProc ? true : false}
                                            gridFilter={gridFilterLot}
                                            dispatchGridFilter={dispatchGridFilterLot}
                                            selectorModalJsx={LotsSelectorModal}
                                            className={createInternetOrdersStyles.field_SelectionLot}
                                            id={"inputAccompanyingSelectionGoods"}
                                            inline={true}
                                            label={t("documents.invoice.selectionOfGoods")}
                                            onSelect={(value) => {
                                                lotDP.getById(value.idGlobal, (lot) => {
                                                    const editItem = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInternetOrderItemCreateDTO;
                                                    if (!editItem)
                                                    {
                                                        renderGlobalAlert(
                                                            {
                                                              variant: "danger",
                                                              statusCode: 200,
                                                              title: "Выберите строку",
                                                            }
                                                        )
                                                        return;
                                                    }
                                                    if (searchOption === SearchOption.goodsName ||
                                                        searchOption === SearchOption.goodsCodeBylot) {
                                                        lotDP.remain(value.idGlobal, data.idGlobal as string, (remain) => {
                                                            setCreatorItem({
                                                                show: true,
                                                                item: { ...lot, quantityRem: remain },
                                                                editItem: editItem
                                                            });
                                                        });
                                                    } else if (searchOption === SearchOption.kiz) {
                                                        setCreatorItem({ show: true, item: value, editItem: editItem });
                                                    }
                                                    
                                                    if (!data.idContractorExternalGlobal)
                                                        setData({...data, contractor: lot.contractor, idContractorExternalGlobal: lot.contractor.idGlobal});
                                                });
                                            }}
                                            onClear={() => { }}
                                            searchOption={searchOption}
                                            onChange={(e) => {
                                                setScanData(e);
                                            }}
                                        />
                                        <Select
                                            disabled={documentStateProc ? true : false}
                                            onSelect={(option) => {
                                                setSearchOption(option.value);
                                            }}
                                            className={styles.field_SelectBlock}
                                            options={[
                                                {
                                                    displayName: t("accounting.internetOrder.goodsName"),
                                                    value: SearchOption.goodsName,
                                                },
                                                {
                                                    displayName: t("accounting.internetOrder.goodsCode"),
                                                    value: SearchOption.goodsCodeBylot,
                                                },
                                                {
                                                    displayName: t("accounting.internetOrder.kiz"),
                                                    value: SearchOption.kiz,
                                                },
                                            ]}
                                        />
                                    </div>
                                    <TextInput
                                        disabled={documentStateProc ? true : false}
                                        className={createInternetOrdersStyles.scanField}
                                        autoFocus={scanFocus}
                                        value={scanValue}
                                        onChange={(e) => {
                                            addKiz(e);
                                            setScanValue(e);
                                        }}
                                    />
                                    <div className={styles.gridWrapper}>
                                        <div className={styles.masterGrid}>
                                            <ChangeableGrid
                                                documentStateProc={documentStateProc}
                                                autoSelect
                                                gridId={uuidv4()}
                                                data={displayItems}
                                                filter={gridFilter}
                                                totalCount={totalCount}
                                                plugin={InternetOrderItemsGridPluginSettings}
                                                selectedItem={selectedItem}
                                                itemsFilter={itemsFilter}
                                                isSortByCreatedDate
                                                itemsValidation={itemsValidation}
                                                setItemsValidation={(value) => {
                                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item))
                                                    setItemsValidation(newItemsValidation)
                                                }}
                                                getStyleCell={(cell, cells) => getStyleCell(cell, cells, DocumentType.internetOrder)}
                                                onSelect={(row) => setSelectedItem(row)}
                                                onClick={(row) => setSelectedItem(row)}
                                                onSort={(i) => {
                                                    dispatchGridFilter({
                                                        type: "sort",
                                                        payload: i.propertyName,
                                                    });
                                                }}
                                                onFilterDelete={(i) =>
                                                    dispatchGridFilter({
                                                        type: "deleteColumnFilter",
                                                        payload: i.propertyName,
                                                    })
                                                }
                                                onPageNumberChange={(n) => {
                                                    dispatchGridFilter({
                                                        type: "changePageNumber",
                                                        payload: { pageNumber: n },
                                                    });
                                                }}
                                                onNumberPerPageChange={(n) => {
                                                    dispatchGridFilter({
                                                        type: "changeNumberPerPage",
                                                        payload: { numberPerPage: n },
                                                    });
                                                }}
                                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                                                onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        
                        <Accordion opened={false} caption={``} id={`InternetOrder-${uuidv4()}`} bold>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent label="Сумма без НДС, пост." inline value={supplierCostInfo?.sum} />
                                <MoneyFormatComponent label="Сумма НДС, пост." inline value={supplierCostInfo?.vatSum} />
                                <MoneyFormatComponent label="Сумма с НДС, пост." inline value={supplierCostInfo?.sumIncVat} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent label="Сумма без НДС, розн." inline value={retailCostInfo?.sum} />
                                <MoneyFormatComponent label="Сумма НДС, розн." inline value={retailCostInfo?.vatSum} />
                                <MoneyFormatComponent label="Сумма с НДС, розн." inline value={retailCostInfo?.sumIncVat} />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>

                {kizScanModal && selectedScalingRatio && (
                    <KizScanModal
                        documentType={DocumentType.internetOrder}
                        ok={(value) => {
                            let items = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                            setData({ ...data, items: items ? [...items, {...value, dateCreated: DateTime.now()}] : [value] });
                            setDisplayItems(
                                displayItems.map((x): IDisplayItem => {
                                    return {
                                        ...x,
                                        dateCreated: x.idGlobal === value.idGlobal ? DateTime.now() : x.dateCreated,
                                    };
                                })
                            );
                            setKizScanModal(false);
                        }}
                        cancel={() => {
                            setKizScanModal(false);
                        }}
                        document={{ idTable: IdTableVariant.InternetOrder, idDocument: data.idGlobal as string }}
                        idLotFrom={selectedItemCreateDto?.idLotExternalGlobal ?? null}
                        selectedItem={selectedItemCreateDto as IInternetOrderItemEditDTO}
                        numerator={selectedScalingRatio.numerator}
                        scalingRatio={selectedScalingRatio}
                    />
                )}

                {creatorItem.show && creatorItem.item && (
                    <InternetOrderCreatorItemModal
                        idDocumentGlobal={data.idGlobal as string}
                        idDocumentItemGlobal={creatorItem.editItem?.idGlobal ?? uuidv4()}
                        lot={creatorItem.item}
                        items={data.items}
                        scanData={searchOption === SearchOption.kizOrKizBox ? scanData : undefined}
                        ok={(createItem: ICreateItem) => {
                            let filteredItems: IInternetOrderItemCreateDTO[] = data.items.filter((x) => x.idLotExternalGlobal !== createItem.item.idLotExternalGlobal);
                            let filteredDisplayItems: IDisplayItem[] = displayItems.filter((x) => x.idLotExternalGlobal !== createItem.item.idLotExternalGlobal);
                            setData({ ...data, items: [...filteredItems, createItem.item] });
                            setDisplayItems([...filteredDisplayItems, {...createItem.displayItem}]);
                            setItemsFilter([...itemsFilter, createItem.filter]);
                            setItemsValidation([...itemsValidation, { idGlobal: createItem.item.idGlobal, message: "", err: false }]);
                            setCreatorItem({ show: false, item: null });
                            setBaseFields({
                                ...baseFields,
                                contractor: creatorItem.item?.contractor as IEntitySimpleDTO,
                                contractorOwner: creatorItem.item?.contractor as IEntitySimpleDTO });
                        }}
                        cancel={() => setCreatorItem({ show: false, item: null })}
                    />
                )}

                {creatorItem.show && creatorItem.item && creatorItem?.editItem && (
                    <InternetOrderUpdateItemModal
                        lot={creatorItem.item}
                        idDocumentGlobal={data.idGlobal as string}
                        idDocumentItemGlobal={creatorItem.editItem?.idGlobal}
                        internetOrderItemEdit={creatorItem.editItem}
                        ok={(createItem: ICreateItem) => {
                            setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== createItem.item.idGlobal), createItem.item] });
                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== createItem.displayItem.idGlobal), {
                                ...createItem.displayItem,
                            }]);
                            setCreatorItem({ show: false, item: null });
                            setBaseFields({
                                ...baseFields,
                                contractor: creatorItem.item?.contractor as IEntitySimpleDTO, 
                                contractorOwner: creatorItem.item?.contractor as IEntitySimpleDTO });
                        }}
                        cancel={() => setCreatorItem({ show: false, item: null, editItem: null })}
                    />
                )}

                {showMessageModal.show && (
                    <MessageModalWindow
                        message={showMessageModal.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                            },
                        }}
                        cancel={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                            },
                        }}
                        primary
                    />
                )}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {isSelectedLot && (                    
                    <LotsSelectorModal
                        gridFilter={gridFilterLot}
                        dispatchGridFilter={dispatchGridFilterLot}
                        ok={(entity, e) => {
                            const editItem = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInternetOrderItemCreateDTO;
                            lotDP.getById(entity.idGlobal, (item: ILotDTO) => {
                                setCreatorItem({
                                    show: true,
                                    item: item,
                                    editItem: editItem,
                                });

                                 if (!data.idContractorExternalGlobal)
                                    setData({...data, contractor: item.contractor, idContractorExternalGlobal: item.contractor.idGlobal});
                            });
                            setIsSelectedLot(false)
                        }}
                        cancel={() => {
                            setIsSelectedLot(false)
                        }}
                    />
                )}

            </BaseDocumentCreator>
        </>
    );
};