import { IStoreDTO, IStoreViewDTO } from "../../libs/coreapi-dto/dirs/store";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class StoreDataProvider extends DictionaryDataProvider<IStoreViewDTO, IStoreDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IStoreViewDTO, IStoreDTO>> {
        return this._coreApiService.storeRequest
    }
}