import { v4 as uuidv4 } from "uuid";
import styles from "./styles/index.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { IDocumentCreatorFormProps } from "../../../@types/documents";
import BaseDocumentCreator, { IPrintButton } from "../../../components/documents/baseDocumentCreator";

import { IButton } from "../../../components/modalWindows/BaseModalWindow";
import { CheckBox } from "../../../components/controls/checkbox";
import { LotSelectorPanel } from "../../../components/lotSelector";
import FlexRow from "../../../components/controls/FlexRow";
import { ChangeableGrid, IChangeDataGrid } from "../../../components/grids/changeableGrid/ChangeableGrid";

import { InventoryVedItemCreatePluginSettings } from ".";

import GoodsSelectorInput from "../../../components/controls/inputs/GoodsSelectorInput";
import { ICostInfoDTO, IEntitySimpleDTO, ISummaryCostInfoDTO } from "../../../libs/coreapi-dto/@types/common";
import { InventoryVedItemFormDataType } from "./inventoryVedItemFormTab";
import { Accordion } from "../../../components/controls/accordion";
import { GridWrapper } from "../../../components/controls/GridWrapper";
import { TextInput, DateInput, TextAreaInput } from "../../../components/controls/inputs";
import { DictionaryInput } from "../../../components/controls/dictionaryInput";
import { ISelectorInputProps, StoreSelectorPanel } from "../../../components/storeSelectorPanel";

import useInventoryVedValidator from "./inventoryVedValidator";
import useGridFilter from "../../../system/hooks/useGridFilter";

import { useTimeout } from "../../../system/hooks/useTimeout";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { DateTime } from "luxon";
import PositionEditPanel, { IPanelEvent } from "../../../components/commandsPanels/PositionEditPanel";
import { IAppContext, useAppContext } from "../../../system/providers/appContextProvider";

import renderGlobalAlert from "../../../system/hooks/useGlobalAlert";
import KizScanModal from "../../Dictionaries/Kiz/ScanModal/KizScanModal";

import { sumKizsCount } from "../../../system/functions/sumKiszCount";
import { DocumentStateType, DocumentType, IdTableVariant } from "../../../@types/enumsGlobal";
import { validateDuplicateKizs } from "../../../system/functions/validateDuplicateKizs";
import { MoneyFormatComponent } from "../../../components/controls/inputs/BaseInput";
import { IKeyValuePair } from "../../Reports/Shared/Interfaces/IKeyValuePair";
import { checkLockStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DeleteModalWindow } from "../../../components/modalWindows/DeleteModalWindow";

import { onPharmacyChanged } from "../../../system/functions/onPharmacyChanged";
import { onStoreChanged } from "../../../system/functions/onStoreChanged";
import { checkStorePharmacy } from "../../../system/functions/checkStorePharmacy";

import { IInventoryVedEditDTO, IInventoryVedFillWithRemainedLotsDto, IInventoryVedGetDTO } from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVed";
import { 
    IInventoryVedItemEditDTO, 
    IInventoryVedItemSaveStateDTO, 
    IInventoryVedItemViewDTO, 
    IInventoryVedSumReportDto 
} from "../../../libs/coreapi-dto/documents/invoice/inventory/inventoryVedItem";
import { IScalingRatioDTO } from "../../../libs/coreapi-dto/dirs/scalingRatio";
import { IKizBoxDTO, IKizDTO } from "../../../libs/coreapi-dto/dirs/kiz";

import { InventoryVedDataProvider } from "../../../Services/DataProviders/InventoryVedDataProvider";
import { ScalingRatioDataProvider } from "../../../Services/DataProviders/ScalingRatioDataProvider";
import { StoreDataProvider } from "../../../Services/DataProviders/StoreDataProvider";
import { Kiz2InventoryVedDataProvider } from "../../../Services/DataProviders/Kiz2InventoryVedDataProvider";
import { ConfirmationItemsModal } from "../../../components/modalWindows/ConfirmationItemsModal";
import { ProgressActionSpinner } from "../../../components/progressActionSpinner/ProgressActionSpinner";
import { useTranslation } from "react-i18next";
import { KizDataProvider } from "../../../Services/DataProviders/KizDataProvider";

const InventoryVedForm: FC<IDocumentCreatorFormProps<IInventoryVedGetDTO, IInventoryVedEditDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    const [entity, setEntity] = useState<IInventoryVedEditDTO>({
        idGlobal: props.idGlobal as string,
        requiredScanKiz: props.document?.requiredScanKiz ?? false,
        documentDate: props.document?.documentDate ?? DateTime.now(),
        comment: props.document?.comment ?? "",
        idStoreGlobal: null,
        items: [], // Используется в документе как буфер строк
        store: props.document?.store,
        updateHeaderOnly: false,
        bulkClearKiz: false
    });

    const [storeFromLot, setStoreFromLot] = useState<IEntitySimpleDTO>();
    const [pharmacy, setPharmacy] = useState<IEntitySimpleDTO>();

    const validator = useInventoryVedValidator(entity);

    const storeDP = new StoreDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const inventoryVedDP = new InventoryVedDataProvider(appContext.coreApiService);
    const kiz2InventoryVedDP = new Kiz2InventoryVedDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);

    const [onlyLot, setOnlyLot] = useState<boolean>(true);
    const [onlyLotRemain, setOnlyLotRemain] = useState<boolean>(true);
    const [onlyKiz, setOnlyKiz] = useState<boolean>(false);
    const [showKizModal, setShowKizModal] = useState<boolean>(false);
    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO | null>(null);

    // Установить значение по IdGlobal для выделения строки
    const [selectById, setSelectById] = useState<string>()

    // Строки отображения в таблице
    const [viewItems, setViewItems] = useState<IInventoryVedItemViewDTO[]>([]);

    // Статус запроса обработки строк документа
    const [sendRequestDocumentItems, setSendRequestDocumentItems] = useState<boolean>(false);

    // Показывать верстку
    const [showForm, setShowForm] = useState<boolean>(false);

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.inventoryVed." + value);

    // Применение изменений
    const applyChanges = (btType = "ok") => {

        if (validator.isValid()) {

            const saveRequest = () => {

                // Установить для "пачки" строк статус save (save_state)
                if (props.variant === 'edit') {
                    inventoryVedDP.batchSaveState(props.idGlobal as string, { saveState: 'save' }, (result) => {

                        if (btType === "ok") {
                            props.ok({ ...entity, updateHeaderOnly: true });
                        } else {
                            props.save({ ...entity, updateHeaderOnly: true });
                        }
                    });
                }
            };

            // Если режим обязательного сканирования
            // валидация маркированных партий ведомости
            if (entity.requiredScanKiz) {

                const confirm = () => {
                    kiz2InventoryVedDP.confirmQuantityFromScannedKiz({ idDocument: props.idGlobal as string }, (result) => {

                        if (result) {
                            setModalWindow(<></>);
                            saveRequest();
                        } else {
                            renderGlobalAlert({ variant: "error", statusCode: 200, title: baseT("messages.quantityFactChangeError") });
                        }
                    })
                };

                allowDocumentItems(props.idGlobal as string, confirm).then((result) => {
                    if (result) {
                        saveRequest();
                    }
                });
            } else {
                saveRequest();
            }
        }
    };

    // Откат изменений
    const rollbackChanges = (action: () => void) => {

        // Удаляем из БД "пачку" строк со статусом autosave (save_state)
        if (props.variant === 'edit') {
            inventoryVedDP.deleteAutoSave(props.idGlobal as string, (result) => {
                action();
            });
        } else {
            action();
        }
    }

    const okHandler: IButton = { 
        onClick: () => applyChanges(),
        disabled: lockFromPermission,
        sendRequestGlobal: props.isSubmitting
    };

    const saveHandler: IButton = { 
        onClick: () => applyChanges("save"), 
        disabled: lockFromPermission,
        sendRequestGlobal: props.isSubmitting
    };

    const cancelHandler: IButton = { 
        onClick: () => rollbackChanges(() => props.cancel())
    };

    const printHandler: IPrintButton = {
        onClick: () => { return IdTableVariant.InventoryVed; },
        onSubItemClick: () => {
            let arr = new Array<IKeyValuePair>();
            if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined) arr.push(
                { 
                    key: props.idGlobal, 
                    value: props.document.mnemocode 
                });
            return arr;
        },
    };

    const otherActions: IButton[] = [
        {
            title: baseT("getRemnants"),//"Получить остатки"
            disabled: entity.idStoreGlobal === null || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
            onClick: async () => fillWithRemainedLots(false)
        },
        {
            title: baseT("getKizMarkedRemnants"),//"Получить остатки, только маркированный товар"
            disabled: entity.idStoreGlobal === null || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
            onClick: async () => fillWithRemainedLots(true)
        },
        {
            title: baseT("setAllQuantityRem"),//"Заполнить факт. кол-во документальным кол-вом"
            disabled: viewItems.length === 0 || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
            onClick: () => setAllQuantityRem(),
        },
        { 
            title: baseT("deleteIncorrect")/*"Удалить ошибочные"*/, 
            disabled: false || lockFromPermission, 
            onClick: () => { }
        },
    ];

    const changeDataGridHandler = (cell: IChangeDataGrid) => {

        if (props.variant !== 'edit')
            return;

        if (cell.propertyName === "quantity") {

            // TODO: перенести в универсальный интерфейс DTO для патчинга
            // Опции патчинга передаются в массиве
            const patchDocument = {
                path: `/${cell.propertyName}`,
                op: 'replace',
                value: cell.value as string
            };

            inventoryVedDP.patchItem(props.idGlobal as string, cell.idGlobal, [ patchDocument ], (result) => {
                if (result) {
                    refreshItemsGrid();
                } else {
                    renderGlobalAlert({ variant: "error", statusCode: 200, title: baseT("messages.saveItemError")/*"Ошибка сохранения строки ведомости"*/ });
                }
            });
        }
    };

    //#region нетривиальные переменные

    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]); // TODO: ?
    const [itemGridFilter, dispatchItemGridFilter] = useGridFilter();
    const [lotGridFilter, dispatchLotGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IInventoryVedItemViewDTO>();

    // Общее число записей в таблице (строки ведомости)
    const [totalCount, setTotalCount] = useState(0);

    const [supplierCostInfo, setSupplierCostInfo] = useState<ISummaryCostInfoDTO>({} as ICostInfoDTO);
    const [retailCostInfo, setRetailCostInfo] = useState<ISummaryCostInfoDTO>({} as ICostInfoDTO);

    // Параметры для окна сканирования киз
    const [selectedItemEntity, setSelectedItemEntity] = useState<IInventoryVedItemEditDTO>();
    const [scalingRatio, setScalingRatio] = useState<IScalingRatioDTO>();

    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    //#endregion // нетривиальные переменные

    const blockedDocumentsMessage = "readyMessages.blockedDocuments";
    const reservedLotsDocumentsMessage = "readyMessages.reservedLotsDocuments";
    const reservedOpLotsDocumentsMessage = "readyMessages.reservedOpLotsDocuments";
    const openedCashSessionsMessage = "readyMessages.openedCashSessions";
    const savedInvoicesMessage = "readyMessages.savedInvoices";

    useEffect(() => {
        const init = () => {
            if (tabsContext?.currentTab) {
                currentIdTab.current = tabsContext.currentTab?.id;
            }
        };

        // Проверка готовности аптеки
        if (props.variant === "create") {
            renderProgressView(baseT("messages.drugstoreReadyProcessName"));
            inventoryVedDP.createCheck((result) => {
                setModalWindow(<></>);

                if (!result?.isAllowed) {

                    const detailItemsText = result?.items.join('\r\n')
                        .replace(savedInvoicesMessage, baseT(savedInvoicesMessage))
                        .replace(blockedDocumentsMessage, baseT(blockedDocumentsMessage))
                        .replace(reservedLotsDocumentsMessage, baseT(reservedLotsDocumentsMessage))
                        .replace(reservedOpLotsDocumentsMessage, baseT(reservedOpLotsDocumentsMessage))
                        .replace(openedCashSessionsMessage, baseT(openedCashSessionsMessage));

                    renderConfirmationModal(baseT(result?.title), baseT(result?.details),
                        detailItemsText, baseT(result?.question),
                        () => {
                            setModalWindow(<></>);
                            setShowForm(true);
                            init();
                        },
                        () => props.cancel());
                } else {
                    setShowForm(true);
                    init();
                }
            }, () => {
                props.cancel();
                setModalWindow(<></>);
                renderGlobalAlert({
                    variant: "error",
                    title: baseT("messages.drugstoreReadyCheckError"),//"Ошибка проверки готовности аптеки"
                    detail: baseT("messages.drugstoreReadyDetailsError"),//"Ошибка на стороне сервера"
                    instance: baseT("messages.drugstoreReadyProcessName"),
                    statusCode: 500,
                });
            });
        } else {
            setShowForm(true);
            init();
        }
    }, []);

    useEffect(() => {
        if (entity.documentDate) {
            dispatchLotGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "dateDocument",
                    value: entity.documentDate.toFormat("yyyyMMdd"),
                    operator: "LessOrEq"
                }
            });
        } else {
            dispatchLotGridFilter({ type: "deleteColumnFilter", payload: "dateDocument" });
        }
    }, [entity.documentDate]);

    useEffect(() => {
        if (selectedItemEntity) {
            scalingRatioDP.overrideGetById(selectedItemEntity.idGoodsGlobal, selectedItemEntity.idScalingRatioGlobal, (value) => {
                setScalingRatio(value);
            });
        }
    }, [selectedItemEntity]);

    useEffect(() => {
        refreshSumReport();
    }, [totalCount]);

    const refreshSumReport = () => {
        inventoryVedDP.getInventoryVedSumReport(props.idGlobal as string, (result) => {

            const report = result as IInventoryVedSumReportDto;

            setSupplierCostInfo({
                sum: report.sumSupplier,
                vatSum: report.sumVatSupplier,
                sumIncVat: report.sumIncVatSupplier,
            });
            setRetailCostInfo({
                sum: report.sumRetail,
                vatSum: report.sumVatRetail,
                sumIncVat: report.sumIncVatRetail,
            });
        });
    };

    const clearItemsBuffer = (targetEntity: IInventoryVedEditDTO) => {
        const len = targetEntity?.items?.length;
        targetEntity?.items?.splice(0, len);
    };

    const openCreateItemFormTab = (data: InventoryVedItemFormDataType) => {
        if (!data.pricing) {
            renderGlobalAlert({ variant: "error", statusCode: 404, title: baseT("messages.pricingModelForStoreError") });
        }

        tabsContext.openChild(
            "inventory_ved_item_create_plugin",
            "create",
            uuidv4(),
            (editItem, viewItem) => {
                if (data.type === 'lot')
                    setStoreFromLot(data.lot.store);

                const providePutRequest = (idDocumentGlobal: string = "") => {

                    const targetIdDocumentGlobal = (idDocumentGlobal.length > 0 ? idDocumentGlobal : props.idGlobal) as string;

                    // Функция обновления таблицы и состояния окна
                    // Если ведомость в состоянии создания и успешно сохранена
                    // Переход в режим редактирования
                    const refreshResult = () => {
                        if (props.variant === 'create')
                            props.redirect?.(targetIdDocumentGlobal, "edit");
                        else {
                            refreshItemsGrid();
                            refreshSumReport();
                        }
                    };

                    const putItemRequest = () => {
                        inventoryVedDP.putItem(targetIdDocumentGlobal, editItem, (result) => {
                            if (result) {
                                
                                // Вода / KizOsu
                                if (editItem.kizOsuDto)
                                    kizDP.kizOsuAsync(editItem.idGlobal, editItem.kizOsuDto);

                                // Формируем буфер КИЗ если добавляли через сканирование в LotSelector
                                // bulkClear флаг регулируется через callback подтверждения KizScanModal
                                if (editItem.isKiz) {
                                    let targetItem = editItem as IInventoryVedItemEditDTO;
    
                                    const kizType = data.searchQuery?.type;
                                    switch (kizType) {
                                        case "kiz": {
                                            targetItem.kizs?.push(data.searchQuery?.data as IKizDTO);
                                            break;
                                        }
                                        case "kizBox": {
                                            targetItem.kizBoxes?.push(data.searchQuery?.data as IKizBoxDTO);
                                            break;
                                        }
                                        default: {
                                            break;
                                        }
                                    }
    
                                    // Добавляем строку с КИЗ в буфер
                                    entity.items?.push(targetItem);

                                    // Сохраняем ведомость
                                    // Если сканировали КИЗ
                                    if (validator.isValid() && kizType) {
                                        inventoryVedDP.update(entity.idGlobal as string, entity, (e) => {
    
                                            // Очистка буфера в случае успешного сохранения
                                            // Предполагается очистка буфера в случаше ошибки
                                            // На данный момент callback в update должен отработать
                                            // TODO: учитывать failedCallback
                                            clearItemsBuffer(entity);
                                            refreshResult();
                                        });
                                    } else {

                                        // Обновление страницы с результатом
                                        // если добавляли без сканирования КИЗ
                                        refreshResult();
                                    }
                                } else {

                                    // Немаркированный товар
                                    refreshResult();
                                }
                            } else {
                                renderGlobalAlert({ variant: "error", statusCode: 500, title: baseT("messages.saveItemError") });
                            }
                        });
                    };

                    // Валидация КИЗ
                    if (editItem.isKiz && data.searchQuery?.data.barcode) {
                        allowKiz(targetIdDocumentGlobal, data.searchQuery?.data.barcode as string).then((result) => {
                            if (result) {
                                putItemRequest();
                            }
                        });
                    } else {
                        putItemRequest();
                    }
                };

                // Создаем ведомость
                // затем прикрепляем к ней позицию
                if (props.variant === 'create') {

                    const targetEntity = !entity.idStoreGlobal && data.type === 'lot' ?
                        { ...entity, idStoreGlobal: data.lot.store.idGlobal } :
                        entity;

                    inventoryVedDP.create(targetEntity, (id) => {
                        providePutRequest(id);
                    });
                }

                // В режиме редактирования - update
                if (props.variant === 'edit') {
                    providePutRequest();
                }
            },
            data
        );
    };

    const contractorButton: ISelectorInputProps = {
        label: baseT("pharmacy"), //"Аптека"
        className: styles.width300px,
        disabled: viewItems.length > 0 || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onSelect: (value) => {
            setPharmacy(value);
            dispatchLotGridFilter(onPharmacyChanged(value.idGlobal));
        },
        onClear: () => {
            setPharmacy(undefined);
            dispatchLotGridFilter(onPharmacyChanged(undefined));
        },
        treeViewCheckDirectoryType:'pharmacy'
    };

    const storeButton: ISelectorInputProps = {
        label: baseT("store"),//"Склад"
        value: entity.store ?? null,
        className: styles.width300px,
        disabled: viewItems.length > 0 || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onSelect: (value) => {
            storeDP.getById(value.idGlobal, (e) => {
                if (e.pricingPricingModel) {
                    setPricingModel({ ...e.pricingPricingModel });
                } else {
                    renderGlobalAlert({ variant: "error", statusCode: 404, title: baseT("messages.pricingModelForStoreError") });
                }
            });
            setEntity({ ...entity, idStoreGlobal: value.idGlobal, store: value });
            dispatchLotGridFilter(onStoreChanged(value.idGlobal));
        },
        onClear: () => { 
            setEntity({ ...entity, idStoreGlobal: null, store: undefined });
            dispatchLotGridFilter(onStoreChanged());
        },
        error: validator.errors.idStoreGlobal,
        onFocus: () => validator.setErrors({ ...validator.errors, idStoreGlobal: undefined })
    };

    // Обновление таблицы с пагинацией для inventory_ved_items
    useEffect(() => {
        refreshItemsGrid();
    }, [itemGridFilter])

    const showKizModalButton: IPanelEvent = {
        disabled: !(selectedItem?.isKiz || selectedItem?.isOsu) || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onClick: () => {

            // Подгрузка GetDTO для редактируемого элемента
            inventoryVedDP.getItemById(props.idGlobal as string, selectedItem?.idGlobal as string, (itemEntity) => {
                    
                let itemEditDto = {
                    ...itemEntity,
                    idLotGlobal: itemEntity.lot?.idGlobal,
                    idGoodsGlobal: itemEntity.goods?.idGlobal,
                    idScalingRatioGlobal: itemEntity.scalingRatio?.idGlobal,
                    idGosContractGoodsGlobal: itemEntity.gosContractGoods?.idGlobal,
                    idSeriesGlobal: itemEntity.series?.idGlobal,
                    idRegCertGlobal: itemEntity.regCert?.idGlobal,
                    idSupplierGlobal: itemEntity.supplier?.idGlobal,
                    kizs: [],
                    kizBoxes: []
                } as IInventoryVedItemEditDTO

                // Обновить состояния
                setSelectedItemEntity(itemEditDto);
                setTimeout(() => setShowKizModal(true), 100);
            });
        }
    };

    const editItemButton: IPanelEvent = {
        disabled: selectedItem === undefined || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onClick() {
            if (selectedItem) {

                // Подгрузка GetDTO для редактируемого элемента
                inventoryVedDP.getItemById(props.idGlobal as string, selectedItem.idGlobal, (itemEntity) => {
                    
                    const itemEditDto = {
                        ...itemEntity,
                        idLotGlobal: itemEntity.lot?.idGlobal,
                        idGoodsGlobal: itemEntity.goods?.idGlobal,
                        idScalingRatioGlobal: itemEntity.scalingRatio?.idGlobal,
                        idGosContractGoodsGlobal: itemEntity.gosContractGoods?.idGlobal,
                        idSeriesGlobal: itemEntity.series?.idGlobal,
                        idRegCertGlobal: itemEntity.regCert?.idGlobal,
                        idSupplierGlobal: itemEntity.supplier?.idGlobal
                    } as IInventoryVedItemEditDTO

                    tabsContext.openChild(
                        "inventory_ved_item_create_plugin",
                        "edit",
                        selectedItem?.idGlobal,
                        (editItem, viewItem) => {

                            inventoryVedDP.putItem(props.idGlobal as string, editItem, (result) => {
                                if (result) {

                                    refreshItemsGrid();
                                } else {

                                    renderGlobalAlert({ variant: "error", statusCode: 500, title: baseT("messages.saveError")/*"Ошибка сохранения ведомости"*/ });
                                }
                            });
                        },
                        {
                            type: "entity",
                            entity: itemEditDto,
                            pricing: pricingModel,
                        }
                    );
                });
            }
        },
    };

    const deleteItemButton: IPanelEvent = {
        disabled: selectedItem === undefined || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onClick() {
            if (selectedItem) {
                const deleteItemFunc = () => {
                    inventoryVedDP.markItemDelete(props.idGlobal as string, selectedItem.idGlobal, (result) => {

                        if (result) {

                            // Обновить таблицу строк ведомостей
                            refreshItemsGrid();
                        }
                    });
                };

                const modalTitle = `${selectedItem.displayName} ${baseT("messages.quantityAbbreviation")} ` +
                    `${selectedItem.quantity} ${baseT("messages.quantityRemnantAbbreviation")} ${selectedItem.quantityRem}`;
                renderDeleteModalWindow(modalTitle, null, null, deleteItemFunc);
            }
        },
    };

    const clearItemButton: IPanelEvent = {
        disabled: totalCount === 0 || props.document?.documentState === DocumentStateType.proc || lockFromPermission,
        onClick() {
            const provideDeleteAllRequest = () => {
                inventoryVedDP.deleteAll(props.idGlobal as string, (result) => {
                    if (result) {
                        refreshItemsGrid();
                    }
                    else
                        renderGlobalAlert({ variant: "error", statusCode: 500, title: baseT("messages.clearError") });
                });
            }

            const customElement = <><br/><span>{baseT("messages.clearAlert")}<br/><b>{t("general.confirm")}</b></span></>;
            renderDeleteModalWindow(baseT("messages.clearProcessName"), "", customElement, provideDeleteAllRequest);
        },
    };

    const renderDeleteModalWindow = (entityTitle: string, customText: string | null, customElement: JSX.Element | string | null = null, deleteEntityFunc: any) => {
        setModalWindow(
            <DeleteModalWindow
                name={`${entityTitle} `}
                customText={customText}
                element={customElement as JSX.Element ?? <><br/><span><b>{entityTitle}</b>&nbsp;?</span></>}
                hideDefaultQuestionMark={true}
                cancel={{
                    onClick: () => {
                        setModalWindow(<></>); // TODO: viewState?
                    },
                }}
                delete={{
                    onClick: () => {
                        deleteEntityFunc();
                        setModalWindow(<></>);
                    },
                    title: t("general.delete") //"Удалить"
                }}
            />
        );
    };

    const renderProgressView = (actionMessage: string) => {
        setModalWindow(
            <ProgressActionSpinner
                loaderText={`${actionMessage}`}
            />
        );
    };

    const renderConfirmationModal = (header: string, warning: string, items: string, question: string, ok: () => void, cancel: () => void) => {
        setModalWindow(
            <ConfirmationItemsModal
                header={`${header}`}
                warning={ `${warning}` }
                listPositions={`${items}`}
                question={`${question}`}
                cancel={cancel}
                ok={ok}
                largeTextArea
            />
        );
    };

    // Выгрузка строк ведомости в таблицу с пагинацией
    const refreshItemsGrid = (scrollDirection: string = "", scrollCallback?: (verticalPivot: string, part: number) => void) => {
        
        if (props.idGlobal && props.variant === 'edit') {

            setSendRequestDocumentItems(true);

            let dataFilter = itemGridFilter;
            if (scrollDirection === "up" && dataFilter.pageNumber > 1)
                dataFilter.pageNumber--;
            if (scrollDirection === "down" && dataFilter.pageNumber === 2 && viewItems.length >= itemGridFilter.numberPerPage * 2)
                dataFilter.pageNumber++;

            inventoryVedDP.getItemsView(props.idGlobal, dataFilter, (items, totalCount) => {

                if (scrollDirection === "")
                    setViewItems(items);

                if (scrollDirection === "up") {
                    scrollCallback?.('height', 3);
                    if (viewItems.length >= itemGridFilter.numberPerPage * 2) {
                        scrollCallback?.('top', 2);
                        setViewItems([...items.map((x) => ({ ...x, idRow: uuidv4() })), ...viewItems.slice(0, itemGridFilter.numberPerPage)]);
                    } else {
                        setViewItems([...items.map((x) => ({ ...x, idRow: uuidv4() })), ...viewItems.slice(0, itemGridFilter.numberPerPage)]);
                    }
                }

                if (scrollDirection === "down") {
                    if (itemGridFilter.pageNumber <= Math.ceil(totalCount / itemGridFilter.numberPerPage)) {
                        if (viewItems.length >= itemGridFilter.numberPerPage * 2) {
                            scrollCallback?.('top', 2);
                            setViewItems([...viewItems.slice(itemGridFilter.numberPerPage), ...items.map((x) => ({ ...x, idRow: uuidv4() }))]);
                        } else {
                            setViewItems([...viewItems, ...items.map((x) => ({ ...x, idRow: uuidv4() }))]);
                        }
                    }
                }

                setTotalCount(totalCount);
                setSendRequestDocumentItems(false);
            });
        }
    }

    // Заполнить ведомость остатками
    // onlyKiz - заполнение только маркированным товаром
    const fillWithRemainedLots = (onlyKiz: boolean | false) => {

        const provideFillRequest = (id?: string) => {

            id = id ?? props.idGlobal as string

            if (entity.idStoreGlobal === null) {
                renderGlobalAlert({ variant: "error", statusCode: 404, title: baseT("messages.noStore") });
                return;
            }
    
            if (viewItems.length > 0) {
                renderGlobalAlert({ variant: "error", statusCode: 500, title: t("messages.remnantsLoadError") });
                return;
            }
    
            let lotHallmark: IInventoryVedFillWithRemainedLotsDto = {
                docDate: entity.documentDate,
                fillOnlyKiz: onlyKiz
            }
    
            inventoryVedDP.fillWithRemainedLots(id, entity.idStoreGlobal, lotHallmark, (result) => {
                if (result) {
                    if (props.variant === 'edit') {
                        refreshItemsGrid();
                    } else {
                        props.redirect?.(id as string, "edit");
                    }
                } else {
                    if (props.variant === "create") {
                        props.redirect?.(id as string, "edit");
                    }
                }
            });
        }

        // Если не задан мнемокод, сохраняем ведомость
        if (props.variant === 'create' || !props.document?.mnemocode || props.document?.mnemocode === '') {

            inventoryVedDP.create(entity, (id) => {
                provideFillRequest(id);
            }, 
            ()=> { }, 
            (e)=>{
                renderGlobalAlert({ variant: "error", statusCode: 0, title: baseT("messages.createError") });
            });
        } else {
            provideFillRequest();
        }
    }

    const setAllQuantityRem = () => {
        if (props.variant === 'edit') {
            inventoryVedDP.fillQuantityWithDocumentary(props.idGlobal as string, (result) => {
                if (result) {
                    refreshItemsGrid();
                } else {
                    console.log(baseT("messages.updateItemsError"));
                }
            });
        }
    };

    const allowKiz = (idDocument: string, barcode: string) => {
        return new Promise<boolean>((resolve, reject) => {

            kiz2InventoryVedDP.allowMarkings({ idDocument, barcode }, (result) => {

                if (!result?.isAllowed && result?.items?.length > 0) {
                    renderGlobalAlert({
                        variant: "warning",
                        title: result?.title,
                        detail: result?.details,
                        instance: baseT("messages.kizCodeAvailableProcessName"),
                        statusCode: 200,
                    });
                }

                resolve(result?.isAllowed);
            }, () => {
                if (props.variant === "create") {
                    props.redirect?.(idDocument as string, "edit");
                }
                resolve(false);
            })
        });
    };

    const allowDocumentItems = (idDocument: string, confirmation: () => void) => {
        return new Promise<boolean>((resolve, reject) => {

            kiz2InventoryVedDP.allowDocumentItems({ idDocument }, (result) => {

                if (!result?.isAllowed && result?.items?.length > 0) {
                    renderConfirmationModal(baseT("messages.validationProcessName"),
                        result?.title, result?.items.join('\r\n'), result?.question, confirmation,
                        () => setModalWindow(<></>));
                }

                resolve(result?.isAllowed);
            }, () => {
                if (props.variant === "create") {
                    props.redirect?.(idDocument as string, "edit");
                }
                resolve(false);
            })
        });
    };

    useEffect(() => {
        if (onlyLot && !onlyKiz) {
            dispatchLotGridFilter({ type: "deleteColumnFilter", payload: "isKiz" });
            
        } else if (onlyLot && onlyKiz) {
            dispatchLotGridFilter({
                type: "addColumnFilter",
                payload: {
                    name: "isKiz",
                    operator: "Eq",
                    value: true,
                }
            });
        }
    }, [onlyKiz]);

    return (
        <> { showForm && (
            <BaseDocumentCreator ok={okHandler} save={saveHandler} otherMenuActions={otherActions} cancel={cancelHandler} print={printHandler}>
                <div className={styles.creatorWrapper}>
                    <div>
                        <FlexRow>
                            <TextInput label={baseT("number")} className={styles.width300px} value={props.document?.mnemocode} disabled />
                            <DateInput
                                className={styles.width300px}
                                required
                                label={baseT("date")}
                                value={entity.documentDate}
                                onBlur={(value) => value && setEntity({ ...entity, documentDate: value })}
                                error={validator.errors.idStoreGlobal}
                                disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            />
                            <DictionaryInput className={styles.width300px} label={baseT("user")} disabled value={props.document?.user.usename ?? ""} disabledInput />
                            <CheckBox
                                id="requiredScanKiz"
                                label={baseT("requiredScanKiz")}
                                defaultChecked={entity.requiredScanKiz}
                                onChanged={() => setEntity({ ...entity, requiredScanKiz: entity.requiredScanKiz !== true })}
                                disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            />
                        </FlexRow>
                        <FlexRow>
                            <StoreSelectorPanel 
                                we={props.document?.store ? false : true}
                                storeFromLot={storeFromLot}
                                compactMode={true}
                                contractor={contractorButton}
                                store={storeButton}
                                notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                documentStore={entity.store ?? undefined}
                            />
                            <DictionaryInput label={baseT("storePlace")} className={styles.width300px} disabled />
                        </FlexRow>
                        <Accordion opened={false} caption={baseT("comment")} id={`accordionAdditionally-${uuidv4()}`}>
                            <FlexRow>
                                <TextAreaInput
                                    label={baseT("comment")}
                                    value={entity.comment}
                                    onEndChange={(value) => setEntity({ ...entity, comment: value })}
                                    className={styles.width100pc}
                                    disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                                />
                            </FlexRow>
                        </Accordion>
                        <FlexRow className={styles.lotsFilterPanel}>
                            <CheckBox
                                id="onlyLot" label={baseT("onlyLot")}
                                defaultChecked={onlyLot}
                                onChanged={() => setOnlyLot(onlyLot !== true)}
                                disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            />
                            <CheckBox
                                id="onlyLotRemain"
                                label={baseT("onlyLotRemain")}
                                defaultChecked={onlyLotRemain}
                                onChanged={() => setOnlyLotRemain(onlyLotRemain !== true)}
                                disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            />
                            <CheckBox
                                id="onlyKiz"
                                label={baseT("onlyKiz")}
                                defaultChecked={onlyKiz}
                                onChanged={() => setOnlyKiz(onlyKiz !== true)}
                                disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            />
                        </FlexRow>
                        <FlexRow>
                            <PositionEditPanel kiz={showKizModalButton} edit={editItemButton} delete={deleteItemButton} clear={clearItemButton}/>
                            {onlyLot ? (
                                <LotSelectorPanel
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    onlyLotRemain={onlyLotRemain}
                                    gridFilter={lotGridFilter}
                                    dispatchGridFilter={dispatchLotGridFilter}
                                    onSelect={(value, searchData) => {
                                        if (!checkStorePharmacy(value, entity.store ?? null, pharmacy))
                                            return;

                                        openCreateItemFormTab({ type: "lot", lot: value, pricing: pricingModel, searchQuery: searchData })
                                    }}
                                    disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                                />
                            ) : (
                                <GoodsSelectorInput
                                    className={styles.width100pc}
                                    label={baseT("searchGoods")}
                                    inline
                                    onSelect={(value) => openCreateItemFormTab({ type: "goods", goods: value, pricing: pricingModel })}
                                    disabled={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                                />
                            )}
                        </FlexRow>
                        
                        <ChangeableGrid

                            hiddenPagination={{ hiddenNumberPage: true, hiddenCountRow: true }} //show pagination
                            documentStateProc={lockFromPermission || props.document?.documentState === DocumentStateType.proc}
                            itemsFilter={itemsFilter}
                            itemsValidation={itemsValidation}
                            setItemsValidation={(value) => setItemsValidation(itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item)))}
                            gridId={uuidv4()}
                            data={viewItems}
                            onPageLoad={ refreshItemsGrid }
                            dispatchFilter={ dispatchItemGridFilter }
                            //autoSelect
                            filter={itemGridFilter}
                            totalCount={totalCount}
                            sendRequest={sendRequestDocumentItems}
                            onSelect={(row) =>
                                setSelectedItem(viewItems.find((i) => i.idGlobal === row?.idGlobal))
                            }
                            //separator
                            autoSelectFirst
                            selectById={selectById}
                            plugin={InventoryVedItemCreatePluginSettings}
                            onDoubleClick={
                                (row) => props.onDoubleClick?.(row)
                            }
                            onSort={
                                (i) => dispatchItemGridFilter({
                                    type: "sort",
                                    payload: i.propertyName
                                })
                            }
                            onFilterDelete={
                                (i) => dispatchItemGridFilter(
                                    {
                                        type: "deleteColumnFilter", payload: i.propertyName
                                    })
                            }
                            onPageNumberChange={
                                (n) => dispatchItemGridFilter({
                                    type: "changePageNumber",
                                    payload: { pageNumber: n }
                                })
                            }
                            onNumberPerPageChange={
                                (n) => dispatchItemGridFilter({
                                    type: "changeNumberPerPage",
                                    payload: { numberPerPage: n }
                                })
                            }
                            onColumnEnter={(value: IChangeDataGrid) => () => changeDataGridHandler(value)}
                            //onChangeData={(value: IChangeDataGrid) => setTimer(() => changeDataGridHandler(value))
                            onBlur={(value: IChangeDataGrid) => changeDataGridHandler(value)}
                        />
                    </div>
                    <Accordion opened={false} caption="" id={uuidv4()} bold>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent disabled label={baseT("sumReport.supplierCostSum")} value={supplierCostInfo.sum} />
                            <MoneyFormatComponent disabled label={baseT("sumReport.supplierCostVatSum")} value={supplierCostInfo.vatSum} />
                            <MoneyFormatComponent disabled label={baseT("sumReport.supplierCostSumIncVat")} value={supplierCostInfo.sumIncVat} />
                        </GridWrapper>
                        <GridWrapper cols={3}>
                            <MoneyFormatComponent disabled label={baseT("sumReport.retailCostSum")} value={retailCostInfo.sum} />
                            <MoneyFormatComponent disabled label={baseT("sumReport.retailCostVatSum")} value={retailCostInfo.vatSum} />
                            <MoneyFormatComponent disabled label={baseT("sumReport.retailCostSumIncVat")} value={retailCostInfo.sumIncVat} />
                        </GridWrapper>
                    </Accordion>
                </div>
            </BaseDocumentCreator>)}

            {showKizModal && selectedItemEntity && (
                <KizScanModal
                    ok={(value: IInventoryVedItemEditDTO, scalingRatio?: IScalingRatioDTO) => {

                        // Update ведомости с полной перезаписью данных по КИЗ
                        let targetEntity: IInventoryVedEditDTO = { 
                            ...entity, 
                            items: [...entity.items.filter((i) => i.idGlobal !== value.idGlobal), value]
                        }
                        
                        setEntity(targetEntity);
                        setViewItems(
                            viewItems.map((x) => {
                                return {
                                    ...x,
                                    kizCount: x.idGlobal === value.idGlobal ? sumKizsCount(value, DocumentType.inventoryVed,scalingRatio) : x.kizCount,
                                };
                            })
                        );
                        setShowKizModal(false);
                        
                        // Сохранить буфер items в БД
                        // Действия с записями КИЗ на бэкенде
                        // Предполагается удаление записей если списки киз или коробов пустые
                        if (validator.isValid()) {

                            inventoryVedDP.update(targetEntity.idGlobal as string, {...targetEntity, bulkClearKiz: true}, (e) => {
                                clearItemsBuffer(targetEntity);
                                refreshItemsGrid();
                                refreshSumReport();
                            });
                        }
                    }}
                    cancel={() => setShowKizModal(false)}
                    selectedItem={selectedItemEntity}
                    documentType={DocumentType.inventoryVed}
                    document={{ idTable: IdTableVariant.InventoryVed, idDocument: entity.idGlobal as string }}
                    idLotFrom={selectedItemEntity?.idLotGlobal ?? null}
                    scalingRatio={scalingRatio}
                    numerator={scalingRatio?.numerator ?? 0}
                    idGoodsGlobal={selectedItemEntity.idGoodsGlobal}
                    onValidateDuplicate={(barcode) => validateDuplicateKizs(entity.items, barcode)}
                    onCustomAllow={allowKiz}
                />
            )}

            {modalWindow}
        </>
    );
};

export default InventoryVedForm;
