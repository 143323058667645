import { memo, useContext, useRef } from "react";
import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import styles from "./bunchGrid.module.scss"
import { MultiplyIcon } from "../../../../../libs/corporate/icons/outlined/suggested/SymbolCollection"
import { Tooltip } from "antd";
import defaultStyles from "../../../../../Business/styles/index.module.scss";
import { DateTime } from "luxon";
import { } from '../../../../../Services/Extensions/Boolean'
import { } from '../../../../../Services/Extensions/DateTime'
import { LoadingStatus } from "../../../../../@types/enumsGlobal";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid'
import { useAppContext } from "../../../../../system/providers/appContextProvider";
import { useUserContext } from "../../../../../system/providers/userContextProvider";
import { DownIcon, SortIcon, UpIcon } from "../../../../../libs/corporate/icons/outlined/directions/ChevronCollection";
import { Spinner } from "../../../../../components/spiner/Spinner";
import { CheckBoxMultiple } from "../../../../../components/checkboxes/checkBoxMultiple";
import GridPaginator from "../../../../../components/grids/gridPaginator";
import { ContextMenu } from "../../../../../components/contextMenu/contextMenu";
import GridUISettings from "../../../../../components/grids/default/gridUiSettings";
import { isArray } from "lodash";
import FlexRow from "../../../../../components/controls/FlexRow";
import { CheckBox } from "../../../../../components/controls/checkbox";

interface IGridCellWithWidth extends IGridCell {
    width: number
}

function compareByOrder(a: IComparer, b: IComparer) {
    if (a.order === undefined || b.order === undefined)
        return 0;

    if (a.order < b.order)
        return -1;

    if (a.order > b.order)
        return 1;

    return 0;
}

interface IMousePosition {
    x: number
    y: number
}

interface IDefaultGridProps<TViewDTO> {
    gridId: string,
    plugin: IPluginSettings,
    data: any[],
    filter: IGridFilter,
    totalCount: number,
    multipleSelect?: boolean,
    selectedItem?: IGridRow | IGridRowWithMultipleValue,
    selectedItems?: IGridRow[],
    hiddenPagination?: boolean
    numbering?: boolean,
    contextMunuItems?: IContextMenuItem[],
    loadingData?: LoadingStatus,
    setLoadingData?: (value: LoadingStatus) => void
    onSelect?: (row: IGridRow | IGridRowWithMultipleValue| undefined) => void,
    onDoubleClick?: (row: IGridRow) => void,
    onEnter?: (row: IGridRow) => void,
    onMultipleSelect?: (rows: IGridRow[]) => void,
    singleDirectory?: boolean;
    separator?: boolean;
    autoSelect?: boolean;

    onSort: (column: IGridColumn) => void,
    onFilterDelete: (column: IGridColumn) => void,
    onPageNumberChange: (pageNumber: number) => void,
    onNumberPerPageChange: (numberPerPage: number) => void,
    onGridRowContextMenuMouseClick?: (selectedRow: IGridRow, position: IMousePosition) => JSX.Element
}

const BunchGrid = <TViewDTO,>(props: PropsWithChildren<IDefaultGridProps<TViewDTO>>) => {
    const appCtx = useAppContext()
    const userCtx = useUserContext()

    const [gridRowContextMenuVisible, setGridRowContextMenuVisible] = useState(false);
    const [gridHeaderContextMenuVisible, setGridHeaderContextMenuVisible] = useState(false);
    const [showGridHeaderSettings, setShowGridHeaderSettings] = useState(false);
    const [gridRowContextMenu, setGridRowContextMenu] = useState<JSX.Element | undefined>();
    const [headerCtxPosY, setHeaderCtxPosY] = useState(0);
    const [headerCtxPosX, setHeaderCtxPosX] = useState(0);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [typeChecked, setTypeChecked] = useState<string>('emptyField');

    const [pluginSettingsLoaded, setPluginSettingsLoaded] = useState<boolean>(false);
    const [pluginSettings, setPluginSettings] = useState<IPluginSettings>(props.plugin);

    const [gridColumnsToSave, setGridColumnsToSave] = useState<IGridColumn[]>();
    const [data, setData] = useState<any[]>(props?.data?.map(x => ({ ...x, idRow: uuidv4() })));
    const [selectedItem, setSelectedItem] = useState<IGridRow | undefined | IGridRowWithMultipleValue>();
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const [, setLoadingRow] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [onlyСonnected, setOnlyСonnected] = useState<boolean>(false);

    let tableRef = useRef<HTMLDivElement>(null);
    const yDividerPos = useRef<number>(0);
    const [clientHeight, setClientHeight] = useState<number | null>(getClientHeight(data.length));   

    const { t } = useTranslation();

    function getClientHeight(dataLength: number) {
        if (dataLength >= 10 && props.singleDirectory) {
            const height = (props.filter.numberPerPage - 1) * 38;
            return height;
        } else if (dataLength >= 10 && !props.singleDirectory) {
            return 380;
        } else if (dataLength === 1) {
            return 100;
        } else if (dataLength === 0) {
            return 380;
        } else {
            return dataLength * 47 + 50;
        }
    }

    const onMouseHoldDown = (e) => {
        yDividerPos.current = e.clientY;
    };

    const onMouseHoldUp = () => {
        yDividerPos.current = 0;
    };

    const onMouseHoldMove = (e) => {
        if (!yDividerPos.current) {
            return;
        }
        setClientHeight(clientHeight + e.clientY - yDividerPos.current);
        yDividerPos.current = e.clientY;
    };

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        };
    });

    useEffect(() => {
        if (props.separator) {
            if (!clientHeight) {
                setClientHeight(getClientHeight(data.length));
                return;
            }
            if (tableRef.current) {
                tableRef.current.style.minHeight = clientHeight + "px";
                tableRef.current.style.maxHeight = clientHeight + "px";
            }
        }
    }, [clientHeight, tableRef.current]);

    useEffect(() => {
        setData(props?.data?.map(x => ({ ...x, idRow: uuidv4() })))
    }, [props.data])

    useEffect(() => {
        pluginSettingsLoad();
    }, [])

    useEffect(() =>  setSelectedItem(props.selectedItem), [props.selectedItem])

    useEffect(() => {

        if ((props.autoSelect && data.length === 1) || (data.length > 0 && !firstRender)) {
            getRows().map((item, index) => {
                const cells = item.cells
                let orderedCells: IGridCellWithWidth[] = [];
                for (let i = 0; i < cells.length; i++) {
                    orderedCells.push({
                        ...cells[i],
                        order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                        width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number
                    });
                }
                orderedCells = orderedCells.sort(compareByOrder)
                if (index === 0) {
                    props.onSelect?.(item);
                    setSelectedItem(item);
                    data.length > 0 && !firstRender && setFirstRender(true);
                }
            })
        }
    }, [data])

    const pluginSettingsLoad = () => {
        appCtx.uiApiService.uiPluginSettingsRequest.load(props.plugin.mnemocode, userCtx.idGlobal, (e) => {
            if (e.respType === "isCompleted") {
                let columns: IGridColumn[] = []
                e.data.gridColumns.forEach((item) => columns.push(item))
                const plugin: IPluginSettings = {
                    name: e.data.name,
                    mnemocode: e.data.mnemocode,
                    columns: [...columns],
                    permission: props.plugin.permission
                }
                setPluginSettings({ ...plugin, })
                setPluginSettingsLoaded(true)
                setLoadingRow(LoadingStatus.Completed)
            }
            else if (e.message.title === 'Не найдено') {
                setPluginSettingsLoaded(true)
                setLoadingRow(LoadingStatus.Completed)
            }

        })
    }

    useEffect(() => {
        if (allChecked) {
            let multipleiArr: IGridRow[] = []
            getRows().map(i => {
                multipleiArr.push(i)
                props.onMultipleSelect?.([...multipleiArr])
                return i

            })
        } else {
            let multipleiArr: IGridRow[] = []
            getRows().map(i => {
                multipleiArr.push(i)
                props.onMultipleSelect?.([])
                return i

            })
        }
    }, [allChecked])

    useEffect(() => {
        const selectedRows = getRows().filter(x => x.isSelected)
        const rowsLength = getRows().length

        if (selectedRows.length === rowsLength)
            setTypeChecked('allChecked')

        else if (selectedRows.length === 0)
            setTypeChecked('emptyField')

        else if (selectedRows.length < rowsLength)
            setTypeChecked('indeterminate')

    }, [getRows()])

    function getRows() {
        let rows: IGridRow[] = [];

        if (data) {
            data.forEach((i, index) => {
                const createIdGlobal = Array.isArray(i["idGlobal"]) ? (i["idGlobal"])[0] : (i["idGlobal"]);
                let cells: IGridCell[] = []
                Object.entries(i).forEach((prop) => {
                    try {
                        const propertyName = prop[0]
                        let value = prop[1]
                        const column = pluginSettings.columns.find(item => item.propertyName === propertyName)
                        if (value && value !== null && value !== '' && column) {
                            switch (column.propertyType) {
                                case 'boolean':
                                    value = (value as boolean)//.toYesNoString()
                                    break
                                case 'date':
                                    if (typeof (value) !== 'string') {
                                        value = (value as DateTime).toF3DateFormat(userCtx)
                                        break
                                    } else {
                                        value = null
                                        break
                                    }
                                case 'datetime':
                                    if (typeof (value) !== 'string') {
                                        value = (value as DateTime).toF3DateTimeFormat(userCtx)
                                        break
                                    } else {
                                        value = null
                                        break
                                    }
                            }
                        }

                        const order = column?.order as number
                        cells.push({ value, propertyName, order, multipleValue: isArray(value), visibility: pluginSettings.columns.find(x => x.propertyName === propertyName)?.visibility as boolean })
                    } catch (ex) {
                        console.trace(prop[0] + ' -> ' + prop[1])
                        console.trace(ex)
                    }
                })
                props?.multipleSelect
                    ? rows.push({
                        idGlobal: i["idGlobal"] as string,
                        idRow: i['idRow'] as string,
                        displayName: i["displayName"] as string,
                        cells: cells.filter(item => item.order !== undefined && item.order !== null).sort(compareByOrder),
                        isDeleted: i["deleted"] as boolean,
                        isSelected: props?.selectedItems?.find(x => x.idRow === i["idRow"]) === undefined ? false : true,
                        bunchType: i.bunchType as string
                    })
                    : rows.push({
                        idGlobal: createIdGlobal,
                        idRow: i['idRow'] as string,
                        displayName: i["displayName"] || i["name"] as string,
                        cells: cells.filter(item => item.order !== undefined && item.order !== null).sort(compareByOrder),
                        isDeleted: i["deleted"] as boolean,
                        isSelected: createIdGlobal === selectedItem?.idGlobal? true : false,
                        success: i["success"] as boolean,
                        bunchType: i.bunchType as string
                    })
            })
        }
        
        return onlyСonnected ? rows.filter((element) => !element.success) : rows;
    }

    function getContextId(type: 'header' | 'row', baseId: string) {
        return `ctxMenu_${type}_${baseId}`
    }

    useEffect(() => {
        const handler = () => setGridHeaderContextMenuVisible(false)

        if (gridHeaderContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId('header', props.gridId)) as HTMLElement
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler)
            }
        }
    }, [gridHeaderContextMenuVisible])

    useEffect(() => {
        const handler = () => setGridRowContextMenuVisible(false)
        if (gridRowContextMenuVisible === true) {
            let ctxMenu = document.getElementById(getContextId('row', props.gridId)) as HTMLElement
            if (ctxMenu) {
                ctxMenu.onmouseleave = handler;
                return ctxMenu.removeEventListener("mouseleave", handler)
            }
        }
    }, [gridRowContextMenuVisible])



    useEffect(() => {
        if (gridRowContextMenu) {
            let ctxMenu = document.getElementById(getContextId('row', props.gridId)) as HTMLElement

            if (ctxMenu) {
                ctxMenu.onmouseleave = () => setGridRowContextMenu(undefined);
                return ctxMenu.removeEventListener("mouseleave", () => setGridRowContextMenu(undefined))
            }
        }
    }, [gridRowContextMenu])

    useEffect(() => {
        if (gridColumnsToSave && pluginSettingsLoaded) {
            appCtx.uiApiService.uiPluginSettingsRequest.save({
                idUser: userCtx.idGlobal,
                name: pluginSettings.name,
                mnemocode: pluginSettings.mnemocode,
                gridColumns: gridColumnsToSave,
                detailsPlugins: [] as string[]

            })
            setPluginSettings({ ...pluginSettings, columns: gridColumnsToSave })
        }
    }, [gridColumnsToSave])

    const savePluginSettingsWidth = () => {
        let grid = document.getElementById(props.gridId) as HTMLElement
        let columnCells = grid.getElementsByClassName(styles.gridColumnCell);
        const newState: IGridColumn[] = [...pluginSettings.columns];

        let changed: boolean = false
        for (var i = 0; i < columnCells.length; i++) {
            const pName = columnCells[i].getAttribute("data-property-name")
            const column = newState.find(x => x.propertyName === pName) as IGridColumn;
            if (column.width !== columnCells[i].getBoundingClientRect().width) {
                changed = true
                column.width = columnCells[i].getBoundingClientRect().width;
            }
        }

        if (changed) {
            setGridColumnsToSave(newState)
        }
    }

    function renderSortIcon(item: IGridColumn) {
        if (props.filter.fieldOrderBy === item.propertyName && props.filter.orderDesc)
            return <DownIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />

        if (props.filter.fieldOrderBy === item.propertyName && !props.filter.orderDesc)
            return <UpIcon className={classNames(styles.sortIcon, styles.sortIcon_Selected)} />

        return <SortIcon className={styles.sortIcon} />
    }
    const render = () => {
        if (props.loadingData === LoadingStatus.InProcess) {
            return <Spinner />
        } else if (props.loadingData === LoadingStatus.NoAccess) {
            return <div className={styles.noDataInfo}>
                <span>Нет доступа</span>
            </div>
        } else if (props.loadingData === LoadingStatus.NoData) {
            return (
                <div className={styles.noDataInfo}>
                    <span>{t('general.noData')}</span>
                </div>
            )
        } else {
            return <div className={styles.gridWrapper}>
                <FlexRow>
                    <div className={styles.gridWrapper__quantityPositions}>Кол-во позиций: {data.length}</div>
                    <div className={styles.gridWrapper__quantityPositions}>Кол-во несвязанных позиций: {data.filter((element) => !element.connected).length}</div>
                    <div className={styles.gridWrapper__checkbox}>
                        <CheckBox
                            id={uuidv4()}
                            label='Только несвязанные'
                            defaultChecked={onlyСonnected}
                            onChanged={(checked) => {
                                setOnlyСonnected(checked);
                            }}
                        />
                    </div>
                </FlexRow>
                <div className={styles.gridWrapper__tables} ref={tableRef}>
                    <table
                        id={props.gridId}
                        className={styles.gridTable} >
                        <thead
                            className={styles.gridColumnsHeader}
                            onContextMenu={(e) => {
                                if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                    e.preventDefault();
                                    setHeaderCtxPosX(e.pageX)
                                    setHeaderCtxPosY(e.pageY)
                                    setGridHeaderContextMenuVisible(true)
                                }
                            }
                            }>
                            <tr
                                className={styles.gridColumn}
                                onMouseUp={(e) => savePluginSettingsWidth()}>
                                {
                                    props.multipleSelect &&
                                    <td
                                        draggable={false}
                                        className={styles.gridColumnCell}
                                        style={{ width: '45px', minWidth: '45px' }} >
                                        <CheckBoxMultiple checked={typeChecked} onChange={() => setAllChecked(!allChecked)} />
                                    </td>
                                }
                                {
                                    props.numbering &&
                                    <td draggable={false} className={styles.gridColumnCell} style={{ width: '45px', minWidth: '45px' }} >

                                    </td>
                                }
                                <>
                                    {
                                        pluginSettings.columns.sort(compareByOrder).map((item, index) => {
                                            let filtered = props.filter.columnFilters.filter((i) => i.name === item.propertyName).length > 0
                                            return (
                                                item.visibility &&
                                                <td

                                                    key={index}
                                                    draggable={true}
                                                    className={styles.gridColumnCell}
                                                    data-order-number={item.order + 1}
                                                    data-property-name={item.propertyName}
                                                    data-filter-selected={filtered}
                                                    style={{ width: item.width }}
                                                    onDragStart={(e) => {
                                                        e.dataTransfer.dropEffect = "move"
                                                        e.dataTransfer.setData("application/json", JSON.stringify(item))
                                                    }}
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        return false
                                                    }}
                                                    onDrop={(e) => {
                                                        let targetOrder = Number((e.target as HTMLElement).getAttribute("data-order-number"));
                                                        let data = JSON.parse(e.dataTransfer.getData("application/json")) as IGridColumn
                                                        let reoderderColumnState = pluginSettings.columns.map(i => {
                                                            if (i.order === data.order) {
                                                                i.order = targetOrder
                                                            }
                                                            else if (i.order === targetOrder) {
                                                                i.order = data.order
                                                            }
                                                            return i
                                                        });
                                                        setGridColumnsToSave(reoderderColumnState)
                                                    }}
                                                >
                                                    <span draggable={false} >{item.displayName}</span>
                                                    <div
                                                        draggable={false}
                                                        className={classNames(styles.gridColumnCellButtons)}>
                                                        <div
                                                            draggable={false}
                                                            className={styles.gridSortButton}
                                                            onClick={() => {
                                                                props.onSort(item)
                                                                props?.setLoadingData?.(LoadingStatus.InProcess)
                                                            }}>
                                                            {renderSortIcon(item)}
                                                        </div>
                                                        {filtered && <div draggable={false} className={styles.gridFilterButton} onClick={() => props.onFilterDelete(item)}> <MultiplyIcon /> </div>}
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </>
                            </tr>
                        </thead>
                        <tbody className={styles.gridRowsGroup} >

                            {

                                getRows().map((item, index) => {
                                    const cells = item.cells
                                    let orderedCells: IGridCellWithWidth[] = [];
                                    for (let i = 0; i < cells.length; i++) {
                                        orderedCells.push({
                                            ...cells[i],
                                            order: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.order as number,
                                            width: pluginSettings.columns.find((item) => item.propertyName === cells[i].propertyName)?.width as number
                                        });
                                    }
                                    orderedCells = orderedCells.sort(compareByOrder)
                                    return (
                                        <tr
                                            onContextMenu={(e) => {
                                                if (process.env.REACT_APP_SHOW_GRID_CONTEXT === "true") {
                                                    e.preventDefault();
                                                    setGridRowContextMenu(props.onGridRowContextMenuMouseClick?.(item, { x: e.pageX - 5, y: e.pageY - 5 }))
                                                }

                                                if (selectedItem) {
                                                    setHeaderCtxPosX(e.pageX);
                                                    setHeaderCtxPosY(e.pageY);
                                                    setGridRowContextMenuVisible(true);
                                                }


                                            }}
                                            key={item.idGlobal ?? index}
                                            tabIndex={-1}
                                            data-item-id={item.idGlobal}
                                            className={classNames(styles.gridRow, item.isSelected ? styles.gridRowSelected : null, item.isDeleted ? styles.gridRowDeleted : null, item.success ? styles.gridRowSuccess : styles.gridRowDeleted, item.bunchType === 'Код товара' ? styles.gridRowGoodsCode : null, item.bunchType === 'Штрихкод товара' ? styles.gridRowBarcode : null, item.bunchType === 'Вручную' ? styles.gridRowHandBunch : null)}
                                            onClick={() => {
                                                if (props?.multipleSelect) {
                                                    if (props?.selectedItems?.length === 0) {
                                                        props.onMultipleSelect?.([item])
                                                    }
                                                    if (props?.selectedItems) {
                                                        props?.selectedItems?.find(x => x.idGlobal === item.idGlobal) === undefined &&
                                                            props.onMultipleSelect?.([...props.selectedItems, item])
                                                    }
                                                    if (item.isSelected && props?.selectedItems) {
                                                        const newSelecterArray = props?.selectedItems?.filter(x => x.idGlobal !== item.idGlobal)
                                                        props.onMultipleSelect?.([...newSelecterArray])
                                                    }
                                                } else {
                                                    if (item.isSelected) {
                                                        setSelectedItem(undefined)
                                                        props.onMultipleSelect?.([])
                                                        props.onSelect?.(undefined)
                                                    } else {
                                                        setSelectedItem(item)
                                                        props.onSelect?.(item)
                                                        props.onMultipleSelect?.([item])
                                                    }
                                                }
                                            }}
                                            onKeyDown={(eKeyPress) => {
                                                switch (eKeyPress.key) {
                                                    case "ArrowDown":
                                                        let nextElement = eKeyPress.currentTarget.nextElementSibling as HTMLElement
                                                        if (nextElement != null)
                                                            nextElement.focus();
                                                        break;

                                                    case "ArrowUp":
                                                        let previousElement = eKeyPress.currentTarget.previousElementSibling as HTMLElement
                                                        if (previousElement != null)
                                                            previousElement.focus();
                                                        break;

                                                    case "Enter":
                                                        if (props.onEnter) {
                                                            selectedItem && item && props.onEnter(item)
                                                        }
                                                        break;

                                                    default:
                                                        break;
                                                }

                                            }}
                                            onDoubleClick={() => {
                                                props.multipleSelect === false && props.onDoubleClick?.(item)
                                            }}
                                        >
                                            {
                                                props.multipleSelect &&
                                                <td className={styles.gridRowCell} style={{ width: '45px', minWidth: '45px' }}>
                                                    <input type='checkbox' checked={item.isSelected} />
                                                </td>
                                            }
                                            {
                                                props.numbering &&
                                                <td className={styles.gridRowCell} style={{ width: '45px', minWidth: '45px' }}>
                                                    {index + 1}
                                                </td>
                                            }

                                            {
                                                orderedCells.map((i, index) => {
                                                    return (
                                                        i.visibility &&
                                                        <td
                                                            key={index}
                                                            className={item.success ? styles.gridRowCellSuccess : styles.gridRowCell}
                                                            style={{ width: i.width }}
                                                        >
                                                            {
                                                                i.multipleValue ?
                                                                    <div className={styles.outWrapper}>
                                                                        <span
                                                                            className={styles.gridRowCellText}
                                                                        >
                                                                            <Tooltip
                                                                                title={i.value[0]}
                                                                            >
                                                                                {i.value[0]}
                                                                            </Tooltip>
                                                                        </span>
                                                                        <>
                                                                            {i.value[1]}
                                                                        </>
                                                                    </div>
                                                                    :
                                                                    <div className={styles.outWrapper}>
                                                                        <span
                                                                            className={styles.gridRowCellText}
                                                                        >
                                                                            <Tooltip
                                                                                title={i.value}
                                                                            >
                                                                                {
                                                                                    typeof (i.value) === 'boolean' ?
                                                                                        <input type='checkbox' checked={i.value} />
                                                                                        : 
                                                                                        i.value                                                   
                                                                                }

                                                                            </Tooltip>
                                                                        </span>
                                                                    </div>
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                
                {props?.separator && <div onMouseDown={onMouseHoldDown} className={classNames(defaultStyles.separator, defaultStyles.separator_horizontal)}></div>}            
                {
                    !props.hiddenPagination &&
                    <GridPaginator
                        nearPage={2}
                        pageNumber={props.filter.pageNumber}
                        totalCount={props.totalCount}
                        numberPerPage={props.filter.numberPerPage}
                        onPageNumberChange={props.onPageNumberChange}
                        onNumberPerPageChange={props.onNumberPerPageChange}
                    />
                }
                {
                    gridHeaderContextMenuVisible &&
                    <ContextMenu
                        id={getContextId('header', props.gridId)}
                        xPos={headerCtxPosX - 5}
                        yPos={headerCtxPosY - 5}
                        items={[{
                            name: "Настройки таблицы",
                            onClick: () => setShowGridHeaderSettings(true)
                        }
                        ]}>
                    </ContextMenu>
                }
                {
                    gridRowContextMenuVisible &&
                    <ContextMenu
                        id={getContextId('row', props.gridId)}
                        xPos={headerCtxPosX - 5}
                        yPos={headerCtxPosY - 5}
                        items={props.contextMunuItems ?? []}>
                    </ContextMenu>
                }
                {
                    showGridHeaderSettings &&
                    <GridUISettings
                        plugin={pluginSettings}
                        defaultPlugin={props.plugin}
                        save={(e) => {
                            setGridColumnsToSave(e.columns)
                            setGridHeaderContextMenuVisible(false)
                            setShowGridHeaderSettings(false)
                        }}
                        cancel={() => {
                            setGridHeaderContextMenuVisible(false)
                            setShowGridHeaderSettings(false)
                        }}>
                    </GridUISettings>
                }

                {
                    gridRowContextMenu
                }
            </div>
        }
    }
    return (
        render()
    )
}

export default memo(BunchGrid)