import { FC, useEffect, useState } from "react";
import { IdTableVariant } from "../../../../../@types/enumsGlobal";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import useOperativeReserve from "../../../../../components/lotSelector/useOperativeReserve";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { IMovementItemEditDTO } from "../../../../../libs/coreapi-dto/documents/movementItem";
import { ICreateItem } from "../MovementUpdateForm";
import { useForm } from "../../../../../system/hooks/useForm";
import { DateTime } from "luxon";

interface IMovementUpdateItemModal {
    idDocumentGlobal: string;
    idDocumentItemGlobal: string;
    lot: ILotDTO;
    movementItemEdit: IMovementItemEditDTO;
    kizCount: number;
    ok: (createItem: ICreateItem) => void;
    cancel: () => void;
}

interface IValidator {
    quantity: number;
}

export const MovementUpdateItemModal: FC<IMovementUpdateItemModal> = (props) => {
    const [createItem, setCreateItem] = useState<IMovementItemEditDTO>(props.movementItemEdit);

    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    const buildDocumentItem = (): ICreateItem => {
        return {
            item: {
                ...createItem,
                idGlobal: props.idDocumentItemGlobal,
                supplierCostInfo: {
                    ...props.lot.supplierCostInfo,
                    vat: props.lot.supplierCostInfo.vat,
                    sum: round(props.lot.supplierCostInfo.price * createItem.quantity),
                    vatSum: round(props.lot.supplierCostInfo.vatPrice * createItem.quantity),
                    sumIncVat: round(props.lot.supplierCostInfo.priceIncVat * createItem.quantity),
                },
                retailCostInfo: {
                    ...props.lot.retailCostInfo,
                    vat: props.lot.retailCostInfo.vat,
                    sum: round(props.lot.retailCostInfo.price * createItem.quantity),
                    vatSum: round(props.lot.retailCostInfo.vatPrice * createItem.quantity),
                    sumIncVat: round(props.lot.retailCostInfo.priceIncVat * createItem.quantity),
                },
                dateCreated: DateTime.now()
            },
            displayItem: {
                idGlobal: props.idDocumentItemGlobal,
                idLotFromGlobal: createItem.idLotFromGlobal,
                goodsName: props.lot.goods.displayName,
                scalingRatioName: props.lot.scalingRatio.displayName,
                quantity: createItem.quantity,

                supplierVat: props.lot.supplierCostInfo.vat,
                supplierPrice: props.lot.supplierCostInfo.price,
                supplierVatPrice: props.lot.supplierCostInfo.vatPrice,
                supplierPriceIncVat: props.lot.supplierCostInfo.priceIncVat,
                supplierSum: round(props.lot.supplierCostInfo.sum * createItem.quantity),
                supplierVatSum: round(props.lot.supplierCostInfo.vatSum * createItem.quantity),
                supplierSumIncVat: round(props.lot.supplierCostInfo.sumIncVat * createItem.quantity),
                supplierAdprice: props.lot.supplierCostInfo.adprice,

                retailVat: props.lot.retailCostInfo.vat,
                retailPrice: props.lot.retailCostInfo.price,
                retailVatPrice: props.lot.retailCostInfo.vatPrice,
                retailPriceIncVat: props.lot.retailCostInfo.priceIncVat,
                retailSum: round(props.lot.retailCostInfo.sum * createItem.quantity),
                retailVatSum: round(props.lot.retailCostInfo.vatSum * createItem.quantity),
                retailSumIncVat: round(props.lot.retailCostInfo.sumIncVat * createItem.quantity),
                retailAdprice: props.lot.retailCostInfo.adprice,

                isKiz: props.lot.isKizFlag,
                countKizScan: props.lot.isKiz === false ? undefined : props.kizCount,
                denominator: props.lot.denominator,
                numerator: props.lot.numerator,
                supplier: props.lot.supplier,
                dateCreated: DateTime.now(),
                isOsu: createItem.isOsu
            },
            filter: {
                idItem: createItem.idGlobal,
                quantityMax: props.lot.quantityRem + props.lot.quantityRes,
                quantityMin: 0,
            },
        };
    };

    const remain = useLotRemain(props.lot.idGlobal, props.idDocumentGlobal);
    const operativeReserve = useOperativeReserve();

    const onOk = () => {
        operativeReserve.send(
            {
                idLot: props.lot.idGlobal,
                idTable: IdTableVariant.Movement,
                idDocument: props.idDocumentGlobal,
                idDocumentItem: props.idDocumentItemGlobal,
                quantity: createItem.quantity,
            },
            () => props.ok(buildDocumentItem())
        );
    };

    const onCancel = () => {
        operativeReserve.send(
            {
                idLot: props.lot.idGlobal,
                idTable: IdTableVariant.Movement,
                idDocument: props.idDocumentGlobal,
                idDocumentItem: props.idDocumentItemGlobal,
                quantity: props.movementItemEdit.quantity,
            },
            props.cancel
        );
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: createItem.quantity && createItem.quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    return (
        <BaseModalWindow
            header="Введите количество"
            ok={{
                onClick: () => {
                    if (isValid()) {
                        onOk();
                    }
                },
            }}
            cancel={{ onClick: onCancel }}
            modalWindowClassName={modalStyles.modalWindowMovement}
            footerStyle="confirm"
        >
            <>
                <TextAreaInput value={props.lot.displayName} disabled />
                <GridWrapper cols={3}>
                    <TextInput label="Единица измерения" value={props.lot.scalingRatio.displayName} disabled />
                    <NumberInput label="Остаток" value={remain} disabled />
                    <NumberInput
                        required
                        onFocus={() => setErrors({ ...errors, quantity: undefined })}
                        error={errors.quantity}
                        keyBan={true}
                        label="Количество"
                        value={createItem.quantity}
                        min={1}
                        max={remain}
                        onChange={(value) => setCreateItem({ ...createItem, quantity: value })}
                    />
                </GridWrapper>
            </>
        </BaseModalWindow>
    );
};
