import { DateTime } from "luxon";
import { IEntitySimpleDTO, ISummaryCostInfoDTO, IUserInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { KizInventoryCreatorReducerActionType } from "./KizInventoryCreatorReducerActionType";
import { IKizInventoryEditDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventory";
import { IKizInventoryItemViewDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";
import { DocumentState } from "../../../../@types/enumsGlobal";
import { v4 as uuidv4 } from 'uuid';

export interface IExtendedKizInventoryViewDTO {
    readonly idGlobal: string;
    readonly documentDate: DateTime;
    readonly displayName: string;
    readonly documentState: string;
    readonly documentStateModified: DateTime;
    readonly mnemocode: string;
    readonly store: IEntitySimpleDTO;
    readonly contractor: IEntitySimpleDTO;
    readonly comment: string;
    readonly accountingLag : number;
    readonly items: IKizInventoryItemViewDTO[];
    readonly selectedItem: IGridRow | undefined;
}

export interface IKizInventoryCreatorReducerInitialState {
    createData: IKizInventoryEditDTO;
    viewData: IExtendedKizInventoryViewDTO;
}

const initialStore = {
    displayName: '', idGlobal: ''
};

const initialContractor = {
    displayName: '', idGlobal: ''
};

export const KizInventoryCreatorReducer: IKizInventoryCreatorReducerInitialState = {
    createData: {
        idGlobal: "",
        documentDate: DateTime.now(),
        idStoreGlobal: "",
        idContractorGlobal: "",
        comment: "",
        accountingLag: 0,
        items: [],
    },
    viewData: {
        idGlobal: "",
        documentDate: DateTime.now(),
        displayName: '',
        documentState: DocumentState['draft'],
        documentStateModified: DateTime.now(),
        mnemocode: '',
        store: initialStore,
        contractor: initialContractor,
        comment: "",
        accountingLag: 0,
        items: [],
        selectedItem: undefined,
    },
};

export function KizInventoryCreatorReducerHandler(state: IKizInventoryCreatorReducerInitialState = KizInventoryCreatorReducer, action: KizInventoryCreatorReducerActionType): IKizInventoryCreatorReducerInitialState {
    switch (action.type) {
        case "autoCompleteFirstRenderCreate":
            return handleUpdateField(state, action, 'idGlobal')
        case "setDocumentDate":
            return handleUpdateField(state, action, 'documentDate')
        case "setComment":
            return handleUpdateField(state, action, 'comment')
        case "setAccountingLag":
            return handleUpdateField(state, action, 'accountingLag')
        case "setStore":
            return handleStoreAction(state, action);
        case "clearStore":
            return handleStoreAction(state, action);
        case "setContractor":
            return handleContractorAction(state, action);
        case "clearContractor":
            return handleContractorAction(state, action);
        case "setSelectedItem":
            return {
                ...state,
                viewData: {
                    ...state.viewData,
                    selectedItem: action.payload
                },
            };
        case "autoCompleteFirstRenderEditCopy":
            const newData = action.payload;
            return {
                ...state,
                createData: {
                    ...state.createData,
                    idGlobal: newData?.idGlobal,
                    documentDate: newData?.documentDate,
                    idStoreGlobal: newData?.store?.idGlobal,
                    idContractorGlobal: newData?.contractor?.idGlobal,
                    comment: newData?.comment,
                    accountingLag: newData?.accountingLag,
                    items: (newData?.items ?? [])?.map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idKizInventoryGlobal: el?.kizInventory.idGlobal,
                        }
                    }),
                },
                viewData: {
                    ...state.viewData,
                    idGlobal: newData?.idGlobal,
                    documentDate: newData?.documentDate,
                    displayName: newData?.displayName,
                    documentState: DocumentState[newData?.documentState],
                    documentStateModified: newData?.documentStateModified,
                    mnemocode: newData?.mnemocode,
                    store: newData?.store,
                    contractor: newData?.contractor,
                    comment: newData?.comment,
                    accountingLag: newData?.accountingLag,
                    items: (newData.items ?? []).map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idDocumentGlobal: state.createData.idGlobal,
                            idKizInventoryGlobal: el?.kizInventory?.idGlobal,
                            dateCreated: state.viewData.documentDate,
                            kizInventoryName: el?.kizInventory?.displayName,
                            sgtin: el?.sgtin,
                            mdlpError: el?.mdlpError,
                        }
                    }),
                },
            };

        default:
            throw new Error();
    }
}

//handlers
const handleStoreAction = (state, action) => {
    const typeStore = action.type === "setStore"
    return {
        ...state,
        createData: {
            ...state.createData,
            idStoreGlobal: typeStore ? action.payload.idGlobal : initialStore.idGlobal,
        },
        viewData: {
            ...state.viewData,
            store: typeStore ? action.payload : initialStore,
        },
    };
};

const handleContractorAction = (state, action) => {
    const _type = action.type === "setContractor"
    return {
        ...state,
        createData: {
            ...state.createData,
            idContractorGlobal: _type ? action.payload.idGlobal : initialContractor.idGlobal,
        },
        viewData: {
            ...state.viewData,
            contractor: _type ? action.payload : initialContractor,
        },
    };
};

const handleUpdateField = (state, action, field) => {
    return {
        ...state,
        createData: {
            ...state.createData,
            [field]: action.payload,
        },
        viewData: {
            ...state.viewData,
            [field]: action.payload,
        },
    };
};
