import axios from 'axios';
import {IGoodsDTO} from '../../coreapi-dto/dirs/goods';
import {IKiz2DocumentItemGetDTO, IKizDTO} from '../../coreapi-dto/dirs/kiz';
import {IInvoiceGetDTO} from '../../coreapi-dto/documents/invoice';
import {IInvoiceItemEditDTO, IInvoiceItemGetDTO} from '../../coreapi-dto/documents/invoiceItem';
import {envF} from '../../../env';
import { IActDisagregationItemEditDTO, IActDisagregationItemGetDTO } from '../../coreapi-dto/documents/actDisagregationItem';
import { IActDisagregationGetDTO } from '../../coreapi-dto/documents/actDisagregation';

export const getActDisagregationItemUpdateDTO = async (
  idDocument: string,
  callback: (e: IActDisagregationItemEditDTO[]) => void,
  editActDisagregation?: IActDisagregationGetDTO
) => {
  const jwtToken = localStorage.getItem('TokenData') as string;
  const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
  const header = {
    headers: {
      Authorization: token,
    },
  };

  const generateKizDocumentRequestUrl = (idDocumentItem) => {
    return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`;
  };
  const generateKizRequestUrl = (sscc) => {
    return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/GetBySscc/${sscc}`;
  };
  const generateGoodsByIdRequestUrl = (idGoods) => {
    return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`;
  };
  

  const generateActDisagregationRequestUrl = () => {
    return `${envF.REACT_APP_API_GATEWAY_PATH}/ActDisagregation/${idDocument}`;
  };

  let items: IActDisagregationItemEditDTO[] = [];

  async function getKizsBySscc (e) {
    const sscc = e?.data?.items[0]?.kizBoxes[0]?.sscc;
    if (sscc === undefined) return []

    const entity = await axios.get<IKizDTO[]>(generateKizRequestUrl(sscc),header)
    let kizs = e.data.items.flatMap(c => c.kizes).filter(c => c !== null)
      
    if (entity?.data?.length > 0) {
        entity.data.forEach(element => {
          if (kizs.length > 0) {
            let result = kizs.find(c => c.barcode === element.barcode)
            if (!result) kizs.push(element)
          }
          else 
            kizs.push(element)
        });
    }

    return kizs
  }

  const itemsPush = async (x: IActDisagregationItemGetDTO) => {
      await axios.get<IKiz2DocumentItemGetDTO>(generateKizDocumentRequestUrl(x.idGlobal), header).then(async (e) => {

        const kizs = await getKizsBySscc(e)
        await axios.get<IGoodsDTO>(generateGoodsByIdRequestUrl(x.goods.idGlobal), header).then((goods) => {
          items.push({
            idGlobal: x.idGlobal as string,
            idGoodsGlobal: x.goods.idGlobal,
            idScalingRatioFromGlobal: x.scalingRatioFrom.idGlobal,
            idScalingRatioToGlobal: x.scalingRatioTo.idGlobal,
            quantity: x.quantity,
            productMargin: x.productMargin,
            supplierCostInfo: x.supplierCostInfo,
            retailCostInfo: x.retailCostInfo,
            gtdNumber: x.gtdNumber,
            barCode: x.barCode,
            registerPrice: x.registerPrice,
            retailPriceIncVat: x.retailPriceIncVat,
            isKiz: x.isKiz,
            isKizBox: x.isKizBox,
            kizs: kizs ?? [],
            kizBoxes: e.data.items[0]?.kizBoxes ?? [],
            isGnvls: goods.data.gnvls.important,
            kizBoxCount: x.kizBoxCount,
            kizCount: x.kizCount,
            denominator: x.denominator,
            numerator: x.numerator,    
            idLotFromGlobal: x.lotFrom.idGlobal,
            quantityFrom: x.quantityFrom,
            supplier: x.supplier
          } as IActDisagregationItemEditDTO);
        });
      }).catch((error) => console.log(error));
  };

  const addItems = async () => {
    if (editActDisagregation) {
      const unresolvedPromises = editActDisagregation.items.map((x) => itemsPush(x));
      const results = await Promise.all(unresolvedPromises);
    } else {
      let actDisagregation = await axios.get<IActDisagregationGetDTO>(generateActDisagregationRequestUrl(), header);
      const unresolvedPromises = actDisagregation.data.items.map((x) => itemsPush(x));
      const results = await Promise.all(unresolvedPromises);
    }
  };

  addItems().then(() => callback(items));
};
