import classNames from "classnames";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultTreeContractorViewCommandsPanel from "../../../components/commandsPanels/DefaultTreeContractorViewCommandsPanel";
import { ContextMenu } from "../../../components/contextMenu/contextMenu";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { TreeViewPanel } from "../../../components/treeViewPanel";
import { IContractorViewDTO } from "../../../libs/coreapi-dto/dirs/contractor";
import { ContractorDataProvider } from "../../../Services/DataProviders/ContractorDataProvider";
import { ContractorGroupDataProvider } from "../../../Services/DataProviders/ContractorGroupDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import styles from '../../styles/index.module.scss';
import { ContractorGroupCreatorModal, ContractorGroupSelectorModal } from "../ContractorGroup";
import ContractorCreatorModal from "./ContractorCreatorModal";
import { DocumentStatusType, DocumentType } from "../../../@types/enumsGlobal";
import { treeViewFolderPharmacy, treeViewFolderSupplier } from "../../../constants";

type GridRowViewState = "view" | "return" | "attachToGroup" | "moveToGroup" | "detachFromGroup" | "setMe"

const ContractorPluginView: FC<IGridProps & ITreeViewCheckDirectory> = (props) => {
    const appContext = useAppContext()
    const pluginContext = usePluginContext()
    const userContext = useUserContext()
    const contractorDP = new ContractorDataProvider(appContext.coreApiService)
    const contractorGroupDP = new ContractorGroupDataProvider(appContext.coreApiService)

    const [contractorViewState, setContractorViewState] = useState<GridStateType>("view")
    const [contractorGroupViewState, setContractorGroupViewState] = useState<TreeViewStateType>('view')
    const [contextMenuViewState, setContextMenuViewState] = useState<GridRowViewState>("view")

    const [contractorGroupGridFilter] = useGridFilter(CreateSelectorGridFilter)
    const [contractorGroupFolders, setContractorGroupFolders] = useState<ITreeViewFolder[]>([])
    const [data, setData] = useState<IContractorViewDTO[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter)
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
        // selected items in invoice for multiselect
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props?.data ?? []);
    const [selectedItemView, setSelectedItemViews] = useState<IContractorViewDTO>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(props.multipleSelect ?? false);
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();

    const [canSetMe, setCanSetMe] = useState<boolean>(false);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    const [clientWidth, setClientWidth] = useState<number | null>(null);
    const xDividerPos = useRef<number>(0);
    const treeViewPanelRef = useRef<HTMLDivElement>(null);

    const [searchGroup, setSearchGroup] = useState<string>('');
    const [loaderThree, setLoaderThree] = useState<boolean>(true);

    const checkDirectoryType = (): boolean => props.treeViewCheckDirectoryType ? true : false; //функция для проверки props.treeViewCheckDirectoryType и блокировки useEffect
    let firstRenderWithDirectory = useRef<boolean>(false); //Реф необходимый для блокировки запросов при монтировании компонента

    function UpdateTree(callBack?: () => void): void {
        
        contractorGroupDP.getView(contractorGroupGridFilter, (entities) => {
            let tmpList: ITreeViewFolder[] = []
            entities.forEach(item => {
                tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
            });
            setContractorGroupFolders(tmpList)
            setContractorGroupViewState('view')
            callBack?.();
        })
    }

    useEffect(() => {
        if (!pluginContext.treeView.visiblePanel && !pluginContext.treeView.selectedFolder) {
            setLoaderThree(true);
        }

        if (pluginContext.treeView.visiblePanel) {
            if (!pluginContext.treeView.selectedFolder) {
                UpdateTree(() => setLoaderThree(false));
            }
            else if (contractorGroupViewState === 'return') {
                UpdateTree();
            }
        }
    }, [pluginContext.treeView.visiblePanel, contractorGroupViewState, pluginContext.treeView.selectedFolder])
    
    const onMouseHoldDown = (e) => {
        xDividerPos.current = e.clientX;
    }

    const onMouseHoldUp = () => {
        xDividerPos.current = 0;
    }

    const onMouseHoldMove = (e) => {
        if (!xDividerPos.current) {
            return;
        }
        setClientWidth(clientWidth + e.clientX - xDividerPos.current);
        xDividerPos.current = e.clientX;
    }

    useEffect(() => {
        setClientWidth(treeViewPanelRef.current?.clientWidth as number);
    }, [])

    useEffect(() => {
        document.addEventListener("mouseup", onMouseHoldUp);
        document.addEventListener("mousemove", onMouseHoldMove);
        return () => {
            document.removeEventListener("mouseup", onMouseHoldUp);
            document.removeEventListener("mousemove", onMouseHoldMove);
        }
    })

    useEffect(() => {
        if (!clientWidth) {
            setClientWidth(treeViewPanelRef.current?.clientWidth as number);
            return;
        }
        if (treeViewPanelRef.current) {
            treeViewPanelRef.current.style.minWidth = clientWidth + "px";
            treeViewPanelRef.current.style.maxWidth = clientWidth + "px";
        }
    }, [clientWidth, treeViewPanelRef.current])


    useEffect(() => {
        if(checkDirectoryType() && !firstRenderWithDirectory.current) return;//Блокировка запроса при 1ом монтировании компонента.
        setIsSubmitting(true)
        contractorDP.getView(gridFilter, (entities, totalCount) => {
            setData(entities)
            setTotalCount(totalCount)
            setIsSubmitting(false)
        })
    }, [gridFilter])

    useEffect(() => {
        if(checkDirectoryType()) return;
        if (contractorViewState === 'refresh') {
            setIsSubmitting(true)
            contractorDP.getView(gridFilter, (entities, totalCount) => {
                setData(entities)
                setContractorViewState('view')
                setTotalCount(totalCount)
                setIsSubmitting(false)
            })
        }
    }, [contractorViewState])

    //Вызов справочника
    function getContractorView({ onDataReceived, customGridFilter }: { onDataReceived?: VoidFunction, customGridFilter?: IGridFilter }) {
        setIsSubmitting(true)
        contractorDP.getView(customGridFilter ?? gridFilter, (entities, totalCount) => {
            setData(entities)
            setTotalCount(totalCount)
            if (onDataReceived) {
                onDataReceived()
            }
            setIsSubmitting(false)
        })
    }
    //Типы согласно которым будет производится запрос.
    const treeViewSelectParams:Partial<Record<TreeViewCheckDirectoryType, ITreeViewFolder>> = {
        'supplier': treeViewFolderSupplier, //Поставщик
        'pharmacy': treeViewFolderPharmacy, //Аптека
    };

    //При монтировании выбирается группа согласно переданному типу.
    const openTreeStructureByTypeFirstRender = () => {

        if (props.treeViewCheckDirectoryType) {
            // При нажатии кнопки Enter при выделенном инпуте произойдет обычный запрос.
            if (props.isEnterPressed) {
                setLoaderThree(true);
                getContractorView({
                    onDataReceived: () => {
                        setLoaderThree(false);
                        firstRenderWithDirectory.current = true;
                    },
                });
            } else {
                //Проверка при которой происходит выбор групп и открытие справочника согласно типу.
                if (treeViewSelectParams[props.treeViewCheckDirectoryType]) {
                    const selectParam = treeViewSelectParams[props.treeViewCheckDirectoryType] as ITreeViewFolder;
                    pluginContext.treeView.onVisiblePanelEvent(true);
                    pluginContext.treeView.onSelectEvent(selectParam);
    
                    setLoaderThree(true);
                    UpdateTree(() => {
                        getContractorView({ customGridFilter: { ...gridFilter, paramFilter: { idGroupGlobal: selectParam.folderId } } });
                        setLoaderThree(false);
                        firstRenderWithDirectory.current = true;
                    });
                }
            }
        }
    }

    useEffect(() => {
        contractorDP.canSetMe((value) => setCanSetMe(value))
        openTreeStructureByTypeFirstRender();

        return () => {
            pluginContext.treeView.onVisiblePanelEvent(false); //Снятия флага групп при удалении компонента.
            pluginContext.treeView.onUnselectEvent()
            pluginContext.masterGrid.onUnselectEvent()
        }
    }, [])

    useEffect(() => {
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginContext.masterGrid.onUnselectEvent()
    }, [contractorViewState,gridFilter]);

    return <>
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultTreeContractorViewCommandsPanel
                        selectedItems={[selectedItems,setSelectedItems]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={contractorDP}
                        groupDataProvider={contractorGroupDP}
                        creatorModalJsx={ContractorCreatorModal}
                        creatorGroupModalJsx={ContractorGroupCreatorModal}
                        selectorGroupModalJsx={ContractorGroupSelectorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setContractorViewState(vs)}
                        setGroupViewState={(vs) => setContractorGroupViewState(vs)}
                        contextMenuViewState={[contextMenuViewState, setContextMenuViewState]}
                        activateGroupProcessing={props.activateGroupProcessing}
                    />
                </ToolbarWrapper>
            }

            <div className={styles.pluginWorkspace}>
                {
                    <>
                        <div style={{display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={styles.treeView} ref={treeViewPanelRef}>
                            <TreeViewPanel
                                loaderThree={loaderThree}
                                folders={contractorGroupFolders}
                                selectedFolder={pluginContext.treeView.selectedFolder}
                                onHideClick={() => pluginContext.treeView.onVisiblePanelEvent(false)}
                                onSelectFolder={(folder) => pluginContext.treeView.onSelectEvent(folder)}
                                onExpandFolder={(folder, callback) => {
                                    contractorGroupDP.getChildViewAsync(folder.folderId, contractorGroupGridFilter,
                                        (entities) => {
                                            let tmpList: ITreeViewFolder[] = []
                                            entities.forEach(item => {
                                                tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                                            });
                                            callback(tmpList)
                                        })
                                }} 
                                setSearchGroup = {(value) => setSearchGroup(value)}
                                searchGroup= {searchGroup}
                                groupDataProvider={contractorGroupDP}
                                setGroupViewState={(value) => setContractorGroupViewState(value) }
                                gridFilter={[gridFilter,dispatchGridFilter]}
                                pluginSettings={props.plugin}
                            />
                        </div>
                        <div onMouseDown={onMouseHoldDown} style={{cursor: 'col-resize', display: pluginContext.treeView.visiblePanel ? 'block' : 'none'}} className={classNames(styles.separator, styles.separator_vertical)}></div>
                    </>
                }

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {
                            isSubmitting ?
                                <Spinner /> :
                                <DefaultGrid
                                    gridId={props.gridId}
                                    data={data}
                                    totalCount={totalCount}
                                    filter={gridFilter}
                                    plugin={props.plugin}
                                    separator
                                    singleDirectory
                                    dataProvider={contractorDP}
                                    selectedItem={selectedItem}
                                    multipleSelect={multipleSelect}
                                    selectedItems={selectedItems}
                                    onSelect={(row) => {
                                        setSelectedItem(row ? row : undefined)
                                        row ? pluginContext.masterGrid.onSelectEvent(row, DocumentType.contractor) : pluginContext.masterGrid.onUnselectEvent();
                                        props.onSelect?.(row ? row: undefined)
                                    }}
                                    onMultipleSelect={(rows) => {
                                        setSelectedItems(rows)
                                        props?.onMultipleSelect?.(rows)
                                    }}
                                    onDoubleClick={(row) => { }}
                                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                                    onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                                    onGridRowContextMenuMouseClick={(selectedRow, position) => {
                                        const checkGroupSelected = pluginContext.treeView.selectedFolder !== undefined;
                                        const multiPosition = selectedItems.find(el => el.idGlobal === selectedRow.idGlobal) ? false : true;
                                        const onePosition = (selectedRow.idGlobal === selectedItem?.idGlobal) ? false : true;
                                        const checkGroup =  checkGroupSelected ? (multipleSelect ? multiPosition : onePosition) : true;

                                        return <ContextMenu id={'ctxMenu_row_' + props.gridId} xPos={position.x - 300} yPos={position.y - 140}
                                            items={[{
                                                name: t('general.addToGroup'),
                                                onClick: () => setContextMenuViewState("attachToGroup"),
                                                disabled: multipleSelect ? multiPosition : onePosition,
                                            }, {
                                                name: t('general.moveToGroup'),
                                                disabled: checkGroup,
                                                onClick: () => setContextMenuViewState("moveToGroup")
                                            }, {
                                                name: t('general.removeFromGroup'),
                                                disabled: checkGroup,
                                                onClick: () => setContextMenuViewState("detachFromGroup")
                                            }
                                            ]}>
                                        </ContextMenu>
                                    }} 
                                />
                        }

                    </div>
                </div>
            </div>
        </PluginWrapper>
    </>
}

export default ContractorPluginView