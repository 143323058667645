const KizExchangeDetailedGridPluginSettings: IPluginSettings = {
    name: 'Кизы',
    mnemocode: 'kiz_exchange_detailed_plugin',
    permission: 'KizExchange',
    columns:[{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 150
    },{
        order: 1,
        propertyName: 'sscc',
        propertyType: 'string',
        displayName: 'SSCC',
        visibility: true,
        width: 150
    },{
        order: 2,
        propertyName: 'gtinSGtin',
        propertyType: 'string',
        displayName: 'ГТИНСГТИН',
        visibility: true,
        width: 150
    },{
        order: 3,
        propertyName: 'gtin',
        propertyType: 'string',
        displayName: 'ГТИН',
        visibility: true,
        width: 150
    },{
        order: 4,
        propertyName: 'sGtin',
        propertyType: 'string',
        displayName: 'СГТИН',
        visibility: true,
        width: 150
    },{
        order: 5,
        propertyName: 'docNum',
        propertyType: 'string',
        displayName: 'Номер документа',
        visibility: true,
        width: 150
    },{
        order: 6,
        propertyName: 'docNum',
        propertyType: 'string',
        displayName: 'Номер документа',
        visibility: false,
        width: 150
    },{
        order: 7,
        propertyName: 'operationDate',
        propertyType: 'datetime',
        displayName: 'Дата операции',
        visibility: true,
        width: 150
    },{
        order: 8,
        propertyName: 'stateCode',
        propertyType: 'number',
        displayName: 'Код состояния',
        visibility: true,
        width: 150
    },{
        order: 9,
        propertyName: 'stateName',
        propertyType: 'string',
        displayName: 'Описание состояния',
        visibility: true,
        width: 150
    },{
        order: 10,
        propertyName: 'acceptionType',
        propertyType: 'string',
        displayName: 'Тип приема',
        visibility: true,
        width: 150
    },{
        order: 11,
        propertyName: 'acceptionName',
        propertyType: 'string',
        displayName: 'Название приема',
        visibility: true,
        width: 150
    },{
        order: 12,
        propertyName: 'actionId',
        propertyType: 'number',
        displayName: 'ИД действия',
        visibility: true,
        width: 150
    },{
        order: 13,
        propertyName: 'actionName',
        propertyType: 'string',
        displayName: 'Название действия',
        visibility: true,
        width: 150
    },{
        order: 14,
        propertyName: 'idKizGlobal',
        propertyType: 'string',
        displayName: 'IdKizGlobal',
        visibility: false,
        width: 150
    }
]
}

export default KizExchangeDetailedGridPluginSettings;