import { IDocumentInfoDTO } from "../../libs/coreapi-dto/@types/common";
import { IKizOsuEditDTO } from "../../libs/coreapi-dto/dirs/KizOsu";
import { IKizDTO, IKizStatusDTO, IKizViewDTO, IParsedBarcode, IKizStatusMoveErrorDTO, IKizStatusMoveErrorInDTO, IkizDocumentItemGtinDto } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizDataProvider extends DictionaryDataProvider<IKizViewDTO, IKizDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<IKizViewDTO, IKizDTO>> {
        return this._coreApiService.kizRequest
    }

    getKizViewAsync(idDocument: string, filter: IViewBody, callback?: (data: IKizViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizRequest
            .then(r => r
                .getKizViewAsync(idDocument, filter, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.(e.data.entities, e.data.totalCount);
                    }
                    else
                        throw new Error(e.message.title);
                }, (info) => { testApi?.(info) })
            );
    }

    kizOsuAsync(idDocumentItem: string, dto: IKizOsuEditDTO, callback?: () => void) {
        this._coreApiService.kizRequest
            .then(r => r
                .kisOsuAsync(idDocumentItem, dto, (e) => {
                    if (e.respType === 'isCompleted') {
                        callback?.();
                    }
                    else
                        throw new Error(e.message.title);
                })
            );
    }

    getKizByItem(documentId: string, documentItemId: string, filter: IViewBody, callback?: (data) => void, testApi?: ({ name, time }) => void) {
        this._coreApiService.kizRequest
            .then(r => r.getKizByItem(documentId, documentItemId, filter, e => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data.entities);
                }
                else
                    throw new Error(e.message.title);
            }, (info) => { testApi?.(info) }))
    }
    getKizBySscc(sscc: string, callback?: (data) => void) {
        this._coreApiService.kizRequest
            .then(r => r.getKizBySscc(sscc, e => {
                if (e.respType === 'isCompleted') {
                    callback?.(e.data);
                }
                else
                    throw new Error(e.message.title);
            }))
    }
    getKizStatus(barcode, callback: (e: IKizStatusDTO) => void, errCallback: () => void) {
        this._coreApiService.kizRequest
            .then(r => r.getKizStatus(barcode, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }
    parse(barcode: string, callback: (e: IParsedBarcode) => void): void {
        this._coreApiService.kizRequest.then(r => r.parse(barcode, (e) => {
            if (e.respType === 'isCompleted') {
                callback(e.data)
            } else {
                throw new Error(e.message.title)
            }
        }))
    }

    getKizStatusMoveError(barcode, idDocument, callback: (e: IKizStatusMoveErrorDTO) => void, errCallback: () => void) {
        this._coreApiService.kizRequest
            .then(r => r.getKizStatusMoveError(barcode, idDocument, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

    getIdDocumentItemByGtin(barcode: string, document: IDocumentInfoDTO, idLotGlobal: string, callback: (e: IkizDocumentItemGtinDto) => void, errCallback: () => void) {
        this._coreApiService.kizRequest
            .then(r => r.getIdDocumentItemByGtin(barcode, document, idLotGlobal, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

    getKizStatusMoveErrors(dto: IKizStatusMoveErrorInDTO, callback: (e: IKizStatusMoveErrorDTO[]) => void, errCallback: () => void) {
        this._coreApiService.kizRequest
            .then(r => r.getKizStatusMoveErrors(dto, e => {
                if (e.respType === 'isCompleted') {
                    callback(e.data);
                }
                else
                    errCallback?.()
            }))
    }

}