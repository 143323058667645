const CompaniesGridPluginSettings: IPluginSettings = {
    name: 'Компании',
    mnemocode: 'companies_plugin',
    permission: 'Company',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ID',
        visibility: false,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Компания',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'lockoutEnd',
        propertyType: 'datetime',
        displayName: 'Заблокирован',
        visibility: true,
        width: 150
    }
    ]
}

export default CompaniesGridPluginSettings