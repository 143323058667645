import { v4 as uuidv4 } from "uuid";
import { IDocumentCreatorFormProps } from "../../../../@types/documents";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { LotDataProvider } from "../../../../Services/DataProviders/LotDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import styles from "../styles/ActDeductionCreator.module.scss";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextAreaInput, TextInput } from "../../../../components/controls/inputs";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { LotSelectorPanel } from "../../../../components/lotSelector";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { Accordion, AccordionDeduction } from "../../../../components/controls/accordion";
import { CheckBox } from "../../../../components/controls/checkbox";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import useActDeductionValidator from "./ActDeductionValidator";
import { MoneyFormatComponent, NumberInputWithSideEffect } from "../../../../components/controls/inputs/BaseInput";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { DeductionReasonSelectorModal } from "../../../Dictionaries/DeductionReason";
import { KizDestructionTypeSelectorModal } from "../../../Dictionaries/KizDestructionType/selectorModel/KizDestructionTypeSelectorModal";
import { KizDestructionMethodSelectorModal } from "../../../Dictionaries/KizDestructionMethod/selectorModel/KizDestructionMethodSelectorModal";
import { DeductionReasonDataProvider } from "../../../../Services/DataProviders/DeductionReasonDataProvider";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import ActDeductionItemCreator from "./ActDeductionItemCreator";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { DocumentType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import { IActDeductionItemCreateDTO, IActDeductionItemEditDTO, IActDeductionItemViewDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";
import { FC, useEffect, useReducer, useRef, useState } from "react";
import { IActDeductionGetDTO, IActDeductionUpdateDTO } from "../../../../libs/coreapi-dto/documents/actDeduction";
import { onPharmacyChanged } from "../../../../system/functions/onPharmacyChanged";
import { onStoreChanged } from "../../../../system/functions/onStoreChanged";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { checkStorePharmacy } from "../../../../system/functions/checkStorePharmacy";
import { onCanChangeData } from "../../../../system/functions/onCanChangeData";
import { ActDeductionReducerHandler, ActDeductionReducerInitialState } from "../state/ActDeductionReducer";
import useOperativeReserve from "../../../../components/lotSelector/useOperativeReserve";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { useTranslation } from "react-i18next";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { IKizCounter } from "../state/ActDeductionReducerActionType";
import ActDeductionItemFormPluginSettings from "../pluginSettings/actDeductionItemFormPluginSettings";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { DateTime } from "luxon";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { SelectDocumentModalWindow } from "../../../../components/modalWindows/SelectDocumentModalWindow";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { IDeductionReasonDTO } from "../../../../libs/coreapi-dto/dirs/deductionReason";
import FlexRow from "../../../../components/controls/FlexRow";
import { IKizOsuDTO, IKizOsuEditDTO } from "../../../../libs/coreapi-dto/dirs/KizOsu";

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

type BtSaveOrOk = "ok" | "save";

interface IReturnCreatedItem {
    displayItem: any;
    createdItem: any;
}

export const ActDeductionCreator: FC<IDocumentCreatorFormProps<IActDeductionGetDTO, IActDeductionUpdateDTO>> = (props) => {
    const appContext = useAppContext();

    const deductionReasonDP = new DeductionReasonDataProvider(appContext.coreApiService);
    const lotDP = new LotDataProvider(appContext.coreApiService);
    const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);

    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);

    const [state, dispatch] = useReducer(ActDeductionReducerHandler, ActDeductionReducerInitialState);
    const validator = useActDeductionValidator(state);
    const [viewItemsGF] = useGridFilter();
    const [lotGridFilter, dispatchLotGridFilter] = useGridFilter();
    const [kizModal, setKizModal] = useState(<></>);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const operativeReserve = useOperativeReserve();
    const { t } = useTranslation();
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });
    const errorsT = (value: string) => t("errors." + value);
    const [setTimer, clearTimer]  = useTimeout()

    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });

    const [baseDocument, setBaseDocument] = useState<string>();
    const [showMessageModalValidBaseDocument, setShowMessageModalValidBaseDocument] = useState<boolean>(false);
    const [selectBaseDocument, setSelectBaseDocument] = useState<boolean>(false);

    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const [searchOption, setSearchOption] = useState<SearchOption>(SearchOption.goodsName);
    const [scanData, setScanData] = useState<string>("");

    const tabsContext = useTabsContext();
    const currentIdTab = useRef<string>('');

    const renderKizScanModal = () => {
        if (!state.showKizScan) {
            setKizModal(<></>);
            return;
        }

        if (state.selectedItem) {
            const editItem = state.data.items.find((item) => item.idGlobal === state.selectedItem?.idGlobal);
            const idLotGlobal = editItem?.idLotGlobal;
            const isKiz = editItem?.isKiz || editItem?.isOsu;
            if (idLotGlobal && isKiz) {
                lotDP.getById(idLotGlobal, (data) => {
                    scalingRatioDP.overrideGetById(data.goods.idGlobal, data.scalingRatio.idGlobal, (scalingRatio)=>{
                        setKizModal(
                            <KizScanModal
                                ok={(value: IActDeductionItemCreateDTO, scalingRatio: IScalingRatioDTO) => {
                                    dispatch({
                                        type: "addValueKizScan",
                                        payload: {
                                            ...value,
                                            idGlobal: value.idGlobal,
                                            dateCreated: DateTime.now(),
                                            kizCount: sumKizsCount(value, DocumentType.actDeduction,scalingRatio),
                                        } as IKizCounter,
                                    });
                                    dispatch({ type: "showKizScan", payload: false });
                                }}
                                cancel={() => dispatch({ type: "showKizScan", payload: false })}
                                selectedItem={editItem as IActDeductionItemCreateDTO}
                                documentType={DocumentType.actDeduction}
                                numerator={data.numerator}
                                document={{ idTable: IdTableVariant.ActDeduction, idDocument: state.data.idGlobal }}
                                idLotFrom={editItem.idLotGlobal}
                                scalingRatio={scalingRatio}
                            />
                        );
                    })
                });
            }
        }
    };

    useEffect(() => dispatchLotGridFilter(onStoreChanged(state.store?.idGlobal)), [state.store]);
    useEffect(() => dispatchLotGridFilter(onPharmacyChanged(state.pharmacy?.idGlobal)), [state.pharmacy]);
    useEffect(() => renderKizScanModal(), [state.showKizScan]);
    useEffect(() => dispatch({ type: "calculate" }), [state.data.items]);

    useEffect(() => {
        if (state.selectedItem !== undefined) {
            const editEntity = state.items.find((item) => item.idGlobal === state?.selectedItem?.idGlobal);

            if (editEntity === undefined) {
                return;
            }
            dispatch({ type: "calculate" });
        }

        // if (state.selectedItem === undefined) {
        //     dispatch({ type: "calculateClear" });
        // }
    }, [state.selectedItem]);

    const addItemsFilter = () => {
        let arr: IItemFilter[] = [];
        props.document?.items?.forEach((x) => {
            lotDP.remain(x.lot.idGlobal, props.idGlobal as string, (response) => {
                arr.push({
                    idItem: x.idGlobal,
                    quantityMax: response,
                    quantityMin: 0,
                });
            });
        });
        setItemsFilter(arr);
    };

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }

        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: IdTableVariant.ActDeduction,
                idDocument: props.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [state.data.documentDate, state.data.idDeductionReasonGlobal, state.data.idKizDestructionMethod, state.data.idKizDestructionType, state.data.idStoreGlobal, state.data.items]);

    useEffect(() => {
        if ((props.variant == "edit") && props.document) {

            lockingDocuments.check(props.idGlobal as string, (e)=>{
                if (e) setOnlyRead(true)
                else
                    lockingDocuments.send({
                        idTable: IdTableVariant.ActDeduction,
                        idDocument: props.idGlobal as string,
                    }, (e)=> {
                        setIdLockingDocument(e)
                    });
            })

            dispatch({ type: "autoCompleteAll", payload: props.document });
            addItemsFilter();
            setItemsValidation(
                props.document.items.map((x) => ({
                    err: false,
                    message: "",
                    idGlobal: x.idGlobal,
                }))
            );
            if (props.document.baseDocumentInfo?.idTable === IdTableVariant.Invoice) {
                invoiceDP.getById(props.document.baseDocumentInfo.idDocument, (entity) => setBaseDocument(entity.displayName))
            }
        } else if (props.variant === "create" || "copy") {
            dispatch({ type: "autoCompleteOnlyCreate", payload: props.idGlobal ?? uuidv4() });
        }
    }, []);

    const operativeReserveCreateHandle = (edit: IActDeductionItemEditDTO, onSuccess: () => void) => {
        operativeReserve.send(
            {
                idTable: IdTableVariant.ActDeduction,
                idLot: edit.idLotGlobal,
                idDocument: props.idGlobal as string,
                idDocumentItem: edit.idGlobal,
                quantity: edit.quantity,
            },
            () => onSuccess()
        );
    };
    const operativeReserveDeleteHandle = (idGlobal: string, onSuccess: (idGlobal: string) => void) => {
        const itemForDelete = state.items.find(i => i.idGlobal === idGlobal)

        if (!itemForDelete) {
            onSuccess(idGlobal)
            return;
        }

        operativeReserve.delete(
            {
                idTable: IdTableVariant.ActDeduction,
                idLot: itemForDelete?.idLotGlobal,
                idDocument: props.idGlobal as string,
                idDocumentItem: idGlobal
            },
            () => onSuccess(idGlobal)
        );
    };


    const changeRow = (entity: IActDeductionItemViewDTO ,quantity: number, kizOsuDto?: IKizOsuEditDTO) => {
            const editEntity = {
                idGlobal: entity?.idGlobal,
                lotName: entity?.lotName,
                idLotGlobal: entity?.idLotGlobal,
                quantity: quantity,
                retailCostInfo: {
                    vat: entity?.retailVat,
                    price: entity?.retailPrice,
                    vatPrice: entity?.retailVatPrice,
                    priceIncVat: entity?.retailPriceIncVat,

                    sum: +((entity.retailPrice ?? 0) * +quantity).toFixed(2),
                    vatSum: +((entity.retailVatPrice ?? 0) * +quantity).toFixed(2),
                    sumIncVat: +((entity.retailPriceIncVat ?? 0) * +quantity).toFixed(2),

                    adprice: entity?.retailAdprice,
                },
                supplierCostInfo: {
                    vat: entity?.supplierVat,
                    price: entity?.supplierPrice,
                    vatPrice: entity?.supplierVatPrice,
                    priceIncVat: entity?.supplierPriceIncVat,

                    sum: +((entity.supplierPrice ?? 0) * +quantity).toFixed(2),
                    vatSum: +((entity.supplierVatPrice ?? 0) * +quantity).toFixed(2),
                    sumIncVat: +((entity.supplierPriceIncVat ?? 0) * +quantity).toFixed(2),

                    adprice: entity?.supplierAdprice,
                },
                isKiz: entity?.isKiz,
                isKizBox: entity?.isKizBox,
                kizCount: entity?.kizCount,
                kizBoxCount: entity?.kizBoxCount,
                dateCreated: DateTime.now(),
                isOsu: entity.isOsu,
                kizOsuDto: kizOsuDto
            } as IActDeductionItemCreateDTO;

            const viewEntity = {
                idGlobal: entity?.idGlobal,
                lotName: entity?.lotName,
                idLotGlobal: entity?.idLotGlobal,
                idDocumentGlobal: entity?.idDocumentGlobal,
                quantity: quantity,

                retailVat: entity?.retailVat,
                retailPrice: entity?.retailPrice,
                retailVatPrice: entity?.retailVatPrice,
                retailPriceIncVat: entity?.retailPriceIncVat,

                retailSum: +((entity.retailPrice ?? 0) * +quantity).toFixed(2),
                retailVatSum: +((entity.retailVatPrice ?? 0) * +quantity).toFixed(2),
                retailSumIncVat: +((entity.retailPriceIncVat ?? 0) * +quantity).toFixed(2),

                retailAdprice: entity?.retailAdprice,
                supplierVat: entity?.supplierVat,
                supplierPrice: entity?.supplierPrice,
                supplierVatPrice: entity?.supplierVatPrice,
                supplierPriceIncVat: entity?.supplierPriceIncVat,

                supplierSum: +((entity.supplierPrice ?? 0) * +quantity).toFixed(2),
                supplierVatSum: +((entity.supplierVatPrice ?? 0) * +quantity).toFixed(2),
                supplierSumIncVat: +((entity.supplierPriceIncVat ?? 0) * +quantity).toFixed(2),

                supplierAdprice: entity?.supplierAdprice,
                isKiz: entity?.isKiz,
                isKizBox: entity?.isKizBox,
                dateCreated: DateTime.now(),
                isOsu: entity.isOsu,
            } as IActDeductionItemViewDTO;

            return {editEntity,viewEntity}
    };

    const isValidDocument = (items:IActDeductionItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0)
        {
            flag = false;
            showMessage();
        }
        return flag;
    }
    const calcRow =  (value : IChangeDataGrid) => { 
        const oldView = state.items.find((item) => item.idGlobal === value.idGlobal);
        const oldEntity = state.data.items.find(c => c.idGlobal === value.idGlobal);
        if (oldView) {
            let result = changeRow(oldView,value.value as number,oldEntity?.kizOsuDto)
            operativeReserveCreateHandle(result.editEntity, () => {
                dispatch({ type: "editViewWithItem", payload: {
                    item: result?.editEntity,
                    viewItem: result?.viewEntity}
                });
            });
        }
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActDeduction,
            idDocument: state.data.idGlobal as string,
        });

        props.ok(state.data);
    };

    const onCancel = () => {
        lockingDocuments.delete({
            idTable: IdTableVariant.ActDeduction,
            idDocument: state.data.idGlobal as string,
        });

        props.cancel();
    };

    function isExistsKizItem(): boolean
    {
        let flag = false;
        state.items?.forEach(x=>{
            if (x.isKiz) flag = true;
        })
        return flag;
    }

    const GetReasonDto = (idGlobal, callback: (value) => void)=> {

        if (idGlobal)
            deductionReasonDP.getView({
                    numberPerPage: 15,
                    pageNumber: 1,
                    columnFilters: [
                        {
                            name: "idGlobal",
                            operator: "Eq",
                            value: idGlobal,
                        },
                    ],
                },
                (data) => {
                    callback(data[0]);
                }
            );
        else
            callback(null);
    }
  
    const isDisabledEditPanel = () => {
        const item = state.data.items.find((item) => item.idGlobal == state?.selectedItem?.idGlobal)
        if ((onlyRead) || !((item?.isKiz) || (item?.isOsu))) return true
        return false
    }

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (validator.isValid()
                            && isValidDocument(state.data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true
                        ) {
                            GetReasonDto(state.reason?.idGlobal, (data)=>{
                                if (isExistsKizItem() && !data?.code)
                                {
                                    renderGlobalAlert({ variant: "error", statusCode: 404, title: errorsT("actDeductionReasonError") });
                                }
                                else
                                {
                                    let quantityNoKiz = 0;
                                    state.data.items.forEach((x) => {
                                        if (x.isKiz === true && x.kizs?.length !== x.kizCount) {
                                            setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" });
                                            quantityNoKiz++
                                        }
                                    })
                                    if (quantityNoKiz === 0) {
                                        onOkAction();
                                    }
                                }
                            })
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: () => {
                        if (validator.isValid()
                            && isValidDocument(state.data.items, ()=> setShowMessageModalValidDocument({message: errorsT("validDocument"), show: true })) === true
                        ) {
                            GetReasonDto(state.reason?.idGlobal, (data)=>{
                                if (isExistsKizItem() && !data?.code)
                                {
                                    renderGlobalAlert({ variant: "error", statusCode: 404, title: errorsT("actDeductionReasonError") });
                                }
                                else
                                {
                                    let quantityNoKiz = 0;
                                    state.data.items.forEach((x) => {
                                        if (x.isKiz === true && x.kizs?.length !== x.kizCount) {
                                            setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" });
                                            quantityNoKiz++
                                        }
                                    })
                                    if (quantityNoKiz === 0) {
                                        props.save(state.data);
                                    }
                                }
                            })
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                cancel={{ onClick: onCancel }}
            >
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div>
                            <GridWrapper cols={3}>
                                <div className={styles.store}>
                                    <StoreSelectorPanel
                                        we
                                        required
                                        compactMode={true}
                                        change={onCanChangeData(state.items.length)}
                                        storeFromLot={state.storeFromLot ?? undefined}
                                        contractor={{
                                            label: "Аптека",
                                            onSelect: (value) => value && dispatch({ type: "setPharmacy", payload: value }),
                                            onClear: () => dispatch({ type: "setPharmacy", payload: {} as IEntitySimpleDTO }),
                                            disabled: onlyRead ? true : false,
                                            treeViewCheckDirectoryType:'pharmacy'
                                        }}
                                        store={{
                                            label: "Склад",
                                            value: state.store,
                                            onSelect: (value) => dispatch({ type: "setStore", payload: value }),
                                            onClear: () => dispatch({ type: "clearStore" }),
                                            error: validator.errors.idStoreGlobal,
                                            onFocus: () => validator.setErrors({ ...validator.errors, idStoreGlobal: undefined }),
                                            disabled: onlyRead ? true : false
                                        }}
                                        notAutoComplete={props.variant === "edit" || props.variant === "copy"}
                                        documentStore={props.document?.store}
                                    />
                                </div>
                                <TextInput 
                                    key="mnemocode" 
                                    label="Номер" 
                                    value={props.variant === "edit" ? props.document?.mnemocode : ""} 
                                    disabled 
                                />
                                <DateInput
                                    required
                                    disabled={onlyRead ? true : false}
                                    label="Дата"
                                    value={state.data.documentDate}
                                    error={validator.errors.documentDate}
                                    onBlur={(value) => {
                                        if (value) {
                                            dispatch({ type: "setDocumentDate", payload: value });
                                        }
                                    }}
                                />
                                <TextInput label="Приказ" disabled={true} />
                                <DateInput
                                    required
                                    disabled={onlyRead ? true : false}
                                    label="Дата приказа"
                                    value={state.data.orderDate}
                                    onBlur={(value) => {
                                        if (value) {
                                            dispatch({ type: "setOrderDate", payload: value });
                                        }
                                    }}
                                />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <GridSelectorInput
                                    disabled={onlyRead ? true : false}
                                    selectorModalJsx={DeductionReasonSelectorModal}
                                    id={uuidv4()}
                                    selectedEntity={state.reason}
                                    label={"Причина списания"}
                                    onSelect={(value) => {
                                        if (value !== undefined) {
                                            GetReasonDto(value.idGlobal, (data)=>{
                                                if (isExistsKizItem() && !data?.code)
                                                {
                                                    renderGlobalAlert({ variant: "error", statusCode: 404, title: errorsT("actDeductionReasonNotCode") });
                                                    dispatch({ type: "clearReason" })
                                                }
                                                else
                                                {
                                                    const mode = data?.mode;
                                                    dispatch({ type: "setReason", payload: value });

                                                    if (mode === "USER" || mode === "EXIT") {
                                                        dispatch({ type: "setKizDestructionOp", payload: false });
                                                    } else if (mode === "DROP") {
                                                        dispatch({ type: "setKizDestructionOp", payload: true });
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    onClear={() => {
                                        dispatch({ type: "clearReason" })
                                    }}
                                />
                                <DictionaryInput 
                                    placeholder="-- Не выбрано --" 
                                    value={baseDocument} 
                                    label={t("documents.movement.baseDocument")}
                                    onMoreClick={() => {
                                        !state.store?.idGlobal ? setShowMessageModalValidBaseDocument(true) : setSelectBaseDocument(true);
                                    }}
                                    disabled={onlyRead ? true : false}
                                />
                                <CheckBox
                                    id={`destruction-${uuidv4()}`}
                                    label="Уничтожение"
                                    defaultChecked={state.data.kizDestructionOp}
                                    onChanged={(value) => dispatch({ type: "setKizDestructionOp", payload: value })}
                                    disabled={onlyRead ? true : false}
                                />
                            </GridWrapper>
                            <AccordionDeduction opened={state.data.kizDestructionOp} caption={"Уничтожение"} id={`reason-${uuidv4()}`}>
                                <GridWrapper cols={3}>
                                    <GridSelectorInput
                                        disabled={!state.data.kizDestructionOp}
                                        selectorModalJsx={KizDestructionTypeSelectorModal}
                                        id={uuidv4()}
                                        selectedEntity={state.kizDestructionType}
                                        label={"Основание передачи на уничтожение"}
                                        onSelect={(value) => dispatch({ type: "setKizDestructionType", payload: value })}
                                        onClear={() => dispatch({ type: "clearKizDestructionType" })}
                                    />
                                    <GridSelectorInput
                                        disabled={!state.data.kizDestructionOp}
                                        selectorModalJsx={ContractorSelectorModal}
                                        id={uuidv4()}
                                        label={"Уничтожающая организация"}
                                        selectedEntity={state.kizMoveDestructionContractor}
                                        onSelect={(value) => dispatch({ type: "setKizMoveDestructionContractor", payload: value })}
                                        onClear={() => dispatch({ type: "clearKizMoveDestructionContractor" })}
                                    />
                                    <GridSelectorInput
                                        disabled={!state.data.kizDestructionOp}
                                        selectorModalJsx={KizDestructionMethodSelectorModal}
                                        id={uuidv4()}
                                        selectedEntity={state.kizDestructionMethod}
                                        label={"Способ уничтожения"}
                                        onSelect={(value) => dispatch({ type: "setKizDestructionMethod", payload: value })}
                                        onClear={() => dispatch({ type: "clearKizDestructionMethod" })}
                                    />
                                </GridWrapper>
                                <GridWrapper cols={3}>
                                    <TextInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="№ договора передачи на уничтожение"
                                        value={state.data.kizMoveDestructionDocNum}
                                        onChange={(value) => dispatch({ type: "setKizMoveDestructionDocNum", payload: value })}
                                    />
                                    <DateInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="Дата договора передачи на уничтожение"
                                        value={state.data.kizMoveDestructionDocDate}
                                        onBlur={(value) => value && dispatch({ type: "setKizMoveDestructionDocDate", payload: value })}
                                    />
                                    <TextInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="№ акта уничточтожения"
                                        value={state.data.kizDestructionDocNum}
                                        onChange={(value) => dispatch({ type: "setKizDestructionDocNum", payload: value })}
                                    />
                                </GridWrapper>
                                <GridWrapper cols={3}>
                                    <TextInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="№ акта передачи на уничтожение"
                                        value={state.data.kizMoveDestructionActNum}
                                        onChange={(value) => dispatch({ type: "setKizMoveDestructionActNum", payload: value })}
                                    />
                                    <DateInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="Дата акта передачи на уничтожение"
                                        value={state.kizMoveDestructionActDate}
                                        onBlur={(value) => value && dispatch({ type: "setKizMoveDestructionActDate", payload: value })}
                                    />
                                    <DateInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="Дата акта уничтожения"
                                        value={state.data.kizDestructionDocDate}
                                        onBlur={(value) => value && dispatch({ type: "setKizDestructionDocDate", payload: value })}
                                    />
                                </GridWrapper>
                                <GridWrapper cols={3}>
                                    <TextInput
                                        disabled={!state.data.kizDestructionOp}
                                        label="Адрес ФИАС"
                                        value={state.data.kizMoveDestructionFiasAoguid}
                                        onChange={(value) => dispatch({ type: "setKizMoveDestructionFiasAoguid", payload: value })}
                                        error={validator.errors.kizMoveDestructionFiasAoguid}
                                    />
                                </GridWrapper>
                            </AccordionDeduction>
                            <Accordion opened={false} caption={"Комментарий"} id={`comment-${uuidv4()}`}>
                                <GridWrapper cols={1}>
                                    <TextAreaInput 
                                        label="Комментарий" 
                                        value={state.data.comment} 
                                        onChange={(value) => dispatch({ type: "setComment", payload: value })}
                                        disabled={onlyRead ? true : false}
                                    />
                                </GridWrapper>
                            </Accordion>
                            <FlexRow className={styles.lotPanel}>
                                <PositionEditPanel
                                    kiz={{
                                        disabled: isDisabledEditPanel(),
                                        onClick: () => {
                                            dispatch({ type: "showKizScan", payload: true })
                                        },
                                    }}
                                    edit={{
                                        onClick: () => {
                                            const editItemLot = state.data.items.find((i) => i.idGlobal === state.selectedItem?.idGlobal);
                                            if (editItemLot && editItemLot.idLotGlobal) {
                                                lotDP.getById(editItemLot.idLotGlobal, (value) => {
                                                    dispatch({
                                                        type: "editItemLot",
                                                        payload: {
                                                            idGlobal: editItemLot.idGlobal,
                                                            idInvoiceOutGlobal: props.idGlobal,
                                                            lot: value,
                                                            edit: editItemLot,
                                                        },
                                                    });
                                                });
                                            }
                                        },
                                        disabled: state.selectedItem && !onlyRead ? false : true,
                                    }}
                                    delete={{
                                        onClick: () => {
                                            if (state.selectedItem && state.selectedItem?.idGlobal) {
                                                operativeReserveDeleteHandle(state.selectedItem?.idGlobal, (idGlobal) => {
                                                    dispatch({ type: "deleteViewWithItem", payload: idGlobal  });

                                                    const itemsValid = itemsValidation.filter((item) => item.idGlobal !== idGlobal);
                                                    const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== idGlobal);
                                                    setItemsValidation(itemsValid)
                                                    setItemsFilter(itemsFilterValid)
                                                })
                                            }
                                        },
                                        disabled: state.selectedItem && !onlyRead ? false : true,
                                    }}
                                />
                                <LotSelectorPanel
                                    disabled={onlyRead ? true : false}
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    gridFilter={lotGridFilter}
                                    dispatchGridFilter={dispatchLotGridFilter}
                                    onSelect={(value) => {
                                        if (!checkStorePharmacy(value, state.store, state.pharmacy)) return;
                                        dispatch({ type: "addItemLot", payload: value });
                                    }}
                                    setScanData={setScanData}
                                    setSearchOption={setSearchOption}
                                />
                            </FlexRow>
                            <ChangeableGrid
                                documentStateProc={onlyRead}
                                autoSelect
                                gridId={uuidv4()}
                                data={state.items}
                                dataProvider={lotDP}
                                selectedItem={state.selectedItem}
                                filter={viewItemsGF}
                                itemsFilter={itemsFilter}
                                itemsValidation={itemsValidation}
                                isSortByCreatedDate
                                setItemsValidation={(value) => {
                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item))
                                    setItemsValidation(newItemsValidation)
                                }}
                                totalCount={state.items.length}
                                onSelect={(row) => dispatch({ type: "setSelectedItem", payload: row })}
                                plugin={ActDeductionItemFormPluginSettings}
                                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                                onSort={(i) => dispatchLotGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchLotGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchLotGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchLotGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                onColumnEnter={(value: IChangeDataGrid) => clearTimer(() => calcRow(value))}
                                onChangeData={(value: IChangeDataGrid) => setTimer(() => calcRow(value))}
                            />
                        </div>
                        <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                            <div className={styles.accordionWrapper}>
                                <GridWrapper cols={4}>
                                    <MoneyFormatComponent disabled label="Сумма НДС, розн." value={state.retailCostInfo?.vatSum} />
                                    <MoneyFormatComponent disabled label="Сумма НДС, пост." value={state.supplierCostInfo?.vatSum} />
                                    <MoneyFormatComponent disabled label="Сумма с НДС, розн." value={state.retailCostInfo?.sumIncVat} />
                                    <MoneyFormatComponent disabled label="Сумма с НДС, пост." value={state.supplierCostInfo?.sumIncVat} />
                                </GridWrapper>
                            </div>
                        </Accordion>
                    </div>
                </div>
                {state.addItemLot && (
                    <ActDeductionItemCreator
                        idGlobal={uuidv4()}
                        idActDeductionGlobal={props.idGlobal as string}
                        lot={state.addItemLot}
                        scanData={searchOption === SearchOption.kizOrKizBox ? scanData : undefined}
                        ok={(editEntity, viewEntity) => {
                            lotDP.remain(editEntity.idLotGlobal, props.idGlobal as string, (response) => {
                                setItemsFilter([
                                    ...itemsFilter,
                                    {
                                        idItem: editEntity.idGlobal,
                                        quantityMax: response,
                                        quantityMin: 0,
                                    },
                                ]);
                            });
                            setItemsValidation([
                                ...itemsValidation,
                                {
                                    err: false,
                                    message: "",
                                    idGlobal: editEntity.idGlobal,
                                },
                            ]);

                            const oldView = state.items.find((item) => item.idLotGlobal === viewEntity.idLotGlobal);
                            const oldEntity = state.data.items.find(c => c.idGlobal === viewEntity.idGlobal);
                            if (oldView && oldView.idLotGlobal) {
                                let result = changeRow(oldView, oldView.quantity + editEntity.quantity,oldEntity?.kizOsuDto)
                                operativeReserveCreateHandle(result.editEntity, () =>
                                    dispatch({ type: "editViewWithItem", payload: { item: result.editEntity, viewItem: result.viewEntity } })
                                )
                            }
                            else {
                                operativeReserveCreateHandle(editEntity, () =>
                                    dispatch({ type: "AddViewWithItem", payload: { item: editEntity, viewItem: viewEntity } })
                                )
                            }

                            state.addItemLot?.store && dispatch({ type: "setStoreFromLot", payload: state.addItemLot?.store });
                        }}
                        cancel={() => dispatch({ type: "clearItemLot" })}
                    />
                )}
                {state.editItemLot && (
                    <ActDeductionItemCreator
                        idGlobal={state.editItemLot.idGlobal}
                        idActDeductionGlobal={props.idGlobal as string}
                        lot={state.editItemLot?.lot}
                        edit={state.editItemLot?.edit}
                        ok={(editEntity, viewEntity) => {
                            let result = changeRow(viewEntity,viewEntity.quantity,editEntity.kizOsuDto)
                            operativeReserveCreateHandle(editEntity, () =>
                                dispatch({ type: "editViewWithItem", payload: { item: result.editEntity, viewItem: result.viewEntity } })
                            )}
                        }
                        cancel={() => dispatch({ type: "clearEditItemLot" })}
                    />
                )}

                {kizModal}

                {showMessageModal.show && (
                    <MessageModalWindow
                        message={showMessageModal.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(state.data);
                            },
                        }}
                        cancel={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                            },
                        }}
                        primary
                    />
                )}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {showMessageModalValidBaseDocument && 
                    <MessageModalWindow
                        message={t("errors.noStoreForBaseDocument")}
                        ok={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                        cancel={{ onClick: () => setShowMessageModalValidBaseDocument(false) }}
                    />
                }

                {selectBaseDocument && (
                    <SelectDocumentModalWindow
                    idStoreGlobal={state.data.idStoreGlobal}
                    dateDocument={state.data.documentDate}
                        ok={(lots, document) => {
                            const values: IReturnCreatedItem[] = lots.map((x) => {
                                const idGlobal: string = uuidv4();
                                return {
                                    displayItem: {
                                        idGlobal: idGlobal,
                                        idDocumentGlobal: props.idGlobal,
                                        idLotGlobal: x.idGlobal,
                                        quantity: x.quantityRem,
                                        retailVat: x.retailCostInfo?.vat ?? 0,
                                        retailPrice: x.retailCostInfo?.price ?? 0,
                                        retailVatPrice: x.retailCostInfo?.vat ?? 0,
                                        retailPriceIncVat: x.retailCostInfo?.priceIncVat ?? 0,
                                        retailSum: x.retailCostInfo?.sum ?? 0,
                                        retailVatSum: x.retailCostInfo?.vat ?? 0,
                                        retailSumIncVat: x.retailCostInfo?.sumIncVat ?? 0,
                                        retailAdprice: x.retailCostInfo.adprice,
                                        supplierVat: x.supplierCostInfo?.vat ?? 0,
                                        supplierPrice: x.supplierCostInfo?.price ?? 0,
                                        supplierVatPrice: x.supplierCostInfo?.vat ?? 0,
                                        supplierPriceIncVat: x.supplierCostInfo?.priceIncVat ?? 0,
                                        supplierSum: x.supplierCostInfo?.sum ?? 0,
                                        supplierVatSum: x.supplierCostInfo?.vatSum ?? 0,
                                        supplierSumIncVat: x.supplierCostInfo?.sumIncVat ?? 0,
                                        supplierAdprice: x.supplierCostInfo.adprice,
                                        isKiz: x.isKiz,
                                        isKizBox: x.isKizBox,
                                        lotName: x.lotName,
                                        supplier: x.supplier,
                                        dateCreated: x.docDate,
                                        kizCount: x.isKiz === false ? undefined : 0,
                                        kizBoxCount: x.isKizBox === false ? undefined : 0,
                                        isOsu: x.kizOsuDto?.barcode !== undefined
                                    },
                                    createdItem: {
                                        idGlobal: idGlobal,
                                        idGoodsGlobal: x.goods.idGlobal,
                                        idScalingRatioGlobal: x.scalingRatio.idGlobal,
                                        idLotGlobal: x.idGlobal,
                                        idSupplierGlobal: x.idSupplierGlobal,
                                        contractorPricePerUnit: 0,
                                        price: 0,
                                        priceVat: x.retailCostInfo?.vat ?? 0,
                                        vatSup: 0,
                                        isKiz: x.isKiz,
                                        retailCostInfo: x.retailCostInfo,
                                        supplierCostInfo: x.supplierCostInfo,
                                        kizs: [],
                                        kizBoxes: [],
                                        quantity: x.quantityRem ?? 0,
                                        isOsu: x.kizOsuDto?.barcode !== undefined,
                                        kizOsuDto: x?.kizOsuDto
                                    },
                                };
                            });
                            setBaseDocument(document.displayName);
                            state.items = values.map((x) => x.displayItem);
                            state.data = {
                                ...state.data,
                                baseDocumentInfo: { idTable: document.idTable, idDocument: document.idGlobal },
                                items: values.map((x) => x.createdItem)
                            };
                            values.map((x) => {
                                operativeReserveCreateHandle(x.createdItem, () =>
                                    dispatch({ type: "editViewWithItem", payload: { item: x.createdItem, viewItem: x.displayItem } })
                                )
                            })
                            setSelectBaseDocument(false);
                        }}
                        cancel={{ onClick: () => setSelectBaseDocument(false) }}
                        onlyProc
                    />
                )}

            </BaseDocumentCreator>
        </>
    );
};

export default ActDeductionCreator;
