import React from 'react'
import { ImportProcessModalWindow } from '../../../../components/modalWindows/ImportProcessModalWindow';

interface IImportInvoicesInProcessModalWindowProps {
    cancel: () => void
}
export const ImportInvoicesInProcessModalWindow: React.FC<IImportInvoicesInProcessModalWindowProps> = props => {
    return (
        <ImportProcessModalWindow
            ok={{ onClick: () => { props.cancel() } }}
            cancel={{ onClick: () => { props.cancel() } }}
        >
            <span>Идет процесс импортирования...</span>
        </ImportProcessModalWindow>
    )
};