import {v4 as uuidv4} from 'uuid';
import {IEntitySimpleDTO} from '../../../../../libs/coreapi-dto/@types/common';
import {IInvoiceItemCreateDTO} from '../../../../../libs/coreapi-dto/documents/invoiceItem';
import {IDisplayItem, IGosContractFields} from '../InvoiceUpdateForm';
import InvoiceItemCreator from './InvoiceItemCreator';
import { IContractorDTO } from '../../../../../libs/coreapi-dto/dirs/contractor';
import { CreateVariant } from '../../../../MasterTabsProvider';
import { DateTime } from 'luxon';
import i18n from '../../../../../i18n';

export const InvoiceItemCreatorTab = (
  data: {
    goods: IEntitySimpleDTO;
    item?: IInvoiceItemCreateDTO;
    displayItem: IDisplayItem;
    idPricingModel: string;
    idSupplier: string;
    idStore: string;
    gosContractFields: IGosContractFields;
    scalingRatio: IEntitySimpleDTO;
    currentSupplier?: IContractorDTO;
    currentPharmacy?: IContractorDTO;
    variant?: CreateVariant;
    documentStateProc?: boolean;
    documentDate: DateTime
  },
  parentCallback?: (e) => void
  ): ITab => {
    return {
    id: uuidv4(),
    title: `${i18n.t("documents.invoice.itemPrefix")} — ${data?.goods?.displayName}`,
    view: {
      content: (
        <InvoiceItemCreator
          goods={data.goods}
          parentCallback={parentCallback}
          idPricingModel={data.idPricingModel}
          idSupplier={data.idSupplier}
          idStore={data.idStore}
          scalingRatio={data.scalingRatio}
          item={data.item}
          displayItem={data.displayItem}
          gosContractFields={data.gosContractFields}
          currentSupplier={data.currentSupplier}
          currentPharmacy={data.currentPharmacy}
          variant={data.variant}
          documentStateProc={data.documentStateProc}
          documentDate={data.documentDate}
        />
      ),
    },
  };
};

export default InvoiceItemCreatorTab;
