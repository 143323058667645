export const ActDeductionItemFormPluginSettings: IPluginSettings = {
    name: "Акт списания: Позиции",
    mnemocode: "act_deduction_item_form_plugin",
    permission: "InvoiceOut",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "lotName",
            propertyType: "string",
            displayName: "Партия",
            visibility: true,
            width: 200,
        },
        {
            order: 2,
            propertyName: "supplier",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 3,
            propertyName: "quantity",
            propertyType: "number",
            displayName: "Кол-во",
            visibility: true,
            width: 100,
            changeable: true,
        },
        {
            order: 4,
            propertyName: "retailVat",
            propertyType: "number",
            displayName: "Ставка НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 5,
            propertyName: "retailPrice",
            propertyType: "number",
            displayName: "Цена б/НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 6,
            propertyName: "retailVatPrice",
            propertyType: "number",
            displayName: "НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 7,
            propertyName: "retailPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 8,
            propertyName: "retailSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 9,
            propertyName: "retailVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 10,
            propertyName: "retailSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 11,
            propertyName: "retailAdprice",
            propertyType: "number",
            displayName: "Наценка, розн.",
            visibility: true,
            width: 100,
        },
        {
            order: 12,
            propertyName: "supplierVat",
            propertyType: "number",
            displayName: "Ставка НДC, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 13,
            propertyName: "supplierPrice",
            propertyType: "number",
            displayName: "Цена б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 14,
            propertyName: "supplierVatPrice",
            propertyType: "number",
            displayName: "НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 15,
            propertyName: "supplierPriceIncVat",
            propertyType: "number",
            displayName: "Цена с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 16,
            propertyName: "supplierSum",
            propertyType: "number",
            displayName: "Сумма б/НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 17,
            propertyName: "supplierVatSum",
            propertyType: "number",
            displayName: "Сумма НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 18,
            propertyName: "supplierSumIncVat",
            propertyType: "number",
            displayName: "Сумма с НДС, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 19,
            propertyName: "supplierAdprice",
            propertyType: "number",
            displayName: "Наценка, пост.",
            visibility: true,
            width: 100,
        },
        {
            order: 20,
            propertyName: "isKiz",
            propertyType: "boolean",
            displayName: "Ввод КИЗ?",
            visibility: true,
            width: 100,
        },
        {
            order: 21,
            propertyName: "isKizBox",
            propertyType: "boolean",
            displayName: "Короб",
            visibility: true,
            width: 100,
   		},
        {
            order: 22,
            propertyName: 'kizCount',
            propertyType: 'number',
            displayName: 'Кол-во отсканированных кизов',
            visibility: true,
            width: 150
        },
        {
            order: 23,
            propertyName: 'isGnvls',
            propertyType: "boolean",
            displayName: "ЖНВЛС?",
            visibility: false,
            width: 100,
        },
        {
            order: 24,
            propertyName: 'isOsu',
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 100,
        }
    ],
    detailsPlugins: [],
};

export default ActDeductionItemFormPluginSettings;
