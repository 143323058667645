export enum ReportFilterDataType{
    String,
    //PluginCode,
    IdTable,
    GroupName,
    Boolean,
    DateTime,
    Float,//ToDo Проверить как задаются значения по-умолчанию и min max?
    Integer,//ToDo Проверить как задаются значения по-умолчанию и min max?
    MultiPlugin,
    SinglePlugin,
    Period,
    FilePath,
    FolderPath,
    Combo,
    CheckList,
    RadioGroup,//ToDo
    ComboCondition,
    DateCondition,
    DateConditionPeriod
}