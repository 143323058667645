import { FC, useEffect, useState } from "react";
import classNames from "classnames"
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { ICreateUser, IUserViewDTO } from "../../../../libs/coreapi-dto/service/user";
import { UserDataProvider } from "../../../../Services/DataProviders/UserDataProvider";
import useGridFilter, { DefaultGridFilter } from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { CreateUserModal, TypeOpen } from "../Components/modal/user/CreateUserModal";
import { UserCommandPanel } from "../Components/modal/user/UserPanel";
import styles from '../../../styles/index.module.scss'
import { UserRolesModal } from "../Components/modal/roles/UserRolesModal";
import { v4 as uuidv4 } from 'uuid';
import { IRoleViewDTO } from "../../../../libs/coreapi-dto/service/role";
import { UserRolePanel } from "../Components/modal/roles/UserRolePanel";
import { RoleDataProvider } from "../../../../Services/DataProviders/RoleDataProvider";
import RoleGridMinimalPluginSettings from "../../Role/RoleGridMinimalPluginSettings";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../../../components/spiner/Spinner";

export const UserGridPluginView: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userDataProvider = new UserDataProvider(appCtx.coreApiService);
    const roleDataProvider = new RoleDataProvider(appCtx.coreApiService);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter({ ...DefaultGridFilter, numberPerPage: 15, fieldOrderBy: `ShortName`});
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [data, setData] = useState<IUserViewDTO[]>([]);
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [typeOpen, setTypeOpen] = useState<TypeOpen>('Create');
    const [selectedUser, setSelectedUser] = useState<IUserViewDTO | null>(null);
    const [userRoles, setUserRoles] = useState<IRoleViewDTO[]>([])
    const [selectedRoleItem, setSelectedRoleItem] = useState<IGridRow>();
    const [selectedRole, setSelectedRole] = useState<IRoleViewDTO | null>(null)
    const [roleGridFilter, dispatchRoleGridFilter] = useGridFilter();
    const [openAddRole, setOpenAddRole] = useState<boolean>(false);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const { t } = useTranslation();
    const [clearSig, setClearSig] = useState<boolean>(false);

    const refresh = () => {
        setSelectedItem(undefined);
        setSelectedUser(null);
        setViewState('refresh');
    }

    const blockUser = () => {
        userDataProvider.blockUser(selectedItem?.idGlobal as string, () => {
            refresh();
        })
    }

    const unBlockUser = () => {
        userDataProvider.unBlockUser(selectedItem?.idGlobal as string, () => {
            refresh();
        })
    }

    const removeRole = () => {
        if (selectedItem && selectedRole) {
            userDataProvider.removeRole(selectedItem.idGlobal, selectedRole.name, () => {
                getUserRoles();
                setSelectedRoleItem(undefined);
                setSelectedRole(null);
            })
        }
    }

    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true);
            setClearSig(!clearSig);
            setTotalCount(0);
            setData([]);

            dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: 1 }});
            searchUserWithCustomWhereFilter("");

            loadData();

            setViewState('view');
        }
    }, [viewState])

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        try {
            userDataProvider.getView(gridFilter, (entities, totalCount) => {
                setData([...entities.map(x => {
                    return {
                        idGlobal: x.id,
                        fullName: x.fullName,
                        shortName: x.shortName,
                        userName: x.userName,
                        email: x.email,
                        phoneNumber: x.phoneNumber,
                        companyName: x.companyName,
                        lockoutEnd: x.lockoutEnd,
                        dateCreated: x.dateCreated,
                    } as IUserViewDTO
                })]);
                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
                setIsSubmitting(false);
            })

        } catch {
            setLoadingStatus(LoadingStatus.Failed);
        }
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    useEffect(() => {
        if (loadingStatus === LoadingStatus.Completed) {
            setIsSubmitting(false);
        }

    }, [loadingStatus])

    const getUserRoles = () => {
        if (selectedItem) {
            userDataProvider.getRoles(selectedItem.idGlobal as string, (e, c) => {
                let userRolesArr: IRoleViewDTO[] = e.map(x => ({
                    idGlobal: uuidv4(),
                    name: x 
                }))
                setUserRoles(userRolesArr);
            })
        }
    }

    useEffect(() => {
        getUserRoles();
    }, [selectedItem])

    useEffect(() => {
        if (selectedRoleItem) {
            roleDataProvider.findRoleByName(selectedRoleItem.displayName, (e) => {
                setSelectedRole({ 
                    idGlobal: e[0].id, 
                    name: e[0].name,
                    dateCreated: e[0].dateCreated,
                    dateModified: e[0].dateModified,
                    dateDeleted: e[0].dateDeleted 
                });
            })
        }
    }, [selectedRoleItem])


    useEffect(() => {
        if (selectedItem?.idGlobal) {
            let user = data.find(x => x.idGlobal === selectedItem.idGlobal);
            if (user) {
                setSelectedUser(
                    {
                        idGlobal: user.idGlobal,
                        fullName: user.fullName,
                        shortName: user.shortName,
                        userName: user.userName,
                        phoneNumber: user.phoneNumber,
                        email: user.email,
                        companyName: user.companyName,
                        lockoutEnd: user.lockoutEnd,
                        dateCreated: user.dateCreated,
                        dateModified: user.dateModified
                    }
                );
            }
        } else {
            setSelectedUser(null);
        }
    }, [selectedItem])

    function searchUserWithCustomWhereFilter(value: string) {
        if (value.length > 0) {
            // pgsql-выражение (блока условия, финальный and (expr)):
            // - не учитывает регистр
            // - shortName - вхождение с первого символа
            // - userName - выхождение с первого символа
            // соединение через ИЛИ
            const expression = `lower("ShortName") like lower('${value}%') or lower("UserName") like lower('%${value}%')`;
            dispatchGridFilter({ type: "addCustomWhereExpressionFilter", payload: expression });
        } else {
            dispatchGridFilter({ type: "deleteCustomWhereExpressionFilter" });
        }
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <UserCommandPanel
                    add={{
                        onClick: () => {
                            setTypeOpen('Create');
                            setOpenCreateModal(true);
                        }
                    }}

                    edit={{
                        onClick: () => {
                            setTypeOpen('Edit');
                            setOpenCreateModal(true);
                        },
                        disabled: selectedItem ? false : true
                    }}
                    block={{
                        onClick: () => {
                            blockUser();
                        },
                        disabled: selectedUser && selectedUser.lockoutEnd === null ? false : true
                    }}
                    unBlock={{
                        onClick: () => {
                            unBlockUser();
                        },
                        disabled: selectedUser && selectedUser.lockoutEnd !== null ? false : true

                    }}
                    refresh={{
                        onClick: () => {
                            refresh();
                        }
                    }}
                    permission={props.plugin.permission}
                    search={{
                        placeholder: t("userPanel.placeholderNameLogin"),//"Имя, логин"
                        call: (value: string) => searchUserWithCustomWhereFilter(value),
                        clearSig: clearSig
                    }}
                />
            </ToolbarWrapper>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                {isSubmitting ? <div><Spinner /></div> : <DefaultGrid
                        hiddenPagination={{ hiddenCountRow: false, hiddenNumberPage: false }}
                        gridId={props.gridId}
                        data={data}
                        dataProvider={userDataProvider}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        totalCount={totalCount}
                        filter={gridFilter}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows)
                        }}
                        multipleSelect={props.multipleSelect}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props?.onSelect?.(row)
                        }}
                        useUiService={false}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />}
                    <div className={classNames(styles.separator, styles.separator_horizontal)}></div>
                    <div className={styles.detailsTabView}>
                        {
                            selectedItem?.idGlobal && <>
                                <UserRolePanel
                                    add={{
                                        onClick: () => setOpenAddRole(true)
                                    }}
                                    delete={{
                                        onClick: () => {
                                            removeRole()
                                        },
                                        disabled: selectedRoleItem ? false : true

                                    }}
                                    permission={props.plugin.permission}
                                />

                                <DefaultGrid
                                    gridId={uuidv4()}
                                    data={userRoles}
                                    totalCount={0}
                                    filter={roleGridFilter}
                                    plugin={RoleGridMinimalPluginSettings}
                                    selectedItem={selectedRoleItem}
                                    onSelect={(row) => {
                                        setSelectedRoleItem(row)
                                    }}
                                    useUiService={false}
                                    onSort={(i) => dispatchRoleGridFilter({ type: "sort", payload: i.propertyName })}
                                    onFilterDelete={(i) => dispatchRoleGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                    onPageNumberChange={(n) => dispatchRoleGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                    onNumberPerPageChange={(n) => dispatchRoleGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                />
                            </>
                        }
                    </div>
                </div>
            </div>
            {
                openAddRole &&
                <UserRolesModal
                    ok={() => {
                        getUserRoles()
                    }}
                    cancel={() => {
                        setOpenAddRole(false)
                    }}
                    userId={selectedItem?.idGlobal ? selectedItem.idGlobal : null}
                />
            }
            {
                openCreateModal &&
                <CreateUserModal
                    ok={(userData: ICreateUser) => {
                        if (typeOpen === 'Create')
                        {
                            userDataProvider.create(userData, () => {
                                refresh();
                                setOpenCreateModal(false);
                            })

                        }
                        else
                            userDataProvider.update(selectedItem?.idGlobal as string, userData, () => {
                                refresh()
                                setOpenCreateModal(false)
                            })
                    }}
                    userEditData={selectedItem ? selectedUser : null}
                    cancel={() => setOpenCreateModal(false)}
                    typeOpen={typeOpen}
                    lockFromPermission={lockFromPermission}
                />
            }
        </PluginWrapper>
    )
}
