import React, { useState } from 'react'
import styles from './DatePeriodPanel.module.scss'
import { DateTime } from 'luxon';
import { IOption, Select } from "../../components/selects/select";
import DatePeriodPicker, { IDatePeriod } from "../../components/datePeriodPicker/datePeriodPicker";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type Period = "day" | "week" | "month" | "year"

interface Props {
    datePeriod: IDatePeriod
    onDatePeriodChange: (value: IDatePeriod) => void
    disabled?: boolean
    label?: string
    inline?: boolean
}

export const DatePeriodPanel: React.FC<Props> = (props) => {

    const defaultPeriodOption = { displayName: "Выберите период", value: "", hidden: true } as IOption;
    const [currentOption, setCurrentOption] = useState<IOption>(defaultPeriodOption);

    const { t } = useTranslation();
    const baseT = (value: string) => t('documents.invoice.' + value);

    function getPeriod(p?: Period): IDatePeriod {
        const now = DateTime.now();

        switch (p) {
            case "day": return { startDate: now.startOf('day'), endDate: now.endOf('day') }
            case "week": return { startDate: now.startOf('week'), endDate: now }
            case "month": return { startDate: now.startOf('month'), endDate: now }
            case "year": return { startDate: now.startOf('year'), endDate: now }
            default: return { startDate: null, endDate: null }
        }
    }

    return (
        <div className={classNames(styles.datePeriodPanel, props.inline && styles.datePeriodInline)}>
            {
                props.label && (
                    <label className={styles.datePeriodPanel__label}>
                        {props.label}
                    </label>
                )
            }
            <div className={styles.datePeriodPanel__wrapper}>
                <DatePeriodPicker
                    disabled={props.disabled ?? false}
                    period={props.datePeriod}
                    onPeriodChange={(value) => {
                        props.onDatePeriodChange(value)
                        setCurrentOption(defaultPeriodOption)
                    }}
                />
                <div className={styles.datePeriodPanel__period}>
                    <Select
                        className={styles.select}
                        disabled={props.disabled ?? false}
                        defaultOption={defaultPeriodOption}
                        value={currentOption}
                        onSelect={(option) => {
                            if (currentOption !== option) {
                                setCurrentOption(option)
                                props.onDatePeriodChange(getPeriod(option.value as Period))
                            }
                        }}
                        options={[
                            defaultPeriodOption,
                            { displayName: baseT('thisDay'), value: "day" },
                            { displayName: baseT('thisWeek'), value: "week" },
                            { displayName: baseT('thisMonth'), value: "month" },
                            { displayName: baseT('thisYear'), value: "year" }
                        ]}>
                    </Select>
                </div>
            </div>
        </div>
    )
};