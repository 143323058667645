import { KizViewPluginSettings } from "../../../Dictionaries/Kiz/KizView/KizViewPluginSettings"
import { ScanBoxCreatePluginSettings } from "../../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings"



const ImportRemainsItemGridPluginSettings: IPluginSettings = {
    name: 'Позиции',
    mnemocode: 'import_remains_item_plugin',
    permission: 'ImportRemains',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Имя спецификации',
            visibility: true,
            width: 100
        }, {
            order: 2,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во',
            visibility: true,
            width: 100
        }, {
            order: 3,
            propertyName: 'scalingRatioName',
            propertyType: 'string',
            displayName: 'Ед. изм.',
            visibility: true,
            width: 100
        }, {
            order: 4,
            propertyName: 'retailPriceIncVat',
            propertyType: 'number',
            displayName: 'Цена розн.',
            visibility: true,
            width: 100
        }, {
            order: 5,
            propertyName: 'retailSumIncVat',
            propertyType: 'number',
            displayName: 'Сумма розн.',
            visibility: true,
            width: 100
        }, {
            order: 6,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 100
        }, {
            order: 7,
            propertyName: 'isGnvls',
            propertyType: 'boolean',
            displayName: 'ЖНВЛС',
            visibility: true,
            width: 100
        },{
            order: 8,
            propertyName: "supplier",
            propertyType: "string",
            displayName: "Поставщик",
            visibility: true,
            width: 200,
        },
        {
            order: 9,
            propertyName: "seriesNumber",
            propertyType: "string",
            displayName: "Серия",
            visibility: true,
            width: 200,
        },
        {
            order: 10,
            propertyName: "bestBefore",
            propertyType: "datetime",
            displayName: "Срок годности",
            visibility: true,
            width: 200,
        },
        {
            order: 11,
            propertyName: "isOsu",
            propertyType: "boolean",
            displayName: "Признак воды",
            visibility: true,
            width: 200,
        },
    ],
    detailsPlugins: [
        KizViewPluginSettings,
        ScanBoxCreatePluginSettings
    ]
}

export default ImportRemainsItemGridPluginSettings