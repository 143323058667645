import React, { FC, useEffect, useState } from 'react'
import styles from './styles/LotsByGoodsFilteringPanel.module.scss'
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../components/checkboxes/checkbox';
import { TextInput } from '../../../../components/controls/inputs';
import { IOption, Select } from '../../../../components/selects/select';
import { Button } from '../../../../components/buttons/button';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { LoadingStatus, TypeExport } from '../../../../@types/enumsGlobal';
import { Spinner } from '../../../../components/spiner/Spinner';
import { IDefecturaDTO, IDefecturaExcel } from '../../../../libs/coreapi-dto/service/defectura';
import { ExportExcel } from '../exportExcel/exportExcel';
import { DefecturaProvider } from '../../../../Services/DataProviders/DefecturaProvider';
import { GridFilterAction } from '../../../../system/hooks/useGridFilter';
import { ExportXML } from '../exportExcel/exportXML';
import { RadioGroup } from '../../../../components/radioGroup/radioGroup';
import { ContractorDataProvider } from '../../../../Services/DataProviders/ContractorDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

interface Props {
    searchInput: string | undefined
    filterBy: IOption | undefined
    stopList: boolean
    setStopList: (value: boolean) => void
    loadingStatus: LoadingStatus
    defecturaProvider: DefecturaProvider
    gridFilter: [IGridFilter, React.Dispatch<GridFilterAction>]
    lockFromPermission?: boolean
}

export const DefecturaFilteringPanel: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const contractorDP = new ContractorDataProvider(appCtx.coreApiService)
    const { t } = useTranslation();
    const baseT = (value: string) => t('accounting.Defectura.filteringPanel.filterBy.' + value);
    const [inputValue, setInputValue] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [isSpinner, setIsSpinner] = useState<boolean>(false);    
    const [autoFilter, setAutoFilter] = useState<boolean>(true);
    const [withinRange, setWithinRange] = useState<boolean>(true);
    const [typeExport, setTypeExport] = useState<string>(TypeExport.xlsx)

    const [option] = useState<IOption[]>(
        [ {displayName: "Код товара", value: "goodsCode", }, 
          {displayName: "Имя товара", value: "goodsName", }, 
          {displayName: "Штрих-код", value: "barcode", }  
        ] as IOption[] )   

    const [optionValue, setOptionValue] = useState<IOption>(option[0])

    const isNumber = (n) => {
        return typeof (n) != "boolean" && !isNaN(n);
    }

    const isStopList = (idDefecturaStopListGlobal: string) :string => {
        const isStoplist = 'Находится в стоп-листе'
        const isNotStopList = 'Не находится в стоп-листе'
        if (idDefecturaStopListGlobal === null) return isNotStopList;
        if (idDefecturaStopListGlobal === undefined) return isNotStopList;
        if (idDefecturaStopListGlobal === '00000000-0000-0000-0000-000000000000') return isNotStopList
        return isStoplist;
    }

    const exportXml = (entities: IDefecturaDTO[]) => {
       
        contractorDP.getMe((me) => {
            let dateNow = DateTime.now().toFormat('dd.MM.yyyy hh/mm/ss')
            let xml = '<main>'
            xml += `<Head>
                    <ID_DEFECTURA>${uuidv4()}</ID_DEFECTURA>
                    <DOC_DATE>${dateNow}</DOC_DATE>
                    <ID_CONTRACTOR_GLOBAL>${me?.idGlobal ?? 'Не указан контрагент "Мы"'}</ID_CONTRACTOR_GLOBAL>
                    <CONTRACTOR_NAME>${me?.fullName ?? 'Не указан контрагент "Мы"'}</CONTRACTOR_NAME>
                    <CONTRACTOR_CODE>${me?.code ?? 'Не указан контрагент "Мы"'}</CONTRACTOR_CODE>
                    </Head>
                    <Body>
            `
            entities.forEach((item) => {
                xml += `
                <lot>
                    <ID_GOODS_GLOBAL>${item.idGoodsGlobal}</ID_GOODS_GLOBAL>
                    <GOODS_NAME>${item.goodsName}</GOODS_NAME>
                    <PRODUCER_NAME>${item.producerName}</PRODUCER_NAME>
                    <QTY_REMAIN>${item.quantityRemain}</QTY_REMAIN>
                    <QTY_MIN>${props.gridFilter[0]?.paramFilter?.quantity_min}</QTY_MIN>
                    <LAST_PRICE_SAL>${item.lastRetailPrice}</LAST_PRICE_SAL>
                    <LAST_PRICE_SUP>${item.lastSupplierPrice}</LAST_PRICE_SUP>
                    <LAST_SUPPLIER_NAME>${item.lastSupplier}</LAST_SUPPLIER_NAME>
                    <STOP_LIST>${isStopList(item.idDefecturaStopListGlobal)}</STOP_LIST>
                </lot>`
                })
            xml += '</Body></main>'
            ExportXML(xml,dateNow)
        })
    }

    const exportExcel = (entities: IDefecturaDTO[]) => {
        var excelData = entities.map((item): IDefecturaExcel => {
            return {
                Товар: item.goodsName,
                КодТовара: item.goodsCode,
                Штрихкод: item.barcode,
                Производитель: item.producerName,
                ПоследнийПоставщик: item.lastSupplier,
                ДатаПоставкиМедикамента: item.lastDateDocument.toString(),
                ЦенаПоставщика: item.lastSupplierPrice,
                ЦенаРозничная: item.lastRetailPrice,
                Остаток: item.quantityRemain,
                СтопЛист: isStopList(item.idDefecturaStopListGlobal)
            }
        })
        ExportExcel(excelData)
    }

    async function handlerOnExportAsync()
    {
        await new Promise(resolve => {
            props.defecturaProvider.getView(props.gridFilter[0], (entities) => {
                switch (typeExport) {
                    case TypeExport.xlsx: {
                         exportExcel(entities)
                         return resolve(false)
                    }
                    case TypeExport.xml: {
                         exportXml(entities)
                        return resolve(false)
                    }
                }
            })  
        })
        return false
    }

    useEffect(() => {                     
        autoFilter ? setIsAutoFilter(): setIsFilter()
    }, [inputValue,autoFilter,optionValue])

    useEffect(() => {

        if (!isSpinner) return

        handlerOnExportAsync().then((data) => {
            setIsSpinner(data)
        })       

    }, [isSpinner])

    const setIsAutoFilter = () => {
        let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[]
        let additional = withinRange ? '%' : ''

        let result = isNumber(inputValue)

        if (!result)
        {
            dispatchGridFilter(newColumnFilters,inputValue)
            return
        }
        
        if (inputValue.length === 13 || inputValue.length === 12)
        {
            newColumnFilters.push({
                'name': 'barcode',
                'operator': withinRange ? 'Like' : 'Eq',
                'value': inputValue + additional
            })
        }
        else 
        {
            newColumnFilters.push({
                'name': 'goodsCode',
                'operator': withinRange ? 'Like' : 'Eq',
                'value': inputValue + additional
            })
        }

        dispatchGridFilter(newColumnFilters) 
       
    }

    const setIsFilter = () => {
        let newColumnFilters: IGridColumnFilter[] = [] as IGridColumnFilter[]
        let goodsName: string = ''
        let additional = withinRange ? '%' : ''
        
        if (optionValue.value === 'goodsCode') 
        {
            newColumnFilters.push({
                'name': 'goodsCode',
                'operator': withinRange ? 'Like' : 'Eq',
                'value': inputValue + additional
            })
        }

        if (optionValue.value === 'goodsName') 
        {
            goodsName = inputValue
        }

        if (optionValue.value === 'barcode') 
        {
            newColumnFilters.push({
                'name': 'barcode',
                'operator': withinRange ? 'Like' : 'Eq',
                'value': inputValue + additional
            })
        }

        dispatchGridFilter(newColumnFilters,goodsName)
    }

    const dispatchGridFilter = (columns: IGridColumnFilter[], goodsName?: string) =>{
        props.gridFilter[1]({
            type: 'paramSimpleFilter', payload: {
                'gridColumnFilter': columns,
                'gridParamFilter': {...props.gridFilter[0]?.paramFilter, goodsName: goodsName ?? null} 
            }
        })
    }

    return (
        <>
        <GridWrapper className={styles.newField_alignCenter} cols={7}>       
            <TextInput 
                className={styles.newField_search} 
                disabled={false}
                label={t('accounting.Defectura.filteringPanel.search')}
                inputId={"inputDefecturaSearch"}
                inline={true}
                searchButtonShow={true}
                onChange={(value) => {
                    setSearchValue(value)
                }}
                searchButtonOnClick={() => {
                    setInputValue(searchValue);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setInputValue(searchValue);
                    }
                }}
            />       
            <Select
                className={styles.newField_select}                
                defaultOption={option[0]}
                onSelect={(option) => {  
                    setOptionValue(option)                  
                }}
                value={props.filterBy}
                options={[
                    { displayName: baseT('goodsName'), value: "goodsName" },
                    { displayName: baseT('goodsCode'), value: "goodsCode" },
                    { displayName: baseT('barcode'), value: "barcode" }
                ]}>
            </Select>

            <div className={styles.empty}>
                <Checkbox                    
                    name='autoFilter'
                    label='Автофильтр'
                    checked={autoFilter}
                    onChange={(checked) => {
                        setAutoFilter(checked)
                    }}
                    disabled={false} />
            </div>
            <div className={styles.empty}>
                <Checkbox name="StopList" label="Стоп-лист" checked={props.stopList}
                    onChange={(checked) => props.setStopList(checked)}
                />
            </div>
            <div className={styles.empty}>
                <Checkbox name='withinRange' label='По вхождению' 
                    checked={withinRange} onChange={(checked) => setWithinRange(checked)} disabled={false} />
            </div>
            {   
                props.loadingStatus !== LoadingStatus.NoData?
                <div>
                     <RadioGroup
                        value={{displayName: '', value: typeExport}}
                        onSelect={(option) => {
                            setTypeExport(option?.value)
                        }}
                        options={[
                            {displayName: TypeExport.xlsx, value: TypeExport.xlsx},
                            {displayName: TypeExport.xml, value: TypeExport.xml},
                        ]}
                    />
                    <Button 
                        className={styles.field_button} variant="outprimary" onClick={() => {
                            setIsSpinner(true)
                        }}
                        disabled={props.lockFromPermission}
                    > Выгрузка 
                    </Button>
                </div> 
                : <div></div>                    
            }
            {
                isSpinner === true?
                <Spinner />
                :<></>
            }

            </GridWrapper>
        </>
    )
};

