import { FC, useEffect, useState } from "react";
import styles from './styles/ContractorCreatorModal.module.scss'
import { LegalEntityCreatorModal, LegalEntitySelectorModal } from "../LegalEntity";
import { IContractorCreateModel, IContractorImportInvoiceInfo, IContractorInfo, IContractorKizSettings, IContractorTimeZoneInfo } from "../../../libs/coreapi-dto/dirs/contractor";
import BaseCreator from "../../../components/creators/baseCreator";
import { Accordion } from "../../../components/controls/accordion";
import { TextAreaInput, TextInput } from "../../../components/controls/inputs";
import { DictionaryInput } from "../../../components/controls/dictionaryInput";
import { CheckBox } from "../../../components/controls/checkbox";
import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import GridSelectorInput from "../../../components/controls/GridSelectorInput";
import { useTranslation } from "react-i18next";
import { IOption, Select } from "../../../components/selects/select";
import { useForm } from "../../../system/hooks/useForm";
import { PricingModelSelectorModal } from "../PricingModel";
import { SettingsButton } from "../../../components/buttons/iconButtons/action/MainCollection";
import GridWrapper from "../../../components/controls/GridWrapper";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import InvoiceSettingsSelectorModal from "./invoiceSettings/InvoiceSettings/InvoiceSettingsSelectorModal";
import { DictionarySelect } from "../../../components/controls/dictionarySelect";
import { AdmissibleExtensions, ViewExtension } from "../../../@types/enumsGlobal";
import { ITimeZoneViewDTO } from "../../../libs/coreapi-dto/dirs/timeZone";
import { TimeZoneDataProvider } from "../../../Services/DataProviders/TimeZoneDataProvider";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import  {}  from "../../../Services/Extensions/DateTime";
import AccessPointSelectorModal from "../../Service/AccessPoint/AccessPointSelectorModal";
import { AccessPointDataProvider } from "../../../Services/DataProviders/AccessPointDataProvider";
import { validate as isValidUUID } from 'uuid';
import UserLocalSelectorModal from "../../Service/UserLocal/components/selectorModal/UserLocalSelectorModal";
import { Button } from "../../../components/buttons/button";
import { LegalEntityDataProvider } from "../../../Services/DataProviders/LegalEntityDataProvider";
import { ILegalEntityDTO } from "../../../libs/coreapi-dto/dirs/legalEntity";
import { InoviceSettingsDataProvider } from "../../../Services/DataProviders/InoviceSettingsDataProvider";

interface IValid {
    name: string
    fullName: string
    legalEntity: IEntitySimpleDTO
    kpp: string
}

export const ContractorCreatorModal: FC<ICreatorModalProps<IContractorCreateModel>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const accessPointDataProvider = new AccessPointDataProvider(appContext.coreApiService);

    const [contractor, setContractor] = useState<IContractorCreateModel>(props.data ?? {
        useVat: true,
        attributesInfo: {
            isComittent: false,
            isFilial: false,
            trustedSupplier: false

        },
        kizSettings: {
            importFromMdlp: false,
            isLiteMdlp: false,
            kizAcceptionType: 0
        }
    } as IContractorCreateModel);

    const [kizAcceptionType, setKizAcceptionType] = useState<IOption | undefined>();
    const [accessPoint, setAccessPoint] = useState<IEntitySimpleDTO>();
    const [internetOrderAccessPoint, setInternetOrderAccessPoint] = useState<IEntitySimpleDTO>();

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.contractors.' + value);
    const errorsT = (value: string) => t('errors.' + value);
    const kizOptionsBaseT = (value: string) => t('directory.contractors.kizAcceptionTypes.' + value);

    const timeZoneDP = new TimeZoneDataProvider(appContext.coreApiService);
    const [timeZones, setTimeZones] = useState<ITimeZoneViewDTO[]>([]);
    const userContext = useUserContext();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const legalEntityDataProvider = new LegalEntityDataProvider(appContext.coreApiService)
    const [isAddLegalEntity, setIsAddLegalEntity] = useState<boolean>(false);
    const [legalEntityForAdd, setLegalEntityForAdd] = useState<ILegalEntityDTO|undefined>();

    const invoiceSettingsDP = new InoviceSettingsDataProvider(appContext.coreApiService);

    const { isValid, errors, setErrors } = useForm<IValid>({
        validations: {
            name: {
                required: {
                    value: contractor.name ? false : true,
                    message: errorsT("required")
                }
            },
            fullName: {
                required: {
                    value: contractor.fullName ? false : true,
                    message: errorsT("required")
                }
            },
            legalEntity: {
                required: {
                    value: contractor.legalEntity ? false : true,
                    message: errorsT("required")
                }
            },
            kpp: {
                custom: {
                    isValid: (value) => {
                        return contractor.kpp ? RegExp(/^[0-9]+$/).test(contractor.kpp) : true;
                    },
                    message: errorsT("onlyDigits"),
                }
            },
        }
    });

    function handleSubmit() {
        const valid = isValid((err) => {
            if (err) {
                setTimeout(() => document.getElementById(Object.keys(err)[0])?.scrollIntoView({ block: "center", behavior: "smooth" }), 0);
            }
        });
        if (valid) {
            props.save(contractor);
            setIsSubmitting(true);
        }
    }

    function loadTimeZones() {
        timeZoneDP.getTimeZones((e) => {
            setTimeZones(e);
        })
    }

    function mapToOptions(val: ITimeZoneViewDTO[]) {
        let temp: IOption[] = []
        val.map((ii) => {
            let rec = {
                value: ii.idTimeZone,
                displayName: ii.displayNameTimeZone
            } as IOption
            temp.push(rec)
        })
        return temp;        
    }

    function getDisplayNameOnIdTimeZone(val:string, mas: ITimeZoneViewDTO[]) {
        let a= mas.filter(item => {
            return item.idTimeZone === val
          })
        if (a.length === 0)
          return null
        return a[0].displayNameTimeZone
    }

    function updateTimeZonOffset()
    {
        userContext.updateTimeZonOffset()
    }

    function saveTimeZoneOffsetInUserContext()
    {
        if (!contractor.isMe) return
        let idTimeZone = contractor.timeZoneInfo ? contractor.timeZoneInfo.idTimeZone : null
        if (idTimeZone)
            timeZoneDP.GetTimeZoneOffset(idTimeZone,(ent) => {
                userContext.userTimeZoneOffset = ent.offsetTimeZone;
            })
        else
            userContext.userTimeZoneOffset = "00:00:00";
    }

    useEffect(() => {
        loadTimeZones()
        switch (contractor.kizSettings?.kizAcceptionType) {
            case 0:
                return setKizAcceptionType({ displayName: kizOptionsBaseT('straight'), value: 0 })
            case 1:
                return setKizAcceptionType({ displayName: kizOptionsBaseT('reverse'), value: 1 })
            case null:
                setContractor({ ...contractor, kizSettings: { ...contractor.kizSettings, kizAcceptionType: 0 } as IContractorKizSettings })
        }

    }, [])

    useEffect(() => {
        let idAccessPoint = contractor.importInvoiceInfo?.invoiceLoaderSettingsFileName
        if (idAccessPoint)
            if (isValidUUID(idAccessPoint))
            {
                accessPointDataProvider.getById(idAccessPoint,(data)=>{
                    if (idAccessPoint)
                        setAccessPoint({idGlobal: idAccessPoint, displayName: data.name});
                });
            }
    }, [])    

    useEffect(() => {
        let idInternetOrderAccessPoint = contractor.idInternetOrderAccessPointExternalGlobal
        if (idInternetOrderAccessPoint)
            if (isValidUUID(idInternetOrderAccessPoint))
                accessPointDataProvider.getById(idInternetOrderAccessPoint, (data)=>{
                    if (idInternetOrderAccessPoint)
                        setInternetOrderAccessPoint({idGlobal: idInternetOrderAccessPoint, displayName: data.name});
                });
    }, [])    

    const isVisible = () : boolean => {
        if (tabsContext.currentTab?.title === props.title) return true
        if (tabsContext.currentTab?.title?.startsWith('Настройка импорта')) return false
        if (props?.title === baseT('title')) return true
        return false
    }

    function clickAddLegalEntity()
    {
        const copyLegalEntityForAdd = {
            ...legalEntityForAdd,
            name: contractor?.fullName,
            kpp: contractor?.kpp,
            email: contractor?.contactInfo?.email,
            urAddress: contractor?.contactInfo?.address
        } as ILegalEntityDTO

        setLegalEntityForAdd(copyLegalEntityForAdd)
    }

    function saveAddLegalEntity(id)
    {
        legalEntityDataProvider.getById(id as string, (data)=>{
            let dto: IEntitySimpleDTO = { idGlobal: id as string, displayName: data.name}
            setContractor({ ...contractor, legalEntity: dto })
        })

        setIsAddLegalEntity(false)
        setLegalEntityForAdd(undefined)
    }

    const CheckValidSettings = (ext, callback: (value) => void)=> {
        if (contractor?.invoiceImportMappingModel?.idGlobal)
            invoiceSettingsDP.getById(contractor?.invoiceImportMappingModel?.idGlobal, (data)=>{
                callback(data?.extension ? data.extension === ext : true)
            })
        else {
            callback(true);
        }
    }

    return <> 
        {             
            isVisible() &&
            <BaseCreator 
                variant={props.variant} 
                isSubmitting={isSubmitting}
                save={handleSubmit} 
                cancel={props.cancel} 
                modalWindowClassName={styles.contractorEditorModalWindow} 
                valid={!props.lockFromPermission}
            >
                <div className={styles.contractorEditor}>
                    <div className={styles.contractorPanel}>
                        <Accordion opened={true} caption={baseT('counterpartyDetails')} id="accordionContragentChars" className={styles.contractorChars}>
                            <div className={styles.charsRow}>
                                <TextInput
                                    required
                                    value={contractor.name}
                                    label={baseT('shortName')}
                                    inputId={"name"}
                                    onChange={(value) => setContractor({ ...contractor, name: value as string })}
                                    error={errors.name}
                                    onFocus={() => setErrors({ ...errors, name: undefined })}
                                    disabled={props.lockFromPermission}
                                />
                                <TextInput
                                    value={contractor.mnemocode}
                                    label={baseT('mnemocode')}
                                    inputId={"inputContractorMnemoCode"}
                                    onChange={(value) => setContractor({ ...contractor, mnemocode: value as string })}
                                    disabled={props.lockFromPermission}
                                />
                                <TextInput
                                    value={contractor.code}
                                    label={baseT('code')}
                                    inputId={"inputContractorCode"}
                                    onChange={(value) => setContractor({ ...contractor, code: value as string })}
                                    disabled={props.lockFromPermission}
                                />
                            </div>
                            <div className={styles.fullNameRow}>
                                <TextInput
                                    required
                                    value={contractor.fullName}
                                    label={baseT('fullName')}
                                    inputId={"fullName"}
                                    onChange={(value) => setContractor({ ...contractor, fullName: value as string })}
                                    error={errors.fullName} onFocus={() => setErrors({ ...errors, fullName: undefined })}
                                    disabled={props.lockFromPermission}
                                />
                            </div>

                            <GridWrapper
                                cols={7}
                            >
                                <GridSelectorInput
                                    required
                                    className={styles.fieldLegal_Entity}
                                    selectedEntity={contractor?.legalEntity}
                                    label={baseT('legalEntity')}
                                    id={"legalEntity"}
                                    selectorModalJsx={LegalEntitySelectorModal}
                                    onSelect={(entity) => setContractor({ ...contractor, legalEntity: entity })}
                                    error={errors.legalEntity} onFocus={() => setErrors({ ...errors, legalEntity: undefined })}
                                    onClear={()=> setContractor({ ...contractor, legalEntity: undefined }) }
                                    disabled={props.lockFromPermission}
                                />

                                <div className={styles.fieldLegal_Button}>
                                <Button  variant="outsecondary"
                                    onClick={() => {
                                        clickAddLegalEntity()
                                        setIsAddLegalEntity(true)
                                    }}
                                    disabled={contractor?.legalEntity ? true : false}
                                >
                                    Добавить
                                </Button>
                                </div>
                            </GridWrapper>

                            <div className={styles.legalEntity}>
                                <TextInput
                                    value={contractor.kpp}
                                    label={baseT('kpp')}
                                    inputId={"kpp"}
                                    onChange={(value) => setContractor({ ...contractor, kpp: value as string })}
                                    error={errors.kpp} onFocus={() => setErrors({ ...errors, kpp: undefined })}
                                    disabled={props.lockFromPermission}
                                />
                                <GridSelectorInput
                                    selectedEntity={contractor?.mol}
                                    label={baseT('financiallyResponsiblePerson')}
                                    id={"dictionaryInputContractorMol"}
                                    selectorModalJsx={UserLocalSelectorModal}
                                    onSelect={(entity) => setContractor({ ...contractor, mol: entity })}
                                    onClear={()=> setContractor({ ...contractor, mol: undefined }) }
                                    disabled={props.lockFromPermission}
                                />
                            </div>                            

                            <GridSelectorInput
                                selectedEntity={contractor?.pricingPricingModel}
                                label={baseT('pricingPricingModel')}
                                id={"dictionaryInputContractorPricingPricingModel"}
                                selectorModalJsx={PricingModelSelectorModal}
                                onSelect={(entity) => setContractor({ ...contractor, pricingPricingModel: entity })}
                                onClear={()=> setContractor({ ...contractor, pricingPricingModel: undefined }) }
                                disabled={props.lockFromPermission}
                            />
                        </Accordion>
                        <Accordion opened={true} caption={baseT('documentFlowSettings')} id="accordionContragentInvoices" className={styles.contractorInvocies}>
                            <GridWrapper
                                cols={7}
                            >
                                <DictionarySelect
                                    label={baseT('invoiceImportFormat')}
                                    className={styles.field_ImportProvider}
                                    setValue={(value) => {
                                        CheckValidSettings(value, (valid)=>{
                                            if (value === ViewExtension.sstProtek || !valid) {
                                                setContractor({ ...contractor, invoiceImportMappingModel: undefined, importInvoiceInfo: contractor?.importInvoiceInfo ? { ...contractor.importInvoiceInfo, invoiceImportMappingModel: undefined, importProvider: value } as IContractorImportInvoiceInfo : { importProvider: value } as IContractorImportInvoiceInfo })
                                            } else {
                                                setContractor({ ...contractor, importInvoiceInfo: contractor?.importInvoiceInfo ? { ...contractor.importInvoiceInfo, importProvider: value } as IContractorImportInvoiceInfo : { importProvider: value } as IContractorImportInvoiceInfo })
                                            }
                                        })
                                    }}
                                    options={AdmissibleExtensions}
                                    value={contractor?.importInvoiceInfo?.importProvider}
                                    disabled={props.lockFromPermission}
                                />

                                <GridSelectorInput
                                    selectorModalJsx={InvoiceSettingsSelectorModal}
                                    label={baseT('settingInvoice')}
                                    onSelect={(entity) => { setContractor({ ...contractor, invoiceImportMappingModel: entity }) }}
                                    selectedEntity={contractor?.invoiceImportMappingModel}
                                    data={{ extension: contractor?.importInvoiceInfo?.importProvider, idContactor: contractor.idGlobal, name: contractor.name }}
                                    className={styles.field_InvoiceImportMappingModel}
                                    disabled={(contractor?.importInvoiceInfo?.importProvider && contractor?.importInvoiceInfo?.importProvider !== ViewExtension.sstProtek ? false : true) || props.lockFromPermission}
                                    onClear={() => {setContractor({ ...contractor, invoiceImportMappingModel: undefined })}}
                                />

                                <div className={styles.field_SettingsButton}>
                                    <SettingsButton
                                        sizeVariant='normal'
                                        disabled={(contractor?.importInvoiceInfo?.importProvider && contractor?.importInvoiceInfo?.importProvider !== ViewExtension.sstProtek ? false : true) || props.lockFromPermission}
                                        onClick={() => { 
                                            tabsContext.openChild('invoice_settings_creator', !contractor?.invoiceImportMappingModel ? 'create' : 'edit', contractor?.invoiceImportMappingModel?.idGlobal, () => { },{ 
                                                idContractor: !contractor?.invoiceImportMappingModel ? contractor.idGlobal : contractor?.invoiceImportMappingModel?.idGlobal, 
                                                extension: contractor?.importInvoiceInfo?.importProvider, 
                                                name: !contractor?.invoiceImportMappingModel ? contractor.name : contractor?.invoiceImportMappingModel?.displayName 
                                            }) 
                                        }}
                                    />
                                </div>
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <GridSelectorInput
                                    selectedEntity= {accessPoint} 
                                    label={baseT('accessPoint')}
                                    id={"dictionaryInputContractorAccessPoint"}
                                    selectorModalJsx={AccessPointSelectorModal}
                                    onSelect={(entity) => {
                                        setAccessPoint(entity);
                                        let tmp = entity.idGlobal;
                                        setContractor({ ...contractor, 
                                            importInvoiceInfo : contractor?.importInvoiceInfo ? {...contractor.importInvoiceInfo, invoiceLoaderSettingsFileName : tmp } as IContractorImportInvoiceInfo : {invoiceLoaderSettingsFileName : tmp} as IContractorImportInvoiceInfo })
                                    }}
                                    onClear={()=> {
                                        setAccessPoint(undefined);
                                        setContractor({ ...contractor, 
                                            importInvoiceInfo : contractor?.importInvoiceInfo ? {...contractor.importInvoiceInfo, invoiceLoaderSettingsFileName : '' } as IContractorImportInvoiceInfo : {invoiceLoaderSettingsFileName : '' } as IContractorImportInvoiceInfo })
                                    } }
                                    disabled={props.lockFromPermission}
                                />

                                <CheckBox label={baseT('isAutoLoadingInvoices')} id="checkboxContractorIsAutoLoadingInvoices"
                                    defaultChecked={contractor.isAutoLoadingInvoices}
                                    onChanged={() => setContractor({ ...contractor, isAutoLoadingInvoices: !contractor.isAutoLoadingInvoices })} 
                                    disabled={props.lockFromPermission}
                                />

                                <CheckBox label={baseT('isAutoImportInvoices')} id="checkboxContractorIsAutoImportInvoices"
                                    defaultChecked={contractor.isAutoImportInvoices}
                                    onChanged={() => setContractor({ ...contractor, isAutoImportInvoices: !contractor.isAutoImportInvoices })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>

                            <div className={styles.exportRow}>
                                <DictionaryInput
                                    value={contractor?.importInvoiceInfo?.invoiceAccessPoint}
                                    label={baseT('exportAccessPoint')}
                                    inputId={"dictionaryInputContractorInvoiceAccessPoint"}
                                    onChange={(value) => setContractor({ ...contractor, importInvoiceInfo: contractor?.importInvoiceInfo ? { ...contractor.importInvoiceInfo, invoiceAccessPoint: value } as IContractorImportInvoiceInfo : { invoiceAccessPoint: value } as IContractorImportInvoiceInfo })}
                                    disabled={props.lockFromPermission}
                                />
                            </div>

                            <GridWrapper cols={2}>
                                <GridSelectorInput
                                    selectedEntity={internetOrderAccessPoint} 
                                    label={baseT('internetOrderAccessPoint')}
                                    id={"dictionaryInputContractorInternetOrderAccessPoint"}
                                    selectorModalJsx={AccessPointSelectorModal}
                                    onSelect={(entity) => {
                                        setInternetOrderAccessPoint(entity);
                                        let tmp = entity.idGlobal;
                                        setContractor({ ...contractor, idInternetOrderAccessPointExternalGlobal: tmp})
                                    }}
                                    onClear={()=> {
                                        setInternetOrderAccessPoint(undefined);
                                        setContractor({ ...contractor, idInternetOrderAccessPointExternalGlobal: ''})
                                    } }
                                    disabled={props.lockFromPermission}
                                />

                                <CheckBox label={baseT('isAutoImportInternetOrders')} id="checkboxContractorIsAutoImportInternetOrders"
                                    defaultChecked={contractor.isAutoImportInternetOrders}
                                    onChanged={() => setContractor({ ...contractor, isAutoImportInternetOrders: !contractor.isAutoImportInternetOrders })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>
                        </Accordion>
                        <Accordion opened={true} caption={baseT('contactData')} id="accordionContragentContacts" className={styles.contractorContacts} >
                            <div>
                                <TextInput
                                    value={contractor?.contactInfo?.phone}
                                    label={baseT('telephone')}
                                    onChange={(value) => setContractor({ ...contractor, contactInfo: contractor?.contactInfo ? { ...contractor.contactInfo, phone: value } as IContractorInfo : { phone: value } as IContractorInfo })}
                                    disabled={props.lockFromPermission}
                                />
                                <TextInput
                                    value={contractor?.contactInfo?.email}
                                    label={baseT('mail')}
                                    onChange={(value) => setContractor({ ...contractor, contactInfo: contractor?.contactInfo ? { ...contractor.contactInfo, email: value } as IContractorInfo : { email: value } as IContractorInfo })}
                                    disabled={props.lockFromPermission}
                                />
                            </div>
                            <div>
                                <TextAreaInput
                                    value={contractor?.contactInfo?.address}
                                    label={baseT('address')}
                                    onChange={(value) => setContractor({ ...contractor, contactInfo: contractor?.contactInfo ? { ...contractor.contactInfo, address: value } as IContractorInfo : { address: value } as IContractorInfo })}
                                    disabled={props.lockFromPermission}
                                />
                            </div>
                        </Accordion>
                        <Accordion opened={true} caption={baseT('additionalSettings')} id="accordionContragentAdditional" className={styles.contractorAdditional} >
                            <GridWrapper cols={3}>
                                <CheckBox label={baseT('affiliatedUndertaking')} id="checkboxContractorBranch"
                                    defaultChecked={contractor?.attributesInfo?.isFilial}
                                    onChanged={() => setContractor({ ...contractor, attributesInfo: { ...contractor.attributesInfo, isFilial: !contractor.attributesInfo.isFilial } })}
                                    disabled={props.lockFromPermission}
                                />
                                <CheckBox label={baseT('reliableSupplier')} id="checkboxContractorTrustedSupplier"
                                    defaultChecked={contractor?.attributesInfo?.trustedSupplier}
                                    onChanged={() => setContractor({ ...contractor, attributesInfo: { ...contractor.attributesInfo, trustedSupplier: !contractor.attributesInfo.trustedSupplier } })}
                                    disabled={props.lockFromPermission}
                                />
                                <CheckBox label={baseT('useVat')} id="checkboxContractorUseVat"
                                    defaultChecked={contractor.useVat}
                                    onChanged={() => setContractor({ ...contractor, useVat: !contractor.useVat })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <CheckBox label={baseT('commitent')} id="checkboxContractorUsesComitent"
                                    defaultChecked={contractor?.attributesInfo?.isComittent}
                                    onChanged={() => setContractor({ ...contractor, attributesInfo: { ...contractor.attributesInfo, isComittent: !contractor.attributesInfo.isComittent } })}
                                    disabled={props.lockFromPermission}
                                />
                                <CheckBox label={baseT('isDefaultSupplier')} id="checkboxContractorIsDefaultSupplier"
                                    defaultChecked={contractor?.isDefaultSupplier}
                                    onChanged={() => setContractor({ ...contractor, isDefaultSupplier: !contractor.isDefaultSupplier })} 
                                    disabled={props.lockFromPermission}
                                />
                            </GridWrapper>
                            <DictionarySelect
                                inline
                                disabled = {props.lockFromPermission}
                                label={baseT('timeZoneInfo')}
                                setValue={(value) => {
                                    setContractor({ ...contractor, timeZoneInfo: contractor?.timeZoneInfo ? 
                                        {...contractor.timeZoneInfo, idTimeZone: value, displayNameTimeZone: getDisplayNameOnIdTimeZone(value,timeZones)} as IContractorTimeZoneInfo : {idTimeZone: value, displayNameTimeZone: getDisplayNameOnIdTimeZone(value,timeZones)} as IContractorTimeZoneInfo })
                                }}
                                options= {mapToOptions(timeZones)} 
                                value={contractor?.timeZoneInfo?.idTimeZone}
                            />
                        </Accordion>
                        <Accordion opened={true} caption={baseT('markingSettings')} id="accordionContragentKiz" className={styles.contractorKiz} >
                            <CheckBox label={baseT('importFromMDLP')} id='importFromMdlp'
                                defaultChecked={contractor?.kizSettings?.importFromMdlp}
                                onChanged={() => setContractor({ ...contractor, kizSettings: { ...contractor.kizSettings, importFromMdlp: !contractor.kizSettings?.importFromMdlp } as IContractorKizSettings })}
                                disabled={props.lockFromPermission}
                            />
                            <TextInput
                                value={contractor?.kizSettings?.kizCode}
                                label={baseT('codeInTheMarkingSystem')}
                                onChange={(value) => setContractor({ ...contractor, kizSettings: { ...contractor.kizSettings, kizCode: value } as IContractorKizSettings })}
                                disabled={props.lockFromPermission}
                            />
                            <Select
                                className={styles.filterBy}
                                defaultOption={{ displayName: baseT('kizAcceptionType'), value: "", hidden: true }}
                                onSelect={(option) => {
                                    setKizAcceptionType(option)
                                    setContractor({ ...contractor, kizSettings: { ...contractor.kizSettings, kizAcceptionType: option.value } as IContractorKizSettings })
                                }}
                                value={kizAcceptionType}
                                options={[
                                    { displayName: kizOptionsBaseT('straight'), value: 0 },
                                    { displayName: kizOptionsBaseT('reverse'), value: 1 }
                                ]}
                                disabled={props.lockFromPermission}
                            />
                            <CheckBox label={baseT('isLiteMdlp')} id='isLiteMdlp'
                                defaultChecked={contractor?.kizSettings?.isLiteMdlp}
                                onChanged={() => setContractor({ ...contractor, kizSettings: { ...contractor.kizSettings, isLiteMdlp: !contractor.kizSettings?.isLiteMdlp } as IContractorKizSettings })}
                                disabled={props.lockFromPermission}
                            />
                        </Accordion>
                    </div>
                </div>

            {
                isAddLegalEntity && legalEntityForAdd &&
                <LegalEntityCreatorModal
                    variant='create'
                    data={legalEntityForAdd}
                    save={(model) => legalEntityDataProvider.create(model, (e) => {
                        saveAddLegalEntity(e)
                    })}
                    cancel={() => {
                        setIsAddLegalEntity(false)
                        setLegalEntityForAdd(undefined)
                    }}
                />
            }

            </BaseCreator>
        } </>
}

export default ContractorCreatorModal
