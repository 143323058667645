import { FC, useEffect, useReducer, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { GosContactCommandsPanel } from "../components/GosContactCommandsPanel";
import styles from "../../../styles/index.module.scss";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import classNames from "classnames";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { TabsPanel } from "../../../../components/tabs";
import { IInitialState, initialState, reducer } from "../state/GosContract/reducer";
import { usePluginContext } from "../../../../system/providers/plugin";
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel";
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker";
import { SearchModalWindow } from "../../../../components/modalWindows/SearchModalWindow";
import { DocumentType } from "../../../../@types/enumsGlobal";

const DefaultGridFilter = (datePeriod) => {
    return {
        numberPerPage: 10,
        pageNumber: 1,
        columnFilters: [
            ...(datePeriod?.startDate
                ? [
                      {
                          name: "dateCreated",
                          value: datePeriod.startDate.toFormat("yyyyMMdd"),
                          operator: "MoreOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
            ...(datePeriod?.endDate
                ? [
                      {
                          name: "dateCreated",
                          value: datePeriod.endDate.toFormat("yyyyMMdd"),
                          operator: "LessOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
        ],
    };
};

export const GosContractPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const tabContext = useTabsContext();
    const pluginCtx = usePluginContext();

    const gosContractDP = new GosContractDataProvider(appContext.coreApiService);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultGridFilter(state.DefaultDatePeriod));
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [modalWindow, setModalWindow] = useState(<></>);

    useEffect(() => {
        dispatch({ type: "changeSubmitting", payload: true });
        gosContractDP.getView(gridFilter, (data, totalCount) => {
            dispatch({ type: "getData", payload: { data: data, totalCount: totalCount } as IInitialState });
        });
    }, [gridFilter]);

    useEffect(() => {
        if (state.viewState == "refresh") {
            dispatch({ type: "changeSubmitting", payload: true });
            gosContractDP.getView(gridFilter, (data, totalCount) => {
                dispatch({
                    type: "getData",
                    payload: { data: data, totalCount: totalCount, viewState: "view" } as IInitialState,
                });
            });
        }
    }, [state.viewState]);

    useEffect(() => {
        renderModalWindow();
    }, [state.viewState]);

    function renderModalWindow() {
        switch (state.viewState) {
            case "search":
                renderSearchWindow();
                break;
            default:
                setModalWindow(<></>);
        }
    }

    function renderSearchWindow() {
        const visibleColumns = props.plugin?.columns.filter((x) => x.visibility === true) ?? [];
        setModalWindow(
            <SearchModalWindow
                columns={visibleColumns}
                cancel={() => dispatch({ type: "changeViewStateHandler", payload: "return" })}
                defaultFilters={gridFilter?.columnFilters}
                search={(columnFilters) => {
                    dispatchGridFilter({ type: "search", payload: columnFilters });
                    dispatch({ type: "refreshData" });
                }}
            />
        );
    }

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <GosContactCommandsPanel
                    add={{
                        onClick: () => {
                            tabContext.openChild("gosContract_create_plugin", "create", undefined, () => {
                                dispatch({ type: "refreshData" });
                            });
                        },
                    }}
                    delete={{
                        onClick: () => {
                            if (state.selectedItem)
                                gosContractDP.markDelete(state.selectedItem.idGlobal, () => {
                                    dispatch({ type: "refreshData" });
                                });
                        },
                        disabled: !state.selectedItem || state.data?.find((x) => x.idGlobal == state.selectedItem?.idGlobal)?.deleted,
                    }}
                    restore={{
                        onClick: () => {
                            if (state.selectedItem)
                                gosContractDP.restore(state.selectedItem.idGlobal, () => {
                                    dispatch({ type: "refreshData" });
                                });
                        },
                        disabled: !state.selectedItem || !state.data?.find((x) => x.idGlobal == state.selectedItem?.idGlobal)?.deleted,
                    }}
                    refresh={{ onClick: () => dispatch({ type: "refreshData" }) }}
                    edit={{
                        onClick: () => {
                            if (state.selectedItem)
                                tabContext.openChild("gosContract_create_plugin", "edit", state.selectedItem.idGlobal, () => {
                                    dispatch({ type: "refreshData" });
                                });
                        },
                        disabled: !state.selectedItem,
                    }}
                    copy={{
                        onClick: () => {
                            if (state.selectedItem)
                                tabContext.openChild("gosContract_create_plugin", "copy", state.selectedItem.idGlobal, () => {
                                    dispatch({ type: "refreshData" });
                                });
                        },
                        disabled: !state.selectedItem,
                    }}
                    search={{
                        onClick: () => {
                            if (state.selectedItem) {
                                dispatch({ type: "changeViewStateHandler", payload: "search" });
                            }
                        },
                        disabled: !state.selectedItem,
                    }}
                    permission={props.plugin.permission}
                />
                <DatePeriodPanel
                    datePeriod={state.DefaultDatePeriod}
                    onDatePeriodChange={(date: IDatePeriod) => {
                        dispatch({ type: "changeDate", payload: date });
                        dispatchGridFilter({
                            type: "sortByFilters",
                            payload: {
                                gridColumnFilter: [
                                    ...(date?.startDate
                                        ? [
                                              {
                                                  name: "dateCreated",
                                                  value: date.startDate.toFormat("yyyyMMdd"),
                                                  operator: "MoreOrEq",
                                              } as IGridColumnFilter,
                                          ]
                                        : []),
                                    ...(date?.endDate
                                        ? [
                                              {
                                                  name: "dateCreated",
                                                  value: date.endDate.toFormat("yyyyMMdd"),
                                                  operator: "LessOrEq",
                                              } as IGridColumnFilter,
                                          ]
                                        : []),
                                ],
                            },
                        });
                    }}
                />
            </ToolbarWrapper>
            {state.isSubmitting ? (
                <Spinner />
            ) : (
                <>
                    <DefaultGrid
                        gridId={props.gridId}
                        data={state.data}
                        separator                        
                        totalCount={state.totalCount}
                        filter={gridFilter}
                        dataProvider={gosContractDP}
                        plugin={props.plugin}
                        selectedItem={state.selectedItem}
                        onDoubleClick={(row) => props.onDoubleClick?.(row)}
                        onSelect={(row) => {
                            dispatch({ type: "setSelectedItem", payload: row });
                            row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.gosContract) : pluginCtx.masterGrid.onUnselectEvent();
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
                    {
                        <div className={styles.detailsTabView}>
                            {detailsTabsPanel.tabs.length > 0 && state.selectedItem && (
                                <>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                            <TabsPanel
                                                id="detailsTabsPanel"
                                                activeId={detailsTabsPanel.currentTab?.id}
                                                tabs={detailsTabsPanel.tabs}
                                                onActive={(id) => {
                                                    dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                                }}
                                            ></TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map((item) => {
                                            return (
                                                <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                    {item.view.content}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                </>
            )}
            {modalWindow}
        </PluginWrapper>
    );
};
