import { FC, useState, useEffect } from "react";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { IActDisagregationCreateDTO, IActDisagregationGetDTO, IActDisagregationUpdateDTO } from "../../../../libs/coreapi-dto/documents/actDisagregation";
import { IActDisagregationItemEditDTO, IActDisagregationItemGetDTO } from "../../../../libs/coreapi-dto/documents/actDisagregationItem";
import { ActDisagregationDataProvider } from "../../../../Services/DataProviders/ActDisagregationDataProvider";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { ActDisagregationUpdateForm } from "./ActDisagregationUpdateForm";
import { v4 as uuidv4 } from "uuid";
import { getActDisagregationItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ActDisagregationItemUpdateRequest";
import { DateTime } from "luxon";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";

interface ICreateDocument {
    callback: (actDisagregation: IActDisagregationCreateDTO, withClose: boolean, view: GridStateType) => void;
}

interface IEditDocument {
    actDisagregationDocument: IActDisagregationGetDTO;
    documentItemsUpdate: IActDisagregationItemEditDTO[];
    documentUpdate: IActDisagregationUpdateDTO;
    documentCopy?: IActDisagregationGetDTO;
}

const ActDisagregationCreatorView: FC<IDocumentCreatorProps<IActDisagregationCreateDTO>> = (props) => {
    const appCtx = useAppContext();
    const tabsContext = useTabsContext();

    const actDisagregationDP = new ActDisagregationDataProvider(appCtx.coreApiService);

    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [idGlobal, setIdGlobal] = useState<string | undefined>(props.idGlobal);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [createDocument, setCreateDocument] = useState<ICreateDocument | null>(null);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);

    useEffect(() => {
        if (viewState === "create") {
            const createCallback = (actDisagregation: IActDisagregationCreateDTO, withClose: boolean, view: GridStateType) => {
                setIsSubmitting(true);
                actDisagregationDP.create(actDisagregation, (e) => {
                    setIdGlobal(e);
                    setViewState(view);
                    props.createdCallback?.();
                    withClose && tabsContext.closeCurrent();
                    setIsSubmitting(false);
                }, ()=>{}, (e)=>{
                    setIsSubmitting(false);
                });
            }
            setCreateDocument({ callback: createCallback });
        }
        
        if (viewState === "edit") {
            actDisagregationDP.getById(idGlobal as string, (ActDisagregationDocument) => {
                getActDisagregationItemUpdateDTO(ActDisagregationDocument.idGlobal as string, (e) => {
                    const itemsUpdate: IActDisagregationItemEditDTO[] = [];
                    e.forEach((item) => {
                        itemsUpdate.push({
                            idGlobal: item.idGlobal,
                            barCode: item.barCode,
                            denominator: item.denominator,
                            gtdNumber: item.gtdNumber,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idLotFromGlobal: item.idLotFromGlobal,
                            idScalingRatioFromGlobal: item.idScalingRatioFromGlobal,
                            idScalingRatioToGlobal: item.idScalingRatioToGlobal,
                            kizBoxCount: item.kizBoxCount,
                            kizCount: item.kizCount,
                            numerator: item.numerator,
                            productMargin: item.productMargin,
                            quantity: item.quantity,
                            quantityFrom: item.quantityFrom,
                            registerPrice: item.registerPrice,
                            retailCostInfo: item.retailCostInfo,
                            retailPriceIncVat: item.retailPriceIncVat,
                            supplierCostInfo: item.supplierCostInfo,
                            isGnvls: item.isGnvls,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizBoxes: item.kizBoxes,
                            kizs: item.kizs,
                        } as IActDisagregationItemEditDTO);
                    });

                    const documentUpdate: IActDisagregationUpdateDTO = {
                        idGlobal: ActDisagregationDocument.idGlobal as string,
                        documentDate: ActDisagregationDocument.documentDate,
                        idStoreGlobal: ActDisagregationDocument.store.idGlobal,
                        comment: ActDisagregationDocument.comment,
                        idUserGlobal: undefined,
                        idGosContractGlobal: ActDisagregationDocument?.gosContract?.idGlobal ?? undefined,
                        isKizUnpackRecursive: ActDisagregationDocument.isKizUnpackRecursive,
                        isNewBarcode: ActDisagregationDocument.isNewBarcode,
                        items: itemsUpdate,
                    };

                    setEditDocument({
                        actDisagregationDocument: ActDisagregationDocument,
                        documentItemsUpdate: itemsUpdate,
                        documentUpdate: documentUpdate,
                    });
                });
            });
        }

        if (viewState === "copy") {
                
                const itemsUpdate: IActDisagregationItemEditDTO[] = [];
                    
                const idGlobalNew = uuidv4();
                const documentUpdate: IActDisagregationUpdateDTO = {
                    idGlobal: idGlobalNew,
                    documentDate: DateTime.now(),
                    items: itemsUpdate,
                    isKizUnpackRecursive: false,
                    isNewBarcode: false
                };

                const documentCopy: IActDisagregationGetDTO = {
                    idGlobal: idGlobalNew,
                    documentDate: DateTime.now(),
                    store: {} as IEntitySimpleDTO,
                    amount: 0,
                    amountVat: 0,
                    isKizUnpackRecursive: false,
                    isNewBarcode: false,
                    dateCreated: DateTime.now(),
                    items: [],
                    displayName: "",
                    documentState: "",
                    documentStateModified: DateTime.now(),
                    mnemocode: ""
                };

                setEditDocument({
                    actDisagregationDocument: documentCopy,
                    documentItemsUpdate: itemsUpdate,
                    documentUpdate: documentUpdate,
                    documentCopy: documentCopy,
                });   
        }
    }, [viewState]);

    return (
        <>
            {viewState === "create" ? (
                createDocument && (
                    <ActDisagregationUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        idGlobal={props.idGlobal}
                        documentItems={null}
                        documentUpdate={null}
                        variant="create"
                        save={(actDisagregation: IActDisagregationCreateDTO) => createDocument.callback(actDisagregation, false, "edit")}
                        ok={(actDisagregation: IActDisagregationCreateDTO) => createDocument.callback(actDisagregation, true, "create")}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        permission={props.permission}
                    />
                )
            ) : viewState === "edit" ? (
                editDocument && (
                    <ActDisagregationUpdateForm
                        isSubmitting={isSubmitting}
                        variant="edit"
                        save={(actDisagregation: IActDisagregationUpdateDTO) => {
                            setIsSubmitting(true);
                            actDisagregationDP.update(idGlobal as string, actDisagregation, (e) => {
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            });
                        }}
                        ok={(actDisagregation: IActDisagregationUpdateDTO) => {
                            setIsSubmitting(true);
                            actDisagregationDP.update(idGlobal as string, actDisagregation, (e) => {
                                props.createdCallback?.();
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                setIsSubmitting(false);
                            });
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        document={editDocument.actDisagregationDocument}
                        documentItems={editDocument.actDisagregationDocument.items}
                        documentUpdate={editDocument.documentUpdate}
                        permission={props.permission}
                    />
                )
            ) : viewState === "copy" ? (
                editDocument && (
                    <ActDisagregationUpdateForm
                        isSubmitting={isSubmitting}
                        variant="copy"
                        save={(actDisagregation: IActDisagregationUpdateDTO) => {
                            setIsSubmitting(true);
                            actDisagregationDP.create(actDisagregation, (e) => {
                                setIdGlobal(e);
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        ok={(actDisagregation: IActDisagregationUpdateDTO) => {
                            setIsSubmitting(true);
                            actDisagregationDP.create(actDisagregation, (e) => {
                                setIdGlobal(e);
                                props.createdCallback?.();
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab);
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            });
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        document={editDocument.documentCopy as IActDisagregationGetDTO}
                        documentItems={editDocument.documentCopy?.items as IActDisagregationItemGetDTO[]}
                        documentUpdate={editDocument.documentUpdate}
                        permission={props.permission}
                    />
                )
            ) : (
                <></>
            )}
        </>
    );
};

export default ActDisagregationCreatorView;
