import { FC, useEffect, useState } from "react";
import styles from './select.module.scss'
import classNames from "classnames";
import { useBarcodeReaderContext } from "../../system/providers/barcodeReaderGlobal";

export interface IOption {
    displayName: string
    value: any
    hidden?: boolean
    propertyType?: string
}

interface ISelectProps {
    defaultOption?: IOption
    options?: IOption[]
    onSelect: (value: IOption) => void
    value?: IOption
    disabled?: boolean
    barcodeScan?: boolean
    className?: string
    label?: string
    inline?: boolean
}

export const Select: FC<ISelectProps> = (props) => {

    const [option, setOption] = useState<IOption | undefined>();
    const barcodeReaderContext = useBarcodeReaderContext();

    useEffect(() => {
        if (option)
            props.onSelect?.(option)
    }, [option])

    useEffect(() => {
        if (props.barcodeScan && barcodeReaderContext.barcodeScanFlag) {
            if (barcodeReaderContext.barcode.length === 13 && props.options?.find((item) => item.value === "internalBarcode") && barcodeReaderContext.barcode.startsWith('2000')) {
                props.onSelect?.(props.options?.find((item) => item.value === "internalBarcode") as IOption);
            } else if (barcodeReaderContext.barcode.length === 13 && props.options?.find((item) => item.value === "barcodeCode")) {
                props.onSelect?.(props.options?.find((item) => item.value === "barcodeCode") as IOption);
            } else if (barcodeReaderContext.barcode.length === 13 && props.options?.find((item) => item.value === "barcode")) {
                props.onSelect?.(props.options?.find((item) => item.value === "barcode") as IOption);
            } else if ((barcodeReaderContext.barcode.length === 20 || barcodeReaderContext.barcode.length === 28 || barcodeReaderContext.barcode.length === 32 || barcodeReaderContext.barcode.length === 83 || barcodeReaderContext.barcode.length === 85  || barcodeReaderContext.barcode.length === 86) && props.options?.find((item) => item.value === "kizOrKizBox")) {
                props.onSelect?.(props.options?.find((item) => item.value === "kizOrKizBox") as IOption);
            } else if (barcodeReaderContext.barcode.length === 20 && props.options?.find((item) => item.value === "KizBox")) {
                props.onSelect?.(props.options?.find((item) => item.value === "KizBox") as IOption);
            } else {
                if (option)
                    props.onSelect?.(option)
            }
        }
    }, [barcodeReaderContext.barcodeScanFlag]);

    return (
        <div className={classNames(styles.select, props.className, props.inline && styles.selectInline)}>
            {props.label && 
                <div>
                    <label className={styles.select__label}>
                        {props.label}
                    </label>
                </div>
            }
            <select 
                className={classNames(styles.select__custom, props.disabled && styles.select__customDisabled)} 
                defaultValue={props.defaultOption?.value} 
                disabled={props.disabled}
                onChange={(event) => {
                    if (props.options && props.options.length > 0) {
                        const opt = props.options[event.currentTarget.selectedIndex];
                        setOption(opt)
                    }
                }}
                value={props.value?.value}
            >
                {
                    props.options &&
                    props.options.length > 0 &&
                    props.options.map((item, index) => {
                        return <option key={index} value={item.value} hidden={item.hidden}> {item.displayName} </option>
                    })
                }
            </select>
        </div>
    )
}