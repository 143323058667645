import axios from "axios"
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IImportRemainsGetDTO } from "../../coreapi-dto/documents/importRemains";
import { IImportRemainsItemEditDTO, IImportRemainsItemGetDTO } from "../../coreapi-dto/documents/importRemainsItem";
import { envF } from "../../../env";

export const getImportRemainsItemUpdateDTO = async (idDocument: string, callback: (e) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`
    }
    const generateGoodsByIdRequestUrl = (idGoods) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`
    }

    const generateImportRemainsRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/ImportRemains/${idDocument}`
    }

    let importRemains = await axios.get<IImportRemainsGetDTO>(
        generateImportRemainsRequestUrl(),
        header
    )

    let items: IImportRemainsItemEditDTO[] = []

    const itemsPush = async (x: IImportRemainsItemGetDTO) => {
        if (!x.isKiz) {
            await axios.get<IGoodsDTO>(
                generateGoodsByIdRequestUrl(x.goods.idGlobal),
                header
            ).then((goods) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioGlobal: x.scalingRatio.idGlobal,
                    idSeriesGlobal: x.series?.idGlobal,
                    quantity: x.quantity,
                    isOsu: x.isOsu,
                    kizOsuDto: x.kizOsuDto,
                    producerPrice: x.producerPrice,
                    productMargin: x.productMargin,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    supplierGoodsCode: x.supplierGoodsCode,
                    gtdNumber: x.gtdNumber,
                    barCode: x.barCode,
                    registerPrice: x.registerPrice,
                    retailPriceIncVat: x.retailPriceIncVat,
                    isKiz: x.isKiz,
                    isKizBox: x.isKizBox,
                    isGnvls: goods.data.gnvls.important,
                    kizBoxCount: x.kizBoxCount,
                    kizCount: x.kizCount,
                    idSupplierGlobal: x.supplier.idGlobal,
                    supplierDocDate: x.supplierDocDate,
                    supplierDocNumber: x.supplierDocNumber,      
                    denominator: x.denominator,
                    numerator: x.numerator,
                    dateCreated: x.dateCreated
                })
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then(async (e) => {
                await axios.get<IGoodsDTO>(
                    generateGoodsByIdRequestUrl(x.goods.idGlobal),
                    header
                ).then((goods) => {
                    items.push({
                        idGlobal: x.idGlobal as string,
                        idGoodsGlobal: x.goods.idGlobal,
                        idScalingRatioGlobal: x.scalingRatio.idGlobal,
                        idSeriesGlobal: x.series?.idGlobal,
                        quantity: x.quantity,
                        isOsu: x.isOsu,
                        kizOsuDto: x.kizOsuDto,
                        producerPrice: x.producerPrice,
                        productMargin: x.productMargin,
                        supplierCostInfo: x.supplierCostInfo,
                        retailCostInfo: x.retailCostInfo,
                        supplierGoodsCode: x.supplierGoodsCode,
                        gtdNumber: x.gtdNumber,
                        barCode: x.barCode,
                        registerPrice: x.registerPrice,
                        retailPriceIncVat: x.retailPriceIncVat,
                        isKiz: x.isKiz,
                        isKizBox: x.isKizBox,
                        kizs: e.data.items[0]?.kizes ?? [],
                        kizBoxes: e.data.items[0]?.kizBoxes ?? [],
                        isGnvls: goods.data.gnvls.important,
                        kizBoxCount: x.kizBoxCount,
                        kizCount: x.kizCount,
                        idSupplierGlobal: x.supplier.idGlobal,
                        supplierDocDate: x.supplierDocDate,
                        supplierDocNumber: x.supplierDocNumber,
                        denominator: x.denominator,
                        numerator: x.numerator,
                        dateCreated: x.dateCreated
                    })
                })

            })
        }
    }

    const addItems = async () => {
        const unresolvedPromises = importRemains.data.items.map((x) => itemsPush(x));
        const results = await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items))

}