export const ContractorGridPluginSettings: IPluginSettings = {
    name: 'Контрагенты',
    mnemocode: 'contractor_plugin',
    permission: 'Contractor',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 157
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: false,
        width: 157
    }, {
        order: 2,
        propertyName: 'fullName',
        propertyType: 'string',
        displayName: 'Наименование полное',
        visibility: true,
        width: 157
    }, {
        order: 3,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 157
    }, {
        order: 4,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код',
        visibility: true,
        width: 157
    }, {
        order: 5,
        propertyName: 'exportAccessPoint',
        propertyType: 'string',
        displayName: 'Точка доступа',
        visibility: true,
        width: 157
    }, {
        order: 6,
        propertyName: 'useVat',
        propertyType: 'boolean',
        displayName: 'Плательщик НДС',
        visibility: true,
        width: 157
    }, {
        order: 7,
        propertyName: 'updateInformAccessPoint',
        propertyType: 'string',
        displayName: 'Обновление в форме точки доступа',
        visibility: true,
        width: 157
    }, {
        order: 8,
        propertyName: 'daysOfDeferredPayment',
        propertyType: 'number',
        displayName: 'Отсрочка платежей',
        visibility: true,
        width: 157
    }, {
        order: 9,
        propertyName: 'sno',
        propertyType: 'number',
        displayName: 'СНО',
        visibility: true,
        width: 157
    }, {
        order: 10,
        propertyName: 'legalEntityName',
        propertyType: 'string',
        displayName: 'Юридическое лицо',
        visibility: true,
        width: 157
    }, {
        order: 11,
        propertyName: 'address',
        propertyType: 'string',
        displayName: 'Адрес',
        visibility: false,
        width: 157
    }, {
        order: 12,
        propertyName: 'phone',
        propertyType: 'string',
        displayName: 'Телефон',
        visibility: false,
        width: 157
    }, {
        order: 13,
        propertyName: 'email',
        propertyType: 'string',
        displayName: 'e-mail',
        visibility: false,
        width: 157
    }, {
        order: 14,
        propertyName: 'importProvider',
        propertyType: 'string',
        displayName: 'Драйвер импорта',
        visibility: false,
        width: 157
    }, {
        order: 15,
        propertyName: 'xmlDetail',
        propertyType: 'string',
        displayName: 'Настройки ',
        visibility: false,
        width: 157
    }, {
        order: 16,
        propertyName: 'invoiceLoaderSettingsFileName',
        propertyType: 'string',
        displayName: 'Загрузчик',
        visibility: false,
        width: 157
    }, {
        order: 17,
        propertyName: 'invoiceAccessPoint',
        propertyType: 'string',
        displayName: 'Точка доступа для ПН',
        visibility: false,
        width: 157
    }, {
        order: 18,
        propertyName: 'isFilial',
        propertyType: 'boolean',
        displayName: 'Филиал',
        visibility: false,
        width: 157
    }, {
        order: 19,
        propertyName: 'isComittent',
        propertyType: 'boolean',
        displayName: 'Комитент',
        visibility: false,
        width: 157
    }, {
        order: 20,
        propertyName: 'trustedSupplier',
        propertyType: 'boolean',
        displayName: 'Доверенный поставщик',
        visibility: false,
        width: 157
    }, {
        order: 21,
        propertyName: 'kizCode',
        propertyType: 'string',
        displayName: 'Код МДЛП',
        visibility: false,
        width: 157
    }, {
        order: 22,
        propertyName: 'importFromMdlp',
        propertyType: 'boolean',
        displayName: 'Получать из МДЛП',
        visibility: false,
        width: 157

    }, 
	{
        order: 23,
        propertyName: 'isLiteMdlp',
        propertyType: 'boolean',
        displayName: 'Уведомительный режим МДЛП',
        visibility: true,
        width: 300
    },
	{
        order: 24,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удален',
        visibility: false,
        width: 157
    }, {
        order: 25,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 26,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 27,
        propertyName: 'isMe',
        propertyType: 'boolean',
        displayName: 'Контрагент "Мы"',
        visibility: true,
        width: 57
    }, {
        order: 28,
        propertyName: 'isDefaultSupplier',
        propertyType: 'boolean',
        displayName: 'Поставщик по-умолчанию',
        visibility: true,
        width: 157
    }, {
        order: 29,
        propertyName: 'displayNameTimeZone',
        propertyType: 'string',
        displayName: 'Часовой пояс',
        visibility: true,
        width: 157
    }
],
}

export default ContractorGridPluginSettings