import { FC, SetStateAction, useRef, useState } from "react";
import { IColumnToSave, IPage } from "../default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { Tooltip } from "antd";
import classNames from "classnames";
import { MultiplyIcon } from "../../../libs/corporate/icons/outlined/suggested/SymbolCollection";

interface IClsProps {
    tD: string;
    tooltip: string;
    sortingDiv: string;
    sortingDivButton: string;
    filteredDiv: string;
}

interface ITableHeaderProps {
    index: number;
    draggable: boolean;
    item: IGridColumn;
    filtered: boolean;
    pluginSettings: IPluginSettings;
    setGridColumnsToSave: (value: SetStateAction<IColumnToSave>) => void;
    sorting: boolean;
    onSort: (column: IGridColumn) => void;
    renderSortIcon: (item: IGridColumn) => JSX.Element;
    onFilterDelete: (column: IGridColumn) => void;
    onPageNumberChange?: (pageNumber: number) => void;
    setPageNumber?: (value: SetStateAction<IPage>) => void;
    setLoadingStatus?: ((value: LoadingStatus) => void) | undefined;
    filter?: IGridFilter;
    cls: IClsProps;
}


export const TableHeader: FC<ITableHeaderProps> = ({
    index,
    draggable,
    item,
    filtered,
    pluginSettings,
    setGridColumnsToSave,
    sorting,
    onSort,
    renderSortIcon,
    onFilterDelete,
    onPageNumberChange,
    setPageNumber,
    setLoadingStatus,
    filter,
    cls 
}) => {
    const [spanWidth, setSpanWidth] = useState<boolean>(false);
    const TableHeaderRef = useRef<HTMLTableCellElement>(null)

    const actionForRef = (ref) => {
        if (ref && ref.current) {
            if(sorting) {
                const tdElement = ref.current;
                const tdElementCss = getComputedStyle(tdElement);
                const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight))
                
                const span = tdElement.querySelector('span');
                const spanWidth = span.scrollWidth;
                
                const div = tdElement.querySelector('div');
                const divWidth = div.scrollWidth;
    
                const spanWithDivWidth = spanWidth + divWidth;
                spanWithDivWidth > tdWidth ? setSpanWidth(true) : setSpanWidth(false)
            } else {
                const tdElement = ref.current;
                const tdElementCss = getComputedStyle(tdElement);
                const tdWidth = tdElement.offsetWidth - (parseFloat(tdElementCss.paddingLeft) + parseFloat(tdElementCss.paddingRight))
                
                const span = tdElement.querySelector('span');
                const spanWidth = span.scrollWidth;
                spanWidth > tdWidth ? setSpanWidth(true) : setSpanWidth(false)
            }
           ;
        }
    };

    return (
        <td
            ref={TableHeaderRef}
            onMouseEnter={() => actionForRef(TableHeaderRef)}
            key={index}
            draggable={draggable}
            className={cls.tD}
            data-order-number={item.order}
            data-property-name={item.propertyName}
            data-filter-selected={filtered}
            style={{ width: item.width }}
            onDragStart={(e) => {
                e.dataTransfer.dropEffect = "move";
                e.dataTransfer.setData("application/json", JSON.stringify(item));
            }}
            onDragOver={(e) => {
                e.preventDefault();
                return false;
            }}
            onDrop={(e) => {
                let firstColumn = JSON.parse(e.dataTransfer.getData("application/json")) as IGridColumn;
                let firstColumnIndex = pluginSettings.columns.findIndex((x) => x.order === firstColumn.order);

                let secondColumnOrder: number = Number((e.currentTarget as HTMLElement).getAttribute("data-order-number"));
                let secondColumn = pluginSettings.columns.find((x) => x.order === secondColumnOrder) as IGridColumn;
                let secondColumnIndex = pluginSettings.columns.findIndex((x) => x.order === secondColumnOrder);

                let columns = pluginSettings.columns;

                columns[firstColumnIndex] = { ...secondColumn, order: firstColumn.order };
                columns[secondColumnIndex] = { ...firstColumn, order: secondColumnOrder };
                setGridColumnsToSave({ columns: columns, save: true });
            }}
        >
            <Tooltip className={cls.tooltip} title={spanWidth && item.displayName}>
                {item.displayName}
            </Tooltip>

            {sorting && (
                <div draggable={false} className={classNames(cls.sortingDiv)}>
                    <div
                        draggable={false}
                        className={cls.sortingDivButton}
                        onClick={() => {
                            onSort(item);
                            if(onPageNumberChange && setPageNumber && filter && setLoadingStatus) {
                                onPageNumberChange(1);
                                setPageNumber({
                                    pageNumber: filter.pageNumber,
                                    prevPageNumber: filter.pageNumber,
                                });
                                setLoadingStatus(LoadingStatus.InProcess);
                            }
                        }}
                    >
                        {renderSortIcon(item)}
                    </div>
                    {filtered && (
                        <div draggable={false} className={cls.filteredDiv} onClick={() => onFilterDelete(item)}>
                            {" "}
                            <MultiplyIcon />{" "}
                        </div>
                    )}
                </div>
            )}
        </td>
    );
};
