import { ContentType } from "../../../libs/fetchApiDistributor/contentTypes";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, IRequestEventHandler } from "../common";

export class BaseDocRequestV2<TDocumentViewDtoPagenatedView, TDocumentGetDTO, TDocumentCreateDTO, IDocumentUpdateDTO, TDocumentItemViewDtoPagenatedView, TDocumentItemGetDto>
    extends BaseCoreApiRequest
    implements IDocumentRequestV2<TDocumentViewDtoPagenatedView, TDocumentGetDTO, TDocumentCreateDTO, IDocumentUpdateDTO, TDocumentItemViewDtoPagenatedView, TDocumentItemGetDto> {
    protected _routePrefix: string
    protected _eventHandler: IRequestEventHandler | null | undefined;
    protected readonly _token: string

    constructor(baseUrl: string, routePrefix: string, token: string, eventHandler?: IRequestEventHandler | null) {
        super(baseUrl)
        this._routePrefix = routePrefix
        this._eventHandler = eventHandler
        this._token = token;
    }
    getView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<TDocumentViewDtoPagenatedView>>,) => void) {
        this.post<IViewWrapper<TDocumentViewDtoPagenatedView>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }

        })
    }

    getById(id: string, callback?: (e: CoreApiResponse<TDocumentGetDTO>) => void) {
        this.get<TDocumentGetDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
        })
    }

    create(body: TDocumentCreateDTO, callback: (e: CoreApiResponse<string>, testApi?: { name: string, time: number }) => void) {
        super.post<string>(`${this._baseUrl}/${this._routePrefix}/Create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                    callback?.(e);
                } else {
                    callback?.(e, { name: `${this._routePrefix}/Create`, time: e.time as number });
                }

            }
        })
    }

    update(idGlobal: string, body: IDocumentUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<boolean|null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Delete`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markItemDelete(idGlobal: string, idItemGlobal: string, callback: (e: CoreApiResponse<boolean>) => void) {
        super.delete2(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Item/${idItemGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Restore`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    process(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Process`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    unProcess(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/UnProcess`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getItemsView(idGlobal: string, body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<TDocumentItemViewDtoPagenatedView>>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.post<IViewWrapper<TDocumentItemViewDtoPagenatedView>>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/item/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/${idGlobal}/item/View`, time: new Date().getTime() - startTime })
        })
    }

    getItemById(idGlobal: string, idItemGlobal: string, callback?: (e: CoreApiResponse<TDocumentItemGetDto>) => void, testApi?: ({ name, time }) => void) {
        const startTime = new Date().getTime();
        this.get<TDocumentItemGetDto>(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/item/${idItemGlobal}`, [this.getBearerHeader(this._token)], (e) => {
            callback?.(e);
            testApi?.({ name: `${this._routePrefix}/${idGlobal}/item/${idItemGlobal}`, time: new Date().getTime() - startTime })
        })
    }


}