const ActDisassemblingItemCreatorGridPluginSettings: IPluginSettings = {
  name: 'Акты разукомплектации',
  mnemocode: 'act_disassembling_item_creator_plugin',
  columns: [
    {
      order: 0,
      propertyName: 'idGlobal',
      propertyType: 'string',
      displayName: 'idGlobal',
      visibility: false,
      width: 100,
    },
    {
      order: 1,
      propertyName: 'goodsName',
      propertyType: 'string',
      displayName: 'Товар',
      visibility: true,
      width: 150,
    },
    {
      order: 2,
      propertyName: 'quantityFrom',
      propertyType: 'number',
      displayName: 'Исходное количество',
      visibility: true,
      width: 150,
      changeable: true,
    },
    {
      order: 3,
      propertyName: 'quantity',
      propertyType: 'number',
      displayName: 'Количество',
      visibility: true,
      width: 150,
    },

    {
      order: 4,
      propertyName: 'scalingRatioFrom',
      propertyType: 'string',
      displayName: 'Исходная упаковка',
      visibility: true,
      width: 150,
    },
    {
      order: 5,
      propertyName: 'scalingRatioTo',
      propertyType: 'string',
      displayName: 'Упаковка',
      visibility: true,
      width: 150,
    },
    {
      order: 6,
      propertyName: 'supplierPrice',
      propertyType: 'number',
      displayName: 'Цена поставщика',
      visibility: true,
      width: 150,
    },
    {
      order: 7,
      propertyName: 'retailPrice',
      propertyType: 'number',
      displayName: 'Цена розничная',
      visibility: true,
      width: 150,
    },
    {
      order: 8,
      propertyName: 'retailPriceIncVat',
      propertyType: 'number',
      displayName: 'Цена розничная c НДС',
      visibility: true,
      width: 150,
    },
    {
      order: 9,
      propertyName: 'retailSumWithVat',
      propertyType: 'number',
      displayName: 'Сумма розничная с НДС',
      visibility: true,
      width: 150,
    },
    {
      order: 10,
      propertyName: 'supplierSum',
      propertyType: 'number',
      displayName: 'Сумма поставщика',
      visibility: true,
      width: 150,
    },
    {
      order: 11,
      propertyName: 'isKiz',
      propertyType: 'boolean',
      displayName: 'Ввод киз',
      visibility: true,
      width: 150,
    },
    {
      order: 12,
      propertyName: 'isGnvls',
      propertyType: 'boolean',
      displayName: 'ЖНВЛС',
      visibility: true,
      width: 150,
    },
    {
      order: 13,
      propertyName: 'countKizScan',
      propertyType: 'number',
      displayName: 'Кол-во отсканированных кизов',
      visibility: true,
      width: 150,
    },
    // {
    //   order: 14,
    //   propertyName: 'supplier',
    //   propertyType: 'string',
    //   displayName: 'Поставщик',
    //   visibility: true,
    //   width: 150,
    // },
  ],
};

export default ActDisassemblingItemCreatorGridPluginSettings;
