import { FC, useEffect, useState } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { KizInventoryDataProvider } from "../../../../Services/DataProviders/KizInventoryDataProvider";
import { LoadingStatus } from "../../../../@types/enumsGlobal";
import { IKizInventoryItemCorrectViewDTO } from "../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { KizInventoryItemCorrectSelectorModalCommandsPanel } from "../components/KizInventoryItemCorrectSelectorModalCommandsPanel";
import { Spinner } from "../../../../components/spiner/Spinner";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";

export const KizInventoryItemCorrectGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const kizInventoryDataProvider = new KizInventoryDataProvider(appCtx.coreApiService);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [data, setData] = useState<IKizInventoryItemCorrectViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    
    useEffect(() => {
        setIsSubmitting(true)
        //console.log(props.masterIdGlobal)
        kizInventoryDataProvider.getItemsCorrectView(props.masterIdGlobal as string, gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            <ToolbarWrapper>
                <KizInventoryItemCorrectSelectorModalCommandsPanel
                    selectedItem={[selectedItem, setSelectedItem]}
                    gridFilter={gridFilter}
                    dispatchGridFilter={(value) => { dispatchGridFilter(value) }}
                    //dataProvider={kizInventoryDataProvider}
                    //creatorModalJsx={ContractorCreatorModal}
                    pluginSettings={props.plugin}
                    setViewState={(vs) => setViewState(vs)}
                />
            </ToolbarWrapper>

            {
                isSubmitting ?
                    <Spinner />
                    :
                    <DefaultGrid
                        gridId={props.gridId}
                        separator
                        singleDirectory
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        filter={gridFilter}
                        totalCount={totalCount}
                        dataProvider={kizInventoryDataProvider}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        getView={(gridFilter,callback) => {
                            kizInventoryDataProvider.getItemsCorrectView(props.masterIdGlobal as string, gridFilter, (entities) => {
                                callback(entities)
                                setSelectedItem(undefined);
                            })
                        }}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row, data.find(x => x.idGlobal === row?.idGlobal))
                        }}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows)
                            props?.onMultipleSelect?.(rows)
                        }}
                        multipleSelect={props.multipleSelect}
                        plugin={props.plugin}
                        onDoubleClick={(row) => props.onDoubleClick?.(row, data.find(x => x.idGlobal === row.idGlobal))}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
            }
        </PluginWrapper>
    )
}