import {IGoodsDTO, IGoodsViewDTO } from "../../../libs/coreapi-dto/dirs/goods";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
//import {IGoodsAttributes, IGoodsAssortmentPlanning,IGoodsGnvls,IGoodsName,IGoodsOtherCodes,IGoodsPacking } from "../../../libs/coreapi-dto/dirs/goods";

export interface IGoodsByIdDTO extends IGoodsDTO {
    readonly idGlobal: string
}

class GoodsRequest extends BaseDirRequest<IGoodsViewDTO, IGoodsDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Goods", token, eventHandler)
    }
    
    attachToGroup(idGoodsGlobal:string, idGoodsGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGoodsGlobal}/AttachToGroup/${idGoodsGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    moveFromGroup(idGoodsGlobal:string, fromIdGoodsGroupGlobal: string, toIdGoodsGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGoodsGlobal}/MoveFromGroup/${fromIdGoodsGroupGlobal}/To/${toIdGoodsGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    detachFromGroup(idGoodsGlobal:string, idGoodsGroupGlobal: string, callback: ( e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGoodsGlobal}/DetachFromGroup/${idGoodsGroupGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
    getGoodsByBarcode(externalBarcode: string, callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetByExternalBarcode/${externalBarcode}`,this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),  e => { 
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        })
    }
    getGoodsByCode(code: string, callback: (e) => void) {
        this.get(`${this._baseUrl}/${this._routePrefix}/GetByCode/${code}`,this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]),  e => { 
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        })
    }

    getViewByName(body: IViewBody, callback: (e: CoreApiResponse<IViewWrapper<IGoodsViewDTO>>) => void, signal?: AbortSignal | null) {        
        this.post<IViewWrapper<IGoodsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewByName`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
            callback(e);
        }, signal)
    }
}

export default GoodsRequest