export const ScanBoxCreatePluginSettings: IPluginSettings = {
    name: 'Короба',
    mnemocode: 'scan_box_plugin',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 250
        }, {
            order: 1,
            propertyName: 'barcode',
            propertyType: 'string',
            displayName: 'Зашифрованный SSCC',
            visibility: false,
            width: 250
        }, {
            order: 2,
            propertyName: 'sscc',
            propertyType: 'string',
            displayName: 'SSCC',
            visibility: true,
            width: 250
        }, {
            order: 3,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Количество в коробе',
            visibility: true,
            width: 250,
            changeable: true
        },
        {
            order: 4,
            propertyName: 'idError',
            propertyType: 'string',
            displayName: 'Код ошибки',
            visibility: true,
            width: 100
        },
        {
            order: 5,
            propertyName: 'kizState',
            propertyType: 'string',
            displayName: 'Статус SSCC',
            visibility: true,
            width: 100
        },
        {
            order: 6,
            propertyName: 'lotName',
            propertyType: 'string',
            displayName: 'Товар',
            visibility: true,
            width: 100
        },
        {
            order: 7,
            propertyName: 'code',
            propertyType: 'string',
            displayName: 'Код товара',
            visibility: false,
            width: 100
        },
        {
            order: 8,
            propertyName: 'docType',
            propertyType: 'string',
            displayName: 'Схема',
            visibility: false,
            width: 100
        },
        {
            order: 9,
            propertyName: 'error',
            propertyType: 'string',
            displayName: 'Ошибка ГИС',
            visibility: true,
            width: 100
        },
    ]
}