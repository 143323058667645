import classNames from "classnames"
import { FC } from "react"
import styles from '../../styles/icons.module.scss'

export const ArchiveIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 8V21H3V8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 3H1V8H23V3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 12H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const PrinterIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9V2H18V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V11C2 10.4696 2.21071 9.96086 2.58579 9.58579C2.96086 9.21071 3.46957 9 4 9H20C20.5304 9 21.0391 9.21071 21.4142 9.58579C21.7893 9.96086 22 10.4696 22 11V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18 14H6V22H18V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const MailIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 6L12 13L2 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const InboxIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12H16L14 15H10L8 12H2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.45 5.11L2 12V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V12L18.55 5.11C18.3844 4.77679 18.1292 4.49637 17.813 4.30028C17.4967 4.10419 17.1321 4.0002 16.76 4H7.24C6.86792 4.0002 6.50326 4.10419 6.18704 4.30028C5.87083 4.49637 5.61558 4.77679 5.45 5.11V5.11Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}

export const AtSignIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 7.99999V13C16 13.7956 16.3161 14.5587 16.8787 15.1213C17.4413 15.6839 18.2044 16 19 16C19.7957 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V12C21.9999 9.74302 21.2362 7.55247 19.8333 5.78452C18.4303 4.01658 16.4706 2.77521 14.2726 2.26229C12.0747 1.74936 9.76794 1.99503 7.72736 2.95936C5.68677 3.92368 4.03241 5.54995 3.03327 7.57371C2.03413 9.59748 1.74898 11.8997 2.22418 14.1061C2.69938 16.3125 3.90699 18.2932 5.65064 19.7263C7.39429 21.1593 9.57144 21.9603 11.8281 21.9991C14.0847 22.0379 16.2881 21.3122 18.08 19.94" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}
export const SquarePlusIcon: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 8V16" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 12H16" stroke="#495057" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    </>
}
export const SquarePlusMinus: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 9L15 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 9L9 15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </>
}
export const RoundPlusMinus: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 9L9 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 9L15 15" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </>
}
export const RoundDone: FC<ISvgProps> = (props) => {
    return <>
        <svg className={classNames(styles.svg, props.className)} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53447 21.3746C6.51168 20.6273 4.78465 19.2461 3.61096 17.4371C2.43727 15.628 1.87979 13.4881 2.02168 11.3363C2.16356 9.18455 2.99721 7.13631 4.39828 5.49706C5.79935 3.85781 7.69279 2.71537 9.79619 2.24013C11.8996 1.7649 14.1003 1.98232 16.07 2.85999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 4L12 14.01L9 11.01" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </>
}