import { FC } from "react";
import { useEffect, useState } from "react";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { IInvoiceItemViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem";
import { InvoiceDataProvider } from "../../../../Services/DataProviders/InvoiceDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import styles from "../../../styles/index.module.scss";

interface IInvoiceItemsGrid extends IGridProps {
    idGlobalInvoice: string;
}

export const InvoiceItemGrid: FC<IInvoiceItemsGrid> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();
    const invoiceDataProvider = new InvoiceDataProvider(appContext.coreApiService);
    const [data, setData] = useState<IInvoiceItemViewDTO[]>([]);
    const [totalCount, ] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);

    useEffect(() => {
        invoiceDataProvider.getItemsView(props.idGlobalInvoice, gridFilter, (entities) => {
            setData(entities);
        });
    }, []);

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent();
    }, [tabCtx.currentTab]);

    return (
        <>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    <DefaultGrid
                        gridId={props.gridId}
                        data={data}
                        filter={gridFilter}
                        totalCount={totalCount}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        selectedItems={selectedItems}
                        onSelect={(row) => {
                            props.onSelect?.(row);
                            setSelectedItem(row);
                        }}
                        multipleSelect={props.multipleSelect}
                        onMultipleSelect={(rows) => {
                            setSelectedItems(rows);
                            props?.onMultipleSelect?.(rows);
                        }}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    />
                </div>
            </div>
        </>
    );
};
