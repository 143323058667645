import { FC, memo, useContext, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { ICompanyViewDTO, ICreateCompany, IEditCompany } from "../../../libs/coreapi-dto/service/company";
import { CompanyDataProvider } from "../../../Services/DataProviders/CompanyDataProvider";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { CompaniesCommandPanel } from "./Components/CompaniesPanel";
import { CreateCompaniesModal, TypeOpen } from "./Components/CreateCompaniesModal";
import styles from '../../styles/index.module.scss'
import { DatabaseDataProvider } from "../../../Services/DataProviders/DatabaseDataProvider";
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow";
import { Spinner } from "../../../components/spiner/Spinner";
import { checkLockStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

const CompaniesGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const companyDataProvider = new CompanyDataProvider(appCtx.coreApiService);
    const databaseDataProvider = new DatabaseDataProvider(appCtx.coreApiService);

    const [data, setData] = useState<ICompanyViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [typeOpen, setTypeOpen] = useState<TypeOpen>('Create')
    const [selectedCompany, setSelectedCompany] = useState<ICompanyViewDTO | null>(null);

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });
    const [migrateProcess, setMigrateProcess] = useState<boolean>(false);

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.plugin.permission as IPermission, userContext.userPermission);

    const refresh = () => {
        setViewState('refresh')
        setSelectedItem(undefined)
        setSelectedCompany(null)
    }

    const blockCompany = () => {
        if (selectedItem) {
            companyDataProvider.blockCompany(selectedItem.idGlobal, () => {
                refresh()
            })
        }
    }

    const migrationCompany = () => {
        if (selectedItem) {
            setMigrateProcess(true);
            databaseDataProvider.migrateCompany(selectedItem.idGlobal, (e) => {
                setMigrateProcess(false);
                if (e === true)
                {
                    setShowMessageModal({ show: true, message: 'Миграция успешно завершена' })
                    companyDataProvider.isMigrated(selectedItem.idGlobal, ()=>{
                        refresh();
                    })
                }
            })
        }
    }

    const unBlockCompany = () => {
        if (selectedItem) {
            companyDataProvider.unBlockCompany(selectedItem.idGlobal, () => {
                refresh()
            })
        }
    }

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        companyDataProvider.viewCompany(gridFilter, (entities, totalCount) => {
            try {
                setData([...entities.map(x => ({ idGlobal: x.id, name: x.name, lockoutEnd: x.lockoutEnd }))])
                setTotalCount(totalCount)
                setLoadingStatus(LoadingStatus.Completed)
            } catch {
                setLoadingStatus(LoadingStatus.Failed)
            }
        })
    }
    useEffect(() => {
        if (viewState === 'refresh') {
            loadData()
            setViewState('view')
        }
    }, [viewState])

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    useEffect(() => {
        if (selectedItem?.idGlobal) {
            companyDataProvider.findCompany(selectedItem.idGlobal, (data)  => {
                setSelectedCompany(
                    {
                        idGlobal: data.id,
                        name: data.name,
                        lockoutEnd: data.lockoutEnd,
                        connectionString: data.connectionString,
                        isMigrated: data.isMigrated
                    }
                )
            })  
        } else {
            setSelectedCompany(null)
        }
    }, [selectedItem])

    return (
        <PluginWrapper>

            <ToolbarWrapper>
                <CompaniesCommandPanel
                    add={{
                        onClick: () => {
                            setTypeOpen('Create');
                            setOpenCreateModal(true);
                        }
                    }}
                    edit={{
                        onClick: () => {
                            setTypeOpen('Edit');
                            setOpenCreateModal(true);
                        },
                        disabled: selectedItem?.idGlobal ? false : true
                    }}
                    migration={{
                        onClick: () => {
                            migrationCompany()
                        },
                        disabled: selectedCompany && selectedCompany.connectionString && !selectedCompany.isMigrated && !migrateProcess ? false : true
                    }}
                    block={{
                        onClick: () => {
                            blockCompany();
                        },
                        disabled: selectedCompany && selectedCompany.lockoutEnd === null ? false : true
                    }}
                    unBlock={{
                        onClick: () => {
                            unBlockCompany()
                        },
                        disabled: selectedCompany && selectedCompany.lockoutEnd !== null ? false : true
                    }}
                    refresh={{
                        onClick: () => {
                            refresh();
                        }
                    }}
                    permission={props.plugin.permission}
                />
            </ToolbarWrapper>
            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {
                        migrateProcess ?
                        (<Spinner />) :
                        (<DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            totalCount={totalCount}
                            filter={gridFilter}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            dataProvider={companyDataProvider}
                            selectedItems={selectedItems}
                            onMultipleSelect={(rows) => {
                                setSelectedItems(rows)
                                props?.onMultipleSelect?.(rows)
                            }}
                            multipleSelect={props.multipleSelect}
                            onSelect={(row) => {
                                setSelectedItem(row)
                                props?.onSelect?.(row)
                            }}
                            useUiService={false}
                            onDoubleClick={(i) => props.onDoubleClick?.(i)}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onEnter={() => setViewState("edit")}
                        />)
                    }
                </div></div>

            {
                showMessageModal.show &&
                <MessageModalWindow
                    ok={{ onClick: () => { 
                        if (showMessageModal.handler) { 
                            showMessageModal.handler() 
                        }
                        setShowMessageModal({ show: false, message: '' }) 
                    }}}
                    cancel={{ hidden: true,
                        onClick: () => { setShowMessageModal({ show: false, message: '' }) } }}
                    message={showMessageModal.message}
                />
            }

            {
                openCreateModal &&
                <CreateCompaniesModal
                    ok={(companyData: ICreateCompany | IEditCompany, validate: (() => Promise<boolean>) | (() => void), processCompany: ((id: string) => void) | (() => void)) => {
                        if (typeOpen === 'Create') {

                            // Валидация файла ведомости перед созданием компании
                            (validate() as Promise<boolean>).then((result) => {
                                if (result) {
                                    companyDataProvider.create(companyData as ICreateCompany, (createdCompany) => {
                                        refresh();
                                        setOpenCreateModal(false);
        
                                        // Если компания создана успешно, подтянуть лицензию в систему
                                        if (createdCompany && "id" in createdCompany) {
                                            processCompany(createdCompany.id);
                                        }
                                    }) 
                                }
                            });
                        } else {
                            companyDataProvider.update(selectedItem?.idGlobal as string, companyData as IEditCompany, () => {
                                refresh();
                                setOpenCreateModal(false);
                            })
                        }
                    }}
                    cancel={() => setOpenCreateModal(false)}
                    companyEditData={selectedCompany ? { companyName: selectedCompany.name, connectionString: selectedCompany.connectionString ?? '' } : null}
                    typeOpen={typeOpen}
                    lockFromPermission={lockFromPermission}
                />
            }
        </PluginWrapper>
    )
}

export default CompaniesGrid
