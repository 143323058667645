import {ILegalEntityDTO, ILegalEntityViewDTO } from "../../../libs/coreapi-dto/dirs/legalEntity";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


class LegalEntityRequest extends BaseDirRequest<ILegalEntityViewDTO, ILegalEntityDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "legalentities", token, eventHandler)
    }
    
    create(body: ILegalEntityDTO, callback: (e:CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: ILegalEntityDTO, callback: (e:CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}
export default LegalEntityRequest