import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";
import { IKizDTO, IKizViewDTO } from "../../coreapi-dto/dirs/kiz";


class KizDocRequest extends BaseDirRequest<IKizViewDTO, IKizDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "KizDoc", token, eventHandler)
    }

    create702Document(idInvoiceGlobal: string) {
        this.post(`${this._baseUrl}/${this._routePrefix}/Create702Document/${idInvoiceGlobal}`, null, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }
        })
    }
}

export default KizDocRequest