import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";

export const importCsvFileRequest = async (
    file: File,
    setIsSubmitting: (value: boolean) => void
) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const formData = new FormData;
    const header = {
        headers: {
            Authorization: token,
        },
    }
    formData.append('Content', file)

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }

    setIsSubmitting(true)
    await axios.post<string>(
        generateRequestUrl('ImportFiles/ImportRemains/Csv/ImportFileCsv'),
        formData,
        header
    ).then((e) => {
        setIsSubmitting(false)
        renderGlobalAlert({ variant: "success", statusCode: 200, title: 'Успешно' })
    }).catch((err: AxiosError) => {
        setIsSubmitting(false)
        renderGlobalAlert({ variant: 'error', statusCode: 500, title: err.response?.statusText ?? 'Ошибка' })
    })

}