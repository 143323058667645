import axios, { AxiosError, AxiosResponse } from "axios";
import { IdTableVariant } from "../../@types/enumsGlobal";
import { getCoreApiLikeException, getTimeZone } from "../../Business/Reports/Common/CommonFunctions";
import { LabelTemplateKinds } from "../../Business/Reports/Shared/Enums/LabelTemplateKinds";
import { ILabelDesignerProperty } from "../../Business/Reports/Shared/Interfaces/ILabelDesignerProperty";
import { ILabelPrintLot } from "../../Business/Reports/Shared/Interfaces/ILabelPrintLot";
import { ILabelPrintSelectedLot } from "../../Business/Reports/Shared/Interfaces/ILabelPrintSelectedLot";
import { ILabelTemplate } from "../../Business/Reports/Shared/Interfaces/ILabelTemplate";
import { CoreApiResponse, IRequestEventHandler } from "../core-api-requests/common";
import { BaseDirRequest } from "../core-api-requests/dirs/baseDirRequest";
import { ContentType } from "../fetchApiDistributor/contentTypes";
import { useUserContext } from "../../system/providers/userContextProvider";
import { DateTime } from "luxon";

interface IViewLabelsData {
    items: Array<ILabelPrintLot>
    columns: Array<IGridColumn>
}

class LabelPrintItemRequest extends BaseDirRequest<ILabelPrintLot, ILabelPrintLot> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "Reports", token, eventHandler)
    }

    getViewByParams(idTable: IdTableVariant, documentsIds: Array<string>, dateWithUserZone: DateTime, callback?: ((e: CoreApiResponse<IViewLabelsData>) => void)): void {
        this.post(`${this._baseUrl}/${this._routePrefix}/ViewLabels/${idTable}/${getTimeZone(dateWithUserZone)}`, documentsIds, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IViewLabelsData>) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    print(labelPrintLots: Array<ILabelPrintSelectedLot>, kind: LabelTemplateKinds, dateWithUserZone: DateTime, callback: (e: CoreApiResponse<Blob>) => void) {
        const bear = this.getBearerHeader(this._token)
        const url = `${this._baseUrl}/${this._routePrefix}/PrintLabel/${getTimeZone(dateWithUserZone)}/${kind}`
        let t = this
        axios.post(url,
            labelPrintLots,
            {
                responseType: 'blob',
                headers: {
                    "Authorization": bear[1],
                    'Content-Type': 'application/json'
                }
            })
            .then(function (data) {
                const resp = (data as unknown as AxiosResponse<unknown, any>)
                if (resp.status === 200) {
                    const blob: Blob = resp.data as Blob
                    const r = { respType: "isCompleted", data: blob, message: t.getCompletedMessage(resp.status) } as CoreApiResponse<Blob>
                    if (t._eventHandler) {
                        t._eventHandler.onGetResponse(r)
                    }
                    callback?.(r)
                } else {
                    const result = getCoreApiLikeException(resp.request, t)
                    callback?.(result)
                    if (t._eventHandler) {
                        t._eventHandler.onGetResponse(result)
                    }
                }
            })
            .catch((ex: AxiosError) => {
                const result = getCoreApiLikeException(ex.request, t)
                callback?.(result)
                if (t._eventHandler) {
                    t._eventHandler.onGetResponse(result)
                }
            })
    }

    getDesignerElements(callback?: ((e: CoreApiResponse<Array<ILabelDesignerProperty>>) => void)): void {
        this.get(`${this._baseUrl}/${this._routePrefix}/${"DesignerElements"}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<Array<ILabelDesignerProperty>>) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getLabelTemplates(callback?: ((e: CoreApiResponse<Array<ILabelTemplate>>) => void)): void {
        this.get(`${this._baseUrl}/${this._routePrefix}/${"LabelTemplates"}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<Array<ILabelTemplate>>) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
}
export default LabelPrintItemRequest