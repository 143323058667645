import { FC, useEffect, useState } from "react";
import { TabHead } from "./TabHead";
import { Tab } from "./Tab";
import { TabHeadItem } from "./TabHeadItem";
import { TabBody } from "./TabBody";
import { TabBodyItem } from "./TabBodyItem";
import { useTabsContext } from "../../system/providers/tabsProvider";
import { TabMenu } from "./TabMenu";
import { tabsLocalStorage } from "../../system/hooks/useTabsPanel";
import QuickLaunchPanel from "../../hoc/QuickLaunchPanel/QuickLaunchPanel";
import style from "./styles/TabBusinessComponent.module.scss"

interface ITabsAreaProps {
    quickLaunchPanelVisible: boolean;
    burgerMenuState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export interface IOpenTabsMenu {
    open: boolean
    xPos: number
    yPos: number
}

export const TabBusinessComponent: FC<ITabsAreaProps> = (props) => {
    const tabContext = useTabsContext()
    const [openTabsMenu, setOpenTabsMenu] = useState<IOpenTabsMenu>({ open: false, xPos: 0, yPos: 0 })
    const [burgerMenuVisible, setBurgerMenuVisible] = props.burgerMenuState;

    useEffect(() => {
        const localStorage = tabsLocalStorage();
        if (localStorage !== null && !tabContext.tabs.length) {
            localStorage.forEach(tab => {
                switch(tab.variant) {
                    case 'create':
                        return tabContext.openChild(tab.plugin, 'create', undefined, () => {});
                    case 'edit':
                        return tabContext.openChild(tab.plugin, 'edit', tab.idGlobal, () => {}, tab.mnemocode);
                    case 'copy':
                        return tabContext.openChild(tab.plugin, 'copy', tab.idGlobal, () => {});
                    case 'view':
                        return tabContext.openChild(tab.plugin, 'view', tab.idGlobal, () => {});
                    default:
                        return tabContext.open(tab.plugin);
                }
            })
        }
    }, [])

    if (tabContext.tabs.length) {
        return <>
            {props.quickLaunchPanelVisible &&
                <div className={style.topPanelSticky} >
                    <QuickLaunchPanel burgerMenuVisible={burgerMenuVisible} onShowBurgerMenuClick={() => setBurgerMenuVisible(true)} />
                <TabHead>
                    {
                        tabContext.tabs.map((item, index) => {
                            return <TabHeadItem
                                id={item.id}
                                key={item.id}
                                openTabsMenu={(value: IOpenTabsMenu) => setOpenTabsMenu(value)}
                                order={index + 1}
                                activated={item.id === tabContext.currentTab?.id}
                                confirmClosing={item.closeWarning}
                                onActivate={() => tabContext.activate(item)}
                                onClose={() => {
                                    item.onTabClosed?.()
                                    tabContext.close(item)
                                } }
                            >
                                {item.title}
                            </TabHeadItem>
                        })
                    }
                </TabHead>
                </div>
            }
            <Tab>
                <TabBody>
                    {
                        tabContext.tabs.map((item) => {
                            return <TabBodyItem key={item.id} visible={item.id === tabContext.currentTab?.id}>
                                {item.view.content}
                            </TabBodyItem>
                        })
                    }
                    {
                        openTabsMenu.open && <TabMenu close={() => setOpenTabsMenu({ open: false, xPos: 0, yPos: 0 })} xPos={openTabsMenu.xPos} yPos={openTabsMenu.yPos} />
                    }
                </TabBody>
            </Tab>
        </>
    } else {
        return <>
            <div className={style.topPanelSticky} >
                <QuickLaunchPanel burgerMenuVisible={burgerMenuVisible} onShowBurgerMenuClick={() => setBurgerMenuVisible(true)} />
            </div>
        </>
    }
}