import React, { useEffect, useState } from 'react';
import styles from "./styles/index.module.scss";
import { ComboBox } from '@imsuresh206/testing';
import { useTranslation } from 'react-i18next';
import { DownIcon } from '../../libs/corporate/icons/outlined/directions/ChevronCollection';
import { ComboBoxDataProviderType } from '@imsuresh206/testing/dist/types/combobox';
import { useAppContext } from '../../system/providers/appContextProvider';
import { User2ContractorDataProvider } from '../../Services/DataProviders/User2ContractorDataProvider';
import { IUser2ContractorDTO, IUser2ContractorViewDTO } from '../../libs/coreapi-dto/dirs/user2Contractor';
import { useUserContext } from '../../system/providers/userContextProvider';
import renderGlobalAlert from '../../system/hooks/useGlobalAlert';

// TODO: dark theme
interface IContractorSelectorDropdownProps {
    darkTheme?: boolean
}

interface IContractorItem extends ComboBoxDataProviderType {
    content: IUser2ContractorViewDTO
}

export const ContractorSelectorDropdown: React.FC<IContractorSelectorDropdownProps> = (props) => {
    const [items, setItems] = useState<IContractorItem[]>([]);
    const [selectedItems, setSelectedItems] = useState<IContractorItem | IContractorItem[]>([]);

    const { t } = useTranslation();

    const appContext = useAppContext();
    const userContext = useUserContext();
    const user2ContractorDataProvider = new User2ContractorDataProvider(appContext.coreApiService);

    const MaxContractorNo = 256; // TODO: Get All (Iterations / API)
    const DefaultFilter = {
        pageNumber: 1,
        numberPerPage: MaxContractorNo,
        columnFilters: [
            {
                name: "idUserGlobal",
                value: userContext.idGlobal,
                operator: "Eq",
                invisible: true
            } as IGridColumnFilter
        ]
    } as IGridFilter;

    // Load
    const loadItems = async () => {

        const load = async function (filter: IGridFilter) {
            try {
                return await new Promise<IContractorItem[]>(function (resolve, reject) {
                    try {
                        user2ContractorDataProvider.getView(filter, ((items: IUser2ContractorViewDTO[], totalCount: number) => {
                            const result = items.map(x => {
                                return {
                                    value: x.idGlobal,
                                    label: x.contractorName,
                                    isSelected: x.isSelected,
                                    content: x
                                } as IContractorItem;
                            });

                            resolve(result);
                        }));
                    }
                    catch {
                        reject(t("sidePanel.contractorsDropdown.errorUser2ContractorViewRequest"));
                    }
                });
            } catch (reason) {
                renderGlobalAlert({
                    variant: "error",
                    statusCode: 500,
                    title: reason as string,
                });
            }
        }

        const items = await Promise.resolve(load(DefaultFilter));
        return items;
    };

    const refreshItems = () => {
        loadItems().then((items) => {
            setItems(items as IContractorItem[]);
            setSelectedItems((items as IContractorItem[]).filter(x => x.isSelected));
        });
    }

    // Update many
    const updateMany = (items: IContractorItem[]) => {

        items.forEach((item) => {

            const dto = {
                idUserGlobal: userContext.idGlobal,
                idContractorGlobal: item.content?.idContractorGlobal,
                isSelected: item.isSelected
            } as IUser2ContractorDTO

            user2ContractorDataProvider.update(item.value as string, dto, () => {

                refreshItems();
            });
        })
    };

    useEffect(() => {
        refreshItems();
    }, []);

    return (
        <div className={styles.ContractorsDropdown}>
            <ComboBox
                dataProvider={items}
                value={selectedItems}
                onComplete={(value) => {

                    // Возвращаются только выделенные опции                        
                    const selected = Array.isArray(value) ? value : [value];

                    // Для выделенных узнать кто изменился
                    let targetArr: IContractorItem[] = [];
                    selected.forEach((selectedItem) => {
                        const item = items.find(c => c.value === selectedItem.value);

                        // Если не совпадение,
                        // добавляем в целевой массив с новым значением выделения
                        // true
                        if (item && !item.isSelected)
                            targetArr.push({
                                ...item,
                                isSelected: true
                            } as IContractorItem);
                    });

                    // Для невыделенных
                    const unselected = items.filter(x => !selected.some(c => c.value === x.value));
                    unselected.forEach((unselectedItem) => {

                        // Обратный случай
                        // для невыделенных элементов
                        // добавим в целевой массив
                        // те, что были выделены ранее
                        if (unselectedItem.isSelected)
                            targetArr.push({
                                ...unselectedItem,
                                isSelected: false
                            } as IContractorItem);
                    });

                    // Обновить связку U2C
                    updateMany(targetArr);
                }}
                placeHolder={t("sidePanel.contractorsDropdown.placeholder")}
                selectAllText={t("sidePanel.contractorsDropdown.selectAll")}
                cancelText={t("sidePanel.contractorsDropdown.cancel")}
                submitText={t("sidePanel.contractorsDropdown.submit")}
                rightAdornment={
                    <DownIcon className = "dropDownIcon" />
                }
            />
        </div>
    );
}