import CashSessionGridPluginSettings from './Accounting/CashSession/CashSessionGridPluginSettings';
import ChequeGridViewPluginSettings from './Accounting/Cheque/ChequeGridViewPluginSettings';
import { ChequeGridPluginSettings } from './Accounting/Cheque/ChequeGrid/ChequeGridPluginSettings';
import { LotsByGoodsGridPluginSettings } from './Accounting/LotsByGoods';
import { GoodsGridPluginSettings } from './Dictionaries/Goods';
import ActDisassemblingGridPluginSettings from './Documents/ActDisassembling/ActDisassemblingGridPluginSettings';
import ActRevaluationGridPluginSettings from './Documents/ActRevaluation/ActRevaluationGridPluginSettings';
import { InvoiceGridPluginSettings } from './Documents/Invoice';
import { InvoiceItemGridPluginSettings } from './Documents/Invoice/InvoiceItem';
import ActDisagregationGridPluginSettings from './Documents/ActDisagregation/ActDisagregationGridPluginSettings';
import MovementGridPluginSettings from "./Documents/Movement/MovementGridPluginSettings"
import MasterTabsProvider from './MasterTabsProvider';
import CashRegistersPluginSettings from './Service/CashRegisters/CashRegistersPluginSettings';
import ConditionGridPluginSettings from './Service/DiscountConditionType/ConditionGridPluginSettings';
import DiscountProgramFormGridPluginSettings from './Dictionaries/DiscountProgram/DiscountProgramFormGridPluginSettings';
import { KizExchangeGridPluginSettings } from './Accounting/KizExchangeStatus';
import DefecturaGridPluginSettings from './Accounting/Defectura/DefecturaGridPluginSettings';
import KizExchangeModalPluginSettings from './Accounting/KizExchangeCreatorModal/KizExchangeModalPluginSettings';
import { ImportRemainsItemGridPluginSettings } from './Documents/ImportRemains/ImportRemainsItem';
import ImportRemainsGridPluginSettings from './Documents/ImportRemains/ImportRemainsGridPluginSettings';
import { InvoiceOutGridPluginSettings } from './Documents/InvoiceOut';
import { InvoiceOutItemGridPluginSettings } from './Documents/InvoiceOut/pluginSettings/invoiceOutItemGridPluginSettings'
import TaskProgramFormGridPluginSettings from './Dictionaries/TaskProgram/TaskProgramFormGridPluginSettings';
import TaskProgramItemGridPluginSettings from './Dictionaries/TaskProgram/TaskProgramItem/TaskProgramItemGridPluginSettings';
import { GosContractPluginSettings } from './Dictionaries/GosContract/pluginSettings/gosContractPluginSettings';
import { GosContractItemsPluginSettings } from './Dictionaries/GosContract/pluginSettings/gosContractItemsPluginSettings';
import { ActDeductionPluginSettings } from './Documents/ActDeduction/pluginSettings/actDeductionPluginSettings';
import ActReturnToContractorPluginSettings from './Documents/ActReturnToContractor/pluginSettings/ActReturnToContractorPluginSettings';
import { InventoryVedItemPluginSettings, InventoryVedPluginSettings } from './Accounting/InventoryVed';
import { InventorySvedItemPluginSettings, InventorySvedPluginSettings } from './Accounting/InventorySved';
import InternetOrderGridPluginSettings from './Accounting/InternetOrder/InternetOrderGridPluginSettings';
import SysOptionsPluginSettings from './Service/SysOptions/SysOptionsPluginSettings';
import SysOptionsDetailPluginSettings from './Service/SysOptions/components/detailGrid/SysOptionsDetailPluginSettings';
import { PricingAdpriceScaleTypePluginSettings } from './Dictionaries/PricingAdpriceScaleType/pluginSettings/PricingAdpriceScaleTypePluginSettings';
import { PricingRoundSchemaPluginSettings } from './Dictionaries/PricingRoundSchema/pluginSettings/PricingRoundSchemaPluginSettings';
import { PricingExtremeAdpriceSchemaTypePluginSettings } from './Dictionaries/PricingExtremeAdpriceSchemaType/pluginSettings/PricingExtremeAdpriceSchemaTypePluginSettings';
import { PricingMinAdpriceSchemaTypePluginSettings } from './Dictionaries/PricingMinAdpriceSchemaType/pluginSettings/PricingMinAdpriceSchemaTypePluginSettings';
import { PricingPriceCalcSchemaTypePluginSettings } from './Dictionaries/PricingPriceCalcSchemaType/pluginSettings/PricingPriceCalcSchemaTypePluginSettings';
import { KizInventoryItemAddPluginSettings, KizInventoryItemCorrectStatusPluginSettings, KizInventoryItemMisPluginSettings, KizInventoryItemOldPluginSettings, KizInventoryItemPluginSettings, KizInventoryItemSubPluginSettings, KizInventoryPluginSettings } from './Accounting/KizInventory';


export class DetailsTabsProvider {

    public static Create(plugin: IPlugin): ITab[] {
        switch (plugin) {
            case 'goods_plugin': return this.getTabs(GoodsGridPluginSettings.detailsPlugins)
            case 'invoice_plugin': return this.getTabs(InvoiceGridPluginSettings.detailsPlugins)
            case 'invoice_item_plugin': return this.getTabs(InvoiceItemGridPluginSettings.detailsPlugins)
            case 'lots_by_goods_plugin': return this.getTabs(LotsByGoodsGridPluginSettings.detailsPlugins)
            case 'cash_registers_plugin': return this.getTabs(CashRegistersPluginSettings.detailsPlugins)
            case 'cheque_view_plugin': return this.getTabs(ChequeGridViewPluginSettings.detailsPlugins)
            case 'cheque_plugin': return this.getTabs(ChequeGridPluginSettings.detailsPlugins)
            case 'movement_plugin': return this.getTabs(MovementGridPluginSettings.detailsPlugins)
            case 'act_revaluation_plugin': return this.getTabs(ActRevaluationGridPluginSettings.detailsPlugins)
            case 'act_disassembling_plugin': return this.getTabs(ActDisassemblingGridPluginSettings.detailsPlugins)
            case 'cash_session_plugin': return this.getTabs(CashSessionGridPluginSettings.detailsPlugins)
            case 'defectura_plugin': return this.getTabs(DefecturaGridPluginSettings.detailsPlugins)
            case 'discount_condition_type_plugin': return this.getTabs(ConditionGridPluginSettings.detailsPlugins)
            case 'discount_plugin': return this.getTabs(DiscountProgramFormGridPluginSettings.detailsPlugins)
            case 'act_disagregation_plugin': return this.getTabs(ActDisagregationGridPluginSettings.detailsPlugins)
            case 'kiz_exchange_plugin': return this.getTabs(KizExchangeGridPluginSettings.detailsPlugins)
            case 'kiz_exchange_modal_plugin': return this.getTabs(KizExchangeModalPluginSettings.detailsPlugins)
            case 'import_remains_plugin': return this.getTabs(ImportRemainsGridPluginSettings.detailsPlugins)
            case 'import_remains_item_plugin': return this.getTabs(ImportRemainsItemGridPluginSettings.detailsPlugins)
            case 'invoice_out_plugin': return this.getTabs(InvoiceOutGridPluginSettings.detailsPlugins)
            case 'invoice_out_item_plugin': return this.getTabs(InvoiceOutItemGridPluginSettings.detailsPlugins)
            case 'task_program_plugin': return this.getTabs(TaskProgramFormGridPluginSettings.detailsPlugins)
            case 'task_program_item_plugin': return this.getTabs(TaskProgramItemGridPluginSettings.detailsPlugins)
            case 'gosContract_plugin': return this.getTabs(GosContractPluginSettings.detailsPlugins)
            case 'act_deduction_plugin': return this.getTabs(ActDeductionPluginSettings.detailsPlugins)
            case 'act_return_to_contractor_plugin': return this.getTabs(ActReturnToContractorPluginSettings.detailsPlugins)
            case 'inventory_ved_plugin': return this.getTabs(InventoryVedPluginSettings.detailsPlugins)
            case 'inventory_ved_item_plugin': return this.getTabs(InventoryVedItemPluginSettings.detailsPlugins)
            case 'inventory_sved_plugin': return this.getTabs(InventorySvedPluginSettings.detailsPlugins)
            case 'inventory_sved_item_plugin': return this.getTabs(InventorySvedItemPluginSettings.detailsPlugins)
            case 'kiz_inventory_plugin': return this.getTabs(KizInventoryPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_plugin': return this.getTabs(KizInventoryItemPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_sub_plugin': return this.getTabs(KizInventoryItemSubPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_correct_status_plugin': return this.getTabs(KizInventoryItemCorrectStatusPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_add_plugin': return this.getTabs(KizInventoryItemAddPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_mis_plugin': return this.getTabs(KizInventoryItemMisPluginSettings.detailsPlugins)
            case 'kiz_inventory_item_old_plugin': return this.getTabs(KizInventoryItemOldPluginSettings.detailsPlugins)
            case 'internet_order_plugin': return this.getTabs(InternetOrderGridPluginSettings.detailsPlugins)
            case 'sys_options_plugin': return this.getTabs(SysOptionsPluginSettings.detailsPlugins)
            case 'sys_options_detail_plugin': return this.getTabs(SysOptionsDetailPluginSettings.detailsPlugins)
            case 'pricing_adprice_scale_type_plugin': return this.getTabs(PricingAdpriceScaleTypePluginSettings.detailsPlugins)
            case 'pricing_round_schema_plugin': return this.getTabs(PricingRoundSchemaPluginSettings.detailsPlugins)
            case 'pricing_extreme_adprice_schema_type_plugin': return this.getTabs(PricingExtremeAdpriceSchemaTypePluginSettings.detailsPlugins)
            case 'pricing_min_adprice_schema_type_plugin': return this.getTabs(PricingMinAdpriceSchemaTypePluginSettings.detailsPlugins)
            case 'pricing_price_calc_schema_type_plugin': return this.getTabs(PricingPriceCalcSchemaTypePluginSettings.detailsPlugins)
            default:
                return [] as ITab[]
        }
    }

    private static getTabs(plugins?: IPluginSettings[]) {
        let tabs: ITab[] = []
        plugins?.forEach(a => tabs.push(MasterTabsProvider.Create(a.mnemocode)))
        return tabs
    }
}

export default DetailsTabsProvider


