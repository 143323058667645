import { FC, useCallback, useEffect, useState } from "react";
import { DocumentGridStateType } from "../../../../@types/documents";
import { DocumentStatusType, DocumentType, IdTableVariant, LoadingStatus } from "../../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { Spinner } from "../../../../components/spiner/Spinner";
import { TabsPanel } from "../../../../components/tabs";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import styles from "../../../styles/index.module.scss";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import classNames from "classnames";
import { ActDeductionDataProvider } from "../../../../Services/DataProviders/ActDeductionDataProvider";
import { IActDeductionViewDTO } from "../../../../libs/coreapi-dto/documents/actDeduction";
import { UserActionLogModal } from "../../../Service/UserActionLog/view/UserActionLogModal";

export const ActDeductionPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();

    const actDeductionDP = new ActDeductionDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);

    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [data, setData] = useState<IActDeductionViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Completed);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemView, setSelectedItemViews] = useState<IActDeductionViewDTO>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [impossibilityProcess, setImpossibilityProcess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
        //forModalLog
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    const getKizAndId = useCallback(() => {
        if (selectedItem) {
            actDeductionDP.getById(selectedItem?.idGlobal, (entity) => {
                kizDP.getKizViewAsync(selectedItem?.idGlobal, { numberPerPage: 100, pageNumber: 1, columnFilters: [] }, (kiz, totalCount) => {
                    let kizs = entity.items
                        ?.map((x) => {
                            if (x.isKiz) {
                                return x.quantity;
                            } else {
                                return null;
                            }
                        })
                        .filter((x) => x !== null);
                    if (kizs.length > 0) {
                        if (
                            kizs.reduce((a, b) => {
                                return (a as number) + (b as number);
                            }) === totalCount
                        ) {
                            setImpossibilityProcess(false);
                        } else {
                            setImpossibilityProcess(true);
                        }
                    } else {
                        setImpossibilityProcess(false);
                    }
                });
            });
        }
    }, []);

    useEffect(() => {
        if (viewState === "view") {
            setIsSubmitting(true);
            actDeductionDP.getView(gridFilter, async (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                setIsSubmitting(false);
                setLoadingStatus(totalCount > 0 ? LoadingStatus.Completed : LoadingStatus.NoData);
            });
        }

        if (viewState === "refresh") {
            setViewState("view");
        }
    }, [gridFilter, viewState]);

    useEffect(() => {
        if (tabCtx.currentTab?.title === "Акты списания") {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));

            getKizAndId();
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginCtx.masterGrid.onUnselectEvent();
    }, [documentStatusType]);

    const deleteRow = () => {
        if (selectedItem?.[0]?.isDeleted || selectedDocumentState === "save" || selectedDocumentState === "proc") {
            // anything else ... ups, I don't know why it's empty
        } else {
            actDeductionDP.markDelete(selectedItem?.idGlobal as string, () => {
                setViewState("refresh");
            });
        }
    };

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="act_deduction_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        dataProvider={actDeductionDP}
                        pluginSettings={props.plugin}
                        idTable={IdTableVariant.ActDeduction}
                        setViewState={(vs) => setViewState(vs)}
                        advancedFeatures
                        impossibilityAction={{ impossibilityProcess: impossibilityProcess }}
                        items={[ {
                            label: "Просмотреть лог пользователя",
                            onClick: () => selectedItem && setShowModalLog(true),
                            disabled: selectedItem ? false : true,
                        },]}
                        mnemocode={selectedItemView?.mnemocode}
                        checkLockingDocuments
                    />
                </ToolbarWrapper>
                <div>
                    <div>
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <DefaultGrid
                                openWithEnterForEdit={{
                                pluginTabContext:"act_deduction_creator_plugin",
                                mnemocode:selectedItemView?.mnemocode,
                                selectedDocumentState}}
                                gridId={props.gridId}
                                data={data}
                                documentStatus
                                separator
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                actionGridRow={{ delete: () => deleteRow() }}
                                filter={gridFilter}
                                hiddenPagination={undefined}
                                dataProvider={actDeductionDP}
                                totalCount={totalCount}
                                plugin={props.plugin}
                                multipleSelect={multipleSelect}
                                selectedItem={selectedItem}
                                selectedItems={selectedItems}
                                onSelect={(row) => {
                                    setSelectedItem(row);
                                    row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.actDeduction) : pluginCtx.masterGrid.onUnselectEvent();
                                }}
                                onMultipleSelect={(rows) => setSelectedItems(rows)}
                                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => {
                                    i.propertyName === "documentState" && setDocumentStatusType(undefined);
                                    dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                                }}
                                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            />
                        )}
                    </div>
                    <div className={styles.detailsTabView}>
                        {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect && (
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) => dispatchDetailsTabsPanel({ type: "activate", payload: id })}
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                    {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                </div>
            </PluginWrapper>
        </>
    );
};
