import { FC, useEffect, useState } from "react";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import { NumberInput } from "../../../../components/controls/inputs";
import { useLotRemain } from "../../../../components/lotSelector/useLotRemain";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { Spinner } from "../../../../components/spiner/Spinner";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { IActDeductionItemCreateDTO, IActDeductionItemEditDTO, IActDeductionItemViewDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { useForm } from "../../../../system/hooks/useForm";
import buffer from "buffer";
import { KizBoxEntity } from "../../../Dictionaries/Kiz/KizBoxEntity";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { v4 as uuidv4 } from "uuid";
import { IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";

interface IActDeductionItemCreatorProps {
    idGlobal: string;
    idActDeductionGlobal: string;
    edit?: IActDeductionItemEditDTO;
    ok?: (edit: IActDeductionItemEditDTO, view: IActDeductionItemViewDTO) => void;
    cancel?: () => void;
    lot: ILotDTO;
    scanData?: string;
}

interface IValidator {
    quantity: number;
}

export const ActDeductionItemCreator: FC<IActDeductionItemCreatorProps> = (props) => {
    const remain = useLotRemain(
        props.lot.idGlobal,
        props.idActDeductionGlobal,
        () => setIsSubmitting(true),
        () => setIsSubmitting(false)
    );
    const appCtx = useAppContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const [createItem, setCreateItem] = useState<IActDeductionItemCreateDTO>({} as IActDeductionItemCreateDTO);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [viewEntity, setViewEntity] = useState<IActDeductionItemViewDTO>({} as IActDeductionItemViewDTO);
    const [editEntity, setEditEntity] = useState<IActDeductionItemEditDTO>({
        idGlobal: props.edit?.idGlobal ?? props.idGlobal,
        idLotGlobal: props.edit?.idLotGlobal ?? props.lot?.idGlobal,
        quantity: props.edit?.quantity ?? 1,
        retailCostInfo: {
            vat: props.lot.retailCostInfo.vat ?? 0,
            price: props.lot.retailCostInfo.price ?? 0,
            vatPrice: props.lot.retailCostInfo.vatPrice ?? 0,
            priceIncVat: props.lot.retailCostInfo.priceIncVat ?? 0,
            sum: props.lot.retailCostInfo.sum ?? 0,
            vatSum: props.lot.retailCostInfo.vatSum ?? 0,
            sumIncVat: props.lot.retailCostInfo.sumIncVat ?? 0,
            adprice: props.lot.retailCostInfo.adprice ?? 0,
        },
        supplierCostInfo: {
            vat: props.lot.supplierCostInfo.vat ?? 0,
            price: props.lot.supplierCostInfo.price ?? 0,
            vatPrice: props.lot.supplierCostInfo.vatPrice ?? 0,
            priceIncVat: props.lot.supplierCostInfo.priceIncVat ?? 0,
            sum: props.lot.supplierCostInfo.sum ?? 0,
            vatSum: props.lot.supplierCostInfo.vatSum ?? 0,
            sumIncVat: props.lot.supplierCostInfo.sumIncVat ?? 0,
            adprice: props.lot.supplierCostInfo.adprice ?? 0,
        },
        isKiz: props.lot.isKiz,
        isOsu: props.lot?.kizOsuDto?.barcode !== undefined,
        kizOsuDto: props.lot?.kizOsuDto?.barcode !== undefined ? {...props.lot?.kizOsuDto, idDocumentItem: props.edit?.idGlobal ?? props.idGlobal} : undefined,
        isKizBox: props.lot.isKizBox,
        kizBoxCount: props.edit?.kizBoxCount ?? 0,
        kizCount: props.edit?.kizCount ?? 0,
    } as IActDeductionItemEditDTO);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: editEntity.quantity && editEntity.quantity > 0 ? false : true,
                    message: 'Значение должно быть больше 0',
                },
            },
        },
    });

    useEffect(() => {
        if (props.scanData) {
            let scanData = (props.scanData.length === 18) ? ('00' + props.scanData) : props.scanData;
            const scanDataBase64: string = buffer.Buffer.from(scanData, "ascii").toString("base64");
            if (KizBoxEntity.isKizBox(props.scanData)) {
                kizDataProvider.parse(scanDataBase64, (parsedBarcode) => {
                    setCreateItem({
                        idLotGlobal: props.lot?.idGlobal,
                        retailCostInfo: props.lot.retailCostInfo,
                        supplierCostInfo: props.lot.supplierCostInfo,
                        kizBoxCount: 0,
                        kizCount: 0,
                        quantity: 0,
                        isKiz: props.lot.isKiz,
                        kizs: [] as IKizDTO[],
                        kizBoxes: [{ ...parsedBarcode.kizBoxData, idGlobal: uuidv4(), quantity: props.lot.numerator ?? 1 }],
                    } as IActDeductionItemCreateDTO);
                })
            }
        }
    },[])

    useEffect(() => {
        if (!createItem) return;
        setEditEntity({ ...editEntity, kizBoxes: createItem.kizBoxes })
    },[createItem])

    useEffect(() => {
        setViewEntity({
            idGlobal: props.idGlobal,
            idDocumentGlobal: props.idGlobal,

            lotName: props.lot.displayName,
            idLotGlobal: props.lot.idGlobal,
            supplier: props.lot.supplier,

            quantity: editEntity.quantity,

            retailVat: editEntity.retailCostInfo?.vat ?? 0,
            retailPrice: editEntity.retailCostInfo?.price ?? 0,
            retailVatPrice: editEntity.retailCostInfo?.vatPrice ?? 0,
            retailPriceIncVat: editEntity.retailCostInfo?.priceIncVat ?? 0,
            retailSum: editEntity.retailCostInfo?.sum ?? 0,
            retailVatSum: editEntity.retailCostInfo?.vatSum ?? 0,
            retailSumIncVat: editEntity.retailCostInfo?.sumIncVat ?? 0,
            retailAdprice: editEntity.retailCostInfo.adprice ?? 0,

            supplierVat: editEntity.supplierCostInfo?.vat ?? 0,
            supplierPrice: editEntity.supplierCostInfo?.price ?? 0,
            supplierVatPrice: editEntity.supplierCostInfo?.vatPrice ?? 0,
            supplierPriceIncVat: editEntity.supplierCostInfo?.priceIncVat ?? 0,
            supplierSum: editEntity.supplierCostInfo?.sum ?? 0,
            supplierVatSum: editEntity.supplierCostInfo?.vatSum ?? 0,
            supplierSumIncVat: editEntity.supplierCostInfo?.sumIncVat ?? 0,
            supplierAdprice: editEntity.supplierCostInfo.adprice ?? 0,

            isKiz: editEntity.isKiz,
            isKizBox: editEntity.isKizBox,
            kizBoxCount: props.edit?.kizBoxCount ?? 0,
            kizCount: props.edit?.kizCount ?? 0,
            isOsu: editEntity.isOsu
        } as IActDeductionItemViewDTO);
    }, [editEntity]);

    useEffect(() => {
        const retailCostInfoChange = {
            sum: +((editEntity.retailCostInfo?.price ?? 0) * editEntity.quantity).toFixed(2),
            sumIncVat: +((editEntity.retailCostInfo?.priceIncVat ?? 0) * editEntity.quantity).toFixed(2),
            vatSum: +((editEntity.retailCostInfo?.vatSum ?? 0) * editEntity.quantity).toFixed(2),
        };
        const supplierCostInfoChange = {
            sum: +((editEntity.supplierCostInfo?.price ?? 0) * editEntity.quantity).toFixed(2),
            sumIncVat: +((editEntity.supplierCostInfo?.priceIncVat ?? 0) * editEntity.quantity).toFixed(2),
            vatSum: +((editEntity.supplierCostInfo?.vatSum ?? 0) * editEntity.quantity).toFixed(2),
        };
        setEditEntity({ ...editEntity, retailCostInfo: { ...editEntity.retailCostInfo, ...retailCostInfoChange }, supplierCostInfo: { ...editEntity.supplierCostInfo, ...supplierCostInfoChange } });
    }, [editEntity.quantity]);

    return (
        <BaseModalWindow
            header={props.lot.displayName}
            ok={{
                onClick: () => {
                    if (isValid()) {
                        props.ok?.(editEntity, viewEntity);
                    }
                },
            }}
            cancel={{ onClick: () => props.cancel?.() }}
            fullScreen
        >
            {isSubmitting ? (
                <Spinner />
            ) : (
                <div>
                    <div>
                        <GridWrapper cols={3}>
                            <NumberInput
                                required
                                onFocus={() => setErrors({ ...errors, quantity: undefined })}
                                error={errors.quantity}
                                keyBan={true} 
                                label="Количество"
                                value={editEntity.quantity}
                                min={0} max={remain} onChange={(value) => setEditEntity({ ...editEntity, quantity: value })} 
                            />
                            <NumberInput label="Доступно для списания" value={remain} disabled />
                            <DictionaryInput label="Ед. измерения" value={props.lot?.scalingRatio.displayName} disabled />
                        </GridWrapper>
                    </div>
                </div>
            )}
        </BaseModalWindow>
    );
};

export default ActDeductionItemCreator;
