import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common"
import { useForm } from "../../../../system/hooks/useForm"
import styles from '../styles/importRemainContent.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { createRemainRequest, ICreateItem } from "../request/createRemainsRequest"
import { saveImportFileRequest } from "../request/saveImportFileRequest"
import { addKizRequest } from "../request/addKizRequest"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { ImportRemainsDataProvider } from "../../../../Services/DataProviders/ImportRemainsDataProvider"
import { LgWidthContent } from "../components/LgWidthContent"
import { XlWidthContent } from "../components/XlWidthContent"
import { downloadAsFile } from "../function/functions"
import { useTabsContext } from "../../../../system/providers/tabsProvider"
import { checkLockStatus } from "../../../CommonHelperFunctions"
import { useUserContext } from "../../../../system/providers/userContextProvider"

export interface IValidator {
    store: IEntitySimpleDTO,
    pricingModel: IEntitySimpleDTO,
    remainId: string
    supplierId: string
}

export interface IFileWrapper {
    file: IFile[]
    id: string | null
}


export const ImportRemainContent = (props) => {
    const appContext = useAppContext();
    const tabContext = useTabsContext()
    const importRemainsDP = new ImportRemainsDataProvider(appContext.coreApiService)

    const [remain, setRemain] = useState<IFileWrapper>({ file: [], id: null });
    const [remainSubmitting, setRemainSubmitting] = useState<boolean>(false)

    const [kiz, setKiz] = useState<IFileWrapper>({ file: [], id: null });
    const [kizSubmitting, setKizSubmitting] = useState<boolean>(false)

    const [supplier, setSupplier] = useState<IFileWrapper>({ file: [], id: null });
    const [supplierSubmitting, setSupplierSubmitting] = useState<boolean>(false)

    const [store, setStore] = useState<IEntitySimpleDTO>({} as IEntitySimpleDTO)
    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO>({} as IEntitySimpleDTO)

    const [importId, setImportId] = useState<string | null>(null)
    const [idRemains, setIdRemains] = useState<string | null>(null)
    const [selectedRemain, setSelectedRemain] = useState<IEntitySimpleDTO>({} as IEntitySimpleDTO)

    const userContext = useUserContext();
    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const admissibleExtension: string = 'csv';
    let windowOuterWidth = window.outerWidth

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            store: {
                required: {
                    value: store?.idGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            pricingModel: {
                required: {
                    value: pricingModel?.idGlobal ? false : true,
                    message: errorsT("required")
                }
            },
            remainId: {
                required: {
                    value: remain.id ? false : true,
                    message: errorsT("required")
                }
            },
            supplierId: {
                required: {
                    value: supplier.id ? false : true,
                    message: errorsT("required")
                }
            },
        },
    });

    useEffect(() => {
        if (idRemains) {
            importRemainsDP.getByIdWithOutItems(idRemains, (e) => {
                setSelectedRemain({ idGlobal: e.idGlobal, displayName: e.displayName })
            })
        } else {
            setSelectedRemain({} as IEntitySimpleDTO)
        }
    }, [idRemains])

    return (
        <BaseDocumentCreator
            cancel={{

                title: 'Закрыть',
                onClick: () => { tabContext.closeCurrent() }
            }}
            otherAction={{
                title: 'Сохранить файл',
                onClick: () => {
                    if (importId) {
                        saveImportFileRequest(importId, (value) => {
                            downloadAsFile(value)
                        })
                    }
                },
                sendRequestSpinner: isSubmitting,
                disabled: (importId ? false : true) || lockFromPermission
            }}
        >
            <div className={styles.layout}>
                {
                    <LgWidthContent
                        errors={errors}
                        setErrors={(value) => setErrors(value)}
                        store={store}
                        setStore={(value) => setStore(value)}
                        pricingModel={pricingModel}
                        setPricingModel={(value) => setPricingModel(value)}
                        remain={remain}
                        setRemain={(value) => setRemain(value)}
                        remainSubmitting={remainSubmitting}
                        setRemainSubmitting={(value) => setRemainSubmitting(value)}
                        admissibleExtension={admissibleExtension}
                        supplier={supplier}
                        setSupplier={(value) => setSupplier(value)}
                        supplierSubmitting={supplierSubmitting}
                        setSupplierSubmitting={(value) => setSupplierSubmitting(value)}
                        selectedRemain={selectedRemain}
                        setIdRemains={(value) => setIdRemains(value)}
                        kiz={kiz}
                        setKiz={(value) => setKiz(value)}
                        kizSubmitting={kizSubmitting}
                        setKizSubmitting={(value) => setKizSubmitting(value)}
                        importRemains={() => {
                            if (isValid()) {
                                let item: ICreateItem = {
                                    idGlobal: uuidv4(),
                                    listIdFile: [remain.id as string, supplier.id as string],
                                    idStoreGlobal: store.idGlobal,
                                    idPricingModelGlobal: pricingModel.idGlobal
                                }
                                createRemainRequest(
                                    item,
                                    (value: boolean) => { setIsSubmitting(value) },
                                    (value: string) => setImportId(value),
                                    (value: string) => setIdRemains(value)
                                )
                            }
                        }}
                        importKiz={() => {
                            if (idRemains && kiz.id) {
                                let item: ICreateItem = {
                                    idGlobal: uuidv4(),
                                    listIdFile: [kiz.id as string],
                                    idStoreGlobal: store?.idGlobal ?? uuidv4(),
                                    idPricingModelGlobal: pricingModel?.idGlobal ?? uuidv4()
                                }
                                addKizRequest(
                                    item,
                                    idRemains,
                                    (value: boolean) => setIsSubmitting(value)
                                )
                            }
                        }}
                        isSubmitting={isSubmitting}
                        lockFromPermission={lockFromPermission}
                    />
                }
            </div>
        </BaseDocumentCreator >
    )
}