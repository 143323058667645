import { FC, useEffect, useState } from "react"
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins"
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider"
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { IDiscountProgramFormViewDTO } from "../../../libs/coreapi-dto/dirs/discountProgram";
import { DiscountProgramProvider } from "../../../Services/DataProviders/DiscountProgramProvider";
import DefaultDictionaryCommandsPanelV2 from "../../../components/commandsPanels/DefaultDictionaryCommandsPanelV2";
import { DateTime } from "luxon";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { Spinner } from "../../../components/spiner/Spinner";

const DiscountProgramFormGrid: FC<IGridProps> = (props) => {

    const appCtx = useAppContext();
    const discountProgramProvider = new DiscountProgramProvider(appCtx.coreApiService);

    const DefaultDatePeriod = { startDate: DateTime.now().plus({ days: -7 }), endDate: DateTime.now() } as IDatePeriod;
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const defaultGridColumnFilter = {
        numberPerPage: 15,
        pageNumber: 1,
        columnFilters:  [
            {
                name: "dateModified",
                value: DefaultDatePeriod.startDate!.toFormat('yyyyMMdd'),
                operator: "MoreOrEq"
            },
            {
                name: "dateModified",
                value: DefaultDatePeriod.endDate!.toFormat('yyyyMMdd'),
                operator: "LessOrEq"
            },
            {
                name: "deleted",
                value: false,
                operator: "Eq"
            }
        ] as IGridColumnFilter[]
    }
    const [gridFilter, dispatchGridFilter] = useGridFilter(defaultGridColumnFilter);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [data, setData] = useState<IDiscountProgramFormViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        discountProgramProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            if (viewState == 'refresh') setViewState('view')
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData)
            } else {
                setLoadingStatus(LoadingStatus.Completed)
            }
            setIsSubmitting(false)
        })
    }, [gridFilter, viewState])

    return (
        <PluginWrapper>
            {
                <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanelV2
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="discount_program_creator_plugin"
                        DefaultDatePeriod={DefaultDatePeriod}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        dataProvider={discountProgramProvider}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => {
                            setViewState(vs)
                        }}
                        isInactiveToPrint
                    />
                </ToolbarWrapper>
            }
            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    hiddenPagination={undefined}
                    gridId={props.gridId}
                    data={data}
                    separator
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    plugin={props.plugin}
                    dataProvider={discountProgramProvider}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default DiscountProgramFormGrid