import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { DocumentGridStateType } from "../../../../@types/documents";
import { DocumentStatusType, DocumentType, IdTableVariant, LoadingStatus } from "../../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../../components/plugins";
import { TabsPanel } from "../../../../components/tabs";
import { IInvoiceOutViewDTO } from "../../../../libs/coreapi-dto/documents/invoiceOut";
import { InvoiceOutDataProvider } from "../../../../Services/DataProviders/InvoiceOutDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import tabsStyles from "../../../../pages/styles/homePage.module.scss";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import styles from "../styles/index.module.scss";
import { Spinner } from "../../../../components/spiner/Spinner";
import { UserActionLogModal } from "../../../Service/UserActionLog/view/UserActionLogModal";
import { ProcessWarningModalWindow } from "../../../../components/modalWindows/ProcessWarningModalWindow";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { useTranslation } from "react-i18next";

export const InvoiceOutPluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();

    const invoiceOutDP = new InvoiceOutDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);

    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [data, setData] = useState<IInvoiceOutViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Completed);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);
    const [modalWindow, setModalWindow] = useState(<></>);

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemView, setSelectedItemViews] = useState<IInvoiceOutViewDTO>();

    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);

    const [impossibilityProcess, setImpossibilityProcess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    // Modal Log
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoiceOut." + value)

    useEffect(() => {
        setIsSubmitting(true);
        invoiceOutDP.getView(gridFilter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            setIsSubmitting(false);
            setLoadingStatus(totalCount > 0 ? LoadingStatus.Completed : LoadingStatus.NoData);
            if (viewState === "refresh") {
                setViewState("view");
            }
        });
    }, [gridFilter, viewState]);

    useEffect(() => {
        if (tabCtx.currentTab?.title === baseT("tabName")) {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
            invoiceOutDP.getById(selectedItem.idGlobal, (entity) => {
                kizDP.getKizViewAsync(selectedItem.idGlobal, { numberPerPage: 100, pageNumber: 1, columnFilters: [] }, (kiz, totalCount) => {
                    let kizs = entity.items
                        ?.map((x) => {
                            if (x.isKiz) {
                                return x.quantity;
                            } else {
                                return null;
                            }
                        })
                        .filter((x) => x !== null);
                    if (kizs.length > 0) {
                        if (
                            kizs.reduce((a, b) => {
                                return (a as number) + (b as number);
                            }) === totalCount
                        ) {
                            setImpossibilityProcess(false);
                        } else {
                            setImpossibilityProcess(true);
                        }
                    } else {
                        setImpossibilityProcess(false);
                    }
                });
            });
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginCtx.masterGrid.onUnselectEvent();
    }, [documentStatusType]);

    const deleteRow = () => {
        if (selectedItem?.[0]?.isDeleted || selectedDocumentState === "save" || selectedDocumentState === "proc") {
            // anything else ... ups, I don't know why it's empty
        } else {
            invoiceOutDP.markDelete(selectedItem?.idGlobal as string, () => {
                setViewState("refresh");
            });
        }
    };

    const isValidateContractor = (): string => {
        let response = ''
        let result = data.find(c => c.idGlobal === selectedItem?.idGlobal)
        if (!result) return baseT("dataOutError")
        if (result?.isKiz === false) return ''
        if (result?.contractorInn === '') response += t("directory.legalEntities.inn") + '; '
        if (result?.contractorInn === null) response += t("directory.legalEntities.inn") + '; '
        if (result?.contractorKizCode === '') response += baseT("codeKizMark") + '; '
        if (result?.contractorKizCode === null) response += baseT("codeKizMark") + '; '
        if (result?.contractorToKizAcceptionType === undefined) response += baseT("acceptMethod") + '; '
        if (result?.contractorToKizAcceptionType === null) response += baseT("acceptMethod") + '; '
        return response
    }

    const isValidateContractorTo = (): string => {
        let response = ''
        let result = data.find(c => c.idGlobal === selectedItem?.idGlobal)
        if (!result) return baseT("dataOutError")
        if (result?.isKiz === false) return ''
        if (result.contractorToInn === '') response += t("directory.legalEntities.inn") + '; '
        if (result.contractorToInn === null) response += t("directory.legalEntities.inn") + '; '
        if (result.contractorToKizCode === '') response += baseT("codeKizMark") + '; '
        if (result.contractorToKizCode === null) response += baseT("codeKizMark") + '; '
        if (result.contractorToKizAcceptionType === undefined) response += baseT("acceptMethod") + '; '
        if (result.contractorToKizAcceptionType === null) response += baseT("acceptMethod") + '; '
        return response
    }

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="invoice_out_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        dataProvider={invoiceOutDP}
                        pluginSettings={props.plugin}
                        idTable={IdTableVariant.InvoiceOut}
                        setViewState={(vs) => setViewState(vs)}
                        advancedFeatures
                        impossibilityAction={{ impossibilityProcess: impossibilityProcess }}
                        items={[
                            {
                                label: t("general.showUserLog"),
                                onClick: () => selectedItem && setShowModalLog(true),
                                disabled: selectedItem ? false : true,
                            },
                        ]}
                        mnemocode={selectedItemView?.mnemocode}
                        checkLockingDocuments
                        isValidateProc={(callback) => { 
                            let responseContractor = isValidateContractor()
                            if (responseContractor !== '') {
                                callback(false)
                                renderGlobalAlert({ variant: "error", statusCode: 404, 
                                title: `${baseT("messages.mdlpSupplierExchangeDataValidation")}: ${responseContractor}`});
                                return
                            }

                            let responseContractorTo = isValidateContractorTo()
                            if (responseContractorTo !== '')
                            {                                
                                setModalWindow(
                                 <ProcessWarningModalWindow
                                   text={`${baseT("message.mdlpConsigneeDataValidation")}: ${responseContractorTo} 
                                   ${baseT("message.provideDocumentDataSendToUnregisteredMdlpPlace")}. ${t("general.confirm")}`}
                                   cancel={() => {
                                     setModalWindow(<></>)
                                     callback(false)
                                     return
                                   }}
                                   process={() => {
                                     setModalWindow(<></>)
                                     callback(true)
                                     return
                                   }}
                                 />
                               );
                            }
                            else callback(true)
                            
                        }}
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <DefaultGrid
                                openWithEnterForEdit={{
                                pluginTabContext:"invoice_out_creator_plugin",
                                mnemocode:selectedItemView?.mnemocode,
                                selectedDocumentState}}
                                gridId={props.gridId}
                                data={data}
                                documentStatus
                                separator
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                actionGridRow={{ delete: () => deleteRow() }}
                                filter={gridFilter}
                                hiddenPagination={undefined}
                                dataProvider={invoiceOutDP}
                                totalCount={totalCount}
                                plugin={props.plugin}
                                multipleSelect={multipleSelect}
                                selectedItem={selectedItem}
                                selectedItems={selectedItems}
                                onSelect={(row) => {
                                    setSelectedItem(row);
                                    row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.invoiceOut) : pluginCtx.masterGrid.onUnselectEvent();
                                }}
                                onMultipleSelect={(rows) => {
                                    setSelectedItems(rows);
                                }}
                                onSort={(i) => {
                                    dispatchGridFilter({ type: "sort", payload: i.propertyName });
                                }}
                                onFilterDelete={(i) => {
                                    i.propertyName === "documentState" && setDocumentStatusType(undefined);
                                    dispatchGridFilter({
                                        type: "deleteColumnFilter",
                                        payload: i.propertyName,
                                    });
                                }}
                                onPageNumberChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changePageNumber",
                                        payload: { pageNumber: n },
                                    });
                                }}
                                onNumberPerPageChange={(n) => {
                                    dispatchGridFilter({
                                        type: "changeNumberPerPage",
                                        payload: { numberPerPage: n },
                                    });
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.detailsTabView}>
                        {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect && (
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div
                                        id="detailsTabsPanelWrapper"
                                        style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            width: "100%",
                                        }}
                                    >
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) =>
                                                dispatchDetailsTabsPanel({
                                                    type: "activate",
                                                    payload: id,
                                                })
                                            }
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                    {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                </div>
                {modalWindow}
            </PluginWrapper>
        </>
    );
};

export default InvoiceOutPluginView;
