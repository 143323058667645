import { FC, CSSProperties } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common";

interface IGridSelectorModalWindowProps {
    gridDisplayName?: string;
    onlyGridDisplayName?: string;
    saveTitle?: string;
    customStyles?: boolean;
    cancelTitle?: string;
    ok: IButton;
    cancel: IButton;
    modalWindowClassName?: string;
    style?: CSSProperties;
    fullScreen?: boolean
    entityToPressEnter?: IEntitySimpleDTO | {}
}

export const GridSelectorModalWindow: FC<IGridSelectorModalWindowProps> = (props) => {
    const title: string = props.onlyGridDisplayName ?? 'Выбор строк из справочника: ' + props.gridDisplayName;

    return (
        <BaseModalWindow
            header={title}
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={props.modalWindowClassName} style={props.style}
            footerStyle='customStyles'
            fullScreen={props?.fullScreen}
            onKeyDown={(e)=> {
                if(e.key === 'Enter' && props.entityToPressEnter) {
                if (Object.keys(props.entityToPressEnter).length !== 0) {
                    props.ok.onClick()
                }}
            }}
        >
            {props.children}
        </BaseModalWindow>
    );
};
