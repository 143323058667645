import { FC, useContext, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { IPricingModelViewDTO } from "../../../libs/coreapi-dto/dirs/pricingModel";
import { PricingModelDataProvider } from "../../../Services/DataProviders/PricingModelDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { PricingModelCommandsPanel } from "./components/PricingModelCommandsPanel";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { BaseModalWindow } from "../../../components/modalWindows/BaseModalWindow";
import { ImportPricingModelView } from "../../Service/ImportPricingModel/ImportPricingModelView";
import { useTranslation } from "react-i18next";

const PricingModelGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext()
    const tabContext = useTabsContext();
    const { t } = useTranslation();

    const pricingModelDataProvider = new PricingModelDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<IPricingModelViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? { ...CreateSelectorGridFilter, fieldOrderBy: 'name' });
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const [importPricingModal, setImportPricingModal] = useState<boolean>(false);
    const showModalImport = () => setImportPricingModal(prev => !prev);

    const [exportPricingModal, setExportPricingModal] = useState<boolean>(false);
    const showModalExport = () => setExportPricingModal(prev => !prev);

    useEffect(() => {
        getView();
    }, [viewState, gridFilter])


    const getView = () => {
        setSelectedItem(undefined);
        setLoadingStatus(LoadingStatus.InProcess)
        pricingModelDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
        })
    }

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <PricingModelCommandsPanel
                        add={{
                            onClick: () => {
                                tabContext.openChild("pricing_model_create_plugin", "create", undefined, () => {
                                    getView();
                                });
                            },
                        }}
                        edit={{
                            onClick: () => {
                                if (selectedItem)
                                    tabContext.openChild("pricing_model_create_plugin", "edit", selectedItem.idGlobal, () => {
                                        getView();
                                    });
                            },
                            disabled: !selectedItem,
                        }}
                        copy={{
                            onClick: () => {
                                if (selectedItem)
                                    tabContext.openChild("pricing_model_create_plugin", "copy", selectedItem.idGlobal, () => {
                                        getView();
                                    });
                            },
                            disabled: !selectedItem,
                        }}
                        delete={{
                            onClick: () => {
                                if (selectedItem)
                                    pricingModelDataProvider.markDelete(selectedItem.idGlobal, () => {
                                        getView();
                                    });
                            },
                            disabled: !selectedItem || data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted,
                        }}
                        restore={{
                            onClick: () => {
                                if (selectedItem)
                                    pricingModelDataProvider.restore(selectedItem.idGlobal, () => {
                                        getView();
                                    });
                            },
                            disabled: !selectedItem || !data?.find((x) => x.idGlobal == selectedItem?.idGlobal)?.deleted,
                        }}
                        showDeleted={{
                            disabled: false,
                            onChecked: (e) => dispatchGridFilter({ type: 'showDeleted', payload: e }),
                        }}
                        refresh={{
                            onClick: getView
                        }}
                        export={{
                            onClick: showModalExport,
                            disabled: !selectedItem
                        }}
                        import={{
                            onClick: showModalImport
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>
                {
                    <DefaultGrid
                        separator
                        gridId={props.gridId}
                        data={data}
                        loadingStatus={loadingStatus}
                        setLoadingStatus={setLoadingStatus}
                        totalCount={totalCount}
                        filter={gridFilter}
                        dataProvider={pricingModelDataProvider}
                        plugin={props.plugin}
                        selectedItem={selectedItem}
                        onSelect={(row) => {
                            setSelectedItem(row)
                            props.onSelect?.(row)
                        }}
                        onDoubleClick={(i) => props.onDoubleClick?.(i)}
                        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                        onEnter={() => setViewState("edit")}
                    />
                }
            </PluginWrapper>
            {
                exportPricingModal &&
                <BaseModalWindow
                    header={t('directory.pricing.model.export')}
                    ok={{ onClick: showModalExport, title: 'Подтвердить' }}
                    cancel={{ onClick: showModalExport }}
                >
                    <p>Вы действительно хотите провести экспорт модели ценообразования?</p>
                </BaseModalWindow>
            }
            {
                importPricingModal &&
                <BaseModalWindow
                    header={t('directory.pricing.model.import')}
                    ok={{ onClick: ()=> {}, hidden: true}}
                    cancel={{ onClick: ()=> {}, hidden: true }}
                >
                    <ImportPricingModelView plugin={'import_pricing_model_plugin'} cancel={{onClick: showModalImport}}/>
                </BaseModalWindow>
            }
        </>
    )
}

export default PricingModelGrid