import React, { useContext, useEffect, useState } from 'react'
import { LoadingStatus } from '../../../../@types/enumsGlobal';
import { ImportInvoicesModalWindow } from '../../../../components/modalWindows/ImportInvoicesModalWindow';
import { IDataForMoveFiles } from '../../../../libs/coreapi-dto/dirs/importInvoices';
import { IImportedStatusList } from '../../../../libs/coreapi-dto/documents/importedInvoice';
import { ImportedInvoiceDataProvider } from '../../../../Services/DataProviders/ImportedInvoiceDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';

interface IImportInvoicesCompletedModalWindowProps {
    cancel: () => void
    setLoadingStatus: (value: LoadingStatus) => void
    openBunchTab: (items, idInvoice, isSucceed, dataForMoveInvoices, callbackAfterImport, supplierDocNum) => void
    crashedInvoices: IImportedStatusList[]
    succeedInvoice: IImportedStatusList[]
    refresh: () => void
    dataForMoveInvoices: IDataForMoveFiles
    callbackAfterImport: ()=>void
}
export const ImportInvoicesCompletedModal: React.FC<IImportInvoicesCompletedModalWindowProps> = props => {
    const appCtx = useAppContext();
    const importedInvoiceDataProvider = new ImportedInvoiceDataProvider(appCtx.coreApiService);

    const openBunchWindow = () => {
        props.succeedInvoice.forEach(x => {
            importedInvoiceDataProvider.getById(x.idImportedInvoiceGlobal, (e) => {
                props.openBunchTab([...e.items.map(i => {
                    let c = x.byBarcode.find(z=>z.idInvoiceItem === i.idGlobal);
                    return ({ ...i, success: true, byBarcode: c?.byBarcode,supplierVat: i.supplierCostInfo?.vat ?? 0 })
                } 
                )], x.idImportedInvoiceGlobal, x.isSucceed, props.dataForMoveInvoices, e.supplierDocNum, props.callbackAfterImport)
            })
        })
        props.crashedInvoices.forEach(x => {
            importedInvoiceDataProvider.getById(x.idImportedInvoiceGlobal, (e) => {
                let errorList = x.errorsList.map(x => x.split(' ')[0]);
                props.openBunchTab([...e.items.map(i => {
                    let c = x.byBarcode.find(z=>z.idInvoiceItem === i.idGlobal);
                    return ({ ...i, success: errorList.find(y => y === i.idGlobal) ? false : true, byBarcode: c?.byBarcode,supplierVat: i.supplierCostInfo?.vat ?? 0 })
                } 
                )], x.idImportedInvoiceGlobal, x.isSucceed, props.dataForMoveInvoices, e.supplierDocNum, props.callbackAfterImport)
            })
        })
    }

    const returnMessage = () => {
        if (props.crashedInvoices.length > 0 && props.succeedInvoice.length > 0) {
            return `В ${props.crashedInvoices.length} документе ошибки накладной, откроются окна связки, ${props.succeedInvoice.length} исправны, откроются окна для подтверждения`
        } else if (props.crashedInvoices.length > 0 && props.succeedInvoice.length <= 0) {           
            return  `
                При импорте накладных произошла ошибка: \n  
                ${props.crashedInvoices.map((x) : string => {
                    return x.errorsList.join("\n")                    
                })}
            `
        } else if (props.crashedInvoices.length <= 0 && props.succeedInvoice.length > 0) {
            return `${props.succeedInvoice.length} накладная(ых) исправны, откроются окна подтверждения`
        } else if (props.crashedInvoices.length === 0 && props.succeedInvoice.length === 0) {
            return 'При импорте накладных произошла ошибка.'
        }
    }

    function clearImportedInvoices()
    {
        props.succeedInvoice.forEach(x => {
            importedInvoiceDataProvider.deleteImportedInvoice(x.idImportedInvoiceGlobal);
        })
        props.crashedInvoices.forEach(x => {
            importedInvoiceDataProvider.deleteImportedInvoice(x.idImportedInvoiceGlobal);
        })
    }

    useEffect(() => {
        const handleTabClose = (event) => {
            clearImportedInvoices();
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    return (
        <ImportInvoicesModalWindow
            ok={{
                onClick: () => {
                    openBunchWindow()
                    props.cancel()
                }
            }}
            cancel={{ onClick: () => {
                clearImportedInvoices();
                props.cancel();
            }}}
        >
        {
            returnMessage()?.split("\n").map((x) => <p>{x}</p>)
        }
        </ImportInvoicesModalWindow>
    )
};