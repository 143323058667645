import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {DocumentGridStateType} from '../../@types/documents';
import {DocumentStatusType, IdTableVariant, SysOptionName} from '../../@types/enumsGlobal';
import {IKeyValuePair} from '../../Business/Reports/Shared/Interfaces/IKeyValuePair';
import {DocumentsFilteringPanel} from '../../hoc/DocumentsFilteringPanel/DocumentsFilteringPanel';
import {DocumentDataProviderV2} from '../../Services/DataProviders/Common/DocumentDataProviderV2';
import {GridFilterAction, DefaultDatePeriod} from '../../system/hooks/useGridFilter';
import {useTabsContext} from '../../system/providers/tabsProvider';
import {IDatePeriod} from '../datePeriodPicker/datePeriodPicker';
import {IItem} from '../dropDown/ButtonDropDown';
import {DeleteModalWindow} from '../modalWindows/DeleteModalWindow';
import {ProcessModalWindow} from '../modalWindows/ProcessModalWindow';
import {RestoreModalWindow} from '../modalWindows/RestoreModalWindow';
import {SearchModalWindow} from '../modalWindows/SearchModalWindow';
import {UnprocessModalWindow} from '../modalWindows/UnprocessModalWindow';
import DocumentsCommandsPanel from './DocumentsCommandsPanel';
import styles from './styles/BaseCommandsPanel.module.scss';
import useLockingDocuments from '../lockDocuments/useLockingDocuments';
import { useAppContext } from '../../system/providers/appContextProvider';
import { SysOptionsDataProvider } from '../../Services/DataProviders/SysOptionsDataProvider';
import { IJson } from '../../Business/Service/SysOptions/components/interface';
import { CancelModalWindow } from '../modalWindows/CancelModalWindow';

export type DateType = 'date' | 'documentDate' | 'dateCreated';

interface IDefaultDocumentsPanelProps<
  TDocumentViewDtoPagenatedView,
  TDocumentGetDTO,
  TDocumentCreateDTO,
  IDocumentUpdateDTO,
  TDocumentItemViewDtoPagenatedView,
  TDocumentItemGetDTO
> {
  selectedItem?: [IGridRow | undefined, (value: IGridRow | undefined) => void];
  selectedItems: [IGridRow[] | [], (value: IGridRow[] | []) => void];
  gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>];
  documentStatusType: [DocumentStatusType | undefined, (value: DocumentStatusType | undefined) => void];
  selectedDocumentStatusType: DocumentStatusType | undefined;
  multipleSelect?: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  dataProvider: DocumentDataProviderV2<
    TDocumentViewDtoPagenatedView,
    TDocumentGetDTO,
    TDocumentCreateDTO,
    IDocumentUpdateDTO,
    TDocumentItemViewDtoPagenatedView,
    TDocumentItemGetDTO
  >;
  pluginSettings: IPluginSettings;
  plugin: IPlugin;
  idTable?: IdTableVariant;
  setViewState?: (viewState: DocumentGridStateType) => void;
  dateType?: DateType;
  advancedFeatures?: boolean;
  items?: IItem[];
  impossibilityAction?: {impossibilityProcess: boolean};
  mnemocode: string | undefined
  processDisabled?: boolean;
  unProcessDisabled?: boolean;
  checkLockingDocuments?: boolean;
  isCancelVisible?: boolean;
  isValidateProc?: (callback: (result: boolean) => void) => void;
}
function checkDocumentStateForMultipleChoice(arr:IGridRow[] | []) {
  if (arr.length === 0) {
    return true;
  }
  return !arr.every(object => {
    const documentStateCell = object.cells.find(cell => cell.propertyName === 'documentState');
    return documentStateCell && documentStateCell.value === 'draft'
  });
}

function checkDocumentStateForMultipleChoiceRestore(arr:IGridRow[] | []):boolean {
  if (arr.length === 0) {
    return true;
  }
  return !arr.every(object => {
   return object.isDeleted
  });
}

const DefaultDocumentsCommandsPanelV2 = <
  TDocumentViewDtoPagenatedView,
  TDocumentGetDTO,
  TDocumentCreateDTO,
  IDocumentUpdateDTO,
  TDocumentItemViewDtoPagenatedView,
  TDocumentItemGetDTO
>(
  props: PropsWithChildren<
    IDefaultDocumentsPanelProps<
      TDocumentViewDtoPagenatedView,
      TDocumentGetDTO,
      TDocumentCreateDTO,
      IDocumentUpdateDTO,
      TDocumentItemViewDtoPagenatedView,
      TDocumentItemGetDTO
    >
  >
) => {
  const appContext = useAppContext() 
  const tabsContext = useTabsContext();
  const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)
  const [datePeriod, setDatePeriod] = useState<IDatePeriod>(DefaultDatePeriod);
  const [viewState, setViewState] = useState<DocumentGridStateType>('view');
  const [modalWindow, setModalWindow] = useState(<></>);
  const [stateNotice, setStateNotice] = useState<boolean>(false)

  const lockingDocuments = useLockingDocuments();

  useEffect(() => {
    if (viewState === 'refresh' || viewState === 'view') {
      sysOptionsDataProvider.getByName(SysOptionName.StateNotice, (data) => {
        if (!data) return
        let obj: IJson = JSON.parse(data.jsonData); 
        if (!obj) return      
        if (obj.Value === undefined) return
        setStateNotice(Boolean(obj.Value)) 
      })
    }
  }, [viewState]);

  useEffect(() => {
    if (viewState === 'refresh') {
      refreshDocument();
    } else {
      renderModalWindow();
    }
  }, [viewState]);

  const dispatchFilter = (
    dispatchGridFilter: (data: GridFilterAction) => void,
    datePeriod?: IDatePeriod,
    documentState?: DocumentStatusType
  ) => {
    dispatchGridFilter({
      type: 'sortByFilters',
      payload: {
        gridColumnFilter: [
          ...(datePeriod?.startDate
            ? [
                {
                  name: props.dateType ?? 'documentDate',
                  value: datePeriod.startDate.toFormat('yyyyMMdd'),
                  operator: 'MoreOrEq',
                } as IGridColumnFilter,
              ]
            : []),
          ...(datePeriod?.endDate
            ? [
                {
                  name: props.dateType ?? 'documentDate',
                  value: datePeriod.endDate.toFormat('yyyyMMdd'),
                  operator: 'LessOrEq',
                } as IGridColumnFilter,
              ]
            : []),
          ...(documentState
            ? [
                {
                  name: 'documentState',
                  value: documentState,
                  operator: 'Eq',
                } as IGridColumnFilter,
              ]
            : []),
        ],
      },
    });
  };

  function refreshDocument() {
    props.setViewState?.('refresh');
    dispatchFilter(() => props.gridFilter?.[1], datePeriod, undefined);
    setViewState('view');
  }

  function renderModalWindow() {
    switch (viewState) {
      case 'process':
        renderProcessModalWindow();
        break;
      case 'unprocess':
        renderUnprocessModalWindow();
        break;
      case 'delete':
        renderDeleteModalWindow();
        break;
      case 'restore':
        renderRestoreModalWindow();
        break;
      case 'restoreAll':
        renderRestoreModalWindow();
        break;
      case 'search':
        renderSearchWindow();
        break;
      case 'cancel':
        renderCancelWindow();
        break;
      default:
        setModalWindow(<></>);
        break;
    }
  }

  const isValidateProc = (callback:(result: boolean) => void) => {
    if (!props.isValidateProc) callback(true)
    props?.isValidateProc?.((result) => {
      callback(result)
    })
  }

  function renderProcessModalWindow() {

    const sendData = () => {
      isValidateProc((result) => {
        if (result === false) {
          setViewState('return')
          return
        }

        if (props.selectedItems[0].length > 0) {
          props.selectedItems[0].forEach((element) => {
            lockingDocuments.check(element?.idGlobal as string, (e) => {
              if (!e) {
                props.dataProvider?.process(element.idGlobal as string, (e) => {
                  if (e.respType=== 'isCompleted') setViewState('refresh')
                  else setViewState('return')
                });
              }
            })

          })
        } else {
          lockingDocuments.check(props.selectedItem?.[0]?.idGlobal as string, (e) => {
            if (!e) {
              props.dataProvider?.process(props.selectedItem?.[0]?.idGlobal as string, (e) => {
                if (e.respType=== 'isCompleted') setViewState('refresh')
                else setViewState('return')
              });
            }
          })

        }
    })
    }

    if (stateNotice === false) {
      sendData()
      return
    }

    setModalWindow(
      <ProcessModalWindow
        name={props.selectedItem?.[0]?.cells['name'] as string}
        header={props.selectedItem?.[0]?.displayName}
        cancel={() => {
          setViewState('return');
        }}
        process={() => {
          sendData()
        }}
      />
    );
  }

  function renderUnprocessModalWindow() {

    const sendData = () => {
      lockingDocuments.check(props.selectedItem?.[0]?.idGlobal as string, (e) => {
        if (!e) {
          props.dataProvider?.unProcess(props.selectedItem?.[0]?.idGlobal as string, (e) => {
            if (e.respType=== 'isCompleted') setViewState('refresh')
            else setViewState('return')
          })
        }
      })
    }

    if (stateNotice === false) {
      sendData()
      return
    }

    setModalWindow(
      <UnprocessModalWindow
        name={props.selectedItem?.[0]?.cells['name'] as string}
        header={props.selectedItem?.[0]?.displayName}
        cancel={() => {
          setViewState('return');
        }}
        unprocess={() => sendData()}
      />
    );
  }

  function renderDeleteModalWindow() {
    const sendData =() => {
      if (props.selectedItems[0].length > 0) {
        props.selectedItems[0].forEach((element) => {
          lockingDocuments.check(element?.idGlobal as string, (e) => {
            if (!e) {
              props.dataProvider?.markDelete(element.idGlobal as string, (e) => {
                if (e.respType=== 'isCompleted') setViewState('refresh')
                else setViewState('return')
              });
              props.selectedItems?.[1]([]);
            }
          })
        })
      } else {
        lockingDocuments.check(props.selectedItem?.[0]?.idGlobal as string, (e) => {
          if (!e) {
            props.dataProvider?.markDelete(props.selectedItem?.[0]?.idGlobal as string, (e) =>{
              if (e.respType=== 'isCompleted') setViewState('refresh')
              else setViewState('return')
            });
            props.selectedItem?.[1](undefined);
          }
        })
      }
    }
    
    if (stateNotice === false) {
      sendData()
      return
    }

    setModalWindow(
      <DeleteModalWindow
        name={props.selectedItem?.[0]?.cells['name'] as string}
        header={props.selectedItem?.[0]?.displayName}
        cancel={{
          onClick: () => {
            setViewState('return');
          },
        }}
        delete={{
          onClick: () => {sendData()},
          title: 'Удалить',
        }}
      />
    );
  }

  function renderRestoreModalWindow() {
    const sendData = () => {
      props.dataProvider?.restore(props.selectedItem?.[0]?.idGlobal as string, (e) => {
        if (e.respType=== 'isCompleted') setViewState('refresh')
        else setViewState('return')
      }
    );
    }
    function sendDataAll () {
      props.selectedItems[0].forEach((element) => {
        props.dataProvider?.restore(element.idGlobal as string, (e) => {
          if (e.respType === 'isCompleted') {
            setViewState('refresh')
          } else setViewState('return')
        });
      })
    }

    if (stateNotice === false) {
      sendData()
      return
    }

    setModalWindow(
      <RestoreModalWindow
        name={viewState === 'restoreAll' ? '' : props.selectedItem?.[0]?.cells['name'] as string}
        header={viewState === 'restoreAll' ? '' : props.selectedItem?.[0]?.displayName}
        cancel={{
          onClick: () => {
            setViewState('return');
          },
        }}
        restore={{
          onClick: () => {
            viewState === 'restoreAll' ? sendDataAll() : sendData()
            props.selectedItem?.[1](undefined);
          },
          title: 'Восстановить',
        }}
      />
    );
  }

  function renderSearchWindow() {
    const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? [];

    setModalWindow(
      <SearchModalWindow
        columns={visibleColumns}
        cancel={() => setViewState('return')}
        defaultFilters={props.gridFilter?.[0].columnFilters}
        search={(columnFilters) => {
          props.gridFilter?.[1]({type: 'search', payload: columnFilters});
          const documentStatus: string = columnFilters
            .find((x) => x.name === 'documentState')
            ?.value.replace(/[^a-zа-яё0-9\s]/gi, '');
          props.documentStatusType?.[1]((documentStatus as DocumentStatusType) ?? undefined);
          setViewState('refresh');
        }}
      />
    );
  }

  function renderCancelWindow() {
    const sendData =() => {
      if (props.selectedItems[0].length > 0) {
        props.selectedItems[0].forEach((element) => {
          lockingDocuments.check(element?.idGlobal as string, (e) => {
            if (!e) {
              props.dataProvider?.cancel(element.idGlobal as string, (e) => {
                if (e.respType=== 'isCompleted') setViewState('refresh')
                else setViewState('return')
              });
              props.selectedItems?.[1]([]);
            }
          })
        })
      } else {
        lockingDocuments.check(props.selectedItem?.[0]?.idGlobal as string, (e) => {
          if (!e) {
            props.dataProvider?.cancel(props.selectedItem?.[0]?.idGlobal as string, (e) =>{
              if (e.respType=== 'isCompleted') setViewState('refresh')
              else setViewState('return')
            });
            props.selectedItem?.[1](undefined);
          }
        })
      }
    }
    
    if (stateNotice === false) {
      sendData()
      return
    }

    setModalWindow(
      <CancelModalWindow
        name={props.selectedItem?.[0]?.cells['name'] as string}
        header={props.selectedItem?.[0]?.displayName}
        cancel={{
          onClick: () => {
            setViewState('return');
          },
        }}
        confirm={{
          onClick: () => {sendData()},
          title: 'Отменить',
        }}
      />
    );
  }

  function openChildEdit() {
    tabsContext.openChild(props.plugin, 'edit', props.selectedItem?.[0]?.idGlobal, () => {
      setViewState('refresh');
      props.selectedItem?.[1]?.(undefined);
    }, props.mnemocode);
  }

  function openChildEditArray() {
    props.selectedItems[0].forEach((element) => {
      tabsContext.openChild(props.plugin, 'edit', element.idGlobal, () => {
        setViewState('refresh');
        props.selectedItems?.[1]?.([]);
      }, element.cells.find(el => el.propertyName === 'mnemocode')?.value);
    })
  }

  return (
    <div className={styles.wrapper}>
      <DocumentsCommandsPanel
        add={{
          onClick: () =>
            tabsContext.openChild(props.plugin, 'create', undefined, () => {
              setViewState('refresh');
              props.selectedItem?.[1]?.(undefined);
            }),
            disabled: props.multipleSelect?.[0] === true ? props.selectedItems?.[0].length !== 0 : false
        }}
        edit={{
          onClick: () => {
            if (props.checkLockingDocuments) {
              if (props.selectedItems[0].length > 0) {
                props.selectedItems[0].forEach((element) => {
                  lockingDocuments.check(element?.idGlobal as string, (e) => {
                    if (!e) {
                      tabsContext.openChild(props.plugin, 'edit', element.idGlobal, () => {
                        props.selectedItems?.[1]?.([]);
                      }, element.cells.find(el => el.propertyName === 'mnemocode')?.value);
                    }
                  })
                })
                setViewState('refresh');
              } else {
                lockingDocuments.check(props.selectedItem?.[0]?.idGlobal as string, (e) => {
                    openChildEdit();
                })
              }
            } else {
              if (props.selectedItems[0].length > 0) {
                openChildEditArray();
              } else {
                openChildEdit();
              }
            }
          },
          disabled:
            props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]) :
            props.selectedItem?.[0] === undefined ||
            props.selectedItem?.[0]?.isDeleted ||
            props.selectedDocumentStatusType === 'del',
        }}
        process={{
          onClick: () => setViewState('process'),
          disabled:
            props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]) :
            props.selectedItem?.[0] === undefined ||
            props.selectedDocumentStatusType === 'proc' ||
            props.selectedDocumentStatusType === 'del' ||
            props.processDisabled === true,
        }}
        unProcess={{
          onClick: () => setViewState('unprocess'),
          disabled:
            props.selectedItem?.[0] === undefined ||
            props.selectedDocumentStatusType === 'draft' ||
            props.selectedDocumentStatusType === 'save' ||
            props.selectedDocumentStatusType === 'del' ||
            props.unProcessDisabled === true,
        }}
        copy={{
          onClick: () =>
            tabsContext.openChild(props.plugin, 'copy', props.selectedItem?.[0]?.idGlobal, () =>
              setViewState('refresh')
            ),
          disabled: props.selectedItem?.[0] === undefined,
        }}
        print={{
          onClick: () => {
            return props.idTable;
          },
          onSubItemClick: () => {
            let arr = new Array<IKeyValuePair>();
            let rows: Array<IGridRow> = new Array<IGridRow>();
            props.selectedItems?.[0].length > 0 ? rows.push(...props.selectedItems?.[0]) : rows.push(props.selectedItem?.[0] as IGridRow);
            rows.forEach(function (row) {
              const mnemocode = row.cells.find((e) => {
                return e.propertyName == 'mnemocode';
              })?.value;
              if (mnemocode != undefined) arr.push({key: row.idGlobal, value: mnemocode});
            });
            return arr;
          },
          disabled: props.multipleSelect?.[0] === true && props.selectedItems?.[0].length > 0 ?  false :
          props.selectedItem?.[0] === undefined,
        }}
        delete={{
          onClick: () => setViewState('delete'),
          disabled:
            props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]) :
            props.selectedItem?.[0] === undefined ||
            props.selectedItem?.[0]?.isDeleted ||
            props.selectedDocumentStatusType === 'proc',
        }}
        restore={{
          onClick: () => {
            if(props.multipleSelect?.[0] === true && props.selectedItems?.[0].length > 0) {
              setViewState('restoreAll')
            } else { 
              setViewState('restore')
             }
          },
          disabled:
            props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoiceRestore(props.selectedItems?.[0]) :
            props.selectedItem?.[0] === undefined ||
            !props.selectedItem?.[0]?.isDeleted ||
            props.selectedDocumentStatusType === 'save' ||
            props.selectedDocumentStatusType === 'proc' ||
            props.selectedDocumentStatusType === 'draft',
        }}
        search={{
          onClick: () => setViewState('search'),
          disabled: props.multipleSelect?.[0] === true ? props.selectedItems?.[0].length !== 0 : false
        }}
        cancel={{
          onClick: () => setViewState('cancel'),
          disabled:
            props.multipleSelect?.[0] === true ?  checkDocumentStateForMultipleChoice(props.selectedItems?.[0]) :
            props.selectedItem?.[0] === undefined ||
            props.selectedItem?.[0]?.isDeleted ||
            props.selectedDocumentStatusType === 'done',
          isVisible: props.isCancelVisible
        }}
        refresh={{
          onClick: () => {
            refreshDocument();
          },
        }}
        groupProcessing={{
          onClick: () => {
            props?.multipleSelect?.[1](!props?.multipleSelect?.[0]);
          },
          value: props?.multipleSelect?.[0],
        }}
        advancedFeatures={props.advancedFeatures}
        items={props.items}
        permission={props.pluginSettings.permission}
      />
      <DocumentsFilteringPanel
        datePeriod={datePeriod}
        onDatePeriodChange={(date: IDatePeriod) => {
          setDatePeriod(date);
          if (props.gridFilter?.[1]) {
            dispatchFilter(props.gridFilter[1], date, props.documentStatusType[0]);
          }
        }}
        multipleSelect={props?.multipleSelect?.[0]}
        documentStatusType={props.documentStatusType[0]}
        onDocumentStatusTypeChange={(value) => {
          props.documentStatusType?.[1](value);
          if (props.gridFilter?.[1]) {
            dispatchFilter(props.gridFilter[1], datePeriod, value);
          }
        }}
        onMultipleSelectChange={props?.multipleSelect?.[1]}
      />

      {modalWindow}
    </div>
  );
};

export default DefaultDocumentsCommandsPanelV2;
