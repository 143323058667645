export const BarcodeGridPluginSettings: IPluginSettings = {
    name: 'Штрих-коды и GTIN',
    mnemocode: 'barcode_plugin',
    permission: 'BarCode',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ИД',
        visibility: false,
        width: 157
    },{
        order: 1,
        propertyName: 'goodsName',
        propertyType: 'string',
        displayName: 'Товар',
        visibility: true,
        width: 100
    },{
        order: 2,
        propertyName: 'code',
        propertyType: 'string',
        displayName: 'Код',
        visibility: true,
        width: 100
    },{
        order: 3,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 100
    },{
        order: 4,
        propertyName: 'contractorName',
        propertyType: 'string',
        displayName: 'Контрагент',
        visibility: true,
        width: 100
    },{
        order: 5,
        propertyName: 'idSourceEf',
        propertyType: 'string',
        displayName: 'ИД ЕФ справочника',
        visibility: true,
        width: 100
    },{
        order: 6,
        propertyName: 'idSourceEs',
        propertyType: 'string',
        displayName: 'ИД ЕС справочника',
        visibility: true,
        width: 100
    },{
        order: 7,
        propertyName: 'barCodeType',
        propertyType: 'string',
        displayName: 'Тип ШК',
        visibility: true,
        width: 100
    },{
        order: 8,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 100
    },{
        order: 9,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 100
    },{
        order: 10,
        propertyName: 'deleted',
        propertyType: 'boolean',
        displayName: 'Удалено',
        visibility: false,
        width: 100
    }],
}

export default BarcodeGridPluginSettings