import { FC, useContext, useState, useEffect } from "react";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { usePluginContext } from "../../../../system/providers/plugin";
import { PluginWrapper } from "../../../../components/plugins";
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { ImportRemainsDataProvider } from "../../../../Services/DataProviders/ImportRemainsDataProvider";
import { IImportRemainsItemCreateDTO, IImportRemainsItemEditDTO, IImportRemainsItemViewDTO } from "../../../../libs/coreapi-dto/documents/importRemainsItem";
import { DocumentType, IdTableVariant } from "../../../../@types/enumsGlobal";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { getImportRemainsItemUpdateNew } from "../../../../libs/core-api-requests/customRequest/ImportRemainsItemUpdateRequestNew";
import { IImportRemainsCreateDTO, IImportRemainsUpdateDTO } from "../../../../libs/coreapi-dto/documents/importRemains";
import { BarcodeType, IKizBoxDTO, IKizDTO, IKizStatusMoveErrorDTO, IKizStatusMoveErrorInDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { getNewKiz, getNewKizBox } from "../../Invoice/InvoiceItem/InvoiceItemDetailsGrid";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { kizCounter } from "../../../../system/functions/sumKiszCount";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import { useTranslation } from "react-i18next";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";

export interface IUIModel {
    idGlobal: string
    name: string
    mnemocode: string
    codcode: number
    nameshort: string
    deleted: boolean
    dateDeleted: string
    dateModified: string
}

export interface ICopyGridProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    value: IImportRemainsItemCreateDTO | undefined;
}

const ImportRemainsItemGrid: FC<ICopyGridProps> = (props) => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();

    const { t } = useTranslation();
    const errorsT = (value: string) => t("errors." + value);

    const importRemainsDataProvider = new ImportRemainsDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IImportRemainsItemViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();

    const [selectedScalingRation, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);
    const [dataImportRemainsState, setDataImportRemainsState] = useState<string>();
    const [dataImportRemains, setDataImportRemains] = useState<IImportRemainsCreateDTO>();
    const [dataImportRemainsItem, setDataImportRemainsItem] = useState<IImportRemainsItemCreateDTO>();
    const [dataImportRemainsItems, setDataImportRemainsItems] = useState<IImportRemainsItemCreateDTO[]>([]);
    const [showKizScan, setShowKizScan] = useState<boolean>(false);

    const kdp = new KizDataProvider(appCtx.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appCtx.coreApiService);

    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "", value: undefined });

    const lockingDocuments = useLockingDocuments();
    //const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    useEffect(() => {
        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: IdTableVariant.ImportRemains,
                idDocument: dataImportRemains?.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    function setImportRemainsAndItemsImportRemains(id: string, callback?: (IImportRemainsUpdateDTO) => void) {
        importRemainsDataProvider.getById(id as string, async (importRemainsDocument) => {
            getImportRemainsItemUpdateNew(
                importRemainsDocument.idGlobal as string,
                (e) => {
                    const itemsUpdate: IImportRemainsItemEditDTO[] = [];
                    e.forEach((item) => {
                        itemsUpdate.push({
                            idGlobal: item.idGlobal,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            IdCertificateExternalGlobal: item.IdCertificateExternalGlobal,
                            idSupplierGlobal: item.idSupplierGlobal,
                            supplierCostInfo: item.supplierCostInfo,
                            retailCostInfo: item.retailCostInfo,
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            kizCount: item.kizCount,
                            kizBoxCount: item.kizBoxCount,
                            kizs: item.kizs,
                            kizBoxes: item.kizBoxes,
                            isGnvls: item.isGnvls,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            supplierDocNumber: item.supplierDocNumber,
                            supplierDocDate: item.supplierDocDate,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            dateCreated: item.dateCreated
                        });
                    });

                    const documentUpdate: IImportRemainsUpdateDTO = {
                        idGlobal: importRemainsDocument.idGlobal,
                        documentDate: importRemainsDocument.documentDate,
                        idStoreGlobal: importRemainsDocument.store.idGlobal,
                        idPricingModelGlobal: importRemainsDocument.pricingModel.idGlobal,
                        payerName: importRemainsDocument.payerName,
                        datePay: importRemainsDocument.datePay,
                        items: itemsUpdate,
                    };

                    setDataImportRemainsState(importRemainsDocument.documentState);
                    callback?.(documentUpdate);
                }
            );
        });
    }

    function getNewImportRemainsItem(item: IImportRemainsItemCreateDTO, newKizs: IKizDTO[], newKizBoxs: IKizBoxDTO[]) {
        let newImportRemainsItem = {
            idGoodsGlobal: item.idGoodsGlobal,
            idScalingRatioGlobal: item.idScalingRatioGlobal,
            idSeriesGlobal: item.idSeriesGlobal,
            producerPrice: item.producerPrice,
            productMargin: item.productMargin,
            supplierCostInfo: item.supplierCostInfo,
            retailCostInfo: item.retailCostInfo,
            supplierGoodsCode: item.supplierGoodsCode,
            gtdNumber: item.gtdNumber,
            barCode: item.barCode,
            registerPrice: item.registerPrice,
            kizCount: item.kizCount,
            kizBoxCount: item.kizBoxCount,
            kizs: newKizs,
            kizBoxes: newKizBoxs,
            quantity: item.quantity,
            idGlobal: item.idGlobal,
            isKiz: item.isKiz,
            isKizBox: item.isKizBox,
        } as IImportRemainsItemCreateDTO;
        return newImportRemainsItem;
    }

    interface IDataMoves {
        dto: IKizStatusMoveErrorDTO;
        kiz: IKizDTO | IKizBoxDTO;
    }

    function actionAfterGetMoves(data: IDataMoves[], item: IImportRemainsItemCreateDTO, idDocument: string)
    {
        let newKizs: IKizDTO[] = [];
        let newKizBoxs: IKizBoxDTO[] = [];

        data.forEach((e) => {
            if (e.dto.kizType === BarcodeType.Kiz) {
                let kiz: IKizDTO = getNewKiz(e.kiz as IKizDTO, e.dto.kizData.idError, (e.kiz as IKizDTO).kizState);
                newKizs.push(kiz);
            }
            if (e.dto.kizType === BarcodeType.KizBox) {
                let kizBox: IKizBoxDTO = getNewKizBox(e.kiz as IKizBoxDTO, e.dto.kizBoxData.idError);
                newKizBoxs.push(kizBox);
            }
        });

        let newImportRemainsItem = getNewImportRemainsItem(item, newKizs, newKizBoxs);

        setDataImportRemainsItem(newImportRemainsItem);

        let items: IImportRemainsItemCreateDTO[] = [];
        items.push(newImportRemainsItem);
        setDataImportRemainsItems(items);

        scalingRatioDP.overrideGetById(item.idGoodsGlobal, item.idScalingRatioGlobal as string, (e) => {
            setSelectedScalingRation(e);
            lockingDocuments.check(idDocument, (e) => {
                if (!e) {
                    setShowKizScan(true);
                    lockingDocuments.send({
                        idTable: IdTableVariant.ImportRemains,
                        idDocument: idDocument,
                    }, (e)=> {
                    });
                }
            })
        });
    }

    function setShowKizs(item: IImportRemainsItemCreateDTO, idDocument: string) {

        let barcodes: string[] = [];
        item.kizs?.forEach((x) => {
            barcodes.push(x.barcode);
        });

        item.kizBoxes?.forEach((x) => {
            barcodes.push(x.barcode);
        });
        const dto = {
            barcodes: barcodes,
            idDocument: idDocument,
        } as IKizStatusMoveErrorInDTO;
        kdp.getKizStatusMoveErrors(dto, (e) => {
            let data: IDataMoves[] = [];
            e.forEach(x => {
                const element = {
                    dto: x,
                    kiz: x.kizType === BarcodeType.Kiz ? item.kizs?.find(y => y.idGlobal === x.kizData.idKizGlobal) 
                        : item.kizBoxes?.find(y => y.idGlobal === x.kizBoxData.idKizBoxGlobal),
                } as IDataMoves
                data.push(element);
            })
            actionAfterGetMoves(data, item, idDocument);
        },() => {});
    }

    function getResultImportRemains(value: IImportRemainsItemCreateDTO): IImportRemainsUpdateDTO | undefined {
        if (dataImportRemains) {
            let filteredItems = dataImportRemains.items.filter((x) => x.idGlobal !== value.idGlobal);

            filteredItems.push(value);

            const document: IImportRemainsUpdateDTO = {
                idGlobal: dataImportRemains.idGlobal,
                documentDate: dataImportRemains.documentDate,
                idStoreGlobal: dataImportRemains.idStoreGlobal,
                idPricingModelGlobal: dataImportRemains.idPricingModelGlobal,
                payerName: dataImportRemains.payerName,
                datePay: dataImportRemains.datePay,
                items: filteredItems,
            };
            return document;
        }
        return undefined;
    }

    function setImportRemainsAndItemsImportRemainsAction(selectedItem) {
        setImportRemainsAndItemsImportRemains(pluginCtx.masterGrid.selectedItem?.idGlobal as string, (documentUpdate) => {
            setDataImportRemains(documentUpdate);
            let selectedItemEditDto = documentUpdate?.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IImportRemainsItemEditDTO;

            if (!selectedItemEditDto) return;
            let selectedItemDto = selectedItemEditDto as IImportRemainsItemCreateDTO;

            if (selectedItemDto) {
                setShowKizs(selectedItemDto, documentUpdate.idGlobal);
            } else {
                setSelectedScalingRation(null);
            }
        });
    }

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem && pluginCtx.masterGrid.document === DocumentType.importRemains) {
            importRemainsDataProvider.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                setData(entities)
                setTotalCount(totalCount)
            })
        }

    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])

    function saveEditKizs(value)
    {
        let doc = getResultImportRemains(value);
        if (doc) {
            importRemainsDataProvider.updateEditKizs(doc.idGlobal as string, value.idGlobal, doc, () => {
                pluginCtx.masterGrid.refreshState(true)
            });
            lockingDocuments.delete({
                idTable: IdTableVariant.ImportRemains,
                idDocument: doc.idGlobal as string,
            });
        }
        setShowKizScan(false);
    }

    return (
        <PluginWrapper>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                plugin={props.plugin}
                dataProvider={importRemainsDataProvider}
                getView={(gridFilter,callback) => {
                    importRemainsDataProvider.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                        callback(entities)
                        setTotalCount(totalCount)
                    })
                }}
                openKizEdit={{
                    open: true,
                    action: (row: IGridRow) => {
                        if (row) {
                            setSelectedScalingRation(null);
                            setSelectedItem(row);
                            setImportRemainsAndItemsImportRemainsAction(row);
                        }
                    },
                }}
                hiddenPagination={undefined}
                selectedItem={selectedItem}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onSelect={(row) => setSelectedItem(row)}
                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
            />

            {showMessageModal.show && (
                <MessageModalWindow
                    message={showMessageModal.message}
                    ok={{
                        onClick: () => {
                            let value = showMessageModal.value;
                            if (value)
                            {
                                saveEditKizs(value);
                            }
                            setShowMessageModal({ show: false, message: "", value: undefined });
                        },
                    }}
                    cancel={{ onClick: () => setShowMessageModal({ show: false, message: "", value: undefined }) }}
                />
            )}

            {showKizScan && selectedScalingRation && dataImportRemains && dataImportRemainsState !== "del" && (
                <KizScanModal
                    ok={(value: IImportRemainsItemCreateDTO) => {
                        let mas: IImportRemainsItemCreateDTO[] = [];
                        mas.push(value);
                        if (
                            kizCounter(mas, () => {
                                setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, value: value })
                            }) === true
                        ) {
                            saveEditKizs(value);
                        }
                    }}
                    cancel={() => {
                        setShowKizScan(false);
                        lockingDocuments.delete({
                            idTable: IdTableVariant.ImportRemains,
                            idDocument: dataImportRemains.idGlobal as string,
                        });
                    }}
                    selectedItem={dataImportRemainsItem as IImportRemainsItemCreateDTO}
                    documentType={DocumentType.importRemains}
                    document={{ idTable: IdTableVariant.ImportRemains, idDocument: dataImportRemains.idGlobal as string }}
                    idLotFrom={null}
                    numerator={selectedScalingRation.numerator}
                    isEditKizs={true}
                    canScanKizBox={dataImportRemainsItem?.isKizBox}
                    canScanKiz={!dataImportRemainsItem?.isKizBox}
                    onValidateDuplicate={(barcode) => validateDuplicateKizs(dataImportRemainsItems, barcode)}
                />
            )}

        </PluginWrapper>
    )
}

export default ImportRemainsItemGrid