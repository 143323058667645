import { v4 as uuidv4, NIL as uuidNull } from 'uuid';
import { IDocumentCreatorFormProps } from '../../../../@types/documents';
import BaseDocumentCreator, { IPrintButton } from '../../../../components/documents/baseDocumentCreator';
import useGridFilter, { DefaultGridFilter } from '../../../../system/hooks/useGridFilter';
import styles from '../styles/KizInventoryCreator.module.scss';
import GridWrapper from '../../../../components/controls/GridWrapper';
import { DateInput, NumberInput, TextAreaInput, TextInput } from '../../../../components/controls/inputs';
import { StoreSelectorPanel } from '../../../../components/storeSelectorPanel';
import { Accordion } from '../../../../components/controls/accordion';
import { CheckBox } from '../../../../components/controls/checkbox';
import { ChangeableGrid } from '../../../../components/grids/changeableGrid/ChangeableGrid';
import { FC, useEffect, useReducer, useState } from 'react';
import { IKizInventoryEditDTO, IKizInventoryGetDTO } from '../../../../libs/coreapi-dto/accounting/kizInventory/kizInventory';
import GridMultipleSelectorInput from '../../../../components/controls/GridMultipleSelectorInput';
import { KizInventoryCreatorReducer, KizInventoryCreatorReducerHandler } from '../state/KizInventoryCreatorReducer';
import BaseCommandsPanel from '../../../../components/commandsPanels/BaseCommandsPanel';
import Tooltip from '../../../../components/tooltips/Tooltip';
import { ClearButton, Delete2Button } from '../../../../components/buttons/iconButtons/action/MainCollection';
import { useKizInventoryCreatorValidator } from './KizInventoryCreatorValidator';
import { KizInventoryItemPluginSettings } from '..';
import { IKeyValuePair } from '../../../Reports/Shared/Interfaces/IKeyValuePair';
import { DocumentStatusType, IdTableVariant, SysOptionName } from '../../../../@types/enumsGlobal';
import { useUserContext } from '../../../../system/providers/userContextProvider';
import { checkLockStatus } from '../../../CommonHelperFunctions';
import { onStoreChanged } from '../../../../system/functions/onStoreChanged';
import { onPharmacyChanged } from '../../../../system/functions/onPharmacyChanged'
import moment from 'moment';
import { DateTime } from 'luxon';
import { RotationRightButton } from '../../../../components/buttons/iconButtons/action/RotationCollection';
import { KizInventoryDataProvider } from '../../../../Services/DataProviders/KizInventoryDataProvider';
import { useAppContext } from '../../../../system/providers/appContextProvider';
import renderGlobalAlert from '../../../../system/hooks/useGlobalAlert';
import { useTranslation } from 'react-i18next';
import FlexRow from '../../../../components/controls/FlexRow';
import { DefaultGrid } from '../../../../components/grids/default/defaultGrid';
import { IKizInventoryItemViewDTO } from '../../../../libs/coreapi-dto/accounting/kizInventory/kizInventoryItem';
import { SysOptionsDataProvider } from '../../../../Services/DataProviders/SysOptionsDataProvider';
import { ISysOptionsDTO } from '../../../../libs/coreapi-dto/service/sysOptions';
import { FieldsSourceTypes } from '../../../Service/SysOptions/components/editModalWindow/SourceTypeEditModal';

export const KizInventoryCreator: FC<IDocumentCreatorFormProps<IKizInventoryGetDTO, IKizInventoryEditDTO>> = (props) => {
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [gridFilterItems, dispatchGridFilterItems] = useGridFilter();
    const [totalCount, setTotalCount] = useState(0);
    const [kizInventoryItems, setKizInventoryItems] = useState<IKizInventoryItemViewDTO[]>([]);
    // Состояние модального окна
    const [modalWindow, setModalWindow] = useState(<></>);

    const [state, dispatch] = useReducer(KizInventoryCreatorReducerHandler, KizInventoryCreatorReducer);

    const validator = useKizInventoryCreatorValidator(state.viewData);
    const appContext = useAppContext();
    const userContext = useUserContext();

    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const documentStateProc = props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission);

    const kizInventoryDP = new KizInventoryDataProvider(appContext.coreApiService);
    const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)
    const [sysOptSourceTypeText, setSysOptSourceTypeText] = useState<string>("");

    // Локализация
    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.kizInventory." + value);
    
    /*const printHandler: IPrintButton = { 
        onClick: () => { return IdTableVariant.InventorySved; }, 
        onSubItemClick: () => {
            let arr = new Array<IKeyValuePair>();
            if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined)
                arr.push({ key: props.idGlobal, value: props.document.mnemocode });
            return arr;
        },
    };*/

    useEffect(() => {
        if ((props.variant === 'edit' || 'copy') && props.document) {
            dispatch({ type: "autoCompleteFirstRenderEditCopy", payload: props.document })

        } else if (props.variant === 'create' && props.idGlobal) {
            dispatch({ type: 'autoCompleteFirstRenderCreate', payload: props.idGlobal })
        }
        sysOptionsDataProvider.getByName(SysOptionName.SourceTypeKizInventory, (data) => {
            if (data) {
                const obj = JSON.parse(data.jsonData); 
                let objValue = obj.Value;
                let active = objValue[FieldsSourceTypes.IsActive];
                let types = objValue[FieldsSourceTypes.SourceTypes];
                let text = '';
                if (!active || !types || types.length === 0)
                    text = baseT("sysOptSourceTypeAll");
                else {
                    text = types.toString();
                    text = text.split(',').join(', ');
                }
                setSysOptSourceTypeText(text);
            }
        })
    }, []);

    useEffect(() => {
        if ((props.variant === 'edit' || 'copy') && props.document) {
            kizInventoryDP.getItemsView(props.document?.idGlobal, gridFilterItems, (values, count) => {
                setKizInventoryItems(values)
                setTotalCount(count);
            })
            //setKizInventoryItems(state.viewData.items);
            //setTotalCount(state.viewData.items.length);
        }
    }, [gridFilterItems]);

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (validator.isValid()) {
                            props.ok?.(state.createData);
                        }
                    },
                    disabled: documentStateProc || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                save={{
                    onClick: () => {
                        if (validator.isValid()) {
                            props.save?.(state.createData);
                        }
                    },
                    disabled: documentStateProc || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting
                }}
                cancel={{
                    onClick: () => {
                        props.cancel();
                    },
                }}
                //print={printHandler}
            >
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <div>
                            <FlexRow>
                                <TextInput
                                    key="sysOptSourceType"
                                    label={baseT("sysOptSourceType")}
                                    value={sysOptSourceTypeText}
                                    disabled
                                />
                                <TextInput
                                    key="mnemocode"
                                    label={baseT("number")}
                                    value={ props.variant === "edit" ? props.document?.mnemocode : "" }
                                    disabled
                                />
                                <DateInput
                                    required
                                    disabled={documentStateProc}
                                    label={baseT("documentDate")}
                                    value={ state.viewData.documentDate /*props.variant === 'create' ? DateTime.now().toF3DateTime(userContext).toUTC() : props.document?.documentDate*/ }
                                    error = {validator.errors.documentDate}
                                    onBlur={(value) => {
                                        if (value) {
                                            let parsedDate = new Date(value.toString());
                                            let valid = parsedDate.toString() !== "Invalid Date";
                                            if (valid)
                                            {
                                                const currentHour = DateTime.now().toUTC().hour;
                                                const currentMinute = DateTime.now().minute;
                                                const currentDate = value.toFormat('dd.MM.yyyy');
                                                const dt = DateTime.fromFormat(`${currentDate} ${currentHour}:${currentMinute}`, "dd.MM.yyyy h:m");
                                                dispatch({ type: "setDocumentDate", payload: dt });
                                            } else
                                            {
                                                dispatch({ type: "setDocumentDate", payload: DateTime.now().toUTC() });
                                            }
                                        }
                                    }}
                                />
                                <TextInput
                                    key="documentState"
                                    label={baseT("documentState")}
                                    value={state.viewData.documentState}
                                    disabled
                                />
                                <NumberInput
                                    className={styles.accountingLag}
                                    value={state.viewData.accountingLag} 
                                    onChange={(value) => {
                                        dispatch({ type: "setAccountingLag", payload: value })
                                    }} 
                                    label={baseT("accountingLag")}
                                    inputId={"accountingLag"} 
                                    disabled={documentStateProc}
                                />
                            </FlexRow>
                            <GridWrapper cols={2}>
                                <StoreSelectorPanel
                                    we={props.document?.store ? false : true}
                                    storeFromLot={props.document?.store}
                                    compactMode={true}
                                    contractor={{
                                        label: baseT("pharmacy"),
                                        disabled: documentStateProc,
                                        onSelect: (value) => {
                                            dispatch({ type: "setContractor", payload: value })
                                            dispatchGridFilter(onPharmacyChanged(value.idGlobal));
                                        },
                                        onClear: () => {
                                            dispatch({ type: "clearContractor" })
                                            dispatchGridFilter(onPharmacyChanged());
                                        },
                                        error: validator.errors.idContractorGlobal,
                                        onFocus: () => validator.setErrors({ ...validator.errors, idContractorGlobal: undefined }),
                                        treeViewCheckDirectoryType:'pharmacy'
                                    }}
                                    store={{
                                        label: baseT("store"),
                                        value: state.viewData.store,
                                        onSelect: (value) => { 
                                            dispatch({ type: "setStore", payload: value })
                                            dispatchGridFilter(onStoreChanged(value.idGlobal));
                                        },
                                        onClear: () => {
                                            dispatch({ type: "clearStore" })
                                            dispatchGridFilter(onStoreChanged());
                                        },
                                        disabled: documentStateProc,
                                    }} />
                            </GridWrapper>
                            <Accordion opened={false} caption={baseT("comment")} id={`comment-${uuidv4()}`}>
                                <GridWrapper cols={1}>
                                    <TextAreaInput
                                        label={baseT("comment")}
                                        value={state.viewData.comment}
                                        onChange={(value) => dispatch({ type: "setComment", payload: value })}
                                        disabled={documentStateProc ? true : false}
                                    />
                                </GridWrapper>
                            </Accordion>
                            <GridWrapper cols={0} className={styles.addIventPanel}>
                                <BaseCommandsPanel groups={[
                                    [
                                        {
                                            iconButton: <Tooltip title={t("general.refresh")}>
                                                <RotationRightButton sizeVariant="mini" colorVariant="info"
                                                    onClick={() => {
                                                        if (state.viewData.items.length > 0) {
                                                            //updateItems();
                                                        }
                                                    }}
                                                    disabled //= {state.viewData.items.length > 0 && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        },
                                        {
                                            iconButton: <Tooltip colorVariant={'danger'} title={t("general.delete")/*'Удалить'*/}>
                                                <Delete2Button sizeVariant="mini" colorVariant="danger"
                                                    onClick={() => {
                                                        if (state.viewData.selectedItem && state.viewData.selectedItem?.idGlobal) {
                                                        }
                                                    }}
                                                    disabled//={state.viewData.selectedItem && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        },
                                        {
                                            iconButton: <Tooltip colorVariant={'danger'} title={t("general.clear")}>
                                                <ClearButton sizeVariant="mini" colorVariant="danger"
                                                    onClick={() => {
                                                        if (state.viewData.items.length > 0) {
                                                        }
                                                    }}
                                                    disabled//={state.viewData.items.length > 0 && !documentStateProc ? false : true}
                                                />
                                            </Tooltip>
                                        }
                                    ]
                                ]} />

                            </GridWrapper>
                            <DefaultGrid
                                //documentStateProc={documentStateProc}
                                gridId={uuidv4()}
                                data={kizInventoryItems}
                                filter={gridFilterItems}
                                totalCount={totalCount}
                                plugin={KizInventoryItemPluginSettings}
                                hiddenPagination={{ hiddenCountRow: true, hiddenNumberPage: true }}
                                onSelect={(row) => {
                                    row ? dispatch({ type: "setSelectedItem", payload: row }) : dispatch({ type: "setSelectedItem", payload: undefined })
                                }}
                                selectedItem={state.viewData.selectedItem}
                                onSort={(i) => dispatchGridFilterItems({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchGridFilterItems({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchGridFilterItems({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilterItems({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            />
                        </div>                

                    </div>
                </div>
            </BaseDocumentCreator >
            {modalWindow}
        </>
    );
}