import { useState } from "react";
import { useTranslation } from "react-i18next"
import { TextInput } from "../../../../components/controls/inputs";
import { IOption, Select } from "../../../../components/selects/select";
import { Checkbox } from '../../../../components/checkboxes/checkbox';
import { IKizExchangeColumnFilter, IKizParamFilter, TypeSearchField } from "../KizExchangeGrid";
import styles from './styles/KizExchangeFilteringPanel.module.scss'
import { Delete2Button, SearchButton } from "../../../../components/buttons/iconButtons/action/MainCollection";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { RotationRightButton } from "../../../../components/buttons/iconButtons/action/RotationCollection";
import { GridFilterAction } from "../../../../system/hooks/useGridFilter";

interface IPanelEvent {
    disabled?: boolean
    onClick: () => void
}

interface Props {
    searchInput: string | undefined
    setSearchInput: (value: string) => void
    kizColumnFilter: IKizExchangeColumnFilter
    setKizColumnFilter: (value: IKizExchangeColumnFilter, update: boolean) => void
    kizParamFilter: IKizParamFilter
    setKizParamFilter: (value: IKizParamFilter) => void
    onFilterByChange: (value: IOption | undefined) => void
    autoFilter: boolean
    onAutoFilterChange: (value: boolean) => void
    filterBy: IOption | undefined
    withinRange: boolean
    onWithinRange: (value: boolean) => void
    searchOption?: IPanelEvent
    clearFilters?: IPanelEvent
    onWithinRangeFunc: (value: boolean) => void
    dispatchGridFilter: (value: GridFilterAction) => void
}

export const KizExchangeFilteringPanel: React.FC<Props> = (props) => {

    const { t } = useTranslation();
    const baseT = (value: string) => t('accounting.KizExchange.filteringPanel.filterBy.' + value);

    const [searchInput, setSearchInput] = useState<string | null>("");

    const options = [
        { displayName: baseT('goodsName'), value: "goodsName" },
        { displayName: baseT('barcode'), value: "barcode" },
        { displayName: baseT('internalBarcode'), value: "internalBarcode" },
        { displayName: baseT('sscc'), value: "sscc" },
        { displayName: baseT('gtinSGtin'), value: "gtinSGtin" },
        { displayName: baseT('documentNumber'), value: "documentNumber" },
        { displayName: baseT('code'), value: "code" },
        { displayName: baseT('decodedBarcode'), value: "decodedBarcode" }
    ] as IOption[]

    const setKizFieldColumns = (autoFilter: boolean, kizNameValue: string | null, typeSearchFieldValue: TypeSearchField, update: boolean) => {
        if (autoFilter !== true) {
            if (typeSearchFieldValue === 'sscc') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, sscc: kizNameValue !== null && kizNameValue.length > 2 && kizNameValue.length <= 20 ? kizNameValue : null, barcode: kizNameValue !== null && kizNameValue.length > 20 ? kizNameValue : null, internalBarcode: null, goodsName: null, documentNumber: null, code: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'gtinSGtin') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, gtinSGtin: kizNameValue !== null && kizNameValue.length > 2 ? kizNameValue : null, barcode: null, internalBarcode: null, goodsName: null, documentNumber: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'barcode') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, barcode: kizNameValue !== null && kizNameValue.length > 2 && kizNameValue.length > 20 ? kizNameValue : null, internalBarcode: null, goodsName: null, documentNumber: null, gtinSGtin: null, code: null, sscc: kizNameValue !== null && kizNameValue.length > 2 && kizNameValue.length <= 20 ? kizNameValue : null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'internalBarcode') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, internalBarcode: kizNameValue !== null && kizNameValue.length > 2 ? kizNameValue : null, barcode: null, goodsName: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'goodsName') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, goodsName: kizNameValue !== null && kizNameValue.length > 2 ? kizNameValue : null, barcode: null, internalBarcode: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'documentNumber') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, documentNumber: kizNameValue !== null && kizNameValue.length > 2 ? kizNameValue : null, barcode: null, internalBarcode: null, goodsName: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'code') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, code: kizNameValue !== null && kizNameValue.length > 2 ? kizNameValue : null, barcode: null, internalBarcode: null, goodsName: null, gtinSGtin: null, documentNumber: null, sscc: null, decodedBarcode: null }, update)
            } else if (typeSearchFieldValue === 'decodedBarcode') {
                props.setKizColumnFilter({ ...props.kizColumnFilter, decodedBarcode: kizNameValue !== null && kizNameValue.length >= 31 ? kizNameValue : null, code: null, barcode: null, internalBarcode: null, goodsName: null, gtinSGtin: null, documentNumber: null, sscc: null }, update)
            }
        }
        else {
            if (kizNameValue !== null && (kizNameValue.length === 20 || kizNameValue.length === 18)) {//sscc
                props.onFilterByChange(options[3])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[3].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, sscc: kizNameValue, barcode: null, internalBarcode: null, goodsName: null, documentNumber: null, code: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length === 27) {//gtinSGtin
                props.onFilterByChange(options[4])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[4].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, gtinSGtin: kizNameValue, barcode: null, internalBarcode: null, goodsName: null, documentNumber: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && (kizNameValue.length === 31 || kizNameValue.length === 85)) {//decodedBarcode
                props.onFilterByChange(options[7])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[7].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, decodedBarcode: kizNameValue, goodsName: null, barcode: null, internalBarcode: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length > 20) {//barcode
                props.onFilterByChange(options[1])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[1].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, sscc: null, barcode: kizNameValue !== null && kizNameValue.length > 20 ? kizNameValue : null, internalBarcode: null, goodsName: null, documentNumber: null, code: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length === 13 && kizNameValue.slice(0, 2) === '20') {//internalBarcode
                props.onFilterByChange(options[2])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[2].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, internalBarcode: kizNameValue, barcode: null, goodsName: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length === 16) {//documentNumber
                props.onFilterByChange(options[5])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[5].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, documentNumber: kizNameValue, barcode: null, internalBarcode: null, goodsName: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length === 13) {//code
                props.onFilterByChange(options[6])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[6].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, code: kizNameValue, barcode: null, internalBarcode: null, goodsName: null, gtinSGtin: null, documentNumber: null, sscc: null, decodedBarcode: null }, update)
            } else if (kizNameValue !== null && kizNameValue.length > 2) {//goodsName
                props.onFilterByChange(options[0])
                props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: options[0].value })
                props.setKizColumnFilter({ ...props.kizColumnFilter, goodsName: kizNameValue, barcode: null, internalBarcode: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            }
            else if (kizNameValue !== null && kizNameValue.length === 0) {
                props.setKizColumnFilter({ ...props.kizColumnFilter, goodsName: kizNameValue, barcode: null, internalBarcode: null, documentNumber: null, gtinSGtin: null, code: null, sscc: null, decodedBarcode: null }, update)
            }
        }
    }

    return (
        <>
            <div className={styles.kizFilteringPanel} >
                <TextInput 
                    className={styles.search} 
                    inputClassName={styles.inlineInput}
                    disabled={false}
                    label={t('accounting.KizExchange.filteringPanel.search')}
                    inputId={"inputKizExchangeSearch"}
                    inline={true}
                    value={props.searchInput}
                    onChange={(value)=>{
                        setSearchInput(value);
                        props.setSearchInput(value);
                        setKizFieldColumns(props.autoFilter, value, props.kizParamFilter.typeSearchField, false)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setKizFieldColumns(props.autoFilter, searchInput, props.kizParamFilter.typeSearchField, true)
                        }
                    }}
                />
                <Tooltip title={t('accounting.KizExchange.filteringPanel.search')}>
                    <SearchButton 
                        sizeVariant="mini" 
                        {...props.searchOption} 
                    />
                </Tooltip>
                <Tooltip title={t('accounting.KizExchange.filteringPanel.deleteNote')}>
                    <Delete2Button 
                        sizeVariant="mini" 
                        colorVariant="danger" 
                        {...props.clearFilters} 
                    />
                </Tooltip>
                <Tooltip title={t('accounting.KizExchange.filteringPanel.refresh')}>
                    <RotationRightButton 
                        sizeVariant="mini" 
                        onClick={() => {
                            props.dispatchGridFilter({type: 'refresh'});
                        }}
                    />
                </Tooltip>
                <Select
                    className={styles.filterBy}
                    defaultOption={{ displayName: baseT('barcode'), value: "barcode" }}
                    onSelect={(option) => {
                        props.onFilterByChange(option)
                        props.setKizParamFilter({ ...props.kizParamFilter, typeSearchField: option.value })
                        setKizFieldColumns(props.autoFilter, props.searchInput as string, option.value, true)
                    }}
                    value={props.filterBy}
                    options={options}
                    disabled={props.autoFilter}
                >
                </Select>
                <div className={styles.autoFilter}>
                    <Checkbox
                        name='autoFilter'
                        label='Автофильтр'
                        checked={props.autoFilter}
                        onChange={(checked) => {
                            props.onAutoFilterChange?.(checked)
                            props.setKizParamFilter({ ...props.kizParamFilter, autofilter: checked })
                            setKizFieldColumns(checked, props.searchInput as string, props.kizParamFilter.typeSearchField, true)
                        }}
                        disabled={false} 
                    />
                </div>
                <div className={styles.withinRange}>
                    <Checkbox 
                        name='withinRange' 
                        label='По вхождению' 
                        onChange={(checked) => {
                            props.onWithinRange?.(checked);
                            props.onWithinRangeFunc(checked);
                        }}
                        disabled={false} 
                        defaultChecked={props.withinRange}
                    />
                </div>
            </div>
        </>
    )
};