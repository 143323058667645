import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "../../../components/controls/checkbox";
import GridWrapper from "../../../components/controls/GridWrapper";
import { TextInput } from "../../../components/controls/inputs";
import BaseCreator from "../../../components/creators/baseCreator";
import { IStoreTypeDTO } from "../../../libs/coreapi-dto/dirs/storeType";
import styles from '../../../components/modalWindows/styles/BaseModalWindow.module.scss' 
import { useForm } from "../../../system/hooks/useForm";
interface IValidator{
    name: string
    mnemocode: string
}

const StoreTypeCreatorModal: FC<ICreatorModalProps<IStoreTypeDTO>> = (props) => {

    const [storeType, setStoreType] = useState<IStoreTypeDTO>(props.data ?? {} as IStoreTypeDTO);

    const { t } = useTranslation();
    const baseT = (value: string) => t('directory.typeofStores.' + value)
    const errorsT = (value: string) => t('errors.' + value);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            name: {
                required: {
                    value: storeType.name  ? false : true,
                    message: errorsT("required")
                }
            },
            mnemocode: {
                required: {
                    value: storeType.mnemocode ? false : true,
                    message: errorsT("required")
                }
            }
        }
    });

    const handleSubmit = () => {
        if(isValid()){
            props.save({ ...storeType })
            setIsSubmitting(true)
        }    
    }

    return (
        <BaseCreator
            variant={props.variant}
            isSubmitting={isSubmitting}
            save={handleSubmit}
            cancel={props.cancel}
            valid={!props.lockFromPermission}
            modalWindowClassName={styles.flexModalWindow}
        >
            <GridWrapper cols={2}>
                <TextInput
                    required
                    label={baseT('name')} 
                    value={storeType.name}
                    onChange={(value) => setStoreType({ ...storeType, name: value })} 
                    error={errors.name} onFocus={() => setErrors({ ...errors,name: undefined })}
                    disabled={props.lockFromPermission}    
                />
                <TextInput
                    required
                    label={baseT('mnemocode')} 
                    value={storeType.mnemocode}
                    onChange={(value) => setStoreType({ ...storeType, mnemocode: value })}
                    error={errors.mnemocode} onFocus={() => setErrors({ ...errors,mnemocode: undefined })} 
                    disabled={props.lockFromPermission}       
                />
                <CheckBox
                    label={baseT('requireInputGoscontractOnInvoice')} id='requireInputGoscontractOnInvoice'
                    defaultChecked={storeType.requireInputGoscontractOnInvoice}
                    onChanged={() => setStoreType({ ...storeType, requireInputGoscontractOnInvoice: !storeType?.requireInputGoscontractOnInvoice })}
                    disabled={props.lockFromPermission}   
                />
            </GridWrapper>
        </BaseCreator>
    )
}

export default StoreTypeCreatorModal