import { getDocumentColumns, getDocumentCostInfoColumns } from "../../../system/providers/plugin/defaultPluginSettings"
import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings"
import { ScanBoxCreatePluginSettings } from "../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings"
import MovementItemsGridPluginSettings from "./MovementItems/MovementItemsGridPluginSettings"

let index: number = 0;

const MovementGridPluginSettings: IPluginSettings = {
    name: 'Перемещение товара',
    mnemocode: 'movement_plugin',
    permission: 'Movement',
    columns: [
        ...getDocumentColumns(index, (i) => index = i),
        {
            order: index++,
            propertyName: 'storeFromName',
            propertyType: 'string',
            displayName: 'Из склада',
            visibility: true,
            width: 100
        }, {
            order: index++,
            propertyName: 'storeToName',
            propertyType: 'string',
            displayName: 'В склад',
            visibility: true,
            width: 100
        }, {
            order: index++,
            propertyName: 'userComment',
            propertyType: 'string',
            displayName: 'Комментарий пользователя',
            visibility: false,
            width: 100
        },
        ...getDocumentCostInfoColumns(index, (i) => index = i),
    ],
    detailsPlugins: [
        MovementItemsGridPluginSettings,
        KizViewPluginSettings,
        ScanBoxCreatePluginSettings
    ]

}

export default MovementGridPluginSettings