import { InputNumber } from "antd"
import { DateTime } from "luxon"
import { FC, useState } from "react"
import { Checkbox } from "../../../../components/checkboxes/checkbox"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { DateInput } from "../../../../components/controls/inputs"
import PluginGridSelectorInput from "../../../../components/controls/PluginGridSelectorInput"
import { IDatePeriod } from "../../../../components/datePeriodPicker/datePeriodPicker"
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator"
import { IOption, Select } from "../../../../components/selects/select"
import { DatePeriodPanel } from "../../../../hoc/DatePeriodPanel/DatePeriodPanel"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common"
import { useAppContext } from "../../../../system/providers/appContextProvider"
import { useUserContext } from "../../../../system/providers/userContextProvider"
import styles from "../../styles/ReportViewer.module.scss"
import { ReportFilterDataType } from "../../Shared/Enums/ReportFilterDataType"
import { ReportFilter } from "../../Shared/ReportFilter"
import { ReportFilterGridPluginSettings } from "./ReportFilterGridPluginSettings"
import ReportFilterStyle from './Styles/ReportFilterCreator.module.scss'

interface IReportFilterCreator {
    filters: ReportFilter[]
    asmName: string
    userId: string
    visibleFilters: ReportFilter[]
    cancel: () => void
    process: () => void
}

interface IPdfBlob {
    url: string
    fileName?: string
}

export const ReportFilterCreator: FC<IReportFilterCreator> = (props) => {
    const appCtx = useAppContext()
    const userCtx = useUserContext()
    const [filters, setFilters] = useState<Array<ReportFilter>>(props.filters)
   
    const conditionDate: IOption[] = [{ displayName: 'Больше', value: 0 }, { displayName: 'Меньше', value: 1 }, { displayName: 'Равно', value: 2 },{ displayName: 'Период', value: 3 }] as IOption[]
    const [conditionCombo, setConditionCombo] = useState<IOption>(conditionDate[0])

    function reloadArray() {
        let arr: Array<ReportFilter> = filters as Array<ReportFilter>
        setFilters([...arr])
    }

    function getSelector(filter: ReportFilter, multi: boolean) {
        return (
            <div >
                <PluginGridSelectorInput
                    label={filter.caption}
                    mnemocode={filter.pluginCode as IPlugin}
                    multipleSelect={multi}
                    selectedIds={filter.values}
                    gridPlugin={ReportFilterGridPluginSettings}
                    onClear={() => {
                        filter.values.length = 0
                        reloadArray()
                    }}
                    onSelect={(option: Array<IEntitySimpleDTO>) => {
                        filter.values.length = 0
                        option.forEach(item => {
                            filter.values.push(item.idGlobal)
                        })
                        reloadArray()
                    }}
                />
            </div>
        )
    }

    return (
        <>
            {
                <BaseDocumentCreator
                    ok={{
                        onClick: () => {
                            const encodedReportAssName = encodeURIComponent(props.asmName)
                            //Не слать DefaultValues
                            appCtx.reportAPIService?.reportRequest.then(r => r.saveSettings(userCtx.idGlobal, encodedReportAssName, props.filters))//ToDo siv - градусник и понять что из сеттнгс сохр возможно только для отчётов, а не ПФ
                            props.process()
                        }
                    }}
                    cancel={{ onClick: () => { props.cancel() } }}
                >
                    <div className={styles.wrapper}>
                        <div className={ReportFilterStyle.wrapperFilter}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.SinglePlugin) {
                                        return getSelector(filter, false)
                                    }
                                })
                            }
                        </div>
                        <div className={ReportFilterStyle.wrapperFilter}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.MultiPlugin) {
                                        return getSelector(filter, true)
                                    }
                                })
                            }
                        </div>
                        {
                            props.asmName === 'RCSGoodsKeepingTimeList' ?  
                            <GridWrapper cols={6}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.ComboCondition) {
                                        let arr: Array<IOption> = new Array<IOption>()
                                        filter.defaultValues.forEach(item => {
                                            let condition = conditionDate.find(c => c.displayName === item)
                                            if (condition) { 
                                                const op: IOption = { displayName: condition.displayName, value: condition.value }
                                                arr.push(op)
                                            }                                       
                                        })
                                        let selected: IOption = arr[0]
                                        if (filter.values.length > 0) {
                                            const item: string = filter.values[0]
                                            //Проверить, может такого фильтра уже и нет
                                            if (filter.values.some(s => s === item)) {
                                                selected = { displayName: item, value: item }
                                            }
                                        }
                                        return (
                                            <div>
                                                <div>{filter.caption}</div>
                                                <div>
                                                    <Select
                                                        onSelect={(option) => {
                                                            filter.values.length = 0
                                                            filter.values.push(option.value)
                                                            setConditionCombo(option)
                                                            reloadArray()
                                                        }}
                                                        value={conditionCombo}
                                                        defaultOption={selected}
                                                        options={arr}>
                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    if (filter.dataType === ReportFilterDataType.DateCondition && conditionCombo.value !== 3) {
                                        const date = filter.values.length > 0 ? DateTime.fromISO(filter.values[0]) : null
                                        return (
                                            <div>
                                                <DateInput
                                                    label={filter.caption}
                                                    inputId={filter.name}
                                                    className={styles.field_Date}
                                                    value={date}
                                                    onBlur={(value) => {
                                                        filter.values.length = 0
                                                        if (value != undefined) {
                                                            const formattedDate = value.toISO()
                                                            filter.values.push(formattedDate)
                                                        }
                                                        reloadArray()
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                    if (filter.dataType === ReportFilterDataType.DateConditionPeriod && conditionCombo.value === 3) {
                                        let period: IDatePeriod, minDate: DateTime, maxDate: DateTime
                                        
                                        minDate = DateTime.utc(1753, 1, 1)
                                        maxDate = DateTime.utc(9999, 12, 1)

                                        if (filter.values.length == 2) {
                                            let dateFrom: DateTime, dateTo: DateTime
                                            dateFrom = DateTime.fromISO(filter.values[0])
                                            dateTo = DateTime.fromISO(filter.values[1])
                                            period = { startDate: dateFrom, endDate: dateTo } as IDatePeriod
                                        }
                                        else
                                            period = { startDate: minDate, endDate: maxDate } as IDatePeriod
                                        return (
                                            <div>
                                                <DatePeriodPanel
                                                    label={filter.caption}
                                                    datePeriod={period}
                                                    onDatePeriodChange={(date: IDatePeriod) => {
                                                        filter.values.length = 0
                                                        if (date != undefined) {
                                                            let formattedDateFrom: string
                                                            if (date.startDate != null)
                                                                formattedDateFrom = date.startDate.toISO()
                                                            else
                                                                formattedDateFrom = minDate.toISO()

                                                            let formattedDateTo: string
                                                            if (date.endDate != null)
                                                                formattedDateTo = date.endDate.toISO()
                                                            else
                                                                formattedDateTo = maxDate.toISO()

                                                            filter.values.push(formattedDateFrom)
                                                            filter.values.push(formattedDateTo)
                                                        }
                                                        reloadArray()
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                })

                                
                            }                        
                        </GridWrapper>
                        
                        
                        :<></>
                        }
                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.Boolean) {
                                        let chk: boolean
                                        if (filter.values.length > 0)
                                            chk = JSON.parse(filter.values[0].toLowerCase())
                                        else
                                            chk = false
                                        return (
                                            <Checkbox name={filter.name} label={filter.caption} checked={chk} onChange={(e) => {
                                                filter.values.length = 0
                                                filter.values.push(String(e))
                                                reloadArray()
                                            }} />
                                        )
                                    }
                                })
                            }
                        </GridWrapper>
                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.CheckList) {
                                        return (
                                            <div>
                                                <div>{filter.caption}</div>
                                                {
                                                    filter.defaultValues.map((fi) => {
                                                        const isChecked = filter.values.some(s => s === fi)
                                                        return (
                                                            <div>
                                                                <Checkbox name={fi} label={fi} defaultChecked={isChecked}
                                                                    checked={isChecked}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            if (!isChecked) {
                                                                                filter.values.push(fi)
                                                                                reloadArray()
                                                                            }
                                                                        } else {
                                                                            if (isChecked) {
                                                                                var index = filter.values.indexOf(fi)
                                                                                delete filter.values[index]
                                                                                reloadArray()
                                                                            }
                                                                        }
                                                                    }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>)
                                    }
                                })
                            }
                        </GridWrapper>
                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.DateTime) {
                                        const date = filter.values.length > 0 ? DateTime.fromISO(filter.values[0]) : null
                                        return (
                                            <div>
                                                <DateInput
                                                    label={filter.caption}
                                                    inputId={filter.name}
                                                    className={styles.field_Date}
                                                    value={date}
                                                    onBlur={(value) => {
                                                        filter.values.length = 0
                                                        if (value != undefined) {
                                                            const formattedDate = value.toISO()
                                                            filter.values.push(formattedDate)
                                                        }
                                                        reloadArray()
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </GridWrapper>
                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.Period) {
                                        let period: IDatePeriod, minDate: DateTime, maxDate: DateTime
                                        minDate = DateTime.utc(1753, 1, 1)
                                        maxDate = DateTime.utc(9999, 12, 1)

                                        if (filter.values.length == 2) {
                                            let dateFrom: DateTime, dateTo: DateTime
                                            dateFrom = DateTime.fromISO(filter.values[0])
                                            dateTo = DateTime.fromISO(filter.values[1])
                                            period = { startDate: dateFrom, endDate: dateTo } as IDatePeriod
                                        }
                                        else
                                            period = { startDate: minDate, endDate: maxDate } as IDatePeriod
                                        return (
                                            <div>
                                                <DatePeriodPanel
                                                    label={filter.caption}
                                                    datePeriod={period}
                                                    onDatePeriodChange={(date: IDatePeriod) => {
                                                        filter.values.length = 0
                                                        if (date != undefined) {
                                                            let formattedDateFrom: string
                                                            if (date.startDate != null)
                                                                formattedDateFrom = date.startDate.toISO()
                                                            else
                                                                formattedDateFrom = minDate.toISO()

                                                            let formattedDateTo: string
                                                            if (date.endDate != null)
                                                                formattedDateTo = date.endDate.toISO()
                                                            else
                                                                formattedDateTo = maxDate.toISO()

                                                            filter.values.push(formattedDateFrom)
                                                            filter.values.push(formattedDateTo)
                                                        }
                                                        reloadArray()
                                                    }}
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </GridWrapper>

                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.Integer) {
                                        const val = filter.values.length > 0 ? filter.values[0] : 0
                                        return (
                                            <div>
                                                <div>{filter.caption}</div>
                                                <InputNumber
                                                    value={val}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            filter.values[0] = e.toString()
                                                            reloadArray()
                                                        }
                                                    }} />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </GridWrapper>
                        <GridWrapper cols={5}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.Float) {
                                        const fval = filter.values.length > 0 ? filter.values[0] : "0"
                                        return (
                                            <div>
                                                <div>{filter.caption}</div>
                                                <InputNumber<string>
                                                    value={fval}
                                                    step="0.01"
                                                    onChange={(e) => {
                                                        if (e) {
                                                            filter.values[0] = e.toString()
                                                            reloadArray()
                                                        }
                                                    }}
                                                    stringMode
                                                />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </GridWrapper>
                        <GridWrapper cols={6}>
                            {
                                filters.map((filter) => {
                                    if (filter.dataType === ReportFilterDataType.Combo) {
                                        let arr: Array<IOption> = new Array<IOption>()
                                        filter.defaultValues.forEach(item => {
                                            const op: IOption = { displayName: item, value: item }
                                            arr.push(op)
                                        })
                                        let selected: IOption = arr[0]
                                        if (filter.values.length > 0) {
                                            const item: string = filter.values[0]
                                            //Проверить, может такого фильтра уже и нет
                                            if (filter.values.some(s => s === item)) {
                                                selected = { displayName: item, value: item }
                                            }
                                        }
                                        return (
                                            <div>
                                                <div>{filter.caption}</div>
                                                <div>
                                                    <Select
                                                        onSelect={(option) => {
                                                            filter.values.length = 0
                                                            filter.values.push(option.value)
                                                            reloadArray()
                                                        }}
                                                        defaultOption={selected}
                                                        options={arr}>
                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </GridWrapper>
                    </div>
                </BaseDocumentCreator>
            }
        </>


    )
}