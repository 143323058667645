import { Tooltip } from "antd";
import classNames from "classnames";
import { DocumentState, DocumentStateType, KizState } from "../../@types/enumsGlobal";
import { Delete2Icon } from "../../libs/corporate/icons/outlined/action/MainCollection";
import { RightIcon } from "../../libs/corporate/icons/outlined/directions/ArrowsInCircleCollection";
import { KizBarcodeIcon } from "../../libs/corporate/icons/outlined/kiz/KizBarcodeCollection";
import { Circle, } from "../../libs/corporate/icons/outlined/suggested/CircleCollection";
import { DisketteIcon } from "../../libs/corporate/icons/panels/PanelCollection";
import styles from './styles/StatusIcon.module.scss'
import { RoundDone, RoundPlusMinus } from "../../libs/corporate/icons/outlined/other/MailCollection";

export const returnStatusIcon = (status: DocumentStateType) => {
    switch (status) {
        case 'new': {
            return (
                <Tooltip title={DocumentState.new}>
                    <div className={styles.wrapper}>
                        <Circle className={classNames(styles.common, styles.draft)} />
                    </div>
                </Tooltip>

            )
        }
        case 'draft': {
            return (
                <Tooltip title={DocumentState.draft}>
                    <div className={styles.wrapper}>
                        <DisketteIcon className={classNames(styles.common, styles.draft)} />
                    </div>
                </Tooltip>

            )
        }
        case 'proc': {
            return (
                <Tooltip title={DocumentState.proc} >
                    <div className={styles.wrapper}>
                        <RightIcon className={classNames(styles.common, styles.proc)} />
                    </div>
                </Tooltip>

            )
        }
        case 'done': {
            return (
                <Tooltip title={DocumentState.done} >
                    <div className={styles.wrapper}>
                        <RoundDone className={classNames(styles.common, styles.proc)} />
                    </div>
                </Tooltip>

            )
        }
        case 'del': {
            return (
                <Tooltip title={DocumentState.del}>
                    <div className={styles.wrapper}>
                        <Delete2Icon className={classNames(styles.common, styles.del,)} />
                    </div>
                </Tooltip>

            )
        }
        case 'cancel': {
            return (
                <Tooltip title={DocumentState.cancel}>
                    <div className={styles.wrapper}>
                        <RoundPlusMinus className={classNames(styles.common, styles.del,)} />
                    </div>
                </Tooltip>

            )
        }
        case 'Open': {
            return (
                <Tooltip title={DocumentState.Open}>
                    <div className={styles.wrapper}>
                        <DisketteIcon className={classNames(styles.common, styles.del,)} />
                    </div>
                </Tooltip>

            )
        }
        case 'Closed': {
            return (
                <Tooltip title={DocumentState.Closed}>
                    <div className={styles.wrapper}>
                        <RightIcon className={classNames(styles.common, styles.del,)} />
                    </div>
                </Tooltip>

            )
        }
    }
}
export const returnKizStatusIcon = (status) => {
    switch (status) {
        case 'Save': {
            return (
                <Tooltip title={KizState.Save}>
                    <div className={styles.wrapper}>
                        <Circle className={classNames(styles.common, styles.kizSave)} />
                    </div>
                </Tooltip>

            )
        }
        case 'Proc': {
            return (
                <Tooltip title={KizState.Proc}>
                    <div className={styles.wrapper}>
                        <Circle className={classNames(styles.common, styles.kizProc)} />
                    </div>
                </Tooltip>

            )
        }
        default:
            return (
                <Tooltip title={KizState.Error}>
                    <div className={styles.wrapper}>
                        <Circle className={classNames(styles.common, styles.kizExit)} />
                    </div>
                </Tooltip>

            )
    }
}

export const returnKizEditIcon = (isKiz: boolean, action: () => void) => {
    return (
        <>
            {
                isKiz ? <Tooltip title='Редактировать КИЗ'>
                    <div className={styles.kizWrapper} onClick={(e) => {
                        e.stopPropagation()
                        action()
                    }}>
                        <KizBarcodeIcon className={styles.editKiz} />
                    </div>
                </Tooltip> : <></>
            }
        </>
    )
}