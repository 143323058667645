import { FC, useState, useEffect } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { v4 as uuidv4 } from 'uuid';
import { useTestApiContext } from "../../../../system/providers/testApiProvider";
import { ImportRemainsDataProvider } from "../../../../Services/DataProviders/ImportRemainsDataProvider";
import { IImportRemainsCreateDTO, IImportRemainsGetDTO, IImportRemainsUpdateDTO } from "../../../../libs/coreapi-dto/documents/importRemains";
import { IImportRemainsItemEditDTO, IImportRemainsItemGetDTO } from "../../../../libs/coreapi-dto/documents/importRemainsItem";
import { getImportRemainsItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ImportRemainsItemUpdateRequest";
import ImportRemainsUpdateForm from "./ImportRemainsUpdateForm";
import { DateTime } from "luxon";

interface IEditDocument {
    importRemainsDocument: IImportRemainsGetDTO
    documentItems: IImportRemainsItemGetDTO[]
    documentUpdate: IImportRemainsUpdateDTO
}

interface ICopyDocument {
    importRemainsDocumentCopy: IImportRemainsGetDTO
    documentUpdate: IImportRemainsUpdateDTO
}

const ImportRemainsCreatorView: FC<IDocumentCreatorProps<IImportRemainsCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const testApiContext = useTestApiContext();

    const idp = new ImportRemainsDataProvider(appContext.coreApiService)

    const [viewState, setViewState] = useState<GridStateType>(props.variant)
    const [id, setId] = useState<string | undefined>(props.idGlobal)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);
    const [copyDocument, setCopyDocument] = useState<ICopyDocument | null>(null);

    useEffect(() => {
        if (viewState === 'edit')  {
            idp.getById(id as string, async (importRemainsDocument) => {
                getImportRemainsItemUpdateDTO(importRemainsDocument.idGlobal as string, (e) => {
                    const itemsUpdate: IImportRemainsItemEditDTO[] = []
                    const documentItems: IImportRemainsItemGetDTO[] = []
                    e.forEach(item => {
                        itemsUpdate.push({
                            idGlobal: item.idGlobal,
                            isOsu: item.isOsu,
                            kizOsuDto: item.kizOsuDto,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: {
                                adprice: item.supplierCostInfo.adprice,
                                vatPrice: item.supplierCostInfo.vatPrice,
                                vat: item.supplierCostInfo.vat,
                                sum: item.supplierCostInfo.sum,
                                sumIncVat: item.supplierCostInfo.sumIncVat,
                                vatSum: item.supplierCostInfo.vatSum,
                                price: item.supplierCostInfo.price,
                                priceIncVat: item.supplierCostInfo.priceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailCostInfo.adprice,
                                vatPrice: item.retailCostInfo.vatPrice,
                                vat: item.retailCostInfo.vat,
                                sum: item.retailCostInfo.sum,
                                sumIncVat: item.retailCostInfo.sumIncVat,
                                vatSum: item.retailCostInfo.vatSum,
                                price: item.retailCostInfo.price,
                                priceIncVat:  item.retailCostInfo.priceIncVat 
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizs: item.kizs,
                            kizBoxes: item.kizBoxes,
                            isGnvls: item.isGnvls,       
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            idSupplierGlobal: item.idSupplierGlobal,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated
                        })
                    })
                    importRemainsDocument.items.forEach((item, index) => {
                        documentItems.push({
                            idGlobal: item.idGlobal,
                            goods: item.goods,
                            kizOsuDto: item.kizOsuDto,
                            isOsu: item.isOsu,
                            scalingRatio: item.scalingRatio,
                            series: item.series,
                            quantity: item.quantity,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: {
                                adprice: item.supplierCostInfo.adprice,
                                vatPrice: item.supplierCostInfo.vatPrice,
                                vat: item.supplierCostInfo.vat,
                                sum: item.supplierCostInfo.sum,
                                sumIncVat: item.supplierCostInfo.sumIncVat,
                                vatSum: item.supplierCostInfo.vatSum,
                                price: item.supplierCostInfo.price,
                                priceIncVat: item.supplierCostInfo.priceIncVat
                            },
                            retailCostInfo: {
                                adprice: item.retailCostInfo.adprice,
                                vatPrice: item.retailCostInfo.vatPrice,
                                vat: item.retailCostInfo.vat,
                                sum: item.retailCostInfo.sum,
                                sumIncVat: item.retailCostInfo.sumIncVat,
                                vatSum: item.retailCostInfo.vatSum,
                                price: item.retailCostInfo.price,
                                priceIncVat: item.retailCostInfo.priceIncVat
                            },
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            isKizBox: item.isKizBox,
                            kizBoxCount: item.kizBoxCount,
                            kizCount: item.kizCount,
                            isGnvls: item.isGnvls,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            supplier: item.supplier,   
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: item.dateCreated
                        })
                    })
    
                    const documentUpdate: IImportRemainsUpdateDTO = {
                        idGlobal: importRemainsDocument.idGlobal,
                        documentDate: importRemainsDocument.documentDate.toF3FixedZoneNow(),
                        idStoreGlobal: importRemainsDocument.store.idGlobal,
                        idPricingModelGlobal: importRemainsDocument.pricingModel?.idGlobal,
                        payerName: importRemainsDocument.payerName,
                        datePay: importRemainsDocument.datePay,
                        items: itemsUpdate
                    }
                    
                    setEditDocument({
                        importRemainsDocument: importRemainsDocument, 
                        documentItems: documentItems, 
                        documentUpdate: documentUpdate
                    })
                })
            })
        }

        if (viewState === 'copy')  {
            idp.getById(id as string, (importRemainsDocument) => {
                getImportRemainsItemUpdateDTO(importRemainsDocument.idGlobal as string, (e) => {
                    const itemsUpdate: IImportRemainsItemEditDTO[] = []
                    const itemsImportRemainsDocumentCopy: IImportRemainsItemGetDTO[] = []
                    e.forEach(item => {
                        const idGlobalNew = uuidv4()
                        itemsUpdate.push({
                            idGlobal: idGlobalNew,
                            idGoodsGlobal: item.idGoodsGlobal,
                            idScalingRatioGlobal: item.idScalingRatioGlobal,
                            idSeriesGlobal: item.idSeriesGlobal,
                            quantity: item.quantity,
                            isOsu: item.isOsu,
                            producerPrice: item.producerPrice,
                            productMargin: item.productMargin,
                            supplierCostInfo: item.supplierCostInfo,
                            retailCostInfo: item.retailCostInfo,
                            supplierGoodsCode: item.supplierGoodsCode,
                            gtdNumber: item.gtdNumber,
                            barCode: item.barCode,
                            registerPrice: item.registerPrice,
                            retailPriceIncVat: item.retailPriceIncVat,
                            isKiz: item.isKiz,
                            supplierDocDate: item.supplierDocDate,
                            supplierDocNumber: item.supplierDocNumber,
                            idSupplierGlobal: item.idSupplierGlobal,
                            denominator: item.denominator,
                            numerator: item.numerator,
                            dateCreated: DateTime.now()
                        })
    
                        const itemFound = importRemainsDocument.items.find(x => x.idGlobal === item.idGlobal)
    
                        if (itemFound) {
                            itemsImportRemainsDocumentCopy.push({
                                idGlobal: idGlobalNew,
                                goods: itemFound.goods,
                                scalingRatio: itemFound.scalingRatio,
                                series: itemFound.series,
                                quantity: itemFound.quantity,
                                producerPrice: itemFound.producerPrice,
                                productMargin: itemFound.productMargin,
                                supplierCostInfo: itemFound.supplierCostInfo,
                                retailCostInfo: itemFound.retailCostInfo,
                                supplierGoodsCode: itemFound.supplierGoodsCode,
                                gtdNumber: itemFound.gtdNumber,
                                barCode: itemFound.barCode,
                                isOsu: itemFound.isOsu,
                                registerPrice: itemFound.registerPrice,
                                retailPriceIncVat: item.retailPriceIncVat,
                                isKiz: itemFound.isKiz,
                                isKizBox: item.isKizBox,
                                kizBoxCount: itemFound.kizBoxCount,
                                kizCount: itemFound.kizCount,
                                supplierDocDate: item.supplierDocDate,
                                supplierDocNumber: item.supplierDocNumber,
                                supplier: item.supplier,
                                denominator: item.denominator,
                                numerator: item.numerator,
                                dateCreated: DateTime.now()
                            })
                        }
                    });
    
                    const idGlobalNew = uuidv4()
    
                    const documentUpdate: IImportRemainsUpdateDTO = {
                        idGlobal: idGlobalNew,
                        documentDate: importRemainsDocument.documentDate.toF3FixedZoneNow(),
                        idStoreGlobal: importRemainsDocument.store.idGlobal,
                        idPricingModelGlobal: importRemainsDocument?.pricingModel?.idGlobal,
                        payerName: importRemainsDocument.payerName,
                        datePay: importRemainsDocument.datePay,
                        items: itemsUpdate
                    }
    
                    const importRemainsDocumentCopy: IImportRemainsGetDTO = {
                        idGlobal: idGlobalNew,
                        documentDate: importRemainsDocument.documentDate.toF3FixedZoneNow(),
                        displayName: importRemainsDocument.displayName,
                        documentState: importRemainsDocument.documentState,
                        documentStateModified: importRemainsDocument.documentStateModified,
                        mnemocode: '',
                        store: importRemainsDocument.store,
                        pricingModel: importRemainsDocument.pricingModel,
                        averagePercentSumIncVat: importRemainsDocument.averagePercentSumIncVat,
                        payerName: importRemainsDocument.payerName,
                        datePay: importRemainsDocument.datePay,
                        items: itemsImportRemainsDocumentCopy
                    }     
                    setCopyDocument({
                        importRemainsDocumentCopy: importRemainsDocumentCopy,
                        documentUpdate: documentUpdate
                    })
                })
            })
        }

    }, [viewState])

    return (
        <>
            {
                viewState === "create" ? 
                    <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        isTest={props.isTest}
                        variant="create"
                        save={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id);
                                setViewState('edit');
                                props.createdCallback?.();
                                setIsSubmitting(false);
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(importRemains) => {
                            if (props.isTest) {
                                setIsSubmitting(true)
                                idp.create(importRemains, (id) => {
                                    setId(id)
                                    props.createdCallback?.()
                                    setViewState("create")
                                    tabsContext.close(tabsContext.tabs.find(x => x.id === props.tabId) as ITab)
                                    setIsSubmitting(false)
                                }, (value) => {
                                    testApiContext.setApiInfo(value)
                                }, (e)=>{
                                    setIsSubmitting(false);
                                })
                            } else {
                                setIsSubmitting(true)
                                idp.create(importRemains, (id) => {
                                    setId(id)
                                    props.createdCallback?.()
                                    setViewState("create")
                                    tabsContext.currentTab && tabsContext.closeCurrent()
                                    setIsSubmitting(false)
                                }, ()=>{}, (e)=>{
                                    setIsSubmitting(false);
                                })
                            }
                        }}
                        cancel={() => { tabsContext.closeCurrent() }}
                        permission={props.permission}
                    />
                : 
                viewState === "edit" ? 
                    editDocument && <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="edit"
                        document={editDocument.importRemainsDocument}
                        documentItems={editDocument.documentItems}
                        documentUpdate={editDocument.documentUpdate}
                        save={(importRemainsDocument) => {
                            setIsSubmitting(true)
                            idp.update(id as string, importRemainsDocument, () => {
                                props.createdCallback?.(importRemainsDocument)
                                setIsSubmitting(false)
                            })
                        }}
                        ok={(importRemainsDocument) => {
                            setIsSubmitting(true)
                            idp.update(id as string, importRemainsDocument, () => {
                                props.createdCallback?.(importRemainsDocument)
                                setIsSubmitting(false)
                            })
                            tabsContext.closeCurrent()
                        }}
                        cancel={() => {
                            tabsContext.closeCurrent()
                        }}
                        permission={props.permission}
                    />
                : 
                viewState === "copy" ? 
                    copyDocument && <ImportRemainsUpdateForm
                        isSubmitting={isSubmitting}
                        idGlobal={id}
                        variant="copy"
                        document={copyDocument.importRemainsDocumentCopy}
                        documentItems={copyDocument.importRemainsDocumentCopy.items}
                        documentUpdate={copyDocument.documentUpdate}
                        save={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id)
                                setViewState('edit')
                                props.createdCallback?.()
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(importRemains) => {
                            setIsSubmitting(true)
                            idp.create(importRemains, (id) => {
                                setId(id)
                                props.createdCallback?.()
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        cancel={() => {
                            tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                        }}
                        permission={props.permission}
                    />
                : 
                    <></>
            }
        </>
    );
}

export default ImportRemainsCreatorView