import { FC, useEffect, useState } from "react";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper } from "../../../components/plugins";
import useGridFilter from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { GoodsGroupDataProvider } from "../../../Services/DataProviders/GoodsGroupDataProvider";
import { IGoodsGroupAllViewDTO } from "../../../libs/coreapi-dto/dirs/goodsGroup";


export const GoodsGroupAllGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const goodsGroupADP = new GoodsGroupDataProvider(appCtx.coreApiService);

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [data, setData] = useState<IGoodsGroupAllViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);

    useEffect(() => {
        goodsGroupADP.getViewAll(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
        })
    }, [viewState, gridFilter])

    return (
        <PluginWrapper>
            {
                <DefaultGrid
                    gridId={props.gridId}
                    separator
                    singleDirectory
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    filter={gridFilter}
                    getView={(gridFilter,callback) => {
                        goodsGroupADP.getViewAll(gridFilter, (entities) => {
                            callback(entities)
                            setSelectedItem(undefined);
                        })
                    }}
                    totalCount={totalCount}
                    dataProvider={goodsGroupADP}
                    selectedItem={selectedItem}
                    selectedItems={selectedItems}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onMultipleSelect={(rows) => {
                        setSelectedItems(rows)
                        props?.onMultipleSelect?.(rows)
                    }}
                    multipleSelect={props.multipleSelect}
                    plugin={props.plugin}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}