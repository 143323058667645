import axios, { AxiosResponse } from "axios"
import JSZip from "jszip"
import { ExportFormats } from "../../Business/Reports/Shared/Enums/ExportFormats"
import { IReportInstanceKnot } from "../../Business/Reports/Shared/Interfaces/IReportInstanceKnot"
import { ReportFilter } from "../../Business/Reports/Shared/ReportFilter"
import { ReportWrapper } from "../../Business/Reports/Shared/ReportWrapper"
import BaseCoreApiRequest from "../core-api-requests/baseCoreApiRequest"
import { CoreApiResponse, IRequestEventHandler } from "../core-api-requests/common"
import { IAxiosResponse } from "../fetchApiDistributor/common"
import { v4 as uuidv4 } from 'uuid'
import * as mime from 'react-native-mime-types'
import { ExportMethods } from "../../Business/Reports/Shared/Enums/ExportMethods"
import { ReportFilterDataType } from "../../Business/Reports/Shared/Enums/ReportFilterDataType"
import { IReportFiltersRequest } from "../../Business/Reports/Components/ReportActionButton/ReportActionButton"
import { IKeyValuePair } from "../../Business/Reports/Shared/Interfaces/IKeyValuePair"
import { getTimeZone, isNullOrWhiteSpace } from "../../Business/Reports/Common/CommonFunctions"
import retry from 'retry'
import { IdTableVariant } from "../../@types/enumsGlobal"
import renderGlobalAlert from "../../system/hooks/useGlobalAlert"
import { DateTime } from "luxon"

interface IExportRequestDTO {
    Asm: string
    UserId: string
    Format: ExportFormats
    Fresh: boolean
    ExportMethod: ExportMethods
    Filters: Array<ReportFilter>
    Instances: Array<IKeyValuePair>
    TimeZone: number
}

class ReportRequest extends BaseCoreApiRequest {
    protected eventHandler: IRequestEventHandler | null | undefined
    protected readonly token: string

    private pdfMime = "application/pdf"
    private zip = new JSZip()

    constructor(baseUrl: string, token: string, eventHandler?: IRequestEventHandler | null) {
        super(baseUrl)
        this.eventHandler = eventHandler
        this.token = token
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
    }

    test(callback?: (e: CoreApiResponse<Array<ReportWrapper>>) => void) {
        const url = `${this._baseUrl}/${"Test"}`
        this.get<Array<ReportWrapper>>(url, [this.getBearerHeader(this.token)], (e) => {
            callback?.(e)
        })
    }

    requestFilters(asmName: string, userId: string, callback?: (e: CoreApiResponse<Array<ReportFilter>>) => void) {
        const url = `${this._baseUrl}/${"RequestFilters"}/${asmName}/${userId}`
        this.get<Array<ReportFilter>>(url, [this.getBearerHeader(this.token)], (e) => {
            callback?.(e)
        })
    }

    private getListUrl(userId: string, reportsOnly: boolean, idTable?: IdTableVariant) {
        const url = `${this._baseUrl}/${"List"}/${userId}/${reportsOnly}/${idTable !== undefined ? idTable?.toString() : ""}`
        return url
    }

    list(userId: string, reportsOnly: boolean, idTable?: IdTableVariant, callback?: (e: CoreApiResponse<Array<ReportWrapper>>) => void) {
        const url = this.getListUrl(userId, reportsOnly, idTable)
        this.get<Array<ReportWrapper>>(url, [this.getBearerHeader(this.token)], (e) => {
            callback?.(e)
        })
    }

    listAsync(idTable: IdTableVariant, userId: string, reportsOnly: boolean) {
        const url = this.getListUrl(userId, reportsOnly, idTable)
        let promise = axios.get(url)
        return promise
    }

    freeCache(instanceId: string) {
        const url = `${this._baseUrl}/${"FreeCache"}/${instanceId}`
        axios.get(url)
    }

    saveSettings(userId: string, asm: string, filters: Array<ReportFilter>) {
        const url = `${this._baseUrl}/${"SaveSettings"}`
        const request = {
            userId: userId,
            asm: asm,
            filters: filters
        }
        axios.post(url,
            request,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
    }

    import(content: File, certificate: File | null, callback:(e: AxiosResponse<any,any>) => void) {
        const url = `${this._baseUrl}/${"Import"}`
        const bear = this.getBearerHeader(this.token)
        const formData = new FormData;
        formData.append('Content', content)
        certificate && formData.append('Certificate', certificate)

        axios.post(url,
            formData,
            {
                headers: {
                    "Authorization": bear[1],
                }
            }
        ).then(resp => { callback(resp) })
        .catch((ex) => {
            renderGlobalAlert(
                {
                  variant: "error",
                  statusCode: ex.response.status,
                  title: ex.message,
                  detail: ex.response.data.title,
                }
              )
        })
    }


    freeForUser(token: string) {
        const bear = this.getBearerHeader(token)
        const url = `${this._baseUrl}/${"FreeCacheForUser"}`
        axios.get(url, {
            headers: {
                "Authorization": bear[1]
            }
        })
    }

    download(body: Array<ReportFilter>, asmName: string, format: ExportFormats, instances: Array<IKeyValuePair>, userId: string, fresh: boolean, exportMethod: ExportMethods, dateWithUserZone: DateTime,
        callback: (e: CoreApiResponse<Array<IReportInstanceKnot>>) => void) {
        const url = `${this._baseUrl}/${"Export"}`
        const bear = this.getBearerHeader(this.token)

        const request: IExportRequestDTO = {
            Asm: asmName,
            Filters: body,
            Format: format,
            Fresh: fresh,
            Instances: instances,
            UserId: userId,
            ExportMethod: exportMethod,
            TimeZone: getTimeZone(dateWithUserZone)
        }

        const respType = exportMethod === ExportMethods.ForUrl ? 'json' : 'blob'

        let p: Promise<AxiosResponse> =
            axios.post(url,
                request,
                {
                    responseType: respType,
                    headers: {
                        "Authorization": bear[1],
                        'Content-Type': 'application/json'
                    }
                })

        p.then(resp => {
            if (resp.status === 200) {
                let filesMap: Array<IKeyValuePair>
                var files = new Array<IReportInstanceKnot>()
                const completedMessage = this.getCompletedMessage(resp.status)

                if (exportMethod === ExportMethods.ForUrl) {
                    filesMap = resp.data as Array<IKeyValuePair>

                    filesMap.forEach((fe) => {
                        const fileName = fe.value
                        const instanceId = fe.key
                        const url = `${this._baseUrl}Viewer/${"View"}/${instanceId}/${encodeURIComponent(fe.value)}`
                        files.push({ Instance: instanceId, File: undefined, FileName: fileName, Url: url })
                    })
                    callback?.({ respType: "isCompleted", data: files, message: completedMessage })
                }
                else {
                    const blob: Blob = (resp.data as Blob)
                    const pdfMime = this.pdfMime
                    const zipped = blob.type !== pdfMime

                    if (zipped) {
                        this.zip.loadAsync(resp.data as Blob)
                            .then(function (zip) {
                                const promises = new Array<Promise<Blob>>()
                                const filenamesInZip = new Array<string>()

                                //https://stackoverflow.com/questions/54274686/how-to-wait-for-asynchronous-jszip-foreach-call-to-finish-before-running-next
                                //https://stackoverflow.com/questions/42587203/jszip-extract-file-objects
                                zip.forEach(function (relativePath, file) {
                                    filenamesInZip.push(file.name)
                                    promises.push(file.async("blob"))
                                });

                                const comment = (zip as any).comment as unknown as string

                                if (!isNullOrWhiteSpace(comment))
                                    filesMap = JSON.parse((zip as any).comment as unknown as string) as Array<IKeyValuePair>

                                const mi = mime.lookup(filenamesInZip[0])

                                //Чтобы алгоритм не убегал, нужно сперва скопить все промисы, а затем синхронно их обработать и уже после этого поставить на выход
                                Promise.all(promises).then(function (data) {
                                    let counter = 0
                                    data.forEach((fe) => {
                                        const fileName = filenamesInZip[counter]
                                        const file = new File([fe], fileName, { type: mi as string });
                                        let instanceId: string
                                        if (filesMap !== undefined) {
                                            const instance = filesMap.find((element) => {
                                                return element.value === fileName
                                            })
                                            instanceId = instance?.key as string
                                        }
                                        else
                                            instanceId = instances[0].key
                                        files.push({ Instance: instanceId, File: file, FileName: fileName })

                                        counter++
                                    })
                                    callback?.({ respType: "isCompleted", data: files, message: completedMessage })
                                });
                            })
                            .catch(ex => {
                                console.warn(ex)
                                callback?.({
                                    respType: "isFailed",
                                    message: this.getExceptionMessage()
                                })
                            })
                    }
                    else {
                        //https://stackoverflow.com/questions/50642065/get-a-file-name-before-saving-it
                        //https://stackoverflow.com/questions/60833644/getting-filename-from-react-fetch-call
                        //https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link
                        //https://stackoverflow.com/questions/31214677/download-a-reactjs-object-as-a-file
                        const suggestedFileName = resp.headers["content-disposition"]
                        let filename = suggestedFileName?.split('filename*=UTF-8\'\'')[1];
                        filename = decodeURIComponent(filename as string)
                        const file = new File([resp.data as Blob], filename, { type: pdfMime, endings: "native" });
                        files.push({ Instance: instances[0].key, File: file, FileName: filename })
                        callback?.({ respType: "isCompleted", data: files, message: this.getCompletedMessage(resp.status) })
                    }
                }
            }
            else {
                callback?.({
                    respType: "isFailed",
                    message: this.getErrorMessage(resp.status)
                })
            }
        })
            .catch(ex => {
                console.warn(ex)
                callback?.({
                    respType: "isFailed",
                    message: this.getExceptionMessage()
                })
            })
    }

    protected promiseHandlerAxios(promise: Promise<AxiosResponse>, callback?: (e: IAxiosResponse) => void) {
        promise
            .then(resp => {
                if (resp.status === 200) {
                    callback?.({ response: resp, message: null })
                }
                else {
                    callback?.({ response: resp, message: this.getErrorMessage(resp.status).title })
                }
            })
            .catch(ex => {
                console.warn(ex)
                callback?.({
                    response: null,
                    message: this.getExceptionMessage().title
                })
            })
    }

    beforeDownload(assemblyName: string,
        reportName: string,
        userId: string,
        arrayOf: Array<IKeyValuePair>,
        callback: (e: CoreApiResponse<IReportFiltersRequest>) => void
    ) {
        const encodedReportAssName = encodeURIComponent(assemblyName)
        const url = `${this._baseUrl}/${"RequestFilters"}/${encodedReportAssName}/${userId}`
        let t = this

        axios.get(url)
            .then(function (data) {
                const resp = (data as unknown as AxiosResponse<unknown, any>)
                if (resp.status === 200) {
                    let filters = resp.data as Array<ReportFilter>
                    let instances = new Array<IKeyValuePair>()

                    let pluginFilter = {
                        dataType: ReportFilterDataType.IdTable,
                        saveState: false,
                        name: 'IdTable',
                        values: new Array<string>()
                    } as unknown as ReportFilter

                    filters.splice(0, 0, pluginFilter)

                    if (pluginFilter !== undefined) {
                        //Заполнить фильтр айдишниками документа
                        if (pluginFilter.values === undefined)
                            pluginFilter.values = new Array<string>()
                        else
                            pluginFilter.values.pop()

                        arrayOf.forEach((i) => {
                            pluginFilter?.values.push(i.key)
                            instances.push({ key: uuidv4(), value: i.value })
                        })
                    } else
                        instances.push({ key: uuidv4(), value: reportName })//Значит только один отчёт

                    //Найти видимые фильтры, их наличие будет сигнализировать о том, что нужно отображать форму ввода параметров
                    let visibleFilters: Array<ReportFilter> = new Array<ReportFilter>()
                    filters.forEach((element) => {
                        if (element.hidden === false)
                            visibleFilters.push(element)
                    })

                    let result = { instances: instances, visibleFilters: visibleFilters, filters: filters }
                    callback?.({ respType: "isCompleted", data: result, message: t.getCompletedMessage(resp.status) })
                } else {
                    callback?.({
                        respType: "isFailed",
                        message: t.getErrorMessage(resp.status)
                    })
                }
            })
            .catch(ex => {
                console.warn(ex)
                //ToDo siv что-то оно не всегда выводится
                callback?.({
                    respType: "isFailed",
                    message: this.getExceptionMessage()
                })
            })
    }

    //Освежить токен на сервере отчётов
    freshenUp(oldToken: string) {
        const bear = this.getBearerHeader(this.token)
        const url = `${this._baseUrl}/${"FreshenUp"}`
        const operation = retry.operation({
            retries: 5,
            factor: 3,
            minTimeout: 1000,
            maxTimeout: 60 * 1000,
            randomize: true,
        });
        const data = { Item1: oldToken }
        const head = {
            "Authorization": bear[1],
            'Content-Type': 'application/json'
        }
        operation.attempt(async (currentAttempt) => {
            console.trace('sending request: ', currentAttempt, ' attempt')
            try {
                //ToDo здесь возможна обработка результата, но не пока понятно нужна ли она
                await axios.post(url, data, { headers: head })
            } catch (e) {

            }
        })
    }
}
export default ReportRequest