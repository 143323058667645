import { string } from "mathjs"


export const labelPrintingHeader: string = 'Расширенная печать ценников и этикеток'

export const labelPrintingAssName: string = 'LabelPrinting'

export const labelPrintingplugin: IPluginSettings = {
    name: labelPrintingHeader,
    mnemocode: 'label_print_modal_plugin',
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            displayName: "idGlobal",
            propertyType: "string",
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: "labelQuantity",
            displayName: "Этикеток",
            propertyType: "number",
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 2,
            propertyName: "priceQuantity",
            displayName: "Ценников",
            propertyType: "number",
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 3,
            propertyName: "stelQuantity",
            displayName: "Стеллажек",
            propertyType: "number",
            visibility: true,
            width: 100,
            changeable: true
        },
        {
            order: 4,
            propertyName: "goodsCode",
            displayName: "Код товара",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 5,
            propertyName: "goodsName",
            displayName: "Товар",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 6,
            propertyName: "producerName",
            displayName: "Производитель",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 7,
            propertyName: "countryName",
            displayName: "Страна",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 8,
            propertyName: "externalBarcode",
            displayName: "Внеш. ШК",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 9,
            propertyName: "scalingRatio",
            displayName: "Упаковка",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 10,
            propertyName: "seriesNumber",
            displayName: "Серия",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 11,
            propertyName: "bestBefore",
            displayName: "Срок годности",
            propertyType: "datetime",
            visibility: true,
            width: 100
        },
        {
            order: 12,
            propertyName: "contractorName",
            displayName: "Аптека",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 13,
            propertyName: "internalBarcode",
            displayName: "Внутр. ШК",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 14,
            propertyName: "supplierName",
            displayName: "Поставщик",
            propertyType: "string",
            visibility: true,
            width: 100
        },
        {
            order: 15,
            propertyName: "supplierVat",
            displayName: "Ст. НДС Опт.",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 16,
            propertyName: "retailPriceIncVat",
            displayName: "Цена",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 17,
            propertyName: "oldPrice",
            displayName: "Цена до переоценки",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 18,
            propertyName: "quantity",
            displayName: "Кол-во",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 19,
            propertyName: "specPrice",
            displayName: "Специальная цена",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 20,
            propertyName: "priceDiff",
            displayName: "Разница",
            propertyType: "number",
            visibility: true,
            width: 100
        },
        {
            order: 21,
            propertyName: "isKiz",
            displayName: "Ввод КИЗ",
            propertyType: "boolean",
            visibility: true,
            width: 100
        },
        {
            order: 22,
            propertyName: "kizBarcode",
            displayName: "КИЗ",
            propertyType: "string",
            visibility: true,
            width: 100
        },
    ]
}