import { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames"
import { LoadingStatus } from "../../../@types/enumsGlobal";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { ICreateRole, IRoleDTO, IRoleViewDTO } from "../../../libs/coreapi-dto/service/role";
import { RoleDataProvider } from "../../../Services/DataProviders/RoleDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import styles from '../../styles/index.module.scss'
import { RoleCommandPanel } from "./Components/RolePanel";
import { CreateRoleModal, Permission, TypeOpen } from "./Components/CreateRoleModal";
import { DeleteModalWindow } from "../../../components/modalWindows/DeleteModalWindow";
import { RestoreModalWindow } from "../../../components/modalWindows/RestoreModalWindow";
import { MessageModalWindow } from "../../../components/modalWindows/MessageModalWindow";

interface IMessageModalProps {
    show: boolean,
    message: string,
    handler?: () => void
}

const RoleGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const roleDataProvider = new RoleDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IRoleViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>(props.data ?? []);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: '' });

    const [modalWindow, setModalWindow] = useState(<></>)

    const loadData = () => {
        setLoadingStatus(LoadingStatus.InProcess)
        roleDataProvider.getView(gridFilter, (e)=> {
            try {
                setData([...e.map(x => ({ idGlobal: x.id, name: x.name, dateDeleted: x.dateDeleted, deleted: x.deleted }))])
                setLoadingStatus(LoadingStatus.Completed)
            } catch {
                setLoadingStatus(LoadingStatus.Failed)
            }
        })
    }

    useEffect(() => {
        if (viewState === 'refresh') {
            loadData()
            setViewState('view')
        }
        else
            renderModalWindow()
    }, [viewState])

    useEffect(() => {
        try {
            loadData()
            setLoadingStatus(LoadingStatus.Completed)
        } catch {
            setLoadingStatus(LoadingStatus.Failed)
        }
    }, [gridFilter])

    const close = () => {
        setViewState('refresh')
    }

    function renderModalWindow() {
        switch (viewState) {
            case "create": renderCreator(); break;
            case "edit": renderEditor(); break;
            case "copy": renderCopyEditor(); break;
            case "delete": renderDeleteWindow(); break;
            case "restore": renderRestoreWindow(); break;
            //case "search": renderSearchWindow(); break;
            default: setModalWindow(<></>)
        }
    }

    function renderDeleteWindow() {
        setModalWindow(
            <DeleteModalWindow
                name={selectedItem?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                delete={{
                    onClick: () => {
                        roleDataProvider.canDelete(selectedItem?.idGlobal as string, (e)=>{
                            if (e)
                            {
                                roleDataProvider.markDelete(selectedItem?.idGlobal as string, (e) => {
                                    setViewState("refresh")                                        
                                })
                                setSelectedItem(undefined)
                            }
                            else
                            {
                                setViewState("refresh")  
                                setShowMessageModal({ show: true, message: 'Невозможно удалить роль. Существуют пользователи, использующие данную роль' })
                            }
                                
                        })

                    },
                    title: 'Удалить'
                }}
            />
        )
    }

    function renderRestoreWindow() {
        setModalWindow(
            <RestoreModalWindow
                name={selectedItem?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                restore={{
                    onClick: () => {
                        roleDataProvider.restore(selectedItem?.idGlobal as string, () => setViewState("refresh"))
                        setSelectedItem(undefined)
                    },
                    title: 'Восстановить'
                }}
                header="Предупреждение"
            />
        )
    }

    function renderCreator() {
        setModalWindow(
            <CreateRoleModal
                typeOpen={'Create'}
                selectedItem={selectedItem}
                ok={(role: ICreateRole) => {
                    roleDataProvider.createRole(role, () => {
                        setViewState('refresh');
                    })
                    close();
                }}
                cancel={() => setViewState("return") }
            />
        )
    }

    function renderEditor() {
        setModalWindow(
            <CreateRoleModal
                typeOpen={'Edit'}
                selectedItem={selectedItem}
                ok={(role: ICreateRole) => {
                    roleDataProvider.updateRole(selectedItem?.idGlobal as string,role, () => {
                        setViewState('refresh');
                    })
                    close();
                }}
                cancel={() => setViewState("return") }
            />
        )
    }

    function renderCopyEditor() {
        roleDataProvider.getRoleById(selectedItem?.idGlobal as string, entity => {
            setModalWindow(
                <CreateRoleModal
                    typeOpen={'Copy'}
                    selectedItem={selectedItem}
                    ok={(role: ICreateRole) => {
                        roleDataProvider.createRole(role, () => {
                            setViewState('refresh');
                        })
                    }}
                    cancel={() => setViewState("return") }
                />
            )
        })
    }

    return (
        <>
            <PluginWrapper>

                <ToolbarWrapper>
                    <RoleCommandPanel
                        add={{
                            onClick: () => {
                                setViewState('create')
                            }
                        }}
                        edit={{
                            onClick: () => {
                                setViewState('edit')
                            },
                            disabled: !selectedItem || selectedItem.isDeleted
                        }}
                        refresh={{
                            onClick: () => {
                                setViewState('refresh')
                            }
                        }}
                        showDeleted={{
                            disabled: false,
                            onChecked: (e) => {
                                dispatchGridFilter({ type: "showDeleted", payload: e })
                                setViewState("filter")
                            }
                        }}
                        copy={{
                            onClick: () => setViewState("copy"),
                            disabled: !selectedItem
                        }}
                        delete={{
                            onClick: () => setViewState("delete"),
                            disabled: !selectedItem || selectedItem?.isDeleted
                        }}
                        restore={{
                            onClick: () => setViewState("restore"),
                            disabled: !selectedItem || !selectedItem?.isDeleted
                        }}
                        permission={props.plugin.permission}
                    />
                </ToolbarWrapper>
                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        <DefaultGrid
                            gridId={props.gridId}
                            data={data}
                            loadingStatus={loadingStatus}
                            setLoadingStatus={setLoadingStatus}
                            totalCount={totalCount}
                            filter={gridFilter}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            selectedItems={selectedItems}
                            onMultipleSelect={(rows) => {
                                setSelectedItems(rows)
                                props?.onMultipleSelect?.(rows)
                            }}
                            multipleSelect={props.multipleSelect}
                            onSelect={(row) => {
                                setSelectedItem(row)
                                props?.onSelect?.(row)
                            }}
                            useUiService={false}
                            onDoubleClick={(i) => props.onDoubleClick?.(i)}
                            onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                            onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                            onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                            onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                            onEnter={() => setViewState("edit")}
                        />
                        <div className={classNames(styles.separator, styles.separator_horizontal)}></div>
                        <div className={styles.detailsTabView}>
                        </div>
                    </div>
                </div>

                {
                    showMessageModal.show &&
                    <MessageModalWindow
                        ok={{ onClick: () => { 
                            if (showMessageModal.handler) { 
                                showMessageModal.handler() 
                            }
                            setShowMessageModal({ show: false, message: '' }) 
                        }}}
                        cancel={{ hidden: true,
                            onClick: () => { setShowMessageModal({ show: false, message: '' }) } }}
                        message={showMessageModal.message}
                    />
                }

                {
                    modalWindow
                }

            </PluginWrapper>

        </>
    )
}

export default RoleGrid
