const StoreGridPluginSettings: IPluginSettings = {
    name: 'Склады',
    mnemocode: 'store_plugin',
    permission: 'Store',
    columns: [{
        order: 0,
        propertyName: 'mnemocode',
        propertyType: 'string',
        displayName: 'Мнемокод',
        visibility: true,
        width: 150
    }, {
        order: 1,
        propertyName: 'name',
        propertyType: 'string',
        displayName: 'Наименование',
        visibility: true,
        width: 150
    }, {
        order: 2,
        propertyName: 'mnemocodeEsfi',
        propertyType: 'number',
        displayName: 'ЕСФИ',
        visibility: true,
        width: 300
    }, {
        order: 3,
        propertyName: 'isDefault',
        propertyType: 'boolean',
        displayName: 'По умолчанию',
        visibility: true,
        width: 300
    }, {
        order: 4,
        propertyName: 'sno',
        propertyType: 'number',
        displayName: 'СНО',
        visibility: true,
        width: 300
    }, 
    {
        order: 5,
        propertyName: 'snoName',
        propertyType: 'string',
        displayName: 'Наименование СНО',
        visibility: true,
        width: 300
    },{
        order: 6,
        propertyName: 'isAccountable',
        propertyType: 'boolean',
        displayName: 'Баланс',
        visibility: true,
        width: 300
    }, {
        order: 7,
        propertyName: 'isInternalBarcode',
        propertyType: 'boolean',
        displayName: 'Формирование вн. ШК',
        visibility: true,
        width: 300
    },
    //  {
    //     order: 7,
    //     propertyName: 'isWholesaleAccounting',
    //     propertyType: 'boolean',
    //     displayName: 'isWholesaleAccounting',
    //     visibility: true,
    //     width: 300
    // },
     {
        order: 8,
        propertyName: 'isReferencePrices',
        propertyType: 'boolean',
        displayName: 'Референтная цена',
        visibility: true,
        width: 300
    }, {
        order: 9,
        propertyName: 'isHospitalApteka',
        propertyType: 'boolean',
        displayName: 'Больничная аптека',
        visibility: true,
        width: 300
    }, {
        order: 10,
        propertyName: 'isUsePricingModelSampleParameters',
        propertyType: 'boolean',
        displayName: 'Использовать МЦО',
        visibility: true,
        width: 300
    }, {
        order: 11,
        propertyName: 'accountingInternalBarcode',
        propertyType: 'boolean',
        displayName: '',
        visibility: true,
        width: 300
    }, {
        order: 12,
        propertyName: 'priorityDistribution',
        propertyType: 'number',
        displayName: 'Производственный',
        visibility: true,
        width: 300
    }, {
        order: 13,
        propertyName: 'kizScanning',
        propertyType: 'boolean',
        displayName: 'Сканировать КИЗ',
        visibility: true,
        width: 300
    }, 
    // {
    //     order: 14,
    //     propertyName: 'edmSendingType',
    //     propertyType: 'number',
    //     displayName: 'edmSendingType',
    //     visibility: true,
    //     width: 300
    // }, 
    {
        order: 15,
        propertyName: 'storeTypeName',
        propertyType: 'string',
        displayName: 'Тип склада',
        visibility: true,
        width: 300
    }, {
        order: 16,
        propertyName: 'contractorName',
        propertyType: 'string',
        displayName: 'Контрагент',
        visibility: true,
        width: 300
    }, {
        order: 17,
        propertyName: 'dateModified',
        propertyType: 'datetime',
        displayName: 'Дата изменения',
        visibility: true,
        width: 157
    }, {
        order: 18,
        propertyName: 'dateDeleted',
        propertyType: 'datetime',
        displayName: 'Дата удаления',
        visibility: true,
        width: 157
    }, {
        order: 19,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'ИД',
        visibility: false,
        width: 157
    }, {
        order: 20,
        propertyName: 'displayName',
        propertyType: 'string',
        displayName: 'DisplayName',
        visibility: false,
        width: 157
    }
    ]
}

export default StoreGridPluginSettings