import { CoreApiResponse, ICoreCompletedResponse } from "../../../libs/core-api-requests/common";
import { IErrorMessage, IExceptionMessage } from "../../../libs/fetchApiDistributor/common";

export abstract class DocumentDataProviderV2<TDocumentViewDTO, TDocumentGetDTO, TDocumentCreateDTO, IDocumentUpdateDTO, TDocumentItemViewDTO, TDocumentItemGetDTO>
    implements IDocumentDataProviderV2<TDocumentViewDTO, TDocumentGetDTO, TDocumentCreateDTO, IDocumentUpdateDTO, TDocumentItemViewDTO, TDocumentItemGetDTO>
{
    abstract getDataRequest(): Promise<IDocumentRequestV2<TDocumentViewDTO, TDocumentGetDTO, TDocumentCreateDTO, IDocumentUpdateDTO, TDocumentItemViewDTO, TDocumentItemGetDTO>>;

    getView(body: IViewBody, callback?: (data: TDocumentViewDTO[], totalCount: number) => void) {
        this.getDataRequest().then((r) =>
            r.getView(body, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data.entities, e.data.totalCount);
                else throw new Error(e.message.text);
            })
        );
    }

    getById(idGlobal: string, callback?: (entity: TDocumentGetDTO) => void) {
        this.getDataRequest().then((r) =>
            r.getById(idGlobal, (e) => {
                if (e.respType === "isCompleted") callback?.(e.data);
                else throw new Error(e.message.text);
            })
        );
    }

    create(entity: TDocumentCreateDTO, callback?: (idGlobal: string) => void, testApi?: ({ name, time }) => void, callbackFailed?: (e)=>void) {
        this.getDataRequest().then((r) =>
            r.create(entity, (e, test) => {
                if (e.respType === "isCompleted") {
                    callback?.(e.data);
                    testApi?.(test as { name; time });
                } else {
                    callbackFailed?.(e)
                    throw new Error(e.message.text);
                }
            })
        );
    }

    update(idGlobal: string, body: IDocumentUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.update(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                    else {
                        callback?.(e)
                        throw new Error(e.message.text);
                    }
            })
        );
    }

    updateExt(idGlobal: string, body: IDocumentUpdateDTO, onSuccess: (data: ICoreCompletedResponse<null>) => void, onError: (error: IErrorMessage | IExceptionMessage) => void) {
        this.getDataRequest().then((r) =>
            r.update(idGlobal, body, (e) => {
                if (e.respType === "isCompleted") {
                    onSuccess?.(e);
                } else {
                    onError?.(e.message);
                }
            })
        );
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.markDelete(idGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                else {
                    callback?.(e);
                    throw new Error(e.message.text)
                }
            })
        );
    }

    markItemDelete(idGlobal: string, idItemGlobal: string, callback: (e: CoreApiResponse<boolean>) => void) {
        this.getDataRequest().then((r) =>
            r.markItemDelete(idGlobal, idItemGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                else {
                    callback?.(e);
                }
            })
        );
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.restore(idGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                else {
                    callback?.(e);
                    throw new Error(e.message.text);
                }
            })
        );
    }

    process(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.process(idGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                else {
                    callback?.(e);
                    throw new Error(e.message.text)
                };
            })
        );
    }

    unProcess(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            r.unProcess(idGlobal, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data);
                    } catch {
                        callback?.(e);
                    }
                else {
                    callback?.(e);
                    throw new Error(e.message.text)
                };
            })
        );
    }

    getItemsView(idGlobal: string, body: IViewBody, callback?: (data: TDocumentItemViewDTO[], totalCount: number) => void, testApi?: ({ name, time }) => void) {
        this.getDataRequest().then((r) =>
            r.getItemsView(idGlobal, body, (e) => {
                if (e.respType === "isCompleted")
                    try {
                        callback?.(e.data.entities, e.data.totalCount);
                    } catch {
                        console.error("ee");
                    }
                else throw new Error(e.message.text);
            })
        );
    }

    getItemById(idGlobal: string, idItemGlobal: string, callback?: (e: TDocumentItemGetDTO) => void, testApi?: ({ name, time }) => void) {
        this.getDataRequest().then((r) =>
            r.getItemById(
                idGlobal,
                idItemGlobal,
                (e) => {
                    if (e.respType === "isCompleted")
                        try {
                            callback?.(e.data);
                        } catch {
                            console.error("ee");
                        }
                    else throw new Error(e.message.text);
                },
                (time) => {
                    testApi?.(time);
                }
            )
        );
    }

    cancel(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        this.getDataRequest().then((r) =>
            {
                if(r.cancel)
                    r.cancel(idGlobal, (e) => {
                        if (e.respType === "isCompleted")
                            try {
                                callback?.(e.data);
                            } catch {
                                callback?.(e);
                            }
                        else {
                            callback?.(e);
                            throw new Error(e.message.text)
                        };
                    })
            }
        );
    }
}
