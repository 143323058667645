import { FC, useEffect, useState } from "react"
import BaseCreator from "../../../../../components/creators/baseCreator"
import { ISysOptionsDTO } from "../../../../../libs/coreapi-dto/service/sysOptions"
import { SysOptionsDataProvider } from "../../../../../Services/DataProviders/SysOptionsDataProvider"
import { useAppContext } from "../../../../../system/providers/appContextProvider"
import { DateInput, DecimalInputV2, TextInput } from "../../../../../components/controls/inputs/BaseInput"
import { IJson } from "../interface"
import { useForm } from "../../../../../system/hooks/useForm"
import { dataTypeJson } from "../dataTypeJson"
import { DateTime } from "luxon"
import { CheckBox } from "../../../../../components/controls/checkbox"
import { sysOptionsName } from "../../../../../@types/enumsGlobal"
import SourceTypeEditModal from "./SourceTypeEditModal"

interface ISysOptionsEditModal {
    cancel: () => void
    idGlobal?: string
    lockFromPermission?: boolean
    name: string
}
type Validator = ISysOptionsDTO

export const SysOptionsEditModal: FC<ISysOptionsEditModal> = (props) => {  
  const appContext = useAppContext()
  const sysOptionsDataProvider = new SysOptionsDataProvider(appContext.coreApiService)
  const [data, setData] = useState<ISysOptionsDTO>();

  const [initForSourceTypes, setInitForSourceTypes] = useState<ISysOptionsDTO>();

  const { isValid, errors, setErrors } = useForm<Validator>({
    validations: {
        jsonData: {
            custom: {
                isValid: () => {
                  if (!isValidSmartSearch()) return false
                  return true
                },  
                message: 'Значение больше или меньше одного'  
            }
         }    
    }
  });

  useEffect(() => {  
    if (!props.idGlobal) return
    sysOptionsDataProvider.getById(props.idGlobal as string, (entities) => {
        setData(entities);
        if (props.name === sysOptionsName.SourceTypeKizInventory)
          setInitForSourceTypes(entities);
    });    
  }, []);

  const isValidSmartSearch = () :boolean => {
    if (data?.name !== 'SmartSearch') return true
    let obj: IJson = JSON.parse(data?.jsonData);   
    if (Number(obj.Value) > 1) return false
    if (Number(obj.Value) < 0) return false
    return true
  }

  const getValue = () => {
    if (!data?.jsonData) return undefined
    let obj: IJson = JSON.parse(data?.jsonData);     
    return obj.Value   
  }

  const getComponents = () => {
    if (data?.type === dataTypeJson.Decimal)
    {
      return (        
        <DecimalInputV2 
        value={getValue()} 
        label="Значение" 
        onChange={(value) => {
          setData({...data,
            jsonData: JSON.stringify({Value: value}),
            value: value.toString()
          })
        }} 
        error={errors.jsonData} 
        onFocus={() => setErrors({ ...errors, jsonData: undefined })}
        disabled={props.lockFromPermission}
        />        
      )
    }
    if (data?.type === dataTypeJson.Guid)
    {
      return(
        <TextInput value={getValue()}  label="Значение" onChange={(value) => {
          setData({...data,
            jsonData: JSON.stringify({Value: value}),
            value: value
          })
        }}
        disabled={props.lockFromPermission}
        />
      )
    }
    if (data?.type === dataTypeJson.String) 
    {
      return(
        <TextInput value={getValue()}  label="Значение" onChange={(value) => {
          setData({...data,
            jsonData: JSON.stringify({Value: value}),
            value: value
        })
        }}
        disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === dataTypeJson.DateTime) 
    {
      return(
        <DateInput value={DateTime.fromJSDate(new Date(getValue() as string))}  label="Значение" onBlur={(value) => {
          setData({...data,
            jsonData: JSON.stringify({Value: value?.toFormat("yyyy-MM-dd")}),
            value: value?.toFormat("yyyy-MM-dd") ?? ''
          })
        }}
        disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === dataTypeJson.Boolean) 
    {
      let value = Boolean(getValue())
      return(
        <CheckBox id="bool" defaultChecked={value}  label="Значение" onChanged={() => {
          let val
          if (props.name === sysOptionsName.AllowAcquiringReturnWithDisconnectedPinpad) val = !value ? 1: 0
          else val = !value
          setData({...data,
            jsonData: JSON.stringify({Value: val}),
            value: String(val)
          })
        }}
        disabled={props.lockFromPermission}
        />
      )
    }

    if (data?.type === dataTypeJson.Object) {
      return getComponentsObject();
    }

    return (<></>)
  }

  const getComponentsObject = () => {
    if (!data) return;
    const obj = JSON.parse(data?.jsonData);
    const value = obj.Value;
    let components: any = []
    Object.keys(value).forEach(function(key) {
      let component = getComponentsInner(value[key], key);
      components.push(component);
    });
    return components;
  }

  const getComponentsInner = (inValue, inKey) => {
    if (!data) return
    if (typeof inValue === dataTypeJson.Boolean) {
        let value = Boolean(inValue)
        return(
          <CheckBox id={"bool"+inKey} defaultChecked={value} label={"Значение"+" ("+inKey+")"} onChanged={() => {
            let val
            val = !value
            
            const obj = JSON.parse(data?.jsonData);
            let objValue = obj.Value;
            objValue[inKey] = val;

            setData({...data,
              jsonData: JSON.stringify({Value: objValue})
            })

          }}
          disabled={props.lockFromPermission}
          />
        )
      }
    if (typeof inValue === dataTypeJson.Object) {
      if (props.name === sysOptionsName.SourceTypeKizInventory) {
        return(<SourceTypeEditModal
          data={data}
          setData={(value) => setData(value)}
          initForSourceTypes={initForSourceTypes}
        />)
      }
    }
  }

  const save = () => {
      if (!data) return
      if (!isValid()) return
      sysOptionsDataProvider.update(data.idGlobal as string, data, () => {
        props.cancel()
      })
  }

  return (
      <>
          <BaseCreator 
            save={save}
            cancel={()=> props.cancel()}
            valid={!props.lockFromPermission}
            variant="edit"
          >           
            
          {
            getComponents()
          }
                    
          </BaseCreator>
      </>
    )
}
