import { KizViewPluginSettings } from "../../Dictionaries/Kiz/KizView/KizViewPluginSettings";
import { ScanBoxCreatePluginSettings } from "../../Dictionaries/Kiz/ScanModal/box/ScanBoxCreatePluginSettings";
import ImportRemainsItemGridPluginSettings from "./ImportRemainsItem/ImportRemainsItemGridPluginSettings";

const ImportRemainsGridPluginSettings: IPluginSettings = {
    name: "Ввод остатков",
    mnemocode: "import_remains_plugin",
    permission: "ImportRemains",
    columns: [
        {
            order: 0,
            propertyName: "idGlobal",
            propertyType: "string",
            displayName: "ИД",
            visibility: false,
            width: 200,
        },
        {
            order: 1,
            propertyName: "displayName",
            propertyType: "string",
            displayName: "Наименование",
            visibility: false,
            width: 200,
        },
        {
            order: 2,
            propertyName: "mnemocode",
            propertyType: "string",
            displayName: "Номер",
            visibility: true,
            width: 150,
        },
        {
            order: 3,
            propertyName: "storeName",
            propertyType: "string",
            displayName: "Склад",
            visibility: true,
            width: 150,
        },
        {
            order: 4,
            propertyName: "documentDate",
            propertyType: "date",
            displayName: "Дата",
            visibility: true,
            width: 150,
        },
        {
            order: 5,
            propertyName: "documentState",
            propertyType: "string",
            displayName: "Статус",
            visibility: true,
            width: 150,
        },
        {
            order: 6,
            propertyName: "dateModified",
            propertyType: "datetime",
            displayName: "Дата изменения",
            visibility: true,
            width: 150,
        },
        {
            order: 7,
            propertyName: "dateDeleted",
            propertyType: "datetime",
            displayName: "Дата удаления",
            visibility: true,
            width: 150,
        },
    ],
    detailsPlugins: [ImportRemainsItemGridPluginSettings, KizViewPluginSettings, ScanBoxCreatePluginSettings],
};

export default ImportRemainsGridPluginSettings;
