import { FC, useContext, useState, useEffect } from "react";
import { ExpenseDocumentsButton } from "../../../../components/buttons/iconButtons/special/QuickAccessBarCollection";
import BaseCommandsPanel from "../../../../components/commandsPanels/BaseCommandsPanel";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import { IKizDTO } from "../../../../libs/coreapi-dto/dirs/kiz";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import styles from './Styles/KizViewPlugin.module.scss'
import Tooltip from "../../../../components/tooltips/Tooltip";

export interface IScanKizViewProps extends ISelectorGridProps {
    gridId: string,
    plugin: IPluginSettings,
    baseGridFilter?: IGridFilter,
    id?: string
}
export const KizView: FC<IScanKizViewProps> = (props) => {
    const appCtx = useAppContext()
    const pluginCtx = usePluginContext()
    const tabsCtx = useTabsContext();
    const kizDataProvider = new KizDataProvider(appCtx.coreApiService);
    const [data, setData] = useState<IKizDTO[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [masterSelectedItem, setMasterSelectedItem] = useState<ISelectGridRow | undefined>()

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            if ((!masterSelectedItem || masterSelectedItem && masterSelectedItem.idGlobal !== pluginCtx.masterGrid.selectedItem.idGlobal) || pluginCtx.masterGrid.view === true ) {
                setMasterSelectedItem(pluginCtx.masterGrid.selectedItem)
                kizDataProvider.getKizViewAsync(pluginCtx.masterGrid.selectedItem.idGlobal, gridFilter, (entities, totalCount) => {
                    setData(entities.map(x => {
                        return {
                            idGlobal: x.idGlobal,
                            barcode: x.barcode,
                            decodedBarcode: x.decodedBarcode,
                            gtinSGtin: x.gtinSGtin,
                            sGtin: x.sGtin,
                            gtin: x.gtin,
                            kizState: x.kizState,
                            lotName: x.lotName,
                            code: x.code,
                            docType: x.docType,
                            idError: x.idError,
                            error: x.error,
                        }
                    }))
                    setTotalCount(totalCount)
                    
                    if(pluginCtx.masterGrid.view == true) {
                        pluginCtx.masterGrid.refreshState(false)
                    }
                })
            } 
        }
    }, [pluginCtx.masterGrid.selectedItem, pluginCtx.masterGrid.view])

    return (
        <>
            <div className={styles.toolsPanel}>
                <BaseCommandsPanel groups={[
                    [
                        {
                            iconButton: <Tooltip title='Переход в журнал КИЗ'>
                                <ExpenseDocumentsButton sizeVariant="mini" onClick={() => {
                                    tabsCtx.openChild('kiz_exchange_plugin', undefined, undefined, undefined, data.find(x => x.idGlobal === selectedItem?.idGlobal)?.barcode)
                                }}
                                />
                            </Tooltip>
                        },
                    ]
                ]} />
            </div>
            <DefaultGrid
                gridId={props.gridId}
                data={data}
                filter={gridFilter}
                totalCount={totalCount}
                dataProvider={kizDataProvider}
                getView={(gridFilter,callback) => {
                    kizDataProvider.getKizViewAsync(pluginCtx?.masterGrid?.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                        callback(entities)
                        setTotalCount(totalCount)
                        if(pluginCtx.masterGrid.view == true) {
                            pluginCtx.masterGrid.refreshState(false)
                        }
                    })
                }}
                plugin={props.plugin}
                kizStatus
                selectedItem={selectedItem}
                onDoubleClick={(row) => tabsCtx.openChild('kiz_exchange_plugin', undefined, undefined, undefined, data.find(x => x.idGlobal === row.idGlobal)?.barcode)}
                onSelect={(row) => {
                    setSelectedItem(row)
                    props.onSelect?.(row)
                }}
                onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                onNumberPerPageChange={(n) => {
                    dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                }}
            />
        </>

    )
}

