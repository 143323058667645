import { ICreateUser, IUserDTO, IUserViewDTO } from "../../coreapi-dto/service/user";
import { ContentType } from "../../fetchApiDistributor/contentTypes";
import { IRequestEventHandler } from "../common";
import { BaseDirRequest } from "../dirs/baseDirRequest";



class UserRequest extends BaseDirRequest<IUserViewDTO, IUserDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "users", token, eventHandler)
    }

    createUser(body: ICreateUser, update?: (e) => void): void {
        this.post<IViewWrapper<IUserDTO>>(`${this._baseUrl}/${this._routePrefix}/Create`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            update?.(e);
        })
    }

    updateUser(id: string, body: ICreateUser, update?: () => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/${id}/Update`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            update?.();
        })
    }

    getUserView(body: IViewBody, callback?: (e) => void) {
        this.post<IUserDTO[]>(`${this._baseUrl}/${this._routePrefix}/View`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), e => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                }
            }

            callback?.(e);
        })
    }

    getUserById(id: string, callback?: (e) => void) {
        this.get<IUserDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            callback?.(e);
        })
    }

    getRoles(id: string, callback?: (e) => void) {
        this.get<IUserDTO>(`${this._baseUrl}/${this._routePrefix}/${id}/Roles`, [this.getBearerHeader(this._token)], (e) => {

            callback?.(e);
        })
    }

    addRole(id: string, roleName: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/AddToRole/${roleName}`, {}, [this.getBearerHeader(this._token)], e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            callback?.();
        })
    }

    removeRole(id: string, roleName: string, callback?: () => void) {
        this.delete(`${this._baseUrl}/${this._routePrefix}/${id}/RemoveFromRole/${roleName}`, {}, [this.getBearerHeader(this._token)], e => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            callback?.();
        })
    }

    blockUser(id: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/Block`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            if (e.respType === "isCompleted")
                callback?.();
        })
    }

    unBlockUser(id: string, callback?: () => void) {
        this.patch(`${this._baseUrl}/${this._routePrefix}/${id}/UnBlock`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }

            if (e.respType === "isCompleted")
                callback?.();
        })
    }
}
export default UserRequest