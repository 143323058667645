import { DateTime } from "luxon";
import { IDocumentInfoDTO, IEntitySimpleDTO, ISummaryCostInfoDTO } from "../../../../libs/coreapi-dto/@types/common";
import { ILotDTO } from "../../../../libs/coreapi-dto/accounting/lot";
import { IActDeductionCreateDTO } from "../../../../libs/coreapi-dto/documents/actDeduction";
import { IActDeductionItemViewDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";
import { ActDeductionReducerActionType } from "./ActDeductionReducerActionType";

export interface IActDeductionReducerInitialState {
    data: IActDeductionCreateDTO;
    selectedItem: IGridRow | undefined;
    store: IEntitySimpleDTO | null;
    reason: IEntitySimpleDTO | null;
    kizDestructionType: IEntitySimpleDTO | null;
    kizMoveDestructionContractor: IEntitySimpleDTO | null;
    kizDestructionMethod: IEntitySimpleDTO | null;
    kizDestructionOp: boolean;
    documentDate: DateTime;
    orderDate: DateTime | null;
    kizMoveDestructionDocNum: string;
    kizMoveDestructionDocDate: DateTime;
    kizMoveDestructionFiasAoguid: string;
    comment: string;
    kizDestructionDocNum: string;
    kizMoveDestructionActNum: string;
    kizMoveDestructionActDate: DateTime;
    kizDestructionDocDate: DateTime;
    idGlobal: string;
    mnemocode: string;
    baseDocumentMnemocode: string;
    baseDocumentInfo: IDocumentInfoDTO | null;
    displayName: string;
    kizCancelReason: string;
    kizMoveDestructionFiasFlat: string;
    kizMoveDestructionFiasHouseguid: string;
    orderNumber: string;
    retailSum: number;
    retailVatSum: number;
    retailSumIncVat: number;
    supplierSum: number;
    supplierVatSum: number;
    supplierSumIncVat: number;
    items: IActDeductionItemViewDTO[];
    addItemLot: ILotDTO | null;
    editItemLot: any | null;
    showKizScan: boolean;
    retailCostInfo: ISummaryCostInfoDTO;
    supplierCostInfo: ISummaryCostInfoDTO;
    pharmacy?: IEntitySimpleDTO | null;
    storeFromLot?: IEntitySimpleDTO | null;
}

const initialCalculate = {
    vatSum: 0,
    sumIncVat: 0,
    sum: 0,
};

export const ActDeductionReducerInitialState: IActDeductionReducerInitialState = {
    data: {
        idGlobal: "",
        documentDate: DateTime.now(),
        orderDate: null,
        idStoreGlobal: "",
        idKizDestructionType: "",
        idKizDestructionMethod: "",
        idKizMoveDestructionContractor: "",
        kizMoveDestructionDocNum: "",
        idDeductionReasonGlobal: "",
        kizDestructionOp: false,
        kizMoveDestructionDocDate: DateTime.now(),
        kizDestructionDocNum: "",
        kizMoveDestructionActNum: "",
        kizMoveDestructionActDate: DateTime.now(),
        kizDestructionDocDate: DateTime.now(),
        kizMoveDestructionFiasAoguid: "",
        comment: "",
        items: [],
    },
    idGlobal: "",
    selectedItem: undefined,
    store: null,
    pharmacy: null,
    storeFromLot: null,
    reason: null,
    kizDestructionType: null,
    kizMoveDestructionContractor: null,
    kizDestructionMethod: null,
    kizDestructionOp: false,
    documentDate: DateTime.now(),
    orderDate: null,
    kizMoveDestructionDocNum: "",
    kizMoveDestructionDocDate: DateTime.now(),
    kizMoveDestructionFiasAoguid: "",
    comment: "",
    kizDestructionDocNum: "",
    kizMoveDestructionActNum: "",
    kizMoveDestructionActDate: DateTime.now(),
    kizDestructionDocDate: DateTime.now(),
    items: [],
    mnemocode: "",
    displayName: "",
    kizCancelReason: "",
    kizMoveDestructionFiasFlat: "",
    kizMoveDestructionFiasHouseguid: "",
    orderNumber: "",
    retailSum: 0,
    retailVatSum: 0,
    retailSumIncVat: 0,
    supplierSum: 0,
    supplierVatSum: 0,
    supplierSumIncVat: 0,
    baseDocumentMnemocode: "",
    baseDocumentInfo: null,
    addItemLot: null,
    editItemLot: null,
    showKizScan: false,
    retailCostInfo: initialCalculate,
    supplierCostInfo: initialCalculate,
};

export function ActDeductionReducerHandler(state: IActDeductionReducerInitialState = ActDeductionReducerInitialState, action: ActDeductionReducerActionType): IActDeductionReducerInitialState {
    switch (action.type) {
        case "setSelectedItem":
            return { ...state, selectedItem: action.payload };
        case "setStore":
            return {
                ...state,
                store: action.payload,
                data: {
                    ...state.data,
                    idStoreGlobal: action.payload?.idGlobal,
                },
            };
        case "setPharmacy":
            return { ...state, pharmacy: action.payload };
        case "setStoreFromLot":
            return { ...state, storeFromLot: action.payload };
        case "clearStore":
            return {
                ...state,
                store: null,
                data: {
                    ...state.data,
                    idStoreGlobal: "",
                },
            };
        case "setDocumentDate":
            return {
                ...state,
                documentDate: action.payload,
                data: {
                    ...state.data,
                    documentDate: action.payload,
                },
            };
        case "setOrderDate":
            return {
                ...state,
                orderDate: action.payload,
                data: {
                    ...state.data,
                    orderDate: action.payload,
                },
            };
        case "setReason":
            return {
                ...state,
                reason: action.payload,
                data: {
                    ...state.data,
                    idDeductionReasonGlobal: action.payload?.idGlobal,
                },
            };
        case "clearReason":
            return {
                ...state,
                reason: null,
                data: {
                    ...state.data,
                    idDeductionReasonGlobal: "",
                },
            };
        case "setKizDestructionOp":
            return {
                ...state,
                kizDestructionOp: action.payload,
                data: {
                    ...state.data,
                    kizDestructionOp: action.payload,
                },
            };
        case "setKizDestructionType":
            return {
                ...state,
                kizDestructionType: action.payload,
                data: {
                    ...state.data,
                    idKizDestructionType: action.payload?.idGlobal,
                },
            };
        case "clearKizDestructionType":
            return {
                ...state,
                kizDestructionType: null,
                data: {
                    ...state.data,
                    idKizDestructionType: "",
                },
            };
        case "setKizMoveDestructionContractor":
            return {
                ...state,
                kizMoveDestructionContractor: action.payload,
                data: {
                    ...state.data,
                    idKizMoveDestructionContractor: action.payload?.idGlobal,
                },
            };
        case "clearKizMoveDestructionContractor":
            return {
                ...state,
                kizMoveDestructionContractor: null,
                data: {
                    ...state.data,
                    idKizMoveDestructionContractor: "",
                },
            };
        case "setKizDestructionMethod":
            return {
                ...state,
                kizDestructionMethod: action.payload,
                data: {
                    ...state.data,
                    idKizDestructionMethod: action.payload?.idGlobal,
                },
            };
        case "clearKizDestructionMethod":
            return {
                ...state,
                kizDestructionMethod: null,
                data: {
                    ...state.data,
                    idKizDestructionMethod: "",
                },
            };
        case "setKizMoveDestructionDocNum":
            return {
                ...state,
                kizMoveDestructionDocNum: action.payload,
                data: {
                    ...state.data,
                    kizMoveDestructionDocNum: action.payload,
                },
            };
        case "setKizMoveDestructionFiasAoguid":
            const validateFormat = (value: string) => {
                let result = "";
                let counter = 0;
                for (let i = 0; i < value.length; i++) {
                    const char = value[i];
                    if (/^[0-9A-F]+$/.test(char)) {
                        result += char;
                        counter++;
                        if (counter === 8 || counter === 12 || counter === 16 || counter === 20) {
                            result += "-";
                        }
                    } else if (char === "-" && !/^[0-9A-F]+$/.test(value[i + 1])) {
                        result = result.slice(0, -1);
                    }
                }
                return result.slice(0, 36);
            };
            const resultValidation = validateFormat(action.payload);

            return {
                ...state,
                kizMoveDestructionFiasAoguid: resultValidation,
                data: {
                    ...state.data,
                    kizMoveDestructionFiasAoguid: resultValidation,
                },
            };

        case "setComment":
            return {
                ...state,
                comment: action.payload,
                data: {
                    ...state.data,
                    comment: action.payload,
                },
            };
        case "setKizMoveDestructionDocDate":
            return {
                ...state,
                kizMoveDestructionDocDate: action.payload,
                data: {
                    ...state.data,
                    kizMoveDestructionDocDate: action.payload,
                },
            };
        case "setKizDestructionDocNum":
            return {
                ...state,
                kizDestructionDocNum: action.payload,
                data: {
                    ...state.data,
                    kizDestructionDocNum: action.payload,
                },
            };
        case "setKizMoveDestructionActNum":
            return {
                ...state,
                kizMoveDestructionActNum: action.payload,
                data: {
                    ...state.data,
                    kizMoveDestructionActNum: action.payload,
                },
            };
        case "setKizMoveDestructionActDate":
            return {
                ...state,
                kizMoveDestructionActDate: action.payload,
                data: {
                    ...state.data,
                    kizMoveDestructionActDate: action.payload,
                },
            };
        case "setKizDestructionDocDate":
            return {
                ...state,
                kizDestructionDocDate: action.payload,
                data: {
                    ...state.data,
                    kizDestructionDocDate: action.payload,
                },
            };
        case "autoCompleteOnlyCreate":
            return {
                ...state,
                idGlobal: action.payload,
                data: {
                    ...state.data,
                    idGlobal: action.payload,
                },
            };
        case "autoCompleteAll":
            const newData = action.payload;
            return {
                ...state,
                idGlobal: newData?.idGlobal,
                documentDate: newData?.documentDate,
                mnemocode: newData?.mnemocode,
                baseDocumentMnemocode: newData?.mnemocode,
                baseDocumentInfo: newData?.baseDocumentInfo,
                displayName: newData?.displayName,
                kizCancelReason: newData?.kizCancelReason,
                kizMoveDestructionFiasFlat: newData?.kizMoveDestructionFiasFlat,
                kizMoveDestructionFiasHouseguid: newData?.kizMoveDestructionFiasHouseguid,
                kizMoveDestructionFiasAoguid: newData?.kizMoveDestructionFiasAoguid,
                kizMoveDestructionActDate: newData?.kizMoveDestructionActDate,
                kizMoveDestructionActNum: newData?.kizMoveDestructionActNum,
                kizMoveDestructionDocDate: newData?.kizMoveDestructionDocDate,
                kizMoveDestructionDocNum: newData?.kizMoveDestructionDocNum,
                kizMoveDestructionContractor: newData?.kizMoveDestructionContractor,
                kizDestructionDocDate: newData?.kizDestructionDocDate,
                kizDestructionDocNum: newData?.kizDestructionDocNum,
                kizDestructionMethod: newData?.kizDestructionMethod,
                kizDestructionType: newData?.kizDestructionType,
                kizDestructionOp: newData?.kizDestructionOp,
                orderDate: newData?.orderDate,
                orderNumber: newData?.orderNumber,
                reason: newData?.reason,
                store: newData?.store,
                comment: newData?.comment,
                retailSum: newData?.retailCostInfo?.sum,
                retailVatSum: newData?.retailCostInfo?.vatSum,
                retailSumIncVat: newData?.retailCostInfo?.sumIncVat,
                supplierSum: newData?.supplierCostInfo?.sum,
                supplierVatSum: newData?.supplierCostInfo?.sum,
                supplierSumIncVat: newData?.supplierCostInfo?.sum,                
                items: newData?.items.map((el) => {
                    return {
                        idGlobal: el?.idGlobal,
                        idLotGlobal: el?.lot.idGlobal,
                        lotName: el?.lot.displayName,
                        quantity: el?.quantity,

                        retailVat: el?.retailCostInfo?.vat,
                        retailPrice: el?.retailCostInfo?.price,
                        retailVatPrice: el?.retailCostInfo?.vat,
                        retailPriceIncVat: el?.retailCostInfo?.priceIncVat,
                        retailSum: el?.retailCostInfo?.sum,
                        retailVatSum: el?.retailCostInfo?.vat,
                        retailSumIncVat: el?.retailCostInfo?.sumIncVat,
                        retailAdprice: el?.retailCostInfo?.adprice,

                        supplierVat: el?.supplierCostInfo?.vat,
                        supplierPrice: el?.supplierCostInfo?.price,
                        supplierVatPrice: el?.supplierCostInfo?.vat,
                        supplierPriceIncVat: el?.supplierCostInfo?.priceIncVat,
                        supplierSum: el?.supplierCostInfo?.sum,
                        supplierVatSum: el?.supplierCostInfo?.vatSum,
                        supplierSumIncVat: el?.supplierCostInfo?.sumIncVat,
                        supplierAdprice: el?.supplierCostInfo?.adprice,

                        isKiz: el?.isKiz,
                        isKizBox: el?.isKizBox,
                        supplier: el?.supplier,

                        kizCount: el?.kizs?.length ?? 0,
                        dateCreated: el?.dateCreated,
                        isOsu: el?.isOsu,
                        kizOsuDto: el?.kizOsuDto
                    };
                }),
                data: {
                    ...state.data,
                    idGlobal: newData?.idGlobal,
                    documentDate: newData?.documentDate,
                    baseDocumentMnemocode: newData?.mnemocode,
                    baseDocumentInfo: newData?.baseDocumentInfo,
                    comment: newData?.comment,
                    idStoreGlobal: newData?.store?.idGlobal,
                    idDeductionReasonGlobal: newData?.reason?.idGlobal,
                    orderNumber: newData?.orderNumber,
                    orderDate: newData?.orderDate,
                    kizDestructionOp: newData?.kizDestructionOp,
                    idKizDestructionType: newData?.kizDestructionType?.idGlobal,
                    idKizDestructionMethod: newData?.kizDestructionMethod?.idGlobal,
                    kizDestructionDocNum: newData?.kizDestructionDocNum,
                    kizDestructionDocDate: newData?.kizDestructionDocDate,
                    idKizMoveDestructionContractor: newData?.kizMoveDestructionContractor?.idGlobal,
                    kizMoveDestructionDocNum: newData?.kizMoveDestructionDocNum,
                    kizMoveDestructionDocDate: newData?.kizMoveDestructionDocDate,
                    kizMoveDestructionActNum: newData?.kizMoveDestructionActNum,
                    kizMoveDestructionActDate: newData?.kizMoveDestructionActDate,
                    kizMoveDestructionFiasAoguid: newData?.kizMoveDestructionFiasAoguid,
                    kizMoveDestructionFiasHouseguid: newData?.kizMoveDestructionFiasHouseguid,
                    kizMoveDestructionFiasFlat: newData?.kizMoveDestructionFiasFlat,
                    kizCancelReason: newData?.kizCancelReason,
                    items: newData?.items.map((el) => {
                        return {
                            idGlobal: el?.idGlobal,
                            idLotGlobal: el?.lot?.idGlobal,
                            lotName: el?.lot?.displayName,
                            quantity: el?.quantity,
                            retailCostInfo: el?.retailCostInfo,
                            supplierCostInfo: el?.supplierCostInfo,
                            isKiz: el?.isKiz,
                            isKizBox: el?.isKizBox,
                            supplier: el?.supplier,
                            kizCount: el?.kizCount ?? 0,
                            kizs: el.kizs,
                            kizBoxes: el.kizBoxes,
                            dateCreated: el?.dateCreated,
                            isOsu: el?.isOsu,
                            kizOsuDto: el?.kizOsuDto
                        };
                    }),
                },
            };
        case "addItemLot":
            return { ...state, addItemLot: action.payload };
        case "clearItemLot":
            return { ...state, addItemLot: null };

        case "AddViewWithItem":
            return {
                ...state,
                addItemLot: null,
                items: [...state.items, action.payload.viewItem],
                data: {
                    ...state.data,
                    items: [...state.data.items, action.payload.item],
                },
            };
        case "editItemLot":
            return { ...state, editItemLot: action.payload };
        case "clearEditItemLot":
            return { ...state, editItemLot: null };
        case "editViewWithItem":
            return {
                ...state,
                editItemLot: null,
                addItemLot: null,
                items: state.items.map((item) => (item.idLotGlobal === action.payload.viewItem.idLotGlobal ? { ...item, ...action.payload.viewItem } : item)),
                data: {
                    ...state.data,
                    items: state.data.items.map((item) => (item.idLotGlobal === action.payload.item.idLotGlobal ? { ...item, ...action.payload.item } : item)),
                },
            };
        case "deleteViewWithItem":
            const itemsView = state.items.filter((item) => item.idGlobal !== action.payload);
            const itemData = state.data.items.filter((item) => item.idGlobal !== action.payload);
            return {
                ...state,
                items: itemsView,
                data: {
                    ...state.data,
                    items: itemData,
                },
            };
        case "showKizScan":
            return { ...state, showKizScan: action.payload };
        case "addValueKizScan":
            return {
                ...state,
                items: state.items.map((item) => (item.idGlobal === action.payload.idGlobal ? { ...item, ...action.payload } : item)),
                data: {
                    ...state.data,
                    items: state.data.items.map((item) => (item.idGlobal === action.payload.idGlobal ? { ...item, ...action.payload } : item)),
                },
            };
        case "calculate":
            return {
                ...state,
                retailCostInfo: {
                    vatSum: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.vatSum ?? 0), 0).toFixed(2),
                    sumIncVat: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sumIncVat ?? 0), 0).toFixed(2),
                    sum: +state.data.items.reduce((sum, item) => sum + (item.retailCostInfo?.sum ?? 0), 0).toFixed(2),
                },
                supplierCostInfo: {
                    vatSum: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.vatSum ?? 0), 0).toFixed(2),
                    sumIncVat: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sumIncVat ?? 0), 0).toFixed(2),
                    sum: +state.data.items.reduce((sum, item) => sum + (item.supplierCostInfo?.sum ?? 0), 0).toFixed(2),
                },
            };
        case "calculateClear":
            return { ...state, retailCostInfo: initialCalculate, supplierCostInfo: initialCalculate };

        default:
            throw new Error();
    }
}
