import { FC, useContext, useEffect, useState } from "react";
import { TaxTypeCreatorModal } from ".";
import { LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDictionaryCommandsPanel from "../../../components/commandsPanels/DefaultDictionaryCommandsPanel";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { ITaxTypeViewDTO } from "../../../libs/coreapi-dto/dirs/taxType";
import { TaxTypeDataProvider } from "../../../Services/DataProviders/TaxTypeDataProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { checkAccessStatus } from "../../CommonHelperFunctions";

const TaxTypeGrid: FC<IGridProps> = (props) => {
    const appCtx = useAppContext();
    const userContext = useUserContext();
    const taxTypeDataProvider = new TaxTypeDataProvider(appCtx.coreApiService)

    const [data, setData] = useState<ITaxTypeViewDTO[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [totalCount, setTotalCount] = useState(0);
    const [viewState, setViewState] = useState<GridStateType>("view");
    const [gridFilter, dispatchGridFilter] = useGridFilter(CreateSelectorGridFilter);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);

    useEffect(() => {
        setIsSubmitting(true)
        taxTypeDataProvider.getView(gridFilter, (data, totalCount) => {
            setData(data)
            setTotalCount(totalCount)
            data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
            setIsSubmitting(false)
        })
    }, [gridFilter])

    useEffect(() => {
        if (viewState === 'refresh') {
            setIsSubmitting(true)
            taxTypeDataProvider.getView(gridFilter, (data, totalCount) => {
                setData(data)
                setViewState('view')
                setTotalCount(totalCount)
                data.length > 0 ? setLoadingStatus(LoadingStatus.Completed) : setLoadingStatus(LoadingStatus.NoData)
                setIsSubmitting(false)
            })
        }
    }, [viewState])

    return (
        <PluginWrapper>
            {
                checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && <ToolbarWrapper>
                    <DefaultDictionaryCommandsPanel
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        dataProvider={taxTypeDataProvider}
                        creatorModalJsx={TaxTypeCreatorModal}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                    />
                </ToolbarWrapper>
            }

            {
                isSubmitting ? <Spinner /> : <DefaultGrid
                    gridId={props.gridId}
                    data={data}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    totalCount={totalCount}
                    filter={gridFilter}
                    dataProvider={taxTypeDataProvider}
                    plugin={props.plugin}
                    selectedItem={selectedItem}
                    onSelect={(row) => {
                        setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onDoubleClick={(i) => props.onDoubleClick?.(i)}
                    onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                    onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                    onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                    onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                    onEnter={() => setViewState("edit")}
                />
            }
        </PluginWrapper>
    )
}

export default TaxTypeGrid