import { useReducer } from "react";
import { IDatePeriod } from "../../components/datePeriodPicker/datePeriodPicker";
import { DateTime } from "luxon";

export const DefaultColumnsFilters: IGridColumnFilter[] = [];
export const DefaultParamFilters: [] = [];

export const DefaultGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: DefaultColumnsFilters,
};

export const DefaultLotGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    orderDesc: true,
    fieldOrderBy: "perc",
    columnFilters: [
        {
            name: "quantityRem",
            operator: 'More',
            value: 0
        }
    ],
};

export const DefaultGridFilterWithParams: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: DefaultColumnsFilters,
    paramFilter: DefaultParamFilters
};

export const DefaultDatePeriod = {
    startDate: DateTime.now().plus({ days: -7 }),
    endDate: DateTime.now(),
} as IDatePeriod;

export const ModalGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: DefaultColumnsFilters,
};

export const CreateSelectorGridFilter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: "deleted",
            operator: "Eq",
            value: false,
        },
    ]
};

export const CreateFilterNumerator: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: "numerator",
            operator: "LessOrEq",
            value: 1,
            invisible: true,
        },
    ]
};

export const CreateFilterNumeratorDenominator: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: "numerator",
            operator: "LessOrEq",
            value: 1,
            invisible: true,
        },
        {
            name: "denominator",
            operator: "LessOrEq",
            value: 1,
            invisible: true,
        },
    ],
};

export const MovementDateGridFilter = (DateDocument: DateTime, idStoreGlobal: string) => {
    return {
        numberPerPage: 15,
        pageNumber: 1,
        fieldOrderBy: "documentDate",
        orderDesc: true,
        columnFilters: [
            { name: "documentDate", value: DateDocument.toFormat("yyyyMMdd"), operator: "LessOrEq", invisible: true} as IGridColumnFilter,
            { name: "documentState", value: "proc", operator: 'Eq'} as IGridColumnFilter,
            { name: "idStoreGlobalTo", value: idStoreGlobal, operator: 'Eq'} as IGridColumnFilter
        ],
    };
};

export const DefaultDateGridFilter = () => {
    return {
        numberPerPage: 15,
        pageNumber: 1,
        fieldOrderBy: "documentDate",
        orderDesc: true,
        columnFilters: [
            ...(DefaultDatePeriod?.startDate? [{ name: "documentDate", value: DefaultDatePeriod.startDate.toFormat("yyyyMMdd"), operator: "MoreOrEq", invisible: true,} as IGridColumnFilter,]: []),
            ...(DefaultDatePeriod?.endDate ? [{ name: "documentDate", value: DefaultDatePeriod.endDate.toFormat("yyyyMMdd"), operator: "LessOrEq", invisible: true, } as IGridColumnFilter, ] : []),
        ],
    };
};

export type GridFilterAction =
    | { type: "search"; payload: IGridColumnFilter[] }
    | { type: "showDeleted"; payload: boolean }
    | { type: "sort"; payload: string }
    | { type: "refresh" }
    | { type: "changePageNumber"; payload: { pageNumber: number } }
    | { type: "changeNumberPerPage"; payload: { numberPerPage: number } }
    | { type: "sortByFilters"; payload: { gridColumnFilter: IGridColumnFilter[] } }
    | { type: "paramFilter"; payload: { gridParamFilter: any, sortDesc?: string } }
    | { type: "paramSimpleFilter"; payload: { gridParamFilter: any; gridColumnFilter: IGridColumnFilter[] } }
    | { type: "addColumnFilter"; payload: IGridColumnFilter }
    | { type: "addColumnFilters"; payload: IGridColumnFilter[] }
    | { type: "newColumnFilters"; payload: IGridColumnFilter[] }
    | { type: "addCustomWhereExpressionFilter"; payload: string }
    | { type: "newGridFilters"; payload: IGridFilter }
    | { type: "deleteColumnFilter"; payload: string }
    | { type: "deleteColumnFilters"; payload: string[] }
    | { type: "deleteCustomWhereExpressionFilter" }
    | { type: "dropFilter" }
    | { type: "setGridFilter"; payload: IGridFilter };

export function gridFilterHandler(state: IGridFilter, action: GridFilterAction) {
    switch (action.type) {
        case "search":
            let newColumnFilters = action.payload;
            return {
                ...state,
                columnFilters: [...newColumnFilters],
            };

        case "addColumnFilter":
            const columnFilters: IGridColumnFilter[] = state.columnFilters.filter((i) => i.name !== action.payload.name);
            return {
                ...state,
                columnFilters: [...columnFilters, action.payload],
            };
        case "newColumnFilters":
            return {
                ...state,
                columnFilters: [...action.payload],
            };
        case "newGridFilters":
            return {
                ...state,
                ...action.payload
            };
        case "addColumnFilters":
            return {
                ...state,
                columnFilters: [...state.columnFilters, ...action.payload],
            };
        case "deleteColumnFilter":
            return { ...state, columnFilters: [...state.columnFilters.filter((item) => item.name !== action.payload)]};
        case "deleteColumnFilters":
            return { ...state, columnFilters: [...state.columnFilters.filter((item) => !action.payload.includes(item.name))] };
        case "addCustomWhereExpressionFilter":
            return { 
                ...state as IGridCustomWhereExpressionFilter, 
                expression: action.payload
            } as IGridCustomWhereExpressionFilter
        case "deleteCustomWhereExpressionFilter":
            return { ...state as IGridCustomWhereExpressionFilter, expression: ""} as IGridCustomWhereExpressionFilter
        case "sortByFilters":
            return {
                ...state,
                columnFilters: [...action.payload.gridColumnFilter],
            };
        case "paramFilter": {
            if (action.payload.gridParamFilter && !action.payload.sortDesc)
                return {
                    ...state,
                    paramFilter: { ...action.payload.gridParamFilter },                    
                };
            else if (action.payload.gridParamFilter && action.payload.sortDesc) {                
                return {
                    ...state,
                    paramFilter: { ...action.payload.gridParamFilter },       
                    fieldOrderBy: action.payload.sortDesc,
                    orderDesc: true
                };
            }
            else return { ...state };
        }

        case "paramSimpleFilter": {
            return {
                ...state,
                paramFilter: { ...action.payload.gridParamFilter },
                columnFilters: [...action.payload.gridColumnFilter],
            };
        }

        case "showDeleted": {
            if (action.payload) {
                return { ...state, columnFilters: [...state.columnFilters.filter((item) => item.name !== "deleted")] };
            } else {
                const newFilter: IGridColumnFilter = { name: "deleted", value: "false", operator: "Eq" };
                return { ...state, columnFilters: [...state.columnFilters, newFilter] };
            }
        }

        case "changePageNumber": {
            return { ...state, pageNumber: action.payload.pageNumber };
        }

        case "changeNumberPerPage": {
            return { ...state, pageNumber: 1, numberPerPage: action.payload.numberPerPage };
        }

        case "sort": {
            const oldProp = state.fieldOrderBy;
            const newProp = action.payload;

            if (oldProp !== newProp) {
                return { ...state, fieldOrderBy: newProp };
            } else {
                return { ...state, orderDesc: !state.orderDesc };
            }
        }

        case "refresh":
            return { ...state, columnFilters: [...state.columnFilters.filter((item) => item.name === "deleted" || item.invisible === true)] };

        case "dropFilter":
            return { ...DefaultGridFilter, columnFilters: [] };

        case "setGridFilter":
            return { ...action.payload };

        default:
            return { ...state };
    }
}

const useGridFilter = (baseGridFilter?: IGridFilter): [IGridFilter, React.Dispatch<GridFilterAction>] => {
    const [gridFilter, dispatchGridFilter] = useReducer(
        gridFilterHandler,
        baseGridFilter ?? {
            numberPerPage: 15,
            pageNumber: 1,
            columnFilters: DefaultColumnsFilters,
        }
    );
    return [gridFilter, dispatchGridFilter];
};

export default useGridFilter;
