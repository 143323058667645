import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import baseStyles from "../../styles/index.module.scss";
import useGridFilter, { DefaultDateGridFilter } from "../../../system/hooks/useGridFilter";
import { DocumentGridStateType } from "../../../@types/documents";
import { DocumentStatusType, DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { usePluginContext } from "../../../system/providers/plugin";
import { TabsPanel } from "../../../components/tabs";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { ActDisassemblingDataProvider } from "../../../Services/DataProviders/ActDisassemblingDataProvider";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import DefaultDocumentsCommandsPanelV2 from "../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { IActDisassemblingViewDTO } from "../../../libs/coreapi-dto/documents/actDisassembling";
import { Spinner } from "../../../components/spiner/Spinner";
import { UserActionLogModal } from "../../Service/UserActionLog/view/UserActionLogModal";

export const ActDisassemblingPluginView: FC<IGridProps> = (props) => {
    const pluginCtx = usePluginContext();
    const appContext = useAppContext();
    const tabContext = useTabsContext();
    const actDisassemblingDP = new ActDisassemblingDataProvider(appContext.coreApiService);
    const [data, setData] = useState<IActDisassemblingViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [gridFilter, dispatchGridFilter] = useGridFilter(props.baseGridFilter ?? DefaultDateGridFilter());
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess);
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [selectedItemView, setSelectedItemViews] = useState<IActDisassemblingViewDTO>();

    // ModalLog
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    const refreshWithFilter = (filter: IGridFilter) => {
        setIsSubmitting(true);
        actDisassemblingDP.getView(filter, (e, t) => {
            setData(e);
            setTotalCount(t);
            
            if (viewState == "refresh") setViewState("view");
            setLoadingStatus((t === 0) ? LoadingStatus.NoData : LoadingStatus.Completed)
            setIsSubmitting(false);

            if (locker)
                setTimeout(() => locker = false, 500);
        });
    };

    let locker = false;

    useEffect(() => {
        locker = true;
        if (!isSubmitting && props.baseGridFilter)
            refreshWithFilter(props.baseGridFilter);
        else locker = false
    }, [props.baseGridFilter]);

    useEffect(() => {
        if (!locker)
            refreshWithFilter(gridFilter);
    }, [viewState, gridFilter]);

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent();
    }, [tabContext.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    return (
        <PluginWrapper>
            {(!props.extraOptions || props.extraOptions?.showToolbar) && <ToolbarWrapper>
                <DefaultDocumentsCommandsPanelV2
                    selectedItems={[selectedItems,setSelectedItems]}
                    selectedItem={[selectedItem, setSelectedItem]}
                    multipleSelect={[multipleSelect, setMultipleSelect]}
                    gridFilter={[gridFilter, dispatchGridFilter]}
                    plugin="act_disassembling_creator_plugin"
                    documentStatusType={[documentStatusType, setDocumentStatusType]}
                    selectedDocumentStatusType={selectedDocumentState}
                    dataProvider={actDisassemblingDP}
                    pluginSettings={props.plugin}
                    setViewState={(vs) => setViewState(vs)}
                    idTable={IdTableVariant.ActDisassembling}
                    mnemocode={selectedItemView?.mnemocode}
                    items={[
                        {
                            label: "Просмотреть лог пользователя",
                            onClick: () => selectedItem && setShowModalLog(true),
                            disabled: selectedItem ? false : true,
                        },
                    ]}
                    advancedFeatures
                    checkLockingDocuments
                />
            </ToolbarWrapper>}
            <div className={baseStyles.gridWrapper}>
                <div className={baseStyles.masterGrid}>
                    {isSubmitting ? (
                        <Spinner />
                    ) : (
                        <DefaultGrid
                            openWithEnterForEdit={{
                            pluginTabContext:"act_disassembling_creator_plugin",
                            mnemocode:selectedItemView?.mnemocode,
                            selectedDocumentState}}
                            documentStatus
                            gridId={props.gridId}
                            data={data}
                            separator
                            filter={gridFilter}
                            loadingStatus={loadingStatus}
                            dataProvider={actDisassemblingDP}
                            setLoadingStatus={setLoadingStatus}
                            totalCount={totalCount}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            selectedItems={selectedItems}
                            multipleSelect={multipleSelect}
                            onMultipleSelect={(rows) => { 
                                setSelectedItems(rows);
                                rows && props?.onMultipleSelect?.(rows);
                            }}
                            onSelect={(row) => {
                                setSelectedItem(row);
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.disassembling) : pluginCtx.masterGrid.onUnselectEvent();
                                row && props?.onSelect?.(row);
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName });
                            }}
                            onFilterDelete={(i) => {
                                i.propertyName === "documentState" && setDocumentStatusType(undefined);
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } });
                            }}
                        />
                    )}
                </div>
                {(!props.extraOptions || props.extraOptions?.showItems) &&
                    <div className={baseStyles.detailsTabView}>
                        {detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect &&(
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) => {
                                                dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                            }}
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                }
                {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
            </div>
        </PluginWrapper>
    );
};
