import { FC, useContext, useEffect, useReducer } from "react"
import { useTranslation } from "react-i18next";
import GridMultipleSelectorInput from "../../../../components/controls/GridMultipleSelectorInput";
import { DefaultGrid } from "../../../../components/grids/default/defaultGrid";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { StoreSelectorModal } from "../../../Dictionaries/Store";
import { v4 as uuidv4 } from 'uuid'
import { initialState, reducer } from "../state/ConstructorCreate/reducer";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import { SchemeSelectedModal } from "./SchemeSelectedModal";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { SelectedEntityContractorPluginSettings } from "../pluginSettings/SelectedEntityContractorPluginSettings";
import { ConstructorDocumentSelectedModal } from "./ConstructorDocumentSelectedModal";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { ConstructorSchemesDataProvider } from "../../../../Services/DataProviders/ConstructorSchemesDataProvider";
import { BaseModalWindow } from "../../../../components/modalWindows/BaseModalWindow";
import { IRuleViewDTO } from "../../../../libs/coreapi-dto/dirs/constructorSchemes";
import { StoreDataProvider } from "../../../../Services/DataProviders/StoreDataProvider";
import { useForm } from "../../../../system/hooks/useForm";

type Create = 'Create'
type Edit = 'Edit'
interface IConstructorSchemesCreateProps<TOpen> {
    closeAndRefresh: () => void
    close: () => void
    editItem: TOpen extends Create ? null : IRuleViewDTO
    lockFromPermission?: boolean
}

interface IValidator {
    document: string
    store: string
    scheme: string
}

export const ConstructorSchemesCreate = <TOpen extends Create | Edit,>(props: IConstructorSchemesCreateProps<TOpen>) => {
    const appContext = useAppContext()
    const constructorSchemesDP = new ConstructorSchemesDataProvider(appContext.coreApiService)
    const storeDP = new StoreDataProvider(appContext.coreApiService);

    const [state, dispatch] = useReducer(reducer, initialState)
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const { t } = useTranslation();
    const errorsT = (value: string) => t('errors.' + value);

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            document: {
                required: {
                    value: state.kizConstructorDoc ? false : true,
                    message: errorsT("required"),
                },
            },
            store: {
                required: {
                    value: state.store ? false : true,
                    message: errorsT("required"),
                },
            },
            scheme: {
                required: {
                    value: state.scheme ? false : true,
                    message: errorsT("required"),
                },
            },
        },

    });

    const filterRule = () => {
        let filter = {
            numberPerPage: 1,
            pageNumber: 1,
            columnFilters: [
                {
                    name: "idGlobal",
                    value: props.editItem?.idGlobal,
                    operator: "Eq"
                }
            ],
        } as IViewBody
        return filter;
    }

    useEffect(() => {
        if (props.editItem) {
            let filter = filterRule();
            constructorSchemesDP.viewRule(filter, (data)=>{
                let rule = data[0];
                if (props.editItem)
                constructorSchemesDP.contractors(props.editItem.idGlobal, (contractors)=>{
                    dispatch({
                        type: 'onInit', payload: {
                            idDoc: rule.idKizConstructorDocument,
                            docEntity: { idGlobal: rule.idKizConstructorDocument, displayName: rule.displayName },
                            idTable: rule.idTable,
                            idStore: rule.idStoreGlobal as string,
                            storeEntity: { idGlobal: rule.idStoreGlobal as string, displayName: rule.storeName },
                            idScheme: rule.idSchemeGlobal as string,
                            schemeEntity: {idGlobal: rule.idSchemeGlobal as string, displayName: rule.schemeName as string},
                            contractors: contractors ? contractors.map(x=>{ return {idGlobal: x.idContractorGlobal, displayName: x.contractorName} }) : []
                        }
                    })
                },()=>{})

            })
            
        }
    }, [])

    function clearDocument()
    {
        dispatch({
            type: 'onInit', payload: {
                idDoc: "",
                docEntity: null,
                idTable: null,
                idStore: "",
                storeEntity: null,
                idScheme: "",
                schemeEntity: null,
                contractors: []
            }
        })
    }

    function clearScheme()
    {
        dispatch({ type: 'changeScheme', payload: { id: "", entity: null } });
    }

    function clearStore()
    {
        dispatch({ type: 'changeStore', payload: { id: "", entity: null } })
    }

    function clearContractors()
    {
        dispatch({ type: 'setContractors', payload: { id: [], entities: [] } })
    }

    return (
        <BaseModalWindow
            ok={{
                onClick: () => {
                    if (isValid())
                        if (props.editItem) {
                            constructorSchemesDP.updateRule(props.editItem?.idGlobal, state.createRule, () => props.closeAndRefresh())
                        } else {
                            constructorSchemesDP.createRule(state.createRule, () => props.closeAndRefresh())
                        }
                },
                disabled: props.lockFromPermission
            }}
            cancel={{ onClick: () => props.close() }}
            header= {props.editItem ? 'Редактирование' : 'Создание'} 
        >
            {
                <div>
                    <GridSelectorInput
                        id='idSchemeDocument'
                        selectorModalJsx={ConstructorDocumentSelectedModal}
                        label={'Документ'}
                        selectedEntity={state.kizConstructorDoc}
                        onSelect={(entity: IEntitySimpleDTO) => {
                            clearScheme();
                            constructorSchemesDP.viewDocById(entity.idGlobal, (e) => {
                                dispatch({ type: 'changeKizConstructorDocument', payload: { id: entity.idGlobal, idTable: e[0].idTable, entity } })
                            })
                            setErrors({ ...errors, store: undefined })
                        }}
                        onClear={()=>{ clearDocument() }}
                        onFocus={() => setErrors({ ...errors, document: undefined })}
                        error={errors.document}
                        disabled={props.lockFromPermission}
                    />
                    {
                        state.createRule.idKizConstructorDocument &&
                        <GridSelectorInput
                            id='idStoreGlobal'
                            selectorModalJsx={StoreSelectorModal}
                            label={'Склад'}
                            selectedEntity={state.store}
                            onSelect={(entity: IEntitySimpleDTO) => {
                                dispatch({ type: 'changeStore', payload: { id: entity.idGlobal, entity } })
                                setErrors({ ...errors, scheme: undefined })
                            }}
                            onClear={()=>{ clearStore() }}
                            onFocus={() => setErrors({ ...errors, store: undefined })}
                            error={errors.store}
                            disabled={props.lockFromPermission}
                        />
                    }
                    {
                        state.createRule.idStoreGlobal && state.idTable &&
                        <GridSelectorInput
                            id={`idScheme-${uuidv4()}`}
                            label={'Схема'}
                            idTable={state.idTable}
                            selectorModalJsx={SchemeSelectedModal}
                            selectedEntity={state.scheme}
                            onSelect={(entity: IEntitySimpleDTO) => dispatch({ type: 'changeScheme', payload: { id: entity.idGlobal, entity } })}
                            onClear={()=>{ clearScheme() }}
                            onFocus={() => setErrors({ ...errors, scheme: undefined })}
                            error={errors.scheme}
                            disabled={props.lockFromPermission}
                        />
                    }
                    {
                        state.createRule.idSchemeGlobal &&
                        <GridMultipleSelectorInput
                            id={`idContractor-${uuidv4()}`}
                            selectorModalJsx={ContractorSelectorModal}
                            label={'Контрагенты'}
                            onSelect={(entity: IEntitySimpleDTO[]) => dispatch({ type: 'setContractors', payload: { id: entity.map(x => x.idGlobal), entities: entity } })}
                            onClear={()=>{ clearContractors() }}
                            disabled={props.lockFromPermission}
                        />
                    }
                    {
                        state.createRule.idSchemeGlobal &&
                        <DefaultGrid
                            gridId={uuidv4()}
                            data={state.contractors}
                            filter={gridFilter}
                            totalCount={state.contractors.length}
                            plugin={SelectedEntityContractorPluginSettings}
                            selectedItem={undefined}
                            onSelect={(row) => { }}
                            onSort={(i) => { }}
                            onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                            onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                            onNumberPerPageChange={(n) => { dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } }) }}
                            hiddenPagination={undefined}
                        />
                    }
                </div>
            }
        </BaseModalWindow >
    )
}

