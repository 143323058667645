import { ContentType } from "../../../libs/fetchApiDistributor/contentTypes";
import { ILotByGoodsViewDTO } from "../../coreapi-dto/accounting/lot";
import BaseCoreApiRequest from "../baseCoreApiRequest";
import { CoreApiResponse, IRequestEventHandler } from "../common";

export class BaseDirRequest<TViewDTO, TDTO>
    extends BaseCoreApiRequest
    implements IDictionaryRequest<TViewDTO, TDTO> {
    protected _routePrefix: string
    protected _eventHandler: IRequestEventHandler | null | undefined;
    protected readonly _token: string

    constructor(baseUrl: string, routePrefix: string, token: string, eventHandler?: IRequestEventHandler | null) {
        super(baseUrl)
        this._routePrefix = routePrefix
        this._eventHandler = eventHandler
        this._token = token;
    }

    getView(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<TViewDTO>>) => void, report?: (value) => void) {
        this.post<IViewWrapper<TViewDTO>>(`${this._baseUrl}/${this._routePrefix}/view`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                    report?.({ name: `${this._routePrefix}/view`, time: e.time })
                }
            }


        })
    }

    getViewByGoods(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<ILotByGoodsViewDTO>>) => void, report?: (value) => void) {
        this.post<IViewWrapper<ILotByGoodsViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewByGoods`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IViewWrapper<ILotByGoodsViewDTO>>) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                    report?.({ name: `${this._routePrefix}/ViewByGoods`, time: e.time })
                }
            }

        })
    }

    getViewLotsByGoods(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<TViewDTO>>) => void, report?: (value) => void) {
        this.post<IViewWrapper<TViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewByGoods`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e: CoreApiResponse<IViewWrapper<TViewDTO>>) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                    report?.({ name: `${this._routePrefix}/ViewByGoods`, time: e.time })
                }
            }

        })
    }

    getById(id: string, callback?: (e: CoreApiResponse<TDTO>) => void) {
        this.get<TDTO>(`${this._baseUrl}/${this._routePrefix}/${id}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }

    getByIdAndScalingRatio(id: string, idScalingRatio: string ,callback?: (e: CoreApiResponse<TDTO>) => void) {
        this.get<TDTO>(`${this._baseUrl}/${this._routePrefix}/${id}/${idScalingRatio}`, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }
        })
    }

    create(body: TDTO, callback: (e: CoreApiResponse<string>) => void) {
        this.post<string>(`${this._baseUrl}/${this._routePrefix}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    update(idGlobal: string, body: TDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.put(`${this._baseUrl}/${this._routePrefix}/${idGlobal}`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    markDelete(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.delete(`${this._baseUrl}/${this._routePrefix}/${idGlobal}`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    restore(idGlobal: string, callback: (e: CoreApiResponse<null>) => void) {
        super.patch(`${this._baseUrl}/${this._routePrefix}/${idGlobal}/Restore`, null, [this.getBearerHeader(this._token)], (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }
}