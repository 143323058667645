import { useState, FC, useContext, useEffect } from "react";
import { TreeViewSelectorModal } from "../../../components/modalWindows/TreeViewSelectorModalWindow";
import { TreeViewPanel } from "../../../components/treeViewPanel/TreeViewPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import useGridFilter, { CreateSelectorGridFilter } from "../../../system/hooks/useGridFilter";
import { GoodsGroupDataProvider } from "../../../Services/DataProviders/GoodsGroupDataProvider";

const GoodsGroupSelectorModal: FC<ITreeViewSelectorModalProps> = (props) => {
    const appContext = useAppContext()
    const [gridFilter,] = useGridFilter(CreateSelectorGridFilter)
    const goodsGroupDP = new GoodsGroupDataProvider(appContext.coreApiService)
    const [contractorGroupViewState, setGoodsGroupViewState] = useState<TreeViewStateType>("view")
    const [selectedFolder, setSelectedFolder] = useState<ITreeViewFolder>()
    const [folders, setFolders] = useState<ITreeViewFolder[]>()

    useEffect(() => {
        if (contractorGroupViewState === 'return' || contractorGroupViewState === 'view') {
            goodsGroupDP.getView(gridFilter, (entities) => {
                let tmpList: ITreeViewFolder[] = []
                entities.forEach(item => {
                    tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                });
                setFolders(tmpList)
                setGoodsGroupViewState('view')
            })
        }
    }, [selectedFolder,contractorGroupViewState])

    return <TreeViewSelectorModal
        gridDisplayName={'Группы номенклатур'}
        ok={{ onClick: () => props.ok?.(selectedFolder) }}
        cancel={{ onClick: () => props.cancel?.() }}
    >
        {
            <div>
                <TreeViewPanel
                    folders={folders}
                    selectedFolder={selectedFolder}
                    onSelectFolder={(folder) => setSelectedFolder({ ...folder })}
                    onExpandFolder={(folder, callback) => {
                        goodsGroupDP.getViewById(folder.folderId, gridFilter,
                            (entities) => {
                                let tmpList: ITreeViewFolder[] = []
                                entities.forEach(item => {
                                    tmpList.push({ folderId: item.idGlobal, displayName: item.name, childGroups: item.childGroups })
                                });
                                callback(tmpList)
                            })
                    }}
                    groupDataProvider = {goodsGroupDP} 
                    setGroupViewState={(value) => setGoodsGroupViewState(value)}
                    pluginSettings={props.pluginSettings}
                />
                    
            </div>
        }
    </TreeViewSelectorModal>
}

export default GoodsGroupSelectorModal