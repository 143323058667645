import { ILegalEntityDTO, ILegalEntityViewDTO } from "../../libs/coreapi-dto/dirs/legalEntity";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class LegalEntityDataProvider extends DictionaryDataProvider<ILegalEntityViewDTO, ILegalEntityDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<IDictionaryRequest<ILegalEntityViewDTO, ILegalEntityDTO>> {
        return this._coreApiService.legalEntityRequest
    }
}