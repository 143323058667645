import React, { useContext, useReducer, useState } from "react"
import { FC } from "react"
import { DocumentType } from "../../../@types/enumsGlobal"
import useGridFilter, { GridFilterAction } from "../../hooks/useGridFilter"
import { defaultGridContextState, gridContextReducerHandler } from "./GridContext"
import { defaultTreeViewContextState, treeViewContextReducerHandler } from "./TreeViewContext"

interface ITreeViewContext {
    visiblePanel: boolean
    selectedFolder?: ITreeViewFolder
    onVisiblePanelEvent: (visible: boolean) => void
    onSelectEvent: (value: ITreeViewFolder) => void
    onUnselectEvent: () => void
}
interface IGridContext {
    selectedItem?: ISelectGridRow | undefined
    document?: DocumentType
    view?: boolean
    onSelectEvent: (value: ISelectGridRow, document: DocumentType) => void
    onUnselectEvent: () => void
    refreshState: (value: boolean) => void
}
interface ILotContext {
    gridFilter: IGridFilter
    dispatchGridFilter: (action: GridFilterAction) => void
    setSelectedItem: (value: IGridRow | undefined) => void
    selectedItem?: IGridRow | undefined
}
interface IPluginContext {
    masterGrid: IGridContext
    treeView: ITreeViewContext
    lots: ILotContext
    // detailsGrids?: IGridContext[]
}
const defaultPluginContext: IPluginContext = {
    masterGrid: {
        onSelectEvent: (value, document) => { },
        onUnselectEvent: () => { },
        refreshState: (value) => {}
    },
    treeView: {
        visiblePanel: false,
        onVisiblePanelEvent: (visible) => { },
        onSelectEvent: (value) => { },
        onUnselectEvent: () => { }
    },
    lots: {
        gridFilter: {
            numberPerPage: 10,
            pageNumber: 1,
            columnFilters: []
        },
        dispatchGridFilter: (value: GridFilterAction) => { },
        setSelectedItem: (value: ISelectGridRow | undefined) => { }
    }
}

export const PluginContext = React.createContext<IPluginContext>(defaultPluginContext)
export const usePluginContext = () => useContext(PluginContext)

interface IPluginProviderProps {
    plugin?: IPluginSettings
}
export const PluginProvider: FC<IPluginProviderProps> = (props) => {
    const [masterGridState, dispatchMasterGrid] = useReducer(gridContextReducerHandler, defaultGridContextState)
    const [treeViewState, dispatchTreeView] = useReducer(treeViewContextReducerHandler, defaultTreeViewContextState)

    //lot
    const [selectedLot, setSelectedLot] = useState<IGridRow | undefined>()
    const [gridFilter, dispatchGridFilter] = useGridFilter()
    return <>
        <PluginContext.Provider value={{
            masterGrid: {
                selectedItem: masterGridState.selectedItem,
                document: masterGridState.document,
                view: masterGridState.view,
                onSelectEvent: (value, document) => dispatchMasterGrid({ type: "select", payload: { item: value, document: document } }),
                onUnselectEvent: () => dispatchMasterGrid({ type: "unselect" }),
                refreshState: (value) => dispatchMasterGrid({ type: 'refresh', payload: value }),
            },
            treeView: {
                visiblePanel: treeViewState.visiblePanel,
                onVisiblePanelEvent: (visible) => dispatchTreeView({ type: 'visible', payload: visible }),
                selectedFolder: treeViewState.selectedFolder,
                onSelectEvent: (value) => dispatchTreeView({ type: 'select', payload: value }),
                onUnselectEvent: () => dispatchTreeView({ type: "unselect" })
            },
            lots: {
                selectedItem: selectedLot,
                gridFilter: gridFilter,
                dispatchGridFilter: (action: GridFilterAction) => dispatchGridFilter(action),
                setSelectedItem: (value: IGridRow | undefined) => setSelectedLot(value),

            }
        }}>
            {props.children}
        </PluginContext.Provider>
    </>
}