import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";

export interface ICreateItem {
    idGlobal: string
    listIdFile: string[]
    idStoreGlobal: string
    idPricingModelGlobal: string
    idMappingModel?: string
}

interface IImportedRemainsStatusList {
    readonly isSucceed: boolean,
    readonly idImportedRemainsGlobal: string,
    readonly errorsList: string[]
}

export const createRemainRequest = async (
    createItem: ICreateItem,
    setIsSubmitting: (value: boolean) => void,
    setIdImport: (value: string) => void,
    setIdRemains: (value: string) => void

) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }
    setIsSubmitting(true)
    let importRemains = await axios.post<IImportedRemainsStatusList[]>(
        generateRequestUrl('ImportFiles/ImportRemains/Csv/ImportRemainsCsv'),
        createItem,
        header
    )
    if (importRemains.data.filter(x => x.isSucceed === false).length > 0) {
        const findErr = importRemains.data.find(x => x.isSucceed === false) as IImportedRemainsStatusList
        renderGlobalAlert({ variant: 'error', statusCode: importRemains.status, title: JSON.stringify(findErr.errorsList) })
    } else {
        if (importRemains) {
            await axios.post<string>(
                generateRequestUrl(`ImportedRemains/${importRemains.data[0].idImportedRemainsGlobal}`),
                null,
                header
            ).then((e) => {
                setIdImport(importRemains.data[0].idImportedRemainsGlobal)
                setIsSubmitting(false)
                setIdRemains(e.data)
            }).catch((reason) => {
                renderGlobalAlert(
                    {
                      variant: 'error',
                      statusCode: reason?.response?.status ?? 500,
                      title: "Ошибка импорта ввода остатаков",
                      detail: reason?.response?.data?.title,
                      instance: "Импорт остатаков"
                    }
                  )
            })
        }
    }

}