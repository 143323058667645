import { getDocumentItemColumns, getDocumentItemCostInfoColumns } from "../../../../system/providers/plugin/defaultPluginSettings"

let index: number = 0;

const MovementItemsGridPluginSettings: IPluginSettings = {
    name: 'Перемещение товара',
    mnemocode: 'movement_items_plugin',
    permission: 'Movement',
    columns: [
        ...getDocumentItemColumns(index, (i) => index = i),
        {
            order: index++,
            propertyName: 'goodsName',
            propertyType: 'string',
            displayName: 'Наименование товара',
            visibility: true,
            width: 100
        }, {
            order: index++,
            propertyName: 'quantity',
            propertyType: 'number',
            displayName: 'Кол-во',
            visibility: true,
            width: 100,
            changeable: true
        }, {
        //     order: index++,
        //     propertyName: 'retailVat',
        //     propertyType: 'string',
        //     displayName: 'Ставка НДС',
        //     visibility: true,
        //     width: 100
        // }, {
        //     order: index++,
        //     propertyName: 'fromInternalBarcode',
        //     propertyType: 'string',
        //     displayName: 'fromInternalBarcode',
        //     visibility: false,
        //     width: 100
        // }, {
        //     order: index++,
        //     propertyName: 'fromProductMargin',
        //     propertyType: 'string',
        //     displayName: 'fromProductMargin',
        //     visibility: false,
        //     width: 100
        // }, {
        //    order: index++,
        //    propertyName: 'toInternalBarcode',
        //    propertyType: 'string',
        //    displayName: 'toInternalBarcode',
        //    visibility: false,
        //    width: 100
        //}, {
        //    order: index++,
        //    propertyName: 'toProductMargin',
        //    propertyType: 'number',
        //    displayName: 'toProductMargin',
        //    visibility: false,
        //    width: 100
        //},
        //{
            order: index++,
            propertyName: 'supplierFrom',
            propertyType: 'string',
            displayName: 'От кого',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'supplierTo',
            propertyType: 'string',
            displayName: 'Кому',
            visibility: true,
            width: 100
        },
        {
            order: index++,
            propertyName: 'supplier',
            propertyType: 'string',
            displayName: 'Поставщик',
            visibility: true,
            width: 100
        },
        // {
        //     order: index++,
        //     propertyName: 'isGnvls',
        //     propertyType: 'boolean',
        //     displayName: 'ЖНВЛС',
        //     visibility: false,
        //     width: 100
        // },
        {
            order:  index++,
            propertyName: 'isKiz',
            propertyType: 'boolean',
            displayName: 'Ввод КИЗ',
            visibility: true,
            width: 150
        },
        ...getDocumentItemCostInfoColumns(index, (i) => index = i),
    ]
}



export default MovementItemsGridPluginSettings