import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";


export interface ICreateItem {
    idGlobal: string
    listIdFile: string[]
    idStoreGlobal: string
    idPricingModelGlobal: string
    idMappingModel?: string
}

interface IImportedRemainsStatusList {
    readonly isSucceed: boolean,
    readonly idImportedRemainsGlobal: string,
    readonly errorsList: string[]
}

export const addKizRequest = async (
    createItem: ICreateItem,
    idRemains: string,
    setIsSubmitting: (value: boolean) => void
) => {
    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateRequestUrl = (url: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/` + url
    }

    setIsSubmitting(true)
    let importRemains = await axios.post<IImportedRemainsStatusList[]>(
        generateRequestUrl('ImportFiles/ImportRemains/Csv/ImportRemainsCsv'),
        createItem,
        header
    )
    if (importRemains.data[0].isSucceed) {
        await axios.post<boolean>(
            generateRequestUrl('ImportedRemains/Kizs'),
            {
                idImportedRemainsGlobal: importRemains.data[0].idImportedRemainsGlobal,
                idImportRemainsGlobal: idRemains
            },
            header
        ).then((e) => {
            setIsSubmitting(false)
            renderGlobalAlert({ variant: 'success', statusCode: 200, title: 'Успешно' })
        }).catch((reason) => {
            renderGlobalAlert(
                {
                  variant: 'error',
                  statusCode: reason?.response?.status ?? 500,
                  title: "Ошибка импорта кизов ввода остатаков",
                  detail: reason?.response?.data?.title,
                  instance: "Импорт остатаков"
                }
              )
        })
    } else {
        renderGlobalAlert({ variant: 'error', statusCode: 500, title: JSON.stringify(importRemains.data[0].errorsList) })
    }



}