export const KizInventoryItemCorrectGridPluginSettings: IPluginSettings = {
    name: 'Корректирующие схемы',
    mnemocode: 'kiz_inventory_item_correct_plugin',
    permission: 'KizInventory',
    columns: [{
        order: 0,
        propertyName: 'idGlobal',
        propertyType: 'string',
        displayName: 'IdGlobal',
        visibility: false,
        width: 157
    }, 
    {
        order: 1,
        propertyName: "idDocumentGlobal",
        propertyType: "string",
        displayName: "idDocumentGlobal",
        visibility: false,
        width: 200,
    },
    {
        order: 2,
        propertyName: "kizInventoryName",
        propertyType: "string",
        displayName: "Киз инвенторизация",
        visibility: false,
        width: 200,
    },
    {
        order: 3,
        propertyName: "sgtin",
        propertyType: "string",
        displayName: "Sgtin (КИЗ)",
        visibility: true,
        width: 200,
    },
    {
        order: 4,
        propertyName: "dateCreated",
        propertyType: "date",
        displayName: "Дата создания",
        visibility: false,
        width: 100,
    },
    {
        order: 5,
        propertyName: "sendMdlp",
        propertyType: "boolean",
        displayName: "Отправить в мдлп",
        visibility: true,
        width: 50,
    },
    {
        order: 6,
        propertyName: "scheme",
        propertyType: "string",
        displayName: "Схема",
        visibility: true,
        width: 50,
    },
    {
        order: 7,
        propertyName: "gtin",
        propertyType: "string",
        displayName: "Gtin",
        visibility: true,
        width: 150,
    },
    {
        order: 8,
        propertyName: "goodsName",
        propertyType: "string",
        displayName: "Наименование",
        visibility: true,
        width: 200,
    },
    {
        order: 9,
        propertyName: "internalBarcode",
        propertyType: "string",
        displayName: "Внутренний ШК",
        visibility: true,
        width: 200,
    },
    {
        order: 10,
        propertyName: "lastDocNum",
        propertyType: "string",
        displayName: "Последний документ",
        visibility: true,
        width: 200,
    },
    {
        order: 11,
        propertyName: "lastDocDate",
        propertyType: "date",
        displayName: "Дата последнего документа",
        visibility: true,
        width: 200,
    },
],
}

export default KizInventoryItemCorrectGridPluginSettings