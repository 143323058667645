import { FC } from "react";
import { BaseModalWindow } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IProcessModalWindowProps {
    name: string;
    header?: string;
    process: () => void;
    cancel: () => void;
}
export const ProcessModalWindow: FC<IProcessModalWindowProps> = (props) => {
    return (
        <BaseModalWindow header={`${props.header}`}
            ok={{ onClick: () => { props.process(); }, title: 'Подтвердить' }}
            cancel={{ onClick: () => { props.cancel(); }, title: 'Закрыть' }}
            modalWindowClassName={styles.modalWindowConfirm}
        >
            Вы действительно хотите провести документ {props.name}?
        </BaseModalWindow>
    );
};
