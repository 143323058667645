import { FC } from "react";
import { BaseModalWindow, IButton } from "./BaseModalWindow";
import styles from './styles/BaseModalWindow.module.scss';

interface IDeleteModalWindowProps {
    message: string
    ok: IButton
    cancel: IButton
    primary?: boolean
    className?:string
}
export const MessageModalWindow: FC<IDeleteModalWindowProps> = (props) => {
    return (
        <BaseModalWindow
            header='Сообщение'
            ok={props.ok}
            cancel={props.cancel}
            modalWindowClassName={styles.modalWindowConfirm}
            primary={props.primary}
            className={props.className}
        >
            {props.message}
        </BaseModalWindow>
    );
};
