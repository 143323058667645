import { FC, useEffect, useState } from "react";
import styles from "./styles/index.module.scss";
import { LotSelectorInput } from ".";
import { SearchOption } from "../../@types/enumsGlobal";
import { ILotDTO } from "../../libs/coreapi-dto/accounting/lot";
import { IOption } from "../selects/select";
import FlexRow from "../controls/FlexRow";
import FlexColumn from "../controls/FlexColumn";
import { KizBarcodeButton } from "../buttons/iconButtons/kiz/KizBarcodeCollection";
import { SelectControl } from "../controls/SelectControl";
import { SettingsButtonModal } from "../buttons/iconButtons/settingsButton/SettingsButtonModal";
import { GridFilterAction } from "../../system/hooks/useGridFilter";
import { ISearchQueryInfo } from "./lotSelectorInput";

interface IKizButton {
    disabled?: boolean;
    onClick: () => void;
}

interface ILotSelectorPanelProps {
    disabled?: boolean;
    barcodeScan?: boolean;
    onlyLotRemain?: boolean;
    kizButton?: IKizButton;
    searchOption?: SearchOption;
    gridFilter?: IGridFilter;
    dispatchGridFilter?: (value: GridFilterAction) => void;
    onSelect?: (value: ILotDTO, searchQuery?: ISearchQueryInfo) => void;
    setScanData?: (value) => void
    setSearchOption?: (value) => void
}

const LotSelectorPanel: FC<ILotSelectorPanelProps> = (props) => {
    
    const [searchOption, setSearchOption] = useState<SearchOption>(props.searchOption ?? SearchOption.goodsName);
    const searchOptionList: IOption[] = [
        { value: SearchOption.kizOrKizBox, displayName: "КИЗ/Короб" },
        { value: SearchOption.barcode, displayName: "Штрих-код" },
        { value: SearchOption.goodsName, displayName: "Товар" },
        { value: SearchOption.goodsCodeBylot, displayName: "Код товара" },
    ];

    useEffect(() => {
        props.setSearchOption?.(searchOption);
    },[searchOption])

    return (
        <>
            <FlexRow>
                {props.kizButton && (
                    <FlexColumn className={styles.kiz}>
                        <KizBarcodeButton sizeVariant="big" disabled={props.kizButton?.disabled} onClick={() => props.kizButton?.onClick?.()} />
                    </FlexColumn>
                )}
                <FlexColumn className={styles.lot}>
                    <LotSelectorInput
                        disabled={props.disabled}
                        barcodeScan={props.barcodeScan}
                        onlyLotRemain={props.onlyLotRemain}
                        gridFilter={props.gridFilter}
                        dispatchGridFilter={props.dispatchGridFilter}
                        searchOption={searchOption}
                        onSelect={(value, searchData) => props.onSelect?.(value, searchData)}
                        setScanData={props.setScanData}
                    />
                </FlexColumn>
                <FlexColumn className={styles.options}>
                    <SelectControl
                        disabled={props.disabled}
                        barcodeScan={props.barcodeScan}
                        options={searchOptionList}
                        value={searchOptionList.find((item) => item.value === searchOption)}
                        onSelect={(value) => setSearchOption(value.value)}
                    />
                </FlexColumn>
                <FlexColumn className={styles.settings}>
                    <SettingsButtonModal disabled={props.disabled} />
                </FlexColumn>
            </FlexRow>
        </>
    );
};

export default LotSelectorPanel;
