import { FC, useEffect, useState } from "react";
import GridSelectorInput from "../../../../../components/controls/GridSelectorInput";
import { LotSelectorPanel } from "../../../../../components/lotSelector";
import { IdTableVariant } from "../../../../../@types/enumsGlobal";
import GridWrapper from "../../../../../components/controls/GridWrapper";
import { NumberInput, TextAreaInput, TextInput } from "../../../../../components/controls/inputs";
import { useLotRemain } from "../../../../../components/lotSelector/useLotRemain";
import useOperativeReserve from "../../../../../components/lotSelector/useOperativeReserve";
import { BaseModalWindow } from "../../../../../components/modalWindows/BaseModalWindow";
import modalStyles from "../../../../../components/modalWindows/styles/BaseModalWindow.module.scss";
import { ILotDTO } from "../../../../../libs/coreapi-dto/accounting/lot";
import { IInternetOrderItemEditDTO } from "../../../../../libs/coreapi-dto/accounting/internetOrderItem";
import { ICreateItem } from "../InternetOrderUpdateForm";
import { useForm } from "../../../../../system/hooks/useForm";
import { DateTime } from "luxon";
import useGridFilter from "../../../../../system/hooks/useGridFilter";
import { GoodsDataProvider } from "../../../../../Services/DataProviders/GoodsDataProvider";
import { useAppContext } from "../../../../../system/providers/appContextProvider";

interface IInternetOrderUpdateItemModal {
    idDocumentGlobal: string;
    idDocumentItemGlobal: string;
    lot: ILotDTO;
    internetOrderItemEdit: IInternetOrderItemEditDTO;
    ok: (createItem: ICreateItem) => void;
    cancel: () => void;
}

interface IValidator {
    quantity: number;
}

export const InternetOrderUpdateItemModal: FC<IInternetOrderUpdateItemModal> = (props) => {
    const appContext = useAppContext();
    const [createItem, setCreateItem] = useState<IInternetOrderItemEditDTO>(props.internetOrderItemEdit);
    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const [isGnvls, setIsGnvls] = useState<boolean>(false);
    const round = (num) => {
        const x = Math.pow(10, 2);
        return Math.round(num * x) / x;
    };

    useEffect(() => {
        if (props.lot.goods) {
            goodsDP.getById(props.lot.goods.idGlobal, (e) => {
                setIsGnvls(e.gnvls.important ?? false);
            });
        }
    }, []);

    const buildDocumentItem = (): ICreateItem => {
        return {
            item: {
                ...createItem,
                idLotExternalGlobal: props.lot.idGlobal,
                idGlobal: props.idDocumentItemGlobal,
                supplierCostInfo: {
                    ...props.lot.supplierCostInfo,
                    vat: props.lot.supplierCostInfo.vat,
                    sum: round(props.lot.supplierCostInfo.price * createItem.quantity),
                    vatSum: round(props.lot.supplierCostInfo.vatPrice * createItem.quantity),
                    sumIncVat: round(props.lot.supplierCostInfo.priceIncVat * createItem.quantity),
                },
                retailCostInfo: {
                    ...props.lot.retailCostInfo,
                    vat: props.lot.retailCostInfo.vat,
                    sum: round(props.lot.retailCostInfo.price * createItem.quantity),
                    vatSum: round(props.lot.retailCostInfo.vatPrice * createItem.quantity),
                    sumIncVat: round(props.lot.retailCostInfo.priceIncVat * createItem.quantity),
                },
                producerName: props.lot.producer.displayName,
                isGnvls: isGnvls,
                isKiz: props.lot.isKiz,
                dateCreated: DateTime.now()
            },
            displayItem: {
                idGlobal: props.idDocumentItemGlobal,
                idLotExternalGlobal: props.lot.idGlobal,
                goodsName: createItem.goodsName,
                lotName: props.lot.lotName,
                scalingRatioDisplayName: props.lot.scalingRatio.displayName,
                quantity: createItem.quantity,
                price: createItem.price,
                discount: props.lot.retailCostInfo.priceIncVat - createItem.price,

                supplierVat: props.lot.supplierCostInfo.vat,
                supplierPrice: props.lot.supplierCostInfo.price,
                supplierVatPrice: props.lot.supplierCostInfo.vatPrice,
                supplierPriceIncVat: props.lot.supplierCostInfo.priceIncVat,
                supplierSum: round(props.lot.supplierCostInfo.price * createItem.quantity),
                supplierVatSum: round(props.lot.supplierCostInfo.vatPrice * createItem.quantity),
                supplierSumIncVat: round(props.lot.supplierCostInfo.priceIncVat * createItem.quantity),
                supplierAdprice: props.lot.supplierCostInfo.adprice,

                retailVat: props.lot.retailCostInfo.vat,
                retailPrice: props.lot.retailCostInfo.price,
                retailVatPrice: props.lot.retailCostInfo.vatPrice,
                retailPriceIncVat: props.lot.retailCostInfo.priceIncVat,
                retailSum: round(props.lot.retailCostInfo.price * createItem.quantity),
                retailVatSum: round(props.lot.retailCostInfo.vatPrice * createItem.quantity),
                retailSumIncVat: round(props.lot.retailCostInfo.priceIncVat * createItem.quantity),
                retailAdprice: props.lot.retailCostInfo.adprice,

                producer: props.lot.producer.displayName,
                isGnvls: isGnvls,                
                kizCount: 0,
                isKiz: props.lot.isKiz ?? false,
                dateCreated: DateTime.now()
            },
            filter: {
                idItem: createItem.idGlobal,
                quantityMax: props.lot.quantityRem + props.lot.quantityRes,
                quantityMin: 0,
            },
        };
    };

    const remain = useLotRemain(props.lot.idGlobal, props.idDocumentGlobal);
    const operativeReserve = useOperativeReserve();

    const onOk = () => {
        operativeReserve.send(
            {
                idLot: props.lot.idGlobal,
                idTable: IdTableVariant.InternetOrder,
                idDocument: props.idDocumentGlobal,
                idDocumentItem: props.idDocumentItemGlobal,
                quantity: createItem.quantity,
            },
            () => props.ok(buildDocumentItem())
        );
    };

    const onCancel = () => {
        operativeReserve.send(
            {
                idLot: props.lot.idGlobal,
                idTable: IdTableVariant.InternetOrder,
                idDocument: props.idDocumentGlobal,
                idDocumentItem: props.idDocumentItemGlobal,
                quantity: props.internetOrderItemEdit.quantity,
            },
            props.cancel
        );
    };

    const { isValid, errors, setErrors } = useForm<IValidator>({
        validations: {
            quantity: {
                required: {
                    value: createItem.quantity && createItem.quantity > 0 ? false : true,
                    message: "Значение должно быть больше 0",
                },
            },
        },
    });

    return (
        <BaseModalWindow
            header="Изменение позиции"
            ok={{
                onClick: () => {
                    if (isValid()) {
                        onOk();
                    }
                },
            }}
            cancel={{ onClick: props.cancel }}
            modalWindowClassName={modalStyles.modalWindowInternetOrder}
            footerStyle="confirm"
        >
        <TextAreaInput label="Товар" value={props.lot.goods.displayName} disabled />
        <TextInput label="Партия" disabled value={props.lot.docNum} />
        <GridWrapper cols={3}>
            <TextInput label="Единица измерения" value={props.lot.scalingRatio.displayName} disabled />
            <NumberInput label="Остаток" value={remain} disabled />
            <NumberInput
                required
                onFocus={() => setErrors({ ...errors, quantity: undefined })}
                error={errors.quantity}
                keyBan={true}
                label="Количество"
                value={createItem.quantity}
                min={1}
                max={remain}
                onChange={(value) => setCreateItem({ ...createItem, quantity: value })}
            />
            </GridWrapper>
    </BaseModalWindow>
    );
};