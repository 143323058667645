import { DefaultGrid } from "../../../../components/grids/default/defaultGrid"
import { v4 as uuidv4 } from 'uuid';
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { GosContractItemsPluginSettings } from "../pluginSettings/gosContractItemsPluginSettings";
import { useEffect, useReducer } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../../system/providers/plugin";
import { initialState, reducer } from "../state/GosContractItems/reducer";
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider";

export const GosContractItemsPluginView = () => {
    const appCtx = useAppContext();
    const pluginCtx = usePluginContext();
    const gosContractDP = new GosContractDataProvider(appCtx.coreApiService);

    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        if (pluginCtx.masterGrid.selectedItem) {
            gosContractDP.getItemsView(pluginCtx.masterGrid.selectedItem?.idGlobal as string, gridFilter, (entities, totalCount) => {
                dispatch({ type: 'setItems', payload: entities })
            })
        }
    }, [pluginCtx.masterGrid.selectedItem?.idGlobal, gridFilter])


    return <DefaultGrid
        gridId={uuidv4()}
        data={state.items}
        totalCount={state.items.length}
        filter={gridFilter}
        //dataProvider={gosContractDP}
        plugin={GosContractItemsPluginSettings}
        selectedItem={undefined}
        onSelect={(row) => {
            //setSelectedItem(row)
            //props.onSelect?.(row)
        }}
        onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
        onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
        onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
        onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
    />
}