import SysOptionsDetailPluginSettings from "./components/detailGrid/SysOptionsDetailPluginSettings"

const SysOptionsSourceTypePluginSettings: IPluginSettings = {
    name: 'Источник финансирования',
    mnemocode: 'sys_options_source_type_plugin',
    permission: 'SysOptions',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        },
        {
            order: 1,
            propertyName: 'sourceType',
            propertyType: 'number',
            displayName: 'Тип источника',
            visibility: true,
            width: 100
        },
    ],
    detailsPlugins: [],
}

export default SysOptionsSourceTypePluginSettings
