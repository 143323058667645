import { IInoviceUpdateDTO, IInvoiceCreateDTO, IInvoiceGetDTO, IInvoiceViewDTO } from "../../coreapi-dto/documents/invoice";
import { IInvoiceItemViewDTO } from "../../coreapi-dto/documents/invoiceItem";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDocRequestV2 } from "./baseDocRequestV2";
import { ContentType } from "../../fetchApiDistributor/contentTypes";

class InvoiceRequest extends BaseDocRequestV2<IInvoiceViewDTO, IInvoiceGetDTO, IInvoiceCreateDTO, IInoviceUpdateDTO, IInvoiceItemViewDTO, IInvoiceGetDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler) {
        super(baseUrl, "invoice", token, eventHandler)
    }

    create(body: IInvoiceCreateDTO, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IInoviceUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }

    updateEditKizs(idDocumentGlobal: string, idDocumentItemGlobal: string, body: IInoviceUpdateDTO, callback: (e: CoreApiResponse<null>) => void) {
        this.put(`${this._baseUrl}/${this._routePrefix}/${idDocumentGlobal}/${idDocumentItemGlobal}/UpdateEditKizs`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                this._eventHandler.onGetResponse(e)
            }
            callback?.(e);
        })
    }

    getViewProc(body: IViewBody, callback?: (e: CoreApiResponse<IViewWrapper<IInvoiceViewDTO>>,) => void) {
        this.post<IViewWrapper<IInvoiceViewDTO>>(`${this._baseUrl}/${this._routePrefix}/ViewProc`, body, this.concatHeaders([this.getBearerHeader(this._token)], [ContentType.ApplicationJson]), (e) => {
            if (this._eventHandler) {
                if (e.respType === "isFailed") {
                    this._eventHandler.onGetResponse(e);
                } else {
                    callback?.(e);
                }
            }

        })
    }
}
export default InvoiceRequest