import {forwardRef, FC, useEffect, useRef, PropsWithChildren, CSSProperties, useState} from 'react';
import ReactDOM from 'react-dom';
import styles from './styles/BaseModalWindow.module.scss';
import {Button} from '../buttons/button';
import classNames from 'classnames';
import {CrossButton, ExpandButton, TurnButton} from '../buttons/iconButtons/other/PanelCollection';
import {Dropdown} from 'antd';
import {useTabsContext} from '../../system/providers/tabsProvider';
import {ReactPortal} from './ReactPortal';

interface IOverlayProps {
  style?: CSSProperties;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Overlay: FC<IOverlayProps> = (props) => {
  return (
    <div
      className={styles.modalOverlay}
      style={props.style}
      onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
    >
      {props.children}
    </div>
  );
};

interface IModalWindowProps {
  className?: string;
  style?: CSSProperties;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  baseStyle: string;
}
export const ModalWindow = forwardRef<HTMLDivElement, PropsWithChildren<IModalWindowProps>>((props, ref) => {
  return (
    <Overlay style={props.style} onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}>
      <div tabIndex={-1} ref={ref} className={classNames(props.baseStyle, props.className)}>
        {props.children}
      </div>
    </Overlay>
  );
});

export interface IButton {
  onClick: () => void;
  title?: string;
  disabled?: boolean;
  sendRequestSpinner?: boolean;
  sendRequestGlobal?: boolean;
  hidden?: boolean;
}

declare type OverlayFunc = () => React.ReactElement;

export interface IModalDropDownButton extends IButton {
  overlay: React.ReactElement | OverlayFunc;
}

interface IObjectProps {
  header: string;
  ok: IButton;
  cancel: IButton;
  modalWindowClassName?: string;
  style?: CSSProperties;
  primary?: boolean;
  footerStyle?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  otherAction?: IButton;
  fullScreen?: boolean;
  dropDownButton?: IModalDropDownButton;
  autoFocus?: boolean;
  className?:string
}
export const BaseModalWindow: FC<IObjectProps> = (props) => {
  const okButtonRef = useRef<HTMLButtonElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const otherActionButtonRef = useRef<HTMLButtonElement>(null);
  const modalWindowRef = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [autoFocus, setAutoFocus] = useState(props.autoFocus ?? true);

  useEffect(() => {
    setAutoFocus(props?.autoFocus ?? true);
  }, [props.autoFocus]);

  useEffect(() => {
    let mw = modalWindowRef.current as HTMLDivElement;
    if (autoFocus && mw) {
      mw.focus();
      mw.onkeydown = (e) => {
        switch (e.key) {
          case 'Enter': {
            okButtonRef.current?.click();
            break;
          }
          case 'Escape': {
            cancelButtonRef.current?.click();
            break;
          }
        }
      };
    }
  }, [autoFocus]);

  return <>

    <ReactPortal wrapperId="react-portal-modal-container">
      
      <ModalWindow
        baseStyle={fullScreen ? styles.fullScreenModal : styles.modalEditValues}
        ref={modalWindowRef}
        className={props.modalWindowClassName}
        style={props.style}
        onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
      >
        <header>
          <div className={styles.headerContainer}>
            {props.fullScreen ? (
              <>
                <span className={styles.title}>{props.header}</span>
                <div className={styles.fullScreen}>
                  {fullScreen ? (
                    <TurnButton
                      sizeVariant="mini"
                      onClick={() => {
                        setFullScreen(false);
                      }}
                    />
                  ) : (
                    <ExpandButton
                      sizeVariant="mini"
                      onClick={() => {
                        setFullScreen(true);
                      }}
                    />
                  )}
                  <CrossButton sizeVariant="mini" onClick={() => props.cancel.onClick()} />
                </div>
              </>
            ) : (
              <>
                <span className={styles.title}>{props.header}</span>
                <button className={styles.closeButton} onClick={() => props.cancel.onClick()}></button>
              </>
            )}
          </div>
        </header>
        <main className={styles.main}>
          <div className={styles.mainContainer}>{props.children}</div>
        </main>
        <footer>
          <div className={classNames(styles.footer, props.className)}>
            {!props.cancel.hidden && (
              <div className={styles.footerButton}>
                <Button
                  ref={cancelButtonRef}
                  variant={props.primary ? 'primary' : 'danger'}
                  onClick={() => {
                    props.cancel.onClick();
                  }}
                >
                  {props.cancel?.title ?? 'Отмена'}
                </Button>
              </div>
            )}
            <div className={styles.footerButton}>
              {!props.ok?.hidden && (
                <Button
                  ref={okButtonRef}
                  variant="primary"
                  disabled={props.ok?.disabled}
                  onClick={() => {
                    props?.ok.onClick();
                  }}
                  isLoadingSpinner={props.ok?.sendRequestSpinner}
                >
                  {props.ok?.title ?? 'OK'}
                </Button>
              )}
              {props.otherAction && (
                <Button
                  ref={otherActionButtonRef}
                  variant="outsecondary"
                  disabled={props.otherAction?.disabled}
                  onClick={() => {
                    props?.otherAction?.onClick?.();
                  }}
                >
                  {props.otherAction?.title}
                </Button>
              )}
              {props.dropDownButton && (
                <Dropdown
                  overlay={props.dropDownButton?.overlay}
                  trigger={['click']}
                  disabled={props.dropDownButton?.disabled}
                >
                  <Button
                    className={styles.button}
                    onClick={() => {}}
                    disabled={props.dropDownButton?.disabled}
                    variant="primary"
                  >
                    {props.dropDownButton?.title}
                  </Button>
                </Dropdown>
              )}
            </div>
          </div>
        </footer>
      </ModalWindow>
    </ReactPortal>
    </>
};
