export const ActReturnToContractorItemFormPluginSettings: IPluginSettings = {
  name: 'Позиции',
  mnemocode: 'act_return_to_contractor_create_plugin',
  permission: 'ActReturnToContractor',
  columns: [
    {
      order: 0,
      propertyName: 'idGlobal',
      propertyType: 'string',
      displayName: 'idGlobal',
      visibility: false,
      width: 150,
    },
    {
      order: 1,
      propertyName: 'idDocumentGlobal',
      propertyType: 'string',
      displayName: 'idDocumentGlobal',
      visibility: false,
      width: 150,
    },
    {
      order: 2,
      propertyName: 'goodsName',
      propertyType: 'string',
      displayName: 'Товар',
      visibility: true,
      width: 150,
    },
    {
      order: 3,
      propertyName: 'quantity',
      propertyType: 'number',
      displayName: 'Кол-во',
      visibility: true,
      width: 150,
      changeable: true,
    },
    {
      order: 4,
      propertyName: 'retailVat',
      propertyType: 'number',
      displayName: 'Ставка НДС, розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 5,
      propertyName: 'retailPrice',
      propertyType: 'number',
      displayName: 'Цена без НДС розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 6,
      propertyName: 'retailVatPrice',
      propertyType: 'number',
      displayName: 'НДС розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 7,
      propertyName: 'retailSum',
      propertyType: 'number',
      displayName: 'Сумма без НДС розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 8,
      propertyName: 'retailVatSum',
      propertyType: 'number',
      displayName: 'Сумма НДС, розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 9,
      propertyName: 'retailSumIncVat',
      propertyType: 'number',
      displayName: 'Сумма розн. с НДС',
      visibility: true,
      width: 150,
    },
    {
      order: 10,
      propertyName: 'retailAdprice',
      propertyType: 'number',
      displayName: 'Наценка розн.',
      visibility: true,
      width: 150,
    },
    {
      order: 11,
      propertyName: 'supplierVat',
      propertyType: 'number',
      displayName: 'Ставка НДC, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 12,
      propertyName: 'supplierPrice',
      propertyType: 'number',
      displayName: 'Цена, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 13,
      propertyName: 'supplierVatPrice',
      propertyType: 'number',
      displayName: 'НДС, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 14,
      propertyName: 'supplierPriceIncVat',
      propertyType: 'number',
      displayName: 'Цена с НДС, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 15,
      propertyName: 'supplierSum',
      propertyType: 'number',
      displayName: 'Сумма пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 16,
      propertyName: 'supplierVatSum',
      propertyType: 'number',
      displayName: 'Сумма НДС, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 17,
      propertyName: 'supplierSumIncVat',
      propertyType: 'number',
      displayName: 'Сумма с НДС, пост.',
      visibility: true,
      width: 150,
    },
    {
      order: 18,
      propertyName: 'supplierAdprice',
      propertyType: 'number',
      displayName: 'Наценка поставщика',
      visibility: true,
      width: 150,
    },
    {
      order: 19,
      propertyName: 'scalingRatioName',
      propertyType: 'string',
      displayName: 'Ед.измерения',
      visibility: true,
      width: 150,
    },
    {
      order: 20,
      propertyName: 'contractorPricePerUnit',
      propertyType: 'number',
      displayName: 'contractorPricePerUnit',
      visibility: false,
      width: 150,
    },
    {
      order: 21,
      propertyName: 'isKiz',
      propertyType: 'boolean',
      displayName: 'Ввод КИЗ',
      visibility: true,
      width: 150,
    },
    {
      order: 22,
      propertyName: 'kizCount',
      propertyType: 'number',
      displayName: 'Кол-во отсканированных кизов',
      visibility: true,
      width: 150
    },
    {
      order: 23,
      propertyName: 'isOsu',
      propertyType: 'boolean',
      displayName: 'Признак воды',
      visibility: true,
      width: 150
    },
    // {
    //   order: 23,
    //   propertyName: 'isGnvls',
    //   propertyType: "boolean",
    //   displayName: "ЖНВЛС",
    //   visibility: true,
    //   width: 100,
    // },
    // {
    //   order: 24,
    //   propertyName: 'supplier',
    //   propertyType: 'string',
    //   displayName: 'Поставщик',
    //   visibility: true,
    //   width: 150
    // }
  ],
  detailsPlugins: [
  ]
};
