import { FC, useEffect, useState } from "react";
import { BaseModalWindow } from "./BaseModalWindow";
import { IOption } from "../selects/select";
import GridWrapper from "../controls/GridWrapper";
import { SelectControl } from "../controls/SelectControl";
import { Button } from "../buttons/button";
import { DateInput, NumberInput, TextInput } from "../controls/inputs";
import { DateTime } from "luxon";
import {} from "../../Services/Extensions/DateTime";
import baseStyles from "./styles/BaseModalWindow.module.scss";
import styles from "./styles/SearchModalWidnow.module.scss";
import { defaultColumn, defaultOperator } from "../../@types/enumsGlobal";
import { useUserContext } from "../../system/providers/userContextProvider";

type PropertyType = 'string' | 'number' | 'boolean' | 'date' | 'datetime' | 'decimal';

export interface ISearchColumn {
    propertyName: string;
    propertyType: PropertyType;
    displayName: string;
}

interface ISearchModalWindowProps {
    defaultFilters?: IGridColumnFilter[];
    columns: ISearchColumn[];
    search?: (columnFilters: IGridColumnFilter[]) => void;
    cancel?: () => void;
}
export const SearchModalWindow: FC<ISearchModalWindowProps> = (props) => {
    const userCtx = useUserContext();

    const [columnFilters, setColumnFilters] = useState<IGridColumnFilter[]>(props.defaultFilters ?? ([] as IGridColumnFilter[]));
    const defaultBooleanOption = { displayName: "Выберите", value: "", hidden: true } as IOption;
    const columnOptions: IOption[] = props.columns.map((item) => {
        return { ...item, value: item.propertyName };
    });
    const [column, setColumn] = useState<ISearchColumn>(props.columns[0]);

    const [operatorOptions, setOperatorOptions] = useState<IOption[]>([] as IOption[]);
    const [operator, setOperator] = useState<OperatorType>();

    const [query, setQuery] = useState<any>();

    const [date, setDate] = useState<DateTime>();

    var getFormattedFilterDate = (date: DateTime) => {
        setDate(date);
        return date.toF3DateFormat(userCtx);
    };

    useEffect(() => {
        const allOperators = [
            { displayName: "Содержит", value: "Like", propertyTypes: ["string"] },
            { displayName: "Равно", value: "Eq", propertyTypes: ["string", "number", "boolean", "date"] },
            { displayName: "Не равно", value: "NotEq", propertyTypes: ["string", "number", "boolean", "date"] },
            { displayName: "Больше", value: "More", propertyTypes: ["number", "date"] },
            { displayName: "Больше либо равно", value: "MoreOrEq", propertyTypes: ["number", "date"] },
            { displayName: "Меньше", value: "Less", propertyTypes: ["number", "date"] },
            { displayName: "Меньше либо равно", value: "LessOrEq", propertyTypes: ["number", "date"] },
        ];
        const operatorList: IOption[] = allOperators.filter((item) => item.propertyTypes.indexOf(column.propertyType) >= 0);
        setOperatorOptions([...operatorList]);
        setQuery("");
    }, [column]);

    useEffect(() => {
        if (operatorOptions?.length > 0) setOperator(operatorOptions[0].value);
    }, [operatorOptions]);

    return (
        <BaseModalWindow
            header="Поиск"
            ok={{ onClick: () => props.search?.(columnFilters), title: "Найти" }}
            modalWindowClassName={baseStyles.modalWindowSearch}
            cancel={{ onClick: () => props.cancel?.(), title: "Закрыть" }}
        >
            <GridWrapper cols={4}>
                <SelectControl
                    label="Поле"
                    options={columnOptions}
                    onSelect={(item) => {
                        setColumn({ ...(item as any) });
                        if (item.propertyType === "boolean") setQuery(defaultBooleanOption);
                    }}
                />
                <SelectControl label="Оператор" options={operatorOptions} onSelect={(item) => setOperator(item.value)} />

                {
                    // string
                    column.propertyType === "string" && <TextInput className={baseStyles.textEditClearMargin} label="Искать" onChange={(item) => setQuery(item)} />
                }
                {
                    // number
                    column.propertyType === "number" && <NumberInput className={baseStyles.textEditClearMargin} label="Искать" onChange={(item) => setQuery(item)} />
                }
                {
                    // boolean
                    column.propertyType === "boolean" && (
                        <SelectControl
                            label="Искать"
                            onSelect={(item) => {
                                setQuery(item.value);
                            }}
                            value={query}
                            defaultOption={defaultBooleanOption}
                            options={[defaultBooleanOption, { displayName: "Да", value: true }, { displayName: "Нет", value: false }]}
                        />
                    )
                }
                {
                    // date
                    column.propertyType === "date" && <DateInput isModal={true} label="Дата" value={date} onBlur={(item) => item && setQuery(getFormattedFilterDate(item))} />
                }

                {
                    <Button
                        className={styles.addButton}
                        disabled={query !== undefined && query !== "" ? false : true}
                        variant="primary"
                        onClick={() => {
                            setColumnFilters([
                                ...columnFilters,
                                {
                                    name: column.propertyName,
                                    value: operator === "Like" ? `%${query}%` : `${query}`,
                                    operator: operator as OperatorType,
                                    invisible: false,
                                },
                            ]);
                        }}
                    >
                        Добавить
                    </Button>
                }
            </GridWrapper>

            {columnFilters.map((item, index) => {
                const nameFilters = props.columns?.find(el => el.propertyName === item.name)?.displayName;
                return (
                    <div className={styles.wrapperFilter}>
                        <GridWrapper key={index} cols={4}>
                            <div> {nameFilters ?? defaultColumn[item.name]} </div>
                            <div> {defaultOperator[item.operator]} </div>
                            <div> {item.value} </div>
                            <Button variant="danger" disabled={item.invisible ? true : false} onClick={() => setColumnFilters([...columnFilters.filter((i) => i.name !== item.name)])}>
                                Удалить
                            </Button>
                        </GridWrapper>
                    </div>
                );
            })}
        </BaseModalWindow>
    );
};
