import React, { PropsWithChildren, useEffect, useState } from "react"
import { checkLockStatus } from "../../Business/CommonHelperFunctions"
import { IEntitySimpleDTO } from "../../libs/coreapi-dto/@types/common"
import { GridFilterAction } from "../../system/hooks/useGridFilter"
import { useUserContext } from "../../system/providers/userContextProvider"
import { DeleteModalWindow } from "../modalWindows/DeleteModalWindow"
import { RestoreModalWindow } from "../modalWindows/RestoreModalWindow"
import { SearchModalWindow } from "../modalWindows/SearchModalWindow"
import DictionaryCommandsPanel from "./DictionaryCommandsPanel"
import { usePluginContext } from "../../system/providers/plugin/PluginContextProvider"
import { ICommandsPanelCheckboxProps } from "./BaseCommandsPanel"
import { IPanelEvent } from "./PositionEditPanel"
import { useTabsContext } from "../../system/providers/tabsProvider"

interface IData {
    name: string
    value: IEntitySimpleDTO
}
  export type TabsType = 'nomenclature';

interface IDefaultDictionaryPanelProps<TEntityViewDTO, TEntityDTO> {
    selectedItem?: [IGridRow | undefined, React.Dispatch<React.SetStateAction<IGridRow | undefined>>]
    gridFilter?: [IGridFilter, React.Dispatch<GridFilterAction>]
    data?: IData
    dataProvider: IDictionaryDataProvider<TEntityViewDTO, TEntityDTO>
    creatorModalJsx: any
    pluginSettings: IPluginSettings
    setViewState?: (viewState: GridStateType) => void
    tabsType?: TabsType[];
}

const DefaultDictionaryCommandsPanel = <TEntityViewDTO, TEntityDTO>(props: PropsWithChildren<IDefaultDictionaryPanelProps<TEntityViewDTO, TEntityDTO>>) => {
    const [viewState, setViewState] = useState<GridStateType>("view")
    const [modalWindow, setModalWindow] = useState(<></>)
    const userContext = useUserContext()
    const pluginContext = usePluginContext();
    const tabsContext = useTabsContext();

    const lockFromPermission = checkLockStatus(props.pluginSettings.permission as IPermission, userContext.userPermission);

    useEffect(() => {
        if (viewState === 'refresh') {
            refreshDocument()
        } else {
            renderModalWindow()
        }
    }, [viewState])

    function refreshDocument() {
        props.setViewState?.('refresh')
        setViewState('view')
    }

    function renderModalWindow() {
        switch (viewState) {
            case "create": renderCreator(); break;
            case "edit": renderEditor(); break;
            case "copy": renderCopyEditor(); break;
            case "delete": renderDeleteWindow(); break;
            case "restore": renderRestoreWindow(); break;
            case "search": renderSearchWindow(); break;
            default: setModalWindow(<></>)
        }
    }
    function renderCreator() {
        setModalWindow(
            <props.creatorModalJsx
                variant='create'
                data={{ [props.data?.name ?? '']: props.data?.value }}
                save={(model) => props.dataProvider?.create(model, () => setViewState("refresh"))}
                cancel={() => setViewState("return")}
            />
        )
    }
    function renderEditor() {
        props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, (entity) => {
            setModalWindow(
                <props.creatorModalJsx variant='edit' data={entity}
                    save={(model) => props.dataProvider?.update(props.selectedItem?.[0]?.idGlobal as string, model, () => setViewState("refresh"))}
                    cancel={() => setViewState("return")}
                    lockFromPermission={lockFromPermission}
                />
            )
        })
    }

    function renderCopyEditor() {
        props.dataProvider?.getById(props.selectedItem?.[0]?.idGlobal as string, entity => {
            const isStore = props.pluginSettings.mnemocode === 'store_plugin';
            const copyEntities = {
                ...entity,
                name: '',
                mnemocode: '',
                mnemocodeEsfi: 0,
            }
            setModalWindow(
                <props.creatorModalJsx variant='copy' data={isStore ? copyEntities : entity}
                    save={(model) => { props.dataProvider?.create(model, () => setViewState("refresh")); }}
                    cancel={() => setViewState("return")}
                />
            )
        })
    }

    function renderDeleteWindow() {
        setModalWindow(
            <DeleteModalWindow
                name={props.selectedItem?.[0]?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                delete={{
                    onClick: () => {
                        props.dataProvider?.markDelete(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("refresh"))
                        props.selectedItem?.[1](undefined)
                    },
                    title: 'Удалить'
                }}
            />
        )
    }

    function renderRestoreWindow() {
        setModalWindow(
            <RestoreModalWindow
                name={props.selectedItem?.[0]?.cells["name"] as string}
                cancel={{ onClick: () => setViewState('return') }}
                restore={{
                    onClick: () => {
                        props.dataProvider?.restore(props.selectedItem?.[0]?.idGlobal as string, () => setViewState("refresh"))
                        props.selectedItem?.[1](undefined)
                    },
                    title: 'Восстановить'
                }}
            />
        )
    }


    function renderSearchWindow() {
        const visibleColumns = props.pluginSettings?.columns.filter((x) => x.visibility === true) ?? []
        setModalWindow(
            <SearchModalWindow
                defaultFilters={props.gridFilter?.[0].columnFilters} columns={visibleColumns}
                cancel={() => setViewState("return")}
                search={(columnFilters) => {
                    props.gridFilter?.[1]({ type: 'search', payload: columnFilters })
                    setViewState("refresh")
                }}
            />
        )
    }
    function getShowDeleted():ICommandsPanelCheckboxProps {
        if (tabsContext.currentTab?.title === "Номенклатура") {
            return {
                disabled: pluginContext.masterGrid.selectedItem === undefined,
                onChecked: (e) => {
                    props.gridFilter?.[1]({ type: "showDeleted", payload: e })
                    setViewState("filter")
                },
                tabsType: props.tabsType,
                parentSelectedItem: pluginContext.masterGrid.selectedItem
            };
        } else {
            return {
                disabled: false,
                onChecked: (e) => {
                    props.gridFilter?.[1]({ type: "showDeleted", payload: e })
                    setViewState("filter")
                },
            };
        }
    }
    function getPrintButtonHandler(): IPanelEvent {
        if (props?.pluginSettings?.permission === 'Unit') {
            return {
                onClick: () => { },
                disabled: true
            }
        } else {
            return {
                onClick: () => window.alert("print")
            };
        }
    }

    return <>
        <DictionaryCommandsPanel
            add={{
                onClick: () => setViewState("create"),
            }}
            edit={{
                onClick: () => setViewState("edit"),
                disabled: props.selectedItem?.[0] === undefined || props.selectedItem?.[0]?.isDeleted 
            }}
            copy={{
                onClick: () => setViewState("copy"),
                disabled: props.selectedItem?.[0] === undefined
            }}
            print={getPrintButtonHandler()}
            delete={{
                onClick: () => setViewState("delete"),
                disabled: props.selectedItem?.[0] === undefined || props.selectedItem?.[0]?.isDeleted
            }}
            restore={{
                onClick: () => setViewState("restore"),
                disabled: props.selectedItem?.[0] === undefined || !props.selectedItem?.[0]?.isDeleted
            }}
            search={{ onClick: () => setViewState("search") }}
            refresh={{
                onClick: () => {
                    props.gridFilter?.[1]({ type: "refresh" })
                }
            }}
            showDeleted={getShowDeleted()}
            permission={props.pluginSettings.permission}
        />

        {modalWindow}

    </>
}

export default DefaultDictionaryCommandsPanel