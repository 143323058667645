import { FC } from "react"
import { DictionaryFileInput } from "../../../../components/controls/dictionaryFileInput"
import GridSelectorInput from "../../../../components/controls/GridSelectorInput"
import GridWrapper from "../../../../components/controls/GridWrapper"
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common"
import { PricingModelSelectorModal } from "../../../Dictionaries/PricingModel"
import { StoreSelectorModal } from "../../../Dictionaries/Store"
import { IFileWrapper, IValidator } from "../view/importRemainContent"
import { v4 as uuidv4 } from 'uuid';
import { importCsvFileRequest } from "../request/importCsvFileRequest"
import { renameFile } from "../function/functions"
import { DictionaryDropZone } from "../../../../components/controls/DropZone/dictionaryDropZone"
import { Delete3Icon } from "../../../../libs/corporate/icons/outlined/action/MainCollection"
import styles from '../styles/importRemainContent.module.scss'
import { ImportRemainsSelectorModal } from "../../../Documents/ImportRemains/ImportRemainsSelectorModal"
import { Button } from "../../../../components/buttons/button"
import lgContentStyles from '../styles/lgWidthContent.module.scss'
import useGridFilter from "../../../../system/hooks/useGridFilter"

interface ILgWidthContentProps {
    errors: Partial<Record<keyof IValidator, string>>
    setErrors: (value) => void
    store: IEntitySimpleDTO
    setStore: (value: IEntitySimpleDTO) => void
    pricingModel: IEntitySimpleDTO
    setPricingModel: (value: IEntitySimpleDTO) => void
    remain: IFileWrapper,
    setRemain: (value: IFileWrapper) => void,
    remainSubmitting: boolean
    setRemainSubmitting: (value: boolean) => void
    admissibleExtension: string;
    supplier: IFileWrapper
    setSupplier: (value: IFileWrapper) => void
    supplierSubmitting: boolean
    setSupplierSubmitting: (value: boolean) => void
    selectedRemain: IEntitySimpleDTO
    setIdRemains: (value: string | null) => void
    kiz: IFileWrapper
    setKiz: (value: IFileWrapper) => void
    kizSubmitting: boolean
    setKizSubmitting: (value: boolean) => void
    isSubmitting: boolean
    importRemains: () => void
    importKiz: () => void
    lockFromPermission?: boolean
}

const filter: IGridFilter = {
    numberPerPage: 15,
    pageNumber: 1,
    columnFilters: [
        {
            name: 'documentState',
            operator: 'Eq',
            value: 'draft'
        }
    ]
}

export const LgWidthContent: FC<ILgWidthContentProps> = ({
    errors, store, setStore, setErrors, pricingModel, setPricingModel, remain,
    setRemain, setRemainSubmitting, remainSubmitting, admissibleExtension,
    supplier, setSupplier, setSupplierSubmitting, supplierSubmitting, selectedRemain,
    setIdRemains, kiz, kizSubmitting, setKiz, setKizSubmitting, isSubmitting, importRemains,
    importKiz, lockFromPermission
}) => {
    const [gridFilter] = useGridFilter(filter)

    return <>
        <GridSelectorInput
            selectorModalJsx={StoreSelectorModal}
            id={`store-${uuidv4()}`}
            label='Склад'
            selectedEntity={store}
            error={errors.store}
            onSelect={(entity) => { setStore(entity) }}
            onClear={() => { setStore({} as IEntitySimpleDTO) }}
            onFocus={() => { setErrors({ ...errors, store: undefined }) }}
            disabled={lockFromPermission}
        />
        <GridSelectorInput
            selectorModalJsx={PricingModelSelectorModal}
            label='Модель ценообразования'
            id={`pricing-${uuidv4()}`}
            selectedEntity={pricingModel}
            onSelect={(entity) => { setPricingModel(entity) }}
            error={errors.pricingModel}
            onClear={() => { setPricingModel({} as IEntitySimpleDTO) }}
            onFocus={() => { setErrors({ ...errors, pricingModel: undefined }) }}
            disabled={lockFromPermission}
        />
        <GridWrapper cols={19}>
            <DictionaryFileInput
                multiple={false}
                files={remain.file}
                setFiles={(files: IFile[]) => {
                    const id = uuidv4();
                    setRemain({ file: files, id: id });
                    importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                        setRemainSubmitting(value)
                    })
                }}
                label='Файл остатков'
                admissibleExtension={admissibleExtension}
                className={styles.fileInput}
                disabled={(selectedRemain?.idGlobal ? true : false) || lockFromPermission}
            />
            <Delete3Icon className={styles.clearButton} onClick={() => { setRemain({ file: [], id: null }) }}/>
            
        </GridWrapper>
        <DictionaryDropZone
            files={remain.file}
            setFiles={(files: IFile[]) => {
                const id = uuidv4()
                setRemain({ file: files, id: id })
                importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                    setRemainSubmitting(value)
                })
            }}
            isSubmitting={remainSubmitting}
            fileNamePattern='remains'
            admissibleExtension={admissibleExtension}
            multiple={false}
            fileWrapperClassName={styles.dropZone}
            wrapperStyle={styles.dropZoneWrapper}
            disabled={(selectedRemain?.idGlobal ? true : false) || lockFromPermission}
        />

        <GridWrapper cols={19}>
            <DictionaryFileInput
                multiple={false}
                files={supplier.file}
                setFiles={(files: IFile[]) => {
                    const id = uuidv4()
                    setSupplier({ file: files, id: id })
                    importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                        setSupplierSubmitting(value)
                    })
                }}
                label='Связка поставщиков'
                admissibleExtension={admissibleExtension}
                className={styles.fileInput}
                disabled={(selectedRemain?.idGlobal ? true : false) || lockFromPermission}
            />
            <Delete3Icon  className={styles.clearButton} onClick={() => setSupplier({ file: [], id: null })}/>           
        </GridWrapper>

        <DictionaryDropZone
            files={supplier.file}
            setFiles={(files: IFile[]) => {
                const id = uuidv4();
                setSupplier({ file: files, id: id });
                importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                    setSupplierSubmitting(value)
                })
            }}
            admissibleExtension={admissibleExtension}
            isSubmitting={supplierSubmitting}
            multiple={false}
            fileWrapperClassName={styles.dropZone}
            disabled={(selectedRemain?.idGlobal ? true : false) || lockFromPermission}
            wrapperStyle={styles.dropZoneWrapper}
            fileNamePattern='contractors'
        />

        <div className={lgContentStyles.buttonBlock}>
            <div></div>
            <Button
                variant='outprimary'
                className={lgContentStyles.button}
                disabled={((store?.idGlobal && pricingModel?.idGlobal &&
                    supplier.id && remain.id && !selectedRemain?.idGlobal && isSubmitting == false) ? false : true) || lockFromPermission}
                isLoadingSpinner={isSubmitting}
                onClick={() => importRemains()}
            >
                Импорт Ввода остатков
            </Button>
        </div>

        <GridWrapper cols={1}>
            <GridSelectorInput
                selectorModalJsx={ImportRemainsSelectorModal}
                id={uuidv4()}
                selectedEntity={selectedRemain}
                onClear={() => setIdRemains(null)}
                gridFilter={gridFilter}
                label='Ввод остатков'
                onSelect={(entity) => setIdRemains(entity.idGlobal)}
                disabled={(store?.idGlobal || pricingModel?.idGlobal ? true : false) || lockFromPermission}
            />
        </GridWrapper>

        <GridWrapper cols={19}>
            <DictionaryFileInput
                multiple={false}
                files={kiz.file}
                setFiles={(files: IFile[]) => {
                    const id = uuidv4();
                    setKiz({ file: files, id: id });
                    importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                        setKizSubmitting(value)
                    })
                }}
                label='КИЗы'
                admissibleExtension={admissibleExtension}
                className={styles.fileInput}
                disabled={(selectedRemain?.idGlobal ? false : true) || lockFromPermission}
            />
            <Delete3Icon className={styles.clearButton} onClick={() => setKiz({ file: [], id: null })} />            
        </GridWrapper>

        <DictionaryDropZone
            files={kiz.file}
            setFiles={(files: IFile[]) => {
                const id = uuidv4();
                setKiz({ file: files, id: id });
                importCsvFileRequest(renameFile(files[0].file, `${id}.csv`), (value: boolean) => {
                    setKizSubmitting(value)
                })
            }}
            isSubmitting={kizSubmitting}
            admissibleExtension={admissibleExtension}
            multiple={false}
            fileWrapperClassName={styles.dropZone}
            wrapperStyle={styles.dropZoneWrapper}
            disabled={(selectedRemain?.idGlobal ? false : true) || lockFromPermission}
            fileNamePattern='kizs'
        />
        <div className={lgContentStyles.buttonBlock}>
            <div></div>
            <Button
                variant='outprimary'
                className={lgContentStyles.kizButton}
                disabled={((selectedRemain?.idGlobal && kiz.id && isSubmitting == false) ? false : true) || lockFromPermission}
                onClick={() => importKiz()}
                isLoadingSpinner={isSubmitting}
            >
                Импорт КИЗ
            </Button>
        </div>

    </>
}