import axios from "axios"
import { IGoodsDTO } from "../../coreapi-dto/dirs/goods";
import { IKiz2DocumentItemGetDTO } from "../../coreapi-dto/dirs/kiz";
import { IActDisassemblingGetDTO } from "../../coreapi-dto/documents/actDisassembling";
import { IActDisassemblingItemEditDTO, IActDisassemblingItemGetDTO } from "../../coreapi-dto/documents/actDisassemblingItem";
import { envF } from "../../../env";

export const getActDisassemblingItemUpdateDTO = async (idDocument: string, callback: (e: IActDisassemblingItemEditDTO[]) => void) => {

    const jwtToken = localStorage.getItem("TokenData") as string;
    const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
    const header = {
        headers: {
            Authorization: token
        },
    }

    const generateKizRequestUrl = (idDocumentItem) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Kiz/Document/${idDocument}/Item/${idDocumentItem}`
    }

    const generateActDisassemblingRequestUrl = () => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/ActDisassembling/${idDocument}`
    }

    const generateGoodsByIdgRequestUrl = (idGoods: string) => {
        return `${envF.REACT_APP_API_GATEWAY_PATH}/Goods/${idGoods}`
    }

    let actDisassembling = await axios.get<IActDisassemblingGetDTO>(
        generateActDisassemblingRequestUrl(),
        header
    )


    let items: IActDisassemblingItemEditDTO[] = []

    const itemsPush = async (x: IActDisassemblingItemGetDTO) => {
        if (!x.isKiz) {
            await axios.get<IGoodsDTO>(
                generateGoodsByIdgRequestUrl(x.goods.idGlobal),
                header
            ).then((goods) => {
                items.push({
                    idGlobal: x.idGlobal as string,
                    idGoodsGlobal: x.goods.idGlobal,
                    idScalingRatioFromGlobal: x.scalingRatioFrom.idGlobal,
                    idLotFromGlobal: x.lotFrom.idGlobal,
                    idScalingRatioToGlobal: x.scalingRatioTo.idGlobal,
                    quantityFrom: x.quantityFrom,
                    supplierCostInfo: x.supplierCostInfo,
                    retailCostInfo: x.retailCostInfo,
                    productMargin: x.productMargin,
                    isKiz: x.isKiz,
                    quantity: x.quantity,
                    isGnvls: goods.data.gnvls.important,
                    kizBoxCount: x.kizBoxCount,
                    kizCount: x.kizCount,
                    dateCreated: x.dateCreated

                })
            })
        } else {
            await axios.get<IKiz2DocumentItemGetDTO>(
                generateKizRequestUrl(x.idGlobal),
                header
            ).then(async (e) => {
                await axios.get<IGoodsDTO>(
                    generateGoodsByIdgRequestUrl(x.goods.idGlobal),
                    header
                ).then((goods) => {
                    items.push({
                        idGlobal: x.idGlobal as string,
                        idGoodsGlobal: x.goods.idGlobal,
                        idScalingRatioFromGlobal: x.scalingRatioFrom.idGlobal,
                        idLotFromGlobal: x.lotFrom.idGlobal,
                        idScalingRatioToGlobal: x.scalingRatioTo.idGlobal,
                        quantityFrom: x.quantityFrom,
                        supplierCostInfo: x.supplierCostInfo,
                        retailCostInfo: x.retailCostInfo,
                        productMargin: x.productMargin,
                        isKiz: x.isKiz,
                        quantity: x.quantity,
                        kizs: e.data.items[0].kizes ?? [],
                        kizBoxes: e.data.items[0].kizBoxes ?? [],
                        isGnvls: goods.data.gnvls.important,
                        kizBoxCount: x.kizBoxCount,
                        kizCount: x.kizCount,
                        dateCreated: x.dateCreated
                    })
                })
            }).catch(async () => {
                await axios.get<IGoodsDTO>(
                    generateGoodsByIdgRequestUrl(x.goods.idGlobal),
                    header
                ).then((goods) => {
                    items.push({
                        idGlobal: x.idGlobal as string,
                        idGoodsGlobal: x.goods.idGlobal,
                        idScalingRatioFromGlobal: x.scalingRatioFrom.idGlobal,
                        idLotFromGlobal: x.lotFrom.idGlobal,
                        idScalingRatioToGlobal: x.scalingRatioTo.idGlobal,
                        quantityFrom: x.quantityFrom,
                        supplierCostInfo: x.supplierCostInfo,
                        retailCostInfo: x.retailCostInfo,
                        productMargin: x.productMargin,
                        isKiz: x.isKiz,
                        quantity: x.quantity,
                        kizs: [],
                        kizBoxes: [],
                        isGnvls: goods.data.gnvls.important,
                        kizBoxCount: x.kizBoxCount,
                        kizCount: x.kizCount,
                        dateCreated: x.dateCreated
                    })
                })

            })
        }
    }

    const addItems = async () => {
        const unresolvedPromises = actDisassembling.data.items.map((x) => itemsPush(x));
        const results = await Promise.all(unresolvedPromises);
    }

    addItems().then(() => callback(items))

}


