import { v4 as uuidv4 } from 'uuid';
import {FC, useEffect, useReducer, useState} from 'react';
import {IStoreSelectorPanelProps} from '.';
import { IEntitySimpleDTO } from '../../libs/coreapi-dto/@types/common';
import {ContractorDataProvider} from '../../Services/DataProviders/ContractorDataProvider';
import {StoreDataProvider} from '../../Services/DataProviders/StoreDataProvider';
import {autocomplete} from '../../system/functions/autocomplete';
import useGridFilter from '../../system/hooks/useGridFilter';
import {useAppContext} from '../../system/providers/appContextProvider';
import ContractorSelectorInput from '../controls/inputs/ContractorSelectorInput';
import StoreSelectorInput from '../controls/inputs/StoreSelectorInput';
import {initialState, reducer} from './state/reducer';
import { Tooltip } from 'antd';

const StoreSelectorPanel: FC<IStoreSelectorPanelProps> = (props) => {
  const appContext = useAppContext();

  const contractorDP = new ContractorDataProvider(appContext.coreApiService);
  const storeDP = new StoreDataProvider(appContext.coreApiService);

  const [storeGridFilter, dispatchStoreGridFilter] = useGridFilter();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {

    if (props.notAutoComplete && props.documentStore)
    {
      storeSelect(props.documentStore.idGlobal, false);
      if (props.documentPricingModel)
        props.pricingModel?.onSelect?.(props.documentPricingModel);
    }
    else
    {
      if (!props.we) return
      autocomplete().then((value) => {
        const contractorMe = value.contractorMe;
        const contractor = {displayName: contractorMe.name, idGlobal: contractorMe.idGlobal}
        dispatch({
          type: 'autocomplete',
          payload: {
            store: contractorMe.store,
            contractor: contractor,
          },
        });

        dispatchStoreGridFilter({
          type: 'addColumnFilter',
          payload: {
            name: 'idContractorGlobal',
            operator: 'Eq',
            value: contractor.idGlobal,
          },
        });
        props.store.onSelect?.(contractorMe.store);
        props.contractor.onSelect?.(contractor);
        
        pricingModelAutoSelect(contractorMe.store, contractor);
      });
    }

  }, []);

  useEffect(() => {
    if (state.contractor?.idGlobal && state.store?.idGlobal) return
    if (!props.storeFromLot?.idGlobal) return
    storeSelect(props.storeFromLot.idGlobal, true)
  },[props.storeFromLot])

  const contractorSelect = (contractorEntity: IEntitySimpleDTO) => {
      dispatch({
        type: 'autocomplete',
        payload: {store: null, contractor: contractorEntity},
      });
      props.contractor.onSelect?.(contractorEntity);

      dispatchStoreGridFilter({
        type: 'addColumnFilter',
        payload: {
          name: 'idContractorGlobal',
          operator: 'Eq',
          value: contractorEntity.idGlobal,
        },
      });

      props.store.onClear?.()
  }

  const storeSelect = (idStoreFromGlobal: string, autoCompletePricingModel: boolean) =>
  {
      if (!idStoreFromGlobal) return
      storeDP.getById(idStoreFromGlobal, (dto) => {
        const storeEntitySimple = {displayName: dto.name, idGlobal: idStoreFromGlobal} as IEntitySimpleDTO
        dispatch({type: 'autocomplete', payload: {contractor: dto.contractor, store: storeEntitySimple}});
        dispatchStoreGridFilter({
          type: 'addColumnFilter',
          payload: {
            name: 'idContractorGlobal',
            operator: 'Eq',
            value: dto.contractor.idGlobal,
          },
        });
      props.store.onSelect?.(storeEntitySimple);
      props.contractor.onSelect?.(dto.contractor);
      pricingModelSelect(dto.pricingPricingModel, autoCompletePricingModel);
      });
  }

  const pricingModelSelect = (entity: IEntitySimpleDTO, autoPricingModel: boolean) =>
  {
    if (autoPricingModel)
      props.pricingModel?.onSelect?.(entity);
  }

  const pricingModelAutoSelect = (storeEntity: IEntitySimpleDTO, contractor: IEntitySimpleDTO) =>
  {
    if (props.pricingModel)
      storeDP.getById(storeEntity.idGlobal, (store) => {
        if (store.pricingPricingModel)
          props.pricingModel?.onSelect?.(store.pricingPricingModel);
        else
        {
          contractorDP.getById(contractor.idGlobal, (cont) => {
            if (cont.pricingPricingModel)
              props.pricingModel?.onSelect?.(cont.pricingPricingModel);
        });
        }
      })
  }

  return (
    <>
      <ContractorSelectorInput
        id={uuidv4()}
        inline={!props.compactMode}
        label={props.required ? `${props.contractor.label} *`: props.contractor.label}
        variant="required"
        change={props.change ?? undefined}
        selectedEntity={state.contractor}
        onSelect={(contractorEntity) => {
          contractorSelect(contractorEntity)
        }}
        onClear={() => {
          dispatch({type: 'autocomplete', payload: {contractor: null, store: null}});
          dispatchStoreGridFilter({
            type: 'deleteColumnFilter',
            payload: 'idContractorGlobal',
          });
          props.contractor?.onClear?.();
          props.store?.onClear?.();
        }}
        error={props.contractor.error}
        onFocus={props.contractor.onFocus}
        labelClassName={props.contractor.labelClassName}
        className={props.contractor.className}
        disabled={props.contractor.disabled}
        treeViewCheckDirectoryType={props.contractor.treeViewCheckDirectoryType}
      />

      <Tooltip
        placement='bottom'
        align={{ offset: [0, -15] }}
        color='red'
        title={props.store.checkLot && 'Невозможно сменить склад, так как документ содержит партии'}>
        <div >
          <StoreSelectorInput
            inline={!props.compactMode}
            change={props.change ?? undefined}
            id={uuidv4()}
            label={props.required ? `${props.store.label} *` : props.store.label}
            variant="required"
            gridFilter={storeGridFilter}
            selectedEntity={state.store}
            onSelect={(storeEntity) => {
              storeSelect(storeEntity.idGlobal, true);
            }}
            onClear={() => {
              dispatch({ type: 'changeStore', payload: null });
              props.store?.onClear?.();
            }}
            error={props.store.error}
            onFocus={props.store.onFocus}
            labelClassName={props.store.labelClassName}
            className={props.store.className}
            disabled={props.store.disabled}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default StoreSelectorPanel;
