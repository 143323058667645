import { IEntitySimpleDTO } from "../../../../../libs/coreapi-dto/@types/common";
import {
  IAutocomplete,
  IGetIdGlobal,
  ISetComment,
  ISetControlType,
  ISetDisplayUnit,
  ISetDosage,
  ISetGoods,
  ISetMnnName,
  ISetOverSupply,
  ISetPrice,
  ISetQty,
  ISetSpecName,
  ISetSpecProducer,
  ISetSpecUnit,
} from "./actionType";

export type Action =
  | IGetIdGlobal
  | ISetQty
  | ISetPrice
  | ISetOverSupply
  | ISetGoods
  | ISetDisplayUnit
  | ISetComment
  | ISetControlType
  | ISetDosage
  | ISetMnnName
  | ISetSpecName
  | ISetSpecProducer
  | ISetSpecUnit
  | IAutocomplete;

export interface IInitialState {
  idGlobal: string;
  qty: number; // +Количество
  price: number; // + Цена
  overSupply: boolean; // ++Сверхпоставка
  goods: IEntitySimpleDTO | null; //Товар +
  info: {
    displayUnit: string; // + ед измерения
    comment: string; // + комментарий
    controlType: string; // + Контроль
    dosage: string; // -
    mnnName: string; //+ МНН
  };
  specInfo: {
    specName: string; // Наименование по контракту:" ++
    specProducer: string; // + производителя по контракту
    specUnit: string; // + ед измерения по контракту
  };
}
// IGosContractGoodsDTO GosContractCreate(тут искать)
export const initialState: IInitialState = {
  idGlobal: "",
  qty: 0,
  price: 0,
  overSupply: false,
  goods: null,
  info: {
    displayUnit: "",
    comment: "",
    controlType: "ЛС",
    dosage: "",
    mnnName: "",
  },
  specInfo: {
    specName: "",
    specProducer: "",
    specUnit: "",
  },
};

export function reducer(
  state: IInitialState = initialState,
  action: Action
): IInitialState {
  switch (action.type) {
    case "getIdGlobal":
      return { ...state, idGlobal: action.payload };
    case "setQty":
      return { ...state, qty: action.payload };
    case "setPrice":
      return { ...state, price: action.payload };
    case "setOverSupply":
      return { ...state, overSupply: action.payload };
    case "setGoods":
      return { ...state, goods: action.payload };
    case "setDisplayUnit":
      return { ...state, info: { ...state.info, displayUnit: action.payload } };
    case "setComment":
      return { ...state, info: { ...state.info, comment: action.payload } };
    case "setControlType":
      return { ...state, info: { ...state.info, controlType: action.payload } };
    case "setDosage":
      return { ...state, info: { ...state.info, dosage: action.payload } };
    case "setMnnName":
      return { ...state, info: { ...state.info, mnnName: action.payload } };
    case "setSpecName":
      return {
        ...state,
        specInfo: { ...state.specInfo, specName: action.payload },
      };
    case "setSpecProducer":
      return {
        ...state,
        specInfo: { ...state.specInfo, specProducer: action.payload },
      };
    case "setSpecUnit":
      return {
        ...state,
        specInfo: { ...state.specInfo, specUnit: action.payload },
      };

    case "autocomplete":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}
