import { DateTime } from "luxon";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4, NIL as uuidNull } from "uuid";
import { IDocumentUpdateFormProps } from "../../../../@types/documents";
import { Button } from "../../../../components/buttons/button";
import { SettingsButtonModal } from "../../../../components/buttons/iconButtons/settingsButton/SettingsButtonModal";
import { Accordion } from "../../../../components/controls/accordion";
import { DictionaryInput } from "../../../../components/controls/dictionaryInput";
import GridSelectorInput from "../../../../components/controls/GridSelectorInput";
import GridWrapper from "../../../../components/controls/GridWrapper";
import { DateInput, TextInput } from "../../../../components/controls/inputs";
import BaseDocumentCreator from "../../../../components/documents/baseDocumentCreator";
import { IOption, Select } from "../../../../components/selects/select";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { IGoodsDTO } from "../../../../libs/coreapi-dto/dirs/goods";
import { IInvoiceCreateDTO, IInvoiceGetDTO } from "../../../../libs/coreapi-dto/documents/invoice";
import { IInvoiceItemCreateDTO, IInvoiceItemGetDTO } from "../../../../libs/coreapi-dto/documents/invoiceItem";
import { ContractorDataProvider } from "../../../../Services/DataProviders/ContractorDataProvider";
import { GoodsDataProvider } from "../../../../Services/DataProviders/GoodsDataProvider";
import { PricingInvoiceDataProvider } from "../../../../Services/DataProviders/PricingInvoiceDataProvider";
import { useForm } from "../../../../system/hooks/useForm";
import useGridFilter from "../../../../system/hooks/useGridFilter";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { ContractorSelectorModal } from "../../../Dictionaries/Contractor";
import { GoodsSelectorModal } from "../../../Dictionaries/Goods";
import KizScanModal from "../../../Dictionaries/Kiz/ScanModal/KizScanModal";
import { PricingModelSelectorModal } from "../../../Dictionaries/PricingModel";
import { IKeyValuePair } from "../../../Reports/Shared/Interfaces/IKeyValuePair";
import { InvoiceCreatorItemGridPluginSettings } from "./InvoiceItemCreator/InvoiceItemCreatorGridPluginSettings";
import styles from "../styles/InvoiceCreatorView.module.scss";
import { DocumentType, EncodingType, IdTableVariant, SearchOption } from "../../../../@types/enumsGlobal";
import InvoiceGoodsSelectModal from "./InvoiceGoodsSelectModal";
import { IGoodsByIdDTO } from "../../../../libs/core-api-requests/dirs/goodsRequest";
import PositionEditPanel from "../../../../components/commandsPanels/PositionEditPanel";
import { MessageModalWindow } from "../../../../components/modalWindows/MessageModalWindow";
import { StockBalanceButtonCompress, StockBalanceButtonExpand } from "../../../../components/buttons/iconButtons/special/QuickAccessBarCollection";
import Tooltip from "../../../../components/tooltips/Tooltip";
import { IScalingRatioDTO } from "../../../../libs/coreapi-dto/dirs/scalingRatio";
import { ScalingRatioDataProvider } from "../../../../Services/DataProviders/ScalingRatioDataProvider";
import { autocomplete } from "../../../../system/functions/autocomplete";
import { getStyleCell } from "../../../../system/functions/getStyleCell";
import { kizCounter, sumKizsCount } from "../../../../system/functions/sumKiszCount";
import { ChangeableGrid, IChangeDataGrid } from "../../../../components/grids/changeableGrid/ChangeableGrid";
import CollectPricingInvoiceItemDTO, { ICollectPricingInvoiceItemProps } from "../CollectPricingInvoiceItemDTO";
import { KizDataProvider } from "../../../../Services/DataProviders/KizDataProvider";
import { BarcodeType, IKizBoxDTO, IKizDTO, IkizDocumentItemGtinDto } from "../../../../libs/coreapi-dto/dirs/kiz";
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { addKizOrKizBox } from "../../../../system/functions/addKizOrKizBox";
import { CheckBox } from "../../../../components/controls/checkbox";
import { StoreSelectorPanel } from "../../../../components/storeSelectorPanel";
import { MoneyFormatComponent } from "../../../../components/controls/inputs/BaseInput";
import { GosContractSelectorModal } from "../../../Dictionaries/GosContract/components/GosContractSelectorModal";
import { addValueToArray } from "../../../../system/functions/addValToArray";
import { GosContractDataProvider } from "../../../../Services/DataProviders/GosContractDataProvider";
import { IGosContractGoodsDTO } from "../../../../libs/coreapi-dto/dirs/gosContract";
import { validateDuplicateKizs } from "../../../../system/functions/validateDuplicateKizs";
import { useTimeout } from "../../../../system/hooks/useTimeout";
import { useComPortScanner } from "../../../../system/hooks/useHubConnection";
import FlexRow from "../../../../components/controls/FlexRow";
import useLockingDocuments from "../../../../components/lockDocuments/useLockingDocuments";
import { IContractorDTO } from "../../../../libs/coreapi-dto/dirs/contractor";
import { checkLockStatus } from "../../../CommonHelperFunctions";
import { useUserContext } from "../../../../system/providers/userContextProvider";
import useItemsByGtin from "../../../../components/kiz/useItemsByGtin"
import { InvoiceItemsSelectorModal } from "../InvoiceItem/InvoiceItemsSelectorModal";
import { IBaseDocumentItemKizDTO } from "../../../../libs/coreapi-dto/documents/base";

interface ISum {
    amountRetailSum: number;
    amountRetailVatSum: number;
    amountRetailSumIncVat: number;
    amountSupplierSum: number;
    amountSupplierVatSum: number;
    amountSupplierSumIncVat: number;
}

interface ISelectedItemGoods extends IGoodsDTO {
    idGlobal: string;
}

interface IGoodsViewItem {
    item: IGoodsByIdDTO[] | null;
    show: boolean;
}

interface IStoreModel {
    store?: IEntitySimpleDTO;
}

interface IValidator {
    supplier: IEntitySimpleDTO;
    pricingModel: IEntitySimpleDTO;
    documentDate: DateTime;
}

interface IMessageModalProps {
    show: boolean;
    message: string;
    btSaveOrOk?: BtSaveOrOk;
}

interface IItemsGroupAlert {
    show: boolean;
}

export type BtSaveOrOk = "ok" | "save";

export interface IDisplayItem {
    idGlobal: string;
    goodsName: string;
    scalingRatioName: string;
    quantity: number;

    productMargin: number;
    producerPrice: number;

    retailPrice: number;
    retailPriceIncVat: number;
    retailAmount: number;
    retailAmountIncVat: number;
    retailVat: number;

    supplierPrice: number;
    supplierAmount: number;
    supplierAmountIncVat: number;
    supplierVat: number;

    isKiz: boolean;
    isGnvls: boolean;
    countKizScan: number | undefined;
    numerator?: number;
    denominator?: number;

    absentInGC?: boolean | null;
    idInvoiceItemGroupGlobal?: string | null;
    dateCreated: DateTime;
    seriesNumber: string;
    bestBefore: DateTime;
    isOsu?: boolean;
}

interface ISelectedItemGoods extends IGoodsDTO {
    idGlobal: string;
}

interface IGoodsViewItem {
    item: IGoodsByIdDTO[] | null;
    show: boolean;
}

export interface IGosContractFields {
    isGosContract: boolean;
    gosContractGoods: IGosContractGoodsDTO | null;
}

const InvoiceCreatorForm: FC<IDocumentUpdateFormProps<IInvoiceGetDTO, IInvoiceItemGetDTO[], IInvoiceCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const userContext = useUserContext();

    const lockFromPermission = checkLockStatus(props.permission as IPermission, userContext.userPermission);
    const [onlyRead, setOnlyRead] = useState((props.variant === 'edit' && (props.document?.documentState === 'proc' || lockFromPermission)) ? true : false);

    const idTable = IdTableVariant.Invoice
    const documentType = DocumentType.invoice
    const itemsByGtin = useItemsByGtin(idTable,documentType)
    const itemsDocumentByGtin = itemsByGtin.getKizIdItemsByGtin();

    const { t } = useTranslation();
    const baseT = (value: string) => t("documents.invoice." + value);
    const errorsT = (value: string) => t("errors." + value);

    const goodsDP = new GoodsDataProvider(appContext.coreApiService);
    const contractorDP = new ContractorDataProvider(appContext.coreApiService);
    const kizDP = new KizDataProvider(appContext.coreApiService);
    const pricingInvoiceDP = new PricingInvoiceDataProvider(appContext.coreApiService);
    const scalingRatioDP = new ScalingRatioDataProvider(appContext.coreApiService);
    const gosContractDP = new GosContractDataProvider(appContext.coreApiService);
    const contractorDataProvider = new ContractorDataProvider(appContext.coreApiService);

    const lockingDocuments = useLockingDocuments();
    const [idLockingDocument, setIdLockingDocument] = useState<string|undefined>();

    const [data, setData] = useState<IInvoiceCreateDTO>(
        props.documentUpdate ??
            ({
                idGlobal: uuidv4(),
                documentDate: props.isTest ? DateTime.now().plus({ days: -7 }) : DateTime.now(),
            } as IInvoiceCreateDTO)
    );
    
    const [displayItems, setDisplayItems] = useState<IDisplayItem[]>([]);

    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemGoods, setSelectedItemGoods] = useState<ISelectedItemGoods>();
    const [selectedItemCreateDto, setSelectedItemCreateDto] = useState<IInvoiceItemCreateDTO>();
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [averageMarkup, setAverageMarkup] = useState<string>(recalculateAverageMarkup(data.items)); // средняя наценка
    const [mnemocode] = useState<string>(props.document?.mnemocode ?? "");
    const [sum, setSum] = useState<ISum>({
        amountRetailSum: 0,
        amountRetailVatSum: 0,
        amountRetailSumIncVat: 0,
        amountSupplierSum: 0,
        amountSupplierSumIncVat: 0,
        amountSupplierVatSum: 0,
    });
    const [showKizScan, setShowKizScan] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<IMessageModalProps>({ show: false, message: "" });
    const [showMessageModalValidDocument, setShowMessageModalValidDocument] = useState<IMessageModalProps>({ show: false, message: "" });
    const [itemsGroupAlert, setItemsGroupAlert] = useState<IItemsGroupAlert>({ show: false });
    const defaultSearchOption = {displayName: baseT("name"), value: "name"} as IOption;
    const [searchOption, setSearchOption] = useState<IOption>(defaultSearchOption);

    const [selectedScalingRation, setSelectedScalingRation] = useState<IScalingRatioDTO | null>(null);
    const [goodsViewItem, setGoodsViewItem] = useState<IGoodsViewItem>({ item: null, show: false });

    const [isCompact, setIsCompact] = useState<boolean>(false);
    const [isCompactStore, setIsCompactStore] = useState<boolean>(false);

    const [scanFocus, setScanFocus] = useState<boolean>(false);
    const [scanValue, setScanValue] = useState<string>("");
    const timeout = useRef<NodeJS.Timeout>();
    const currentIdTab = useRef<string>('');
    const [setTimer, clearTimer]  = useTimeout()

    const [store, setStore] = useState<IEntitySimpleDTO | null>(props.document?.store ?? null);
    const [pharmacy] = useState<IEntitySimpleDTO | null>(null);
    const [pricingModel, setPricingModel] = useState<IEntitySimpleDTO | null>(props.document?.pricingModel ?? null);
    const [supplier, setSupplier] = useState<IEntitySimpleDTO | null>(props.document?.supplier ?? null);
    //Validations
    const [itemsValidation, setItemsValidation] = useState<IValidationItem[]>([]);
    const [itemsFilter, setItemsFilter] = useState<IItemFilter[]>([]);

    const [currentSupplier, setCurrentSupplier] = useState<IContractorDTO>();
    const [currentPharmacy, setCurrentPharmacy] = useState<IContractorDTO>();
    const [kizScanModal, setKizScanModal] = useState(<></>);

    const [gosContract, setGosContract] = useState<IEntitySimpleDTO | null>(null);
    useEffect(() => setData({ ...data, idStoreGlobal: store?.idGlobal }), [store]);
    useEffect(() => setData({ ...data, idPricingModelGlobal: pricingModel?.idGlobal }), [pricingModel]);
    useEffect(() => setData({ ...data, idSupplierGlobal: supplier?.idGlobal }), [supplier]);


    useEffect(() => setStore(props.document?.store ? { ...props.document?.store } : null), [props.document?.store]);
    useEffect(() => setPricingModel(props.document?.pricingModel ? { ...props.document?.pricingModel } : null), [props.document?.pricingModel]);

    useEffect(() => {
        if (props.document?.gosContract) setGosContract(props.document.gosContract);
    }, [props.document?.gosContract]);

    useEffect(() => {
        if (!data.idPricingModelGlobal) return;
        calcAllItems();
    }, [data.idPricingModelGlobal]);

    useEffect(() => {
        if (supplier) {
            return;
        }

        if (props.document?.supplier) {
            setSupplier({ ...props.document?.supplier });
        } else {
            autocomplete().then((e) => {
                e.defaultSupplier && setSupplier({ idGlobal: e.defaultSupplier.idGlobal, displayName: e.defaultSupplier.name });
            });
        }
    }, [props.document?.supplier]);

    useEffect(() => {
        if (pricingModel !== null) {
            return;
        }

        if (pharmacy !== null) {
            contractorDP.getById(pharmacy.idGlobal, (value2) => {
                setPricingModel(value2.pricingPricingModel);
            });
        }
        if(pharmacy) {
            contractorDataProvider.getById(pharmacy.idGlobal, (dataRequest) => setCurrentPharmacy(dataRequest));
        }
    }, [pharmacy]);

    useEffect(() => {
        if (supplier) {
            contractorDataProvider.getById(supplier.idGlobal, (dataRequest) => setCurrentSupplier(dataRequest));
        }
    }, [supplier])

    useEffect(() => {
        const handleTabClose = (event) => {
            lockingDocuments.delete({
                idTable: idTable,
                idDocument: data.idGlobal as string,
            });
            return (event.returnValue = "");
        };

        window.addEventListener("beforeunload", handleTabClose);

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
        };
    }, []);

    const requestUpdateLockingDocumentTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (!idLockingDocument) return;
        clearTimeout(requestUpdateLockingDocumentTimeout.current as NodeJS.Timeout);
        requestUpdateLockingDocumentTimeout.current = setTimeout(() => {
            lockingDocuments.update(idLockingDocument, (e)=>{})
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    }, [data.documentDate, data.idPricingModelGlobal, data.idStoreGlobal, data.idSupplierGlobal, data.items]);

    useEffect(() => {
        if (tabsContext?.currentTab) {
            currentIdTab.current = tabsContext.currentTab?.id;
        }
    }, []);

    useComPortScanner((data) => {
        if (tabsContext.currentTab && (tabsContext.currentTab?.id === currentIdTab.current)) {
            if (!showKizScan && selectedItem) {
                setScanValue(data);
                addKiz(data, true);
            }
        }
    })

    const addKiz = (scanValue: string, portScanner?: boolean) => {
        clearTimeout(timeout.current as NodeJS.Timeout);
        timeout.current = setTimeout(() => {
            let item = data?.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInvoiceItemCreateDTO;
            if (item?.isKiz) {
                if (item.quantity > (item.kizBoxes?.length || 0 + (item.kizs?.length || 0))) {
                    addKizOrKizBox(
                        scanValue,
                        kizDP,
                        documentType,
                        (value) => {
                            let filteredItems = data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal);
                            let item = data.items.find((x) => x.idGlobal === selectedItem?.idGlobal) as IInvoiceItemCreateDTO;
                            let displayItem = displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal) as IDisplayItem;
                            let filteredDisplayItems = displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal);

                            setData({
                                ...data,
                                items: [
                                    ...filteredItems,
                                    {
                                        ...item,
                                        kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                        kizBoxes:
                                            value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                    },
                                ],
                            });
                            setDisplayItems([
                                ...filteredDisplayItems,
                                {
                                    ...displayItem,
                                    countKizScan: sumKizsCount(
                                        {
                                            ...item,
                                            kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                            kizBoxes:
                                                value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                                        }, 
                                        documentType,
                                        {numerator: item.numerator, denominator: item.denominator} as IScalingRatioDTO
                                    ),
                                },
                            ]);
                            setSelectedItemCreateDto({
                                ...item,
                                kizs: value.kizType === BarcodeType.Kiz ? (item.kizs ? [...item.kizs, value.kiz as IKizDTO] : [value.kiz as IKizDTO]) : item.kizs,
                                kizBoxes: value.kizType === BarcodeType.KizBox ? (item.kizBoxes ? [...item.kizBoxes, value.kizBox as IKizBoxDTO] : [value.kizBox as IKizBoxDTO]) : item.kizBoxes,
                            });
                            setScanValue("");
                        },
                        portScanner
                    );
                } else {
                    setScanValue("");
                    renderGlobalAlert({
                        variant: "exception",
                        title: errorsT("boxQuantity"),
                        statusCode: 400,
                    });
                }
            } else {
                setScanValue("");
                renderGlobalAlert({
                    variant: "exception",
                    title: errorsT("noKizState"),
                    statusCode: 400,
                });
            }
        }, Number(process.env.REACT_APP_INTERVAL_REQUEST));
    };

    const { isValid, errors, setErrors } = useForm<IStoreModel & IValidator>({
        validations: {
            supplier: {
                required: {
                    value: data?.idSupplierGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            store: {
                required: {
                    value: data?.idStoreGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            pricingModel: {
                required: {
                    value: data?.idPricingModelGlobal ? false : true,
                    message: errorsT("required"),
                },
            },
            documentDate: {
                required: {
                    value: data?.documentDate?.day ? false : true,
                    message: errorsT("required"),
                },
            },
        },
    });

    useEffect(() => {
        if (selectedItem) {
            setScanFocus(true);
            let selectedItemDto = data.items.find((x) => x.idGlobal === selectedItem.idGlobal) as IInvoiceItemCreateDTO;
            if (selectedItemDto) {
                setSelectedItemCreateDto(selectedItemDto);
                scalingRatioDP.overrideGetById(selectedItemDto.idGoodsGlobal, selectedItemDto.idScalingRatioGlobal as string, (e) => {
                    setSelectedScalingRation(e);
                });
                goodsDP.getById(data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idGoodsGlobal as string, (e) => {
                    setSelectedItemGoods({
                        ...e,
                        idGlobal: data.items.find((x) => x.idGlobal === selectedItem.idGlobal)?.idGoodsGlobal as string,
                    });
                });
            } else {
                setSelectedItemCreateDto(undefined);
                setSelectedItemGoods(undefined);
                setSelectedScalingRation(null);
            }
        } else {
            setScanFocus(false);
            setSelectedItemCreateDto(undefined);
            setSelectedItemGoods(undefined);
            setSelectedScalingRation(null);
        }
        setScanValue("");
    }, [selectedItem]);

    useEffect(() => {
        if (data?.items) {
            setAverageMarkup(recalculateAverageMarkup(data.items));
            setSum({
                ...sum,
                amountRetailSum: data.items.reduce((sum, item) => sum + item.retailCostInfo.sum, 0),
                amountRetailVatSum: data.items.reduce((sum, item) => sum + item.retailCostInfo.vatSum, 0),
                amountRetailSumIncVat: data.items.reduce((sum, item) => sum + item.retailCostInfo.sumIncVat, 0),
                amountSupplierSum: data.items.reduce((sum, item) => sum + item.supplierCostInfo.sum, 0),
                amountSupplierVatSum: data.items.reduce((sum, item) => sum + item.supplierCostInfo.vatSum, 0),
                amountSupplierSumIncVat: data.items.reduce((sum, item) => sum + item.supplierCostInfo.sumIncVat, 0),
            });
        }
    }, [data?.items,displayItems]);

    const addItemsFilter = (items = props.document?.items) => {
        let arr: IItemFilter[] = [];
        items?.forEach((x) => {
            arr.push({
                idItem: x.idGlobal as string,
                quantityMax: 9999999, // TODO: magic number fix
                quantityMin: 0,
            });
        });
        setItemsFilter(arr);
    };

    useEffect(() => {
        if ((props.variant === "edit" || props.variant === "copy") && props.document) {
            if (props.variant === "edit")
                lockingDocuments.check(data.idGlobal as string, (e)=> {
                    if (e) setOnlyRead(true);                    
                    else
                        lockingDocuments.send({
                            idTable: idTable,
                            idDocument: data.idGlobal as string,
                        }, (e)=> {
                            setIdLockingDocument(e)
                        });
                })

            addItemsFilter();
            const propsValidateItems = props.document.items.map((el) => ({
                err: false,
                message: "",
                idGlobal: el.idGlobal,
            })) as IValidationItem[];
            setItemsValidation(propsValidateItems);
        }
    }, []);

    useEffect(() => {        
        if (!itemsDocumentByGtin) return
        itemsByGtin.allowKiz(data.items,
            (e) => setKizByGtin(e)  
        )
    },[itemsDocumentByGtin])

    useEffect(() => {
        if (props.documentItems) {
            setDisplayItems(
                props.documentItems.map((x): IDisplayItem => {
                    return {
                        idGlobal: x.idGlobal as string,
                        goodsName: x.goods.displayName,
                        scalingRatioName: x.scalingRatio.displayName,
                        quantity: x.quantity,

                        productMargin: x.productMargin,
                        producerPrice: x.producerPrice,

                        retailPrice: x.retailCostInfo.price,
                        retailPriceIncVat: x.retailCostInfo.priceIncVat,

                        retailAmount: x.retailCostInfo.sum,
                        retailAmountIncVat: x.retailCostInfo.sumIncVat,
                        retailVat: x.retailCostInfo.vat,

                        supplierAmount: x.supplierCostInfo.sum,
                        supplierAmountIncVat: x.supplierCostInfo.sumIncVat,
                        supplierPrice: x.supplierCostInfo.price,
                        supplierVat: x.supplierCostInfo.vat,

                        isKiz: x.isKiz || x.isKizBox,
                        countKizScan: (x.isKiz || x.isKizBox) === true ? x.kizBoxCount + x.kizCount : undefined,
                        isGnvls: x.isGnvls ?? false,
                        denominator: x.denominator,
                        numerator: x.numerator,
                        absentInGC: props.documentUpdate?.idGosContractExternalGlobal ? (x.idGosContractGoodsGlobal ? false : true) : null,
                        idInvoiceItemGroupGlobal: x.idInvoiceItemGroupGlobal,
                        dateCreated: x.dateCreated,
                        bestBefore: x.bestBefore,
                        seriesNumber: x.seriesNumber,
                        isOsu: x.isOsu
                    };
                })
            );
        }
    }, [props.documentItems]);

    const setKizScanComponent = (_invoiceItem?: IInvoiceItemCreateDTO, _scalingRatio?: IScalingRatioDTO, isFocusScanKizBox?: boolean) => { 

        const selectedItem = _invoiceItem ?? selectedItemCreateDto
        const scalingRatio = _scalingRatio ?? selectedScalingRation

        if (!selectedItem || scalingRatio === null) {
            setKizScanModal(<></>)
            return
        }

        setKizScanModal(<KizScanModal
            ok={(value: IInvoiceItemCreateDTO, scalingRatio?: IScalingRatioDTO) => {
                let result = changeItemScalingRatio(value, scalingRatio);

                setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== result.idGlobal), result] });
                setDisplayItems(
                    displayItems.map((x): IDisplayItem => {
                        return {
                            ...x,
                            countKizScan: x.idGlobal === result.idGlobal ? sumKizsCount(result, documentType,scalingRatio as IScalingRatioDTO) : x.countKizScan,
                            scalingRatioName: x.idGlobal === result.idGlobal ? scalingRatio?.displayName ?? x.scalingRatioName : x.scalingRatioName,
                            dateCreated: x.idGlobal === result.idGlobal ? DateTime.now() : x.dateCreated,
                            isOsu: x.idGlobal === result.idGlobal ? value?.isOsu : x.isOsu,
                        };
                    })
                );
                setShowKizScan(false);
                setKizScanModal(<></>)
            }}
            cancel={() => {
                setShowKizScan(false)
                setKizScanModal(<></>)
            }}
            selectedItem={selectedItem as IInvoiceItemCreateDTO}
            documentType={documentType}
            document={{ idTable: idTable, idDocument: data.idGlobal as string }}
            idLotFrom={null}
            scalingRatio={scalingRatio as IScalingRatioDTO}
            numerator={scalingRatio?.numerator as number}
            idGoodsGlobal={selectedItem?.idGoodsGlobal}
            onValidateDuplicate={(barcode) => validateDuplicateKizs(data.items, barcode)}
            isFocusScanKizBox={isFocusScanKizBox}
        />)        
    }

    function setKizByGtin (e: IBaseDocumentItemKizDTO | undefined) {
        let item = data.items.find((x) => x.idGlobal === e?.idGlobal)
        if (!item) return
        setData({ ...data, items: [...data.items.filter((x) => x.idGlobal !== e?.idGlobal), {...item, kizs: e?.kizs, kizBoxes: e?.kizBoxes }]}); 

        let displayItem = displayItems.find(x => x.idGlobal === e?.idGlobal)  

        if (displayItem)      
            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem?.idGlobal),{...displayItem, countKizScan: (displayItem?.countKizScan ?? 0) + 1}]);

        renderGlobalAlert({ variant: "success", statusCode: 200, title: baseT("kizAddedSuccess") });
        if (itemsDocumentByGtin?.kizParsedDto.barcodeType === BarcodeType.KizBox){
            setShowKizScan(true)
            scalingRatioDP.overrideGetById(item.idGoodsGlobal, item.idScalingRatioGlobal as string, (e) => {
                setKizScanComponent(item,e,true)
            });
        }       
    }

    function recalculateAverageMarkup(items: IInvoiceItemCreateDTO[] | undefined): string {
        let result: number = 0;
        if (items && items.length) {
            for (let i = 0; i < items.length; i++) {
                let supplierSumIncVat: number = items[i].supplierCostInfo.price;
                supplierSumIncVat += (supplierSumIncVat * items[i].supplierCostInfo.vat) / 100;
                let retailSumIncVat: number = items[i].retailCostInfo.price;
                retailSumIncVat += (retailSumIncVat * items[i].retailCostInfo.vat) / 100;
                result += (supplierSumIncVat / retailSumIncVat) * 100;
            }
            result = result / items.length;
        }
        return result > 0 ? result.toFixed(2).toString() : "0";
    }

    const openTab = (entity: IEntitySimpleDTO, gosContractGoods: IGosContractFields) => {
        tabsContext.openChild(
            "invoice_item_creator_plugin",
            "create",
            undefined,
            (value: IInvoiceItemCreateDTO, displayItem: IDisplayItem) => {
                setData({
                    ...data,
                    items: data.items ? [...data.items?.filter((x) => x.idGlobal !== value.idGlobal), value] : [value],
                });
                setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem.idGlobal), displayItem]);

                setItemsFilter([...itemsFilter, { idItem: value.idGlobal, quantityMax: 9999999, quantityMin: 0 }]);
                setItemsValidation([...itemsValidation, { err: false, message: "", idGlobal: value.idGlobal }]);
            },
            {
                goods: entity,
                idPricingModel: data.idPricingModelGlobal,
                idSupplier: data.idSupplierGlobal,
                idStore: data.idStoreGlobal,
                gosContractFields: gosContractGoods,
                currentSupplier,
                currentPharmacy,
                variant: 'create',
                documentDate: data.documentDate
            }
        );
    };

    function callOpenInvoiceItemCreatorPlugin(entity: IEntitySimpleDTO) {
        if (data.idGosContractExternalGlobal) {
            gosContractDP.getById(data.idGosContractExternalGlobal, (gosContract) => {
                const gosContractGoods = gosContract.goods.find((x) => x.goods.idGlobal === entity.idGlobal);
                openTab(entity, { isGosContract: true, gosContractGoods: gosContractGoods ?? null });
            });
        } else {
            openTab(entity, { isGosContract: false, gosContractGoods: null });
        }
    }

    const calcRetailPriceIncVat = (idGlobal: string, value: number, item: IInvoiceItemCreateDTO) => {
        calcInvoiceItem(
            {
                eventTypes: "RetailPriceWithVatChanged",
                idInvoice: "",
                idSupplier: data.idSupplierGlobal,
                idStore: data.idStoreGlobal,
                idItem: "",
                idGoods: item.idGoodsGlobal as string,
                idScalingRatio: item.idScalingRatioGlobal,
                quantity: item.quantity,
                producerPrice: item.producerPrice,
                retailCostInfo: { ...item.retailCostInfo, priceIncVat: value as number },
                supplierCostInfo: item.supplierCostInfo,
                registerPrice: item.registerPrice,
            },
            idGlobal
        );
    };

    const calcRetailAmount = (idGlobal: string, value: number, item: IInvoiceItemCreateDTO) => {
        calcInvoiceItem(
            {
                eventTypes: "RetailAmountChanged",
                idInvoice: "",
                idSupplier: data.idSupplierGlobal,
                idStore: data.idStoreGlobal,
                idItem: "",
                idGoods: item.idGoodsGlobal as string,
                idScalingRatio: item.idScalingRatioGlobal,
                quantity: item.quantity,
                producerPrice: item.producerPrice,
                retailCostInfo: { ...item.retailCostInfo, sum: value as number },
                supplierCostInfo: item.supplierCostInfo,
                registerPrice: item.registerPrice,
            },
            idGlobal
        );
    };

    function calcInvoiceItem(collectionProps: ICollectPricingInvoiceItemProps, idGlobal: string) {
        if (data.idPricingModelGlobal === undefined || data.idPricingModelGlobal === null || data.idPricingModelGlobal === uuidNull) {
            renderGlobalAlert({ variant: "error", statusCode: 500, title: baseT("messages.pricingModelNotSelected"), instance: baseT("pricingModelService") });
            return;
        }

        let pricingInvoiceDTO = CollectPricingInvoiceItemDTO(collectionProps);
        pricingInvoiceDP.getCalculatedInvoiceItemViewAsync(data.idPricingModelGlobal as string, pricingInvoiceDTO, (entity) => {

            if (entity.retailPriceChangeReason) 
                renderGlobalAlert({ variant: "warning", statusCode: 500, title: entity.retailPriceChangeReason, instance: baseT("pricingModelService") });

            let indexItem = data.items.findIndex((item) => item.idGlobal === idGlobal);
            let takeDataObj = data.items.find((item) => item.idGlobal === idGlobal) as IInvoiceItemCreateDTO;

            let indexDisplayItem = displayItems.findIndex((item) => item.idGlobal === idGlobal);
            let takeDisplayObj = displayItems.find((item) => item.idGlobal === idGlobal) as IDisplayItem; 
            
            if (entity.retailPriceChangeReason) {
                setDisplayItems([ ...addValueToArray(displayItems, indexDisplayItem, { ...takeDisplayObj, retailPriceIncVat: 0, }),]);
                renderGlobalAlert({ variant: "warning", statusCode: 500, title: entity.retailPriceChangeReason, instance: baseT("pricingModelService") });
            }

            let newItem: IInvoiceItemCreateDTO = {
                ...takeDataObj,
                retailCostInfo: {
                    vat: entity.percentageOfVatRateRetail,
                    price: entity.retailPrice,
                    vatPrice: entity.retailVatPerUnit,
                    priceIncVat: entity.retailPriceWithVat,
                    sum: entity.retailAmount,
                    vatSum: entity.vatAmountRetail,
                    sumIncVat: entity.retailAmountWithVat,
                    adprice: entity.percentageOfMarkupRetail,
                },
                supplierCostInfo: {
                    vat: entity.supplierVatRatePercentage,
                    price: entity.supplierPriceExcludingVat,
                    vatPrice: entity.supplierVatPerUnit,
                    priceIncVat: entity.supplierPriceWithVat,
                    sum: entity.supplierAmountExcludingVat,
                    vatSum: entity.supplierVatAmount,
                    sumIncVat: entity.supplierAmountWithVat,
                    adprice: entity.supplierMarkupPercentage,
                },
                quantity: entity.quantity,
                registerPrice: entity.registeredPrice,
                producerPrice: entity.manufacturerPrice,
                productMargin: entity.actualMarkupPercentage,
            };

            setData({
                ...data,
                items: addValueToArray(data.items, indexItem, newItem),
            });
            setDisplayItems([
                ...addValueToArray(displayItems, indexDisplayItem, {
                    ...takeDisplayObj,
                    retailVat: entity.percentageOfVatRateRetail,
                    retailPrice: entity.retailPrice,
                    retailPriceIncVat: entity.retailPriceWithVat,
                    retailAmount: entity.retailAmount,
                    retailAmountIncVat: entity.retailAmountWithVat,
                    supplierPrice: entity.supplierPriceExcludingVat,
                    supplierVat: entity.supplierVatRatePercentage,
                    supplierAmount: entity.supplierAmountExcludingVat, 
                    supplierAmountIncVat: entity.supplierAmountWithVat, 
                    quantity: entity.quantity,
                    productMargin: entity.percentageOfMarkupRetail
                }),
            ]);
        });
    }

    function changeItemScalingRatio(value: IInvoiceItemCreateDTO, scalingRatio?: IScalingRatioDTO) {
        if (!scalingRatio) return value;
        return {
            ...value,
            idScalingRatioGlobal: scalingRatio.idGlobal,
            numerator: scalingRatio.numerator,
            dateCreated: DateTime.now()
        } as IInvoiceItemCreateDTO;
    }

    const isValidDocument = (items: IInvoiceItemCreateDTO[], showMessage: () => void): boolean => {
        var flag = true;
        if (!items || items.length === 0) {
            flag = false;
            showMessage();
        }
        return flag;
    };

    function calcAllItems()
    {
        if (!data.items || !displayItems || displayItems.length === 0) return;
        data.items.forEach((x)=>{
            calcRow({propertyName: "quantity", value: x.quantity, idGlobal: x.idGlobal});
        });       
    }

    function getItemsByGtin(barcode: string)
    {   
        itemsByGtin.setKizIdItemsByGtin(undefined)
        itemsByGtin.getItems(barcode, data.idGlobal as string)
    }

    const calcRow =  (row:IChangeDataGrid) => { 
        const item = data.items.find((item) => item.idGlobal === row.idGlobal);
        if (!item) {
            return;
        }

        if (row.propertyName === "quantity") {
            calcInvoiceItem(
                {
                    eventTypes: "QuantityСhanged",
                    idInvoice: "",
                    idSupplier: data.idSupplierGlobal,
                    idStore: data.idStoreGlobal,
                    idItem: "",
                    idGoods: item.idGoodsGlobal as string,
                    idScalingRatio: item.idScalingRatioGlobal,
                    quantity: row.value as number,
                    producerPrice: item.producerPrice,
                    retailCostInfo: { ...item.retailCostInfo, adprice: 0 as number },
                    supplierCostInfo: item.supplierCostInfo,
                    registerPrice: item.registerPrice,
                },
                row.idGlobal
            );
            return;
        }

        switch (row.propertyName) {
            case "retailAmount":
                calcRetailAmount(row.idGlobal, row.value as number, item);
                break;
            case "retailPriceIncVat":
                calcRetailPriceIncVat(row.idGlobal, row.value as number, item);
                break;
        }
    }

    const onOkAction = () => {
        lockingDocuments.delete({
            idTable: idTable,
            idDocument: data.idGlobal as string,
        });
		props.ok(data);
    };

    const onCancel = () => {
        lockingDocuments.delete({
            idTable: idTable,
            idDocument: data.idGlobal as string,
        });

        props.cancel();
    };

    return (
        <>
            <BaseDocumentCreator
                valid={true}
                ok={{
                    onClick: () => {
                        if (
                            isValid() &&
                            isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                            kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "ok" })) === true
                        ) {
                            onOkAction();
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting,
                }}
                save={{
                    onClick: () => {
                        if (
                            isValid() &&
                            isValidDocument(data.items, () => setShowMessageModalValidDocument({ message: errorsT("validDocument"), show: true })) === true &&
                            kizCounter(data.items, () => setShowMessageModal({ message: errorsT("kizCountNotMatch"), show: true, btSaveOrOk: "save" })) === true
                        ) {
                            props.save(data);
                        }
                    },
                    disabled: onlyRead ? true : false || !!itemsValidation.find((x) => x.err == true) || props.isSubmitting,
                    sendRequestGlobal: props.isSubmitting,
                }}
                cancel={{ onClick: onCancel }}
                print={{
                    onClick: () => {
                        return idTable;
                    },
                    onSubItemClick: () => {
                        let arr = new Array<IKeyValuePair>();
                        if (props.document != undefined && props.document.mnemocode != undefined && props.idGlobal != undefined) arr.push({ key: props.idGlobal, value: props.document.mnemocode });
                        return arr;
                    },
                }}
            >
                <div className={styles.glwrapper}>
                    <div className={styles.wrapper}>
                        <div>
                            {isCompact ? (
                                <>
                                    <GridWrapper cols={35}>
                                        <FlexRow className={styles.compactFieldFields} wrap>
                                            <div>
                                                <Button 
                                                    disabled={data.items && data?.items?.length > 0 && !onlyRead ? false : true}
                                                    className={styles.compactField_Recalculate}
                                                    variant="primary"
                                                    onClick={() => {
                                                        calcAllItems();
                                                    }} 
                                                >
                                                    Пересчитать (F5)
                                                </Button>
                                            </div>
                                            <GridSelectorInput
                                                required
                                                disabled={onlyRead ? true : false}
                                                variant="required"
                                                selectorModalJsx={ContractorSelectorModal}
                                                id={"dictionaryInputInvoiceSupplier"}
                                                label={t("documents.invoice.supplier")}
                                                className={styles.compactFieldContractor}
                                                selectedEntity={supplier}
                                                onSelect={(value) => setSupplier({ ...value })}
                                                onClear={() => setSupplier(null)}
                                                error={errors.supplier}
                                                onFocus={() => setErrors({ ...errors, supplier: undefined })}
                                                treeViewCheckDirectoryType='supplier'
                                            />
                                            <TextInput
                                                label={t("documents.invoice.number")}
                                                inputId={"inputInvoiceNumber"}
                                                value={mnemocode}
                                                disabled
                                            />
                                            <DateInput
                                                required
                                                disabled={onlyRead ? true : false}
                                                labelVariant="required"
                                                inputId={"inputInvoiceDate"}
                                                label={t("documents.invoice.date")}
                                                value={data.documentDate}
                                                error={errors.documentDate}
                                                onBlur={(value) => {
                                                    setData({ ...data, documentDate: value as DateTime });
                                                }}
                                            />
                                            <StoreSelectorPanel
                                                we
                                                required
                                                compactMode
                                                contractor={{ 
                                                     label: t("documents.invoice.pharmacy"),
                                                     disabled: onlyRead ? true : false,
                                                     treeViewCheckDirectoryType:'pharmacy'
                                                }}
                                                store={{
                                                    label: t("documents.invoice.store"),
                                                    value: store,
                                                    onSelect: (value) => setStore(value),
                                                    onClear: () => setStore(null),
                                                    error: errors.store,
                                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                                    disabled: onlyRead ? true : false
                                                }}
                                                pricingModel={{
                                                    label: t("documents.invoice.pricingModel"),
                                                    onSelect: (value) => setPricingModel(value),
                                                }}
                                                notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                documentStore={store ?? undefined}
                                                documentPricingModel={pricingModel ?? undefined}
                                            />
                                            <GridSelectorInput
                                                required
                                                disabled={onlyRead ? true : false}
                                                variant="required"
                                                selectorModalJsx={PricingModelSelectorModal}
                                                selectedEntity={pricingModel}
                                                label={t("documents.invoice.pricingModel")}
                                                id={"dictionaryInputPricingModel"}
                                                onSelect={(entity) => setPricingModel({ ...entity })}
                                                onClear={() => setPricingModel(null)}
                                                error={errors.pricingModel}
                                                onFocus={() => {
                                                    setErrors({ ...errors, pricingModel: undefined });
                                                }}
                                            />
                                            <CheckBox
                                                id="isAgentContract"
                                                label={baseT("agentContract")}
                                                disabled={onlyRead ? true : false}
                                                defaultChecked={data.isAgentContract ?? props.document?.isAgentContract ?? false}
                                                onChanged={(checked) => setData({ ...data, isAgentContract: checked })}
                                            />
                                        </FlexRow>
                                        <div className={styles.compactFieldCompactMode}>
                                            <Tooltip title={baseT("basicModeUi")}>
                                                <StockBalanceButtonExpand
                                                    onClick={() => {
                                                        setIsCompact(!isCompact);
                                                    }}
                                                    sizeVariant="normal"
                                                    colorVariant="default"
                                                />
                                            </Tooltip>
                                        </div>
                                    </GridWrapper>
                                    <Accordion bold opened={false} caption="" id={uuidv4()}>
                                        <Accordion opened={false} caption={t("documents.invoice.addition")} id={`additionalInvoiceInfo-${uuidv4()}`}>
                                            <GridWrapper cols={2}>
                                                <GridSelectorInput
                                                    selectorModalJsx={GosContractSelectorModal}
                                                    selectedEntity={gosContract}
                                                    disabled={onlyRead ? true : false}
                                                    onSelect={(value: IEntitySimpleDTO) => {
                                                        setGosContract(value);
                                                        setData({ ...data, idGosContractExternalGlobal: value.idGlobal });
                                                    }}
                                                    label={t("documents.invoice.stateContract")}
                                                    onClear={() => {
                                                        setGosContract(null);
                                                        setData({ ...data, idGosContractExternalGlobal: undefined });
                                                    }}
                                                    inline={true}
                                                />
                                                <DictionaryInput 
                                                    disabled 
                                                    label={t("documents.invoice.contract")} 
                                                    inputId={"dictionaryInputContract"} 
                                                    inline={true} 
                                                />
                                            </GridWrapper>
                                        </Accordion>
                                        <Accordion opened={false} caption={t("documents.invoice.accompanyingDocuments")} id={`accompanyingInvoiceInfo-${uuidv4()}`}>
                                            <FlexRow wrap>
                                                <TextInput
                                                    disabled={onlyRead ? true : false}
                                                    label={t("documents.invoice.invoice")}
                                                    inputId={"inputAccompanyingInvoiceNumber"}
                                                    value={data?.supplierDocNumber}
                                                    onChange={(value) => setData({ ...data, supplierDocNumber: value })}
                                                />
                                                <DateInput
                                                    disabled={onlyRead ? true : false}
                                                    className={styles.field_InvoiceDate}
                                                    label={t("documents.invoice.date")}
                                                    inputId={"inputAccompanyingInvoiceDate"}
                                                    value={data?.supplierDocDate}
                                                    onBlur={(value) => value && setData({ ...data, supplierDocDate: value })}
                                                />
                                                <DateInput
                                                    disabled={onlyRead ? true : false}
                                                    className={styles.field_InvoicePaymentDate}
                                                    label={t("documents.invoice.dateOfPayment")}
                                                    inputId={"inputAccompanyingInvoicePaymentDate"}
                                                    value={data?.datePay}
                                                    onBlur={(value) => value && setData({ ...data, datePay: value })}
                                                />
                                                <DateInput
                                                    className={styles.field_InvoiceActualPaymentDate}
                                                    disabled
                                                    label={t("documents.invoice.inputAccompanyingInvoiceActualPaymentDate")}
                                                    inputId={"inputAccompanyingInvoiceActualPaymentDate"}
                                                />
                                                <TextInput
                                                    disabled={onlyRead ? true : false}
                                                    label={t("documents.invoice.check")}
                                                    inputId={"inputAccompanyingBillNumber"}
                                                    value={data?.supplierBillDocNumber}
                                                    onChange={(value) => {
                                                        setData({ ...data, supplierBillDocNumber: value });
                                                    }}
                                                />
                                                <DateInput
                                                    disabled={onlyRead ? true : false}
                                                    className={styles.field_BillDate}
                                                    label={t("documents.invoice.date")}
                                                    inputId={"inputAccompanyingBillDate"}
                                                    value={data?.supplierBillDocDate}
                                                    onBlur={(value) => {
                                                        value && setData({ ...data, supplierBillDocDate: value });
                                                    }}
                                                />
                                                <TextInput
                                                    className={styles.field_TargetProgram}
                                                    disabled
                                                    label={t("documents.invoice.targetProgram")}
                                                    inputId={"inputAccompanyingTargetProgram"}
                                                    /*value={accompanyingInvoiceInfo?.targetProgram} onChange={(value) => setAccompanyingInvoiceInfo({ ...accompanyingInvoiceInfo, targetProgram: value })}*/
                                                />
                                            </FlexRow>
                                        </Accordion>
                                    </Accordion>
                                </>
                            ) : (
                                <>
                                    <GridWrapper cols={35}>
                                        <GridSelectorInput
                                            required
                                            disabled={onlyRead ? true : false}
                                            variant="required"
                                            selectorModalJsx={ContractorSelectorModal}
                                            className={styles.field_Provider}
                                            inline={true}
                                            id={"dictionaryInputInvoiceSupplier"}
                                            label={t("documents.invoice.supplier")}
                                            selectedEntity={supplier}
                                            onSelect={(value) => setSupplier({ ...value })}
                                            onClear={() => setSupplier(null)}
                                            error={errors.supplier}
                                            onFocus={() => setErrors({ ...errors, supplier: undefined })}
                                            treeViewCheckDirectoryType="supplier"
                                        />
                                        <TextInput
                                            className={styles.field_Number}
                                            disabled={true}
                                            label={t("documents.invoice.number")}
                                            inputId={"inputInvoiceNumber"}
                                            inline={true}
                                            value={mnemocode}
                                        />
                                        <DateInput
                                            required
                                            disabled={onlyRead ? true : false}
                                            labelVariant="required"
                                            inputId={"inputInvoiceDate"}
                                            className={styles.field_Date}
                                            label={t("documents.invoice.date")}
                                            inline={true}
                                            value={data.documentDate}
                                            error={errors.documentDate}
                                            onBlur={(value) => {
                                                setData({ ...data, documentDate: value as DateTime });
                                            }}
                                        />
                                        <div className={styles.field_CompactMode}>
                                            <Tooltip title={baseT("compactModeUi")}>
                                                <StockBalanceButtonCompress
                                                    onClick={() => {
                                                        setIsCompact(!isCompact);
                                                        setIsCompactStore(true);
                                                    }}
                                                    sizeVariant="normal"
                                                    colorVariant="default"
                                                />
                                            </Tooltip>
                                        </div>
                                    </GridWrapper>
                                    <div>
                                        <GridWrapper cols={3}>
                                            <StoreSelectorPanel
                                                we
                                                required
                                                contractor={{ 
                                                    label: t("documents.invoice.pharmacy"),
                                                    disabled: onlyRead ? true : false,
                                                    treeViewCheckDirectoryType:'pharmacy'
                                                }}
                                                store={{
                                                    label: t("documents.invoice.store"),
                                                    value: store,
                                                    onSelect: (value) => setStore(value),
                                                    onClear: () => setStore(null),
                                                    error: errors.store,
                                                    onFocus: () => setErrors({ ...errors, store: undefined }),
                                                    disabled: onlyRead ? true : false
                                                }}
                                                pricingModel={{
                                                    label: t("documents.invoice.pricingModel"),
                                                    onSelect: (value) => setPricingModel(value),
                                                }}
                                                notAutoComplete={isCompactStore === true || props.variant === "edit" || props.variant === "copy"}
                                                documentStore={store ?? undefined}
                                                documentPricingModel={pricingModel ?? undefined}
                                            />
                                            <CheckBox
                                                id="isAgentContract"
                                                label="Агентский контракт"
                                                disabled={onlyRead ? true : false}
                                                defaultChecked={data.isAgentContract ?? props.document?.isAgentContract ?? false}
                                                onChanged={(checked) => setData({ ...data, isAgentContract: checked })}
                                            />
                                        </GridWrapper>
                                    </div>
                                    <GridWrapper cols={6}>
                                        <GridSelectorInput
                                            required
                                            variant="required"
                                            disabled={onlyRead ? true : false}
                                            className={styles.field_PriceModel}
                                            selectorModalJsx={PricingModelSelectorModal}
                                            label={t("documents.invoice.pricingModel")}
                                            id={"dictionaryInputPricingModel"}
                                            inline={true}
                                            selectedEntity={pricingModel}
                                            onSelect={(entity) => setPricingModel({ ...entity })}
                                            error={errors.pricingModel}
                                            onClear={() => setPricingModel(null)}
                                            onFocus={() => {
                                                setErrors({ ...errors, pricingModel: undefined });
                                            }}
                                        />
                                        <Button 
                                            disabled={data.items && data?.items?.length > 0 && !onlyRead ? false : true}
                                            className={styles.field_Recalculate} variant="primary"
                                            onClick={() => {
                                                calcAllItems();
                                            }} 
                                        >
                                            Пересчитать (F5)
                                        </Button>
                                    </GridWrapper>

                                    <Accordion opened={false} caption={t("documents.invoice.addition")} id={`additionalInvoiceInfo-${uuidv4()}`}>
                                        <GridWrapper cols={2}>
                                            <GridSelectorInput
                                                selectorModalJsx={GosContractSelectorModal}
                                                selectedEntity={gosContract}
                                                disabled={onlyRead ? true : false}
                                                onSelect={(value: IEntitySimpleDTO) => {
                                                    setGosContract(value);
                                                    setData({ ...data, idGosContractExternalGlobal: value.idGlobal });
                                                }}
                                                label={t("documents.invoice.stateContract")}
                                                onClear={() => {
                                                    setGosContract(null);
                                                    setData({ ...data, idGosContractExternalGlobal: undefined });
                                                }}
                                                inline={true}
                                            />
                                            <DictionaryInput disabled label={t("documents.invoice.contract")} inputId={"dictionaryInputContract"} inline={true} />
                                        </GridWrapper>
                                    </Accordion>
                                    <Accordion opened={false} caption={t("documents.invoice.accompanyingDocuments")} id={`accompanyingInvoiceInfo-${uuidv4()}`}>
                                        <GridWrapper cols={4}>
                                            <TextInput
                                                disabled={onlyRead ? true : false}
                                                className={styles.field_InvoiceNumber}
                                                labelClassName={styles.invoiceNumberLabel}
                                                label={t("documents.invoice.invoice")}
                                                inputId={"inputAccompanyingInvoiceNumber"}
                                                inline={true}
                                                value={data?.supplierDocNumber}
                                                onChange={(value) => setData({ ...data, supplierDocNumber: value })}
                                            />
                                            <DateInput
                                                disabled={onlyRead ? true : false}
                                                className={styles.field_InvoiceDate}
                                                label={t("documents.invoice.date")}
                                                inputId={"inputAccompanyingInvoiceDate"}
                                                inline={true}
                                                value={data?.supplierDocDate}
                                                onBlur={(value) => value && setData({ ...data, supplierDocDate: value })}
                                            />
                                            <DateInput
                                                disabled={onlyRead ? true : false}
                                                className={styles.field_InvoicePaymentDate}
                                                label={t("documents.invoice.dateOfPayment")}
                                                inputId={"inputAccompanyingInvoicePaymentDate"}
                                                inline={true}
                                                value={data?.datePay}
                                                onBlur={(value) => value && setData({ ...data, datePay: value })}
                                            />
                                            <DateInput
                                                className={styles.field_InvoiceActualPaymentDate}
                                                disabled
                                                label={t("documents.invoice.inputAccompanyingInvoiceActualPaymentDate")}
                                                inputId={"inputAccompanyingInvoiceActualPaymentDate"}
                                                inline={true}
                                            />
                                        </GridWrapper>

                                        <GridWrapper cols={4}>
                                            <TextInput
                                                disabled={onlyRead ? true : false}
                                                className={styles.field_BillNumber}
                                                label={t("documents.invoice.check")}
                                                inputId={"inputAccompanyingBillNumber"}
                                                inline={true}
                                                value={data?.supplierBillDocNumber}
                                                onChange={(value) => {
                                                    setData({ ...data, supplierBillDocNumber: value });
                                                }}
                                            />
                                            <DateInput
                                                disabled={onlyRead ? true : false}
                                                className={styles.field_BillDate}
                                                label={t("documents.invoice.date")}
                                                inputId={"inputAccompanyingBillDate"}
                                                inline={true}
                                                value={data?.supplierBillDocDate}
                                                onBlur={(value) => {
                                                    value && setData({ ...data, supplierBillDocDate: value });
                                                }}
                                            />

                                            <TextInput
                                                className={styles.field_TargetProgram}
                                                disabled
                                                label={t("documents.invoice.targetProgram")}
                                                inputId={"inputAccompanyingTargetProgram"}
                                                inline={true}
                                            />
                                        </GridWrapper>
                                    </Accordion>
                                </>
                            )}
                            <FlexRow className={styles.field_flexbox}>
                                <PositionEditPanel
                                    kiz={{
                                        onClick: () => {
                                            itemsByGtin.setKizIdItemsByGtin(undefined)
                                            setShowKizScan(true)
                                            setKizScanComponent()
                                        },
                                        disabled: (selectedItemCreateDto?.isKiz || selectedItemCreateDto?.isKizBox) && !onlyRead ? false : true,
                                    }}
                                    edit={{
                                        onClick: () => {
                                            if (data.idGosContractExternalGlobal) {
                                                gosContractDP.getById(data.idGosContractExternalGlobal, (gosContract) => {
                                                    const gosContractGoods = gosContract.goods.find((x) => x.goods.idGlobal === (selectedItemGoods?.idGlobal as string));
                                                    tabsContext.openChild(
                                                        "invoice_item_creator_plugin",
                                                        "edit",
                                                        undefined,
                                                        (value: IInvoiceItemCreateDTO, displayItem: IDisplayItem) => {
                                                            setData({
                                                                ...data,
                                                                items: [...data.items.filter((x) => x.idGlobal !== value.idGlobal), value],
                                                            });
                                                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem.idGlobal), displayItem]);
                                                        },
                                                        {
                                                            item: data.items.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                            goods: {
                                                                displayName: selectedItemGoods?.goodsName.name as string,
                                                                idGlobal: selectedItemGoods?.idGlobal as string,
                                                            } as IEntitySimpleDTO,
                                                            displayItem: displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                            idPricingModel: data.idPricingModelGlobal,
                                                            idSupplier: data.idSupplierGlobal,
                                                            idStore: data.idStoreGlobal,
                                                            scalingRatio: { displayName: selectedScalingRation?.displayName, idGlobal: selectedScalingRation?.idGlobal } as IEntitySimpleDTO,
                                                            gosContractFields: {
                                                                isGosContract: true,
                                                                gosContractGoods: gosContractGoods ?? null,
                                                            },
                                                            currentSupplier,
                                                            currentPharmacy,
                                                            variant: 'edit',
                                                            onlyRead: onlyRead,
                                                            documentDate: data.documentDate
                                                        }
                                                    );
                                                });
                                            } else {
                                                tabsContext.openChild(
                                                    "invoice_item_creator_plugin",
                                                    "edit",
                                                    undefined,
                                                    (value: IInvoiceItemCreateDTO, displayItem: IDisplayItem) => {
                                                        setData({
                                                            ...data,
                                                            items: [...data.items.filter((x) => x.idGlobal !== value.idGlobal), value],
                                                        });
                                                        setDisplayItems([...displayItems.filter((x) => x.idGlobal !== displayItem.idGlobal), displayItem]);
                                                    },
                                                    {
                                                        item: data.items.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                        goods: {
                                                            displayName: selectedItemGoods?.goodsName.name as string,
                                                            idGlobal: selectedItemGoods?.idGlobal as string,
                                                        } as IEntitySimpleDTO,
                                                        scalingRatio: { displayName: selectedScalingRation?.displayName, idGlobal: selectedScalingRation?.idGlobal } as IEntitySimpleDTO,
                                                        displayItem: displayItems.find((x) => x.idGlobal === selectedItem?.idGlobal),
                                                        idPricingModel: data.idPricingModelGlobal,
                                                        idSupplier: data.idSupplierGlobal,
                                                        idStore: data.idStoreGlobal,
                                                        gosContractFields: {
                                                            isGosContract: false,
                                                            gosContractGoods: null,
                                                        },
                                                        currentSupplier,
                                                        currentPharmacy,
                                                        variant: 'edit',
                                                        onlyRead: onlyRead,
                                                        documentDate: data.documentDate
                                                    }
                                                );
                                            }
                                        },
                                        disabled: (selectedItem && selectedItemGoods && selectedScalingRation) ? false : true,
                                    }}
                                    delete={{
                                        onClick: () => {
                                            setData({
                                                ...data,
                                                items: [...data.items.filter((x) => x.idGlobal !== selectedItem?.idGlobal)],
                                            });
                                            setDisplayItems([...displayItems.filter((x) => x.idGlobal !== selectedItem?.idGlobal)]);
                                            setSelectedItem(undefined);

                                            const itemsValid = itemsValidation.filter((item) => item.idGlobal !== selectedItem?.idGlobal);
                                            const itemsFilterValid = itemsFilter.filter((item) => item.idItem !== selectedItem?.idGlobal);
                                            setItemsValidation(itemsValid);
                                            setItemsFilter(itemsFilterValid);
                                        },
                                        disabled: selectedItem && !onlyRead ? false : true,
                                    }}
                                />
                                <GridSelectorInput
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    disabled={onlyRead ? true : false}
                                    selectorModalJsx={GoodsSelectorModal}
                                    searchOption={searchOption.value}
                                    className={styles.field_SelectionGoods}
                                    id={"inputAccompanyingSelectionGoods"}
                                    inline={true}
                                    label={t("documents.invoice.selectionOfGoods")}
                                    valid={() => isValid()}
                                    onSelect={(entity,searchQuery) => {
                                        if (searchOption.value === SearchOption.barcode || searchOption.value === SearchOption.goodsCode) {
                                            if (entity.length > 1) setGoodsViewItem({ item: entity, show: true });
                                            else
                                                callOpenInvoiceItemCreatorPlugin(entity);
                                        } 
                                        else if (searchOption.value === SearchOption.gtin && searchQuery) {
                                            getItemsByGtin(searchQuery as string)
                                        } else {
                                            callOpenInvoiceItemCreatorPlugin(entity);
                                        }
                                    }}
                                />
                                <Select
                                    disabled={onlyRead ? true : false}
                                    barcodeScan={tabsContext.currentTab && tabsContext.currentTab?.id === currentIdTab.current}
                                    value={searchOption}
                                    onSelect={(option) => {
                                        setSearchOption(option);
                                    }}
                                    className={styles.field_SelectBlock}
                                    options={[
                                        { displayName: t("documents.invoice.barcode"), value: "barcode" },
                                        { displayName: t("documents.invoice.name"), value: "name" },
                                        { displayName: t("documents.invoice.goodsCode"), value: "goodsCode" },
                                        { displayName: t("documents.invoice.gtin"), value: SearchOption.gtin, hidden: props.variant !== "edit"} 
                                    ]}
                                    defaultOption={defaultSearchOption}
                                />

                                <div className={styles.SettingsButton}>
                                    <SettingsButtonModal 
                                        disabled={onlyRead ? true : false}
                                    />
                                </div>
                            </FlexRow>
                            <TextInput
                                disabled={onlyRead ? true : false}
                                className={styles.scanField}
                                autoFocus={scanFocus}
                                value={scanValue}
                                onChange={(e) => {
									let isKiz = data.items.find(c => c.idGlobal === selectedItem?.idGlobal)?.isKiz
                                    let isKizBox = data.items.find(c => c.idGlobal === selectedItem?.idGlobal)?.isKizBox
                                    if (isKiz || isKizBox) {
                                        addKiz(e);
                                        setScanValue(e);
                                    }
                                }}
                            />
                            <ChangeableGrid
                                documentStateProc={onlyRead}
                                itemsFilter={itemsFilter}
                                itemsValidation={itemsValidation}
                                setItemsValidation={(value) => {
                                    const newItemsValidation = itemsValidation.map((item) => (item.idGlobal === value[0].idGlobal ? { ...item, ...value } : item));
                                    setItemsValidation(newItemsValidation);
                                }}
                                gridId={uuidv4()}
                                items={data?.items}
                                data={displayItems}
                                autoSelect
                                hiddenPagination={undefined}
                                filter={gridFilter}
                                totalCount={displayItems.length}
                                getStyleCell={(cell, cells) => getStyleCell(cell, cells, documentType)}
                                plugin={InvoiceCreatorItemGridPluginSettings}
                                selectedItem={selectedItem}
                                onColumnEnter={(row: IChangeDataGrid) => clearTimer(() => calcRow(row))}
                                onChangeData={(row: IChangeDataGrid) => setTimer(() => calcRow(row))}
                                onSelect={(row) => setSelectedItem(row)}
                                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                                onSort={(i) => dispatchGridFilter({ type: "sort", payload: i.propertyName })}
                                onFilterDelete={(i) => dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName })}
                                onPageNumberChange={(n) => dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } })}
                                onNumberPerPageChange={(n) => dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })}
                                isSortByCreatedDate
                            />
                        </div>
                        <Accordion opened={false} caption="" id={`accompanyingInvoiceInfo-${uuidv4()}`} bold documentSticky>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent disabled label={t("documents.invoice.averageMarkup")} inputId={"inputAccompanyingAverageMarkup"} value={+averageMarkup} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent disabled label={t("documents.invoice.supplierVatAmounts")} inputId={"inputAccompanyingNdsSum"} value={sum.amountSupplierVatSum} />
                                <MoneyFormatComponent
                                    disabled
                                    label={t("documents.invoice.supplierAmountsWithoutVat")}
                                    inputId={"inputAccompanyingNdsProviderSum"}
                                    value={Number(sum.amountSupplierSum)}
                                />
                                <MoneyFormatComponent disabled label={t("documents.invoice.supplierAmounts")} inputId={"inputAccompanyingProviderSum"} value={(sum.amountSupplierSumIncVat)} />
                            </GridWrapper>
                            <GridWrapper cols={3}>
                                <MoneyFormatComponent disabled label={t("documents.invoice.retailAmountWithoutVat")} inputId={"inputAccompanyingRetailSumBNds"} value={(sum.amountRetailSum)} />
                                <MoneyFormatComponent disabled label={t("documents.invoice.retailVatAmount")} inputId={"inputAccompanyingNdsRetailSumNds"} value={(sum.amountRetailVatSum)} />
                                <MoneyFormatComponent disabled label={t("documents.invoice.retailAmount")} inputId={"inputAccompanyingRetailSum"} value={(sum.amountRetailSumIncVat)} />
                            </GridWrapper>
                        </Accordion>
                    </div>
                </div>

                {showKizScan && selectedScalingRation && (kizScanModal)}

                {showMessageModal.show && (
                    <MessageModalWindow
                        message={showMessageModal.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModal({ show: false, message: "" });
                                showMessageModal.btSaveOrOk === "ok" ? onOkAction() : props.save(data);
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModal({ show: false, message: "" }) }}
                    />
                )}

                {showMessageModalValidDocument.show && (
                    <MessageModalWindow
                        message={showMessageModalValidDocument.message}
                        ok={{
                            onClick: () => {
                                setShowMessageModalValidDocument({ show: false, message: "" });
                            },
                        }}
                        cancel={{ onClick: () => setShowMessageModalValidDocument({ show: false, message: "" }) }}
                    />
                )}

                {itemsGroupAlert.show && (
                    <MessageModalWindow
                        message={baseT("messages.priceLotChanged")}
                        ok={{
                            onClick: () => setItemsGroupAlert({ show: false }),
                        }}
                        cancel={{
                            onClick: () => {},
                            hidden: true,
                        }}
                    />
                )}

                {goodsViewItem.item && goodsViewItem.show && (
                    <InvoiceGoodsSelectModal
                        ok={(value: IEntitySimpleDTO) => {
                            callOpenInvoiceItemCreatorPlugin(value);
                        }}
                        cancel={() => setGoodsViewItem({ item: goodsViewItem.item, show: false })}
                        data={goodsViewItem.item}
                    />
                )}
                {
                    itemsByGtin.isShowModal() && data.idGlobal &&
                        <InvoiceItemsSelectorModal 
                        idGlobalInvoice={data.idGlobal as string} 
                        gridFilter={{
                            numberPerPage: 100,
                            pageNumber: 1,
                            columnFilters: [{
                                name: "gtin",
                                operator: "Eq",
                                value: itemsDocumentByGtin?.kizParsedDto?.kizBoxData?.gtin ?? itemsDocumentByGtin?.kizParsedDto?.kizData?.gtin
                             } as IGridColumnFilter]
                        } as unknown as IGridFilter}
                        ok={(entities,obj) => {     
                            itemsByGtin.getKiz(entities.idGlobal as string,data.items, (e) => {
                                setKizByGtin(e)
                                itemsByGtin.setShowModal(false)
                            })
                        }} 
                        cancel={() => itemsByGtin.setShowModal(false)}
                />
                }

            </BaseDocumentCreator>
        </>
    );
};

export default InvoiceCreatorForm;
