import classNames from "classnames";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentGridStateType } from "../../../@types/documents";
import { DocumentStatusType, DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { Spinner } from "../../../components/spiner/Spinner";
import { TabsPanel } from "../../../components/tabs";
import { IInvoiceViewDTO } from "../../../libs/coreapi-dto/documents/invoice";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { InvoiceDataProvider } from "../../../Services/DataProviders/InvoiceDataProvider";
import { KizDataProvider } from "../../../Services/DataProviders/KizDataProvider";
import { KizDocDataProvider } from "../../../Services/DataProviders/KizDocDataProvider";
import useGridFilter, { DefaultDateGridFilter, DefaultDatePeriod } from "../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { UserActionLogModal } from "../../Service/UserActionLog/view/UserActionLogModal";
import styles from "../../styles/index.module.scss";

const InvoicePluginView: FC<IGridProps> = (props) => {
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();

    const invoiceDP = new InvoiceDataProvider(appContext.coreApiService);
    const kizDocDP = new KizDocDataProvider(appContext.coreApiService);
    const tabCtx = useTabsContext();
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");

    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.InProcess);

    const [data, setData] = useState<IInvoiceViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);

    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    //status type for invoice in gridfilter
    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    // one selected items in invoice
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItemView, setSelectedItemViews] = useState<IInvoiceViewDTO>();

    // selected items in invoice for multiselect
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);

    //
    const [impossibilityProcess, setImpossibilityProcess] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    //forModalLog
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        setIsSubmitting(true);
        invoiceDP.getView(gridFilter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            if (viewState == "refresh") setViewState("view");
            if (totalCount === 0) {
                setLoadingStatus(LoadingStatus.NoData);
            } else {
                setLoadingStatus(LoadingStatus.Completed);
            }
            setIsSubmitting(false);
        });
    }, [gridFilter, viewState]);

    useEffect(() => {
        if (tabCtx.currentTab?.title === t("sidePanel.documents.invoices")) {
            setShowDetailsTabs(true);
        } else {
            setShowDetailsTabs(false);
        }
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }
        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        setSelectedItem(undefined);
        pluginCtx.masterGrid.onUnselectEvent();
    }, [documentStatusType, gridFilter, viewState]);

    const deleteRow = () => {
        if (selectedItem?.[0]?.isDeleted || selectedDocumentState === "save" || selectedDocumentState === "proc") {
        } else {
            invoiceDP.markDelete(selectedItem?.idGlobal as string, () => {
                setViewState("refresh");
            });
        }
    };

    return (
        <>
            <PluginWrapper>
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="invoice_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        dataProvider={invoiceDP}
                        pluginSettings={props.plugin}
                        idTable={IdTableVariant.Invoice}
                        setViewState={(vs) => setViewState(vs)}
                        advancedFeatures
                        impossibilityAction={{ impossibilityProcess: impossibilityProcess }}
                        items={[
                            {
                                label: "Отправить 702 схему",
                                onClick: () => {
                                    kizDocDP.create702Document(selectedItem?.idGlobal as string);
                                },
                                disabled: selectedItemView?.isMdlp702SchemeAllowed ? false : true,
                            },
                            {
                                label: "Просмотреть лог пользователя",
                                onClick: () => selectedItem && setShowModalLog(true),
                                disabled: selectedItem ? false : true,
                            },
                        ]}
                        mnemocode={selectedItemView?.mnemocode}
                        checkLockingDocuments
                    />
                </ToolbarWrapper>

                <div className={styles.gridWrapper}>
                    <div className={styles.masterGrid}>
                        {isSubmitting ? (
                            <Spinner />
                        ) : (
                            <DefaultGrid
                                openWithEnterForEdit={{
                                pluginTabContext:"invoice_creator_plugin",
                                mnemocode:selectedItemView?.mnemocode,
                                selectedDocumentState}}
                                gridId={props.gridId}
                                data={data}
                                documentStatus
                                loadingStatus={loadingStatus}
                                setLoadingStatus={setLoadingStatus}
                                separator
                                actionGridRow={{ delete: () => deleteRow() }}
                                filter={gridFilter}
                                hiddenPagination={undefined}
                                dataProvider={invoiceDP}
                                totalCount={totalCount}
                                plugin={props.plugin}
                                multipleSelect={multipleSelect}
                                selectedItem={selectedItem}
                                selectedItems={selectedItems}
                                onSelect={(row) => {
                                    setSelectedItem(row);
                                    row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.invoice) : pluginCtx.masterGrid.onUnselectEvent();
                                }}
                                onMultipleSelect={(rows) => {
                                    setSelectedItems(rows);
                                }}
                                onSort={(i) => {
                                    dispatchGridFilter({ type: "sort", payload: i.propertyName });
                                }}
                                onFilterDelete={(i) => {
                                    i.propertyName === "documentState" && setDocumentStatusType(undefined);
                                    dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                                }}
                                onPageNumberChange={(n) => {
                                    dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } });
                                }}
                                onNumberPerPageChange={(n) => {
                                    dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } });
                                }}
                            />
                        )}
                    </div>
                    {
                        <div className={styles.detailsTabView}>
                            {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect && (
                                <>
                                    <div className={tabsStyles.tabsPanelWrapper}>
                                        <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                            <TabsPanel
                                                id="detailsTabsPanel"
                                                activeId={detailsTabsPanel.currentTab?.id}
                                                tabs={detailsTabsPanel.tabs}
                                                onActive={(id) => {
                                                    dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                                }}
                                            ></TabsPanel>
                                        </div>
                                    </div>
                                    <div className={tabsStyles.contentWrapper}>
                                        {detailsTabsPanel.tabs.map((item) => {
                                            return (
                                                <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                    {item.view.content}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                    {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
                </div>
            </PluginWrapper>
        </>
    );
};

export default InvoicePluginView;
