interface ITreeViewContextState {
    visiblePanel: boolean
    selectedFolder?: ITreeViewFolder
}

type ITreeViewContextAction = 
    | { type: 'select', payload: ITreeViewFolder}
    | { type: 'unselect' }
    | { type: 'visible', payload: boolean }

export const defaultTreeViewContextState: ITreeViewContextState = { visiblePanel: false }

export function treeViewContextReducerHandler(state: ITreeViewContextState, action: ITreeViewContextAction) {
    switch (action.type) {
        case 'select': return { ...state, selectedFolder: action.payload }
        case 'unselect': return { ...state, selectedFolder: undefined }
        case 'visible': return { ...state, visiblePanel: action.payload }
        default: 
            return state
    }
}