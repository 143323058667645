import classNames from "classnames";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { IDatePeriod } from "../../../components/datePeriodPicker/datePeriodPicker";
import { DefaultGrid } from "../../../components/grids/default/defaultGrid";
import { PluginWrapper, ToolbarWrapper } from "../../../components/plugins";
import { TabsPanel } from "../../../components/tabs";
import { IMovementViewDTO } from "../../../libs/coreapi-dto/documents/movement";
import { MovementDataProvider } from "../../../Services/DataProviders/MovementDataProvider";
import useGridFilter, { DefaultDateGridFilter } from "../../../system/hooks/useGridFilter";
import { useDetailsTabsPanel } from "../../../system/hooks/useTabsPanel";
import { useAppContext } from "../../../system/providers/appContextProvider";
import { usePluginContext } from "../../../system/providers/plugin";
import styles from "../../styles/index.module.scss";
import tabsStyles from "../../../pages/styles/homePage.module.scss";
import { DocumentGridStateType } from "../../../@types/documents";
import { checkAccessStatus } from "../../CommonHelperFunctions";
import { useUserContext } from "../../../system/providers/userContextProvider";
import { DocumentStatusType, DocumentType, IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal";
import DefaultDocumentsCommandsPanelV2 from "../../../components/commandsPanels/DefaultDocumentsCommandsPanelV2";
import { useTabsContext } from "../../../system/providers/tabsProvider";
import { Spinner } from "../../../components/spiner/Spinner";
import { UserActionLogModal } from "../../Service/UserActionLog/view/UserActionLogModal";

const DefaultGridFilter = (datePeriod) => {
    return {
        numberPerPage: 10,
        pageNumber: 1,
        fieldOrderBy: "documentDate",
        orderDesc: true,
        columnFilters: [
            ...(datePeriod?.startDate
                ? [
                      {
                          name: "documentDate",
                          value: datePeriod.startDate.toFormat("yyyyMMdd"),
                          operator: "MoreOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
            ...(datePeriod?.endDate
                ? [
                      {
                          name: "documentDate",
                          value: datePeriod.endDate.toFormat("yyyyMMdd"),
                          operator: "LessOrEq",
                          invisible: true,
                      } as IGridColumnFilter,
                  ]
                : []),
        ],
    };
};

export const MovementPluginView: FC<IGridProps> = (props) => {
    //context
    const appContext = useAppContext();
    const pluginCtx = usePluginContext();
    const tabCtx = useTabsContext();
    const userContext = useUserContext();

    //dataproviders
    const movementDP = new MovementDataProvider(appContext.coreApiService);

    const [data, setData] = useState<IMovementViewDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
    const [gridFilter, dispatchGridFilter] = useGridFilter(DefaultDateGridFilter());
    const [viewState, setViewState] = useState<DocumentGridStateType>("view");
    const [detailsTabsPanel, dispatchDetailsTabsPanel] = useDetailsTabsPanel(props.plugin.mnemocode);

    const [documentStatusType, setDocumentStatusType] = useState<DocumentStatusType | undefined>();
    const [selectedDocumentState, setSelectedDocumentState] = useState<DocumentStatusType | undefined>();
    const [showDetailsTabs, setShowDetailsTabs] = useState<boolean>(false);

    const [, setLoadingStatus] = useState<LoadingStatus>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
    const [selectedItemView, setSelectedItemViews] = useState<IMovementViewDTO>();
    //forModalLog
    const [showModalLog, setShowModalLog] = useState<boolean>(false);

    useEffect(() => {
        setIsSubmitting(true);
        movementDP.getView(gridFilter, async (data, totalCount) => {
            setData(data);
            setTotalCount(totalCount);
            setLoadingStatus(LoadingStatus.Completed);
            setIsSubmitting(false);
        });
    }, [gridFilter]);

    useEffect(() => {
        if (viewState === "refresh") {
            setIsSubmitting(true);
            movementDP.getView(gridFilter, async (data, totalCount) => {
                setData(data);
                setTotalCount(totalCount);
                setLoadingStatus(LoadingStatus.Completed);
                setViewState("view");
                setIsSubmitting(false);
            });
        }
    }, [viewState]);

    useEffect(() => {
        if (!multipleSelect) {
            setSelectedItems([]);
        } else {
            setSelectedItem(undefined);
        }

        if (selectedItem) {
            const documentState = selectedItem.cells.find((c) => c.propertyName === "documentState")?.value as DocumentStatusType;
            setSelectedDocumentState(documentState ?? undefined);
            setSelectedItemViews(data.find((x) => x.idGlobal === selectedItem.idGlobal));
        } else {
            setSelectedItemViews(undefined);
        }
    }, [multipleSelect, selectedItem]);

    useEffect(() => {
        pluginCtx.masterGrid.onUnselectEvent();
        setShowDetailsTabs(true);
    }, [tabCtx.currentTab]);

    return (
        <PluginWrapper>
            {checkAccessStatus(props.plugin.permission as IPermission, userContext.userPermission) && (
                <ToolbarWrapper>
                    <DefaultDocumentsCommandsPanelV2
                        selectedItems={[selectedItems,setSelectedItems]}
                        multipleSelect={[multipleSelect, setMultipleSelect]}
                        selectedItem={[selectedItem, setSelectedItem]}
                        gridFilter={[gridFilter, dispatchGridFilter]}
                        plugin="movement_creator_plugin"
                        documentStatusType={[documentStatusType, setDocumentStatusType]}
                        selectedDocumentStatusType={selectedDocumentState}
                        idTable={IdTableVariant.Movement}
                        dataProvider={movementDP}
                        pluginSettings={props.plugin}
                        setViewState={(vs) => setViewState(vs)}
                        mnemocode={selectedItemView?.mnemocode}
                        items={[
                            {
                                label: "Просмотреть лог пользователя",
                                onClick: () => selectedItem && setShowModalLog(true),
                                disabled: selectedItem ? false : true,
                            },
                        ]}
                        advancedFeatures
                        checkLockingDocuments
                    />
                </ToolbarWrapper>
            )}

            <div className={styles.gridWrapper}>
                <div className={styles.masterGrid}>
                    {isSubmitting ? (
                        <Spinner />
                    ) : (
                        <DefaultGrid
                            selectedItems={selectedItems}
                            openWithEnterForEdit={{
                            pluginTabContext:"movement_creator_plugin",
                            mnemocode:selectedItemView?.mnemocode,
                            selectedDocumentState}}
                            documentStatus
                            gridId={props.gridId}
                            data={data}
                            filter={gridFilter}
                            dataProvider={movementDP}
                            totalCount={totalCount}
                            plugin={props.plugin}
                            selectedItem={selectedItem}
                            multipleSelect={multipleSelect}
                            onMultipleSelect={(rows) => setSelectedItems(rows)}
                            onSelect={(row) => {
                                setSelectedItem(row);
                                row ? pluginCtx.masterGrid.onSelectEvent(row, DocumentType.movement) : pluginCtx.masterGrid.onUnselectEvent();
                            }}
                            onSort={(i) => {
                                dispatchGridFilter({ type: "sort", payload: i.propertyName });
                            }}
                            onFilterDelete={(i) => {
                                dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName });
                            }}
                            onPageNumberChange={(n) => {
                                dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } });
                            }}
                            onNumberPerPageChange={(n) => {
                                dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } });
                            }}
                        />
                    )}
                </div>
                {
                    <div className={styles.detailsTabView}>
                        {showDetailsTabs && detailsTabsPanel.tabs.length > 0 && selectedItem && !multipleSelect &&(
                            <>
                                <div className={tabsStyles.tabsPanelWrapper}>
                                    <div id="detailsTabsPanelWrapper" style={{ position: "relative", overflow: "hidden", width: "100%" }}>
                                        <TabsPanel
                                            id="detailsTabsPanel"
                                            activeId={detailsTabsPanel.currentTab?.id}
                                            tabs={detailsTabsPanel.tabs}
                                            onActive={(id) => {
                                                dispatchDetailsTabsPanel({ type: "activate", payload: id });
                                            }}
                                        ></TabsPanel>
                                    </div>
                                </div>
                                <div className={tabsStyles.contentWrapper}>
                                    {detailsTabsPanel.tabs.map((item) => {
                                        return (
                                            <div key={item.id} className={classNames(item.id === detailsTabsPanel.currentTab?.id ? tabsStyles.contentVisible : tabsStyles.contentHidden)}>
                                                {item.view.content}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                }
                {showModalLog && <UserActionLogModal idDocumentGlobal={selectedItem?.idGlobal} onClick={() => setShowModalLog(false)} />}
            </div>
        </PluginWrapper>
    );
};
