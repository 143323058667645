
type AccessStatus = 'FullAccess' | 'View'


export const checkLockStatus = (permission: IPermission, permissionList: string[]): boolean => {
    const accessStatus = permissionList.find(x => x.split('.')[0] === permission)?.split('.')[1] as AccessStatus
    if (accessStatus === 'FullAccess') {
        return false
    } else if (accessStatus === 'View') {
        return true
    } else {
        return true
    }
}

export const checkAccessStatus = (permission: IPermission, permissionList: string[]): boolean => {
    if (permissionList.find(x => x.split('.')[0] === permission)) {
        return true
    } else {
        return false
    }
}

export interface ActiveCommands
{
    add: boolean
    edit: boolean
    copy: boolean
    print: boolean
    delete: boolean
    cancel: boolean
    restore: boolean
    search: boolean
    refresh: boolean
    showDeleted: boolean
    process: boolean
    unProcess: boolean
    editGoods: boolean
    //groupProcessing
}

export const checkActiveCommands = (permission: IPermission, permissionList: string[]): ActiveCommands =>
{

    let lock = checkLockStatus(permission, permissionList);
    if (lock)
    {
        return {
            edit: true,
            print: true,
            search: true,
            refresh: true,
            showDeleted: true,
            add: false,
            copy: false,
            delete: false,
            cancel: false,
            restore: false,
            process: false,
            unProcess: false,
            editGoods: true
        } as ActiveCommands
    }

    return {
        edit: true,
        print: true,
        search: true,
        refresh: true,
        showDeleted: true,
        add: true,
        copy: true,
        delete: true,
        cancel: true,
        restore: true,
        process: true,
        unProcess: true,
        editGoods: true
    } as ActiveCommands

}