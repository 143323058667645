import { IStoreDTO, IStoreViewDTO } from "../../../libs/coreapi-dto/dirs/store";
import { CoreApiResponse, IRequestEventHandler } from "../common";
import { BaseDirRequest } from "./baseDirRequest";


interface IStoreCreate extends IStoreDTO { }
interface IStoreUpdate extends IStoreDTO { }

class StoreRequest extends BaseDirRequest<IStoreViewDTO, IStoreDTO> {

    constructor(baseUrl: string, token: string, eventHandler: IRequestEventHandler){
        super(baseUrl, "stores", token, eventHandler)
    }
    
    create(body: IStoreCreate, callback: (e: CoreApiResponse<string>) => void) {
        super.create(body, callback)
    }

    update(idGlobal: string, body: IStoreUpdate, callback: (e: CoreApiResponse<null>) => void) {
        super.update(idGlobal, body, callback)
    }
}

export default StoreRequest