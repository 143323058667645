import KizDocRequest from "../../libs/core-api-requests/dirs/kizDocRequest";
import { IKizDTO, IKizViewDTO } from "../../libs/coreapi-dto/dirs/kiz";
import CoreApiService from "../../system/api/coreApiService";
import { DictionaryDataProvider } from "./Common";

export class KizDocDataProvider extends DictionaryDataProvider<IKizViewDTO, IKizDTO> {

    private _coreApiService: CoreApiService

    constructor(coreApiService: CoreApiService) {
        super();
        this._coreApiService = coreApiService
    }

    getDataRequest(): Promise<KizDocRequest> {
        return this._coreApiService.kizDocRequest
    }

    create702Document(idInvoiceGlobal: string) {
        this.getDataRequest()
            .then(r => r
                .create702Document(idInvoiceGlobal)
            );
    }
}