import BunchDbPluginSettings from "./components/ModalWindow/BunchDB/BuchDbPluginSettings"
import SettingsAdditionalPluginSettings from "./components/ModalWindow/SettingsAdditional/SettingsAdditionalPluginSettings"

const CashRegistersPluginSettings: IPluginSettings = {
    name: 'Кассовые аппараты',
    mnemocode: 'cash_registers_plugin',
    permission: 'CashRegister',
    columns: [
        {
            order: 0,
            propertyName: 'idGlobal',
            propertyType: 'string',
            displayName: 'idGlobal',
            visibility: false,
            width: 100
        }, {
            order: 1,
            propertyName: 'idCashRegister',
            propertyType: 'string',
            displayName: 'ID кассы',
            visibility: true,
            width: 157
        }, {
            order: 2,
            propertyName: 'nameCashRegister',
            propertyType: 'string',
            displayName: 'Наименование',
            visibility: true,
            width: 167
        },
        {
            order: 3,
            propertyName: 'numberCashRegister',
            propertyType: 'number',
            displayName: 'Номер ККМ',
            visibility: true,
            width: 157
        }, {
            order: 4,
            propertyName: 'contractorName',
            propertyType: 'string',
            displayName: 'Контрагент',
            visibility: true,
            width: 167
        }, {
            order: 5,
            propertyName: 'companyId',
            propertyType: 'string',
            displayName: 'Guid компании',
            visibility: true,
            width: 177
        },{
            order: 6,
            propertyName: 'dateCreated',
            propertyType: 'datetime',
            displayName: 'Дата создания',
            visibility: true,
            width: 167
        },
        {
            order: 7,
            propertyName: 'dateModified',
            propertyType: 'datetime',
            displayName: 'Дата изменения',
            visibility: true,
            width: 167
        },
        {
            order: 8,
            propertyName: 'dateDeleted',
            propertyType: 'datetime',
            displayName: 'Дата удаления',
            visibility: true,
            width: 167
        },
    ],
    detailsPlugins: [
        BunchDbPluginSettings,
        SettingsAdditionalPluginSettings
    ]
}

export default CashRegistersPluginSettings
