import { FC, useState, useEffect } from "react";
import { useAppContext } from "../../../../system/providers/appContextProvider";
import { IDocumentCreatorProps } from "../../../../@types/documents";
import { useTabsContext } from "../../../../system/providers/tabsProvider";
import { ActRevaluationDataProvider } from "../../../../Services/DataProviders/ActRevaluationDataProvider";
import { IActRevaluationCreateDTO, IActRevaluationGetDTO, IActRevaluationUpdateDTO } from "../../../../libs/coreapi-dto/documents/ActRevaluation";
import { ActRevaluationForm } from "./ActRevaluationForm";
import { getActRevaluationItemUpdateDTO } from "../../../../libs/core-api-requests/customRequest/ActRevaluationItemUpdateRequest";
import { DateTime } from "luxon";
import { IActDeductionItemGetDTO } from "../../../../libs/coreapi-dto/documents/actDeductionItem";
import { v4 as uuidv4 } from 'uuid';

interface IEditDocument {
    actRevaluation: IActRevaluationGetDTO
    documentUpdate: IActRevaluationUpdateDTO
}

const ActRevaluationCreatorView: FC<IDocumentCreatorProps<IActRevaluationCreateDTO>> = (props) => {
    const appContext = useAppContext();
    const tabsContext = useTabsContext();
    const actRevaluationDP = new ActRevaluationDataProvider(appContext.coreApiService);
    const [viewState, setViewState] = useState<GridStateType>(props.variant);
    const [id, setId] = useState<string | undefined>(props.idGlobal)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [editDocument, setEditDocument] = useState<IEditDocument | null>(null);

    useEffect(() => {
        if (viewState === 'edit') {
            actRevaluationDP.getById(id as string, (actRevaluation) => {
                getActRevaluationItemUpdateDTO(actRevaluation.idGlobal as string, (e) => {
                    const documentUpdate: IActRevaluationUpdateDTO = {
                        idGlobal: actRevaluation.idGlobal as string,
                        documentDate: actRevaluation.documentDate,
                        idStoreGlobal: actRevaluation.store.idGlobal,
                        idPricingModelGlobal: actRevaluation.pricingModel.idGlobal,
                        mnemocode: actRevaluation.mnemocode,
                        comment: actRevaluation.comment,
                        newBarcode: actRevaluation.newBarcode,
                        idPriceBaseDocGlobal: actRevaluation?.priceBaseDoc?.idGlobal ?? undefined,
                        idActRevaluationReasonGlobal: actRevaluation?.revaluationReason?.idGlobal ?? undefined,
                        items: e
                    }
                    setEditDocument({ actRevaluation: actRevaluation, documentUpdate: documentUpdate })
                })
            })
        }

        if (viewState === 'copy') {
            actRevaluationDP.getById(id as string, (actRevaluation) => {
                let idGlobal = uuidv4()
                const documentUpdate: IActRevaluationUpdateDTO = {
                    items: [],
                    documentDate: DateTime.now(),
                    mnemocode: "",
                    comment: "",
                    newBarcode: false,
                    idPricingModelGlobal: actRevaluation.pricingModel.idGlobal,
                    idPriceBaseDocGlobal: "",
                    idActRevaluationReasonGlobal: "",
                    idGlobal: idGlobal
                }
                setEditDocument({ actRevaluation: {  idGlobal: idGlobal ,items: [] as IActDeductionItemGetDTO[]} as IActRevaluationGetDTO, documentUpdate: documentUpdate })
            })
        }
    }, [viewState])

    return (
        <>
            {
                viewState === "create" ?
                    <ActRevaluationForm
                        isSubmitting={isSubmitting}
                        document={null}
                        documentItems={null}
                        documentUpdate={null}
                        idGlobal={props.idGlobal}
                        variant="create"
                        save={(RevaluationAct) => {
                            setIsSubmitting(true)
                            actRevaluationDP.create(RevaluationAct, (id) => {
                                setId(id)
                                setViewState('edit');
                                props.createdCallback?.()
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        ok={(RevaluationAct) => {
                            setIsSubmitting(true)
                            actRevaluationDP.create(RevaluationAct, (id) => {
                                setId(id)
                                setViewState("create")
                                props.createdCallback?.()
                                tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                setIsSubmitting(false)
                            }, ()=>{}, (e)=>{
                                setIsSubmitting(false);
                            })
                        }}
                        cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                        permission={props.permission}
                    />
                    :
                    viewState === "edit" ?
                        editDocument && <ActRevaluationForm
                            isSubmitting={isSubmitting}
                            variant='edit'
                            save={(ActRevaluation) => {
                                setIsSubmitting(true)
                                actRevaluationDP.update(id as string, ActRevaluation, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    setIsSubmitting(false)
                                })
                            }}
                            ok={(ActRevaluation) => {
                                setIsSubmitting(true)
                                actRevaluationDP.update(id as string, ActRevaluation, (e) => {
                                    if (e.respType === 'isFailed') return setIsSubmitting(false);

                                    props.createdCallback?.()
                                    tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                    setIsSubmitting(false)
                                })
                            }}
                            cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                            document={editDocument.actRevaluation}
                            documentItems={editDocument.actRevaluation.items}
                            documentUpdate={editDocument.documentUpdate}
                            permission={props.permission}
                        />
                        :
                        viewState === "copy" ?
                            editDocument && <ActRevaluationForm
                                isSubmitting={isSubmitting}
                                variant='copy'
                                save={(ActRevaluation) => {
                                    setIsSubmitting(true)
                                    actRevaluationDP.create(ActRevaluation, (id) => {
                                        setId(id)
                                        setViewState('edit')
                                        props.createdCallback?.()
                                        setIsSubmitting(false)
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    })
                                }}
                                ok={(ActRevaluation) => {
                                    setIsSubmitting(true)
                                    actRevaluationDP.create(ActRevaluation, (id) => {
                                        setId(id)
                                        setViewState("create")
                                        props.createdCallback?.()
                                        tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)
                                        setIsSubmitting(false)
                                    }, ()=>{}, (e)=>{
                                        setIsSubmitting(false);
                                    })
                                }}
                                cancel={() => tabsContext.currentTab && tabsContext.close(tabsContext.currentTab)}
                                document={editDocument.actRevaluation}
                                documentItems={[]}
                                documentUpdate={editDocument.documentUpdate}
                                permission={props.permission}
                            />
                            :
                            <></>
            }
        </>
    )
}

export default ActRevaluationCreatorView