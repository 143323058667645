import { useState, FC } from "react";
//import { GridSelectorModalWindow } from "../../../components/modalWindows/GridSelectorModalWindow";
//import { ContractorGrid, ContractorGridPluginSettings } from ".";
import { v4 as uuidv4 } from 'uuid';
//import { IEntitySimpleDTO } from "../../../libs/coreapi-dto/@types/common";
import { useTranslation } from "react-i18next";
import { IEntitySimpleDTO } from "../../../../libs/coreapi-dto/@types/common";
import { KizInventoryItemCorrectGrid, KizInventoryItemCorrectGridPluginSettings } from "..";
import { GridSelectorModalWindow } from "../../../../components/modalWindows/GridSelectorModalWindow";

const KizInventoryItemSelectorModal: FC<IGridSelectorModalProps> = (props) => {
    const [entity, setEntity] = useState<IEntitySimpleDTO>(props.selectedEntity ?? {} as IEntitySimpleDTO)
    const [entities, setEntities] = useState<IEntitySimpleDTO[]>(props?.selectedEntities ?? []);
    const [disabled, setDisabled] = useState<boolean>(false);
    const gridId = uuidv4();
    const plugin = KizInventoryItemCorrectGridPluginSettings
    const { t } = useTranslation();
    
       return <GridSelectorModalWindow
        entityToPressEnter={entity}
        fullScreen
        gridDisplayName={plugin?.name ?? t('documents.kizInventory.itemsCorrect')}
        ok={{
            onClick: () => props.multipleSelect ? props.ok(entities) : props.ok?.(entity),
            disabled: disabled
        }}
        cancel={{ onClick: () => props.cancel?.() }}>
        {
            plugin
                ? <KizInventoryItemCorrectGrid
                    masterIdGlobal={props.masterIdGlobal}
                    data={entities}
                    baseGridFilter={props.gridFilter}
                    gridId={gridId}
                    plugin={plugin}
                    onSelect={(row, rowDto) => {
                        if (row && rowDto && rowDto.deleted === false) {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            setDisabled(false)
                        } else if (row && rowDto && rowDto.deleted === true) {
                            setDisabled(true)
                        } else if (row) {
                            setDisabled(false)
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                        }
                    }}
                    onMultipleSelect={(rows) => {
                        rows && setEntities(rows.map(x => ({ idGlobal: x.idGlobal, displayName: x.displayName } as IEntitySimpleDTO)))
                    }}
                    multipleSelect={props.multipleSelect}
                    onDoubleClick={(row, rowDto) => {
                        if (row && rowDto && rowDto.deleted === false) {
                            setEntity({ idGlobal: row.idGlobal, displayName: row.displayName })
                            props.ok?.({ idGlobal: row.idGlobal, displayName: row.displayName })
                        }
                    }}
                />
                : <p>{t('documents.kizInventory.itemsCorrect')}</p>
        }
    </GridSelectorModalWindow>
}

export default KizInventoryItemSelectorModal