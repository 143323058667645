import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IKizBoxDTO, IKizDTO } from '../../../../../libs/coreapi-dto/dirs/kiz';
import useGridFilter from '../../../../../system/hooks/useGridFilter';
import Styles from '../Styles/ScanModalStyle.module.scss';
import { ChangeableGrid } from '../../../../../components/grids/changeableGrid/ChangeableGrid';

interface IKizModalContentProps extends ISelectorGridProps {
    kizData: IKizDTO[],
    setKizData: (data: IKizDTO[]) => void,
    boxData: IKizBoxDTO[],
    setBoxData: (value: IKizBoxDTO[]) => void,
    selectedItem: IGridRow | undefined,
    setSelectedItem: (data: IGridRow | undefined) => void,
    totalScanCount: number,
    scanCount: number,
    isEditKizs?: boolean,
    styleCell?: boolean,
    pluginSettings: IPluginSettings
}

export const KizModalContent: React.FC<IKizModalContentProps> = (props) => {
    const [totalCount] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();

    const isEditKizs: boolean = props.isEditKizs ?? false;

    const getStyleCell = (processedCell: IGridCell): string => 
    {
        let cell = processedCell.propertyName === 'isTrusted' ? true: false
        if (cell)
        {
            let style = processedCell.value === 'Да' ? Styles.isTrusted : Styles.isNotTrusted
            return style
        }
        return ''
    }

    return (
        <>
            <div
                className={Styles.InputPanel}

            >
            </div>
            {
                <ChangeableGrid
                    gridId={uuidv4()}
                    data={props.kizData}
                    filter={gridFilter}
                    totalCount={totalCount}
                    plugin={props.pluginSettings}
                    numbering={true}
                    hiddenPagination={undefined}
                    selectedItem={props.selectedItem}
                    onDoubleClick={(row) => props.onDoubleClick?.(row)}
                    onSelect={(row) => {
                        props.setSelectedItem(row)
                        props.onSelect?.(row)
                    }}
                    onSort={(i) => { dispatchGridFilter({ type: "sort", payload: i.propertyName }) }}
                    onFilterDelete={(i) => { dispatchGridFilter({ type: "deleteColumnFilter", payload: i.propertyName }) }}
                    onPageNumberChange={(n) => { dispatchGridFilter({ type: "changePageNumber", payload: { pageNumber: n } }) }}
                    onNumberPerPageChange={(n) => {
                        dispatchGridFilter({ type: "changeNumberPerPage", payload: { numberPerPage: n } })
                    }}
                    isEditKizs={isEditKizs}
                    getStyleCell={(processedCell) => props.styleCell === true? getStyleCell(processedCell): null}
                />
                    
            }
        </>
    )
};