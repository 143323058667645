import { FC, useContext, useEffect, useState } from "react"
import { IdTableVariant, LoadingStatus } from "../../../@types/enumsGlobal"
import { PluginWrapper } from "../../../components/plugins"
import { LabelPrintingDataProvider } from "../../../Services/DataProviders/LabelPrintingDataProvider"
import useGridFilter from "../../../system/hooks/useGridFilter"
import { useAppContext } from "../../../system/providers/appContextProvider"
import { ILabelPrintLot } from "../Shared/Interfaces/ILabelPrintLot"
import { ChangeableGrid, IChangeDataGrid } from "../../../components/grids/changeableGrid/ChangeableGrid"
import { useUserContext } from "../../../system/providers/userContextProvider"
import { getDateTimeUserTimeZone } from "../Common/CommonFunctions"

export interface ISelectingPrintItems extends IGridProps {
    idTable: IdTableVariant
    docimentsIds: Array<string>
    onMultipleSelectRows: (rows: IGridRow[]) => void
}

export const LotSelectorPluginView: FC<ISelectingPrintItems> = (props) => {
    const appCtx = useAppContext();
    const dataProvider = new LabelPrintingDataProvider(appCtx.reportAPIService);
    const userCtx = useUserContext();

    const [data, setData] = useState<ILabelPrintLot[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [gridFilter, dispatchGridFilter] = useGridFilter();
    const [selectedItem, setSelectedItem] = useState<IGridRow>();
    const [selectedItems, setSelectedItems] = useState<IGridRow[]>([]);
    const [viewState, setViewState] = useState<GridStateType>("refresh");
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProcess)

    useEffect(() => {
        if (viewState === 'refresh') {
            setViewState('view')
            dataProvider.getViewByParams(props.idTable, props.docimentsIds, getDateTimeUserTimeZone(userCtx), (e: Array<ILabelPrintLot>, c: Array<IGridColumn>) => {
                if (props.plugin.columns.length == 0)
                    props.plugin.columns.push(...c)
                const totalCount = e.length
                if (totalCount > 0) {
                    setLoadingStatus(LoadingStatus.Completed)
                } else {
                    setLoadingStatus(LoadingStatus.NoData)
                }
                setData(e)
                setTotalCount(totalCount)
            }
                , () => {
                    setLoadingStatus(LoadingStatus.Failed)
                })
        }
    }, [viewState])

    const changeData = (changeData: IChangeDataGrid) => 
    {
        switch (changeData.propertyName)
        {
            case 'labelQuantity':
               return setData(data.map((item) => (item.idGlobal === changeData.idGlobal ? { ...item, labelQuantity: changeData.value as number  } : item)))
            case 'priceQuantity':
                return setData(data.map((item) => (item.idGlobal === changeData.idGlobal ? { ...item, priceQuantity: changeData.value as number  } : item)))
            case 'stelQuantity':
                return setData(data.map((item) => (item.idGlobal === changeData.idGlobal ? { ...item, stelQuantity: changeData.value as number  } : item)))
        }
    }

    return (
        <PluginWrapper>
            <ChangeableGrid  
                gridId={props.gridId}
                data={data}
                totalCount={totalCount}
                filter={gridFilter}
                dataProvider={dataProvider}
                plugin={props.plugin}
                selectedItem={selectedItem}
                selectedItems={selectedItems}
                multipleSelect={true}
                onDoubleClick={(row) => props.onDoubleClick?.(row)}
                onMultipleSelect={(rows) => {
                    setSelectedItems(rows)
                    props.onMultipleSelectRows?.(rows)
                }}
                onSelect={(row) => {
                    setSelectedItem(row)
                }}
                onChangeData={(e) => changeData(e)}
                onSort={(i) => { }}
                onFilterDelete={(i) => { }}
                onPageNumberChange={(n) => { }}
                onNumberPerPageChange={(n) => { }}
                onEnter={() => setViewState("edit")}
            />
        </PluginWrapper>
    )
}