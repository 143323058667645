import { FC } from 'react';
import HomePage from './pages/homePage';
import {UserContextProvider} from './system/providers/userContextProvider';
import AppContextProvider, { AppContext } from './system/providers/appContextProvider';
import LoginPage from './pages/loginPage';
import AuthContextProvider, { AuthContext } from './system/providers/authProvider';
import renderGlobalAlert from './system/hooks/useGlobalAlert';
import 'fontsource-roboto'
import "./App.module.scss"
import { TabsProvider } from './system/providers/tabsProvider';
import { PluginProvider } from './system/providers/plugin';
import jwtDecode from 'jwt-decode';
import { TestApiProvider } from './system/providers/testApiProvider';
import { BarcodeReaderGlobal } from './system/providers/barcodeReaderGlobal';
import usePreventDeveloperMode from './system/hooks/usePreventDeveloperMode';
import { UpgradePatchAction } from './components/upgradePatchAction/UpgradePatchAction';

const App: FC = (props) => {
  usePreventDeveloperMode();

  function StartApp() {
    return (
      <AppContextProvider>
        <AppContext.Consumer>{(appCtx) => {
          return (
            <TestApiProvider>
              <AuthContextProvider>
                <AuthContext.Consumer>{(authCtx) => {
                  if (authCtx.userIsAuth) {
                    const token = JSON.parse(localStorage.getItem('TokenData') as string).payload.name;
                    return (
                      <>
                        {
                          authCtx.updateStart && 
                            <UpgradePatchAction 
                              title={token !== 'admin' ? 'Идёт обновление' : ''}
                              stopUpdateButton={token === 'admin' ? true : false}
                            />
                        }
                        <UserContextProvider>
                          <TabsProvider>
                            <BarcodeReaderGlobal>
                              <PluginProvider>
                                <HomePage logout={() => {
                                  let token: string
                                  appCtx.coreApiService.getAccessToken()
                                    .then(e => {
                                      token = e
                                    })
                                  Promise.resolve()
                                    .then((e) => {
                                      authCtx.unauthorize()
                                      //Попытаться очистить кэш вызовов отчётов
                                      try {
                                        appCtx.reportAPIService.reportRequest.then(r => r.freeForUser(token))
                                      }
                                      catch { }
                                    })
                                }}>
                                </HomePage>
                              </PluginProvider>
                            </BarcodeReaderGlobal>
                          </TabsProvider>
                        </UserContextProvider>
                      </>
                    )
                  } else {
                    return (
                      <LoginPage loginOnClick={(u, p, ec) => {
                        appCtx.coreApiService?.accessTokenRequest.getAccessToken(u, p, (resp) => {
                          if (resp.respType === "isCompleted") {
                            const decoded: DecodedToken = jwtDecode(resp.data.access_token)
                            authCtx.authorize({
                              accessToken: resp.data.access_token,
                              refreshToken: resp.data.refresh_token,
                              payload: {
                                idGlobal: decoded.sub,
                                name: decoded.preferred_username
                              }
                            })
                          }
                          else {
                            renderGlobalAlert(
                              {
                                variant: resp.message.type,
                                statusCode: resp.message?.statusCode ?? 500,
                                title: "Неправильные учётные данные",
                                detail: "",
                                instance: "Авторизация"
                              }
                            )
                            ec();
                          }
                        })
                      }}>
                      </LoginPage>
                    )
                  }
                }}
                </AuthContext.Consumer>
              </AuthContextProvider>
            </TestApiProvider>
          )
        }}
        </AppContext.Consumer>
      </AppContextProvider >
    )
  }
  return StartApp();
}

export default App