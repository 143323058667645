import axios, { AxiosError } from "axios"
import renderGlobalAlert from "../../../../system/hooks/useGlobalAlert";
import { envF } from "../../../../env";
import { ICompanyLicenseValidateResultDTO } from "../../../../libs/coreapi-dto/service/license";

export const validateLicenseRequest = async (file: File, callback: (response: ICompanyLicenseValidateResultDTO) => void) => {

        const jwtToken = localStorage.getItem("TokenData") as string;
        const token = `Bearer ${JSON.parse(jwtToken).accessToken}`;
        const formData = new FormData;
        const header = {
            headers: {
                Authorization: token,
            },
        }

        formData.append('Content', file);

        const generateRequestUrl = (prefix: string, endPoint?: string) => {
            if (endPoint) {
                return `${envF.REACT_APP_OAUTH_API_PATH}/${prefix}/${endPoint}`
            } else {
                return `${envF.REACT_APP_OAUTH_API_PATH}/${prefix}`
            }
        }

        await axios.post(
            generateRequestUrl('License', 'Validate'),
            formData,
            header
        ).then((e) => {
            if (e.status === 200) {
                callback(e.data);
            }
        }).catch((err: AxiosError) => {
            renderGlobalAlert({ variant: 'error', statusCode: 500, title: err.response?.statusText ?? 'Ошибка' })
        })
}
